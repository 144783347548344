/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IState } from "store/reducers";
import { isMobile } from "react-device-detect";
import { ISortOrder } from "utils/documentSort";
import {
  defaultDocumentFiltersData,
  DocumentFilters,
} from "components/Browser/Documents/DocumentFilter";

export enum SearchType {
  fulltext = "Full text",
  meta = "Meta",
  semantic = "Semantic",
}

export interface BrowserState {
  selectedCollectionId: number | undefined;
  tags: {
    modeBrowse: "and" | "or";
  };
  search: {
    query: string;
    searchType: SearchType;
    isSearch: boolean;
  };
  documentSort: ISortOrder;
  selectedDocumentFilters: DocumentFilters;
  focusSearchInput: boolean;
  sideBarOpen: boolean;
  selectedCitation: string | undefined;
  showExceededLimitNotifier: boolean;
}

export const initialState: BrowserState = {
  selectedCollectionId: undefined,
  tags: {
    modeBrowse: "or",
  },
  search: {
    query: "",
    searchType: SearchType.fulltext,
    isSearch: false,
  },
  documentSort: {
    key: "date-created",
    order: "descending",
  },
  selectedDocumentFilters: defaultDocumentFiltersData,
  focusSearchInput: false,
  sideBarOpen: !isMobile,
  selectedCitation: undefined,
  showExceededLimitNotifier: true,
};

// browserSlice is used for all states within browser
const browserSlice = createSlice({
  name: "browser",
  initialState,
  reducers: {
    setFocusSearchInput(state, action) {
      state.focusSearchInput = action.payload;
    },
    setSelectedCitation(state, action: PayloadAction<string | undefined>) {
      const currentState = state;
      currentState.selectedCitation = action.payload;
    },
    setSelectedCollectionId(state, action) {
      state.selectedCollectionId = action.payload;
    },
    setSideBarOpen(state, action) {
      state.sideBarOpen = action.payload;
    },
    setShowExceededLimitNotifier(state, action) {
      state.showExceededLimitNotifier = action.payload;
    },
    setModeBrowse(state, action) {
      const currentState = state;
      currentState.tags.modeBrowse = action.payload;
    },
    setSearchQuery(state, action: PayloadAction<string>) {
      state.search.query = action.payload;
    },
    setSearchTypeQuery(state, action: PayloadAction<SearchType>) {
      state.search.searchType = action.payload;
    },
    setIsSearch(state, action: PayloadAction<boolean>) {
      state.search.isSearch = action.payload;
    },
    setDocumentSort(state, action) {
      state.documentSort = action.payload;
    },
    setSelectedDocumentFilters(state, action: PayloadAction<DocumentFilters>) {
      state.selectedDocumentFilters = action.payload;
    },
  },
});

export const {
  setFocusSearchInput,
  setSelectedCitation,
  setSelectedCollectionId,
  setModeBrowse,
  setSearchQuery,
  setSearchTypeQuery,
  setIsSearch,
  setSideBarOpen,
  setShowExceededLimitNotifier,
  setDocumentSort,
  setSelectedDocumentFilters,
} = browserSlice.actions;

export const { reducer } = browserSlice;

export const selectFocusSearchInput = createSelector(
  (state: IState) => state.browser.focusSearchInput,
  (item) => item
);

export const showExceededLimitNotifier = createSelector(
  (state: IState) => state.browser.showExceededLimitNotifier,
  (item: boolean) => item
);

export const selectSelectedCitation = createSelector(
  (state: IState) => state.browser.selectedCitation,
  (item: string | undefined) => item
);

export const getSelectedCollectionId = createSelector(
  (state: IState) => state.browser.selectedCollectionId,
  (item) => item
);

export const selectTagsModeBrowse = createSelector(
  (state: IState) => state.browser.tags.modeBrowse,
  (item) => item || "or"
);

export const isSidebarOpen = createSelector(
  (state: IState) => state.browser.sideBarOpen,
  (item) => item
);

export const selectSearchQuery = createSelector(
  (state: IState) => state.browser.search.query,
  (filter) => filter
);

export const selectSearchTypeQuery = createSelector(
  (state: IState) => state.browser.search.searchType,
  (filter) => filter
);

export const selectIsSearch = createSelector(
  (state: IState) => state.browser.search.isSearch,
  (filter) => filter
);

export const selectDocumentSort = createSelector(
  (state: IState) => state.browser.documentSort,
  (item) => item
);

export const getSelectedDocumentFilters = createSelector(
  (state: IState) => state.browser.selectedDocumentFilters,
  (item) => item
);
