/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "store/features/session/slice";
import { LoadingButton } from "@mui/lab";
import handleAxiosError from "utils/handleAxiosAlert";
import { AxiosError } from "axios";
import { addAlert } from "store/features/general/slice";
import { useOrganizations } from "api/organizationService";
import authService from "api/authService";
import { Organization } from "models/api/response.types";

interface IOrgDialogProps {
  organization?: Organization;
  open: boolean;
  setOpen: (open: boolean) => void;
  orgCompletesCallback?: () => void;
}

const CreateEditOrganizationDialog: React.FC<IOrgDialogProps> = ({
  organization,
  open,
  setOpen,
  orgCompletesCallback,
}) => {
  const theme = useTheme();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { createOrganizationMutation, updateOrganizationMutation } =
    useOrganizations(user?.id);

  // state
  const [orgName, setOrgName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [orgNameError, setOrgNameError] = useState<string>("");
  const [orgDescription, setOrgDescription] = useState<string>("");

  const canDisableButton = useMemo(() => {
    if (organization) {
      return (
        organization?.name === orgName &&
        organization.description === orgDescription
      );
    }
    return false;
  }, [organization, orgDescription, orgName]);

  useEffect(() => {
    if (organization) {
      setOrgName(organization.name || "");
      setOrgDescription(organization?.description || "");
    }
  }, [organization]);

  // last step cleaning state, closing dialog
  const finishOrgCreation = () => {
    if (orgCompletesCallback) {
      orgCompletesCallback();
    } else {
      dispatch(
        addAlert({
          severity: "success",
          autoHideDuration: 10000,
          alert: {
            message: "Workspace has been created.",
          },
        })
      );
    }
    setLoading(false);
    setOrgName("");
    setOrgDescription("");
    setOpen(false);
  };

  // create org flow
  const createOrganization = () => {
    if (user) {
      // create org
      createOrganizationMutation.mutate(
        {
          organization_name: orgName,
          organization_description: orgDescription,
          creator_user_id: user.id,
        },
        {
          onSuccess: () => {
            finishOrgCreation();
            authService.fetchUserInfo(user?.id || 0).then(({ data }) => {
              dispatch(
                setUser({
                  ...data,
                  access_token: user?.access_token || "",
                })
              );
            });
          },
          onError: (err) => {
            handleAxiosError(err as AxiosError, dispatch);
            setLoading(false);
          },
        }
      );
    }
  };

  const editOrganization = () => {
    updateOrganizationMutation.mutate(
      {
        id: organization?.id as number,
        payload: {
          name: orgName,
          description: orgDescription,
        },
      },
      {
        onSuccess: () => {
          setLoading(false);
          setOpen(false);
        },
        onError: (err) => {
          handleAxiosError(err as AxiosError, dispatch);
          setLoading(false);
        },
      }
    );
  };

  // submit form, check inputs, if everythign correct proceed to org creation
  const submitForm = () => {
    if (organization) {
      editOrganization();
    } else if (orgName.length === 0) {
      setOrgNameError("Workspace name is empty.");
    } else {
      setLoading(true);
      createOrganization();
    }
  };

  // org name input change
  const handleOrgNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setOrgNameError("");
    setOrgName(event.target.value);
  };

  // org description input change
  const handleOrgDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setOrgDescription(event.target.value);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={() => setOpen(false)}>
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <DialogTitle>
          {organization ? "Edit workspace" : "Create new workspace"}
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            value={orgName}
            color="primary"
            variant="outlined"
            fullWidth
            type="text"
            placeholder="Workspace name"
            size="small"
            error={orgNameError.length > 0}
            helperText={orgNameError}
            onChange={handleOrgNameChange}
          />
          <TextField
            value={orgDescription}
            color="primary"
            variant="outlined"
            fullWidth
            type="text"
            placeholder="Workspace description (optional)"
            size="small"
            onChange={handleOrgDescriptionChange}
            sx={{
              marginTop: theme.spacing(2),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            size="medium"
            onClick={() => setOpen(false)}
          >
            Skip for now
          </Button>
          <LoadingButton
            disabled={canDisableButton}
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            loading={loading}
          >
            {organization ? "Edit" : "Create"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateEditOrganizationDialog;
