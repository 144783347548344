export function indexOfId(id: number, list: Array<{ id: number }>): number {
  for (let i = 0; i < list.length; i += 1) if (list[i].id === id) return i;
  return -1;
}

export function listsAreEqual<T>(
  a: T[] | undefined,
  b: T[] | undefined
): boolean {
  if (a === undefined || b === undefined) return false;
  return [...a].sort().join() === [...b].sort().join();
}
