/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip } from "recharts";
import { v4 as uuidv4 } from "uuid";
import {
  SearchQueries,
  SnippetChartGenerators,
} from "models/components/SnippetChartGenerator.models";
import getLocation from "utils/getLocation";

const SnippetChartGenerator: React.FC<SnippetChartGenerators> = ({
  setJumpLocation,
  colors,
  searchResults,
  pages,
}) => {
  let searchQueries: any = [];
  const [barProps, setBarProps] = useState(
    searchQueries.reduce(
      (a: { [x: string]: boolean }, { key }: any) => {
        // eslint-disable-next-line no-param-reassign
        a[key] = false;
        return a;
      },
      { hover: null }
    )
  );

  if (searchResults.tokens === undefined) return null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const traces: any[] = [];
  const x = [];
  const nbinsx = Math.min(100, pages.length + 1);
  let y: any = new Array(pages.length).fill(0);
  const concatNumber = Math.ceil(pages.length / 100);

  for (let k = 0; k < pages.length; k += 1) {
    searchQueries = [];
    x.push(k + 1);

    traces.push({
      searchKeys: k + 1,
      x,
      y,
      nbinsx,
    });

    for (let i = 0; i < searchResults.tokens.length; i += 1) {
      const { text } = searchResults.tokens[i];
      const ci = i % colors.length;
      const color = colors[ci];
      const colorStr = `rgba(${color[0]},${color[1]}, ${color[2]},0.9)`;
      y = new Array(pages.length).fill(0);

      searchQueries.push({ key: `${text}`, color: colorStr, totalCount: y });
      const token = searchResults.tokens[i];
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < token.matches.length; j++) {
        const match = token.matches[j];
        const location = getLocation(match.span[0], pages, undefined);
        if (location.pageNumber > 0) {
          const { pageNumber } = location;
          y[pageNumber - 1] += 1;
        }
        traces[k][text] = y[k];
      }
    }
  }

  const concatTraces = () => {
    if (pages.length > 100) {
      const newArray = Array.from(
        { length: Math.ceil(pages.length / Math.ceil(pages.length / 100)) },
        () => Array(0).fill(0)
      );
      traces.map((item: any, index: number) => {
        return newArray[Math.floor(index / concatNumber)].push(item);
      });
      // array to return
      const newTraces = newArray.map((item: any) => {
        let searchKeys = "";
        item.map((obj: any, index: any) => {
          if (index === 0) searchKeys = obj.searchKeys;
          return obj;
        });
        const newObject: { [k: string]: any } = {
          searchKeys,
          nbinsx: 100,
        };
        const getValue = (key: any) => {
          return item
            .map((value: { [x: string]: any }) => value[key])
            .reduce((acc: any, val: any) => {
              return acc + val;
            }, 0);
        };
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < searchQueries.length; j++) {
          newObject[`${searchQueries[j].key}`] =
            getValue(searchQueries[j].key) || 0;
        }
        return newObject;
      });
      return newTraces;
    }
    return traces;
  };

  const selectBar = (e: any) => {
    setBarProps({
      ...barProps,
      [e.dataKey]: !barProps[e.dataKey],
      hover: null,
    });
  };

  const handleClick = (data: { activePayload?: any[] | undefined }) => {
    if (data && data.activePayload) {
      let pts: any = "";
      for (let i = 0; i < data.activePayload.length; i += 1) {
        pts = {
          x: data.activePayload[i].payload.searchKeys,
          y: y[i],
        };
      }
      const pageNumber = pts.x;
      if (setJumpLocation)
        setJumpLocation({
          pageNumber,
          start: 0,
          done: false,
        });
    }
  };

  const renderColorfulLegendText = (value: string) => {
    const q = searchQueries.find(
      (query: { totalCount: any[]; key: string }) => query.key === value
    );
    const countResult = q.totalCount.reduce((a: number, b: number) => a + b, 0);
    return (
      <span key={`${uuidv4()}`}>
        {q.key} ({countResult})
      </span>
    );
  };

  const getLabelTooltip = (data: number) => {
    if (pages.length < 100) return `Page: ${data + 1}`;
    const firstNumber = data * concatNumber + 1;
    let lastNumber = data * concatNumber + concatNumber;
    if (lastNumber > pages.length) {
      lastNumber = pages.length;
    }
    if (firstNumber === lastNumber) {
      return `Page: ${firstNumber}`;
    }
    return `Page: ${firstNumber}-${lastNumber}`;
  };

  return (
    <>
      <ResponsiveContainer
        width="100%"
        maxHeight={136}
        className="snippet-generator-container"
      >
        <BarChart
          width={100}
          barCategoryGap={0}
          barGap={0}
          data={concatTraces()}
          margin={{ top: 0, left: 16, right: 16, bottom: 0 }}
          onClick={(data: { activePayload?: any[] }) => {
            handleClick(data);
          }}
        >
          <Tooltip
            isAnimationActive={false}
            labelFormatter={(data) => getLabelTooltip(data)}
          />
          <Legend formatter={renderColorfulLegendText} onClick={selectBar} />
          {searchQueries.map((item: SearchQueries) => (
            <Bar
              key={item.key}
              dataKey={item.key}
              hide={barProps[item.key] === true}
              stackId="a"
              fill={`${item.color}`}
              fillOpacity={Number(
                barProps.hover === item.key || !barProps.hover ? 1 : 0.6
              )}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default SnippetChartGenerator;
