import { v4 as uuidv4 } from "uuid";

import { Document as DocumentResponse, Task } from "models/api/response.types";

export enum UploadTaskType {
  create = "create",
  update = "update",
}

export enum UploadTaskState {
  invalid = "invalid",
  queued = "queued",
  pending = "pending",
  fulfilled = "fulfilled",
  rejected = "rejected",
  duplicate = "duplicate",
  unknown = "unknown",
  success = "success",
  failed = "failed",
}

// eslint-disable-next-line import/prefer-default-export
export class UploadTask {
  uuid: string;

  type: UploadTaskType;

  file: File;

  formData?: FormData;

  xhr?: XMLHttpRequest;

  errorMessage: string;

  // has the POST request been sent
  sent: boolean;

  state: UploadTaskState;

  loaded: number;

  total: number;

  tasks: Record<string, Task>;

  response?: { id?: number; error?: string };

  documentId?: number;

  organizationId: number;

  document?: DocumentResponse;

  timestamp: number;

  constructor(file: File, organizationId: number) {
    this.type = UploadTaskType.create;
    this.uuid = uuidv4();
    this.file = file;
    this.organizationId = organizationId;
    this.sent = false;
    this.state = UploadTaskState.queued;
    this.errorMessage = "";
    this.loaded = 0;
    this.total = 0;
    this.tasks = {};
    this.timestamp = performance.now();
  }
}

export interface UploadRow extends UploadTask {
  filename: string;
}

export const maxConcurrentUploads = 4;
export const progressBarDebounceInterval = 100;
export const documentRefreshInterval = 10000;
export const documentTypes = [
  "txt",
  "html",
  "pdf",
  "ppt",
  "pptx",
  "doc",
  "docx",
  "jpg",
  "png",
  "jpeg",
  "webp",
];

export const tableTypes = ["csv", "xlsx", "xls"];

export const getTabletype = (file: File): string => {
  for (const ext of tableTypes) {
    if (file.name.toLowerCase().endsWith(ext)) {
      return ext;
    }
  }
  return "";
};

// get doctype of file from extension
export const getDoctype = (file: File): string => {
  for (const ext of documentTypes) {
    if (file.name.toLowerCase().endsWith(ext)) {
      return ext;
    }
  }
  return "";
};
