/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import {
  AICell,
  AIColumn,
  AIColumnList,
  AITable,
  DocumentReadResponse,
  Tag,
} from "models/api/response.types";
import { useDocuments } from "api/documentService";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RotateRightOutlinedIcon from "@mui/icons-material/RotateRightOutlined";
import breaks from "remark-breaks";
import aiService, {
  useAITables,
  useTableCells,
  useTableColumns,
} from "api/aiService";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SortDocumentMenu from "components/Browser/Documents/SortDocumentMenu";
import { ISortOrder, sortOptions } from "utils/documentSort";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import DocumentFilter, {
  DocumentFilters,
  defaultDocumentFiltersData,
} from "components/Browser/Documents/DocumentFilter";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { tagsModes } from "utils/tagsModes";
import {
  selectTagsModeBrowse,
  setSideBarOpen,
} from "store/features/browser/slice";
import LoadingOverlay from "components/helpers/LoadingOverlay";
import { useUsers } from "api/userService";
import { isAdmin, isGuest } from "models/components/Permissions.models";
import AiTableColumnDialog from "components/Dialogs/AiTableColumnDialog";
import AiTableOverwriteDialog from "components/Dialogs/AiTableOverwriteDialog";
import handleAxiosError from "utils/handleAxiosAlert";
import { AxiosError } from "axios";
import AiTableCreditEstimateDialog from "components/Dialogs/AiTableCreditEstimateDialog";
import { AiLanguagesShortcuts, terminal_status_names } from "utils/aiHelpers";
import AiTableOnboarding from "components/Onboarding/AiTableOnboarding";
import { useQueryClient } from "@tanstack/react-query";
import {
  useOrganizationUsage,
  useOrganizations,
} from "api/organizationService";
import clsx from "clsx";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import reorder from "utils/reorder";
import { exportAITableAsExcel } from "utils/aiTableExport";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import AddAIContextDialog from "components/Dialogs/AddAIContextDialog/AddAIContextDialog";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import AiTableCellDialog from "components/Dialogs/AiTableCellDialog";
import { useNavigate, useParams } from "react-router-dom";
import MainLoader from "components/helpers/MainLoader";
import AiTableDocument from "components/Browser/AiTables/AiTableDocument";
import { Container } from "components/Browser/AiTables/AiTable-styles";
import AITableDialog from "components/Dialogs/AITableDialog";
import { isTrialExist } from "utils/plans";
import routePaths from "routes/routePaths";
import TableSourceDialog from "components/Dialogs/TableSourceDialog/TableSourceDialog";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { FlipCameraAndroid } from "@mui/icons-material";

export interface DataToRun {
  type: "cell" | "column";
  existingData: number[][];
  emptyData: number[][];
  continueResponse?: boolean;
  emptyCellsOnly?: boolean;
}

const Wrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1.5)}`,
  flex: 1,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .breadcrumbs": {
      display: "flex",
      alignItems: "center",
      "& .main": {
        cursor: "pointer",
        color: theme.palette.primary.main,
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
  },
}));

const ErrorWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
  "& img": {
    width: "250px",
    [theme.breakpoints.down("lg")]: {
      width: "200px",
    },
    [theme.breakpoints.down("md")]: {
      width: "150px",
    },
  },
}));

const SelectedTable: React.FC<{
  readOnly: boolean;
  table: AITable;
  setTable: (table: AITable) => void;
}> = ({ readOnly, table, setTable }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const tagsModeBrowse = useSelector(selectTagsModeBrowse);
  const { usageQueryKey } = useOrganizationUsage(currentOrganizationId);
  const { updateAITableMutation } = useAITables(currentOrganizationId);
  const { documents, getCachedDocumentById } = useDocuments(
    currentOrganizationId
  );
  const { users } = useUsers(currentOrganizationId);
  const {
    columns,
    deleteColumnMutation,
    columnsIsFetching,
    columnsIsLoading,
    upsertCachedColumn,
  } = useTableColumns(table.id);
  const { cells, cellsQueryKey, cellsIsLoading } = useTableCells(table.id);
  const [dataToRun, setDataToRun] = useState<undefined | DataToRun>(undefined);
  const [openOverwiteDialog, setOpenOverWriteDialog] = useState<boolean>(false);
  const [columnDialogIsOpen, setColumnDialogIsOpen] = useState(false);
  const [cellDialogIsOpen, setCellDialogIsOpen] = useState(false);
  const [documentSort, setDocumentSort] = useState<ISortOrder>({
    key: "date-created",
    order: "descending",
  });
  const [openAddContextDialog, setOpenAddContextDialog] =
    useState<boolean>(false);
  const [selectedDocuments, setSelectedDocuments] = useState<
    DocumentReadResponse[]
  >([]);
  const [runCellCreditEstimate, setRunCellCreditEstimate] =
    useState<boolean>(false);
  const [creditEstimateNumber, setCreditEstimateNumber] = useState<
    number | undefined
  >(undefined);
  const [tableDocumentToUpdate, setTableDocumentToUpdate] = useState<
    DocumentReadResponse | undefined
  >(undefined);
  const [columnIdToEdit, setColumnIdToEdit] = useState<number | undefined>(
    undefined
  );
  const [confirmRemovingSelectedSources, setConfirmRemovingSelectedSources] =
    useState<boolean>(false);
  const [confirmColumnDeletion, setConfirmColumnDeletion] = useState<
    AIColumn | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  // polling loading last message
  const [polling, setPolling] = useState<boolean>(false);
  // all filters options
  const [documentFilters, setDocumentFilters] = useState<DocumentFilters>(
    defaultDocumentFiltersData
  );
  const [selectedDocumentFilters, setSelectedDocumentFilters] =
    useState<DocumentFilters>(defaultDocumentFiltersData);
  const [documentsForFilter, setDocumentsForFilter] = useState<
    DocumentReadResponse[]
  >([]); // filteredArray by search, tags and filters
  // load documents for the first time
  const [loading, setLoading] = useState<boolean>(true);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const showLoadingOverlay =
    columnsIsFetching ||
    columnsIsLoading ||
    cellsIsLoading ||
    !documents ||
    loading;
  const [cellInfoToEdit, setCellInfoToEdit] = useState<{
    cellId: number | undefined;
    colId: number;
    docId: number;
  }>({ cellId: undefined, colId: -1, docId: -1 });
  const [newColumnOrder, setNewColumnOrder] = useState<
    AIColumnList | undefined
  >(undefined);
  const [isColumnDragging, setIsColumnDragging] = useState<boolean>(false);
  const [copied, setCopied] = useState<number | undefined>(undefined);
  const [createNewTableWithIds, setCreateNewTableWithIds] = useState<
    number[] | undefined
  >(undefined);

  useEffect(() => {
    setCurrentPage(1);
    setDocumentsForFilter([]);
    setSelectedDocuments([]);
    setSelectedDocumentFilters(defaultDocumentFiltersData);
  }, [table]);

  useEffect(() => {
    setTimeout(() => {
      setCopied(undefined);
    }, 3000);
  }, [copied]);

  // update polling status if cell task_status is not in terminal state
  useEffect(() => {
    if (cells) {
      if (
        cells.some((data) => !terminal_status_names.includes(data.task_status))
      ) {
        setPolling(true);
      }
    }
  }, [cells]);

  // polling data, await to finish all tasks
  useEffect(() => {
    let intervalId: any;
    if (polling && cells) {
      intervalId = setInterval(() => {
        if (
          !cells.some(
            (data) => !terminal_status_names.includes(data.task_status)
          )
        ) {
          setPolling(false);
        }
        queryClient.invalidateQueries(cellsQueryKey);
      }, 5000);
    }
    return () => {
      queryClient.invalidateQueries(usageQueryKey);
      clearInterval(intervalId);
    };
  }, [polling, cells]);

  const handleAdd = () => {
    setColumnDialogIsOpen(true);
  };

  async function copyTextToClipboard(text: string, cellId: number) {
    setCopied(cellId);
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand("copy", true, text);
  }

  const getData = useCallback(
    (documentId: number, columnId: number): AICell | undefined => {
      if (cells) {
        for (let i = 0; i < cells.length; i += 1) {
          const data = cells[i];
          if (
            data.document_id === documentId &&
            data.ai_column_id === columnId
          ) {
            return data;
          }
        }
      }
      return undefined;
    },
    [cells]
  );

  const handleRemove = (columnId: number) => {
    deleteColumnMutation.mutate(columnId);
  };

  const handleEdit = (columnId: number) => {
    setColumnIdToEdit(columnId);
    setColumnDialogIsOpen(true);
  };

  const handleCellEdit = (columnId: number, documentId: number) => {
    const cellData = getData(documentId, columnId);
    const cellId = cellData && cellData.id ? cellData.id : undefined;
    setCellInfoToEdit({ cellId, docId: documentId, colId: columnId });
    setCellDialogIsOpen(true);
  };

  const runCells = () => {
    if (dataToRun) {
      setPolling(true);
      aiService
        .runTableCells(table.id, {
          doc_id_col_id: dataToRun.emptyCellsOnly
            ? dataToRun.emptyData
            : [...dataToRun.emptyData, ...dataToRun.existingData],
          continue_response: dataToRun.continueResponse,
        })
        .then(() => {
          setDataToRun(undefined);
          queryClient.invalidateQueries(cellsQueryKey);
        })
        .catch((err) => {
          handleAxiosError(err as AxiosError, dispatch);
          setDataToRun(undefined);
        });
    }
  };

  const filteredDocuments: DocumentReadResponse[] = useMemo(() => {
    if (documents) {
      let result: DocumentReadResponse[] = [];
      table.document_ids.forEach((docId) => {
        const [document] = getCachedDocumentById(docId);
        if (document) {
          result.push(document);
        }
      });
      setDocumentsForFilter(result);
      result = result
        .filter((item) =>
          selectedDocumentFilters.titles.length > 0
            ? selectedDocumentFilters.titles.includes(item?.meta?.title)
            : `${item}`
        )
        .filter((item) => {
          if (selectedDocumentFilters.authors.length) {
            const authors = new Set<string>();
            if (Array.isArray(item.meta.author)) {
              item.meta.author.forEach((author) => authors.add(author));
            } else if (item.meta.author) {
              if (item.meta.author.includes(" and ")) {
                (item.meta.author as string)
                  .split(" and ")
                  .forEach((value) => value && authors.add(value));
              } else if (item.meta.author.includes(" AND ")) {
                (item.meta.author as string)
                  .split(" AND ")
                  .forEach((value) => value && authors.add(value));
              } else {
                authors.add(item.meta.author);
              }
            }
            return selectedDocumentFilters.authors.filter((author) => {
              return Array.from(authors).includes(author);
            }).length;
          }
          return item;
        })
        .filter(
          (item) =>
            selectedDocumentFilters.journals.length
              ? selectedDocumentFilters.journals.includes(item.meta.journal)
              : `${item}`,
          false
        )
        .filter((item) => {
          if (selectedDocumentFilters.years.length) {
            return selectedDocumentFilters.years.includes(
              parseInt(item.meta.year, 10)
            );
          }
          return item;
        })
        .filter((item: DocumentReadResponse) => {
          if (selectedDocumentFilters?.tags?.length) {
            const documentTagIds = new Set(item.tag_ids);
            const intersection = selectedDocumentFilters.tags.filter(
              (tag: Tag) => documentTagIds.has(tag.id)
            );
            if (tagsModeBrowse === tagsModes.or && intersection.length === 0) {
              return false;
            }
            return !(
              tagsModeBrowse === tagsModes.and &&
              intersection.length < selectedDocumentFilters.tags.length
            );
          }
          return item;
        });
      // 4. sort by sortOptions
      result = result.sort((a, b) =>
        sortOptions[documentSort.key].compare(a, b, documentSort.order)
      );
      if (loading) {
        setLoading(false);
      }
      return result;
    }
    return [];
  }, [
    table.document_ids,
    documents,
    documentSort,
    tagsModeBrowse,
    selectedDocumentFilters,
  ]);

  const itemsPerPage = filteredDocuments.length > 500 ? 35 : 25;
  const totalPages = Math.ceil(filteredDocuments.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const documentsToShow = filteredDocuments.slice(startIndex, endIndex);

  useEffect(() => {
    if (dataToRun && runCellCreditEstimate) {
      const cellCount = dataToRun.emptyCellsOnly
        ? dataToRun.emptyData
        : [...dataToRun.emptyData, ...dataToRun.existingData];
      if (cellCount.length > 10) {
        setCreditEstimateNumber(cellCount.length);
      } else {
        runCells();
      }
      setRunCellCreditEstimate(false);
    }
  }, [dataToRun, runCellCreditEstimate]);

  const updateSources = (document_ids: number[]) => {
    updateAITableMutation.mutate(
      {
        tableId: table.id,
        body: {
          meta: {
            ...table.meta,
            last_edited: new Date().toISOString(),
          },
          document_ids,
        },
      },
      {
        onSuccess: (result) => {
          setTable(result.data);
        },
        onSettled: () => {
          setOpenAddContextDialog(false);
        },
      }
    );
  };

  const addDocumentSources = (docs: DocumentReadResponse[]) => {
    const idsToAdd = docs.map((src) => src.id);
    const newIds = new Set(table.document_ids);
    idsToAdd.forEach((id) => {
      if (!newIds.has(id)) {
        newIds.add(id);
      }
    });
    updateSources(Array.from(newIds));
  };

  const removeSelectedSources = () => {
    const newIds = new Set(table.document_ids);
    selectedDocuments.forEach((doc) => {
      if (newIds.has(doc.id)) {
        newIds.delete(doc.id);
      }
    });
    updateSources(Array.from(newIds));
    setSelectedDocuments([]);
  };

  const handleRunCell = (documentId: number, columnId: number) => {
    const data = getData(documentId, columnId);
    setDataToRun({
      type: "cell",
      existingData: data ? [[documentId, columnId]] : [],
      emptyData: !data ? [[documentId, columnId]] : [],
      continueResponse: false,
    });
    if (data) {
      setOpenOverWriteDialog(true);
    } else {
      setRunCellCreditEstimate(true);
    }
  };

  const handleRunPage = (columnId: number) => {
    const existingData: number[][] = [];
    const emptyData: number[][] = [];
    const indices = filteredDocuments
      .slice(startIndex, endIndex)
      .map((document) => [document.id, columnId]);
    indices.forEach((ind) => {
      const data = getData(ind[0], ind[1]);
      if (data) {
        existingData.push([ind[0], ind[1]]);
      } else {
        emptyData.push([ind[0], ind[1]]);
      }
    });
    setDataToRun({
      type: "column",
      existingData,
      emptyData,
    });
    if (existingData.length > 0) {
      setOpenOverWriteDialog(true);
    } else {
      setRunCellCreditEstimate(true);
    }
  };

  const handleRunAllCells = () => {
    if (table.document_ids.length > 0 && columns && columns?.length > 0) {
      const indices: number[][] = [];
      table.document_ids.forEach((docId) => {
        columns.forEach((column) => {
          indices.push([docId, column.id]);
        });
      });
      const existingData: number[][] = [];
      const emptyData: number[][] = [];
      indices.forEach((ind) => {
        const data = getData(ind[0], ind[1]);
        if (data) {
          existingData.push([ind[0], ind[1]]);
        } else {
          emptyData.push([ind[0], ind[1]]);
        }
      });
      setDataToRun({
        type: "column",
        existingData,
        emptyData,
      });
      if (existingData.length > 0) {
        setOpenOverWriteDialog(true);
      } else {
        setRunCellCreditEstimate(true);
      }
    }
  };

  const setPage = (page: number) => {
    setCurrentPage(page);
    setSelectedDocuments([]);
    document.getElementById("main-content")?.scrollTo({ top: 0 });
  };

  const handleContinueResponse = (documentId: number, columnId: number) => {
    const data = getData(documentId, columnId);
    setDataToRun({
      type: "cell",
      existingData: data ? [[documentId, columnId]] : [],
      emptyData: !data ? [[documentId, columnId]] : [],
      continueResponse: true,
    });
    setRunCellCreditEstimate(true);
  };

  const tableColumns = useMemo(() => {
    if (newColumnOrder) {
      return newColumnOrder;
    }
    if (columns) {
      return columns.sort((a, b) => a.order_id - b.order_id);
    }
    return [];
  }, [columns, newColumnOrder]);

  const columnDragEnd = (response: DropResult) => {
    const { destination, source } = response;
    if (destination) {
      const column = tableColumns[source.index];
      const newOrder = reorder(tableColumns, source.index, destination.index);
      setNewColumnOrder(newOrder);
      const prevColumn = newOrder[destination.index - 1];
      const nextColumn = newOrder[destination.index + 1];

      let newOrderId;
      if (prevColumn && nextColumn) {
        newOrderId = (prevColumn.order_id + nextColumn.order_id) / 2;
      } else if (prevColumn) {
        newOrderId = prevColumn.order_id + 1;
      } else if (nextColumn) {
        newOrderId = (0 + nextColumn.order_id) / 2;
      } else {
        newOrderId = 1;
      }
      aiService
        .updateTableColumn(table.id, column.id, {
          order_id: newOrderId,
        })
        .then((res) => {
          upsertCachedColumn(res.data);
          setNewColumnOrder(undefined);
          updateAITableMutation.mutate({
            tableId: table.id,
            body: {
              meta: {
                ...table.meta,
                last_edited: new Date().toISOString(),
              },
            },
          });
        });
      setTimeout(() => {
        setIsColumnDragging(false);
      }, 300);
      setIsColumnDragging(false);
    } else {
      setIsColumnDragging(false);
    }
  };

  return (
    <Wrapper>
      <Box className="header">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          {smScreen && (
            <IconButton
              className="sidebar-close-icon"
              size="medium"
              onClick={() => dispatch(setSideBarOpen(true))}
              color="primary"
            >
              <MenuOutlinedIcon fontSize="medium" color="action" />
            </IconButton>
          )}
          <Typography className="breadcrumbs">
            <span
              className="main"
              onClick={() => {
                navigate(`/browse/ai-tables`);
              }}
            >
              Template Libraries
            </span>
            <KeyboardArrowRightOutlinedIcon fontSize="small" />
            {table.meta?.name || "Template Library"}
          </Typography>
        </Box>
        {table.document_ids.length > 0 && (
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
            }}
          >
            {selectedDocuments.length > 0 && (
              <>
                <Button
                  size="small"
                  color="primary"
                  variant="text"
                  startIcon={<TableChartOutlinedIcon fontSize="small" />}
                  onClick={() => {
                    setCreateNewTableWithIds(
                      selectedDocuments.map((doc) => doc.id)
                    );
                  }}
                >
                  Create new
                </Button>
                <Button
                  size="small"
                  color="primary"
                  startIcon={<DeleteOutlineOutlinedIcon fontSize="small" />}
                  variant="text"
                  onClick={() => {
                    setConfirmRemovingSelectedSources(true);
                  }}
                >
                  Remove
                </Button>
              </>
            )}
            <Button
              size="small"
              color="primary"
              variant="text"
              startIcon={<FileUploadOutlinedIcon fontSize="small" />}
              onClick={() => {
                exportAITableAsExcel(
                  filteredDocuments,
                  tableColumns,
                  cells || [],
                  user?.id
                );
              }}
            >
              Export
            </Button>
            {!readOnly && (
              <>
                <Button
                  className="manage-sources"
                  color="secondary"
                  variant="contained"
                  size="small"
                  startIcon={<FlipCameraAndroid fontSize="small" />}
                  onClick={handleRunAllCells}
                >
                  Get answer for all cells
                </Button>
                <Button
                  className="manage-sources"
                  color="primary"
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon fontSize="small" />}
                  onClick={() => {
                    setOpenAddContextDialog(true);
                  }}
                >
                  Source
                </Button>
              </>
            )}
          </Box>
        )}
      </Box>
      {table.document_ids.length > 0 ? (
        <Container>
          {!showLoadingOverlay ? (
            <Box id="main-content">
              <Box className="table-container">
                <Box className="table">
                  <Box className="head">
                    <DragDropContext
                      onDragEnd={columnDragEnd}
                      onDragStart={() => {
                        setIsColumnDragging(true);
                      }}
                    >
                      <Droppable
                        droppableId="droppableColumns"
                        direction="horizontal"
                      >
                        {(provided) => (
                          <Box
                            className="header-container"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            <Box className="header documents">
                              <Box className="document-filters">
                                {!readOnly && (
                                  <Checkbox
                                    color="primary"
                                    className="document-checkbox"
                                    size="small"
                                    checked={selectedDocuments.length > 0}
                                    indeterminate={
                                      selectedDocuments.length > 0 &&
                                      selectedDocuments.length <
                                        documentsToShow.length
                                    }
                                    onChange={() => {
                                      if (selectedDocuments.length > 0) {
                                        setSelectedDocuments([]);
                                      } else {
                                        setSelectedDocuments(documentsToShow);
                                      }
                                    }}
                                  />
                                )}
                                <SortDocumentMenu
                                  documentSort={documentSort}
                                  setSortValue={setDocumentSort}
                                />
                                <DocumentFilter
                                  selectedFilters={selectedDocumentFilters}
                                  filteredDocuments={documentsForFilter}
                                  filters={documentFilters}
                                  setFilters={setDocumentFilters}
                                  setSelectedDocumentFilters={
                                    setSelectedDocumentFilters
                                  }
                                />
                              </Box>
                              <Box className="secondary-container">
                                <Box className="page-container">
                                  <IconButton
                                    disabled={currentPage === 1}
                                    size="small"
                                    color="primary"
                                    onClick={() => setPage(currentPage - 1)}
                                  >
                                    <KeyboardArrowLeftIcon fontSize="medium" />
                                  </IconButton>
                                  <Typography variant="body2" color="primary">
                                    {currentPage} / {totalPages}
                                  </Typography>
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    disabled={totalPages === currentPage}
                                    onClick={() => setPage(currentPage + 1)}
                                  >
                                    <KeyboardArrowRightIcon fontSize="medium" />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                            {tableColumns?.map((column, index) => {
                              const language =
                                column.meta.language || "English (US)";
                              const languageShortCut =
                                AiLanguagesShortcuts[language];
                              return (
                                <Draggable
                                  key={column.id}
                                  draggableId={column.id.toString()}
                                  index={index}
                                  disableInteractiveElementBlocking
                                  isDragDisabled={
                                    !isAdmin(currentUserRole) || readOnly
                                  }
                                >
                                  {(secondaryProvided) => (
                                    <Box
                                      className={clsx("header", {
                                        disabled: readOnly,
                                        dragging: isColumnDragging,
                                      })}
                                      key={column.id}
                                      ref={secondaryProvided.innerRef}
                                      {...secondaryProvided.draggableProps}
                                      {...secondaryProvided.dragHandleProps}
                                    >
                                      <Typography
                                        className="column-name"
                                        variant="body2"
                                        fontWeight={500}
                                      >
                                        {column.name || column.query}
                                      </Typography>
                                      {!readOnly && (
                                        <Box
                                          id={`actions-container-${index + 1}`}
                                          className="actions-container"
                                        >
                                          <Tooltip
                                            placement="top"
                                            enterDelay={500}
                                            title={`Column language: ${language}`}
                                          >
                                            <Box className="language-shortcut">
                                              {languageShortCut}
                                            </Box>
                                          </Tooltip>
                                          <Tooltip
                                            placement="top"
                                            enterDelay={500}
                                            title={
                                              polling
                                                ? "Some documents still in progress"
                                                : "Get answer for all documents on page"
                                            }
                                          >
                                            <IconButton
                                              size="small"
                                              disabled={polling}
                                              onClick={() =>
                                                handleRunPage(column.id)
                                              }
                                            >
                                              <RotateRightOutlinedIcon fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip
                                            placement="top"
                                            enterDelay={500}
                                            title="Edit column"
                                          >
                                            <IconButton
                                              size="small"
                                              onClick={() =>
                                                handleEdit(column.id)
                                              }
                                            >
                                              <EditOutlinedIcon fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip
                                            placement="top"
                                            enterDelay={500}
                                            title="Delete column"
                                          >
                                            <IconButton
                                              size="small"
                                              onClick={() =>
                                                setConfirmColumnDeletion(column)
                                              }
                                            >
                                              <DeleteOutlineOutlinedIcon fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      )}
                                    </Box>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </Box>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                  <Box className="table-body">
                    {documentsToShow.map((document) => (
                      <Box className="table-row" key={document.id}>
                        <Box className="cell-container">
                          <Box className="main-cell">
                            <AiTableDocument
                              readOnly={readOnly}
                              selectedDocuments={selectedDocuments}
                              setSelectedDocuments={setSelectedDocuments}
                              document={document}
                              setTableDocumentToUpdate={
                                setTableDocumentToUpdate
                              }
                            />
                          </Box>
                        </Box>
                        {columns?.map((column) => {
                          const data = getData(document.id, column.id);
                          const isLoading =
                            data &&
                            !terminal_status_names.includes(data?.task_status);
                          const isFailed =
                            data &&
                            terminal_status_names.includes(data?.task_status) &&
                            !["success", "None"].includes(
                              data?.task_status || ""
                            );
                          return (
                            <Box
                              className={clsx("cell-container", {
                                dragging: isColumnDragging,
                              })}
                              key={column.id}
                            >
                              {data && (
                                <>
                                  {data.response && !isLoading && (
                                    <ReactMarkdown
                                      disallowedElements={[
                                        "table, tbody, thead, td, tr, th, pre, code",
                                      ]}
                                      remarkPlugins={[remarkGfm, breaks]}
                                    >
                                      {data.response}
                                    </ReactMarkdown>
                                  )}
                                  {isFailed && !data.response && !isLoading && (
                                    <Box className="status">
                                      <Typography
                                        variant="body2"
                                        className="error-message"
                                      >
                                        Our servers are currently overloaded,
                                        please try again later. Thank you for
                                        your patience as we continue to scale
                                        our services.
                                      </Typography>
                                    </Box>
                                  )}
                                  {isLoading && (
                                    <Box className="status">
                                      <CircularProgress size="small" />
                                    </Box>
                                  )}
                                </>
                              )}
                              {!isLoading && !isColumnDragging && (
                                <Box className="get-answer-box">
                                  {data?.response && !isFailed && (
                                    <IconButton
                                      className="copy-button"
                                      size="small"
                                      aria-label="Copy cell text"
                                      color="primary"
                                      onClick={() =>
                                        copyTextToClipboard(
                                          data.response,
                                          data.id
                                        )
                                      }
                                    >
                                      {copied === data.id ? (
                                        <CheckIcon fontSize="small" />
                                      ) : (
                                        <ContentCopyIcon fontSize="small" />
                                      )}
                                    </IconButton>
                                  )}
                                  {!readOnly && (
                                    <>
                                      <IconButton
                                        className="edit-button"
                                        size="small"
                                        aria-label="Edit cell text"
                                        color="primary"
                                        onClick={() =>
                                          handleCellEdit(column.id, document.id)
                                        }
                                      >
                                        <EditOutlinedIcon fontSize="small" />
                                      </IconButton>
                                      {data?.meta?.finish_reason ===
                                        "length" && (
                                        <Button
                                          className="continue-button"
                                          size="small"
                                          color="primary"
                                          variant="contained"
                                          onClick={() => {
                                            handleContinueResponse(
                                              document.id,
                                              column.id
                                            );
                                          }}
                                        >
                                          Continue generating
                                        </Button>
                                      )}
                                      <Button
                                        className="new-answer"
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                          handleRunCell(document.id, column.id);
                                        }}
                                      >
                                        {isFailed && !data
                                          ? "Try again"
                                          : data
                                          ? "Get new answer"
                                          : "Get answer"}
                                      </Button>
                                    </>
                                  )}
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
              {!readOnly && (
                <Box className="add-container">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick={handleAdd}
                    startIcon={<AddIcon fontSize="medium" />}
                  >
                    Add question
                  </Button>
                </Box>
              )}
            </Box>
          ) : (
            <LoadingOverlay />
          )}
        </Container>
      ) : (
        <ErrorWrapper>
          <img src="/img/no-documents.svg" alt="No documents" />
          {!readOnly ? (
            <>
              <Typography variant="h6" color="textSecondary" fontWeight={400}>
                Add sources to Template Library
              </Typography>
              <Button
                color="primary"
                variant="contained"
                size="medium"
                startIcon={<AddIcon fontSize="medium" />}
                onClick={() => {
                  setOpenAddContextDialog(true);
                }}
              >
                Add sources
              </Button>
            </>
          ) : (
            <Typography variant="h6" color="textSecondary" fontWeight={400}>
              No documents found within the table
            </Typography>
          )}
        </ErrorWrapper>
      )}
      <AiTableColumnDialog
        table={table}
        open={columnDialogIsOpen}
        setOpen={(open) => {
          setColumnDialogIsOpen(open);
          if (columnIdToEdit) {
            setColumnIdToEdit(undefined);
          }
        }}
        columnId={columnIdToEdit}
      />
      <AiTableCellDialog
        table={table}
        open={cellDialogIsOpen}
        setOpen={(open) => {
          setCellDialogIsOpen(open);
          if (cellInfoToEdit) {
            setCellInfoToEdit({ cellId: undefined, colId: -1, docId: -1 });
          }
        }}
        cellId={cellInfoToEdit ? cellInfoToEdit.cellId : undefined}
        columnId={cellInfoToEdit ? cellInfoToEdit.colId : -1}
        documentId={cellInfoToEdit ? cellInfoToEdit.docId : -1}
      />
      <AiTableCreditEstimateDialog
        creditEstimate={creditEstimateNumber}
        proceed={(proceed: boolean) => {
          if (proceed) {
            runCells();
            setCreditEstimateNumber(undefined);
          } else {
            setCreditEstimateNumber(undefined);
            setDataToRun(undefined);
          }
        }}
      />
      <AiTableOverwriteDialog
        data={dataToRun}
        open={openOverwiteDialog}
        proceed={(proceed, emptyCellsOnly) => {
          if (dataToRun) {
            if (proceed) {
              if (emptyCellsOnly) {
                setDataToRun({ ...dataToRun, emptyCellsOnly });
                setRunCellCreditEstimate(true);
              } else {
                setRunCellCreditEstimate(true);
              }
              setOpenOverWriteDialog(false);
            } else {
              setDataToRun(undefined);
              setOpenOverWriteDialog(false);
            }
          }
          setOpenOverWriteDialog(false);
        }}
      />
      {openAddContextDialog && (
        <AddAIContextDialog
          setOpen={() => {
            setOpenAddContextDialog(false);
          }}
          addSelectedSources={addDocumentSources}
        />
      )}
      {createNewTableWithIds && (
        <AITableDialog
          documentIds={createNewTableWithIds}
          setOpen={(newTable) => {
            if (newTable) {
              navigate(`/browse/ai-table/${newTable.id}`);
            }
            setCreateNewTableWithIds(undefined);
          }}
        />
      )}
      {tableDocumentToUpdate && (
        <TableSourceDialog
          document={tableDocumentToUpdate}
          close={() => {
            setTableDocumentToUpdate(undefined);
          }}
        />
      )}
      {!readOnly &&
        documentsToShow.length > 0 &&
        !user?.meta?.citeOnboarding?.aiTable?.completed && (
          <AiTableOnboarding columns={columns} />
        )}
      {/* document deletion */}
      <ConfirmationDialog
        show={confirmRemovingSelectedSources}
        title={`Remove ${selectedDocuments.length} document(s)`}
        description="Are you sure you want to remove these documents? All data in the table linked to these docs will be gone."
        sensitive
        buttonText="Remove"
        onClose={(confirm?: boolean) => {
          setConfirmRemovingSelectedSources(false);
          if (confirm) {
            setTimeout(() => {
              removeSelectedSources();
            }, 300);
          }
        }}
      />
      {/* column deletion */}
      <ConfirmationDialog
        show={!!confirmColumnDeletion}
        title={`Delete ${confirmColumnDeletion?.name} column`}
        description="Are you sure you want to delete table column? All data in the table linked to these column will be gone."
        sensitive
        buttonText="Delete"
        onClose={(confirm?: boolean) => {
          const columnToDelete = confirmColumnDeletion;
          setConfirmColumnDeletion(undefined);
          if (confirm && columnToDelete) {
            setTimeout(() => {
              handleRemove(columnToDelete.id);
            }, 300);
          }
        }}
      />
    </Wrapper>
  );
};

const TableContainer: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { aiTables } = useAITables(currentOrganizationId);
  const { getCachedOrganizationById } = useOrganizations(user?.id);
  const { users } = useUsers(currentOrganizationId);
  const { organizationUsage } = useOrganizationUsage(currentOrganizationId);
  const tableId = parseInt(params?.id || "-1", 10);
  const [currentOrganization] = getCachedOrganizationById(
    currentOrganizationId || -1
  );
  const [currentTable, setCurrentTable] = useState<AITable | undefined>(
    undefined
  );
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const readOnly =
    (!organizationUsage?.plans?.some((plan) =>
      plan.lookup_key.includes("plan")
    ) &&
      !isTrialExist(currentOrganization?.created_at)) ||
    isGuest(currentUserRole);

  const noAccess = readOnly && aiTables && aiTables.length === 0;

  useEffect(() => {
    if (currentOrganization && noAccess) {
      navigate(routePaths.aiTables);
    }
  }, [currentOrganization, noAccess]);

  useEffect(() => {
    if (aiTables && tableId) {
      const tableToUse = aiTables.find((table) => table.id === tableId);
      if (tableToUse && !currentTable) {
        setCurrentTable(tableToUse);
      } else if (currentTable?.id !== tableToUse?.id) {
        navigate(routePaths.aiTables);
      }
    }
  }, [aiTables, tableId]);

  if (!currentTable || noAccess) {
    return <MainLoader />;
  }

  return (
    <SelectedTable
      table={currentTable}
      setTable={setCurrentTable}
      readOnly={readOnly}
    />
  );
};

export default TableContainer;
