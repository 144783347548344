/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import clsx from "clsx";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import {
  AICreateTemplateBlock,
  AICreateTemplateBlockList,
} from "models/api/response.types";
import LoadingOverlay from "components/helpers/LoadingOverlay";
import { LoadingButton } from "@mui/lab";
import aiCreateService, {
  useAICreateTemplateBlocks,
} from "api/aiCreateService";
import { useDispatch } from "react-redux";
import { addAlert } from "store/features/general/slice";
import reorder from "utils/reorder";
import AICreateConfirmTemplateBlockDeletion from "components/Dialogs/AICreate/AICreateConfirmTemplateBlockDeletion";
import { terminal_status_names } from "utils/aiHelpers";
import { useQueryClient } from "@tanstack/react-query";
import { AiCreateWriteOnboarding } from "components/Onboarding/AiCreateOnboarding";
import TextBlockContentType from "./TextBlockContentType";
import AIPromptBlockContentType from "./AIPromptBlockContentType";

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  flex: 1,
  overflow: "auto",
  display: "flex",
  "& .main-container": {
    margin: "0 auto",
    width: "100%",
    maxWidth: "1500px",
    "& .block-container": {
      display: "flex",
      alignItems: "start",
      gap: "1rem",
      padding: "0.5rem 1rem",
      transition: "background-color 0.3s",
      "&.active": {
        backgroundColor: theme.grey.light,
        borderRadius: "4px",
        "& .drag-indicator": {
          opacity: 1,
        },
      },
      "& .drag-indicator": {
        opacity: 0,
        cursor: "pointer",
        transition: "opacity 0.3s",
        outline: "none",
        "& svg": {
          fill: theme.grey.main,
        },
      },
      "& .block-main-content": {
        flex: 1,
        border: `1px solid ${theme.grey.light}`,
        borderRadius: "4px",
        backgroundColor: theme.background.light,
        padding: "1rem",
        display: "flex",
        gap: "1rem",
        "&.failed": {
          borderLeft: "2px solid",
          borderLeftColor: theme.red.main,
          borderTopLeftRadius: "0",
          borderBottomLeftRadius: "0",
        },
        "& .block-content": {
          flex: 1,
        },
        alignItems: "flex-start",
      },
    },
  },
  "& .error-message": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    "& img": {
      width: "250px",
      [theme.breakpoints.down("lg")]: {
        width: "200px",
      },
      [theme.breakpoints.down("md")]: {
        width: "150px",
      },
    },
  },
}));

const Write: React.FC<{
  projectId: number;
  blocks?: AICreateTemplateBlockList;
}> = ({ blocks, projectId }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { upsertCachedAICreateTemplateBlock, aiCreateBlocksQueryKey } =
    useAICreateTemplateBlocks(projectId);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [activeBlockIndex, setActiveBlockIndex] = useState<number | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [templateCreation, setTemplateCreation] = useState<boolean>(false);
  const [newBlockOrder, setNewBlockOrder] = useState<
    AICreateTemplateBlockList | undefined
  >(undefined);
  const [blockToDelete, setBlockToDelete] = useState<number | undefined>(
    undefined
  );

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    if (blocks && blocks?.length > 0 && templateCreation) {
      setTemplateCreation(false);
    }
  }, [blocks]);

  useEffect(() => {
    let intervalId: any;
    if (templateCreation) {
      intervalId = setInterval(() => {
        aiCreateService.fetchAICreateProject(projectId).then(({ data }) => {
          const { generate_template_task_status } = data;
          if (terminal_status_names.includes(generate_template_task_status)) {
            if (generate_template_task_status !== "success") {
              setTemplateCreation(false);
              dispatch(
                addAlert({
                  severity: "error",
                  autoHideDuration: 5000,
                  alert: {
                    message:
                      "Error for generating custom template. Please try again later.",
                  },
                })
              );
            } else {
              queryClient.invalidateQueries(aiCreateBlocksQueryKey);
            }
          }
        });
      }, 3000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [templateCreation]);

  const addBlock = (block: "text" | "ai") => {
    setLoading(true);
    aiCreateService
      .createAICreateTemplateBlock(projectId, {
        ai_example: "",
        ai_prompt: "",
        block_type: block,
        content: "",
        // by omitting sort_order, the backend will place new block at the end
      })
      .then(({ data }) => {
        setLoading(false);
        upsertCachedAICreateTemplateBlock(data);
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          addAlert({
            severity: "error",
            autoHideDuration: 5000,
            alert: {
              message: "Something went wrong. Please try again later.",
            },
          })
        );
      });
  };

  const generateAITemplate = () => {
    aiCreateService
      .generateAICreateTemplate(projectId)
      .then(() => {
        setTemplateCreation(true);
      })
      .catch(() => {
        dispatch(
          addAlert({
            severity: "error",
            autoHideDuration: 5000,
            alert: {
              message: "Something went wrong. Please try again later.",
            },
          })
        );
      });
  };

  const templateBlocks = useMemo(() => {
    if (newBlockOrder) {
      return newBlockOrder;
    }
    if (blocks) {
      return blocks;
    }
    return [];
  }, [blocks, newBlockOrder]);

  const blockDragEnd = (response: DropResult) => {
    const { destination, source } = response;
    if (destination && templateBlocks) {
      const block = templateBlocks[source.index];
      const newOrder = reorder(templateBlocks, source.index, destination.index);
      setNewBlockOrder(newOrder);
      const prevBlock = newOrder[destination.index - 1];
      const nextBlock = newOrder[destination.index + 1];

      let newOrderId;
      if (prevBlock && nextBlock) {
        newOrderId = (prevBlock.sort_order + nextBlock.sort_order) / 2;
      } else if (prevBlock) {
        newOrderId = prevBlock.sort_order + 1;
      } else if (nextBlock) {
        newOrderId = (0 + nextBlock.sort_order) / 2;
      } else {
        newOrderId = 1;
      }
      aiCreateService
        .updateAICreateTemplateBlock(projectId, block.id, {
          sort_order: newOrderId,
        })
        .then(({ data }) => {
          upsertCachedAICreateTemplateBlock(data);
          setTimeout(() => {
            setNewBlockOrder(undefined);
          }, 1000);
        });
    }
  };

  if (!blocks || templateCreation) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <Wrapper>
        {templateBlocks.length > 0 ? (
          <Box className="main-container">
            <DragDropContext onDragEnd={blockDragEnd}>
              <Droppable direction="vertical" droppableId="droppableBlocks">
                {(provided) => (
                  <Box ref={provided.innerRef} {...provided.droppableProps}>
                    {templateBlocks.map(
                      (block: AICreateTemplateBlock, blockIndex: number) => {
                        const isFailed =
                          terminal_status_names.includes(
                            block.response_task_status
                          ) && block.response_task_status !== "success";
                        return (
                          <Draggable
                            key={block.id}
                            draggableId={block.id.toString()}
                            index={blockIndex}
                            disableInteractiveElementBlocking
                          >
                            {(secondaryProvided) => (
                              <Box
                                className={clsx("block-container", {
                                  active: activeBlockIndex === blockIndex,
                                  first: blockIndex === 0,
                                })}
                                ref={secondaryProvided.innerRef}
                                {...secondaryProvided.draggableProps}
                                onMouseOver={() =>
                                  setActiveBlockIndex(blockIndex)
                                }
                                onMouseLeave={() =>
                                  setActiveBlockIndex(undefined)
                                }
                              >
                                <Box
                                  {...secondaryProvided.dragHandleProps}
                                  className="drag-indicator"
                                >
                                  <DragIndicatorOutlinedIcon fontSize="large" />
                                </Box>
                                <Box
                                  className={clsx("block-main-content", {
                                    failed: isFailed,
                                  })}
                                >
                                  {block.block_type === "text" && (
                                    <TextBlockContentType block={block} />
                                  )}
                                  {block.block_type === "ai" && (
                                    <AIPromptBlockContentType block={block} />
                                  )}
                                  <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      setBlockToDelete(block.id);
                                    }}
                                  >
                                    <DeleteOutlineOutlinedIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              </Box>
                            )}
                          </Draggable>
                        );
                      }
                    )}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
            <Button
              className="add-new-block"
              startIcon={<AddCircleOutlineIcon fontSize="small" />}
              onClick={handleOpenMenu}
              sx={{
                width: "fit-content",
                marginLeft: "3.5rem",
                marginTop: "1rem",
              }}
            >
              <Typography variant="body2">Add new block</Typography>
            </Button>
          </Box>
        ) : (
          <Box className="error-message">
            <img src="/img/add-documents.svg" alt="Add documents" />
            <Typography variant="h6" color="textSecondary">
              There are no blocks in your project
            </Typography>
            <Typography variant="body1" color="textSecondary" fontWeight={400}>
              Start adding blocks
            </Typography>
            <Box>
              <LoadingButton
                variant="contained"
                color="primary"
                size="medium"
                loading={loading}
                onClick={handleOpenMenu}
              >
                Add Custom Block
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="primary"
                size="medium"
                onClick={generateAITemplate}
                sx={{
                  marginLeft: "1rem",
                }}
              >
                Add AI Generated Template
              </LoadingButton>
            </Box>
          </Box>
        )}
        <Menu
          anchorEl={menuAnchorEl}
          open={!!menuAnchorEl}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => {
              addBlock("text");
              handleCloseMenu();
            }}
          >
            <Typography variant="body2">Text</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              addBlock("ai");
              handleCloseMenu();
            }}
          >
            <Typography variant="body2">AI Prompt</Typography>
          </MenuItem>
        </Menu>
      </Wrapper>
      <AICreateConfirmTemplateBlockDeletion
        projectId={projectId}
        blockId={blockToDelete}
        close={() => {
          setBlockToDelete(undefined);
        }}
      />
      <AiCreateWriteOnboarding />
    </>
  );
};

export default Write;
