import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import { useOrganizationUsage } from "api/organizationService";
import UsageBar from "components/helpers/UsageBar";

const AiTableCreditEstimateDialog: React.FC<{
  creditEstimate?: number;
  proceed: (proceed: boolean) => void;
}> = ({ creditEstimate = 0, proceed }) => {
  const theme = useTheme();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { organizationUsage, usageIsLoading } = useOrganizationUsage(
    currentOrganizationId
  );
  const limitExceeded = () => {
    if (
      organizationUsage &&
      organizationUsage.usage_limits.ai_credits - creditEstimate < 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <Dialog open={Boolean(creditEstimate)} fullWidth maxWidth="sm">
      <DialogTitle>
        Verify credit use
        <IconButton onClick={() => proceed(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {organizationUsage && (
          <UsageBar
            loading={usageIsLoading}
            data={[
              {
                label: `Credits to use (${creditEstimate})`,
                percent:
                  (creditEstimate / organizationUsage.usage_limits.ai_credits) *
                  100,
                color: theme.palette.warning.light,
              },
              {
                label: `Available credits (${organizationUsage.usage_limits.ai_credits})`,
                percent: 100,
                color: theme.palette.info.main,
              },
            ]}
          />
        )}
        {limitExceeded() && (
          <Alert sx={{ mt: 2 }} severity="error">
            Credit limit has been exceeded. Add more credits to your workspace
            or wait until the next day, when your workspace will automatically
            be credited with{" "}
            {organizationUsage?.usage_limits.ai_credit_replenish} credits.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="text"
          size="medium"
          onClick={() => proceed(false)}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="medium"
          disabled={limitExceeded()}
          onClick={() => proceed(true)}
        >
          Ask question
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AiTableCreditEstimateDialog;
