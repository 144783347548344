/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccessTokenResponse } from "models/api/response.types";
import { IState } from "store/reducers";
import { v4 as uuidv4 } from "uuid";

export interface SessionState {
  user: AccessTokenResponse | undefined;
  currentOrganizationId: number | undefined;
  sessionId: string;
  enableTelemetry: boolean;
}

export const initialState: SessionState = {
  user: undefined,
  currentOrganizationId: undefined,
  sessionId: uuidv4(),
  enableTelemetry: true,
};

// session slice is a slice that used for user session
const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<AccessTokenResponse | undefined>) {
      state.user = action.payload;
    },
    setCurrentOrganizationId(state, action: PayloadAction<number | undefined>) {
      state.currentOrganizationId = action.payload;
    },
    setSessionId(state, action) {
      state.sessionId = action.payload;
    },
  },
});

export const { reducer } = sessionSlice;
export const { setUser, setCurrentOrganizationId, setSessionId } =
  sessionSlice.actions;

export const selectSessionState = createSelector(
  (state: IState) => state.session,
  (item) => item
);

export const selectUser = createSelector(
  (state: IState) => state.session.user,
  (item) => item
);

export const selectCurrentOrganizationId = createSelector(
  (state: IState) => state.session.currentOrganizationId,
  (item) => item
);

export const selectSessionId = createSelector(
  (state: IState) => state.session.sessionId,
  (item) => item
);

export const selectEnableTelemetry = createSelector(
  (state: IState) => state.session.enableTelemetry,
  (item) => item
);

export const cleanup = (): { type: "session/cleanup" } => ({
  type: "session/cleanup",
});

export const logAsAdmin = (
  userData: AccessTokenResponse
): { type: "session/log_as_admin"; userData: AccessTokenResponse } => ({
  type: "session/log_as_admin",
  userData,
});

export const updateAppVerison = (
  sessionState: SessionState
): {
  type: "session/update_app_version";
  sessionState: SessionState;
} => ({
  type: "session/update_app_version",
  sessionState,
});
