import React from "react";
import { Box, Skeleton, styled } from "@mui/material";
import { useDocumentCollections } from "api/documentCollectionService";
import { useSelector } from "react-redux";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import ToolbarCollectionSelector from "./ToolbarCollectionSelector";
import SearchBar from "./SearchBar";

const Wrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    gap: "1rem",
    justifyContent: "center",
  },
  flexWrap: "wrap",
  "& .coll-selector": {
    flex: 0.3,
  },
  "& .search-selector": {
    flex: 0.6,
    [theme.breakpoints.down("md")]: {
      flex: 1,
    },
  },
}));

const DocumentToolbar: React.FC = () => {
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { collections } = useDocumentCollections(currentOrganizationId);

  return (
    <Wrapper>
      {collections ? (
        <>
          <ToolbarCollectionSelector />
          <SearchBar />
        </>
      ) : (
        <>
          <Skeleton className="coll-selector" variant="rounded" height={36} />
          <Skeleton className="search-selector" variant="rounded" height={36} />
        </>
      )}
    </Wrapper>
  );
};

export default DocumentToolbar;
