/* eslint-disable prefer-destructuring */
export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name?: string) => {
  let a = "";
  let b = "";
  if (name) {
    try {
      a = name.split(" ")[0][0];
      b = name.split(" ")[1][0];
    } catch (e) {
      // console.error(e); // TypeError: Cannot read properties of undefined (reading '0')
    }
  }

  return {
    sx: {
      bgcolor: stringToColor(name || ""),
    },
    children: name ? `${a}${b}` : "",
  };
};
