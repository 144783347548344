/* eslint-disable import/prefer-default-export */
export const academicStatusList = [
  "Student - Bachelor",
  "Student - Master",
  "Student - Doctoral Student",
  "Student - Ph. D. Student",
  "Student - Postgraduate",
  "Professor",
  "Associate Professor",
  "Researcher",
  "Lecturer",
  "Librarian",
  "Unspecified",
];

export const disciplineList = [
  "Agricultural and Biological Sciences",
  "Arts and Humanities",
  "Biochemistry, Genetics and Molecular Biology",
  "Business, Management and Accounting",
  "Chemical Engineering",
  "Chemistry",
  "Computer Science",
  "Decision Sciences",
  "Design",
  "Earth and Planetary Sciences",
  "Economics, Econometrics and Finance",
  "Energy",
  "Engineering",
  "Environmental Science",
  "Immunology and Microbiology",
  "Linguistics",
  "Materials Science",
  "Mathematics",
  "Medicine and Dentistry",
  "Neuroscience",
  "Nursing and Health Professions",
  "Pharmacology, Toxicology and Pharmaceutical Science",
  "Philosophy",
  "Physics and Astronomy",
  "Psychology",
  "Social Sciences",
  "Sports and Recreations",
  "Veterinary Science and Veterinary Medicine",
  "Unspecified",
];
