import { DocumentCollection } from "models/api/response.types";

// returns only Id
export const searchChildCollectionTree = (
  currentId: number,
  collections?: DocumentCollection[]
) => {
  if (collections) {
    let array: any[] = [currentId];
    const search = (id: number) => {
      const childs = collections.filter((coll) => coll.parent_id === id);
      const childIds = childs.map((c) => c.id);
      if (childIds.length > 0) {
        array = [...array, ...childIds];
        childs.map((coll) => search(coll.id));
      }
    };
    search(currentId);
    return array;
  }

  return [];
};

// get explicit path like hello -> world -> one
export const searchParentCollectionTreeName = (
  currentColl: DocumentCollection,
  collections?: DocumentCollection[]
) => {
  if (collections) {
    let array: string[] = [currentColl.name];
    const search = (c: DocumentCollection) => {
      const parentIdx = collections.findIndex(
        (coll) => coll.id === c.parent_id
      );
      if (parentIdx > -1) {
        array = [collections[parentIdx].name, ...array];
        search(collections[parentIdx]);
      }
    };
    search(currentColl);
    return array;
  }
  return [];
};

// get explicit path like hello -> world -> one
export const searchParentCollectionTreeNumber = (
  currentColl: DocumentCollection,
  collections?: DocumentCollection[]
) => {
  if (collections) {
    let array: number[] = [currentColl.id];
    const search = (c: DocumentCollection) => {
      const parentIdx = collections.findIndex(
        (coll) => coll.id === c.parent_id
      );
      if (parentIdx > -1) {
        array = [collections[parentIdx].id, ...array];
        search(collections[parentIdx]);
      }
    };
    search(currentColl);
    return array;
  }
  return [];
};
