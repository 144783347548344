/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Drawer,
  IconButton,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AISession,
  DocumentReadResponse,
  Tag,
} from "models/api/response.types";
import AddIcon from "@mui/icons-material/Add";
import AddAIContextDialog from "components/Dialogs/AddAIContextDialog/AddAIContextDialog";
import { useAISessions } from "api/aiService";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import { useSelector } from "react-redux";
import { useDocuments } from "api/documentService";
import {
  AutoSizer,
  ListRowProps,
  List as VirtualizedList,
} from "react-virtualized";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AIContextDialogDocuments from "components/Dialogs/AddAIContextDialog/AIContextDialogDocument";
import AISessionDialog from "components/Dialogs/AISessionDialog";
import { ISortOrder, sortOptions } from "utils/documentSort";
import { selectTagsModeBrowse } from "store/features/browser/slice";
import { tagsModes } from "utils/tagsModes";
import TableSourceDialog from "components/Dialogs/TableSourceDialog/TableSourceDialog";
import DocumentFilter, {
  DocumentFilters,
  defaultDocumentFiltersData,
} from "../Documents/DocumentFilter";
import SortDocumentMenu from "../Documents/SortDocumentMenu";

interface DrawerProps {
  drawerwidth: string;
}

const SideBarDrawer = styled(Drawer)<DrawerProps>(
  ({ drawerwidth, theme, open }) => ({
    width: drawerwidth,
    position: "fixed",
    right: 0,
    top: 0,
    height: "100%",
    transition: theme.transitions.create(["width", "opacity", "transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(!open && {
      transform: "translateX(100%)",
    }),
    "& .MuiDrawer-paper": {
      width: "100%",
      boxSizing: "border-box",
      background: theme.background.secondary,
      border: "none",
      position: "absolute",
      overflow: "hidden",
      boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
      "& .drawer-container": {
        padding: "1rem 0.5rem",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        "& .header": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 0.5rem",
          marginBottom: "0.5rem",
        },
        "& .context-container": {
          background: theme.background.light,
          border: `1px solid ${theme.grey.light}`,
          flex: 1,
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
          "& .document-container-actions": {
            padding: theme.spacing(1),
            gap: theme.spacing(1),
            borderBottom: `1px solid ${theme.grey.light}`,
            display: "flex",
            alignItems: "center",
            "& .document-actions": {
              marginLeft: "auto",
              "& .remove-context-button": {
                marginRight: "0.5rem",
              },
            },
          },
          "& .document-list": {
            flex: 1,
          },
          "& .document-container-info": {
            padding: theme.spacing(1),
            gap: theme.spacing(1),
            borderTop: `1px solid ${theme.grey.light}`,
            display: "flex",
            flexDirection: "column",
            "& .MuiTypography-root": {
              padding: `0 ${theme.spacing(1)}`,
              color: "#6c6c6c",
            },
          },
        },
        "& .no-rows": {
          marginTop: "2rem",
        },
      },
    },
  })
);

const AIChatContextSidebar: React.FC<{
  open: boolean;
  addSources: boolean;
  selectedSession?: AISession;
  setSelectedSession: (session: AISession) => void;
  setAddSources: (add: boolean) => void;
  setOpen: (open: boolean) => void;
}> = ({
  addSources,
  open,
  setOpen,
  setAddSources,
  selectedSession,
  setSelectedSession,
}) => {
  const theme = useTheme();
  const organizationId = useSelector(selectCurrentOrganizationId);
  const tagsModeBrowse = useSelector(selectTagsModeBrowse);
  const { updateAISessionMutation } = useAISessions(organizationId as number);
  const { documents, getCachedDocumentById } = useDocuments(organizationId);
  const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const drawerWidth = xlScreen ? "700px" : smScreen ? "500px" : "100%";
  const [documentSort, setDocumentSort] = useState<ISortOrder>({
    key: "date-created",
    order: "descending",
  });
  const [totalCharacters, setTotalCharacters] = useState<number>(0);
  const [canClickAway, setCanClickAway] = useState<boolean>(false);
  const [openAddContextDialog, setOpenAddContextDialog] =
    useState<boolean>(false);
  const [openAiSessionDialog, setOpenAiSessionDialog] =
    useState<boolean>(false);
  const [tableDocumentToUpdate, setTableDocumentToUpdate] = useState<
    DocumentReadResponse | undefined
  >(undefined);
  const [selectedDocuments, setSelectedDocuments] = useState<
    DocumentReadResponse[]
  >([]);
  // all filters options
  const [documentFilters, setDocumentFilters] = useState<DocumentFilters>(
    defaultDocumentFiltersData
  );
  // selected filters options
  const [selectedDocumentFilters, setSelectedDocumentFilters] =
    useState<DocumentFilters>(defaultDocumentFiltersData);
  const [documentsForFilter, setDocumentsForFilter] = useState<
    DocumentReadResponse[]
  >([]);
  const defaultRowHeight = 60;

  const onClose = () => {
    setOpen(false);
    setCanClickAway(false);
    setAddSources(false);
    setSelectedDocuments([]);
    setOpenAiSessionDialog(false);
    setSelectedDocumentFilters(defaultDocumentFiltersData);
  };

  useEffect(() => {
    if (open && !canClickAway) {
      setCanClickAway(true);
    }
  }, [open]);

  useEffect(() => {
    if (!selectedSession || selectedSession?.document_ids?.length === 0) {
      onClose();
    }
  }, [selectedSession]);

  useEffect(() => {
    setOpenAddContextDialog(addSources);
  }, [addSources]);

  const updateSessionContext = (documentIds: number[]) => {
    if (selectedSession) {
      updateAISessionMutation.mutate(
        {
          sessionId: selectedSession.id,
          body: {
            document_ids: documentIds,
          },
        },
        {
          onSuccess: ({ data: newSession }) => {
            setSelectedSession(newSession);
          },
        }
      );
    }
  };

  const addSourcesToSession = (sources: DocumentReadResponse[]) => {
    if (selectedSession) {
      const idsToAdd = sources.map((src) => src.id);
      const newIds = new Set(selectedSession.document_ids);

      idsToAdd.forEach((id) => {
        if (!newIds.has(id)) {
          newIds.add(id);
        }
      });
      updateSessionContext(Array.from(newIds));
      setAddSources(false);
      setOpenAddContextDialog(false);
    }
  };

  const removeDocumentsContext = () => {
    if (selectedSession?.document_ids) {
      const ids = selectedDocuments.map((doc) => doc.id);
      const documentIds = selectedSession.document_ids.filter(
        (id) => !ids.includes(id)
      );
      updateSessionContext(documentIds);
    }
  };

  const applyFilters = (docs: DocumentReadResponse[]) => {
    const result = docs
      .filter((item) =>
        selectedDocumentFilters.titles.length > 0
          ? selectedDocumentFilters.titles.includes(item?.meta?.title)
          : `${item}`
      )
      .filter((item) => {
        if (selectedDocumentFilters.authors.length) {
          const authors = new Set<string>();
          if (Array.isArray(item.meta.author)) {
            item.meta.author.forEach((author) => authors.add(author));
          } else if (item.meta.author) {
            if (item.meta.author.includes(" and ")) {
              (item.meta.author as string)
                .split(" and ")
                .forEach((value) => value && authors.add(value));
            } else if (item.meta.author.includes(" AND ")) {
              (item.meta.author as string)
                .split(" AND ")
                .forEach((value) => value && authors.add(value));
            } else {
              authors.add(item.meta.author);
            }
          }
          return selectedDocumentFilters.authors.filter((author) => {
            return Array.from(authors).includes(author);
          }).length;
        }
        return item;
      })
      .filter(
        (item) =>
          selectedDocumentFilters.journals.length
            ? selectedDocumentFilters.journals.includes(item.meta.journal)
            : `${item}`,
        false
      )
      .filter((item) => {
        if (selectedDocumentFilters.years.length) {
          return selectedDocumentFilters.years.includes(
            parseInt(item.meta.year, 10)
          );
        }
        return item;
      })
      .filter((item: DocumentReadResponse) => {
        if (selectedDocumentFilters?.tags?.length) {
          const documentTagIds = new Set(item.tag_ids);
          const intersection = selectedDocumentFilters.tags.filter((tag: Tag) =>
            documentTagIds.has(tag.id)
          );
          if (tagsModeBrowse === tagsModes.or && intersection.length === 0) {
            return false;
          }
          if (
            tagsModeBrowse === tagsModes.and &&
            intersection.length < selectedDocumentFilters.tags.length
          ) {
            return false;
          }
          return true;
        }
        return item;
      });
    return result;
  };

  const sourcesToShow = useMemo(() => {
    if (selectedSession) {
      let total = 0;
      let documentsToUse: DocumentReadResponse[] = [];
      selectedSession.document_ids?.forEach((id) => {
        const [document] = getCachedDocumentById(id);
        if (document) {
          total += document?.meta?.text_character_count || 0;
          documentsToUse.push(document);
        }
      });
      documentsToUse = documentsToUse.sort((a, b) =>
        sortOptions[documentSort.key].compare(a, b, documentSort.order)
      );
      setTotalCharacters(total);
      setDocumentsForFilter(documentsToUse);
      return applyFilters(documentsToUse);
    }
    return [];
  }, [selectedSession, documentSort, selectedDocumentFilters, documents]);

  // render rows from react-virtualized
  const rowRenderer = ({ index, key, style }: ListRowProps) => {
    return (
      <AIContextDialogDocuments
        key={key}
        style={style}
        document={sourcesToShow[index]}
        setTableDocumentToUpdate={setTableDocumentToUpdate}
        selectedDocuments={selectedDocuments || []}
        setSelectedDocuments={(docs: DocumentReadResponse[]) => {
          setSelectedDocuments(docs);
        }}
        canSelect
      />
    );
  };

  return (
    <>
      <SideBarDrawer
        drawerwidth={drawerWidth}
        variant="persistent"
        anchor="right"
        open={open}
        className="right-side-bar"
      >
        <Box className="drawer-container">
          <Box className="header">
            <Typography variant="body1" fontWeight={500}>
              Source documents
            </Typography>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box className="context-container">
            <Box className="document-container-actions">
              <Checkbox
                color="primary"
                className="document-checkbox"
                size="small"
                disabled={sourcesToShow.length === 0}
                checked={selectedDocuments.length > 0}
                indeterminate={
                  selectedDocuments.length > 0 &&
                  selectedDocuments.length < sourcesToShow.length
                }
                onChange={() => {
                  if (selectedDocuments.length > 0) {
                    setSelectedDocuments([]);
                  } else {
                    setSelectedDocuments(sourcesToShow);
                  }
                }}
              />
              <SortDocumentMenu
                documentSort={documentSort}
                setSortValue={setDocumentSort}
              />
              <DocumentFilter
                selectedFilters={selectedDocumentFilters}
                filteredDocuments={documentsForFilter}
                filters={documentFilters}
                setFilters={setDocumentFilters}
                setSelectedDocumentFilters={setSelectedDocumentFilters}
                setSelectedDocuments={setSelectedDocuments}
              />
              <Box className="document-actions">
                {selectedDocuments.length > 0 && (
                  <>
                    <Button
                      className="remove-context-button"
                      size="small"
                      startIcon={<MessageOutlinedIcon fontSize="small" />}
                      onClick={() => {
                        setOpenAiSessionDialog(true);
                      }}
                    >
                      Create new
                    </Button>
                    <Button
                      className="remove-context-button"
                      size="small"
                      startIcon={<DeleteOutlineOutlinedIcon fontSize="small" />}
                      onClick={removeDocumentsContext}
                    >
                      Remove
                    </Button>
                  </>
                )}
                <Button
                  className="add-context-button"
                  size="small"
                  startIcon={<AddIcon fontSize="small" />}
                  onClick={() => {
                    setOpenAddContextDialog(true);
                  }}
                >
                  Source
                </Button>
              </Box>
            </Box>
            <Box className="document-list">
              <AutoSizer>
                {({ width, height }) => (
                  <VirtualizedList
                    height={height}
                    overscanRowCount={15}
                    noRowsRenderer={() => {
                      return (
                        <Box className="no-rows">
                          <Typography
                            variant="body1"
                            fontWeight={500}
                            textAlign="center"
                            color="textSecondary"
                          >
                            No documents found
                          </Typography>
                        </Box>
                      );
                    }}
                    rowCount={sourcesToShow.length}
                    rowHeight={defaultRowHeight}
                    rowRenderer={rowRenderer}
                    width={width}
                  />
                )}
              </AutoSizer>
            </Box>
            <Box className="document-container-info">
              <Typography variant="body2">
                {totalCharacters.toLocaleString()} characters
              </Typography>
              {totalCharacters > 2000000 && (
                <Alert severity="warning">
                  AI performance may degrade if your documents exceed 2,000,000
                  characters total. Please consider using fewer documents or
                  splitting larger documents into smaller sections.
                </Alert>
              )}
            </Box>
          </Box>
        </Box>
      </SideBarDrawer>
      {openAddContextDialog && (
        <AddAIContextDialog
          setOpen={(close: boolean) => {
            setOpenAddContextDialog(close);
            setAddSources(close);
          }}
          addSelectedSources={addSourcesToSession}
        />
      )}
      {tableDocumentToUpdate && (
        <TableSourceDialog
          document={tableDocumentToUpdate}
          close={() => {
            setTableDocumentToUpdate(undefined);
          }}
        />
      )}
      {openAiSessionDialog && (
        <AISessionDialog
          isMultiDoc
          organizationId={organizationId as number}
          documentIds={selectedDocuments.map((doc) => doc.id)}
          setOpen={(_close: boolean, newSession) => {
            if (newSession) {
              setSelectedSession(newSession);
            }
            onClose();
          }}
        />
      )}
    </>
  );
};

export default AIChatContextSidebar;
