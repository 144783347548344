/* eslint-disable no-lone-blocks */
import React, { useEffect, useRef } from "react";
import {
  Box,
  Container,
  IconButton,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import routePaths from "routes/routePaths";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Organization } from "models/api/response.types";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentOrganizationId,
  selectUser,
  setCurrentOrganizationId,
  setUser,
} from "store/features/session/slice";
import { useOrganizations } from "api/organizationService";
import { setTitle } from "store/features/general/slice";
import Switcher from "components/WorkspaceSettings/Switcher/Switcher";
import { authService } from "api";
import SocketsProvider from "sockets/socket";
import useResizeObserver from "use-resize-observer";
import { setSideBarOpen } from "store/features/settings/slice";
import MainLoader from "components/helpers/MainLoader";

const Wrapper = styled(Container)(({ theme }) => ({
  height: "100%",
  display: "flex",
  padding: "0 !important",
  background: theme.background.light,
  "& .content": {
    borderRadius: "4px",
    flex: 1,
    overflow: "hidden",
    padding: `${theme.spacing(1.5)}`,
    [theme.breakpoints.up("lg")]: {
      padding: 0,
    },
    display: "flex",
    flexDirection: "column",
  },
}));

type ContextType = { org: Organization; containerRef: any };

export const useOrg = () => {
  return useOutletContext<ContextType>();
};

const WorkspaceSettings: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { settingsOrganizations: organizations, getCachedOrganizationById } =
    useOrganizations(user?.id);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const [currentOrganization] = getCachedOrganizationById(
    currentOrganizationId || -1
  );
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const settingsContainer = useRef<HTMLDivElement>(
    null
  ) as React.MutableRefObject<HTMLDivElement>;

  const sizesSettingsContainer = useResizeObserver({ ref: settingsContainer });

  useEffect(() => {
    dispatch(setTitle("Account settings"));
  }, []);

  // on org update need to update users data
  useEffect(() => {
    if (organizations && user) {
      authService.fetchUserInfo(user?.id).then(({ data }) => {
        dispatch(
          setUser({
            ...user,
            organization_ids: data.organization_ids,
            organization_roles: data.organization_roles,
          })
        );
      });
    }
  }, [organizations]);

  useEffect(() => {
    if (organizations) {
      const isValidOrg = organizations.some(
        (org) => org.id === currentOrganizationId
      );
      if (!isValidOrg) {
        const personalOrganization = organizations?.find(
          (org) => org.is_personal
        );
        if (personalOrganization) {
          dispatch(setCurrentOrganizationId(personalOrganization.id));
        } else {
          navigate(routePaths.logout);
        }
      }
    }
  }, [currentOrganizationId, organizations]);

  if (!currentOrganization) {
    return <MainLoader />;
  }

  return (
    <SocketsProvider>
      <Wrapper maxWidth="lg">
        <Switcher />
        <Box ref={settingsContainer} className="content">
          {smScreen && (
            <Box className="header">
              <IconButton
                className="sidebar-close-icon"
                size="medium"
                onClick={() => dispatch(setSideBarOpen(true))}
                color="primary"
              >
                <MenuOutlinedIcon fontSize="medium" color="action" />
              </IconButton>
            </Box>
          )}
          <Outlet
            context={{
              org: currentOrganization,
              containerRef: sizesSettingsContainer,
            }}
          />
        </Box>
      </Wrapper>
    </SocketsProvider>
  );
};

export default WorkspaceSettings;
