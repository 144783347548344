import React, { useEffect, useState } from "react";
import {
  Box,
  LinearProgress,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import * as zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import * as zxcvbnEnPackage from "@zxcvbn-ts/language-en";

interface IValidatorProps {
  password: string;
  error?: boolean;
  setIsValidPassword: (valid: boolean) => void;
  authRoute?: boolean;
}

const options = {
  translations: zxcvbnEnPackage.translations,
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  },
};

zxcvbnOptions.setOptions(options);

const SuggestionList = styled("ul")(() => ({
  color: "rgba(0, 0, 0, 0.6)",
  fontStyle: "italic",
  padding: "0 0 0 1rem",
  marginBottom: 0,
  "& .list-item": {
    fontSize: "14px",
  },
}));

const PasswordValidator: React.FC<IValidatorProps> = ({
  password,
  error,
  setIsValidPassword,
  authRoute,
}) => {
  // state
  const theme = useTheme();
  const [progressValue, setProgressValue] = useState<number>(0);
  const [checkFeedback, setCheckFeedback] = useState<string>("");
  const [suggestionList, setSuggestionList] = useState<string[]>([]);

  useEffect(() => {
    const getStrength = async () => {
      const calculationResponse = await zxcvbn(password);
      if (calculationResponse) {
        const { score, feedback } = calculationResponse;
        if (score) {
          setProgressValue((100 / 4) * score);
          setIsValidPassword(score >= 3);
        } else {
          if (password.length > 0) {
            setProgressValue(25);
          } else {
            setProgressValue(0);
          }
          setIsValidPassword(false);
        }
        if (feedback?.warning) {
          setCheckFeedback(feedback.warning);
        } else {
          setCheckFeedback("");
        }
        if (feedback.suggestions.length > 0) {
          setSuggestionList(feedback.suggestions);
        } else {
          setSuggestionList([]);
        }
      }
    };
    getStrength();
  }, [password]);

  return (
    <Box>
      <LinearProgress
        variant="determinate"
        value={progressValue}
        sx={{
          ...(authRoute
            ? {
                maxWidth: "100%",
              }
            : {
                maxWidth: "48%",
              }),
          "& .MuiLinearProgress-bar": {
            transition: "none",
            backgroundColor:
              progressValue === 100
                ? theme.palette.success.main
                : progressValue <= 75 && progressValue > 50
                ? theme.palette.warning.light
                : progressValue <= 50 && progressValue > 25
                ? theme.palette.warning.main
                : theme.palette.error.main,
          },
          borderRadius: "10px",
        }}
      />
      {error && (
        <Typography
          color="error"
          variant="body2"
          sx={{
            fontStyle: "italic",
            marginTop: "0.5rem",
          }}
        >
          {error && (
            <>
              {checkFeedback.length > 0
                ? checkFeedback
                : password.length === 0
                ? "Password is required."
                : "Password is weak."}
            </>
          )}
          {!error && checkFeedback}
        </Typography>
      )}
      {suggestionList.length > 0 && (
        <SuggestionList>
          {suggestionList.map((suggestion, index) => {
            return (
              <li
                className="list-item"
                // eslint-disable-next-line react/no-array-index-key
                key={`${suggestion}+${index}`}
              >
                {suggestion}
              </li>
            );
          })}
        </SuggestionList>
      )}
    </Box>
  );
};

export default PasswordValidator;
