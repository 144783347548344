import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import SouthOutlinedIcon from "@mui/icons-material/SouthOutlined";
import NorthOutlinedIcon from "@mui/icons-material/NorthOutlined";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import { Check } from "@mui/icons-material";
import { ISortOrder, sortOptions } from "utils/documentSort";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import routePaths from "routes/routePaths";

export const RootContainer = styled("div")(({ theme }) => ({
  "& .simpleMenu": {
    "& .MuiMenuItem-root": {
      paddingRight: "2rem",
      paddingLeft: "1rem",
    },
  },
  "& .sortButton.selected": {
    background: theme.menu.selected,
  },
}));

const SortDocumentMenu: React.FC<{
  documentSort: ISortOrder;
  setSortValue: (sortOrder: ISortOrder) => void;
  searchEnabled?: boolean;
}> = ({ documentSort, setSortValue, searchEnabled }) => {
  const location = useLocation();
  const [sortDialog, setSortDialog] = useState<Element | null>(null);
  const [triggerSearchClear, setTriggerSearchClear] = useState<boolean>(false);
  const handleClickSortDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSortDialog(event.currentTarget);
  };

  useEffect(() => {
    if (searchEnabled) {
      setTriggerSearchClear(true);
    }
  }, [searchEnabled]);

  // whenever search is false, need to reset key
  useEffect(() => {
    if (triggerSearchClear) {
      if (!searchEnabled) {
        setSortValue({ key: "date-created", order: documentSort.order });
        setTriggerSearchClear(false);
      } else {
        setSortValue({ key: "relevance", order: "descending" });
      }
    }
  }, [searchEnabled, triggerSearchClear]);

  const handleCloseSortDialog = () => {
    setSortDialog(null);
  };

  const setSortOrderKey = (sortKey: string) => {
    if (documentSort.key !== sortKey) {
      setSortValue({ key: sortKey, order: documentSort.order });
    }
    handleCloseSortDialog();
  };

  const setSortOrder = (sortOrder: "ascending" | "descending") => {
    if (documentSort.order !== sortOrder) {
      setSortValue({ key: documentSort.key, order: sortOrder });
    }
    handleCloseSortDialog();
  };

  return (
    <RootContainer>
      <Button
        variant="text"
        color="primary"
        disableElevation
        onClick={handleClickSortDialog}
        startIcon={<SortOutlinedIcon fontSize="small" />}
        endIcon={
          documentSort.order === "ascending" || searchEnabled ? (
            <NorthOutlinedIcon fontSize="small" />
          ) : (
            <SouthOutlinedIcon fontSize="small" />
          )
        }
        disabled={searchEnabled}
        className={clsx("sortButton", { selected: sortDialog })}
      >
        <Typography sx={{ whiteSpace: "nowrap" }} variant="body2">
          {sortOptions[documentSort.key].label}
        </Typography>
      </Button>
      <Menu
        id="simple-menu"
        className="simpleMenu"
        anchorEl={sortDialog}
        keepMounted
        open={Boolean(sortDialog)}
        onClose={handleCloseSortDialog}
      >
        <MenuItem onClick={() => setSortOrderKey("filename")}>
          {documentSort.key === "filename" && (
            <ListItemIcon className="menu-icon">
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={documentSort.key !== "filename"}
            primary={<Typography variant="body2">Filename</Typography>}
          />
        </MenuItem>
        <MenuItem onClick={() => setSortOrderKey("date-created")}>
          {documentSort.key === "date-created" && (
            <ListItemIcon className="menu-icon">
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={documentSort.key !== "date-created"}
            primary={<Typography variant="body2">Date created</Typography>}
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setSortOrderKey("title")}>
          {documentSort.key === "title" && (
            <ListItemIcon className="menu-icon">
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={documentSort.key !== "title"}
            primary={<Typography variant="body2">Title</Typography>}
          />
        </MenuItem>
        {location.pathname === routePaths.aiChat && (
          <MenuItem onClick={() => setSortOrderKey("character-count")}>
            {documentSort.key === "character-count" && (
              <ListItemIcon className="menu-icon">
                <Check fontSize="small" />
              </ListItemIcon>
            )}
            <ListItemText
              inset={documentSort.key !== "character-count"}
              primary={<Typography variant="body2">Character count</Typography>}
            />
          </MenuItem>
        )}
        <MenuItem onClick={() => setSortOrderKey("last-author")}>
          {documentSort.key === "last-author" && (
            <ListItemIcon className="menu-icon">
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={documentSort.key !== "last-author"}
            primary={<Typography variant="body2">Last author</Typography>}
          />
        </MenuItem>
        <MenuItem onClick={() => setSortOrderKey("first-author")}>
          {documentSort.key === "first-author" && (
            <ListItemIcon className="menu-icon">
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={documentSort.key !== "first-author"}
            primary={<Typography variant="body2">First author</Typography>}
          />
        </MenuItem>
        <MenuItem onClick={() => setSortOrderKey("journal")}>
          {documentSort.key === "journal" && (
            <ListItemIcon className="menu-icon">
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={documentSort.key !== "journal"}
            primary={<Typography variant="body2">Journal</Typography>}
          />
        </MenuItem>
        <MenuItem onClick={() => setSortOrderKey("year")}>
          {documentSort.key === "year" && (
            <ListItemIcon className="menu-icon">
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={documentSort.key !== "year"}
            primary={<Typography variant="body2">Year</Typography>}
          />
        </MenuItem>
        {searchEnabled && (
          <MenuItem onClick={() => setSortOrderKey("relevance")}>
            {documentSort.key === "relevance" && (
              <ListItemIcon className="menu-icon">
                <Check fontSize="small" />
              </ListItemIcon>
            )}
            <ListItemText
              inset={documentSort.key !== "relevance"}
              primary={<Typography variant="body2">Relevance</Typography>}
            />
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={() => setSortOrder("ascending")}>
          {documentSort.order === "ascending" && (
            <ListItemIcon className="menu-icon">
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={documentSort.order !== "ascending"}
            primary={<Typography variant="body2">Ascending</Typography>}
          />
        </MenuItem>
        <MenuItem onClick={() => setSortOrder("descending")}>
          {documentSort.order === "descending" && (
            <ListItemIcon className="menu-icon">
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={documentSort.order !== "descending"}
            primary={<Typography variant="body2">Descending</Typography>}
          />
        </MenuItem>
      </Menu>
    </RootContainer>
  );
};

export default SortDocumentMenu;
