/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import axios from "axios";
import Router from "routes/Router";
import theme from "App-styles";
import userService from "api/userService";
import {
  selectEnableTelemetry,
  selectCurrentOrganizationId,
  selectSessionId,
  selectUser,
  setSessionId,
} from "store/features/session/slice";
import "mathJax.css";
import ErrorBoundary from "ErrorBoundary";
import { IUserDataTelemetry } from "utils/useTelemetry";
import { CssBaseline } from "@mui/material";
import { selectTitle } from "store/features/general/slice";
import ReloadOnIdle from "components/helpers/ReloadOnIdle";
import MainLoader from "components/helpers/MainLoader";
import { isMobile } from "react-device-detect";

const editorExtensionId = "dikbfpemggjmcidjecbjgbikghaaigei";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const sessionId = useSelector(selectSessionId);
  const title = useSelector(selectTitle);
  const enableTelemetry = useSelector(selectEnableTelemetry);
  const [isUserTimedOut, setIsUserTimedOut] = useState<boolean>(false);

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);

  // add body backgorund color
  // for future use will work with themes
  useEffect(() => {
    document.body.style.backgroundColor = theme.background.main;
  }, []);

  // custom event listeners
  useEffect(() => {
    // pageExit event
    const pageExit = () => {
      if (user && currentOrganizationId) {
        const events: IUserDataTelemetry[] = JSON.parse(
          localStorage.getItem("userTelemetry") || "[]"
        );
        userService.sendUserTelemetryData([
          ...events,
          {
            user_id: user?.id,
            organization_id: currentOrganizationId,
            action:
              window.location.pathname === "/select-service"
                ? "redirect_to_service"
                : "page-exit",
            session_uuid: sessionId,
            timestamp: new Date().toISOString(),
            payload_json: JSON.stringify({}),
          },
        ]);
        localStorage.setItem("userTelemetry", JSON.stringify([]));
      }
    };

    window.addEventListener("beforeunload", pageExit);
    // cleanup this events
    return () => {
      window.removeEventListener("beforeunload", pageExit);
    };
  }, [user]);

  // user telemetry log
  useEffect(() => {
    // set session id on mount
    dispatch(setSessionId(uuidv4()));

    // 15 sec interval for checking localstorage
    const telemetryInterval = setInterval(() => {
      // read event log from localStorage
      const events: IUserDataTelemetry[] = JSON.parse(
        localStorage.getItem("userTelemetry") || "[]"
      );
      if (events && events.length > 0) {
        if (enableTelemetry) {
          userService.sendUserTelemetryData(events);
          // clear telemetry data
          localStorage.setItem("userTelemetry", JSON.stringify([]));
        } else {
          // for admins show telemetry in console
          console.log(events);
          localStorage.setItem("userTelemetry", JSON.stringify([]));
        }
      }
    }, 15000);

    return () => clearInterval(telemetryInterval);
  }, [enableTelemetry]);

  // chrome extension logic
  useEffect(() => {
    const handleResponse = () => {
      const { lastError } = chrome.runtime;
      if (lastError) {
        console.log(lastError.message);
      }
    };

    const ua = navigator.userAgent;
    if (!isMobile) {
      if (/Chrome/i.test(ua)) {
        if (user) {
          // chrome.runtime should run on https
          // use `HTTPS=true yarn start` to start the app
          chrome?.runtime?.sendMessage(
            editorExtensionId,
            { user, authed: true },
            handleResponse
          );
        } else {
          chrome?.runtime?.sendMessage(
            editorExtensionId,
            { authed: false },
            handleResponse
          );
        }
      } else if (/Firefox/i.test(ua) || /Safari/i.test(ua)) {
        if (user) {
          window.postMessage({ user, authed: true }, "*");
        } else {
          window.postMessage({ authed: false }, "*");
        }
      }
    }
  }, [user]);

  // set default authorization header to use JWT token
  axios.defaults.headers.common.Authorization = user
    ? `Bearer ${user.access_token}`
    : "";

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          <ReloadOnIdle
            timedOut={isUserTimedOut}
            setTimedOut={setIsUserTimedOut}
          />
          {isUserTimedOut ? <MainLoader /> : <Router />}
        </StyledEngineProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
export { App as pureApp };
