import { PageLayoutResponse } from "models/api/response.types";

export const getRectangularLocation = (
  pages: PageLayoutResponse[],
  bBox: number[]
) => {
  // get pageNumber (in bbox 2nd element)
  const pageNumber = bBox[2];
  // get a correct page
  const page = pages[pageNumber - 1];
  // get a location unit per each location on page
  const locationUnit = Math.round((1 / page.locations.length) * 100) / 100;
  // get aprox annotation location (bbox 4th element shows height on page)
  const startLocation = page.locations[Math.floor(bBox[1] / locationUnit) - 1];
  return {
    pageNumber,
    start: startLocation
      ? startLocation[0]
      : page?.locations?.length > 0
      ? page.locations[0][0]
      : page?.span[0],
  };
};

const getLocation = (
  indexLocation: number | undefined,
  pages: PageLayoutResponse[],
  bBox: number[] | undefined
): { pageNumber: number; start: number } => {
  if (indexLocation !== undefined && !bBox) {
    for (let i = 0; i < pages.length; i += 1) {
      const pageSpan = pages[i].span;
      // index is on this page
      if (pageSpan[0] <= indexLocation && indexLocation <= pageSpan[1]) {
        return { pageNumber: i + 1, start: indexLocation };
      }
      if (indexLocation < pageSpan[0]) {
        break;
      }
    }
    return { pageNumber: 0, start: indexLocation };
  }
  if (!indexLocation && bBox) {
    // as we know that index 2 in bbox is page number , we can get aprox
    // location for rectangle annotation
    return getRectangularLocation(pages, bBox);
  }
  return { pageNumber: 0, start: 0 };
};

export default getLocation;
