/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  styled,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Close } from "@mui/icons-material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDocuments } from "api/documentService";
import { DocumentReadResponse } from "models/api/response.types";
import clsx from "clsx";
import LoadingButton from "@mui/lab/LoadingButton";
import { defaultOrganizationSchema, sortSchemaOrder } from "utils/bibtexSchema";
import { parseBibTeX } from "utils/bibtex";
import detexify from "utils/detexify";

const StyledDialog = styled(Dialog)(() => ({
  "& .indentifier-form": {
    "& .MuiInputLabel-root": {
      color: "#272727",
      position: "relative",
      fontSize: "14px",
      transform: "none",
      marginBottom: "8px",
    },
  },
  "& table": {
    borderCollapse: "collapse",
    borderTop: "1px solid #0001",
    fontSize: "14px",
  },
  "& th": {
    textAlign: "left",
  },
  "& th, td": {
    borderBottom: "1px solid #0001",
    padding: "0.25rem 0.25rem",
    userSelect: "none",
  },
  "& td.active, th.active": {
    background: "#EAF1FB",
  },
  "& .property": {
    display: "flex",
    alignItems: "center",
  },
  "& .property .value": {
    width: "auto",
    minWidth: "20ch",
    maxWidth: "40ch",
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
}));

type MergeStatus = "ready" | "start";

interface PropertyRow {
  key: string;
  current: unknown;
  currentIsEmpty: boolean;
  incoming: unknown;
  incomingIsEmpty: boolean;
  isConflict: boolean;
  rule: RuleType.current | RuleType.incoming;
  result: string;
}

enum RuleType {
  current = "current",
  incoming = "incoming",
}

const BibMergeDialog: React.FC<{
  setOpen(open: boolean): void;
  document: DocumentReadResponse;
}> = ({ setOpen, document }) => {
  const [status, setStatus] = useState<MergeStatus>("start");
  const { updateDocumentMutation } = useDocuments(document.organization_id);
  const [allCurrent, setAllCurrent] = useState(false);
  const [allIncoming, setAllIncoming] = useState(false);
  const [rules, setRules] = useState<
    Record<string, RuleType.current | RuleType.incoming>
  >({});
  const [rowData, setRowData] = useState<PropertyRow[]>([]);
  const [chooseNotEmpty, setChooseNotEmpty] = useState(true);
  const [showConflictOnly, setShowConflictOnly] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [search, setSearch] = useState<boolean>(false);
  const [bibMergeIncoming, setBibMergeIncoming] = useState<
    Record<string, string> | undefined
  >({});
  const { meta } = document;

  const handleMerge = () => {
    const newMeta: Record<string, unknown> = Object.fromEntries(
      rowData.map((row) => {
        return [
          row.key,
          row.rule === RuleType.current ? row.current : row.incoming,
        ];
      })
    );
    const newData = { ...document.meta, ...newMeta };
    updateDocumentMutation.mutate({
      id: document.id,
      payload: { meta: newData },
    });
    setOpen(false);
  };

  const formatValue = (value: unknown): string => {
    if (value === undefined || value === null) return "";
    if (Array.isArray(value)) {
      return (value as string[]).join(", ");
    }
    return detexify(`${value}`.trim(), false);
  };

  useEffect(() => {
    if (bibMergeIncoming && status === "ready") {
      // skip keys if both current and incoming are empty
      const documentKeys: any[] = [];
      const incomingKeys: any[] = [];
      Object.keys(meta).forEach((key: string) => {
        const result = defaultOrganizationSchema.find(
          (prop) => prop.name === key
        );
        if (result) {
          documentKeys.push(result);
        } else if (key !== "cite-key" && key !== "entry-type") {
          documentKeys.push({ name: key, type: "text" });
        }
      });
      Object.keys(bibMergeIncoming).forEach((key: string) => {
        const result = defaultOrganizationSchema.find(
          (prop) => prop.name === key
        );
        if (result) {
          incomingKeys.push(result);
        } else if (key !== "cite-key" && key !== "entry-type") {
          incomingKeys.push({ name: key, type: "text" });
        }
      });

      let newKeys = [...new Set([...documentKeys, ...incomingKeys])]
        .sort()
        .filter((key: { name: string; type: string; label?: string }) => {
          const currentValue: string = key.name in meta ? meta[key.name] : "";
          const incomingValue: string =
            key.name in bibMergeIncoming ? bibMergeIncoming[key.name] : "";
          if (
            (formatValue(currentValue) === "" &&
              formatValue(incomingValue) === "") ||
            key.name === "properties"
          ) {
            return false;
          }
          return true;
        });
      newKeys = sortSchemaOrder(newKeys, meta.entrytype);

      // update rules
      const newRowData: PropertyRow[] = newKeys.map((key) => {
        const currentValue: string = key.name in meta ? meta[key.name] : "";
        const incomingValue: string =
          key.name in bibMergeIncoming ? bibMergeIncoming[key.name] : "";
        const currentIsEmpty = `${currentValue}`.trim() === "";
        const incomingIsEmpty = `${incomingValue}`.trim() === "";
        let rule = key.name in rules ? rules[key.name] : RuleType.incoming;
        if (chooseNotEmpty) {
          if (currentIsEmpty) {
            rule = RuleType.incoming;
          }
        }
        if (incomingIsEmpty) rule = RuleType.current;
        const result =
          rule === RuleType.current
            ? meta[key.name]
            : bibMergeIncoming[key.name];
        const row: PropertyRow = {
          key: key.name,
          current: currentValue,
          currentIsEmpty,
          incoming: incomingValue,
          incomingIsEmpty,
          rule,
          result,
          isConflict:
            JSON.stringify(currentValue) !== JSON.stringify(incomingValue),
        };
        return row;
      });
      setRowData(newRowData);
      setAllCurrent(newRowData.every((row) => row.rule === RuleType.current));
      setAllIncoming(newRowData.every((row) => row.rule === RuleType.incoming));
    }
  }, [document, bibMergeIncoming, rules, chooseNotEmpty]);

  const handleToggleRule = (
    key: string,
    value?: RuleType.current | RuleType.incoming
  ) => {
    const oldValue = key in rules ? rules[key] : RuleType.current;
    const newValue =
      value !== undefined
        ? value
        : oldValue === RuleType.current
        ? RuleType.incoming
        : RuleType.current;
    const newRules: Record<string, RuleType.current | RuleType.incoming> = {
      ...rules,
      [key]: newValue,
    };
    setRules(newRules);
  };

  const setEmptyToIncoming = () => {
    const newRules = { ...rules };
    const emptyCurrentRowData = rowData
      .filter((item) => !item.current && item.incoming)
      .map((item) => item.key);
    Object.keys(newRules).forEach((key) => {
      if (emptyCurrentRowData.includes(key)) newRules[key] = RuleType.incoming;
    });
    setRules(newRules);
  };

  const setAllRules = (value: RuleType.current | RuleType.incoming): void => {
    setRules(
      Object.fromEntries(
        rowData.map((key) => {
          if (
            value === RuleType.current &&
            !key.current &&
            key.incoming &&
            chooseNotEmpty
          ) {
            return [key.key, RuleType.incoming];
          }
          if (value === RuleType.incoming && !key.incoming && key.current) {
            return [key.key, RuleType.current];
          }
          return [key.key, value];
        })
      )
    );
  };

  const exportReferenceBibtex = (reference: string) => {
    fetch(`https://translation-server.petal.org/export?format=bibtex`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: reference,
    })
      .then((response) => response.text())
      .then((data) => {
        setSearch(false);
        const bibtexEntries = parseBibTeX(data).map((entry) =>
          entry
            ? {
                ...entry.properties,
                entrytype: entry.entrytype,
                citekey: entry.citekey,
              }
            : {}
        );
        setBibMergeIncoming(bibtexEntries[0]);
        setStatus("ready");
      })
      .catch(() => {
        setSearch(false);
        setErrorMessage(true);
      });
  };

  const searchReference = () => {
    setSearch(true);
    fetch(`https://translation-server.petal.org/search`, {
      method: "post",
      headers: {
        "Content-Type": "text/plain", // IMPORTANT!
      },
      body: inputValue,
    })
      .then((response) => response.json())
      .then((data) => {
        exportReferenceBibtex(JSON.stringify(data));
      })
      .catch(() => {
        setSearch(false);
        setErrorMessage(true);
      });
  };

  const getPropertyTable = (): React.ReactElement => {
    return (
      <Box
        sx={{
          height: "80%",
          maxHeight: "400px",
          overflowY: "scroll",
          marginTop: "1rem",
        }}
      >
        <table style={{ width: "100%", height: "100%" }}>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th className={clsx({ active: allCurrent })}>
                <Radio
                  color="primary"
                  checked={allCurrent}
                  onClick={() => {
                    setAllRules(RuleType.current);
                  }}
                />
              </th>
              <th
                className={clsx({ active: allCurrent })}
                onClick={() => setAllRules(RuleType.current)}
              >
                Original
              </th>
              <th className={clsx({ active: allIncoming })}>
                <Radio
                  color="primary"
                  checked={allIncoming}
                  onClick={() => {
                    setAllRules(RuleType.incoming);
                  }}
                />
              </th>
              <th
                className={clsx({ active: allIncoming })}
                onClick={() => setAllRules(RuleType.incoming)}
              >
                New
              </th>
            </tr>
          </thead>
          <tbody>
            {rowData
              .filter((row) => {
                return showConflictOnly ? row.isConflict : true;
              })
              .map((row) => (
                <tr key={row.key}>
                  <td style={{ minWidth: "15ch" }}>
                    {row.key !== "entrytype" &&
                      row.key !== "citekey" &&
                      row.key.charAt(0).toUpperCase() + row.key.slice(1)}
                    {row.key === "entrytype" && "Entry-type"}
                    {row.key === "citekey" && "Cite-key"}
                  </td>
                  <td
                    className={clsx({ active: row.rule === RuleType.current })}
                  >
                    <Radio
                      color="primary"
                      checked={row.rule === RuleType.current}
                      onClick={() =>
                        handleToggleRule(row.key, RuleType.current)
                      }
                      value={RuleType.current}
                      disabled={chooseNotEmpty && row.currentIsEmpty}
                      name={row.key}
                    />
                  </td>
                  <td
                    className={clsx({ active: row.rule === RuleType.current })}
                    onClick={() => {
                      if (!(chooseNotEmpty && row.currentIsEmpty))
                        handleToggleRule(row.key, RuleType.current);
                    }}
                    style={{
                      cursor: row.currentIsEmpty ? "default" : "pointer",
                    }}
                  >
                    <div className="property">
                      <div className="value">{formatValue(row.current)}</div>
                    </div>
                  </td>
                  <td
                    className={clsx({ active: row.rule === RuleType.incoming })}
                  >
                    <Radio
                      color="primary"
                      checked={row.rule === RuleType.incoming}
                      onClick={() => {
                        if (!(chooseNotEmpty && row.incomingIsEmpty))
                          handleToggleRule(row.key, RuleType.incoming);
                      }}
                      value={RuleType.incoming}
                      disabled={row.incomingIsEmpty}
                      name={row.key}
                    />
                  </td>
                  <td
                    className={clsx({ active: row.rule === RuleType.incoming })}
                    onClick={() => handleToggleRule(row.key, RuleType.incoming)}
                    style={{
                      cursor:
                        chooseNotEmpty && row.incomingIsEmpty
                          ? "default"
                          : "pointer",
                    }}
                  >
                    <div className="property">
                      <div className="value">{formatValue(row.incoming)}</div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Box>
    );
  };

  return (
    <StyledDialog
      open
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={status === "ready" ? "md" : "sm"}
    >
      <DialogTitle>
        Merge records
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          overflow: "hidden",
        }}
      >
        {status === "start" && (
          <FormControl
            className="indentifier-form"
            variant="standard"
            fullWidth
          >
            <InputLabel shrink htmlFor="indentifier">
              Identifiers (ArXivID, DOI or PMID)
            </InputLabel>
            <TextField
              id="indentifier"
              color="primary"
              variant="outlined"
              value={inputValue}
              type="text"
              size="small"
              autoFocus
              error={errorMessage}
              helperText={errorMessage && "Can't find valid reference"}
              onChange={(e) => {
                setErrorMessage(false);
                setInputValue(e.target.value);
              }}
            />
          </FormControl>
        )}
        {status === "ready" && (
          <>
            <DialogContentText>
              Choose data from the fields to combine the records into a single
              primary record.
            </DialogContentText>
            <FormGroup sx={{ mt: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chooseNotEmpty}
                    onChange={(event) => {
                      setChooseNotEmpty(event.currentTarget.checked);
                      if (!chooseNotEmpty) setEmptyToIncoming();
                    }}
                  />
                }
                label="Always use new record if empty"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showConflictOnly}
                    onChange={(event) =>
                      setShowConflictOnly(event.currentTarget.checked)
                    }
                  />
                }
                label="Only show fields with conflicting data"
              />
            </FormGroup>
            {getPropertyTable()}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {status === "ready" && (
          <LoadingButton
            onClick={handleMerge}
            size="medium"
            variant="contained"
            loading={updateDocumentMutation.isLoading}
            color="primary"
          >
            Merge record
          </LoadingButton>
        )}
        {status === "start" && (
          <LoadingButton
            onClick={searchReference}
            loading={search}
            size="medium"
            variant="contained"
            color="primary"
            disabled={inputValue.length === 0}
          >
            Search
          </LoadingButton>
        )}
      </DialogActions>
    </StyledDialog>
  );
};

export default BibMergeDialog;
