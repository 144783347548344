/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";
import { LoadingButton } from "@mui/lab";
import { validateEmail } from "utils/validation";
import handleAxiosError from "utils/handleAxiosAlert";
import { AxiosError } from "axios";
import inviteService from "api/inviteService";
import { addAlert } from "store/features/general/slice";
import { Organization } from "models/api/response.types";
import { useOrganizationUsage } from "api/organizationService";

const InviteMembersContainer = styled(Dialog)(({ theme }) => ({
  "& .invite-amount": {
    color: theme.palette.primary.main,
  },
  "& .alert-box": {
    marginTop: "1rem",
    display: "flex",
    gap: "0.5rem",
  },
  "& .text-helper": {
    margin: "4px 14px 0",
  },
}));

interface IMembersDialogProps {
  organization?: Organization;
  setOpen: (open: boolean) => void;
  // for settings page
  inviteCompletesCallback?: () => void;
}

const InviteMembersDialog: React.FC<IMembersDialogProps> = ({
  organization,
  setOpen,
  inviteCompletesCallback,
}) => {
  const theme = useTheme();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { organizationUsage } = useOrganizationUsage(organization?.id);

  // state
  const [emailInputValue, setEmailInputValue] = useState<string>("");
  const [invitingMembers, setInvitingMembers] = useState<boolean>(false);
  const [emailTags, setEmailTags] = useState<string[]>([]);
  const [emailError, setEmailError] = useState<string>("");
  const [roleToChoose, setRoleToChoose] = useState("guest");
  const membersSeatsRemaining = organizationUsage
    ? organizationUsage.usage_limits.members - organizationUsage.usage.members
    : 0;
  const guestsSeatsRemaining = organizationUsage
    ? organizationUsage.usage_limits.guests - organizationUsage.usage.guests
    : 0;

  // last step cleaning state, closing dialog
  const finishMembersInvite = (emails: { email: string; status: string }[]) => {
    const message = `${emails
      .map((email) => email.email)
      .join(", ")} received your invite.`;
    dispatch(
      addAlert({
        severity: "success",
        autoHideDuration: 10000,
        alert: {
          message,
        },
      })
    );
    setInvitingMembers(false);
    setEmailTags([]);
    if (inviteCompletesCallback) {
      inviteCompletesCallback();
    }
    setOpen(false);
  };

  const sendInvite = () => {
    if (organization) {
      if (roleToChoose === "guest" && guestsSeatsRemaining <= 0) {
        setEmailError("Don't have enough seats for guests.");
        setInvitingMembers(false);
      } else if (roleToChoose === "member" && membersSeatsRemaining <= 0) {
        setEmailError("Don't have enough seats for members.");
        setInvitingMembers(false);
      } else {
        // check each user permission
        const checkUsersPermission = emailTags.map((email: string) => {
          return inviteService
            .checkInvitePermission(email, organization.id, roleToChoose)
            .then(({ data: permissionData }) => {
              return permissionData;
            })
            .catch((err) => {
              return err;
            });
        });

        // promise for all user check permisisions
        Promise.all(checkUsersPermission).then((permissionResults) => {
          const notValidEmails: string[] = [];
          const validEmails: { email: string; status: string }[] = [];
          permissionResults.forEach((result, index) => {
            // if user has status that means that user is valid
            // otherwise through error
            if (!result.user_status) {
              notValidEmails.push(emailTags[index]);
            } else {
              validEmails.push({
                email: emailTags[index],
                status: result.user_status,
              });
            }
          });
          if (notValidEmails.length > 0) {
            setEmailError(
              `We have a problem to invite these users: ${notValidEmails.join(
                ", "
              )}.`
            );
          }
          if (validEmails.length > 0) {
            // if we have valid users, invite them to org
            const inviteUsers = validEmails.map(({ email, status }) => {
              if (status === "new") {
                return inviteService
                  .inviteNewUser({
                    email,
                    organization_id: organization.id,
                    role: roleToChoose,
                  })
                  .then(({ data: inviteResult }) => inviteResult)
                  .catch((err) => err);
              }
              return inviteService
                .inviteExistingUser({
                  email,
                  organization_id: organization.id,
                  role: roleToChoose,
                })
                .then(({ data: inviteResult }) => inviteResult)
                .catch((err) => err);
            });

            Promise.all(inviteUsers).then((inviteResults) => {
              inviteResults.forEach((result) => {
                if (result?.name === "AxiosError") {
                  handleAxiosError(result as AxiosError, dispatch);
                }
              });
              finishMembersInvite(validEmails);
            });
          } else {
            setInvitingMembers(false);
          }
        });
      }
    }
  };

  // submit form, check inputs, if everythign correct proceed to org creation
  const submitForm = () => {
    if (emailTags.length > 0) {
      const badEmails: string[] = [];
      emailTags.forEach((email) => {
        if (!validateEmail(email) || email === user?.email) {
          badEmails.push(email);
        }
      });
      if (badEmails.length > 0) {
        setInvitingMembers(false);
        setEmailError(`Not valid email: ${badEmails.join(", ")}.`);
      } else {
        setInvitingMembers(true);
        sendInvite();
      }
    }
  };

  // email input change
  const handleEmailTagsChange = (
    event: React.SyntheticEvent<Element, Event>,
    values: any[]
  ) => {
    setEmailError("");
    setEmailTags(values);
  };

  // Autocomplete input change
  const handleEmailInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    // functionality to create email chip on comma or space
    const lastCharachter = newInputValue[newInputValue.length - 1];
    if (lastCharachter === "," || lastCharachter === " ") {
      const result = newInputValue.slice(0, -1);
      if (result.length > 0) {
        setEmailTags([...emailTags, result]);
        setEmailInputValue("");
      } else {
        setEmailInputValue("");
      }
    } else {
      setEmailInputValue(newInputValue);
    }
  };

  // if any text exist on blur check if we can make email chip from it
  const blurEmailInputCheck = () => {
    if (validateEmail(emailInputValue)) {
      setEmailTags([...emailTags, emailInputValue]);
      setEmailInputValue("");
    } else {
      setEmailInputValue("");
    }
  };

  // rendeer chip for email
  const renderEmailTags = (
    tagValue: any[],
    getTagProps: AutocompleteRenderGetTagProps
  ) =>
    tagValue.map((option, index) => {
      return (
        <Chip
          size="medium"
          color="primary"
          label={option}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getTagProps({ index })}
        />
      );
    });

  return (
    <InviteMembersContainer
      open
      maxWidth="sm"
      fullWidth
      onClose={() => setOpen(false)}
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <DialogTitle>
          Invite member(s) to {organization?.name}
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            color="primary"
            value={emailTags}
            freeSolo
            inputValue={emailInputValue}
            forcePopupIcon={false}
            options={[]}
            onInputChange={handleEmailInputChange}
            onChange={handleEmailTagsChange}
            onBlur={blurEmailInputCheck}
            multiple
            renderTags={renderEmailTags}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                variant="outlined"
                placeholder="Emails separated by comma"
                size="small"
                error={emailError.length > 0}
                helperText={emailError}
              />
            )}
          />
          {emailTags.length > 0 && (
            <Typography variant="body2" sx={{ marginTop: "1rem" }}>
              Invite{" "}
              <strong className="invite-amount">
                {emailTags.length}{" "}
                {roleToChoose === "member" ? "members" : "guests"}(s)
              </strong>{" "}
              to this workspace
            </Typography>
          )}
          {organizationUsage && (
            <Box
              sx={{
                margin: "0.5rem 0 0 1rem",
                display: "flex",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <Box>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color="textSecondary"
                >
                  Guest seats remaining:{" "}
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: 500,
                    }}
                  >
                    {guestsSeatsRemaining}
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color="textSecondary"
                >
                  Members seats remaining:{" "}
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: 500,
                    }}
                  >
                    {membersSeatsRemaining}
                  </span>
                </Typography>
              </Box>
              <Box>
                <ToggleButtonGroup
                  color="primary"
                  value={roleToChoose}
                  exclusive
                  onChange={(e, value) => {
                    if (value) {
                      setRoleToChoose(value);
                      setEmailError("");
                    }
                  }}
                  aria-label="User role"
                  size="small"
                >
                  <ToggleButton value="member" size="small">
                    Invite as member
                  </ToggleButton>
                  <ToggleButton value="guest" size="small">
                    Invite as guest
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
          )}
          <Box className="alert-box">
            <ErrorOutlineOutlinedIcon
              fontSize="large"
              sx={{
                color: theme.red.main,
              }}
            />
            <Typography color="textSecondary" variant="body2">
              <strong>
                All content across the workspace are shared with all users of
                the workspace. Please share responsibly.
              </strong>{" "}
              We will take down illegaly distributed content and terminate the
              associated workspace account for copyright infringement.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            size="medium"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={emailTags.length === 0}
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            loading={!!invitingMembers}
          >
            Invite
          </LoadingButton>
        </DialogActions>
      </form>
    </InviteMembersContainer>
  );
};

export default InviteMembersDialog;
