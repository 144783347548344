/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  OutlinedInput,
  Popper,
  Stack,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import { sortOptions } from "utils/tagSort";
import { useTags } from "api/tagService";
import { DocumentReadResponse, Tag, TagList } from "models/api/response.types";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import { useDispatch, useSelector } from "react-redux";
import { ClearOutlined, Close, SearchOutlined } from "@mui/icons-material";
import clsx from "clsx";
import { matchSorter } from "match-sorter";
import { tagsModes } from "utils/tagsModes";
import SortTagMenu from "components/Tags/SortTagMenu";
import {
  selectTagsModeBrowse,
  setModeBrowse,
} from "store/features/browser/slice";
import { selectTagSort } from "store/features/general/slice";
import detexify from "utils/detexify";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { useOrganizations } from "api/organizationService";
import InlineTagById from "../../Tags/InlineTagById";

export interface DocumentFilters {
  [key: string]: any[];
  authors: any[];
  journals: string[];
  years: number[];
  titles: string[];
  tags: TagList;
}

export const defaultDocumentFiltersData = {
  authors: [],
  journals: [],
  years: [],
  titles: [],
  tags: [],
};

export const RootContainer = styled(Box)(({ theme }) => ({
  "& .filter-button.selected": {
    background: theme.palette.primary.main,
    color: theme.background.light,
  },
  "& .filter-button.opened": {
    background: theme.menu.selected,
  },
}));

export const PopperContainer = styled(Popper)(({ theme }) => ({
  border: `1px solid #E9E9E9`,
  boxShadow: `0 0.5rem 1rem rgba(149, 157, 165, 0.2)`,
  borderRadius: 6,
  maxWidth: 410,
  zIndex: 1600,
  backgroundColor: theme.background.light,
  overflow: "hidden",
  "& .clearFilterButton": {
    margin: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  "& .formInput": {
    margin: "0 1rem",
    width: "calc(100% - 2rem)",
  },
  "& .filterButtonSecondary": {
    margin: theme.spacing(1),
  },
  "& .filterButtonText": {
    maxWidth: "12.5rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const DocumentFilter: React.FC<{
  selectedFilters: DocumentFilters;
  filteredDocuments: DocumentReadResponse[];
  filters: DocumentFilters;
  setFilters: (filters: DocumentFilters) => void;
  setSelectedDocumentFilters: (filters: DocumentFilters) => void;
  setSelectedDocuments?: (documents: DocumentReadResponse[]) => void;
}> = ({
  selectedFilters,
  filteredDocuments,
  filters,
  setFilters,
  setSelectedDocumentFilters,
  setSelectedDocuments,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const tagSort = useSelector(selectTagSort);
  const tagsModeBrowse = useSelector(selectTagsModeBrowse);
  const { getCachedOrganizationById } = useOrganizations(user?.id);
  const [currentOrganization] = getCachedOrganizationById(
    currentOrganizationId || -1
  );
  const { tags: currentTags } = useTags(currentOrganization?.id);
  const [inputFilterValue, setInputFilterValue] = useState<string>("");
  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const isFilterChecked = Object.values(selectedFilters).some(
    (val) => val.length > 0
  );

  const setDefaultFilter = () => {
    if (!selectedFilter) {
      const readyFilters = Object.keys(filters).filter((key) => {
        if (filters[key].length === 0) {
          return false;
        }
        return true;
      });
      if (readyFilters.length > 0) {
        setSelectedFilter(readyFilters[0]);
      }
    }
  };

  useEffect(() => {
    const titles = new Set<string>();
    const authors = new Set<string>();
    const journals = new Set<string>();
    const years = new Set<number>();
    const tags = new Set<string>();

    (filteredDocuments as any).forEach(
      (document: {
        meta: {
          author: string | any[];
          journal: string;
          year: string;
          title: string;
        };
        tag_ids: number[];
      }) => {
        const { author, journal, year, title } = document.meta;
        if (Array.isArray(author)) {
          author.forEach((item) => authors.add(item));
        } else if (author) {
          if (author.includes(" and ")) {
            (author as string)
              .split(" and ")
              .forEach((item) => item && authors.add(item));
          } else if (author.includes(" AND ")) {
            (author as string)
              .split(" AND ")
              .forEach((item) => item && authors.add(item));
          } else {
            authors.add(author);
          }
        }
        if (journal) {
          journals.add(journal);
        }
        if (title) {
          titles.add(title);
        }
        if (year && parseInt(year, 10) > 0) {
          years.add(parseInt(year, 10));
        }
        if (document.tag_ids && document.tag_ids.length > 0) {
          if (currentTags) {
            document.tag_ids.forEach((tagId) => {
              const tagData = currentTags?.find(
                (tagD: Tag) => tagD.id === tagId
              );
              tags.add(JSON.stringify(tagData));
            });
          }
        }
      }
    );

    const authorOptions = Array.from(authors).sort((a, b) => {
      const aTokens = a.split(" ");
      const bTokens = b.split(" ");
      const aLastName = aTokens.length > 0 ? aTokens[aTokens.length - 1] : a;
      const bLastName = bTokens.length > 0 ? bTokens[bTokens.length - 1] : b;
      return aLastName
        .toLocaleLowerCase()
        .localeCompare(bLastName.toLocaleLowerCase());
    });
    const journalOptions = Array.from(journals).sort((a, b) =>
      a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
    );
    const titleOptions = Array.from(titles).sort((a, b) =>
      a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
    );
    const yearOptions = Array.from(years).sort((a, b) => b - a);
    // finalize tags parse back them
    const tagOptions: TagList = [];
    tags.forEach((value) => {
      if (value) {
        tagOptions.push(JSON.parse(value));
      }
    });
    setFilters({
      titles: titleOptions,
      authors: authorOptions,
      journals: journalOptions,
      years: yearOptions,
      tags: tagOptions,
    });
  }, [filteredDocuments, currentTags]);

  const setSelectedFilters = (f: DocumentFilters) => {
    setSelectedDocumentFilters(f);
    if (setSelectedDocuments) {
      setSelectedDocuments([]);
    }
  };

  const handleChangeFilterFunctions: {
    [key: string]: (event: any, newValue: any) => void;
  } = {};
  Object.keys(filters).forEach((filter) => {
    handleChangeFilterFunctions[filter] = (event: any, newValue: any) => {
      const newArrayOptions = [...newValue];
      setSelectedFilters({ ...selectedFilters, [filter]: newArrayOptions });
    };
  });

  const clearFilter = (filter: string) => {
    setSelectedFilters({ ...selectedFilters, [filter]: [] });
  };

  const clearAllFilters = () => {
    const clearFilters: DocumentFilters = {
      authors: [],
      journals: [],
      years: [],
      tags: [],
      titles: [],
    };
    Object.keys(filters).forEach((filter) => {
      clearFilters[filter] = [];
    });
    setSelectedFilters(clearFilters);
  };

  const getPlaceholder = (selectedItems: any[], filter: string) => {
    if (selectedItems?.length === 1 && filter !== "tags") {
      return detexify(selectedItems[0].value || selectedItems[0], false);
    }
    if (!selectedItems?.length) {
      return `${filter[0]}`.toUpperCase() + filter.slice(1);
    }
    return `${selectedItems.length} ${filter}`;
  };

  const tagMenu = (filter: string) => {
    return (
      <>
        <Stack
          direction="row"
          spacing={0.5}
          alignItems="center"
          style={{ margin: "0 1rem" }}
        >
          <Typography variant="body2" className="switch-text">
            documents containing <b>at least one</b>
          </Typography>
          <Switch
            checked={tagsModeBrowse === tagsModes.and}
            color="primary"
            size="small"
            onChange={() => {
              dispatch(
                setModeBrowse(
                  tagsModeBrowse === tagsModes.or ? tagsModes.and : tagsModes.or
                )
              );
            }}
          />
          <Typography variant="body2" className="switch-text">
            <b>all</b> selected tags
          </Typography>
        </Stack>
        {selectedFilters[filter].length > 0 && (
          <div
            style={{
              padding: "9px 1rem 0 1rem",
              display: "flex",
              flexWrap: "wrap",
              gap: "0.5rem",
            }}
          >
            {selectedFilters[filter].map((tag: Tag) => {
              return (
                <InlineTagById
                  organizationId={tag?.organization_id}
                  id={tag?.id}
                  key={tag?.id}
                  onClick={() => {
                    const newFilterData = selectedFilters[filter].filter(
                      (data: Tag) => data !== tag
                    );
                    setSelectedFilters({
                      ...selectedFilters,
                      [filter]: newFilterData,
                    });
                  }}
                />
              );
            })}
          </div>
        )}
        <Divider style={{ margin: "8px 0" }} />
        <ListSubheader
          style={{
            display: "flex",
            alignItems: "center",
            lineHeight: 2,
            wordBreak: "break-word",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#000",
            }}
          >
            {inputFilterValue.length === 0
              ? `${filter.charAt(0).toUpperCase() + filter.slice(1)}`
              : `Results for "${
                  inputFilterValue.charAt(0).toUpperCase() +
                  inputFilterValue.slice(1)
                }"`}
          </Typography>
          <SortTagMenu />
        </ListSubheader>
        <div
          style={{
            maxHeight: 265,
            padding: "9px 1rem",
            overflow: "auto",
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem",
          }}
        >
          {matchSorter(filters[filter], inputFilterValue, {
            keys: ["name"],
            threshold: matchSorter.rankings.CONTAINS,
          })
            .filter(
              (value: any) =>
                !selectedFilters[filter].some((tag: Tag) => tag.id === value.id)
            )
            .sort((a: any, b: any) =>
              sortOptions[tagSort.key].compare(a, b, tagSort.order)
            )
            .map((tag: any) => {
              return (
                <InlineTagById
                  organizationId={tag?.organization_id}
                  id={tag?.id}
                  key={tag?.id}
                  onClick={() => {
                    setSelectedFilters({
                      ...selectedFilters,
                      [filter]: [...selectedFilters[filter], tag],
                    });
                  }}
                />
              );
            })}
        </div>
      </>
    );
  };

  const listItemButton = (
    value: any,
    checked: boolean,
    filter: string,
    onClick: () => void
  ) => {
    return (
      <ListItemButton
        key={value}
        style={{
          minHeight: "2.5rem",
          margin: "0.25rem 0",
          padding: "0 5px",
        }}
        onClick={onClick}
      >
        <ListItemIcon
          style={{
            minWidth: "auto",
          }}
        >
          <Checkbox
            color="primary"
            size="small"
            className="checkbox"
            checked={checked}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant="body2"
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {detexify(value, false)}
            </Typography>
          }
        />
      </ListItemButton>
    );
  };

  return (
    <RootContainer>
      <Button
        size="small"
        disabled={Object.values(filters).every(
          (arr) => Array.isArray(arr) && arr.length === 0
        )}
        onClick={(e) => {
          setMenuOpen(e.currentTarget);
          setDefaultFilter();
        }}
        className={clsx("filter-button", {
          selected: isFilterChecked,
          opened: Boolean(menuOpen) && !isFilterChecked,
        })}
        startIcon={
          <FilterListOutlinedIcon
            className={isFilterChecked ? "filterCheckedIcon" : ""}
            fontSize="small"
          />
        }
      >
        Filters
      </Button>
      {menuOpen && (
        <ClickAwayListener onClickAway={() => setMenuOpen(null)}>
          <PopperContainer
            open={!!menuOpen}
            anchorEl={menuOpen}
            placement="bottom-start"
          >
            <Box
              style={{
                margin: "0.5rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  flexWrap: "wrap",
                }}
              >
                {Object.keys(filters).map((filter: string) => {
                  return (
                    <Button
                      className="filterButtonSecondary"
                      key={filter}
                      onClick={() => setSelectedFilter(filter)}
                      color="primary"
                      size="small"
                      variant={
                        selectedFilters[filter]?.length
                          ? "contained"
                          : "outlined"
                      }
                      disabled={filters[filter].length === 0}
                      endIcon={
                        !!selectedFilters[filter]?.length && (
                          <Close
                            onClick={(e) => {
                              e.stopPropagation();
                              clearFilter(filter);
                            }}
                            fontSize="small"
                          />
                        )
                      }
                    >
                      <Typography
                        variant="body2"
                        className={clsx("filterButtonText", {
                          selectedButtonText: selectedFilters[filter]?.length,
                        })}
                      >
                        {getPlaceholder(selectedFilters[filter], filter)}
                      </Typography>
                    </Button>
                  );
                })}
              </Box>
              <IconButton
                className="clearFilterButton"
                onClick={() => clearAllFilters()}
                disabled={!isFilterChecked}
                size="small"
                color="primary"
              >
                <FilterListOffOutlinedIcon fontSize="small" />
              </IconButton>
            </Box>
            {selectedFilter && (
              <>
                <FormControl fullWidth className="formInput">
                  <OutlinedInput
                    color="primary"
                    size="small"
                    value={inputFilterValue}
                    autoFocus
                    placeholder={`Search ${selectedFilter}`}
                    onChange={(event) => {
                      setInputFilterValue(event.target.value);
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchOutlined fontSize="small" />
                      </InputAdornment>
                    }
                    endAdornment={
                      <>
                        {inputFilterValue.length > 0 && (
                          <IconButton onClick={() => setInputFilterValue("")}>
                            <ClearOutlined fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    }
                  />
                </FormControl>
                {selectedFilter === "tags" ? (
                  tagMenu(selectedFilter)
                ) : (
                  <>
                    {selectedFilters[selectedFilter].length > 0 && (
                      <>
                        <List
                          style={{
                            maxHeight: 190,
                            overflow: "auto",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {selectedFilters[selectedFilter].map((value: any) => {
                            return listItemButton(
                              value,
                              true,
                              selectedFilter,
                              () => {
                                const newFilterData = selectedFilters[
                                  selectedFilter
                                ].filter((data: any) => data !== value);
                                setSelectedFilters({
                                  ...selectedFilters,
                                  [selectedFilter]: newFilterData,
                                });
                              }
                            );
                          })}
                        </List>
                      </>
                    )}
                    <Divider style={{ margin: "8px 0" }} />
                    <List
                      style={{
                        maxHeight: 415,
                        overflow: "auto",
                        paddingTop: 0,
                      }}
                    >
                      <ListSubheader
                        style={{
                          lineHeight: "2rem",
                          color: "#000",
                          wordBreak: "break-word",
                        }}
                      >
                        <Typography variant="body2">
                          {inputFilterValue.length === 0
                            ? `${
                                selectedFilter.charAt(0).toUpperCase() +
                                selectedFilter.slice(1)
                              }`
                            : `Results for "${
                                inputFilterValue.charAt(0).toUpperCase() +
                                inputFilterValue.slice(1)
                              }"`}
                        </Typography>
                      </ListSubheader>
                      {/* limit of 20 items in list  */}
                      {inputFilterValue.length === 0 ? (
                        <>
                          {filters[selectedFilter]
                            .filter(
                              (value: string) =>
                                !selectedFilters[selectedFilter].includes(value)
                            )
                            .slice(0, 20)
                            .map((value: any) => {
                              return listItemButton(
                                value,
                                false,
                                selectedFilter,
                                () => {
                                  setSelectedFilters({
                                    ...selectedFilters,
                                    [selectedFilter]: [
                                      ...selectedFilters[selectedFilter],
                                      value,
                                    ],
                                  });
                                }
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {matchSorter(
                            filters[selectedFilter],
                            inputFilterValue,
                            {
                              threshold: matchSorter.rankings.CONTAINS,
                            }
                          )
                            .filter(
                              (value: string) =>
                                !selectedFilters[selectedFilter].includes(value)
                            )
                            .slice(0, 20)
                            .map((value: any) => {
                              return listItemButton(
                                value,
                                false,
                                selectedFilter,
                                () => {
                                  setSelectedFilters({
                                    ...selectedFilters,
                                    [selectedFilter]: [
                                      ...selectedFilters[selectedFilter],
                                      value,
                                    ],
                                  });
                                }
                              );
                            })}
                        </>
                      )}
                    </List>
                  </>
                )}
              </>
            )}
          </PopperContainer>
        </ClickAwayListener>
      )}
    </RootContainer>
  );
};

export default DocumentFilter;
