/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Container,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import {
  selectCurrentOrganizationId,
  selectUser,
  setCurrentOrganizationId,
} from "store/features/session/slice";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { setSideBarOpen } from "store/features/browser/slice";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useDocuments } from "api/documentService";
import LoadingOverlay from "components/helpers/LoadingOverlay";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import { isAdmin } from "models/components/Permissions.models";
import { useLocation, useNavigate } from "react-router-dom";
import { useUsers } from "api/userService";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ChatBubbleOutlinedIcon from "@mui/icons-material/ChatBubbleOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import { Wrapper } from "components/Browser/AiChat/AiChat-styles";
import {
  AILanguage,
  AILanguages,
  maxAmountOfCharacters,
  terminal_status_names,
} from "utils/aiHelpers";
import { selectAILanguage, setAILanguage } from "store/features/ai/slice";
import clsx from "clsx";
import {
  AISession,
  QAListResponse,
  QARequestBody,
  QAResponse,
} from "models/api/response.types";
import { ClearOutlined } from "@mui/icons-material";
import aiService, { useAiQA, useAISessions } from "api/aiService";
import AISessionDialog from "components/Dialogs/AISessionDialog";
import { TypeAnimation } from "react-type-animation";
import { useQueryClient } from "@tanstack/react-query";
import handleAxiosError from "utils/handleAxiosAlert";
import { AxiosError } from "axios";
import moment from "moment";
import {
  useOrganizationUsage,
  useOrganizations,
} from "api/organizationService";
import AISessionSelector from "components/helpers/AISessionSelector";
import AiChatOnboarding from "components/Onboarding/AiChatOnboarding";
import AIChatMessage from "components/helpers/AIChatMessage";
import MainLoader from "components/helpers/MainLoader";
import OpenAiProvider from "components/helpers/OpenAiProvider";
import { APP_TITLE, companyFeatures, companySettings } from "company-config";
import { canUseMultidocChat, isTrialExist } from "utils/plans";
import AIChatContextSidebar from "components/Browser/AiChat/AIChatContextSidebar";
import { exportAIChatAsExcel } from "utils/aiChatExport";
import ShareAIChatSessionDialog from "components/Dialogs/ShareAIChatSessionDialog";
import { addAlert } from "store/features/general/slice";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import routePaths from "routes/routePaths";

const AiChat: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const organizationToAccess = urlSearchParams.get("organizationId");
  const sessionToView = urlSearchParams.get("sessionId");
  const currentUser = useSelector(selectUser);
  const language = useSelector(selectAILanguage);
  const { organizations } = useOrganizations(currentUser?.id);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { organizationUsage, usageQueryKey } = useOrganizationUsage(
    currentOrganizationId
  );
  const { users, getCachedUserById } = useUsers(currentOrganizationId);
  const [selectedSession, setSelectedSession] = useState<AISession | undefined>(
    undefined
  );
  const {
    aiSessions,
    createAISessionMutation,
    getCachedAISessionById,
    deleteAISessionMutation,
    aiSessiosIsFetching,
  } = useAISessions(currentOrganizationId || 0);
  const { qaMessages, qaMessagesQueryKey, qaMessagesIsFetching } = useAiQA(
    selectedSession?.id
  );
  const { documents } = useDocuments(currentOrganizationId);
  const { getCachedOrganizationById } = useOrganizations(currentUser?.id);
  const [currentOrganization] = getCachedOrganizationById(
    currentOrganizationId || -1
  );
  const [sessionToUpdate, setSessionToUpdate] = useState<AISession | undefined>(
    undefined
  );
  const [sessionToDelete, setSessionToDelete] = useState<AISession | undefined>(
    undefined
  );
  const [confirmSessionDeletion, setConfirmSessionDeletion] =
    useState<boolean>(false);
  const [openAiSessionDialog, setOpenAiSessionDialog] =
    useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [query, setQuery] = useState<string>("");
  const [useHistory, setUseHistory] = useState<boolean>(true);
  const [optionsCollapsed, setOptionsCollapsed] = useState<boolean>(true);
  const [restrictToContext, setRestrictToContext] = useState<boolean>(true);
  // loader on render for sessions
  const [limitExceeded, setLimitExceeded] = useState<boolean>(false);
  const [openChatContext, setOpenChatContext] = useState<boolean>(false);
  const [addSourcesFromContext, setAddSourcesFromContext] =
    useState<boolean>(false);
  const [aiModel, setAiModel] = useState<"gpt-4o-mini-16k" | "gpt-4o">(
    companySettings.defaultMultiDocChatModel
  );
  const [shareSession, setShareSession] = useState<AISession | undefined>(
    undefined
  );
  const currentUserRole = users?.find((u) => u.id === currentUser?.id)?.role;

  // users that have less than Advanced tier and no trial
  // can't access ai chat
  const readOnly =
    !canUseMultidocChat(organizationUsage?.usage_limits.ai_credit_limit) &&
    !isTrialExist(currentOrganization?.created_at);

  useEffect(() => {
    if (selectedSession) {
      setSelectedSession(undefined);
    }
  }, [currentOrganizationId]);

  const hideParams = () => {
    const newUrl = `${window.location.pathname}`;
    window.history.replaceState({}, "", newUrl);
  };

  const selectURLSession = () => {
    const [organization] = getCachedOrganizationById(
      parseInt(organizationToAccess || "-1", 10)
    );
    if (organization?.id === currentOrganizationId) {
      const sessionId = parseInt(sessionToView || "-1", 10);
      const sessionToUse = aiSessions?.find((sess) => sess.id === sessionId);
      if (sessionToUse) {
        setSelectedSession(sessionToUse);
      } else {
        dispatch(
          addAlert({
            severity: "error",
            autoHideDuration: 5000,
            alert: {
              message:
                "The session either does not exist or you do not have the necessary access permissions.",
            },
          })
        );
        hideParams();
      }
    } else {
      dispatch(setCurrentOrganizationId(organization?.id));
    }
  };

  useEffect(() => {
    if (aiSessions && !selectedSession && organizations) {
      if (sessionToView && organizationToAccess) {
        selectURLSession();
      } else {
        if (sessionToView || organizationToAccess) {
          hideParams();
        }
        setSelectedSession(aiSessions[0]);
      }
    }
  }, [aiSessions, selectedSession, sessionToView, organizations]);

  useEffect(() => {
    if (aiSessions && selectedSession) {
      const [currentSession] = getCachedAISessionById(selectedSession.id);
      if (JSON.stringify(currentSession) !== JSON.stringify(selectedSession)) {
        setSelectedSession(currentSession);
      }
    }
  }, [sessionToUpdate]);

  const chooseLanguage = (lang: AILanguage) => {
    dispatch(setAILanguage(lang));
    setMenuAnchor(null);
  };

  const polling = useMemo(() => {
    return (
      qaMessages &&
      qaMessages.some(
        (response) => !terminal_status_names.includes(response.task_status)
      )
    );
  }, [qaMessages]);

  const documentsToUse = useMemo(() => {
    if (selectedSession?.document_ids) {
      return selectedSession?.document_ids;
    }
    return [];
  }, [selectedSession]);

  const noReferencesFound = documentsToUse.length === 0;

  const showPlaceholder = useMemo(() => {
    return (
      !noReferencesFound &&
      (qaMessages?.length === 0 || !qaMessages) &&
      !qaMessagesIsFetching &&
      !aiSessiosIsFetching &&
      !limitExceeded &&
      !polling
    );
  }, [
    noReferencesFound,
    qaMessages,
    qaMessagesIsFetching,
    aiSessiosIsFetching,
    limitExceeded,
    polling,
    selectedSession,
  ]);

  // polling data , await to finish all tasks
  useEffect(() => {
    let intervalId: any;
    if (polling && qaMessages) {
      intervalId = setInterval(() => {
        if (
          !qaMessages.some(
            (response) => !terminal_status_names.includes(response.task_status)
          )
        ) {
          document.getElementById("chat-content")?.scrollTo({ top: 0 });
        }
        queryClient.invalidateQueries(qaMessagesQueryKey);
      }, 1000);
    }
    return () => {
      queryClient.invalidateQueries(usageQueryKey);
      clearInterval(intervalId);
    };
  }, [polling, qaMessages]);

  const defaultPostData: QARequestBody = useMemo(() => {
    // always use document for reference use_context = true
    return {
      query,
      language,
      restrict_to_context: restrictToContext,
      use_context: true,
      use_history: useHistory,
      chat_model: aiModel,
    };
  }, [query, language, restrictToContext, useHistory, aiModel]);

  const resetSession = () => {
    if (selectedSession) {
      aiService.resetAISession(selectedSession.id).then(() => {
        queryClient.setQueryData(qaMessagesQueryKey, []);
      });
    }
  };

  const postQuestion = (session: AISession, message?: QARequestBody) => {
    if (
      organizationUsage &&
      organizationUsage.usage_limits.ai_credits - 8 < 0
    ) {
      setLimitExceeded(true);
    } else {
      aiService
        .postQuestion(session.id, message || defaultPostData)
        .then(() => {
          setQuery("");
          queryClient.invalidateQueries(qaMessagesQueryKey);
          setTimeout(() => {
            document.getElementById("chat-content")?.scrollTo({ top: 0 });
          }, 300);
        })
        .catch((err) => {
          handleAxiosError(err as AxiosError, dispatch);
        });
    }
  };

  const createDefaultSessionOnQuery = () => {
    createAISessionMutation.mutate(
      {
        document_ids: [],
        meta: {
          name: query,
          color: undefined,
        },
        organization_id: currentOrganizationId || 0,
        user_id: currentUser?.id || 0,
        visibility: "private",
        is_multidoc: true,
      },
      {
        onSuccess: ({ data: newSession }) => {
          postQuestion(newSession);
        },
      }
    );
  };

  const messages: { [key: string]: QAListResponse } = useMemo(() => {
    if (qaMessages) {
      return qaMessages
        .sort((a, b) => a.created_at.localeCompare(b.created_at))
        .reduce((grouped: any, item) => {
          const date = moment(item.created_at);
          const key = date.format("YYYY-MM-DD");

          if (!grouped[key]) {
            grouped[key] = [];
          }

          grouped[key].push(item);

          return grouped;
        }, {});
    }
    return undefined;
  }, [qaMessages]);

  // need for response continuation
  const recentMessage = useMemo(() => {
    if (messages) {
      const recentKeyDate = Object.keys(messages).pop();
      if (recentKeyDate) {
        const recentMessages = messages[recentKeyDate];
        return recentMessages[recentMessages.length - 1];
      }
      return undefined;
    }
    return undefined;
  }, [messages]);

  const showContinueButton = useMemo(() => {
    return recentMessage?.meta?.finish_reason === "length" && !readOnly;
  }, [recentMessage, readOnly]);

  const generateContinueResponse = () => {
    if (recentMessage && selectedSession) {
      postQuestion(selectedSession, {
        query: "Please continue your response.",
        language,
        restrict_to_context: restrictToContext,
        use_context: true,
        use_history: true,
        chat_model: aiModel,
        meta: {
          search_query:
            recentMessage?.meta?.search_query || recentMessage.query,
        },
      });
    }
  };

  if (!organizationUsage || !aiSessions || !currentOrganizationId) {
    return <MainLoader />;
  }

  return (
    <>
      <Wrapper className="ai-chat">
        <OpenAiProvider />
        {aiSessions.length > 0 ? (
          <Box className="content">
            {!smScreen ? (
              <Box className="sessions-large">
                {!readOnly && (
                  <Button
                    className="add-chat"
                    color="primary"
                    variant="contained"
                    size="medium"
                    startIcon={<AddIcon fontSize="medium" />}
                    onClick={() => {
                      setOpenAiSessionDialog(true);
                    }}
                  >
                    Create new chat
                  </Button>
                )}
                <List className="session-list">
                  {aiSessions
                    ?.sort((a, b) => b.created_at.localeCompare(a.created_at))
                    .map((session) => {
                      const [sessionUser] = getCachedUserById(session.user_id);
                      return (
                        <ListItemButton
                          className="list-button"
                          selected={selectedSession?.id === session.id}
                          key={session.id}
                          onClick={() => {
                            setSelectedSession(session);
                          }}
                        >
                          <ListItemIcon>
                            {session.meta.color ? (
                              <ChatBubbleOutlinedIcon
                                fontSize="small"
                                sx={{
                                  fill: session.meta.color,
                                }}
                              />
                            ) : (
                              <ChatBubbleOutlineOutlinedIcon
                                fontSize="small"
                                color="action"
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText>
                            <Typography variant="body2">
                              {session.meta.name}
                            </Typography>
                          </ListItemText>
                          <Box className="actions">
                            {session.user_id === currentUser?.id && (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSessionToUpdate(session);
                                    setOpenAiSessionDialog(true);
                                  }}
                                >
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSessionToDelete(session);
                                    setConfirmSessionDeletion(true);
                                  }}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </>
                            )}
                          </Box>
                          {session.visibility === "shared" && (
                            <Tooltip
                              placement="top"
                              enterDelay={500}
                              title={
                                sessionUser?.id === currentUser?.id
                                  ? "Chat is shared within the workspace"
                                  : `Shared by ${sessionUser?.name}`
                              }
                            >
                              <VisibilityIcon
                                className="visibility"
                                color="primary"
                                fontSize="inherit"
                                sx={{ ml: 1 }}
                              />
                            </Tooltip>
                          )}
                        </ListItemButton>
                      );
                    })}
                </List>
              </Box>
            ) : (
              <Box className="sessions-small">
                <IconButton
                  className="sidebar-close-icon"
                  size="medium"
                  onClick={() => dispatch(setSideBarOpen(true))}
                  color="primary"
                >
                  <MenuOutlinedIcon fontSize="medium" color="action" />
                </IconButton>
                {!readOnly && (
                  <Tooltip
                    enterDelay={500}
                    title="Create new chat"
                    placement="top"
                  >
                    <IconButton
                      size="medium"
                      className="add-session-button"
                      onClick={() => {
                        setOpenAiSessionDialog(true);
                      }}
                    >
                      <AddIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                )}
                <AISessionSelector
                  organizationId={currentOrganizationId}
                  selectedSession={selectedSession}
                  setSelectedSession={setSelectedSession}
                  sessionToUpdate={(session: AISession) => {
                    setSessionToUpdate(session);
                    setOpenAiSessionDialog(true);
                  }}
                />
              </Box>
            )}
            <Box className="chat">
              <Container className="dialog-container" maxWidth="xl">
                <Box className="chat-container">
                  <Box className="chat-header">
                    <Box
                      sx={{
                        marginLeft: "auto",
                        display: "flex",
                        gap: "1rem",
                      }}
                    >
                      {qaMessages && qaMessages.length > 0 && (
                        <>
                          <Button
                            color="primary"
                            variant="text"
                            size="small"
                            startIcon={<ShareOutlinedIcon fontSize="small" />}
                            onClick={() => {
                              setShareSession(selectedSession);
                            }}
                          >
                            Share
                          </Button>
                          <Button
                            color="primary"
                            variant="text"
                            size="small"
                            startIcon={
                              <FileUploadOutlinedIcon fontSize="small" />
                            }
                            onClick={() => {
                              exportAIChatAsExcel(
                                qaMessages,
                                selectedSession?.meta?.name,
                                users
                              );
                            }}
                          >
                            Export
                          </Button>
                          {!readOnly && (
                            <>
                              <Button
                                color="primary"
                                variant="text"
                                size="small"
                                startIcon={
                                  <RestartAltOutlinedIcon fontSize="small" />
                                }
                                onClick={resetSession}
                              >
                                Reset
                              </Button>
                            </>
                          )}
                        </>
                      )}
                      {!readOnly && (
                        <Button
                          color="primary"
                          variant="text"
                          size="small"
                          startIcon={<LanguageIcon fontSize="small" />}
                          endIcon={<ExpandMoreIcon fontSize="small" />}
                          onClick={(event) => {
                            setMenuAnchor(event.currentTarget);
                          }}
                        >
                          {language}
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box
                    id="chat-content"
                    sx={{
                      ...((aiSessiosIsFetching ||
                        (qaMessagesIsFetching && !polling) ||
                        !documents) && {
                        height: "100%",
                      }),
                      ...(showContinueButton && {
                        paddingBottom: "3rem !important",
                      }),
                    }}
                  >
                    {(aiSessions?.length > 0 && !selectedSession) ||
                    (qaMessagesIsFetching && !polling) ? (
                      <LoadingOverlay />
                    ) : (
                      <>
                        {(limitExceeded || noReferencesFound) && (
                          <Fade in>
                            <Box className="custom-message">
                              {noReferencesFound && (
                                <>
                                  <Typography variant="body2">
                                    To start chatting, you&apos;ll need to add
                                    documents. Click the &quot;Add Sources&quot;
                                    button to include your documents in the chat
                                  </Typography>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={() => {
                                      setAddSourcesFromContext(true);
                                    }}
                                    sx={{ mt: 1 }}
                                  >
                                    Add sources
                                  </Button>
                                </>
                              )}
                              {limitExceeded && (
                                <>
                                  <Typography variant="body2">
                                    Your credit limit has been exceeded. To
                                    continue using {APP_TITLE} AI, please either
                                    update your plan or increase your credit
                                    limit. Thank you.
                                  </Typography>
                                  {isAdmin(currentUserRole) && (
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      onClick={() => {
                                        navigate(routePaths.workspaceBilling);
                                      }}
                                      sx={{ mt: 1 }}
                                    >
                                      Upgrade / Add credit limit
                                    </Button>
                                  )}
                                </>
                              )}
                            </Box>
                          </Fade>
                        )}
                        {messages && Object.keys(messages).length > 0 && (
                          <Box className="message-container">
                            {Object.entries(messages).map(
                              (messagesKey, index) => {
                                const currentMessages = messagesKey[1];
                                const currentDate = messagesKey[0];
                                const date =
                                  moment(currentDate).format("MMM D, YYYY");
                                return (
                                  <>
                                    <Box
                                      className="date"
                                      key={`${messagesKey[0]} + ${index}`}
                                    >
                                      <Divider>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          fontStyle="italic"
                                        >
                                          {date}
                                        </Typography>
                                      </Divider>
                                    </Box>
                                    <Box
                                      className="messages"
                                      key={messagesKey[0]}
                                    >
                                      {currentMessages.map(
                                        (message: QAResponse) => (
                                          <AIChatMessage
                                            organizationId={
                                              currentOrganizationId || -1
                                            }
                                            key={message.id}
                                            message={message}
                                            showSources
                                          />
                                        )
                                      )}
                                    </Box>
                                  </>
                                );
                              }
                            )}
                          </Box>
                        )}
                        {showPlaceholder && (
                          <TypeAnimation
                            cursor={false}
                            sequence={[
                              readOnly
                                ? "Don't have access to chat, please upgrade your subscription."
                                : "Type in your question and we will provide you with the most relevant information. Get quick and accurate answers with ease.",
                            ]}
                            className="custom-message"
                            style={
                              readOnly
                                ? {
                                    marginTop: "0.5rem",
                                  }
                                : {}
                            }
                            speed={75}
                          />
                        )}
                      </>
                    )}
                  </Box>
                  {showContinueButton && (
                    <Button
                      className="continue-button"
                      size="medium"
                      color="primary"
                      variant="outlined"
                      startIcon={<ArrowRightIcon fontSize="medium" />}
                      onClick={generateContinueResponse}
                    >
                      Continue generating
                    </Button>
                  )}
                </Box>
                {!readOnly && (
                  <Box className="form-container">
                    <Button
                      className="collapse-button"
                      fullWidth
                      variant="text"
                      color="primary"
                      size="small"
                      onClick={() => {
                        setOptionsCollapsed(!optionsCollapsed);
                      }}
                    >
                      <KeyboardArrowUpIcon
                        className={clsx("option-icon", {
                          opened: !optionsCollapsed,
                        })}
                        fontSize="medium"
                      />
                    </Button>
                    <Collapse in={!optionsCollapsed}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        <Typography className="body2" color="textSecondary">
                          GPT-4o-mini
                        </Typography>
                        <Switch
                          color="primary"
                          size="small"
                          checked={aiModel === "gpt-4o"}
                          onChange={() => {
                            setAiModel(
                              aiModel === "gpt-4o"
                                ? "gpt-4o-mini-16k"
                                : "gpt-4o"
                            );
                          }}
                        />
                        <Typography className="body2" color="textSecondary">
                          GPT-4o
                        </Typography>
                      </Box>
                      <FormControl className="history-container">
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              className="primary"
                              checked={useHistory}
                              onChange={() => {
                                setUseHistory(!useHistory);
                              }}
                            />
                          }
                          label={
                            <Typography color="textSecondary" variant="body2">
                              New messages related to previous questions
                            </Typography>
                          }
                        />
                      </FormControl>
                      <FormControl className="external-knowledge-container">
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              className="primary"
                              checked={restrictToContext}
                              onChange={() => {
                                setRestrictToContext((cur) => !cur);
                              }}
                            />
                          }
                          label={
                            <Typography color="textSecondary" variant="body2">
                              Only use information contained in the text
                            </Typography>
                          }
                        />
                      </FormControl>
                    </Collapse>
                    <Box
                      className={clsx("question-container", {
                        "no-documents": documentsToUse.length === 0,
                      })}
                    >
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        sx={{
                          ...(query.length === maxAmountOfCharacters && {
                            color: theme.red.main,
                          }),
                        }}
                      >
                        Characters: {query.length}/{maxAmountOfCharacters}
                      </Typography>
                      <Box className="input-container">
                        {documentsToUse.length > 0 && (
                          <Button
                            className="manage-sources"
                            size="small"
                            variant="contained"
                            onClick={() => {
                              setOpenChatContext(true);
                            }}
                          >
                            Sources
                          </Button>
                        )}
                        <OutlinedInput
                          color="primary"
                          multiline
                          size="small"
                          autoFocus
                          maxRows={6}
                          fullWidth
                          value={query}
                          onChange={(e: any) => {
                            if (e.target.value.length < maxAmountOfCharacters) {
                              setQuery(e.target.value);
                            } else {
                              const result = e.target.value.substr(0, 400);
                              setQuery(result);
                            }
                          }}
                          placeholder="Write your question..."
                          sx={{
                            padding: "0.3rem 0.5rem",
                          }}
                          endAdornment={
                            <IconButton
                              size="small"
                              onClick={() => setQuery("")}
                            >
                              <ClearOutlined fontSize="small" />
                            </IconButton>
                          }
                          onKeyPress={(
                            event: React.KeyboardEvent<HTMLDivElement>
                          ) => {
                            if (
                              event.key === "Enter" &&
                              query.length > 0 &&
                              !polling &&
                              !limitExceeded &&
                              !noReferencesFound
                            ) {
                              event.preventDefault();
                              if (selectedSession) {
                                postQuestion(selectedSession);
                              } else {
                                createDefaultSessionOnQuery();
                              }
                            }
                          }}
                        />
                        <IconButton
                          size="medium"
                          color="primary"
                          disabled={
                            query.length === 0 ||
                            polling ||
                            limitExceeded ||
                            noReferencesFound
                          }
                          onClick={() => {
                            if (selectedSession) {
                              postQuestion(selectedSession);
                            } else {
                              createDefaultSessionOnQuery();
                            }
                          }}
                        >
                          <SendIcon fontSize="medium" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Container>
            </Box>
          </Box>
        ) : (
          <Box className="error-message">
            <img src="/img/new-chat.svg" alt="Create new chat" />
            {!readOnly ? (
              <>
                <Typography variant="h6" color="textSecondary" fontWeight={400}>
                  Please create a chat
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  size="medium"
                  startIcon={<AddIcon fontSize="medium" />}
                  onClick={() => {
                    setOpenAiSessionDialog(true);
                  }}
                >
                  Create new chat
                </Button>
              </>
            ) : (
              <>
                {companyFeatures.settings.canUpgradePlans ? (
                  <>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      fontWeight={400}
                    >
                      Only users with <strong>Advanced</strong> plan or above
                      can access this feature.
                    </Typography>
                    {isAdmin(currentUserRole) && (
                      <Button
                        color="primary"
                        variant="contained"
                        size="medium"
                        onClick={() => {
                          navigate(routePaths.workspaceBilling);
                        }}
                      >
                        Upgrade
                      </Button>
                    )}
                  </>
                ) : (
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    fontWeight={400}
                  >
                    Your Trial Has Been Expired! Contact Us for More Information
                  </Typography>
                )}
              </>
            )}
          </Box>
        )}
        <AIChatContextSidebar
          selectedSession={selectedSession}
          addSources={addSourcesFromContext}
          setAddSources={setAddSourcesFromContext}
          open={openChatContext}
          setOpen={setOpenChatContext}
          setSelectedSession={setSelectedSession}
        />
        <Menu
          id="basic-menu"
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          onClose={() => setMenuAnchor(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            style: {
              maxHeight: 200,
            },
          }}
        >
          {AILanguages.map((lang: AILanguage) => (
            <MenuItem
              key={lang}
              onClick={() => {
                chooseLanguage(lang);
              }}
            >
              <Typography variant="body2">{lang}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Wrapper>
      <ConfirmationDialog
        show={confirmSessionDeletion}
        title={`Remove ${sessionToDelete?.meta?.name} chat`}
        description="Are you sure you want to delete this chat? Please note that deleting this chat will also remove the entire conversation within it."
        sensitive
        buttonText="Delete"
        onClose={(confirm?: boolean) => {
          setConfirmSessionDeletion(false);
          if (confirm && sessionToDelete) {
            deleteAISessionMutation.mutate(sessionToDelete.id);
            if (selectedSession?.id === sessionToDelete.id) {
              setSelectedSession(undefined);
              setSessionToDelete(undefined);
            }
          }
        }}
      />
      {openAiSessionDialog && (
        <AISessionDialog
          isMultiDoc
          sessionToUpdate={sessionToUpdate}
          organizationId={currentOrganizationId}
          setOpen={(open: boolean, newSession) => {
            if (newSession) {
              setSelectedSession(newSession);
            }
            if (sessionToUpdate) {
              setSessionToUpdate(undefined);
            }
            setOpenAiSessionDialog(open);
          }}
        />
      )}
      <ShareAIChatSessionDialog
        session={shareSession}
        setSelectedSession={(updatedSession) => {
          setSelectedSession(updatedSession);
          setShareSession(updatedSession);
        }}
        close={() => {
          setShareSession(undefined);
        }}
      />
      {!readOnly &&
        selectedSession &&
        selectedSession.document_ids.length > 0 &&
        !currentUser?.meta?.citeOnboarding?.aiChatCompleted && (
          <AiChatOnboarding />
        )}
    </>
  );
};

export default AiChat;
