/* eslint-disable import/prefer-default-export */
import { Box, styled } from "@mui/material";

interface RootProps {
  isnew?: string;
  loading?: string;
  failed?: string;
  istrash?: string;
  isguest?: string;
  docviewerdisabled?: string;
  isfulltextsearch?: string;
}

export const RootContainer = styled(Box)<RootProps>(
  ({
    isnew,
    theme,
    loading,
    failed,
    istrash,
    isguest,
    docviewerdisabled,
    isfulltextsearch,
  }) => ({
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    transition: "background 0.15s, opacity 0.15s",
    backgroundColor: theme.background.light,
    borderBottom: `1px solid ${theme.grey.light}`,
    borderLeft: `1px solid ${theme.grey.light}`,
    borderRight: `1px solid ${theme.grey.light}`,
    padding: 0,
    paddingLeft: "0.5rem",
    height: isfulltextsearch ? "90px" : "80px",
    cursor: "default",
    position: "relative",
    "&.selected-root": {
      background: theme.document.selected.main,
      "&:hover": {
        background: theme.document.selected.secondary,
      },
    },
    ...(!loading &&
      !failed &&
      !istrash &&
      !isguest && {
        cursor: "grab",
      }),
    ...(!loading && {
      "&:hover": {
        background: theme.document.hover.main,
        "& .tags": {
          visibility: "visible",
        },
        "& .document-tag-container": {
          opacity: 1,
        },
        "& .action-container button, .action-container .button": {
          visibility: "visible",
        },
      },
    }),
    "& .document-checkbox": {
      height: "fit-content",
      marginTop: "0.45rem",
      "&:not(.Mui-checked)": {
        color: "rgba(0, 0, 0, 0.12)",
      },
    },
    "& .favorites": {
      height: "fit-content",
      marginTop: "0.45rem",
      ...((loading || failed) && {
        opacity: 0.4,
      }),
    },
    "& .doc-type-icon": {
      padding: `${theme.spacing(1.8)} 0.3rem ${theme.spacing(1.8)} 0`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      rowGap: ".5rem",
      opacity: 0,
      "&.visible": {
        opacity: 1,
        ...((loading || failed) && {
          opacity: 0.4,
        }),
      },
    },
    "& .summary": {
      flex: 1,
      marginLeft: "0.3rem",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      padding: `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(
        1.5
      )} 0`,
      ...(loading && {
        opacity: 0.2,
      }),
    },
    "& .file-alert": {
      width: "1.375rem",
      height: "1.375rem",
    },
    "& .title-wrapper": {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      gap: "0.5rem",
    },
    "& .header-text": {
      ...(!loading && !failed && isnew
        ? {
            fontWeight: 700,
          }
        : {
            fontWeight: "normal",
          }),
      "&.document": {
        color: theme.palette.secondary.main,
        ...(!loading &&
          !docviewerdisabled && {
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }),
      },
      "&.table": {
        color: "#52796f",
        ...(!loading && {
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }),
      },
      display: "-webkit-box",
      WebkitLineClamp: "1",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      overflowWrap: "anywhere",
      fontSize: "16px",
    },
    "& .compact-document-data": {
      fontWeight: "normal",
      marginLeft: "auto",
      display: "-webkit-box",
      WebkitLineClamp: "1",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      overflowWrap: "anywhere",
      textOverflow: "ellipsis",
      fontSize: "14px",
      color: theme.palette.text.secondary,
      "& .journal": {
        color: theme.document.compact.journal,
      },
      "& .year": {
        color: theme.document.compact.year,
      },
    },
    "& .compact-info": {
      display: "flex",
      gap: "1rem",
      justifyContent: "space-between",
      alignItems: "center",
      "& .authors,& .search-snippet,& .table-description": {
        minWidth: "150px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "& p": {
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: "1",
          overflowWrap: "anywhere",
          minWidth: "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      "& .authors": {
        color: theme.document.compact.authors,
      },
      "& .search-snippet": {
        color: "#6c6c6c",
        "& p": {
          WebkitLineClamp: "2",
          "& em": {
            fontStyle: "normal",
          },
        },
      },
      "& .tags-wrapper": {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      },
    },
    "& .tags": {
      whiteSpace: "nowrap",
      visibility: "hidden",
    },
    "& .description-text": {
      fontSize: "14px",
      display: "-webkit-box",
      WebkitLineClamp: "1",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      "&.snippet,&.author": {
        color: theme.palette.text.secondary,
      },
      "&.error": {
        color: theme.red.main,
        fontStyle: "italic",
      },
    },
    "& .stepper-loader": {
      borderRadius: "16px",
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiStepper-root": {
        width: "100%",
        "& .MuiStepIcon-root": {
          width: "30px",
          height: "30px",
          color: theme.palette.primary.main,
          "&.Mui-completed": {
            color: "rgb(0, 161, 116)",
          },
        },
        "& .MuiStepConnector-root": {
          left: "calc(-50% + 40px)",
          right: "calc(50% + 40px)",
          "& .MuiStepConnector-line": {
            borderTopWidth: "5px",
            borderRadius: "16px",
          },
          "&.Mui-completed .MuiStepConnector-line": {
            borderColor: "rgb(0, 161, 116)",
          },
        },
      },
    },
    "& em": {
      backgroundColor: theme.notice.main,
      fontStyle: "normal",
    },
  })
);
