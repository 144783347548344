/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from "react";
import { Route, Navigate, BrowserRouter, Routes } from "react-router-dom";
import routePaths from "routes/routePaths";
import MainLoader from "components/helpers/MainLoader";
import Logout from "pages/Logout";
import AddDocuments from "pages/AddDocuments";
import Documents from "pages/Documents";
import AiTables from "pages/AiTables";
import AiTable from "pages/AiTable";
import AiChat from "pages/AiChat";
import AiCreate from "pages/AiCreate";
import ProfileMenu from "components/WorkspaceSettings/Profile/ProfileMenu";
import WorkspaceOverview from "components/WorkspaceSettings/WorkspaceOverview/WorkspaceOverview";
import MembersAndInvite from "components/WorkspaceSettings/MembersAndInvite";
import { companyFeatures } from "company-config";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { useSelector } from "react-redux";
import { useUsers } from "api/userService";
import { isGuest } from "models/components/Permissions.models";
import EmergencyAlert from "pages/EmergencyAlert";

// lazy rendering components
const Admin = React.lazy(() => import("pages/Admin"));
const CiteGen = React.lazy(() => import("pages/CiteGen"));
const AuthRoute = React.lazy(() => import("./AuthRoute"));
const AuthDocumentViewerStandalone = React.lazy(
  () => import("pages/DocumentViewerStandalone")
);
const Browser = React.lazy(() => import("pages/Browser"));
const Status = React.lazy(() => import("components/Admin/Status"));
const CrossRefApiProxy = React.lazy(
  () => import("components/Admin/CrossRefApiProxy")
);
const Organizations = React.lazy(
  () => import("components/Admin/Organizations")
);
const UserData = React.lazy(() => import("components/Admin/UserData"));
const Users = React.lazy(() => import("components/Admin/Users"));
const OrganizationData = React.lazy(
  () => import("components/Admin/OrganizationData")
);
const WorkspaceSettings = React.lazy(() => import("pages/WorkspaceSettings"));
const BillingPlans = React.lazy(
  () => import("components/WorkspaceSettings/BillingPlans/BillingPlans")
);
const AppRoute = React.lazy(() => import("routes/AppRoute"));
const Login = React.lazy(() => import("pages/Login"));
const SignUp = React.lazy(() => import("pages/SignUp"));
const ForgotPassword = React.lazy(() => import("pages/ForgotPassword"));
const SelectService = React.lazy(() => import("pages/SelectService"));

const Router: React.FC = () => {
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { users } = useUsers(currentOrganizationId);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const isDocViewerEnabled =
    !isGuest(currentUserRole) ||
    companyFeatures.app.guests.enableDocumentViewer;

  return (
    <Suspense fallback={<MainLoader />}>
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={
              user ? (
                <Navigate to={routePaths.browser} replace />
              ) : (
                <Navigate to={routePaths.login} replace />
              )
            }
          />
          <Route path={routePaths.default} element={<AppRoute />}>
            <Route path={routePaths.login} element={<Login />} />
            <Route
              path={routePaths.maintenance}
              element={<EmergencyAlert maintenance />}
            />
            <Route
              path={`${routePaths.browser}`}
              element={
                <AuthRoute>
                  <Browser />
                </AuthRoute>
              }
            >
              <Route path="" element={<Navigate to="documents" replace />} />
              <Route path="add" element={<AddDocuments />} />
              <Route path="documents" element={<Documents />} />
              <Route path="ai-tables" element={<AiTables />} />
              <Route path="ai-table/:id" element={<AiTable />} />
              <Route path="ai-chat" element={<AiChat />} />
              <Route path="ai-create" element={<AiCreate />} />
            </Route>
            {companyFeatures.settings.enableSettingsPage && (
              <>
                <Route path={`${routePaths.workspace}`}>
                  <Route
                    path=""
                    element={
                      <AuthRoute>
                        <WorkspaceSettings />
                      </AuthRoute>
                    }
                  >
                    <Route path="profile" element={<ProfileMenu />} />
                    <Route path="overview" element={<WorkspaceOverview />} />
                    <Route path="members" element={<MembersAndInvite />} />
                    <Route
                      path=""
                      element={<Navigate to="overview" replace />}
                    />
                  </Route>
                  <Route
                    path=""
                    element={
                      <AuthRoute>
                        <WorkspaceSettings />
                      </AuthRoute>
                    }
                  />
                </Route>
                {companyFeatures.settings.canUpgradePlans && (
                  <Route
                    path={routePaths.workspaceBilling}
                    element={
                      <AuthRoute>
                        <BillingPlans />
                      </AuthRoute>
                    }
                  />
                )}
              </>
            )}
            {companyFeatures.enableSecondaryApps && (
              <>
                <Route
                  path={routePaths.selectService}
                  element={<SelectService />}
                />
                <Route path={`${routePaths.citegen}`} element={<CiteGen />} />
              </>
            )}
            {companyFeatures.auth.enableSignUp && (
              <Route path={routePaths.signup} element={<SignUp />} />
            )}
            {isDocViewerEnabled && (
              <Route
                path={`${routePaths.documentView}/*`}
                element={
                  <AuthRoute>
                    <AuthDocumentViewerStandalone />
                  </AuthRoute>
                }
              />
            )}
            {companyFeatures.enablePetalRoutes && (
              <>
                <Route path={routePaths.discourseSSO} element={<Login />} />
                <Route path={routePaths.referral} element={<SignUp />} />
                <Route
                  path={`${routePaths.admin}`}
                  element={
                    <AuthRoute>
                      <Admin />
                    </AuthRoute>
                  }
                >
                  <Route
                    path=""
                    element={<Navigate to="dashboard" replace />}
                  />
                  <Route path="dashboard" element={<Status />} />
                  <Route path="crossref" element={<CrossRefApiProxy />} />
                  <Route path="organizations" element={<Organizations />} />
                  <Route
                    path="organizations/:id"
                    element={<OrganizationData />}
                  />
                  <Route path="users" element={<Users />} />
                  <Route path="users/:id" element={<UserData />} />
                </Route>
              </>
            )}
            <Route
              path={routePaths.forgotPasword}
              element={<ForgotPassword />}
            />
          </Route>
          <Route path={`${routePaths.logout}`} element={<Logout />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
