import { styled } from "@mui/material/styles";

const InlineTag = styled("div")(() => ({
  display: "inline-block",
  padding: "0rem 0.4rem",
  lineHeight: "0.8rem",
  borderRadius: "0.8rem",
  border: "1px solid rgba(127, 127, 127, 0.2)",
}));

export default InlineTag;
