import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmationDialog: React.FC<{
  show: boolean;
  title: string;
  description: string;
  buttonText: string;
  sensitive?: boolean;
  onClose: (confirm?: boolean) => void;
}> = ({ show, title, description, buttonText, sensitive, onClose }) => {
  return (
    <Dialog open={show} onClose={() => onClose()} fullWidth maxWidth="sm">
      <DialogTitle>
        {title}
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="medium"
          color={sensitive ? "error" : "primary"}
          onClick={() => onClose(true)}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
