import React, { MutableRefObject, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";
import formatBytes from "utils/formatBytes";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BrowserNotSupportedOutlinedIcon from "@mui/icons-material/BrowserNotSupportedOutlined";
import UsageBar from "components/helpers/UsageBar";
import { Close } from "@mui/icons-material";
import {
  getDoctype,
  getTabletype,
  maxConcurrentUploads,
  progressBarDebounceInterval,
  UploadTask,
  UploadTaskState,
  UploadTaskType,
} from "utils/upload.helpers";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { useOrganizationUsage } from "api/organizationService";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import { useDocuments } from "api/documentService";
import routePaths from "routes/routePaths";
import { useNavigate } from "react-router-dom";

export interface SpecialFileToUpload {
  // file from HTMLInputElement, etc.
  file: File;
  // documentId is 0 if creating new file
  documentId: number;
  isTable: boolean;
}

const DialogContainer = styled(Dialog)(({ theme }) => ({
  "& .flex": {
    display: "flex",
  },
  "& .fdc": {
    flexDirection: "column",
    width: "100%",
  },
  "& .box": {
    width: "100%",
    height: "18rem",
  },
  "& .boxInfo": {
    opacity: 0.5,
    marginLeft: "1rem",
  },
  "& .highlighted-text": {
    color: theme.palette.secondary.main,
  },
  "& .loader-container": {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    "& .loader": {
      width: "18px !important",
      height: "18px !important",
    },
  },
  "& td .status-icon": {
    width: "14px !important",
    height: "14px !important",
  },
}));

const SpecialFileToUpload: React.FC<{
  fileToUpload: SpecialFileToUpload;
  setFileToUpload: (file?: SpecialFileToUpload) => void;
}> = ({ fileToUpload, setFileToUpload }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const user = useSelector(selectUser);
  const { organizationUsage, usageIsLoading, usageQueryKey } =
    useOrganizationUsage(currentOrganizationId);
  const { documentsQueryKey } = useDocuments(currentOrganizationId);
  const { logAction } = useTelemetry();
  const lastRender = useRef<number>(performance.now());
  const totalUploadSize = fileToUpload.file.size;
  const [uploadStage, setUploadStage] = useState<number>(0);
  const [documentRowData, setDocumentRowData] = useState<any>(undefined);
  const upload = useRef<UploadTask>() as MutableRefObject<UploadTask>;

  const limitExceeded = organizationUsage
    ? organizationUsage.usage_limits.storage_bytes -
        organizationUsage.usage.storage.total_bytes -
        totalUploadSize <
      0
    : false;

  // Update row data from uploads ref
  const updateRowData = (force = false) => {
    if (
      performance.now() - lastRender.current > progressBarDebounceInterval ||
      force
    ) {
      setDocumentRowData({
        ...upload.current,
        filename: upload.current.file.name,
      });
      lastRender.current = performance.now();
    }
  };

  // monitor uploads to only send new requests when below maxConcurrentUploads
  const updateRequests = (forceUpdate = false) => {
    let queued = 0;
    let pending = 0;

    queued += upload.current.state === UploadTaskState.queued ? 1 : 0;
    pending +=
      upload.current.state === UploadTaskState.pending ||
      (upload.current.xhr?.readyState === 1 && upload.current.sent)
        ? 1
        : 0;
    // send pending requests if below maxConcurrentUploads
    if (pending < maxConcurrentUploads) {
      if (!upload.current.sent && upload.current.xhr?.readyState === 1) {
        upload.current.xhr.send(upload.current.formData);
        upload.current.sent = true;
      }
    }
    // keep updating requests until all requests are resolved
    if (queued + pending > 0) {
      window.setTimeout(() => {
        updateRequests();
      }, progressBarDebounceInterval);
    }
    updateRowData(forceUpdate);
  };

  // Makes a POST request using multipart/form-data to
  const uploadFile = (specialFileToUpload: SpecialFileToUpload) => {
    if (currentOrganizationId) {
      setUploadStage(1);
      const { file, documentId, isTable } = specialFileToUpload;
      const uploader = new UploadTask(file, currentOrganizationId);
      uploader.documentId = documentId;
      uploader.type = UploadTaskType.update;
      upload.current = uploader;
      // construct multipart/form-data
      const formData = new FormData();
      formData.append("file", file);
      // if creating new document
      const doctype = isTable ? getTabletype(file) : getDoctype(file);
      if (doctype !== "") {
        formData.append("doctype", doctype);
      } else {
        upload.current.errorMessage = `Unsupported filetype`;
        upload.current.state = UploadTaskState.invalid;
        updateRowData();
        setUploadStage(2);
        return;
      }
      upload.current.formData = formData;
      // use xhr instead of fetch for onprogress event handler
      upload.current.xhr = new XMLHttpRequest();
      const url = `${axios.defaults.baseURL}/api/document/${specialFileToUpload.documentId}/update`;
      upload.current.xhr.open("POST", url);
      upload.current.xhr.setRequestHeader(
        "Authorization",
        axios.defaults.headers.common.Authorization as string
      );
      // update upload onprogress event
      upload.current.xhr.upload.addEventListener(
        "progress",
        (e: ProgressEvent<XMLHttpRequestEventTarget>) => {
          upload.current.loaded = e.loaded;
          upload.current.total = e.total;
          upload.current.state = UploadTaskState.pending;
          upload.current.timestamp = performance.now();
          updateRowData();
        },
        false
      );
      // update upload loadend event
      upload.current.xhr.addEventListener("loadend", () => {
        if (upload.current.xhr) {
          upload.current.loaded = upload.current.total; // ensure progress bars reach 100%
          try {
            upload.current.response = JSON.parse(
              upload.current.xhr.responseText
            );
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, upload.current.xhr.responseText);
          }
          if (upload.current.xhr.status === 200 && upload.current.response) {
            upload.current.documentId = upload.current.response.id;
            upload.current.state = UploadTaskState.fulfilled;
            upload.current.timestamp = performance.now();
            queryClient.invalidateQueries(documentsQueryKey);
            queryClient.invalidateQueries(usageQueryKey);
            if (user) {
              logAction(telemetryAction.upload_files, {
                success: true,
                filename: upload.current.file.name,
                organization_id: currentOrganizationId,
                document_id: upload.current.documentId,
                size: upload.current.file.size,
              });
            }
            setUploadStage(2);
          } else if (
            upload.current.xhr.status === 409 &&
            upload.current.response
          ) {
            // assuming doc is duplicated , need to extract docId from error message
            let error = upload.current.response?.error;
            if (error) {
              error = error.replace(/[^0-9]/g, "");
              upload.current.documentId = parseInt(error, 10);
            }
            upload.current.state = UploadTaskState.duplicate;
            upload.current.timestamp = performance.now();
            if (upload.current.documentId) {
              if (user) {
                logAction(telemetryAction.upload_files, {
                  success: true,
                  filename: upload.current.file.name,
                  organization_id: currentOrganizationId,
                  document_id: upload.current.documentId,
                  size: upload.current.file.size,
                });
              }
            }
            setUploadStage(2);
          } else {
            upload.current.state = UploadTaskState.rejected;
            upload.current.errorMessage = upload.current.xhr.responseText;
            upload.current.timestamp = performance.now();
            if (user && currentOrganizationId) {
              logAction(telemetryAction.upload_files, {
                success: false,
                filename: upload.current.file.name,
                organization_id: currentOrganizationId,
                size: upload.current.file.size,
              });
            }
            setUploadStage(2);
          }
          updateRequests(true);
          updateRowData(true);
        }
      });
      upload.current.state = UploadTaskState.queued;
      updateRequests(true);
    }
  };

  const onClose = () => {
    if (uploadStage !== 1) {
      setFileToUpload(undefined);
    }
  };

  const getStatus = (status: string) => {
    switch (status) {
      case "queued":
        return <CircularProgress className="status-icon" color="secondary" />;
      case "pending":
        return <CircularProgress className="status-icon" color="secondary" />;
      case "success":
        return <CheckCircleIcon className="status-icon" color="success" />;
      case "fulfilled":
        return <CheckCircleIcon className="status-icon" color="success" />;
      case "duplicate":
        return (
          <Tooltip enterDelay={500} title="Duplicate file">
            <ContentCopyIcon className="status-icon" color="secondary" />
          </Tooltip>
        );
      case "rejected":
        return (
          <Tooltip enterDelay={500} title="Failed to process">
            <CancelIcon className="status-icon" color="error" />
          </Tooltip>
        );
      case "invalid":
        return (
          <Tooltip enterDelay={500} title="Unsupported file type">
            <BrowserNotSupportedOutlinedIcon
              className="status-icon"
              color="warning"
            />
          </Tooltip>
        );
      default:
        return (
          <Tooltip enterDelay={500} title="Await to upload">
            <HourglassEmptyOutlinedIcon
              className="status-icon"
              color="primary"
            />
          </Tooltip>
        );
    }
  };

  return (
    <>
      <DialogContainer open onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>
          Upload {fileToUpload.isTable ? "table" : "document"}
          <IconButton disabled={uploadStage === 1} onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Paper elevation={0}>
              <TableContainer
                sx={{
                  maxHeight: 300,
                }}
              >
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>File name</TableCell>
                      <TableCell align="left">Size</TableCell>
                      <TableCell align="left">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" align="left">
                        {fileToUpload.file.name}
                      </TableCell>
                      <TableCell align="left">
                        {formatBytes(fileToUpload.file.size)}
                      </TableCell>
                      <TableCell align="left">
                        {getStatus(
                          documentRowData ? documentRowData.state : "await"
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
          {organizationUsage && (
            <Box
              sx={{
                mt: 2,
              }}
            >
              <UsageBar
                loading={usageIsLoading}
                label="Storage space"
                usingLabel={`${formatBytes(
                  organizationUsage?.usage.storage.total_bytes
                )}`}
                totalLabel={`${formatBytes(
                  organizationUsage?.usage_limits.storage_bytes
                )}`}
                data={[
                  {
                    label: `Used (${formatBytes(
                      organizationUsage?.usage.storage.total_bytes
                    )})`,
                    percent:
                      (100 * organizationUsage.usage.storage.total_bytes) /
                      organizationUsage.usage_limits.storage_bytes,
                    color: theme.palette.info.main,
                  },
                  {
                    label: `To upload (${formatBytes(totalUploadSize)})`,
                    percent:
                      (100 / organizationUsage.usage_limits.storage_bytes) *
                      totalUploadSize,
                    color: theme.palette.warning.light,
                  },
                  {
                    label: `Availabe (${formatBytes(
                      organizationUsage.usage_limits.storage_bytes -
                        (organizationUsage.usage.storage.total_bytes +
                          totalUploadSize)
                    )})`,
                    percent:
                      ((organizationUsage.usage_limits.storage_bytes -
                        (organizationUsage.usage.storage.total_bytes +
                          totalUploadSize)) /
                        (organizationUsage.usage.storage.total_bytes +
                          totalUploadSize +
                          (organizationUsage.usage_limits.storage_bytes -
                            (organizationUsage.usage.storage.total_bytes +
                              totalUploadSize)))) *
                      100,
                    color: theme.palette.grey[200],
                  },
                ]}
              />
            </Box>
          )}
          {limitExceeded && (
            <Alert sx={{ mt: 2 }} severity="error">
              You have reached the storage limit. You can delete some documents
              to upload or upgrade your plan.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          {limitExceeded ? (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                navigate(routePaths.workspaceBilling);
              }}
            >
              Upgrade
            </Button>
          ) : (
            <>
              {uploadStage === 0 && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    uploadFile(fileToUpload);
                  }}
                >
                  Upload
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </DialogContainer>
    </>
  );
};

export default SpecialFileToUpload;
