import React, { useMemo, useState, useContext } from "react";
import { matchSorter } from "match-sorter";
import {
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  OutlinedInput,
  styled,
  Typography,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { selectTagSort } from "store/features/general/slice";
import CloseIcon from "@mui/icons-material/Close";
import { ClearOutlined, SearchOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useTags } from "api/tagService";
import { Tag as TagInterface } from "models/api/response.types";
import { sortOptions } from "utils/tagSort";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import { DocumentsContext } from "pages/Documents";
import Tag from "./Tag";
import SortTagMenu from "./SortTagMenu";
import TagsEditDialog from "../Tags/TagsEditDialog";

export const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    height: "800px",
    paddingBottom: "1rem",
    width: "100%",
  },
  "& .tags-search-input": {
    margin: "0rem 1rem 0.25rem",
    width: "calc(100% - 2rem)",
    "& .MuiOutlinedInput-root": {
      fontSize: "inherit",
      "& input": {
        padding: "0.4rem 0 0.5rem",
      },
    },
  },
  "& .tag-list": {
    maxHeight: "800px",
    overflow: "auto",
    paddingTop: 0,
    "& .tag-header": {
      display: "flex",
      alignItems: "center",
      lineHeight: 2,
      wordBreak: "break-word",
      justifyContent: "space-between",
      marginBottom: "0.5rem",
    },
  },
  "& .tag-create-button": {
    padding: "4px 1rem",
    flex: "none",
    "&__icon": {
      marginRight: "10px",
      width: "10%",
      minWidth: 0,
      "& > svg": {
        marginLeft: "auto",
      },
    },
  },
}));

const ManageTagsDialog: React.FC = () => {
  const { setOpenManageTagsDialog } = useContext(DocumentsContext);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const tagSort = useSelector(selectTagSort);
  const { tags } = useTags(currentOrganizationId);
  const [filterValue, setFilterValue] = useState<string>("");
  const [selectedTag, setSelectedTag] = useState<TagInterface | undefined>(
    undefined
  );
  const [openUpdateTagDialog, setOpenUpdateTagDialog] = useState(false);

  const handleCloseUpdateTagDialog = () => {
    setOpenUpdateTagDialog(false);
    setSelectedTag(undefined);
  };

  const handleClose = () => {
    setOpenManageTagsDialog(false);
  };

  const filteredTags: TagInterface[] = useMemo(() => {
    if (tags && tags.length > 0) {
      let filterSort = matchSorter(tags, filterValue, { keys: ["name"] });
      filterSort = filterSort.sort((a, b) =>
        sortOptions[tagSort.key].compare(a, b, tagSort.order)
      );
      return filterSort;
    }
    return [];
  }, [tags, tagSort, filterValue]);

  return (
    <StyledDialog open onClose={handleClose} maxWidth="xs">
      <DialogTitle>
        Tags
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <FormControl fullWidth className="tags-search-input">
        <OutlinedInput
          color="secondary"
          size="small"
          value={filterValue}
          autoFocus
          placeholder="Search tags"
          onChange={(event) => {
            setFilterValue(event.target.value);
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlined fontSize="small" />
            </InputAdornment>
          }
          endAdornment={
            <>
              {filterValue.length > 0 && (
                <IconButton onClick={() => setFilterValue("")}>
                  <ClearOutlined fontSize="small" />
                </IconButton>
              )}
            </>
          }
        />
      </FormControl>
      <Divider style={{ margin: "8px 0" }} />
      <List
        className="tag-list"
        subheader={
          <ListSubheader className="tag-header">
            <Typography
              variant="body1"
              sx={{
                color: "#000",
              }}
            >
              {filterValue.length === 0
                ? `All tags`
                : `Results for "${filterValue}"`}
            </Typography>
            <SortTagMenu />
          </ListSubheader>
        }
      >
        {filteredTags && filteredTags.length > 0 && (
          <>
            {filteredTags.map((tag) => (
              <Tag
                tag={tag}
                key={tag.id}
                selectedTag={selectedTag}
                setSelectedTag={setSelectedTag}
                setUpdateTag={setOpenUpdateTagDialog}
              />
            ))}
          </>
        )}
      </List>
      <Divider style={{ margin: "8px 0" }} />
      <ListItemButton
        className="tag-create-button"
        onClick={() => {
          setOpenUpdateTagDialog(true);
        }}
      >
        <ListItemIcon className="tag-create-button__icon">
          <AddOutlinedIcon fontSize="medium" />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body1">Create new tag</Typography>
        </ListItemText>
      </ListItemButton>
      <>
        {openUpdateTagDialog && (
          <TagsEditDialog
            setOpenDialog={handleCloseUpdateTagDialog}
            currentTag={selectedTag}
          />
        )}
      </>
    </StyledDialog>
  );
};

export default ManageTagsDialog;
