import React, { useState } from "react";
import { AISession } from "models/api/response.types";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { TwitterPicker } from "react-color";
import { colorsToCreate } from "utils/customColorPalette";
import { useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";
import { useAISessions } from "api/aiService";
import { LoadingButton } from "@mui/lab";
import { isGuest } from "models/components/Permissions.models";
import { useUsers } from "api/userService";

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiDialogContent-root": {
    display: "flex",
    flexDirection: "column",
    "& .session-name": {
      marginBottom: "1rem",
    },
    "& .visibility-container, & .color-container": {
      width: "fit-content",
      marginLeft: "0.5rem",
      "& .MuiButtonBase-root": {
        marginRight: "0.5rem",
      },
    },
    "& .color-picker": {
      display: "flex",
      flexDirection: "row",
      gap: "0.5rem",
      width: "90%",
      marginTop: "1rem",
      marginLeft: "0.5rem",
    },
  },
}));

const AISessionDialog: React.FC<{
  organizationId: number;
  setOpen: (open: boolean, newSession?: AISession) => void;
  isMultiDoc: boolean;
  documentIds?: number[];
  sessionToUpdate?: AISession;
}> = ({
  organizationId,
  isMultiDoc,
  documentIds,
  sessionToUpdate,
  setOpen,
}) => {
  const user = useSelector(selectUser);
  const { createAISessionMutation, updateAISessionMutation } = useAISessions(
    organizationId,
    !isMultiDoc && documentIds ? documentIds[0] : undefined
  );
  const { users } = useUsers(organizationId);
  const [loading, setLoading] = useState(false);
  const [sessionName, setSessionName] = useState<string>(
    sessionToUpdate?.meta.name || ""
  );
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const [visibility, setVisibility] = useState<string>(
    sessionToUpdate?.visibility
      ? sessionToUpdate.visibility
      : isGuest(currentUserRole)
      ? "shared"
      : "private"
  );
  const [addColorTag, setAddColorTag] = useState<boolean>(
    Boolean(sessionToUpdate?.meta.color) || false
  );
  const [pickedColor, setPickedColor] = useState<string>(
    sessionToUpdate?.meta.color || "#62B602"
  );

  const createNewSession = () => {
    if (user) {
      setLoading(true);
      const newSession = {
        ...(documentIds && {
          document_ids: documentIds,
        }),
        meta: {
          name: sessionName,
          color: addColorTag ? pickedColor : undefined,
        },
        organization_id: organizationId,
        user_id: user?.id,
        visibility,
        is_multidoc: isMultiDoc,
      };
      createAISessionMutation.mutate(newSession, {
        onSettled: (data) => {
          setLoading(false);
          setOpen(false, data?.data || undefined);
        },
      });
    }
  };

  const updateSession = () => {
    if (sessionToUpdate) {
      setLoading(true);
      updateAISessionMutation.mutate(
        {
          sessionId: sessionToUpdate?.id,
          body: {
            meta: {
              name: sessionName,
              color: addColorTag ? pickedColor : undefined,
            },
            visibility,
          },
        },
        {
          onSettled: () => {
            setLoading(false);
            setOpen(false);
          },
        }
      );
    }
  };

  return (
    <StyledDialog open fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <DialogTitle>
        {sessionToUpdate ? "Update chat" : "Create new chat"}
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          value={sessionName}
          className="session-name"
          color="primary"
          variant="outlined"
          fullWidth
          name="sessionName"
          type="text"
          placeholder="Enter chat name"
          size="small"
          onChange={(e) => {
            setSessionName(e.target.value);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === "Enter" && sessionName.length > 0) {
              event.preventDefault();
              if (sessionToUpdate) {
                updateSession();
              } else {
                createNewSession();
              }
            }
          }}
        />
        {!isGuest(currentUserRole) && (
          <FormControl className="visibility-container">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  className="primary"
                  checked={visibility === "shared"}
                  onChange={() => {
                    if (visibility === "private") {
                      setVisibility("shared");
                    } else {
                      setVisibility("private");
                    }
                  }}
                />
              }
              label={
                <Typography color="textSecondary" variant="body2">
                  Make the chat shared across workspace
                </Typography>
              }
            />
          </FormControl>
        )}
        <FormControl className="color-container">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                className="primary"
                checked={addColorTag}
                onChange={() => {
                  setAddColorTag(!addColorTag);
                }}
              />
            }
            label={
              <Typography color="textSecondary" variant="body2">
                Add color tag to chat
              </Typography>
            }
          />
        </FormControl>
        {addColorTag && (
          <Box className="color-picker">
            <TwitterPicker
              color={pickedColor}
              onChange={(color: { hex: string }) => {
                setPickedColor(color.hex);
              }}
              colors={colorsToCreate}
              triangle="hide"
              styles={{
                default: {
                  card: {
                    boxShadow: "none",
                  },
                  body: {
                    padding: 0,
                  },
                  input: {
                    width: 60,
                    height: 22,
                    paddingLeft: 2,
                  },
                  hash: {
                    height: 24,
                    width: 24,
                  },
                  swatch: {
                    height: 24,
                    width: 24,
                    borderRadius: "50px",
                  },
                },
              }}
            />
            <Box
              sx={{
                width: "24px",
                height: "24px",
                background: pickedColor,
                borderRadius: "50px",
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          size="medium"
          color="primary"
          disabled={sessionName.length === 0}
          loading={loading}
          onClick={() => {
            if (sessionToUpdate) {
              updateSession();
            } else {
              createNewSession();
            }
          }}
        >
          {sessionToUpdate ? "Update" : "Create"}
        </LoadingButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default AISessionDialog;
