/* eslint-disable no-useless-computed-key */
import { Box, styled } from "@mui/material";

export const Wrapper = styled(Box)(({ theme }) => ({
  flex: "1 1 0%",
  overflow: "hidden",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "& .settings-bar": {
    display: "flex",
    gap: theme.spacing(2),
    margin: `${theme.spacing(1)} 0`,
    justifyContent: "space-between",
    "& .doc-options": {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
      marginLeft: "0.8rem",
      "& .document-checkbox": {
        padding: 0,
        height: "fit-content",
        "&:not(.Mui-checked)": {
          color: "rgba(0, 0, 0, 0.12)",
        },
      },
    },
  },
  "& .search-results": {
    marginBottom: "0.5rem",
    display: "inherit",
    marginRight: "0.5rem",
    "& .search-title": {
      borderRadius: "4px",
      background: theme.notice.main,
      padding: "0.1rem 0.5rem",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

export const RootContainer = styled("div")(() => ({
  flexGrow: 1,
  outline: "none",
  position: "relative",
  "& div": {
    "&:focus-visible": {
      outline: "none",
    },
  },
}));
