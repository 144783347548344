import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError, AxiosPromise } from "axios";
import {
  AICreateDocumentQAStatusList,
  AICreateProject,
  AICreateProjectCreateBody,
  AICreateProjectList,
  AICreateProjectUpdateBody,
  AICreateQABody,
  AICreateQuestion,
  AICreateQuestionList,
  AICreateTemplateBlock,
  AICreateTemplateBlockCreateBody,
  AICreateTemplateBlockList,
  AICreateTemplateBlockUpdateBody,
  SuccessResponse,
} from "models/api/response.types";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import handleAxiosError from "utils/handleAxiosAlert";

const fetchAICreateProjects = (
  organizationId: number
): AxiosPromise<AICreateProjectList> => {
  return axios.get(`/api/ai-create/list?organization_id=${organizationId}`);
};

const fetchAICreateProject = (
  projectId: number
): AxiosPromise<AICreateProject> => {
  return axios.get(`/api/ai-create/${projectId}`);
};

const deleteAICreateProject = (
  projectId: number
): AxiosPromise<SuccessResponse> => {
  return axios.delete(`/api/ai-create/${projectId}`);
};

const updateAICreateProject = (
  projectId: number,
  projectMeta: AICreateProjectUpdateBody
): AxiosPromise<AICreateProject> => {
  return axios.patch(`/api/ai-create/${projectId}`, projectMeta);
};

const createAICreateProject = (
  project: AICreateProjectCreateBody
): AxiosPromise<AICreateProject> => {
  return axios.post(`/api/ai-create/new`, project);
};

const fecthAICreateQuestionsByProjectId = (
  projectId: number
): AxiosPromise<AICreateQuestionList> => {
  return axios.get(`/api/ai-create/${projectId}/doc_qa/list`);
};

const fetchAICreateDocumentQAsStatusByProjectId = (
  projectId: number
): AxiosPromise<AICreateDocumentQAStatusList> => {
  return axios.get(`/api/ai-create/${projectId}/document/list`);
};

const updateAICreateQAResponse = (
  projectId: number,
  qaId: number,
  response: string
): AxiosPromise<AICreateQuestion> => {
  return axios.patch(`/api/ai-create/${projectId}/doc_qa/${qaId}`, {
    response,
  });
};

const deleteAICreateQA = (
  projectId: number,
  qaId: number
): AxiosPromise<SuccessResponse> => {
  return axios.delete(`/api/ai-create/${projectId}/doc_qa/${qaId}`);
};

const rerunAICreateQA = (
  projectId: number,
  qaId: number
): AxiosPromise<AICreateQuestion> => {
  return axios.post(`/api/ai-create/${projectId}/doc_qa/${qaId}/run`);
};

const createAICreateQA = (
  projectId: number,
  qa: AICreateQABody
): AxiosPromise<AICreateQuestion> => {
  return axios.post(`/api/ai-create/${projectId}/doc_qa`, qa);
};

const fetchAICreateTemplateBlocks = (
  projectId: number
): AxiosPromise<AICreateTemplateBlockList> => {
  return axios.get(`/api/ai-create/${projectId}/template/list`);
};

const createAICreateTemplateBlock = (
  projectId: number,
  block: AICreateTemplateBlockCreateBody
): AxiosPromise<AICreateTemplateBlock> => {
  return axios.post(`/api/ai-create/${projectId}/template`, block);
};

const updateAICreateTemplateBlock = (
  projectId: number,
  blockId: number,
  block: AICreateTemplateBlockUpdateBody
) => {
  return axios.patch(`/api/ai-create/${projectId}/template/${blockId}`, block);
};

const deleteAICreateTemplateBlock = (projectId: number, blockId: number) => {
  return axios.delete(`/api/ai-create/${projectId}/template/${blockId}`);
};

const rerunAICreateTemplateBlock = (
  projectId: number,
  blockId: number
): AxiosPromise<AICreateTemplateBlock> => {
  return axios.post(`/api/ai-create/${projectId}/template/${blockId}/run`);
};

const generateAICreateTemplate = (
  projectId: number
): AxiosPromise<AICreateProject> => {
  return axios.post(`/api/ai-create/${projectId}/template/generate`);
};

const aiCreateService = {
  fetchAICreateProjects,
  deleteAICreateProject,
  createAICreateProject,
  updateAICreateProject,
  fecthAICreateQuestionsByProjectId,
  fetchAICreateDocumentQAsStatusByProjectId,
  updateAICreateQAResponse,
  deleteAICreateQA,
  rerunAICreateQA,
  createAICreateQA,
  fetchAICreateTemplateBlocks,
  createAICreateTemplateBlock,
  updateAICreateTemplateBlock,
  deleteAICreateTemplateBlock,
  rerunAICreateTemplateBlock,
  generateAICreateTemplate,
  fetchAICreateProject,
};

export default aiCreateService;

// Used to add a cancel property to Promise to appease Typescript
// See https://github.com/tannerlinsley/react-query/issues/1265
interface ExtendedPromise<T> extends Promise<T> {
  cancel?: () => void;
}

export const useAICreateQuestions = (projectId?: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const queryKey = [`ai-create/project/${projectId}/qa`];
  const {
    data: aiCreateQuestions,
    isLoading,
    isFetching,
  } = useQuery<unknown, unknown, AICreateQuestionList, any>(
    queryKey,
    (): ExtendedPromise<AICreateQuestionList> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = aiCreateService.fecthAICreateQuestionsByProjectId(
        projectId || 0
      );
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: AICreateQuestionList }) => responseData
      );
    },
    {
      enabled: !!projectId,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch),
    }
  );

  const getCachedAICreateQuestionById: (
    id: number
  ) => [item: Readonly<AICreateQuestion> | undefined, index: Readonly<number>] =
    useCallback(
      (id: number) => {
        if (aiCreateQuestions && aiCreateQuestions.length > 0) {
          const idx = aiCreateQuestions.findIndex((qa) => qa.id === id);
          if (idx > -1) {
            return [aiCreateQuestions[idx], idx];
          }
          return [undefined, -1];
        }
        return [undefined, -1];
      },
      [aiCreateQuestions]
    );

  const removeCachedAICreateQuestion = (
    id: number
  ): AICreateQuestion | undefined => {
    const [qa, index] = getCachedAICreateQuestionById(id);
    if (aiCreateQuestions && qa) {
      const newQuestions = [...aiCreateQuestions];
      newQuestions.splice(index, 1);
      queryClient.setQueryData<AICreateQuestionList>(queryKey, newQuestions);
      return qa;
    }
    return undefined;
  };

  const upsertCachedAICreateQuestion = (
    newQuestion: AICreateQuestion
  ): AICreateQuestion | undefined => {
    const [currentQuestion, index] = getCachedAICreateQuestionById(
      newQuestion.id
    );
    if (aiCreateQuestions) {
      const newQuestions = [...aiCreateQuestions];
      if (index > -1) {
        newQuestions.splice(index, 1, newQuestion);
      } else {
        newQuestions.push(newQuestion);
      }
      queryClient.setQueryData<AICreateQuestionList>(queryKey, newQuestions);
    } else {
      queryClient.setQueryData<AICreateQuestionList>(queryKey, [newQuestion]);
    }
    return currentQuestion;
  };

  return {
    aiCreateQuestions,
    aiCreateQuestionsQueryKey: queryKey,
    aiCreateQuestionsIsLoading: isLoading,
    aiCreateQuestionsIsFetching: isFetching,
    getCachedAICreateQuestionById,
    upsertCachedAICreateQuestion,
    removeCachedAICreateQuestion,
  };
};

export const useAICreateProjects = (organizationId?: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const queryKey = [`ai-create/${organizationId}/projects`];
  const {
    data: aiCreateProjects,
    isLoading,
    isFetching,
  } = useQuery<unknown, unknown, AICreateProjectList, any>(
    queryKey,
    (): ExtendedPromise<AICreateProjectList> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = aiCreateService.fetchAICreateProjects(
        organizationId || 0
      );
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: AICreateProjectList }) => responseData
      );
    },
    {
      enabled: !!organizationId,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch),
    }
  );

  const getCachedAICreateProjectById: (
    id: number
  ) => [item: Readonly<AICreateProject> | undefined, index: Readonly<number>] =
    useCallback(
      (id: number) => {
        if (aiCreateProjects && aiCreateProjects.length > 0) {
          const idx = aiCreateProjects.findIndex((proj) => proj.id === id);
          if (idx > -1) {
            return [aiCreateProjects[idx], idx];
          }
          return [undefined, -1];
        }
        return [undefined, -1];
      },
      [aiCreateProjects]
    );

  const removeCachedAICreateProject = (
    id: number
  ): AICreateProject | undefined => {
    const [project, index] = getCachedAICreateProjectById(id);
    if (aiCreateProjects && project) {
      const newProjects = [...aiCreateProjects];
      newProjects.splice(index, 1);
      queryClient.setQueryData<AICreateProjectList>(queryKey, newProjects);
      return project;
    }
    return undefined;
  };

  const upsertCachedAICreateProject = (
    newProject: AICreateProject
  ): AICreateProject | undefined => {
    const [currentProject, index] = getCachedAICreateProjectById(newProject.id);
    if (aiCreateProjects) {
      const newProjects = [...aiCreateProjects];
      if (index > -1) {
        newProjects.splice(index, 1, newProject);
      } else {
        newProjects.push(newProject);
      }
      queryClient.setQueryData<AICreateProjectList>(queryKey, newProjects);
    } else {
      queryClient.setQueryData<AICreateProjectList>(queryKey, [newProject]);
    }
    return currentProject;
  };

  return {
    aiCreateProjects,
    aiCreateProjectsQueryKey: queryKey,
    aiCreateProjectsIsLoading: isLoading,
    aiCreateProjectsIsFetching: isFetching,
    getCachedAICreateProjectById,
    upsertCachedAICreateProject,
    removeCachedAICreateProject,
  };
};

export const useAICreateTemplateBlocks = (projectId?: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const queryKey = [`ai-create/project/${projectId}/template-blocks`];
  const {
    data: aiCreateBlocks,
    isLoading,
    isFetching,
  } = useQuery<unknown, unknown, AICreateTemplateBlockList, any>(
    queryKey,
    (): ExtendedPromise<AICreateTemplateBlockList> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = aiCreateService.fetchAICreateTemplateBlocks(
        projectId || 0
      );
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: AICreateTemplateBlockList }) =>
          responseData
      );
    },
    {
      enabled: !!projectId,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch),
    }
  );

  const getCachedAICreateTemplateBlockById: (
    id: number
  ) => [
    item: Readonly<AICreateTemplateBlock> | undefined,
    index: Readonly<number>
  ] = useCallback(
    (id: number) => {
      if (aiCreateBlocks && aiCreateBlocks.length > 0) {
        const idx = aiCreateBlocks.findIndex((qa) => qa.id === id);
        if (idx > -1) {
          return [aiCreateBlocks[idx], idx];
        }
        return [undefined, -1];
      }
      return [undefined, -1];
    },
    [aiCreateBlocks]
  );

  const removeCachedAICreateTemplateBlock = (
    id: number
  ): AICreateTemplateBlock | undefined => {
    const [block, index] = getCachedAICreateTemplateBlockById(id);
    if (aiCreateBlocks && block) {
      const newBlocks = [...aiCreateBlocks];
      newBlocks.splice(index, 1);
      queryClient.setQueryData<AICreateTemplateBlockList>(queryKey, newBlocks);
      return block;
    }
    return undefined;
  };

  const upsertCachedAICreateTemplateBlock = (
    newBlock: AICreateTemplateBlock
  ): AICreateTemplateBlock | undefined => {
    const [currentBlock, index] = getCachedAICreateTemplateBlockById(
      newBlock.id
    );
    if (aiCreateBlocks) {
      const newBlocks = [...aiCreateBlocks];
      if (index > -1) {
        newBlocks.splice(index, 1, newBlock);
      } else {
        newBlocks.push(newBlock);
      }
      queryClient.setQueryData<AICreateTemplateBlockList>(queryKey, newBlocks);
    } else {
      queryClient.setQueryData<AICreateTemplateBlockList>(queryKey, [newBlock]);
    }
    return currentBlock;
  };

  const blocks = aiCreateBlocks?.sort((a, b) => a.sort_order - b.sort_order);

  return {
    aiCreateBlocks: blocks,
    aiCreateBlocksQueryKey: queryKey,
    aiCreateBlocksIsLoading: isLoading,
    aiCreateBlocksIsFetching: isFetching,
    getCachedAICreateTemplateBlockById,
    upsertCachedAICreateTemplateBlock,
    removeCachedAICreateTemplateBlock,
  };
};
