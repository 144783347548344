/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { canUseAiCreate, isTrialExist } from "utils/plans";
import {
  useOrganizationUsage,
  useOrganizations,
} from "api/organizationService";
import routePaths from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import AICreateSelectedProject from "components/Browser/AICreate/SelectedProject/AICreateSelectedProject";
import { useAICreateProjects } from "api/aiCreateService";
import AICreateProjectDashboard from "../components/Browser/AICreate/AICreateProjectDashboard";

const AiCreate: React.FC = () => {
  const navigate = useNavigate();
  const currentUser = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { getCachedOrganizationById } = useOrganizations(currentUser?.id);
  const { organizationUsage } = useOrganizationUsage(currentOrganizationId);
  const [currentOrganization] = getCachedOrganizationById(
    currentOrganizationId || -1
  );
  const [selectedProjectId, setSelectedProjectId] = useState<
    number | undefined
  >(undefined);
  const { aiCreateProjects: projects, getCachedAICreateProjectById } =
    useAICreateProjects(currentOrganizationId);

  // users that have less than Advanced tier and no trial
  // can't access ai chat
  const noAccess =
    !canUseAiCreate(organizationUsage?.usage_limits.ai_credit_limit || 0) &&
    !isTrialExist(currentOrganization?.created_at);

  const selectedProject = useMemo(() => {
    if (selectedProjectId && projects) {
      const [project] = getCachedAICreateProjectById(selectedProjectId);
      return project;
    }
    return undefined;
  }, [selectedProjectId, projects]);

  useEffect(() => {
    if (currentOrganization && noAccess) {
      navigate(routePaths.documents);
    }
  }, [currentOrganization, noAccess]);

  if (selectedProject) {
    return (
      <AICreateSelectedProject
        project={selectedProject}
        goBack={() => setSelectedProjectId(undefined)}
      />
    );
  }

  return (
    <AICreateProjectDashboard
      setSelectedProjectId={setSelectedProjectId}
      projects={projects}
    />
  );
};

export default AiCreate;
