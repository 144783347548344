import React, { useRef, useState } from "react";
import {
  Box,
  ClickAwayListener,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import ChatBubbleOutlinedIcon from "@mui/icons-material/ChatBubbleOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { AISession } from "models/api/response.types";
import { useAISessions } from "api/aiService";
import { useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";
import { useUsers } from "api/userService";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";

interface Props {
  size?: string;
}

const Wrapper = styled(Box)<Props>(({ theme, size }) => ({
  background: theme.background.light,
  border: `1px solid ${theme.grey.light}`,
  "&:hover": {
    border: `1px solid ${theme.grey.main}`,
  },
  height: "36px",
  padding: "3.5px 10px",
  ...(size === "medium" && {
    padding: "8.5px 14px",
  }),
  display: "flex",
  justifyContent: "space-between",
  flex: 1,
  borderRadius: "4px",
  alignItems: "center",
  cursor: "pointer",
  "& .chat": {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    overflow: "hidden",
    "& .title": {
      color: theme.palette.text.primary,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

const AISessionSelector: React.FC<{
  selectedSession: AISession | undefined;
  organizationId: number;
  documentId?: number;
  size?: string;
  setSelectedSession: (session?: AISession) => void;
  sessionToUpdate: (sessionToUpdate: AISession) => void;
}> = ({
  organizationId,
  documentId,
  selectedSession,
  size,
  setSelectedSession,
  sessionToUpdate,
}) => {
  const theme = useTheme();
  const currentUser = useSelector(selectUser);
  const { getCachedUserById } = useUsers(organizationId);
  const { aiSessions, deleteAISessionMutation } = useAISessions(
    organizationId,
    documentId
  );
  const [confirmSessionDeletion, setConfirmSessionDeletion] =
    useState<boolean>(false);
  const [sessionToDelete, setSessionToDelete] = useState<AISession | undefined>(
    undefined
  );
  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const customInputRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Wrapper
        size={size}
        className="input-selector"
        ref={customInputRef}
        onClick={(e) => {
          setMenuOpen(e.currentTarget);
        }}
      >
        <Box className="chat">
          {selectedSession?.meta.color ? (
            <ChatBubbleOutlinedIcon
              fontSize="small"
              sx={{
                fill: selectedSession.meta.color,
              }}
            />
          ) : (
            <ChatBubbleOutlineOutlinedIcon fontSize="small" color="action" />
          )}
          <Typography variant="body2" className="title">
            {selectedSession ? `${selectedSession.meta.name}` : "All chats"}
          </Typography>
        </Box>
        <KeyboardArrowDownIcon
          color="action"
          sx={
            menuOpen
              ? {
                  transform: "rotate(180deg)",
                }
              : {}
          }
        />
      </Wrapper>
      <ConfirmationDialog
        show={confirmSessionDeletion}
        title={`Remove ${sessionToDelete?.meta?.name} chat`}
        description="Are you sure you want to delete this chat? Please note that deleting this chat will also remove the entire conversation within it."
        sensitive
        buttonText="Delete"
        onClose={(confirm?: boolean) => {
          setConfirmSessionDeletion(false);
          if (confirm && sessionToDelete) {
            deleteAISessionMutation.mutate(sessionToDelete.id);
            if (selectedSession?.id === sessionToDelete.id) {
              setSelectedSession(undefined);
              setSessionToDelete(undefined);
            }
          }
        }}
      />
      {menuOpen && (
        <ClickAwayListener onClickAway={() => setMenuOpen(null)}>
          <Popper
            open={!!menuOpen}
            anchorEl={menuOpen}
            placement="bottom-start"
            sx={{
              padding: "0.5rem 0",
              borderRadius: "4px",
              border: `1px solid #E9E9E9`,
              boxShadow: `0 0.5rem 1rem rgba(149, 157, 165, 0.2)`,
              maxHeight: "250px",
              width: customInputRef.current
                ? `${customInputRef.current.offsetWidth}px`
                : "300px",
              zIndex: 1200,
              backgroundColor: theme.background.light,
              overflow: "auto",
              "& .MuiListItemButton-root": {
                paddingTop: 0,
                paddingBottom: 0,
                height: "32px",
                "&:hover": {
                  "& .actions": {
                    display: "flex",
                  },
                },
                "& .MuiListItemIcon-root": {
                  minWidth: 0,
                  marginRight: "0.5rem",
                },
                "& .MuiListItemText-root": {
                  "& p": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                },
                "& .actions": {
                  display: "none",
                  alignItems: "center",
                  flexWrap: "nowrap",
                },
                "& .visibility": {
                  marginLeft: "0.5rem",
                },
              },
            }}
          >
            <List disablePadding className="chat-list-container">
              {aiSessions
                ?.sort((a, b) => b.created_at.localeCompare(a.created_at))
                .map((session) => {
                  const [sessionUser] = getCachedUserById(session.user_id);
                  return (
                    <ListItemButton
                      selected={selectedSession?.id === session.id}
                      key={session.id}
                      onClick={() => {
                        setMenuOpen(null);
                        setSelectedSession(session);
                      }}
                    >
                      <ListItemIcon>
                        {session.meta.color ? (
                          <ChatBubbleOutlinedIcon
                            fontSize="small"
                            sx={{
                              fill: session.meta.color,
                            }}
                          />
                        ) : (
                          <ChatBubbleOutlineOutlinedIcon
                            fontSize="small"
                            color="action"
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="body2">
                          {session.meta.name}
                        </Typography>
                      </ListItemText>
                      <Box className="actions">
                        {session.user_id === currentUser?.id && (
                          <>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                sessionToUpdate(session);
                                setMenuOpen(null);
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSessionToDelete(session);
                                setConfirmSessionDeletion(true);
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </>
                        )}
                      </Box>
                      {session.visibility === "shared" && (
                        <Tooltip
                          placement="top"
                          enterDelay={500}
                          title={
                            sessionUser?.id === currentUser?.id
                              ? "Chat is shared within the workspace"
                              : `Shared by ${sessionUser?.name}`
                          }
                        >
                          <VisibilityIcon
                            className="visibility"
                            color="primary"
                            fontSize="small"
                          />
                        </Tooltip>
                      )}
                    </ListItemButton>
                  );
                })}
            </List>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default AISessionSelector;
