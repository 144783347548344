/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-useless-computed-key */
/* eslint-disable react/no-danger */
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  styled,
  Box,
  Typography,
  AccordionDetails,
  MenuItem,
  FormControl,
  Select,
  Tooltip,
  Accordion,
  AccordionSummary,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  BibtexEntry,
  bibtexEntry2html,
  bibtexEntryDiff,
  BibtexEntryDiffResult,
  BibtexEntryFormatted,
  DedupeMatch,
  entriesAreEqual,
  findMatches,
  parseBibTeX,
} from "utils/bibtex";
import documentService, { useDocuments } from "api/documentService";
import {
  DocumentCollection,
  DocumentReadResponse,
} from "models/api/response.types";
import BibtexFileImport from "components/Browser/AddDocuments/ImportBibTex/BibtexFileImport";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import displayTextWithMath from "utils/displayTextWithMath";
import handleAxiosError from "utils/handleAxiosAlert";
import { useDocumentCollections } from "api/documentCollectionService";
import { Icon } from "@iconify/react";
import { searchParentCollectionTreeName } from "utils/collections";
import LoadingOverlay from "components/helpers/LoadingOverlay";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import { defaultCollectionsTypes } from "models/components/Browse.models";

// import -> show file upload and textarea
// review -> resolve merge conflicts
// summary -> apply changes
type ImportDialogModes = "import" | "review" | "summary";

// create -> create new Document stub
// skip -> do nothing
// merge -> merge with existing document
// overwrite -> merge and overwrite conflicts with existing document
type ImportAction = "create" | "skip" | "merge" | "overwrite";

interface ImportItem extends BibtexEntry {
  matches: DedupeMatch[];
  action: ImportAction;
  diff?: BibtexEntryDiffResult;
}

interface ImportItems {
  duplicate: ImportItem[];
  create: ImportItem[];
  needsReview: ImportItem[];
}

interface AwaitCollectionProps {
  duplicate: boolean;
  create: boolean;
  overwrite: boolean;
  merge: boolean;
}

// status of individual API requests when applying changes
type RequestStatus = "init" | "loading" | "success" | "failed";

// overall status of all "concurrent" requests
type ApplyStatus = "idle" | "loading" | "done";

// wrapper for dialog
const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "500px",
  maxHeight: "650px",
  overflow: "auto",
  ["@media (max-height:650px)"]: {
    minHeight: "400px",
  },
  padding: "1rem 2rem",
  gap: "1rem",
  "& .button": {
    width: "fit-content",
    margin: "0 auto",
  },
  "& ol": {
    fontSize: "14px",
    paddingInlineStart: "2.5rem",
  },
  "& ol li": { marginBottom: "0.5rem" },
  "& .MuiAccordion-root": {
    borderRadius: "4px",
    boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
    "&::before": {
      display: "none",
    },
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  "& .highlighted-text": {
    color: theme.palette.secondary.main,
  },
}));

// review merge conflict table
const ReviewTable = styled(Box)(() => ({
  "& table": {
    borderCollapse: "collapse",
  },
  "& th": {
    textAlign: "left",
  },
  "& th, td": {
    fontSize: "14px",
    padding: "0.5rem 0.5rem",
    userSelect: "none",
    maxWidth: "50rem",
    verticalAlign: "top",
  },
  "& tbody tr:hover": {
    background: "#ccc1",
  },
  "& h6": {
    margin: "0.25rem 0",
    padding: 0,
    fontSize: "0.8rem",
  },
  "& .diff": { display: "flex", flexWrap: "wrap" },
  "& .diff span": {
    borderRadius: "16px",
    background: "#fc36",
    padding: "0.25rem 0.5rem",
    marginRight: "0.25rem",
    marginBottom: "0.25rem",
    display: "inline-block",
  },
  "& .conflict": {},
  "& .conflict span": {
    background: "#f336",
    padding: "0.25rem 0.5rem",
    marginRight: "0.25rem",
    marginBottom: "0.25rem",
    display: "inline-block",
  },
}));

// Convert ImportItem to metadata record (stripping extra properties)
const convertItemToMeta = (
  item: ImportItem | BibtexEntry
): Record<string, string> => {
  const meta: Record<string, string> = {};
  const denyList = ["matches", "action", "diff"];
  Object.entries(item).forEach(([key, value]) => {
    if (!denyList.includes(key)) {
      // flatten array values using " and " (bibtex spec)
      if (Array.isArray(value)) {
        meta[key] = value.map((entry) => `${entry}`.trim()).join(" and ");
      } else {
        meta[key] = `${value}`.trim();
      }
    }
  });
  return meta;
};

const ImportBibTex: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { logAction } = useTelemetry();
  const { documents, getCachedDocumentById, upsertCachedDocuments } =
    useDocuments(currentOrganizationId);
  const { collections, getCachedCollectionById, updateCollectionMutation } =
    useDocumentCollections(currentOrganizationId);
  const [selectedCollectionId, setSelectedCollectionId] = useState<
    number | undefined
  >(undefined);
  const [collectionToUse] = getCachedCollectionById(selectedCollectionId || -1);
  const [uploadCollection, setUploadCollection] = useState<
    DocumentCollection | undefined
  >(undefined);
  const [mode, setMode] = useState<ImportDialogModes>("import");
  const [awaitingItemsMerge, setAwaitingItemsMerging] =
    useState<AwaitCollectionProps>({
      duplicate: true,
      create: true,
      overwrite: true,
      merge: true,
    });
  const [idsToCollection, setIdsToCollection] = useState<number[]>([]);
  // contents of .bib file
  const [fileContent, setFileContent] = useState<string>("");
  const [items, setItems] = useState<ImportItems>({
    duplicate: [],
    create: [],
    needsReview: [],
  });
  // accordion state
  const [expanded, setExpanded] = React.useState<string | false>("needsReview");
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  // uuid -> status code, to track status of multiple simultaneous requests
  // wait until all Object.values(requestStatuses).every(status === "finished")
  const [requestStatuses, setRequestStatuses] = useState<
    Record<string, RequestStatus>
  >({});

  // this state is updated by useEffect hook that watches requestStatuses
  const [applyStatus, setApplyStatus] = useState<ApplyStatus>("idle");

  // set uploadCollection when selectedCollection is changed
  useEffect(() => {
    if (collections && selectedCollectionId) {
      const [collection] = getCachedCollectionById(selectedCollectionId);
      if (collection && !defaultCollectionsTypes.includes(collection?.name)) {
        setUploadCollection(collection);
      } else {
        setUploadCollection(undefined);
      }
    } else {
      setUploadCollection(undefined);
    }
  }, [selectedCollectionId, collections]);

  // after all merges / creations / overwriting
  // add all docs to collection
  useEffect(() => {
    if (Object.values(awaitingItemsMerge).every((value) => !value)) {
      if (idsToCollection.length > 0 && uploadCollection) {
        updateCollectionMutation.mutate({
          collections: [
            {
              id: uploadCollection.id,
              document_ids: [
                ...uploadCollection.document_ids,
                ...idsToCollection,
              ],
            },
          ],
        });
        setAwaitingItemsMerging({
          duplicate: true,
          create: true,
          overwrite: true,
          merge: true,
        });
        setIdsToCollection([]);
      } else {
        setAwaitingItemsMerging({
          duplicate: true,
          create: true,
          overwrite: true,
          merge: true,
        });
      }
    }
  }, [awaitingItemsMerge, idsToCollection]);

  // update parsed bibtex entries when fileContent changes
  useEffect(() => {
    if (fileContent.length > 0) {
      // "flatten" properties into BibtexEntry[]
      const bibtexEntries = parseBibTeX(fileContent).map((entry) =>
        entry
          ? {
              ...entry.properties,
              entrytype: entry.entrytype,
              citekey: entry.citekey,
            }
          : {}
      );
      // categorize entries into three buckets: duplicate, create, needsReview
      const newItems: ImportItems = {
        duplicate: [],
        create: [],
        needsReview: [],
      };
      bibtexEntries.forEach((entry) => {
        const matches = findMatches(documents, entry);
        const item: ImportItem = { ...entry, matches, action: "create" };
        if (matches.length > 0 && matches[0].score > 0.95) {
          if (entriesAreEqual(matches[0].current, matches[0].incoming)) {
            item.action = "skip";
            newItems.duplicate.push(item);
          } else {
            item.diff = bibtexEntryDiff(
              item.matches[0].current,
              item.matches[0].incoming
            );
            // if current is missing something from incoming, and no conflicts
            if (
              item.diff.currentMissing.length > 0 &&
              item.diff.conflicts.length === 0
            ) {
              item.action = "merge";
            } else {
              item.action = "skip";
            }
            newItems.needsReview.push(item);
          }
        } else {
          newItems.create.push(item);
        }
      });
      setItems(newItems);
    } else {
      // fileContent is empty
      setItems({
        duplicate: [],
        create: [],
        needsReview: [],
      });
    }
  }, [fileContent]);

  const itemCount = useMemo(() => {
    return (
      items.duplicate.length + items.create.length + items.needsReview.length
    );
  }, [items]);

  const renderReference = (formatted: BibtexEntryFormatted) => {
    return (
      <span>
        {formatted.authors}, <em>{displayTextWithMath(formatted.title)}</em>,{" "}
        {formatted.remainder}
      </span>
    );
  };

  const applyChanges = () => {
    if (currentOrganizationId) {
      const itemsToCreate: ImportItem[] = [
        ...items.create,
        ...items.needsReview.filter((item) => item.action === "create"),
      ];
      const itemsToMerge: ImportItem[] = [
        ...items.needsReview.filter((item) => item.action === "merge"),
      ];
      const itemsToOverwrite: ImportItem[] = [
        ...items.needsReview.filter((item) => item.action === "overwrite"),
      ];
      setMode("summary");
      logAction(telemetryAction.ImportReferencesDialogApply, {
        itemsToCreate: itemsToCreate.length,
        itemsToMerge: itemsToMerge.length,
        itemsToOverwrite: itemsToOverwrite.length,
      });

      if (items.duplicate) {
        if (uploadCollection) {
          const stubIds = items.duplicate.map((stub) => stub.matches[0].id);
          setIdsToCollection((ids) => [...ids, ...stubIds]);
          setAwaitingItemsMerging((result) => ({
            ...result,
            duplicate: false,
          }));
        }
      } else {
        setAwaitingItemsMerging((result) => ({
          ...result,
          duplicate: false,
        }));
      }

      // create document stubs
      if (itemsToCreate.length > 0) {
        setRequestStatuses({ ...requestStatuses, create: "loading" });
        documentService
          .createDocumentStubs(
            currentOrganizationId,
            itemsToCreate.map((item) => {
              return {
                meta_json: JSON.stringify(convertItemToMeta(item) || {}),
                ui_json: JSON.stringify({ read_by: [] }),
              };
            })
          )
          .then((response) => {
            const newDocumentStubs: DocumentReadResponse[] = response.data;
            upsertCachedDocuments(newDocumentStubs);
            if (uploadCollection) {
              const newDocumentStubsIds = newDocumentStubs.map(
                (stub) => stub.id
              );
              setIdsToCollection((ids) => [...ids, ...newDocumentStubsIds]);
            }
            setRequestStatuses({ ...requestStatuses, create: "success" });
            setAwaitingItemsMerging((result) => ({
              ...result,
              create: false,
            }));
          });
      } else {
        setAwaitingItemsMerging((result) => ({ ...result, create: false }));
      }

      // merge without overwriting
      if (itemsToMerge.length > 0) {
        const mergedDocuments = itemsToMerge.map((item) => {
          const { incoming, id: documentId } = item.matches[0];
          const [currentDocument] = getCachedDocumentById(documentId);
          if (currentDocument) {
            const requestKey = `merge-${documentId}`;
            setRequestStatuses({
              ...requestStatuses,
              [requestKey]: "loading",
            });
            return documentService
              .updateDocument(currentDocument.id, {
                meta_json: JSON.stringify({
                  ...convertItemToMeta(incoming),
                  ...currentDocument.meta,
                }),
              })
              .then((response) => response.data)
              .catch((err) => err);
          }
          return undefined;
        });

        Promise.all(mergedDocuments).then((results) => {
          const validResults: DocumentReadResponse[] = [];
          results.forEach((result) => {
            if (result) {
              // error with document update throw error
              if (result?.name && result.name === "AxiosError") {
                handleAxiosError(result, dispatch);
              } else {
                // document successfully updated
                const doc = result as DocumentReadResponse;
                const requestKey = `merge-${doc.id}`;
                setRequestStatuses({
                  ...requestStatuses,
                  [requestKey]: "success",
                });
                validResults.push(doc);
              }
            }
          });
          upsertCachedDocuments(validResults);
          if (uploadCollection) {
            const docIds = validResults.map((doc) => doc.id);
            setIdsToCollection((ids) => [...ids, ...docIds]);
          }
          setAwaitingItemsMerging((result) => ({ ...result, merge: false }));
        });
      } else {
        setAwaitingItemsMerging((result) => ({ ...result, merge: false }));
      }

      // merge with overwriting
      if (itemsToOverwrite.length > 0) {
        const overwrittenDocuments = itemsToOverwrite.map((item) => {
          const { incoming, id: documentId } = item.matches[0];
          const [currentDocument] = getCachedDocumentById(documentId);
          if (currentDocument) {
            const requestKey = `overwrite-${documentId}`;
            setRequestStatuses({
              ...requestStatuses,
              [requestKey]: "loading",
            });
            return documentService
              .updateDocument(currentDocument.id, {
                meta_json: JSON.stringify({
                  ...currentDocument.meta,
                  ...convertItemToMeta(incoming),
                }),
              })
              .then((response) => response.data)
              .catch((err) => err);
          }
          return undefined;
        });

        Promise.all(overwrittenDocuments).then((results) => {
          const validResults: DocumentReadResponse[] = [];
          results.forEach((result) => {
            if (result) {
              // error with document update throw error
              if (result?.name && result.name === "AxiosError") {
                handleAxiosError(result, dispatch);
              } else {
                // document successfully updated
                const doc = result as DocumentReadResponse;
                const requestKey = `overwrite-${doc.id}`;
                setRequestStatuses({
                  ...requestStatuses,
                  [requestKey]: "success",
                });
                validResults.push(doc);
              }
            }
          });
          upsertCachedDocuments(validResults);
          if (uploadCollection) {
            const docIds = validResults.map((doc) => doc.id);
            setIdsToCollection((ids) => [...ids, ...docIds]);
          }
          setAwaitingItemsMerging((result) => ({
            ...result,
            overwrite: false,
          }));
        });
      } else {
        setAwaitingItemsMerging((result) => ({
          ...result,
          overwrite: false,
        }));
      }
    }
  };

  // update applyStatus when individual requestStatuses are changed
  useEffect(() => {
    let status: ApplyStatus = "idle";
    if (
      Object.values(requestStatuses).filter((value) => value === "loading")
        .length > 0
    ) {
      status = "loading";
    }
    if (
      Object.values(requestStatuses).every(
        (value) => value === "success" || value === "failed"
      )
    ) {
      status = "done";
    }
    setApplyStatus(status);
  }, [requestStatuses]);

  return (
    <Wrapper>
      {mode === "import" && (
        <>
          <BibtexFileImport
            fileContent={fileContent}
            setFileContent={setFileContent}
            selectedCollectionId={selectedCollectionId}
            setSelectedCollectionId={setSelectedCollectionId}
          />
          <Button
            className="button"
            onClick={() => {
              setMode("review");
            }}
            disabled={itemCount === 0}
            size="medium"
            variant="contained"
            color="primary"
          >
            Import {itemCount} {itemCount === 1 ? "entry" : "entries"}
          </Button>
        </>
      )}
      {mode === "review" && (
        <>
          <Typography variant="body1">
            Import {itemCount > 1 ? `${itemCount} references` : "reference"} to{" "}
            <span style={{ color: theme.palette.secondary.main }}>
              {collectionToUse
                ? `${searchParentCollectionTreeName(
                    collectionToUse,
                    collections
                  ).join(" > ")}`
                : "All documents"}
            </span>
          </Typography>
          <Accordion
            square
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded === "create"}
            onChange={handleAccordionChange("create")}
            disabled={items.create.length === 0}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2" lineHeight="inherit">
                New ({items.create.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ol>
                {items.create.map((item) => {
                  return (
                    <li key={JSON.stringify(item)}>
                      {renderReference(bibtexEntry2html(item))}
                    </li>
                  );
                })}
              </ol>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded === "duplicate"}
            onChange={handleAccordionChange("duplicate")}
            disabled={items.duplicate.length === 0}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2" lineHeight="inherit">
                Duplicate ({items.duplicate.length})
              </Typography>
              <Tooltip
                enterDelay={500}
                title="Exact metadata match across all fields"
                placement="right"
              >
                <HelpOutlineOutlinedIcon
                  style={{ marginLeft: "1rem" }}
                  color="primary"
                  fontSize="small"
                />
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails>
              <ol>
                {items.duplicate.map((item) => {
                  return (
                    <li key={JSON.stringify(item)}>
                      {renderReference(bibtexEntry2html(item))}
                    </li>
                  );
                })}
              </ol>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            TransitionProps={{ unmountOnExit: true }}
            defaultExpanded
            expanded={expanded === "needsReview"}
            onChange={handleAccordionChange("needsReview")}
            disabled={items.needsReview.length === 0}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2" lineHeight="inherit">
                Needs review ({items.needsReview.length})
              </Typography>
              <Tooltip
                enterDelay={500}
                title="One or more fields with metadata conflicts"
                placement="right"
              >
                <HelpOutlineOutlinedIcon
                  style={{ marginLeft: "1rem" }}
                  color="primary"
                  fontSize="small"
                />
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails>
              {items.needsReview.length > 0 ? (
                <ReviewTable>
                  <table>
                    <thead>
                      <tr>
                        <th>Resolution</th>
                        <th>Current</th>
                        <th>Incoming</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.needsReview.map((item, index) => {
                        const diff = bibtexEntryDiff(
                          item.matches[0].current,
                          item.matches[0].incoming
                        );
                        return (
                          <tr key={JSON.stringify(item)}>
                            <td>
                              <FormControl
                                sx={{ m: 0, minWidth: 150 }}
                                variant="standard"
                                size="small"
                              >
                                <Select
                                  value={item.action}
                                  onChange={(event: SelectChangeEvent) => {
                                    const newItems = { ...items };
                                    newItems.needsReview = [
                                      ...items.needsReview,
                                    ];
                                    newItems.needsReview[index] = {
                                      ...item,
                                      action: event.target
                                        .value as ImportAction,
                                    };
                                    setItems(newItems);
                                  }}
                                >
                                  <MenuItem value="skip">
                                    <Typography variant="body2">
                                      Skip
                                    </Typography>
                                  </MenuItem>
                                  <MenuItem value="merge">
                                    <Typography variant="body2">
                                      Merge
                                    </Typography>
                                  </MenuItem>
                                  <MenuItem value="overwrite">
                                    <Typography variant="body2">
                                      Overwrite
                                    </Typography>
                                  </MenuItem>
                                  <MenuItem value="create">
                                    <Typography variant="body2">
                                      Create new
                                    </Typography>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </td>
                            <td>
                              <div>
                                {renderReference(bibtexEntry2html(item))}
                              </div>
                              {diff.currentMissing.length > 0 && (
                                <>
                                  <h6>Missing</h6>
                                  <div className="diff">
                                    {diff.currentMissing.map((name) => (
                                      <span key={name}>{name}</span>
                                    ))}
                                  </div>
                                </>
                              )}
                            </td>
                            <td>
                              <div>
                                {renderReference(bibtexEntry2html(item))}
                              </div>
                              {diff.incomingMissing.length > 0 && (
                                <>
                                  <h6>Missing</h6>
                                  <div className="diff">
                                    {diff.incomingMissing.map((name) => (
                                      <span key={name}>{name}</span>
                                    ))}
                                  </div>
                                </>
                              )}
                              {diff.conflicts.length > 0 && (
                                <>
                                  <h6>Conflict</h6>
                                  <div className="diff conflict">
                                    {diff.conflicts.map((name) => (
                                      <span key={name}>{name}</span>
                                    ))}
                                  </div>
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </ReviewTable>
              ) : (
                <Typography variant="body2">
                  No conflicting references detected
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "flex-end",
              marginTop: "auto",
            }}
          >
            <Button
              onClick={() => {
                setMode("import");
              }}
              size="medium"
              variant="text"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={applyChanges}
              size="medium"
              variant="contained"
              color="primary"
            >
              Continue
            </Button>
          </Box>
        </>
      )}
      {mode === "summary" && (
        <>
          <Box
            sx={{
              display: "flex",
              flex: 1,
            }}
          >
            {applyStatus === "loading" ? (
              <LoadingOverlay message="Uploading BibTex references" />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  "& svg": {
                    width: "30px",
                    height: "30px",
                    color: theme.palette.primary.main,
                  },
                }}
              >
                <Icon icon="line-md:check-list-3-twotone" />
                <Typography variant="body1">
                  Your BibTex references has been successfully uploaded!
                </Typography>
              </Box>
            )}
          </Box>
          {applyStatus !== "loading" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => {
                  setFileContent("");
                  setExpanded("needsReview");
                  setRequestStatuses({});
                  setApplyStatus("idle");
                  setMode("import");
                }}
              >
                Upload more
              </Button>
            </Box>
          )}
        </>
      )}
    </Wrapper>
  );
};
export default ImportBibTex;
