/* eslint-disable import/prefer-default-export */

import { Box, styled } from "@mui/material";

export const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  overflow: "hidden",
  padding: "1px",
  borderRadius: "4px",
  gap: "0.5rem",
  "& .main-container": {
    position: "relative",
    flex: 1,
    "&:not(.browse)": {
      borderRadius: "4px",
    },
    boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
    background: theme.background.light,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    "& .header": {
      display: "flex",
      padding: "0.5rem 1rem",
      alignItems: "center",
      "& .option-container": {
        marginLeft: "auto",
        display: "flex",
        gap: "0.5rem",
      },
    },
    "& #chat-container": {
      padding: "1rem",
      display: "flex",
      flex: 1,
      alignItems: "start",
      gap: "1rem",
      overflow: "hidden",
      overflowY: "auto",
      transition: "all 0.3s",
      "& .placeholder": {
        background: `${theme.background.secondary}70`,
        borderRadius: "4px",
        borderBottomLeftRadius: "0",
        padding: "0.5rem 1rem",
        fontSize: "14px",
        marginRight: "1rem",
        "&.full-width": {
          width: "100%",
        },
        "& .verify-actions": {
          marginTop: theme.spacing(1),
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
        },
      },
      "& .message-container": {
        width: "100%",
        "& > *:not(:last-child)": {
          marginBottom: "1rem",
        },
        "& .date": {
          width: "100%",
        },
        "& .messages": {
          width: "100%",
          "& > .message:not(:last-child)": {
            marginBottom: "1rem",
          },
        },
      },
    },
    "& .continue-button": {
      position: "absolute",
      background: theme.background.light,
      bottom: "0.5rem",
      left: "50%",
      transform: "translateX(-50%)",
      boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
      whiteSpace: "nowrap",
    },
  },
  "& .loading-message": {
    "& span": {
      width: "12px",
      height: "12px",
      margin: "0 5px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      display: "inline-block",
      animationName: "dots",
      animationDuration: "2s",
      animationIterationCount: "infinite",
      animationTimingFunction: "ease-in-out",
      "&:nth-of-type(2)": {
        animationDelay: "0.4s",
      },
      "&:nth-of-type(3)": {
        animationDelay: "0.8s",
      },
    },
    "@keyframes dots": {
      "50%": {
        opacity: 0,
        transfrom: "scale(0.7) translateY(10px)",
      },
    },
  },
  "& .form-container": {
    background: theme.background.light,
    boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .collapse-button": {
      marginBottom: "0.5rem",
      "& .option-icon": {
        transition: "all 0.3s",
        "&.opened": {
          transform: "rotate(180deg)",
        },
      },
    },
    "& .MuiCollapse-root": {
      width: "100%",
    },
    "& .MuiCollapse-wrapperInner": {
      width: "100%",
      padding: "0 1rem",
      marginBottom: "0.5rem",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    "& .usage-container": {
      width: "100%",
      padding: "0 1rem",
      "& .credit-usage": {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        marginBottom: "0.5rem",
      },
    },
    "& .question-container": {
      padding: "1rem",
      display: "flex",
      gap: "1rem",
      alignItems: "center",
      width: "100%",
      "& .input-container": {
        flex: 1,
        "& p": {
          marginBottom: "0.3rem",
        },
      },
    },
    "& .external-knowledge-container, & .history-container": {
      width: "100%",
      "& .MuiCheckbox-root": {
        padding: 0,
        marginRight: "0.5rem",
      },
      "& label": {
        margin: 0,
      },
    },
    "& .answer-prefix-container": {
      flexDirection: "row",
      width: "100%",
      alignItems: "flex-end",
      "& .MuiCheckbox-root": {
        padding: 0,
        marginRight: "0.5rem",
        marginBottom: "0.3rem",
      },
    },
  },
}));
