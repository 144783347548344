import localforage from "localforage";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { IData } from "csl-json";
import {
  CiteGenListCreateBody,
  CiteGenListResponse,
  CiteGenListResponseList,
  CiteGenListUpdateBody,
  SuccessResponse,
} from "models/api/response.types";
import axios, {
  AxiosError,
  AxiosPromise,
  CancelToken as CancelTokenType,
} from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import handleAxiosError from "utils/handleAxiosAlert";
import { selectUser } from "store/features/session/slice";

export type CslStyle = { filename: string; title: string; parent?: string };

export interface SourceList {
  id: number;
  name: string;
  modified_at: string;
  items: SourceItem[];
  style: CslStyle;
}

export interface SourceItem {
  type?: string;
  [key: string]: any;
}

export interface SearchResponse {
  message: string;
  items: { csljson: IData }[];
}

const fetchSourceLists = (options?: {
  cancelToken?: CancelTokenType;
}): AxiosPromise<CiteGenListResponseList> =>
  axios.get("/api/citegen/lists", { cancelToken: options?.cancelToken });

const createSourceList = (
  body: CiteGenListCreateBody
): AxiosPromise<CiteGenListResponse> =>
  axios.post("/api/citegen/list/create", body);

const updateSourceList = (
  id: number,
  body: CiteGenListUpdateBody
): AxiosPromise<CiteGenListResponse> =>
  axios.post(`/api/citegen/list/${id}/update`, body);

const deleteSourceList = (id: number): AxiosPromise<SuccessResponse> =>
  axios.delete(`/api/citegen/list/${id}/delete`);

const fetchLocalStorageSourceLists = async (): Promise<SourceList[]> => {
  try {
    const value = await localforage.getItem(`sourceLists`);
    if (value === null) return [];
    return value as SourceList[];
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createLocalStorageSourceList = async (
  name: string,
  items: SourceItem[],
  style: CslStyle
): Promise<SourceList> => {
  let sourceLists =
    ((await localforage.getItem(`sourceLists`)) as SourceList[]) || [];
  if (!sourceLists?.length) {
    sourceLists = [];
  }
  const ids = (sourceLists || []).map((b: SourceList) => b.id);
  const newId = ids.length > 0 ? Math.max(...ids) + 1 : 1;
  const newSourceList: SourceList = {
    name,
    items,
    style,
    id: newId,
    modified_at: new Date().toISOString(),
  };
  const newSourceLists = [...sourceLists, newSourceList];
  await localforage.setItem(`sourceLists`, newSourceLists);
  return newSourceList;
};

const updateLocalStorageSourceList = async (
  id: number,
  newValue: SourceList
): Promise<SourceList> => {
  try {
    const sourceLists =
      ((await localforage.getItem(`sourceLists`)) as SourceList[]) || [];
    const newSourceLists = [...sourceLists];
    const index = newSourceLists.findIndex((bib) => bib.id === newValue.id);
    newSourceLists[index] = newValue;
    await localforage.setItem(`sourceLists`, newSourceLists);
    return newValue;
  } catch (err) {
    return newValue;
  }
};

const deleteLocalStorageSourceList = async (id: number) => {
  const value = await localforage.getItem(`sourceLists`);
  const sourceLists = value ? (value as SourceList[]) : [];
  const newSourceLists = sourceLists.filter((b) => b.id !== id);
  await localforage.setItem(`sourceLists`, newSourceLists);
};

const citegenService = {
  fetchLocalStorageSourceLists,
  createLocalStorageSourceList,
  updateLocalStorageSourceList,
  deleteLocalStorageSourceList,
  fetchSourceLists,
  createSourceList,
  updateSourceList,
  deleteSourceList,
};

export default citegenService;

const sourceListToCiteGenListUpdateBody = (sourceList: SourceList) => {
  const body: CiteGenListUpdateBody = {
    data_json: {
      name: sourceList.name,
      style: sourceList.style,
      items: sourceList.items,
    },
  };
  return body;
};

const citeGenListResponseToSourceList = (response: CiteGenListResponse) => {
  const sourceList: SourceList = {
    id: response.id,
    name: response.data_json.name,
    modified_at: response.modified_at,
    items: response.data_json.items,
    style: response.data_json.style,
  };
  return sourceList;
};

export const useSourceListsLocal = () => {
  const sourceListsQueryKey = ["sourceListsLocal"];
  const queryClient = useQueryClient();
  const { data: sourceLists } = useQuery<SourceList[]>(
    sourceListsQueryKey,
    () => {
      return citegenService.fetchLocalStorageSourceLists();
    }
  );
  const createSourceListMutation = useMutation(
    (payload: { name: string; items: SourceItem[]; style: CslStyle }) => {
      return citegenService.createLocalStorageSourceList(
        payload.name,
        payload.items,
        payload.style
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(sourceListsQueryKey);
      },
    }
  );
  const updateSourceListMutation = useMutation(
    (variables: { id: number; sourceList: SourceList }) => {
      return citegenService.updateLocalStorageSourceList(
        variables.id,
        variables.sourceList
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(sourceListsQueryKey);
      },
    }
  );
  const deleteSourceListMutation = useMutation(
    (id: number) => {
      return citegenService.deleteLocalStorageSourceList(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(sourceListsQueryKey);
      },
    }
  );

  return {
    sourceListsLocal: sourceLists,
    sourceListsQueryKeyLocal: sourceListsQueryKey,
    createSourceListMutationLocal: createSourceListMutation,
    updateSourceListMutationLocal: updateSourceListMutation,
    deleteSourceListMutationLocal: deleteSourceListMutation,
  };
};

export const useSourceListsApi = (userId = -1) => {
  const sourceListsQueryKey = ["sourceListsApi", userId];
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data: sourceLists } = useQuery<SourceList[]>(
    sourceListsQueryKey,
    () => {
      if (userId > -1) {
        const { CancelToken } = axios;
        const source = CancelToken.source();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const promise = citegenService.fetchSourceLists({
          cancelToken: source.token,
        });
        (promise as any).cancel = () => {
          source.cancel("Query was cancelled by React Query");
        };
        return promise.then(
          ({ data: responseData }: { data: CiteGenListResponseList }) => {
            return responseData.map((item) => {
              const sourceList: SourceList = {
                id: item.id,
                name: item.data_json.name,
                modified_at: item.modified_at,
                items: item.data_json.items,
                style: item.data_json.style,
              };
              return sourceList;
            });
          }
        );
      }
      return new Promise<SourceList[]>((resolve, reject) => {
        resolve([]);
      });
    }
  );

  const indexFromId = React.useMemo(
    () =>
      sourceLists?.reduce(
        (lut: { [id: number]: number }, sourceList, index) => {
          // eslint-disable-next-line no-param-reassign
          lut[sourceList.id] = index;
          return lut;
        },
        {}
      ),
    [sourceLists]
  );

  const getCachedSourceListById: (
    id: number
  ) => [item: Readonly<SourceList> | undefined, index: Readonly<number>] =
    React.useCallback(
      (id: number) =>
        sourceLists && indexFromId && id in indexFromId
          ? [sourceLists[indexFromId[id]], indexFromId[id]]
          : [undefined, -1],
      [indexFromId]
    );

  const removeSourceList = (id: number) => {
    const [currentSourceList, index] = getCachedSourceListById(id);
    if (sourceLists && currentSourceList) {
      const newSourceLists = [...sourceLists];
      newSourceLists.splice(index, 1);
      queryClient.setQueryData<SourceList[]>(
        sourceListsQueryKey,
        newSourceLists
      );
      return currentSourceList;
    }
    return undefined;
  };

  const upsertCachedSourceList = (
    newSourceList: SourceList
  ): SourceList | undefined => {
    const [currentSourceList, index] = getCachedSourceListById(
      newSourceList.id
    );
    if (sourceLists) {
      const newSourceLists = [...sourceLists];
      if (index > -1) {
        newSourceLists.splice(index, 1, newSourceList);
      } else {
        newSourceLists.push(newSourceList);
      }
      queryClient.setQueryData<SourceList[]>(
        sourceListsQueryKey,
        newSourceLists
      );
    } else {
      queryClient.setQueryData<SourceList[]>(sourceListsQueryKey, [
        newSourceList,
      ]);
    }
    return currentSourceList;
  };

  const createSourceListMutation = useMutation(
    async (payload: { name: string; items: SourceItem[]; style: CslStyle }) => {
      const response = await citegenService.createSourceList(
        sourceListToCiteGenListUpdateBody({
          ...payload,
          id: -1,
          modified_at: new Date().toISOString(),
        }) as CiteGenListCreateBody
      );
      return citeGenListResponseToSourceList(response.data);
    },
    {
      retry: false,
      onMutate: async (payload: {
        name: string;
        items: SourceItem[];
        style: CslStyle;
      }) => {
        await queryClient.cancelQueries(sourceListsQueryKey);
        upsertCachedSourceList({
          ...payload,
          id: -1,
          modified_at: new Date().toISOString(),
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(sourceListsQueryKey);
      },
      onError: (error) => {
        queryClient.invalidateQueries(sourceListsQueryKey);
        handleAxiosError(error as AxiosError, dispatch);
      },
    }
  );

  const updateSourceListMutation = useMutation(
    async (variables: { id: number; sourceList: SourceList }) => {
      const response = await citegenService.updateSourceList(
        variables.id,
        sourceListToCiteGenListUpdateBody(variables.sourceList)
      );
      return citeGenListResponseToSourceList(response.data);
    },
    {
      retry: false,
      onMutate: async (variables: { id: number; sourceList: SourceList }) => {
        await queryClient.cancelQueries(sourceListsQueryKey);
        const [currentSourceList] = getCachedSourceListById(variables.id);
        if (currentSourceList) {
          const newSourceList = {
            ...currentSourceList,
            ...variables.sourceList,
          };
          upsertCachedSourceList(newSourceList);
        }
      },
      onSuccess: (sourceList) => {
        if (sourceList) {
          upsertCachedSourceList(sourceList);
        }
        queryClient.invalidateQueries(sourceListsQueryKey);
      },
      onError: (error) => {
        queryClient.invalidateQueries(sourceListsQueryKey);
        handleAxiosError(error as AxiosError, dispatch);
      },
    }
  );

  const deleteSourceListMutation = useMutation(
    (id: number) => {
      citegenService.deleteSourceList(id);
      return new Promise(() => {});
    },
    {
      onMutate: async (id: number) => {
        await queryClient.cancelQueries(sourceListsQueryKey);
        removeSourceList(id);
      },
      onSettled: (response, error, variables, context) => {
        if (error) {
          handleAxiosError(error as AxiosError, dispatch);
        }
        queryClient.invalidateQueries(sourceListsQueryKey);
      },
    }
  );

  return {
    sourceListsApi: sourceLists,
    sourceListsQueryKeyApi: sourceListsQueryKey,
    createSourceListMutationApi: createSourceListMutation,
    updateSourceListMutationApi: updateSourceListMutation,
    deleteSourceListMutationApi: deleteSourceListMutation,
  };
};

const fetchStyle = async (style: CslStyle): Promise<string> => {
  const { filename, parent } = style;
  const key = `styles/${parent || filename}.csl`;
  const cachedValue = await localforage.getItem(key);
  if (cachedValue) {
    // eslint-disable-next-line no-console
    console.info(`Loaded ${filename}`);
    return new Promise((resolve) => {
      resolve(cachedValue as string);
    });
  }
  // eslint-disable-next-line no-console
  console.info(`Could not find ${filename}`);
  return fetch(`https://ks-collab.github.io/csl-api/${key}`)
    .then((response) => response.text())
    .then((data) => {
      localforage.setItem(key, data);
      // eslint-disable-next-line no-console
      console.info(`Saved ${filename}`);
      return data;
    });
};

export const useCslStyle = (style: CslStyle) => {
  const { filename } = style;
  const cslStyleQueryKey = [`csl/style/${filename}`];
  const { data: styleData, isFetching } = useQuery(
    cslStyleQueryKey,
    () => fetchStyle(style),
    { enabled: !!filename, placeholderData: undefined }
  );
  return { styleData, styleDataIsFetching: isFetching };
};

type CslLocales = Record<string, string>;

const fetchLocales = async (): Promise<CslLocales> => {
  const key = `csl-locales`;
  const cachedValue = await localforage.getItem(key);
  if (cachedValue && (cachedValue as CslLocales)["en-US"]) {
    // eslint-disable-next-line no-console
    console.info("Loaded locale data");
    return new Promise((resolve) => {
      resolve(cachedValue as CslLocales);
    });
  }
  // eslint-disable-next-line no-console
  console.info(`Could not find locale data`);
  return fetch(`https://ks-collab.github.io/csl-api/locales.json`)
    .then((response) => response.json())
    .then((data) => {
      localforage.setItem(key, data);
      // eslint-disable-next-line no-console
      console.info(`Saved locale data`);
      return data;
    });
};

export const useCslLocales = () => {
  const cslLocalesQueryKey = [`csl/locales`];
  const { data: localeData, isFetching } = useQuery(
    cslLocalesQueryKey,
    () => fetchLocales(),
    { placeholderData: undefined }
  );
  return { localeData, localeDataIsFetching: isFetching };
};

type CslStyles = { filename: string; title: string; parent?: string }[];

const fetchStyles = async (): Promise<CslStyles> => {
  const key = `csl-styles`;
  const cachedValue = await localforage.getItem(key);
  if (cachedValue && (cachedValue as CslStyles).length > 0) {
    // eslint-disable-next-line no-console
    console.info("Loaded locale data");
    return new Promise((resolve) => {
      resolve(cachedValue as CslStyles);
    });
  }
  // eslint-disable-next-line no-console
  console.info(`Could not find styles data`);
  return fetch(`https://ks-collab.github.io/csl-api/styles.json`)
    .then((response) => response.json())
    .then((data) => {
      localforage.setItem(key, data);
      // eslint-disable-next-line no-console
      console.info(`Saved styles data`);
      return data;
    });
};

export const useCslStyles = () => {
  const cslStylesQueryKey = [`csl/styles`];
  const { data: stylesData, isFetching } = useQuery(
    cslStylesQueryKey,
    () => fetchStyles(),
    { placeholderData: undefined }
  );
  return { stylesData, stylesDataIsFetching: isFetching };
};

type CreateSourceListMutation = UseMutationResult<
  SourceList,
  unknown,
  { name: string; items: SourceItem[]; style: CslStyle },
  unknown
>;

type UpdateSourceListMutation = UseMutationResult<
  SourceList,
  unknown,
  { id: number; sourceList: SourceList },
  unknown
>;

type DeleteSourceListMutation = UseMutationResult<
  unknown,
  unknown,
  number,
  unknown
>;

export const useSourceListsCustomHook = () => {
  const user = useSelector(selectUser);
  const {
    sourceListsLocal,
    createSourceListMutationLocal,
    updateSourceListMutationLocal,
    deleteSourceListMutationLocal,
  } = useSourceListsLocal();
  const {
    sourceListsApi,
    createSourceListMutationApi,
    updateSourceListMutationApi,
    deleteSourceListMutationApi,
  } = useSourceListsApi(user?.id);

  const [sourceLists, setSourceLists] = React.useState<
    SourceList[] | undefined
  >();
  const [createSourceListMutation, setCreateSourceListMutation] =
    React.useState<CreateSourceListMutation>(createSourceListMutationLocal);
  const [updateSourceListMutation, setUpdateSourceListMutation] =
    React.useState<UpdateSourceListMutation>(updateSourceListMutationLocal);
  const [deleteSourceListMutation, setDeleteSourceListMutation] =
    React.useState<DeleteSourceListMutation>(deleteSourceListMutationLocal);

  React.useEffect(() => {
    if (user) {
      setSourceLists(sourceListsApi);
      setCreateSourceListMutation(createSourceListMutationApi);
      setUpdateSourceListMutation(updateSourceListMutationApi);
      setDeleteSourceListMutation(deleteSourceListMutationApi);
    } else {
      setSourceLists(sourceListsLocal);
      setCreateSourceListMutation(createSourceListMutationLocal);
      setUpdateSourceListMutation(updateSourceListMutationLocal);
      setDeleteSourceListMutation(deleteSourceListMutationLocal);
    }
  }, [user, sourceListsLocal, sourceListsApi]);

  return {
    sourceLists,
    createSourceListMutation,
    updateSourceListMutation,
    deleteSourceListMutation,
  };
};
