import { Alert } from "store/features/general/slice";

interface IError {
  need_to_redirect: boolean;
  need_to_reset_organization: boolean;
  message: string;
}

export const ErrorMessages = {
  NETWORK_ERROR_MESSAGE:
    "Can't reach the server. Check your internet connection and try refreshing the page.",
};

// axiosErrors
const UNEXPECTED_ERROR = "Request failed with status code 500";
const BAD_REQUEST = "Request failed with status code 400";
const NOT_FOUD = "Request failed with status code 404";
const NETWORK_ERROR = "Network Error";
// backendErrors
const ORGANIZATION_NOT_FOUND = "Workspace not found.";

const axiosErrors = [UNEXPECTED_ERROR, BAD_REQUEST, NOT_FOUD];
const organizationErrors = [ORGANIZATION_NOT_FOUND];

// checking if errors are coming from API requests or no
// check if need to change route
// check if need to reload anything
const errorCheck = (error: Alert) => {
  const errorTypes: IError = {
    need_to_redirect: false,
    need_to_reset_organization: false,
    message: "Something went wrong",
  };
  const { message, response } = error.alert;

  // if no response nothing that we can check
  // just return error
  if (message === NETWORK_ERROR) {
    errorTypes.need_to_redirect = true;
    errorTypes.message = ErrorMessages.NETWORK_ERROR_MESSAGE;
  } else if (response && response.data) {
    const { data } = response;
    if (organizationErrors.includes(data.error)) {
      errorTypes.need_to_reset_organization = true;
    } else if (axiosErrors.includes(message)) {
      errorTypes.message = message;
    }
  } else if (message) {
    errorTypes.message = message;
  }

  return errorTypes;
};

export default errorCheck;
