import { AnyAction, CombinedState, combineReducers } from "redux";
import {
  BrowserState,
  reducer as browser,
  initialState as browserInitialState,
} from "store/features/browser/slice";
import {
  SessionState,
  reducer as session,
  initialState as sessionInitialState,
} from "store/features/session/slice";
import {
  reducer as general,
  GeneralState,
  initialState as generalInitialState,
} from "store/features/general/slice";
import {
  reducer as settings,
  SettingsState,
  initialState as settingsInitialState,
} from "store/features/settings/slice";
import {
  reducer as ai,
  AIState,
  initialState as aiInitialState,
} from "store/features/ai/slice";
import {
  reducer as documentViewer,
  DocumentViewerState,
  initialState as documentViewerInitialState,
} from "store/features/documentViewer/slice";

export interface IState {
  general: GeneralState;
  session: SessionState;
  browser: BrowserState;
  documentViewer: DocumentViewerState;
  ai: AIState;
  settings: SettingsState;
}

const appReducer = combineReducers({
  session,
  general,
  browser,
  documentViewer,
  ai,
  settings,
});

// logout should wipe-out the state
const rootReducer = (
  state: CombinedState<IState> | undefined,
  action: AnyAction
): CombinedState<IState> => {
  if (action.type === "session/cleanup") {
    return appReducer(undefined, action);
  }
  if (action.type === "session/log_as_admin") {
    const newState = {
      session: {
        ...sessionInitialState,
        user: action.userData,
        enableTelemetry: false,
      },
      general: generalInitialState,
      browser: browserInitialState,
      documentViewer: documentViewerInitialState,
      ai: aiInitialState,
      settings: settingsInitialState,
    };
    return appReducer(newState, action);
  }
  if (action.type === "session/update_app_version") {
    const newState = {
      session: action.sessionState,
      general: generalInitialState,
      browser: browserInitialState,
      documentViewer: documentViewerInitialState,
      ai: aiInitialState,
      settings: settingsInitialState,
    };
    return appReducer(newState, action);
  }
  return appReducer(state, action);
};
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
