/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
import React from "react";
import {
  IconButton,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { getComparator, ITableRow, stableSort } from "./table-utils";

interface IHeadCell {
  id: string;
  label: string;
}

interface IProjectTableProps {
  minWidth: number;
  maxHeight: number;
  headCells: IHeadCell[];
  tableRows: ITableRow[];
  canDelete?: boolean;
  deleteRow?: (row: ITableRow) => void;
}

const SimpleTableHead = (props: {
  order: "asc" | "desc";
  orderBy: string;
  onRequestSort: (event: any, property: any) => void;
  headCells: IHeadCell[];
  canDelete?: boolean;
}) => {
  const { order, orderBy, onRequestSort, headCells, canDelete } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell.id ? (order as SortDirection) : undefined
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        {canDelete && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

// custom MuiTableComponent for use in different pages
// covers custom cells and custom sort
const SimpleTable: React.FC<IProjectTableProps> = ({
  minWidth,
  headCells,
  tableRows,
  maxHeight,
  canDelete,
  deleteRow,
}) => {
  const theme = useTheme();
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer
      sx={{
        maxHeight,
        "& .MuiTable-root": {
          border: `1px solid ${theme.grey.light}`,
          "& .MuiTableCell-root": {
            borderBottom: `1px solid ${theme.grey.light}`,
          },
        },
      }}
    >
      <Table size="small" sx={{ minWidth, maxHeight }}>
        <SimpleTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCells}
          canDelete={canDelete}
        />
        <TableBody>
          {stableSort(tableRows, getComparator(order, orderBy)).map(
            (row: ITableRow, index: number) => {
              return (
                <TableRow hover key={`${row[index]}+${index}`}>
                  {Object.values(row).map(
                    (cell: string | number, key: number) => {
                      if (row.id === cell) {
                        return null;
                      }
                      return (
                        <TableCell
                          key={`${cell}+${key}+${index}`}
                          component="th"
                          sx={{
                            position: "relative",
                          }}
                        >
                          {cell}
                        </TableCell>
                      );
                    }
                  )}
                  {canDelete && (
                    <TableCell
                      component="th"
                      sx={{
                        position: "relative",
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          if (deleteRow) {
                            deleteRow(row);
                          }
                        }}
                      >
                        <DeleteOutlineOutlinedIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;
