/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
import React, { Component, ErrorInfo, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { IState } from "store/reducers";
import { SessionState } from "store/features/session/slice";
import CloudOffOutlinedIcon from "@mui/icons-material/CloudOffOutlined";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import { logUserEvent, telemetryAction } from "utils/useTelemetry";
import { Box, Typography } from "@mui/material";
import { ErrorMessages } from "utils/errorCheck";
import { clearAllAlerts } from "store/features/general/slice";
import { APP_TITLE, LOGO_SRC, PROD_HOSTNAME } from "company-config";

const ErrorFallbackWrapper = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  minWidth: "0",
  wordWrap: "break-word",
  backgroundColor: "#fff",
  backgroundClip: "border-box",
  border: "1px solid rgba(0, 0, 0, 0.125)",
  borderRadius: "0.25rem",
  margin: "3rem 0",
}));

const ErrorFallbakcHeader = styled("div")(() => ({
  padding: "0.75rem 1.25rem",
  marginBottom: "0",
  backgroundColor: "rgba(0, 0, 0, 0.03)",
  borderBottom: "1px solid rgba(0, 0, 0, 0.125)",

  "& > p > span": {
    cursor: "pointer",
    color: "#0077ff",
    marginLleft: "5px",
  },
}));

const ErrorFallbackBody = styled("div")(() => ({
  flex: "1 1 auto",
  minHeight: "1px",
  padding: "1.25rem",
}));

const ProdFallbackWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  position: "relative",
  padding: "1rem",
  "& .logo": {
    height: "40px",
  },
  "& .container": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    gap: "1rem",
  },
  "& svg.image": {
    width: "100px",
    height: "100px",
    fill: "rgb(195, 61, 51)",
  },
}));

interface Props {
  children?: ReactNode;
  userSession: SessionState;
  clearAlerts: () => void;
}

interface State {
  error?: Error;
  isProd?: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: undefined,
      isProd: window.location.hostname === PROD_HOSTNAME,
    };
  }
  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { user, currentOrganizationId, sessionId } = this.props.userSession;

    // cut error for less string
    if (user) {
      const firstComponentStackError = errorInfo.componentStack.split("\n")[1];
      logUserEvent({
        user_id: user?.id,
        organization_id: currentOrganizationId || -1,
        action: telemetryAction.runtime_error,
        session_uuid: sessionId || "",
        payload_json: {
          error,
          errorInfo: firstComponentStackError,
        },
      });
    }

    // clear all alerts
    this.props.clearAlerts();
  }

  public render() {
    if (this.state.error) {
      if (this.state.isProd) {
        return (
          <ProdFallbackWrapper>
            <img src={LOGO_SRC} className="logo" alt={APP_TITLE} />
            <Box className="container">
              {this.state.error.message ===
              ErrorMessages.NETWORK_ERROR_MESSAGE ? (
                <>
                  <CloudOffOutlinedIcon className="image" />
                  <Typography variant="h6">Network Error</Typography>
                </>
              ) : (
                <>
                  <SentimentDissatisfiedOutlinedIcon className="image" />
                  <Typography variant="h6">Something went wrong</Typography>
                </>
              )}
              <Typography variant="body2">
                {this.state.error.message}
              </Typography>
            </Box>
          </ProdFallbackWrapper>
        );
      }

      return (
        <ErrorFallbackWrapper>
          <ErrorFallbakcHeader>
            <p>
              There was an error.
              <span
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload this page
              </span>
            </p>
          </ErrorFallbakcHeader>
          <ErrorFallbackBody>
            <details>
              <summary>Click for error details</summary>
              {this.state.error.message}
            </details>
          </ErrorFallbackBody>
        </ErrorFallbackWrapper>
      );
    }

    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearAlerts: () => dispatch(clearAllAlerts()),
  };
};

const mapStateToProps = (state: IState) => {
  return {
    userSession: state.session,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
