import { Box, Skeleton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactNode } from "react";

const UsageBarWrapper = styled(Box)(({ theme }) => ({
  "& .usingLabel": {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  "& .bar": {
    backgroundColor: "rgb(238, 238, 238)",
    border: `1px solid ${theme.grey.light}`,
    height: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    "& .section": {
      height: "10px",
    },
    margin: "0.5rem 0 0.5rem 0",
  },
  "& .legend": {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "1rem",
    gap: "40px",
    "& .entry": {
      display: "flex",
      marginRight: "1rem",
      alignItems: "center",
      "& .key": {
        display: "inline-block",
        height: "20px",
        width: "20px",
        top: "0.5rem",
        marginRight: "0.5rem",
        backgroundColor: "#ccc",
      },
    },
  },
}));

const UsageBar: React.FC<{
  label?: ReactNode;
  usingLabel?: string;
  totalLabel?: string;
  loading: boolean;
  data: Array<{ label: string; percent: number; color: string }>;
}> = ({ label, usingLabel, totalLabel, data, loading }) => {
  return (
    <UsageBarWrapper>
      {label ||
        (usingLabel && totalLabel && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            {label && (
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "500",
                }}
              >
                {label}
              </Typography>
            )}
            {usingLabel && totalLabel && (
              <Typography variant="body2">
                {" "}
                Using <span className="usingLabel">{usingLabel}</span> of{" "}
                <span>{totalLabel}</span>
              </Typography>
            )}
          </Box>
        ))}
      {loading ? (
        <Skeleton variant="rectangular" sx={{ height: "24px" }} />
      ) : (
        <div className="bar">
          {data.map((section) => {
            return (
              <div
                key={section.label}
                className="section"
                style={{
                  width: `${section.percent}%`,
                  backgroundColor: section.color,
                }}
              />
            );
          })}
        </div>
      )}
      <div className="legend">
        {data.map((section) => {
          return (
            <div key={`legend-entry-${section.label}`} className="entry">
              <div className="key" style={{ backgroundColor: section.color }} />
              <Typography variant="body2" color="textSecondary">
                {section.label}
              </Typography>
            </div>
          );
        })}
      </div>
    </UsageBarWrapper>
  );
};

export default UsageBar;
