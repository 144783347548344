import React from "react";
import { Box, styled } from "@mui/material";
import { APP_TITLE, SMALL_LOGO_SRC, companyFeatures } from "company-config";

const LoaderContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  width: "100%",
  height: "100%",
  zIndex: "5000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#f6f8fc",
  "& .logo": {
    animationName: "rotation",
    animationDuration: "3s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(359deg)",
    },
  },
  ".loader, .loader:before, .loader:after": {
    borderRadius: "50%",
    width: "2.5em",
    height: "2.5em",
    animationFillMode: "both",
    animation: "bblFadInOut 1.8s infinite ease-in-out",
  },
  ".loader": {
    color: theme.palette.primary.main,
    fontSize: "7px",
    position: "relative",
    textIndent: "-9999em",
    transform: "translateZ(0)",
    animationDelay: "-0.16s",
  },
  ".loader:before,\n.loader:after": {
    content: "''",
    position: "absolute",
    top: "0",
  },
  ".loader:before": { left: "-3.5em", animationDelay: "-0.32s" },
  ".loader:after": { left: "3.5em" },
  "@keyframes bblFadInOut": {
    "0%, 80%, 100%": { boxShadow: "0 2.5em 0 -1.3em" },
    "40%": { boxShadow: "0 2.5em 0 0" },
  },
}));

const MainLoader: React.FC = () => {
  if (companyFeatures.isWhiteLabel) {
    return (
      <LoaderContainer>
        <span className="loader" />
      </LoaderContainer>
    );
  }

  return (
    <LoaderContainer>
      <img className="logo" width="110" alt={APP_TITLE} src={SMALL_LOGO_SRC} />
    </LoaderContainer>
  );
};

export default MainLoader;
