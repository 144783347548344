/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { documentService } from "api";
import { DocumentReadResponse } from "models/api/response.types";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { useOrganizations } from "api/organizationService";
import LoadingButton from "@mui/lab/LoadingButton";
import { addAlert } from "store/features/general/slice";

const CopyDocumentsDialog: React.FC<{
  setOpen: (open: boolean) => void;
  selectedDocuments: DocumentReadResponse[];
}> = ({ setOpen, selectedDocuments }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const user = useSelector(selectUser);
  const { organizations } = useOrganizations(user?.id);
  const [destinationOrganization, setDestinationOrganization] = useState<{
    id: number;
    label: string;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [copyTags, setCopyTags] = useState<boolean>(false);
  const [copyAnnotations, setCopyAnnotations] = useState<boolean>(true);

  const handleSubmit = () => {
    if (destinationOrganization && destinationOrganization.id) {
      setLoading(true);
      documentService
        .copyDocuments({
          destination_organization_id: destinationOrganization.id,
          document_ids: selectedDocuments?.map((doc) => doc.id),
          copy_annotations: copyAnnotations,
          copy_tags: copyTags,
        })
        .then(() => {
          setLoading(false);
          dispatch(
            addAlert({
              severity: "success",
              autoHideDuration: 5000,
              alert: {
                message: `References successfully copied to ${destinationOrganization.label}.`,
              },
            })
          );
          setOpen(false);
        });
    } else {
      setOpen(false);
    }
  };

  const renderOptions = () => {
    return (
      organizations
        ?.filter((organization) => organization.id !== currentOrganizationId)
        ?.map((item) => ({ id: item.id, label: item.name })) || []
    );
  };

  return (
    <Dialog
      open
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Select copy destination
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <Autocomplete
            value={destinationOrganization}
            onChange={(event: React.ChangeEvent<unknown>, value) => {
              setDestinationOrganization(
                value as { id: number; label: string }
              );
            }}
            options={renderOptions()}
            renderOption={(props, option) => (
              <ListItem
                key={option.id}
                disablePadding
                disableGutters
                {...props}
              >
                <ListItemText>
                  <Typography variant="body2">{option.label}</Typography>
                </ListItemText>
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select workspace"
                size="small"
                variant="outlined"
                color="primary"
                sx={{
                  marginBottom: theme.spacing(2),
                }}
              />
            )}
          />
          <FormControlLabel
            className="checkbox"
            control={
              <Checkbox
                checked={copyAnnotations}
                onChange={(e) => setCopyAnnotations(e.target.checked)}
                disableRipple
                sx={{
                  "& > svg": {
                    width: "20px",
                    height: "20px",
                  },
                }}
              />
            }
            label={<Typography variant="body2">Copy annotations</Typography>}
          />
          <FormControlLabel
            className="checkbox"
            control={
              <Checkbox
                checked={copyTags}
                onChange={(e) => setCopyTags(e.target.checked)}
                disableRipple
                sx={{
                  "& > svg": {
                    width: "20px",
                    height: "20px",
                  },
                }}
              />
            }
            label={<Typography variant="body2">Copy tags</Typography>}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          className="submit-button"
          variant="contained"
          size="medium"
          color="primary"
          disabled={!destinationOrganization}
          onClick={handleSubmit}
          loading={loading}
        >
          {selectedDocuments.length > 1 ? "Copy documents" : "Copy document"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CopyDocumentsDialog;
