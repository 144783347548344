import axios, { AxiosError, AxiosPromise, AxiosResponse } from "axios";
import {
  AccessTokenResponse,
  Notification,
  NotificationCountUnreadResponse,
  SuccessResponse,
} from "models/api/response.types";
import { useCallback, useMemo } from "react";
import {
  useQuery,
  useQueryClient,
  useMutation,
  UseMutationResult,
} from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { addAlert } from "store/features/general/slice";
import handleAxiosError from "utils/handleAxiosAlert";

const notificationWarning = (text: string, dispatch: any) => {
  dispatch(
    addAlert({
      severity: "error",
      autoHideDuration: 10000,
      alert: {
        message: text,
      },
    })
  );
};

const fetchUnread = (): AxiosPromise<NotificationCountUnreadResponse> => {
  return axios.get(`/api/notification/count_unread`);
};

const fetchNotifications = (): AxiosPromise<Notification[]> => {
  return axios.get(`/api/notification/list`);
};

const deleteNotification = (id: number): AxiosPromise<SuccessResponse> => {
  return axios.delete(`/api/notification/${id}/delete`);
};

const markNotification = (
  id: number,
  payload: { read: boolean }
): AxiosPromise<Notification> => {
  return axios.post(`/api/notification/${id}/mark`, payload);
};

const notificationService = {
  fetchUnread,
  fetchNotifications,
  deleteNotification,
  markNotification,
};

export default notificationService;

interface UseNotificationsResult {
  notifications: Notification[] | undefined;
  notificationsQueryKey: string[];
  notificationsIsLoading: boolean;
  getCachedNotificationById: (
    id: number
  ) => [item: Readonly<Notification> | undefined, index: Readonly<number>];
  deleteNotificationMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    number,
    {
      previousNotification: Readonly<Notification> | undefined;
    }
  >;
  markNotificationMutation: UseMutationResult<
    AxiosResponse<Notification>,
    unknown,
    number,
    {
      previousNotification: Readonly<Notification> | undefined;
    }
  >;
  removeCachedNotification: (id: number) => Notification | undefined;
  upsertCachedNotification: (
    newNotification: Notification
  ) => Notification | undefined;
  markNotificationsAsRead: (ids: number[]) => void;
}

export const useNotifications = (
  user: AccessTokenResponse | undefined
): UseNotificationsResult => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const notificationsQueryKey = [`user/${user?.id}/notifications`];

  const { data: notifications, isLoading: notificationsIsLoading } = useQuery<
    unknown,
    unknown,
    Notification[],
    any
  >(
    notificationsQueryKey,
    () => notificationService.fetchNotifications().then(({ data }) => data),
    {
      enabled: !!user,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch, { user }),
    }
  );

  const notificationIndexById = useMemo(
    () =>
      notifications?.reduce(
        (lut: { [id: number]: number }, notification, index) => {
          // eslint-disable-next-line no-param-reassign
          lut[notification.id] = index;
          return lut;
        },
        {}
      ),
    [notifications]
  );

  const getCachedNotificationById: (
    id: number
  ) => [item: Readonly<Notification> | undefined, index: Readonly<number>] =
    useCallback(
      (id: number) =>
        notifications && notificationIndexById && id in notificationIndexById
          ? [
              notifications[notificationIndexById[id]],
              notificationIndexById[id],
            ]
          : [undefined, -1],
      [notificationIndexById]
    );

  const removeCachedNotification = (id: number): Notification | undefined => {
    const [currentNotification, index] = getCachedNotificationById(id);
    if (notifications && currentNotification) {
      const newNotifications = [...notifications];
      newNotifications.splice(index, 1);
      queryClient.setQueryData<Notification[]>(
        notificationsQueryKey,
        newNotifications
      );
      return currentNotification;
    }
    return undefined;
  };

  const upsertCachedNotification = (
    newNotification: Notification
  ): Notification | undefined => {
    const [currentNotification, index] = getCachedNotificationById(
      newNotification.id
    );
    if (notifications) {
      const newNotifications = [...notifications];
      if (index > -1) {
        newNotifications.splice(index, 1, newNotification);
      } else {
        newNotifications.unshift(newNotification);
      }
      queryClient.setQueryData<Notification[]>(
        notificationsQueryKey,
        newNotifications
      );
    } else {
      queryClient.setQueryData<Notification[]>(notificationsQueryKey, [
        newNotification,
      ]);
    }
    return currentNotification;
  };

  const deleteNotificationMutation = useMutation(
    (id: number) => notificationService.deleteNotification(id),
    {
      retry: false,
      onMutate: async (id: number) => {
        const [currentNotification] = getCachedNotificationById(id);
        if (currentNotification)
          removeCachedNotification(currentNotification.id);
        return { previousNotification: currentNotification };
      },
      onError: () => {
        queryClient.invalidateQueries(notificationsQueryKey);
      },
    }
  );

  const markNotificationMutation = useMutation(
    (id: number) => notificationService.markNotification(id, { read: true }),
    {
      retry: false,
      onMutate: async (id: number) => {
        const [currentNotification] = getCachedNotificationById(id);
        if (currentNotification)
          upsertCachedNotification({
            ...currentNotification,
            read_at: new Date().toISOString(),
          });
        return { previousNotification: currentNotification };
      },
      onError: (error, payload, context) => {
        if (context?.previousNotification?.type === "invite") {
          notificationWarning("Invite no longer exists", dispatch);
        } else {
          handleAxiosError(error as AxiosError, dispatch);
        }
        queryClient.invalidateQueries(notificationsQueryKey);
      },
    }
  );

  const markNotificationsAsRead = (ids: number[]): void => {
    if (notifications) {
      const newNotifications = [...notifications];
      ids.forEach((id: number) => {
        const [_currentNotification, index] = getCachedNotificationById(id);
        markNotificationMutation.mutate(id);
        newNotifications[index].read_at = new Date().toISOString();
      });
      queryClient.setQueryData<Notification[]>(
        notificationsQueryKey,
        newNotifications
      );
    }
  };

  return {
    notifications,
    notificationsQueryKey,
    notificationsIsLoading,
    getCachedNotificationById,
    deleteNotificationMutation,
    markNotificationMutation,
    removeCachedNotification,
    upsertCachedNotification,
    markNotificationsAsRead,
  };
};
