/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-useless-computed-key */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, useEffect, MutableRefObject } from "react";
import axios, { AxiosError } from "axios";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
  Link,
} from "@mui/material";
import {
  UploadTaskState,
  UploadTaskType,
  UploadTask,
  progressBarDebounceInterval,
  maxConcurrentUploads,
  getDoctype,
  getTabletype,
} from "utils/upload.helpers";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BrowserNotSupportedOutlinedIcon from "@mui/icons-material/BrowserNotSupportedOutlined";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import { DocumentCollection } from "models/api/response.types";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import documentCollectionService, {
  useDocumentCollections,
} from "api/documentCollectionService";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import handleAxiosError from "utils/handleAxiosAlert";
import { useQueryClient } from "@tanstack/react-query";
import {
  useOrganizationUsage,
  useOrganizations,
} from "api/organizationService";
import formatBytes from "utils/formatBytes";
import UsageBar from "components/helpers/UsageBar";
import { useDocuments } from "api/documentService";
import CollectionSelector from "components/helpers/CollectionSelector";
import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";
import UploadCollectionsLimitExceededDialog from "components/Dialogs/UploadCollectionsLimitExceededDialog";
import { setSelectedCollectionId } from "store/features/browser/slice";
import routePaths from "routes/routePaths";
import { useNavigate } from "react-router";
import { defaultCollectionsTypes } from "models/components/Browse.models";
import { usePrompt } from "utils/routerPromptHook";
import UploadManagerDialogPrompt from "components/Dialogs/UploadManagerDialogPrompt";
import { isCollectionLimitExceeded } from "utils/plans";
import { APP_TITLE } from "company-config";

interface FileToLoad extends File {
  path: string;
  collId?: number;
}

interface CollReady {
  id: number;
  ready: boolean;
}

interface DropZone {
  dropzoneactivated?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DropZone = styled(Box)<DropZone>(({ theme, dropzoneactivated }) => ({
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1rem",
  display: "flex",
  transition: "all 0.3s",
  border: `3px dashed ${theme.palette.primary.main}`,
  ...(dropzoneactivated && {
    background: `${theme.palette.primary.main}10`,
  }),
  "& .drop-zone-container": {
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "4px",
    "& .drop-container": {
      borderRadius: "4px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      padding: "1rem 2rem",
      transition: "all 0.3s",
      ...(dropzoneactivated && {
        background: theme.palette.primary.main,
        color: theme.background.light,
      }),
    },
    "& .choose-container": {
      transition: "all 0.3s",
      marginTop: "0.5rem",
      ...(dropzoneactivated && {
        opacity: 0,
      }),
    },
    "& .drop-zone-icon": {
      transition: "all 0.3s",
      ...(!dropzoneactivated && {
        color: theme.palette.primary.main,
      }),
      width: "100px",
      height: "100px",
    },
  },
}));

interface WrapperProps {
  filesexist?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Wrapper = styled(Box)<WrapperProps>(({ theme, filesexist }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "500px",
  ...(!filesexist && {
    background: theme.background.light,
  }),
  ...(filesexist && {
    padding: "1rem 2rem",
  }),
  "& .choose-container": {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    marginBottom: "1rem",
  },
  "& .upload-table": {
    maxHeight: "400px",
    background: theme.background.light,
    border: `1px solid ${theme.grey.light}`,
    "& td .status-icon": {
      width: "14px !important",
      height: "14px !important",
    },
    "& tr:hover .delete-icon": {
      opacity: 1,
    },
    "& .delete-icon": {
      opacity: 0,
    },
    marginTop: "1rem",
  },
  "& .collection-container": {
    display: "flex",
    margin: "1rem auto 0",
    alignItems: "center",
    gap: "0.5rem",
    width: "70%",
  },
  "& .usage": {
    margin: "1rem 0",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  "& .action-button": {
    width: "250px",
    "+ .action-button": {
      marginLeft: "1.5rem",
    },
  },
  "& .action-button-container": {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  },
}));

const TableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>File name</TableCell>
        <TableCell align="left">Size</TableCell>
        <TableCell align="left">Status</TableCell>
      </TableRow>
    </TableHead>
  );
};

const UploadManager: React.FC<{
  isTable?: boolean;
}> = ({ isTable }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { organizationUsage, usageIsLoading, usageQueryKey } =
    useOrganizationUsage(currentOrganizationId);
  const { documentsQueryKey } = useDocuments(currentOrganizationId);
  const { getCachedOrganizationById } = useOrganizations(user?.id);
  const { logAction } = useTelemetry();
  const {
    collections,
    defaultCollections,
    getCachedCollectionById,
    updateCollectionMutation,
    collectionsQueryKey,
  } = useDocumentCollections(currentOrganizationId);
  const [currentOrganization] = getCachedOrganizationById(
    currentOrganizationId || 0
  );
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const totalUploadSize = filesToUpload
    .map((fileData) => fileData.size)
    .reduce((a, b) => a + b, 0);
  const [generetingCollections, setGeneretingCollections] = useState(false);
  const [uploadStarted, setUploadStarted] = useState<boolean>(false);
  const [localSelectedCollectionId, setLocalSelectedCollectionId] = useState<
    number | undefined
  >(undefined);
  // prevent users from upload the same files
  const [uploadMore, setUploadMore] = useState<boolean>(false);
  const [activateDropZone, setActivateDropZone] = useState<boolean>(false);
  const [
    showCollectionLimitExceededDialog,
    setShowCollectionLimitExceededDialog,
  ] = useState<boolean>(false);
  const [documentRowData, setDocumentRowData] = useState<any[]>([]);
  const [resolvedDocuments, setResolvedDocuments] = useState<CollReady[]>([]);
  const [uploadCollection, setUploadCollection] = useState<
    DocumentCollection | undefined
  >(undefined);
  const uploads = useRef<UploadTask[]>([]) as MutableRefObject<UploadTask[]>;
  const lastRender = useRef<number>(performance.now());
  const folderInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const limitExceeded = (files: File[]) => {
    let currentBytes = 0;
    files.forEach((fileData: File) => {
      currentBytes += fileData.size;
    });
    if (organizationUsage) {
      if (
        organizationUsage.usage_limits.storage_bytes -
          organizationUsage.usage.storage.total_bytes -
          currentBytes <
        0
      ) {
        return true;
      }
      return false;
    }
    return false;
  };
  const showUploadPrompt =
    filesToUpload.length > 0 &&
    resolvedDocuments.length !== filesToUpload.length &&
    !uploadMore;
  const { showPrompt, confirmNavigation, cancelNavigation } =
    usePrompt(showUploadPrompt);

  // set uploadCollection when selectedCollection is changed
  useEffect(() => {
    if (!uploadStarted && !generetingCollections && collections) {
      if (localSelectedCollectionId) {
        const [collection] = getCachedCollectionById(localSelectedCollectionId);
        if (collection && !defaultCollectionsTypes.includes(collection?.name)) {
          setUploadCollection(collection);
        } else {
          setUploadCollection(undefined);
        }
      } else {
        setUploadCollection(undefined);
      }
    }
  }, [localSelectedCollectionId, collections]);

  useEffect(() => {
    if (
      resolvedDocuments.length > 0 &&
      resolvedDocuments.length === uploads.current.length
    ) {
      const readyDocs = resolvedDocuments.filter((doc) => doc.ready);
      // if first upload doc has collId
      // means that we have folder upload logic
      // need to point docs to specific collections
      // otherwise proceed with selected collection
      if ((uploads.current[0].file as FileToLoad)?.collId) {
        const collectionDocs: { [key: number]: number[] } = {};
        uploads.current.forEach((upload: UploadTask) => {
          const { documentId } = upload;
          const { collId } = upload.file as FileToLoad;
          if (documentId && collId) {
            if (collectionDocs[collId]) {
              collectionDocs[collId] = [...collectionDocs[collId], documentId];
            } else {
              collectionDocs[collId] = [documentId];
            }
          }
        });
        const collsToUpdate = Object.entries(collectionDocs).map(
          ([collKey, docs]) => {
            return {
              id: parseInt(collKey, 10),
              document_ids: docs,
            };
          }
        );
        updateCollectionMutation.mutate({
          collections: collsToUpdate,
        });
      } else if (uploadCollection) {
        const readyDocIds = readyDocs.map((doc) => doc.id);
        if (readyDocIds.length > 0) {
          updateCollectionMutation.mutate({
            collections: [
              {
                id: uploadCollection.id,
                document_ids: [
                  ...uploadCollection.document_ids,
                  ...readyDocIds,
                ],
              },
            ],
          });
        }
      }
      setUploadStarted(false);
      queryClient.invalidateQueries(usageQueryKey);
      queryClient.invalidateQueries(documentsQueryKey);
      setResolvedDocuments([]);
      setUploadMore(true);
    }
  }, [resolvedDocuments, uploadCollection]);

  // Update row data from uploads ref
  const updateRowData = (force = false) => {
    if (
      performance.now() - lastRender.current > progressBarDebounceInterval ||
      force
    ) {
      setDocumentRowData(
        uploads.current.map((upload) => ({
          ...upload,
          filename: upload.file.name,
        }))
      );
      lastRender.current = performance.now();
    }
  };

  // monitor uploads to only send new requests when below maxConcurrentUploads
  const updateRequests = (forceUpdate = false) => {
    let queued = 0;
    let pending = 0;
    uploads.current.forEach((upload) => {
      queued += upload.state === UploadTaskState.queued ? 1 : 0;
      pending +=
        upload.state === UploadTaskState.pending ||
        (upload.xhr?.readyState === 1 && upload.sent)
          ? 1
          : 0;
    });
    // send pending requests if below maxConcurrentUploads
    if (pending < maxConcurrentUploads) {
      for (let i = 0; i < uploads.current.length; i += 1) {
        const upload = uploads.current[i];
        if (!upload.sent && upload.xhr?.readyState === 1) {
          upload.xhr.send(upload.formData);
          upload.sent = true;
          break;
        }
      }
    }
    // keep updating requests until all requests are resolved
    if (queued + pending > 0) {
      window.setTimeout(() => {
        updateRequests();
      }, progressBarDebounceInterval);
    }
    updateRowData(forceUpdate);
  };

  // Upload multiple files from FileList (from drag/drop API or file input)
  // documentIds (optional) is used to specify which documents to update (instead of create)
  const uploadFiles = (files: File[]) => {
    if (currentOrganizationId) {
      setResolvedDocuments([]);
      uploads.current = [];
      for (let i = 0; i < files.length; i += 1) {
        const upload = new UploadTask(files[i], currentOrganizationId);
        upload.documentId = 0;
        upload.type = UploadTaskType.create;
        uploads.current.push(upload);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        uploadFile(i);
      }
    } else {
      setFilesToUpload([]);
      setUploadStarted(false);
    }
  };

  // Makes a POST request using multipart/form-data to
  // /api/document/create or /api/document/{documentId}/update
  // depending on the value of documentId (0 if creating new document)
  const uploadFile = (index: number) => {
    const upload = uploads.current[index];
    const { file } = upload;
    // construct multipart/form-data
    const formData = new FormData();
    formData.append("file", file);
    // if creating new document
    if (upload.type === UploadTaskType.create) {
      formData.append("organization_id", `${currentOrganizationId}`);
      formData.append("meta_json", "{}");
      formData.append("ui_json", '{"read_by":[]}');
    }
    const doctype = isTable ? getTabletype(file) : getDoctype(file);
    if (doctype !== "") {
      formData.append("doctype", doctype);
    } else {
      upload.errorMessage = `Unsupported filetype`;
      setResolvedDocuments((docs) => [...docs, { id: -1, ready: false }]);
      upload.state = UploadTaskState.invalid;
      updateRowData();
      return;
    }
    upload.formData = formData;
    // use xhr instead of fetch for onprogress event handler
    upload.xhr = new XMLHttpRequest();
    const url =
      upload.type === UploadTaskType.create
        ? `${axios.defaults.baseURL}/api/document/create`
        : `${axios.defaults.baseURL}/api/document/${upload.documentId}/update`;
    upload.xhr.open("POST", url);
    upload.xhr.setRequestHeader(
      "Authorization",
      axios.defaults.headers.common.Authorization as string
    );
    // update upload onprogress event
    upload.xhr.upload.addEventListener(
      "progress",
      (e: ProgressEvent<XMLHttpRequestEventTarget>) => {
        upload.loaded = e.loaded;
        upload.total = e.total;
        upload.state = UploadTaskState.pending;
        upload.timestamp = performance.now();
        updateRowData();
      },
      false
    );
    // update upload loadend event
    upload.xhr.addEventListener("loadend", () => {
      if (upload.xhr) {
        upload.loaded = upload.total; // ensure progress bars reach 100%
        try {
          upload.response = JSON.parse(upload.xhr.responseText);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err, upload.xhr.responseText);
        }
        if (upload.xhr.status === 200 && upload.response) {
          upload.documentId = upload.response.id;
          upload.state = UploadTaskState.fulfilled;
          upload.timestamp = performance.now();
          setResolvedDocuments((docs) => [
            ...docs,
            { id: upload.documentId as number, ready: true },
          ]);
          if (user && currentOrganizationId) {
            logAction(telemetryAction.upload_files, {
              success: true,
              filename: upload.file.name,
              organization_id: currentOrganizationId,
              document_id: upload.documentId,
              size: upload.file.size,
            });
          }
        } else if (upload.xhr.status === 409 && upload.response) {
          // assuming doc is duplicated , need to extract docId from error message
          let error = upload.response?.error;
          if (error) {
            error = error.replace(/[^0-9]/g, "");
            upload.documentId = parseInt(error, 10);
          }
          upload.state = UploadTaskState.duplicate;
          upload.timestamp = performance.now();
          if (upload.documentId) {
            setResolvedDocuments((docs) => [
              ...docs,
              { id: upload.documentId as number, ready: true },
            ]);
            if (user && currentOrganizationId) {
              logAction(telemetryAction.upload_files, {
                success: true,
                filename: upload.file.name,
                organization_id: currentOrganizationId,
                document_id: upload.documentId,
                size: upload.file.size,
              });
            }
          }
        } else {
          upload.state = UploadTaskState.rejected;
          upload.errorMessage = upload.xhr.responseText;
          upload.timestamp = performance.now();
          setResolvedDocuments((docs) => [...docs, { id: -1, ready: false }]);
          if (user && currentOrganizationId) {
            logAction(telemetryAction.upload_files, {
              success: false,
              filename: upload.file.name,
              organization_id: currentOrganizationId,
              size: upload.file.size,
            });
          }
        }
        updateRequests(true);
        updateRowData(true);
      }
    });
    upload.state = UploadTaskState.queued;
    updateRequests(true);
  };

  const collectionsCreate = async (
    colls: string[],
    filesToLoad: FileToLoad[]
  ) => {
    // need to split each path to get parents later
    // in this case last index is collection to create
    // last index - 1 parent
    const childParentColls: string[][] = [];
    const filePaths: string[][] = [];

    colls.forEach((coll) => childParentColls.push(coll.split("/")));
    filesToLoad.forEach((file) => {
      const fullPath = file.path.split("/");
      filePaths.push(fullPath.slice(0, fullPath.length - 1));
    });

    // before creating need to check for duplicates
    // on the same lvl
    let collectionsToCheck = defaultCollections ? [...defaultCollections] : [];
    const customCollections = uploadCollection
      ? collections?.filter((coll) => coll.parent_id === uploadCollection.id)
      : collections?.filter((coll) => coll.parent_id === 0);
    collectionsToCheck = [...collectionsToCheck, ...(customCollections || [])];
    const isCollectionsExceeded = isCollectionLimitExceeded(
      currentOrganization?.meta?.plans || [],
      customCollections?.length || 0
    );

    if (isCollectionsExceeded) {
      setShowCollectionLimitExceededDialog(true);
      setUploadStarted(false);
      setGeneretingCollections(false);
      return;
    }

    // if colletionsToCheck exists means we need to check for duplications
    // check duplications only on top lvl, OS covers all other cases
    // convert duplicates
    if (collectionsToCheck && collectionsToCheck.length > 0) {
      const treesToUpdate: number[] = [];
      childParentColls.forEach((tree: string[], index) => {
        if (
          collectionsToCheck.some(
            (coll: DocumentCollection) => coll.name === tree[1]
          ) ||
          defaultCollectionsTypes.includes(tree[1])
        ) {
          treesToUpdate.push(index);
        }
      });

      const randomNumber = Math.floor(Math.random() * 898) + 101;
      treesToUpdate.forEach((i: number) => {
        // find the same path for file and update it
        const pathsToChange: number[] = [];
        filePaths.forEach((path: string[], index) => {
          if (JSON.stringify(path) === JSON.stringify(childParentColls[i])) {
            pathsToChange.push(index);
          }
        });
        pathsToChange.forEach((idx: number) => {
          filePaths[idx][1] = `${filePaths[idx][1]}_${randomNumber}`;
        });
        // update collection path
        childParentColls[i][1] = `${childParentColls[i][1]}_${randomNumber}`;
      });
    }

    // procceed forward with creating collections
    const createdCollections = childParentColls.map((names: string[]) => {
      return documentCollectionService
        .createDocumentCollection({
          organization_id: currentOrganizationId || 0,
          parent_id: 0,
          name: names[names.length - 1],
          allow_duplicate: true,
        })
        .then(({ data: collection }) => {
          return collection;
        })
        .catch((err) => {
          return err;
        });
    });

    Promise.all(createdCollections).then((newCollections) => {
      let procceed = true;
      // if any collection failed to load, stop upload processing
      newCollections.forEach((result) => {
        if (result?.name === "AxiosError") {
          procceed = false;
          handleAxiosError(result as AxiosError, dispatch);
          setGeneretingCollections(false);
        }
      });
      if (procceed) {
        // after collection creation we need to connect them
        // base on parent_id
        const connectedResult = newCollections.map(
          (collection: DocumentCollection, index: number) => {
            const parentId = uploadCollection ? uploadCollection.id : 0;
            // if previous string is empty means that parent folder wasn't provided
            // use selected collection or default number as parentId
            if (
              childParentColls[index][childParentColls[index].length - 2] === ""
            ) {
              return documentCollectionService.updateDocumentCollection({
                collections: [
                  {
                    id: collection.id,
                    parent_id: parentId,
                    allow_duplicate: false,
                  },
                ],
              });
            }
            const parentCollectionPath = childParentColls[index].slice(
              0,
              childParentColls[index].length - 1
            );
            const parentIndex = childParentColls.findIndex(
              (path: string[]) =>
                JSON.stringify(path) === JSON.stringify(parentCollectionPath)
            );
            return documentCollectionService.updateDocumentCollection({
              collections: [
                {
                  id: collection.id,
                  parent_id: newCollections[parentIndex].id,
                  allow_duplicate: false,
                },
              ],
            });
          }
        );

        Promise.all(connectedResult).then((finalCollectionResults) => {
          // after collection update
          // procceed with file downloading
          setGeneretingCollections(false);
          // to make sure that our collections exists
          queryClient.invalidateQueries(collectionsQueryKey);
          const updatedColls = finalCollectionResults.map(
            (result) => result.data[0]
          );
          // need to add collId to each document, so we can update them later
          const resolvedFiles = [...filesToLoad];
          filesToLoad.forEach((file, index) => {
            const path = filePaths[index];
            const collectionIndex = childParentColls.findIndex(
              (collpath: string[]) =>
                JSON.stringify(collpath) === JSON.stringify(path)
            );
            resolvedFiles[index].collId = updatedColls[collectionIndex].id;
          });
          uploadFiles([...resolvedFiles].map((file) => file));
        });
      }
    });
  };

  const startUpload = () => {
    setUploadStarted(true);
    // filesToLoad uses only for creating custom collections
    const collsToCreate = new Set<string>([]);
    const filesToLoad: FileToLoad[] = [];
    filesToUpload.forEach((fileData: File) => {
      // check for path prop (comes from ondrop)
      // check for webkitRelativePath (comes from folder input)
      // need to assing path to all files along with status
      // pending status starts the load
      if (
        (fileData as FileToLoad)?.path &&
        (fileData as FileToLoad)?.path.includes("/")
      ) {
        const file = fileData as FileToLoad;
        filesToLoad.push(file);
        const parts = file.path.split("/");
        parts.forEach((_partPath, index) => {
          collsToCreate.add(parts.slice(0, index).join("/"));
        });
      } else {
        const file = fileData as File;
        let filePath = "";
        if (file.webkitRelativePath.length > 0) {
          const path = `/${file.webkitRelativePath}`;
          const divider = path.lastIndexOf("/");
          filePath = path;
          collsToCreate.add(path.slice(0, divider));
        }
        const newFile: FileToLoad = file as unknown as FileToLoad;
        if (!newFile.path) {
          newFile.path = filePath;
        }
        filesToLoad.push(newFile);
      }
    });
    // filter empty string
    const newColls = Array.from(collsToCreate).filter((coll) => coll !== "");
    if (newColls.length > 0) {
      setGeneretingCollections(true);
      collectionsCreate(newColls, filesToLoad);
    } else {
      uploadFiles(filesToUpload);
    }
  };

  // if user with limited collections want to upload files only
  // ignore collections creation and just call upload files
  const proceedWithFileUploadOnly = () => {
    setShowCollectionLimitExceededDialog(false);
    setUploadStarted(true);
    uploadFiles(filesToUpload);
  };

  // trigger upload file logic
  const triggerUploadInputFile = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileInputOnChange = () => {
    if (fileInputRef?.current?.files?.length) {
      const { files } = fileInputRef.current;
      setFilesToUpload(Array.from(files));
      fileInputRef.current.value = "";
    }
  };

  // trigger upload folder logic
  const triggerUploadInputFolder = () => {
    if (folderInputRef && folderInputRef.current) {
      folderInputRef.current.click();
    }
  };

  const folderInputOnChange = () => {
    if (folderInputRef?.current?.files?.length) {
      const { files } = folderInputRef.current;
      setFilesToUpload(Array.from(files));
      folderInputRef.current.value = "";
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    onDrop: (files) => {
      setFilesToUpload(files);
      setActivateDropZone(false);
    },
  });

  const getStatus = (status: string) => {
    switch (status) {
      case "queued":
        return <CircularProgress className="status-icon" color="secondary" />;
      case "pending":
        return <CircularProgress className="status-icon" color="secondary" />;
      case "success":
        return <CheckCircleIcon className="status-icon" color="success" />;
      case "fulfilled":
        return <CheckCircleIcon className="status-icon" color="success" />;
      case "duplicate":
        return (
          <Tooltip enterDelay={500} title="Duplicate file">
            <ContentCopyIcon className="status-icon" color="secondary" />
          </Tooltip>
        );
      case "rejected":
        return (
          <Tooltip enterDelay={500} title="Failed to process">
            <CancelIcon className="status-icon" color="error" />
          </Tooltip>
        );
      case "invalid":
        return (
          <Tooltip enterDelay={500} title="Unsupported file type">
            <BrowserNotSupportedOutlinedIcon
              className="status-icon"
              color="warning"
            />
          </Tooltip>
        );
      default:
        return (
          <Tooltip enterDelay={500} title="Await to upload">
            <HourglassEmptyOutlinedIcon
              className="status-icon"
              color="primary"
            />
          </Tooltip>
        );
    }
  };

  return (
    <Wrapper
      className="wrapper-container"
      filesexist={filesToUpload.length > 0 ? "true" : undefined}
    >
      {filesToUpload.length === 0 ? (
        <DropZone
          {...getRootProps()}
          dropzoneactivated={activateDropZone ? "true" : undefined}
          className="drop-zone"
          onDragEnter={(event: React.DragEvent<HTMLDivElement>) => {
            if (event.dataTransfer?.types) {
              // eslint-disable-next-line no-plusplus
              if (event.dataTransfer.types.includes("Files")) {
                setActivateDropZone(true);
              }
            }
          }}
          onDragLeave={(event: any) => {
            if (
              !event.relatedTarget ||
              (typeof event.relatedTarget.className === "string" &&
                (event.relatedTarget.className.includes("MuiContainer-root") ||
                  event.relatedTarget.className.includes("wrapper-container")))
            ) {
              setActivateDropZone(false);
            }
          }}
        >
          <input {...getInputProps()} />
          <Box className="drop-zone-container">
            <Box className="drop-container">
              <Icon
                className="drop-zone-icon"
                icon="line-md:cloud-upload-loop"
              />
              <Typography
                className="drop-zone-text"
                fontWeight={400}
                variant="h6"
              >
                {isTable
                  ? " Drag and drop .csv, .xlsx, .xls files to upload"
                  : " Drag and drop files to upload"}
              </Typography>
            </Box>
            <Box className="choose-container">
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<DescriptionOutlinedIcon fontSize="small" />}
                onClick={(e) => {
                  e.stopPropagation();
                  triggerUploadInputFile();
                }}
              >
                Choose file
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<FolderOutlinedIcon fontSize="small" />}
                onClick={(e) => {
                  e.stopPropagation();
                  triggerUploadInputFolder();
                }}
              >
                Choose folder
              </Button>
            </Box>
          </Box>
        </DropZone>
      ) : (
        <>
          {!isTable ? (
            <Alert severity="info">
              AI performance may be degraded for documents with more than
              1,000,000 characters. Please consider splitting them into smaller
              sections if possible.
            </Alert>
          ) : (
            <Alert severity="info">
              If you have a row-header-only table, please{" "}
              <Link
                color="secondary"
                fontWeight={500}
                underline="hover"
                onClick={() => {
                  window.open(
                    "https://support.microsoft.com/en-gb/office/transpose-rotate-data-from-rows-to-columns-or-vice-versa-3419f2e3-beab-4318-aae5-d0f862209744",
                    "_blank"
                  );
                }}
              >
                transpose
              </Link>{" "}
              it before converting it to a CSV and importing it to {APP_TITLE}.
            </Alert>
          )}
          <TableContainer className="upload-table">
            <Table size="small" stickyHeader>
              <TableHeader />
              <TableBody>
                {filesToUpload.map((row, index) => {
                  const labelId = `${row.name}-${index}`;
                  const status = documentRowData[index]
                    ? documentRowData[index].state
                    : "await";

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="left"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          whiteSpace: " nowrap",
                        }}
                      >
                        {formatBytes(row.size)}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                          justifyContent: "space-between",
                        }}
                      >
                        {getStatus(status)}
                        <IconButton
                          className="delete-icon"
                          size="small"
                          onClick={() => {
                            setFilesToUpload(
                              filesToUpload.filter(
                                (_file, idx) => idx !== index
                              )
                            );
                          }}
                        >
                          <DeleteOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {organizationUsage && (
            <Box className="usage">
              <UsageBar
                loading={usageIsLoading}
                label="Storage space"
                usingLabel={`${formatBytes(
                  organizationUsage?.usage.storage.total_bytes
                )}`}
                totalLabel={`${formatBytes(
                  organizationUsage?.usage_limits.storage_bytes
                )}`}
                data={[
                  {
                    label: `Used (${formatBytes(
                      organizationUsage?.usage.storage.total_bytes
                    )})`,
                    percent:
                      (100 * organizationUsage.usage.storage.total_bytes) /
                      organizationUsage.usage_limits.storage_bytes,
                    color: theme.palette.info.main,
                  },
                  {
                    label: `To upload (${formatBytes(totalUploadSize)})`,
                    percent:
                      (100 / organizationUsage.usage_limits.storage_bytes) *
                      totalUploadSize,
                    color: theme.palette.warning.light,
                  },
                  {
                    label: `Availabe (${formatBytes(
                      organizationUsage.usage_limits.storage_bytes -
                        (organizationUsage.usage.storage.total_bytes +
                          totalUploadSize)
                    )})`,
                    percent:
                      ((organizationUsage.usage_limits.storage_bytes -
                        (organizationUsage.usage.storage.total_bytes +
                          totalUploadSize)) /
                        (organizationUsage.usage.storage.total_bytes +
                          totalUploadSize +
                          (organizationUsage.usage_limits.storage_bytes -
                            (organizationUsage.usage.storage.total_bytes +
                              totalUploadSize)))) *
                      100,
                    color: theme.palette.grey[200],
                  },
                ]}
              />
            </Box>
          )}
          {!limitExceeded(filesToUpload) && (
            <Box className="collection-container">
              <SubdirectoryArrowRightIcon color="primary" fontSize="small" />
              <Typography variant="body2" fontWeight={500}>
                To the collection:
              </Typography>
              <CollectionSelector
                includeDefaultCollections
                canCreateCollection
                size="small"
                disabled={uploadStarted}
                currentParentId={localSelectedCollectionId || -1}
                collectionToUse={(id) => {
                  setLocalSelectedCollectionId(id);
                }}
              />
            </Box>
          )}
          {limitExceeded(filesToUpload) && (
            <Alert sx={{ mb: 2 }} severity="error">
              You have reached the storage limit. You can delete some documents
              to upload or upgrade your plan.
            </Alert>
          )}
          <div className="action-button-container">
            {limitExceeded(filesToUpload) ? (
              <Button
                className="action-button"
                color="secondary"
                variant="contained"
                onClick={() => {
                  navigate(routePaths.workspaceBilling);
                }}
              >
                Upgrade
              </Button>
            ) : (
              <>
                <Button
                  className="action-button"
                  color="primary"
                  variant="contained"
                  disabled={uploadStarted}
                  onClick={() => {
                    if (uploadMore) {
                      setFilesToUpload([]);
                      setUploadMore(false);
                      setDocumentRowData([]);
                    } else {
                      startUpload();
                    }
                  }}
                >
                  {uploadMore ? "Upload more files" : "Begin upload files"}
                </Button>
                {uploadMore && (
                  <Button
                    className="action-button"
                    color="primary"
                    variant="contained"
                    disabled={uploadStarted}
                    onClick={() => {
                      dispatch(
                        setSelectedCollectionId(localSelectedCollectionId)
                      );
                      navigate(routePaths.documents);
                    }}
                  >
                    Go to workspace
                  </Button>
                )}
              </>
            )}
          </div>
        </>
      )}
      {/* folder upload */}
      <input
        id="contained-button-folder"
        multiple
        type="file"
        directory=""
        webkitdirectory=""
        style={{ display: "none" }}
        onChange={folderInputOnChange}
        ref={folderInputRef}
      />
      {/* file upload */}
      <input
        accept={
          isTable
            ? ".csv, .xlsx, .xls"
            : ".txt, .html, .pdf, .ppt, .pptx, .doc, .docx, .jpg, .png, .jpeg, .webp"
        }
        id="contained-button-file"
        multiple
        type="file"
        style={{ display: "none" }}
        onChange={fileInputOnChange}
        ref={fileInputRef}
      />
      {showCollectionLimitExceededDialog && (
        <UploadCollectionsLimitExceededDialog
          setOpen={() => {
            setShowCollectionLimitExceededDialog(false);
          }}
          proceedWithFileUploadOnly={proceedWithFileUploadOnly}
        />
      )}
      {showPrompt && (
        <UploadManagerDialogPrompt
          uploads={uploads.current}
          onCancel={cancelNavigation}
          onConfirm={confirmNavigation}
        />
      )}
    </Wrapper>
  );
};

export default UploadManager;
