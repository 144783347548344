import React from "react";
import { CircularProgress, styled, Typography } from "@mui/material";
import clsx from "clsx";

const Wrapper = styled("div")(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backdropFilter: "blur(16px)",
  "& .message": {
    marginTop: "0.5rem",
  },
  "& .icon": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "2rem",
  },
  "& .compact": {
    marginTop: 0,
  },
}));

interface LoadingOverlayProps {
  compact?: boolean;
  message?: string;
}

const component: React.FC<LoadingOverlayProps> = ({
  compact = false,
  message,
}) => {
  return (
    <Wrapper>
      <div className={clsx({ icon: true, compact })}>
        <CircularProgress />
        {message && (
          <Typography variant="body2" color="textSecondary" fontStyle="italic">
            {message}
          </Typography>
        )}
      </div>
    </Wrapper>
  );
};

export default component;
