/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from "react";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AICreateProject as AICreateProjectType } from "models/api/response.types";
import { format } from "date-fns";
import { selectUser } from "store/features/session/slice";
import { useSelector } from "react-redux";
import { projectColors, projectIcons } from "./AICreateProjectDashboard";

interface ProjProps {
  projectcolor: string;
}

const AICreateProjectWrapperCard = styled(Box)<ProjProps>(
  ({ theme, projectcolor }) => ({
    height: "160px",
    background: theme.background.light,
    borderRadius: "4px",
    padding: "0.5rem",
    border: `1px solid ${theme.grey.light}`,
    transition: "all 0.3s",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    "& .recent-project-header": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
      paddingBottom: "0.5rem",
      "& svg": {
        fill: projectcolor,
        width: "18px",
        height: "18px",
      },
      "& .opened": {
        fontSize: "12px",
      },
    },
    "& .project-actions": {
      flex: 1,
      display: "flex",
      justifyContent: "end",
      alignItems: "end",
      gap: "0.5rem",
    },
    "&:hover": {
      borderColor: projectcolor,
    },
    "& p": {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflowWrap: "anywhere",
      overflow: "hidden",
      "&:not(.summary)": {
        WebkitLineClamp: "1",
      },
      "&.summary": {
        WebkitLineClamp: "2",
      },
    },
  })
);

const AICreateProjectWrapperList = styled(Box)<ProjProps>(
  ({ theme, projectcolor }) => ({
    width: "100%",
    height: "50px",
    background: theme.background.light,
    borderRadius: "4px",
    padding: "0.5rem",
    border: `1px solid ${theme.grey.light}`,
    transition: "all 0.3s",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    "&:hover": {
      borderColor: projectcolor,
    },
    "& .modified_at": {
      marginLeft: "auto",
      whiteSpace: "nowrap",
      fontSize: "12px",
    },
    "& .icon": {
      fill: projectcolor,
      width: "18px",
      height: "18px",
    },
    "& .title, .summary": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      flex: 1,
    },
    "& .project-actions": {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
    },
  })
);

const AICreateProject: React.FC<{
  project: AICreateProjectType;
  view: "list" | "card";
  setSelectedProjectId: (proj: number) => void;
  setDeleteProjectId: (id: number) => void;
  editProject: (proj: AICreateProjectType) => void;
}> = ({
  project,
  view,
  setDeleteProjectId,
  setSelectedProjectId,
  editProject,
}) => {
  const currentUser = useSelector(selectUser);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

  const menuItems = [
    {
      index: 0,
      icon: (
        <ModeEditOutlineOutlinedIcon className="menu-icon" fontSize="small" />
      ),
      label: "Edit",
      onClick: () => {
        editProject(project);
      },
    },
    {
      index: 1,
      icon: (
        <DeleteOutlineOutlinedIcon className="menu-icon" fontSize="small" />
      ),
      label: "Delete",
      onClick: () => {
        setDeleteProjectId(project.id);
      },
    },
  ];

  return (
    <>
      {view === "card" ? (
        <AICreateProjectWrapperCard
          projectcolor={projectColors[project.project_type]}
          onClick={() => {
            setSelectedProjectId(project.id);
          }}
        >
          <Box className="recent-project-header">
            {projectIcons[project.project_type]}
            <Typography color="textSecondary" className="opened">
              Modified at:{" "}
              {format(new Date(project.modified_at), "yyyy-MM-dd HH:mm:ss")}
            </Typography>
          </Box>
          <Typography
            sx={{ marginTop: "0.5rem" }}
            variant="body2"
            fontWeight={500}
          >
            {project.title || "No provided title"}
          </Typography>
          <Typography
            className="summary"
            sx={{ marginTop: "0.5rem" }}
            variant="body2"
            color="textSecondary"
            fontStyle="italic"
          >
            {project.topic_summary || "No generated summary"}
          </Typography>
          <Box className="project-actions">
            {project.visibility === "shared" && (
              <Tooltip
                placement="top"
                enterDelay={500}
                title="Chat is shared within the workspace"
              >
                <VisibilityIcon
                  className="visibility"
                  color="primary"
                  fontSize="small"
                  sx={{
                    marginBottom: "0.3rem",
                  }}
                />
              </Tooltip>
            )}
            {project.user_id === currentUser?.id && (
              <IconButton
                color="primary"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setMenuAnchorEl(e.currentTarget);
                }}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </AICreateProjectWrapperCard>
      ) : (
        <AICreateProjectWrapperList
          projectcolor={projectColors[project.project_type]}
          onClick={() => {
            setSelectedProjectId(project.id);
          }}
        >
          {projectIcons[project.project_type]}
          <Typography variant="body2" fontWeight={500} className="title">
            {project.title || "No provided title"}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            fontStyle="italic"
            className="summary"
          >
            {project.topic_summary || "No generated summary"}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className="modified_at"
          >
            Modified at:{" "}
            {format(new Date(project.modified_at), "yyyy-MM-dd HH:mm:ss")}
          </Typography>
          <Box className="project-actions">
            {project.visibility === "shared" && (
              <Tooltip
                placement="top"
                enterDelay={500}
                title="Chat is shared within the workspace"
              >
                <VisibilityIcon
                  className="visibility"
                  color="primary"
                  fontSize="small"
                />
              </Tooltip>
            )}
            {project.user_id === currentUser?.id && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setMenuAnchorEl(e.currentTarget);
                }}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </AICreateProjectWrapperList>
      )}
      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          zIndex: 1500,
        }}
      >
        {menuItems.map((option: any) => (
          <MenuItem
            key={option.index}
            onClick={() => {
              option.onClick();
              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>
              <Typography variant="body2">{option.label}</Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AICreateProject;
