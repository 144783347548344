import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { UploadTask } from "utils/upload.helpers";

const UploadManagerDialogPrompt: React.FC<{
  uploads: UploadTask[];
  onConfirm: () => void;
  onCancel: () => void;
}> = ({ uploads, onConfirm, onCancel }) => {
  return (
    <Dialog open scroll="paper" fullWidth maxWidth="sm">
      <DialogTitle>
        {uploads.length > 0 ? "Uploading documents" : "Unsaved changes"}
      </DialogTitle>
      <DialogContent>
        {uploads.length > 0 ? (
          <Typography variant="body1">
            If you leave the page while uploading documents, any unfinished
            uploads will not be saved in the workspace. To make sure all your
            documents are uploaded successfully, please stay on the page until
            the upload process is complete.
          </Typography>
        ) : (
          <Typography variant="body1">
            Are you sure you want to navigate away before confirming upload?
            Doing so will discard the documents you selected, and they will not
            be uploaded to your workspace.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} variant="text" color="primary">
          Discard & Switch
        </Button>
        <Button onClick={onCancel} variant="contained" color="primary">
          Stay & Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadManagerDialogPrompt;
