export const isGuest = (role?: string) => {
  return ["guest", "previousmember", "previousadmin"].includes(role || "");
};

export const isAdmin = (role?: string) => {
  return role === "admin";
};

export const isMember = (role?: string) => {
  return role === "member";
};
