/* eslint-disable import/prefer-default-export */
import { useState } from "react";
import { Tag } from "models/api/response.types";
import { useTags } from "api/tagService";
import { listsAreEqual } from "./util";

interface useReactQueryTagInputProp {
  tagOptions: any[];
  defaultTagIds?: number[];
  organizationId: number;
  saveTagIds: (ids: number[], different: boolean) => void;
  setLoading?: (loading: boolean) => void;
}

export const useReactQueryTagInput = ({
  tagOptions,
  defaultTagIds,
  organizationId,
  saveTagIds,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
useReactQueryTagInputProp): readonly [
  any[],
  (tags: any[]) => void,
  (documentIds?: number[]) => void
] => {
  const { createTagsMutation } = useTags(organizationId);
  const defaultTagValues = defaultTagIds
    ? defaultTagIds.map((id) => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tagOptions.length; i++) {
          if (tagOptions[i].id === id) {
            return tagOptions[i];
          }
        }
        return id;
      })
    : [];
  const [tags, setTags] = useState<any[]>(defaultTagValues);
  const saveSelectedTagIds = (documentIds?: number[]) => {
    const existingTagIds = tags
      .filter((tag) => tag.id !== -1)
      .map((tag) => tag.id);
    const newTags = tags
      .filter((tag) => tag.id === -1)
      .map((tag) => {
        return {
          color: tag.color,
          name: tag.name,
          organization_id: organizationId,
          ...(documentIds &&
            documentIds?.length > 0 && { add_document_ids: documentIds }),
        };
      });
    if (newTags.length > 0) {
      createTagsMutation.mutate(newTags, {
        onSuccess: ({ data }: { data: Tag[] }, _variable) => {
          const tag_ids = existingTagIds.concat(data.map((tag) => tag.id));
          saveTagIds(tag_ids, !listsAreEqual(defaultTagIds, tag_ids));
        },
      });
    } else {
      saveTagIds(existingTagIds, !listsAreEqual(defaultTagIds, existingTagIds));
    }
  };
  return [tags, setTags, saveSelectedTagIds] as const;
};
