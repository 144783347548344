/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { APP_TITLE } from "company-config";
import { AxiosResponse } from "axios";
import { IState } from "store/reducers";
import { ISortOrder } from "utils/documentSort";

export interface Alert {
  severity: "error" | "warning" | "info" | "success";
  autoHideDuration?: number;
  alert: {
    message: string;
    response?: AxiosResponse;
  };
}

export interface GeneralState {
  title: string;
  annotationDialogDefaultColor: string;
  alerts: Alert[];
  tagSort: ISortOrder;
  authEmailHandoff: string;
  preAuthRoute: string | undefined;
}

export const initialState: GeneralState = {
  title: APP_TITLE,
  annotationDialogDefaultColor: "#62B602",
  alerts: [],
  authEmailHandoff: "",
  tagSort: {
    key: "frequency",
    order: "descending",
  },
  preAuthRoute: undefined,
};

// general slice is a slice that used across our app
const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setTitle(state, action: PayloadAction<string>) {
      state.title = `${action.payload} - ${APP_TITLE}`;
    },
    setAnnotationDialogDefaultColor(state, action: PayloadAction<string>) {
      state.annotationDialogDefaultColor = action.payload;
    },
    addAlert(state, action: { type: string; payload: Alert }) {
      state.alerts.push({ ...action.payload });
    },
    clearAlert(state) {
      if (state.alerts.length > 0) state.alerts.shift();
    },
    clearAllAlerts(state) {
      state.alerts = [];
    },
    setTagSort(state, action) {
      state.tagSort = action.payload;
    },
    setAuthEmailHandoff(state, action: PayloadAction<string>) {
      state.authEmailHandoff = action.payload;
    },
    setPreAuthRoute(state, action) {
      state.preAuthRoute = action.payload;
    },
  },
});

export const { reducer } = generalSlice;
export const {
  setTitle,
  setAnnotationDialogDefaultColor,
  addAlert,
  clearAlert,
  clearAllAlerts,
  setTagSort,
  setAuthEmailHandoff,
  setPreAuthRoute,
} = generalSlice.actions;

export const selectTitle = createSelector(
  (state: IState) => state.general.title,
  (item: string) => item
);

export const selectAnnotationDialogDefaultColor = createSelector(
  (state: IState) => state.general.annotationDialogDefaultColor,
  (item: string) => item
);

export const selectAllAlerts = createSelector(
  (state: IState) => state.general.alerts,
  (item: Alert[]) => item
);

export const selectTagSort = createSelector(
  (state: IState) => state.general.tagSort,
  (item) => item
);

export const selectAuthEmailHandoff = createSelector(
  (state: IState) => state.general.authEmailHandoff,
  (item) => item
);

export const selectPreAuthRoute = createSelector(
  (state: IState) => state.general.preAuthRoute,
  (item) => item
);
