export interface MultiDialogType {
  open: boolean;
  type: "document_to_show" | "selected_documents";
}

export const defaultCollectionsTypes = [
  "__favorites",
  "__read_later",
  "Favorites",
  "Read later",
];

export const DragTypes = {
  COLLECTION: "collection",
  TRASH: "trash",
  DOCUMENT: "document",
};
