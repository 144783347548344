import React, { useState } from "react";
import { Box, styled } from "@mui/material";
import {
  AICreateDocumentQAStatusList,
  AICreateQuestionList,
  DocumentReadResponse,
} from "models/api/response.types";
import LoadingOverlay from "components/helpers/LoadingOverlay";
import { terminal_status_names } from "utils/aiHelpers";
import SortDocumentMenu from "components/Browser/Documents/SortDocumentMenu";
import { ISortOrder, sortOptions } from "utils/documentSort";
import { AiCreateExtractKnowledgeOnboarding } from "components/Onboarding/AiCreateOnboarding";
import ExtractedDocQAs from "./ExtractedDocQAs";

const Wrapper = styled(Box)(() => ({
  width: "100%",
  flex: 1,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  "& .knowledge-header": {
    maxWidth: "1500px",
    width: "100%",
    margin: "0 auto",
  },
  "& .main-container": {
    width: "100%",
    flex: 1,
    overflow: "auto",
    "& .content-container": {
      margin: "0 auto",
      width: "100%",
      maxWidth: "1500px",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      paddingRight: "1rem",
    },
  },
}));

const ExtractedKnowledge: React.FC<{
  projectId: number;
  projectDocuments: DocumentReadResponse[];
  questions?: AICreateQuestionList;
  docStatuses?: AICreateDocumentQAStatusList;
}> = ({ projectId, projectDocuments, questions, docStatuses }) => {
  const [documentSort, setDocumentSort] = useState<ISortOrder>({
    key: "date-created",
    order: "descending",
  });

  if (!questions) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <Wrapper>
        <Box className="knowledge-header">
          <SortDocumentMenu
            documentSort={documentSort}
            setSortValue={setDocumentSort}
          />
        </Box>
        <Box className="main-container">
          <Box className="content-container">
            {projectDocuments
              .sort((a, b) =>
                sortOptions[documentSort.key].compare(a, b, documentSort.order)
              )
              .map((doc: DocumentReadResponse) => {
                const currentDocQuestions = questions.filter(
                  (question) => question.document_id === doc.id
                );
                const docStatus = docStatuses?.find(
                  (status) => status.document_id === doc.id
                );
                const isGeneratingQuestion = docStatus
                  ? !terminal_status_names.includes(
                      docStatus.generate_queries_task_status
                    )
                  : false;
                return (
                  <ExtractedDocQAs
                    key={doc.id}
                    projectId={projectId}
                    document={doc}
                    questions={currentDocQuestions}
                    generatingQuestion={isGeneratingQuestion}
                  />
                );
              })}
          </Box>
        </Box>
      </Wrapper>
      <AiCreateExtractKnowledgeOnboarding />
    </>
  );
};

export default ExtractedKnowledge;
