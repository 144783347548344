import axios, {
  AxiosError,
  AxiosPromise,
  AxiosResponse,
  CancelToken as CancelTokenType,
} from "axios";
import {
  Organization,
  OrganizationCreateBody,
  OrganizationCreateResponse,
  OrganizationDetailed,
  OrganizationList,
  OrganizationSchemaList,
  OrganizationUsageResponse,
  UserList,
} from "models/api/response.types";
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import handleAxiosError from "utils/handleAxiosAlert";
import { useCallback, useMemo } from "react";
import { selectUser } from "store/features/session/slice";

const fetchOrganization = (
  id: number,
  options?: { cancelToken?: CancelTokenType }
): Promise<AxiosResponse<OrganizationDetailed>> => {
  return axios.get<OrganizationDetailed>(`/api/organization/${id}`, {
    cancelToken: options?.cancelToken,
  });
};

const createOrganization = (
  data: OrganizationCreateBody
): Promise<AxiosResponse<OrganizationCreateResponse>> => {
  return axios.post<OrganizationCreateResponse>(
    "/api/organization/create",
    data
  );
};

const updateOrganization = (
  id: number,
  payload: { name?: string; schema?: OrganizationSchemaList }
): AxiosPromise<Organization> => {
  return axios.post(`/api/organization/${id}/update`, payload);
};

const fetchUsers = (id: number): Promise<AxiosResponse<UserList>> => {
  return axios.get<UserList>(`/api/organization/${id}/user/list`);
};

const fetchCiteUsage = (
  id: number
): Promise<AxiosResponse<OrganizationUsageResponse>> => {
  return axios.get<OrganizationUsageResponse>(`/api/organization/${id}/usage`);
};

const fetchSettingsUsage = (
  id: number
): Promise<AxiosResponse<OrganizationUsageResponse>> => {
  return axios.get<OrganizationUsageResponse>(
    `/api/payment/subscription_info?organization_id=${id}`
  );
};

const leaveOrganization = (id: number): Promise<AxiosResponse> => {
  return axios.post(`/api/organization/${id}/leave`);
};

const fetchUserOrganizationList = (
  userId: number,
  options?: { cancelToken?: CancelTokenType }
): AxiosPromise<OrganizationList> => {
  return axios.get<OrganizationList>(`/api/user/${userId}/organization/list`, {
    cancelToken: options?.cancelToken,
  });
};

const fetchOrganizationUsageById = (
  id: number,
  initiatedAfter: string
): Promise<AxiosResponse<OrganizationUsageResponse>> => {
  return axios.get<OrganizationUsageResponse>(
    `/api/organization/${id}/usage?initiated_after=${initiatedAfter}`
  );
};

export const organizationService = {
  fetchOrganization,
  fetchUsers,
  fetchCiteUsage,
  fetchSettingsUsage,
  leaveOrganization,
  createOrganization,
  fetchOrganizationUsageById,
  updateOrganization,
  fetchUserOrganizationList,
};

export default organizationService;

// Used to add a cancel property to Promise to appease Typescript
// See https://github.com/tannerlinsley/react-query/issues/1265
interface ExtendedPromise<T> extends Promise<T> {
  cancel?: () => void;
}

interface UseOrganizationResult {
  organizations: OrganizationList | undefined;
  settingsOrganizations: OrganizationList | undefined;
  createOrganizationMutation: UseMutationResult<
    AxiosResponse<OrganizationCreateResponse>,
    unknown,
    OrganizationCreateBody,
    unknown
  >;
  getCachedOrganizationById: (
    id: number
  ) => [item: Organization | undefined, index: number];
  organizationIsLoading: boolean;
  organizationQueryKey: string[];
  updateOrganizationMutation: UseMutationResult<
    AxiosResponse<Organization>,
    unknown,
    {
      id: number;
      payload: {
        name?: string;
        description?: string;
        schema?: OrganizationSchemaList;
      };
    },
    unknown
  >;
}

export const useOrganizations = (
  userId: number | undefined
): UseOrganizationResult => {
  const dispatch = useDispatch();
  const organizationQueryKey = [`organizations/${userId}`];
  const queryClient = useQueryClient();
  const user = useSelector(selectUser);

  const { data: organizations, isLoading: organizationIsLoading } = useQuery<
    unknown,
    unknown,
    OrganizationList,
    any
  >(
    organizationQueryKey,
    (): ExtendedPromise<OrganizationList> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = organizationService.fetchUserOrganizationList(
        userId as number,
        {
          cancelToken: source.token,
        }
      );
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: OrganizationDetailed }) => responseData
      );
    },
    {
      enabled: !!userId && userId !== -1,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch),
    }
  );

  // in settings page user can get orgs with admin rights only
  const settingsOrganizations: Organization[] | undefined = useMemo(() => {
    if (organizations && user) {
      const result: Organization[] = [];
      user.organization_roles.forEach((role, index) => {
        if (role === "admin") {
          const id = user.organization_ids[index];
          const matches = organizations.filter((o) => o.id === id);
          if (matches.length > 0) {
            result.push(matches[0]);
          }
        }
      });
      return result;
    }
    return undefined;
  }, [organizations]);

  const organizationIndexById = useMemo(
    () =>
      organizations?.reduce(
        (lut: { [id: number]: number }, organization, index) => {
          // eslint-disable-next-line no-param-reassign
          lut[organization.id] = index;
          return lut;
        },
        {}
      ),
    [organizations]
  );

  const getCachedOrganizationById: (
    id: number
  ) => [item: Readonly<Organization> | undefined, index: Readonly<number>] =
    useCallback(
      (id: number) =>
        organizations && organizationIndexById && id in organizationIndexById
          ? [
              organizations[organizationIndexById[id]],
              organizationIndexById[id],
            ]
          : [undefined, -1],
      [organizationIndexById]
    );

  const upsertCachedOrganization = (
    newOrg: Organization
  ): Organization | undefined => {
    const [currentOrg, index] = getCachedOrganizationById(newOrg.id);
    if (organizations) {
      const newOrgs = [...organizations];
      if (index > -1) {
        newOrgs.splice(index, 1, newOrg);
      } else {
        newOrgs.unshift(newOrg);
      }
      queryClient.setQueryData<Organization[]>(organizationQueryKey, newOrgs);
    } else {
      queryClient.setQueryData<Organization[]>(organizationQueryKey, [newOrg]);
    }
    return currentOrg;
  };

  const createOrganizationMutation = useMutation(
    (variables: OrganizationCreateBody) =>
      organizationService.createOrganization(variables),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(organizationQueryKey);
      },
    }
  );

  const updateOrganizationMutation = useMutation(
    (variables: {
      id: number;
      payload: {
        name?: string;
        description?: string;
        schema?: OrganizationSchemaList;
      };
    }) =>
      organizationService.updateOrganization(variables.id, variables.payload),
    {
      retry: false,
      onSuccess: (response) => {
        if (response?.data) {
          upsertCachedOrganization(response.data);
        }
      },
    }
  );

  return {
    organizations,
    settingsOrganizations,
    organizationIsLoading,
    organizationQueryKey,
    updateOrganizationMutation,
    createOrganizationMutation,
    getCachedOrganizationById,
  };
};

interface UseOrganizationUsageResult {
  organizationUsage: OrganizationUsageResponse | undefined;
  usageIsLoading: boolean;
  usageQueryKey: string[];
}

export const useOrganizationUsage = (
  orgId?: number
): UseOrganizationUsageResult => {
  const dispatch = useDispatch();
  const usageQueryKey = [`organization_usage/${orgId}`];

  const { data, isLoading: usageIsLoading } = useQuery<
    unknown,
    unknown,
    OrganizationUsageResponse,
    any
  >(
    usageQueryKey,
    (): ExtendedPromise<OrganizationList> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = organizationService.fetchSettingsUsage(orgId || -1);
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: OrganizationDetailed }) => responseData
      );
    },
    {
      enabled: !!orgId,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch),
    }
  );

  // with new stripe updates we need to remove old part from all key strings
  const plans = useMemo(() => {
    if (data) {
      const newPlans = data.plans.map((plan) => {
        const isOldPlan = plan.lookup_key.includes("old");
        return {
          ...plan,
          lookup_key: plan?.lookup_key?.replace(/old/g, "") || undefined,
          next_key: plan?.next_key?.replace(/old/g, "") || undefined,
          is_old: isOldPlan,
        };
      });
      return newPlans;
    }
    return undefined;
  }, [data]);

  // on backend total bytes calculating everything
  // metadata, embeddings , pictures of document pages etc.
  // we need calcaulte total_bytes only base on document bytes and media bytes
  const organizationUsage =
    data && plans
      ? {
          ...data,
          plans,
          usage: {
            ...data.usage,
            storage: {
              ...data.usage.storage,
              total_bytes:
                data.usage.storage.document_bytes +
                data.usage.storage.media_bytes,
            },
          },
        }
      : undefined;
  return {
    organizationUsage,
    usageIsLoading,
    usageQueryKey,
  };
};
