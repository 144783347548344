/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";
import { IState } from "store/reducers";

export interface SettingsState {
  sideBarOpen: boolean;
}

export const initialState: SettingsState = {
  sideBarOpen: !isMobile,
};

// general slice is a slice that used across our app
const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSideBarOpen(state, action) {
      state.sideBarOpen = action.payload;
    },
  },
});

export const { reducer } = settingsSlice;
export const { setSideBarOpen } = settingsSlice.actions;

export const isSidebarOpen = createSelector(
  (state: IState) => state.settings.sideBarOpen,
  (item) => item
);
