import React from "react";
import { useTags } from "api/tagService";
import hexToHSL from "utils/hexToHSL";
import InlineTag from "components/Tags/InlineTagComponent";
import { Typography } from "@mui/material";

interface InlineTagByIdProp {
  id: number;
  style?: React.CSSProperties;
  onClick?: () => void;
  organizationId: number;
  disabled?: boolean;
}

const InlineTagById = ({
  id,
  style,
  onClick,
  organizationId,
  disabled,
}: InlineTagByIdProp): React.ReactElement => {
  const { getCachedTagById } = useTags(organizationId);
  const [tag] = getCachedTagById(id);
  let returnComponent = <></>;

  if (tag) {
    const { l: luminosity } = hexToHSL(tag.color);
    const foreground =
      luminosity < 60 ? "rgba(255,255,255,0.8)" : "rgba(0,0,0,0.8)";
    const backgroundColor = tag.color;
    returnComponent = (
      <>
        <InlineTag
          key={tag.id}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: foreground,
            whiteSpace: "nowrap",
            backgroundColor,
            cursor: onClick && !disabled ? "pointer" : "default",
            ...style,
          }}
          onClick={onClick}
        >
          <Typography variant="body2">{tag.name}</Typography>
        </InlineTag>
      </>
    );
  }
  return returnComponent;
};

export default InlineTagById;
