import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import SouthOutlinedIcon from "@mui/icons-material/SouthOutlined";
import NorthOutlinedIcon from "@mui/icons-material/NorthOutlined";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import { Check } from "@mui/icons-material";
import { sortOptions } from "utils/tagSort";
import { selectTagSort, setTagSort } from "store/features/general/slice";
import clsx from "clsx";

export const Wrapper = styled("div")(({ theme }) => ({
  "& .simpleMenu": {
    "& .MuiMenuItem-root": {
      paddingRight: "2rem",
      paddingLeft: "1rem",
    },
  },
  "& .sortButton.selected": {
    background: theme.menu.selected,
  },
}));

const SortTagMenu: React.FC = () => {
  const dispatch = useDispatch();
  const tagSort = useSelector(selectTagSort);
  const [sortDialog, setSortDialog] = useState<Element | null>(null);
  const handleClickSortDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSortDialog(event.currentTarget);
  };

  const handleCloseSortDialog = () => {
    setSortDialog(null);
  };

  const setSortOrderKey = (sortKey: string) => {
    if (tagSort.key !== sortKey) {
      dispatch(setTagSort({ key: sortKey, order: tagSort.order }));
    }
    handleCloseSortDialog();
  };

  const setSortOrder = (sortOrder: "ascending" | "descending") => {
    if (tagSort.order !== sortOrder) {
      dispatch(setTagSort({ key: tagSort.key, order: sortOrder }));
    }
    handleCloseSortDialog();
  };

  return (
    <Wrapper>
      <Button
        variant="text"
        color="primary"
        disableElevation
        onClick={handleClickSortDialog}
        startIcon={<SortOutlinedIcon fontSize="small" />}
        className={clsx("sortButton", {
          selected: sortDialog,
        })}
        endIcon={
          tagSort.order === "ascending" ? (
            <NorthOutlinedIcon fontSize="small" />
          ) : (
            <SouthOutlinedIcon fontSize="small" />
          )
        }
      >
        <Typography sx={{ whiteSpace: "nowrap" }} variant="body2">
          {sortOptions[tagSort.key].label}
        </Typography>
      </Button>
      <Menu
        id="simple-menu"
        className="simpleMenu"
        anchorEl={sortDialog}
        keepMounted
        open={Boolean(sortDialog)}
        onClose={handleCloseSortDialog}
      >
        <MenuItem onClick={() => setSortOrderKey("frequency")}>
          {tagSort.key === "frequency" && (
            <ListItemIcon>
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={tagSort.key !== "frequency"}
            primary={<Typography variant="body2">Frequency</Typography>}
          />
        </MenuItem>
        <MenuItem onClick={() => setSortOrderKey("alphabetical")}>
          {tagSort.key === "alphabetical" && (
            <ListItemIcon>
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={tagSort.key !== "alphabetical"}
            primary={<Typography variant="body2">Alphabetical</Typography>}
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setSortOrder("ascending")}>
          {tagSort.order === "ascending" && (
            <ListItemIcon>
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={tagSort.order !== "ascending"}
            primary={<Typography variant="body2">Ascending</Typography>}
          />
        </MenuItem>
        <MenuItem onClick={() => setSortOrder("descending")}>
          {tagSort.order === "descending" && (
            <ListItemIcon>
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={tagSort.order !== "descending"}
            primary={<Typography variant="body2">Descending</Typography>}
          />
        </MenuItem>
      </Menu>
    </Wrapper>
  );
};

export default SortTagMenu;
