import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import referralService from "api/referralService";
import { useDispatch } from "react-redux";
import { addAlert } from "store/features/general/slice";
import { LoadingButton } from "@mui/lab";

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: "#272727",
    position: "relative",
    fontSize: "14px",
    transform: "none",
    marginBottom: "8px",
  },
  "& #friend": {
    background: theme.background.light,
    padding: "11px 14px",
  },
  "& .description": {
    margin: "1rem 0 0.5rem 0",
  },
  "& .learn-more": {
    display: "inline-block",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const ReferFriendDialog: React.FC<{
  setOpen: (open: boolean) => void;
}> = ({ setOpen }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const createReferral = () => {
    setLoading(true);
    referralService
      .createReferral({ email })
      .then(() => {
        dispatch(
          addAlert({
            severity: "success",
            autoHideDuration: 5000,
            alert: {
              message: "Referral link is successfully sent.",
            },
          })
        );
        setEmail("");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.error) {
          setEmailError(err?.response?.data?.error as string);
        } else {
          dispatch(
            addAlert({
              severity: "error",
              autoHideDuration: 5000,
              alert: {
                message: "Something went wrong, please try again.",
              },
            })
          );
        }
      });
  };

  return (
    <DialogWrapper open onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>
        Refer a friend
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="friend">
            Please enter the referee&apos;s email
          </InputLabel>
          <TextField
            id="friend"
            color="primary"
            variant="outlined"
            value={email}
            type="email"
            size="medium"
            autoFocus
            error={!!emailError}
            helperText={emailError}
            onChange={(e) => {
              setEmailError("");
              setEmail(e.target.value);
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === "Enter" && email.length > 0 && !loading) {
                event.preventDefault();
                createReferral();
              }
            }}
          />
        </FormControl>
        <Typography
          className="description"
          variant="body2"
          color="textSecondary"
        >
          Both you and the referee will receive a 200 message credits bonus upon
          a successful referral.
        </Typography>
        <Tooltip
          placement="top"
          enterDelay={10}
          title={
            <>
              <p>
                To qualify a successful referral, the new user has to complete
                the following steps within 2 weeks to receive the bonus:
              </p>
              <ul>
                <li>Sign up with the link found in the email</li>
                <li>Authenticate and create an account</li>
                <li>Upload 3 documents to any one workspace</li>
              </ul>
              <p>Both users get a bonus of 200 message credits</p>
              <p>
                If the new user upgrades the plan, you both receive an extra 400
                message credits each.
              </p>
            </>
          }
        >
          <Typography className="learn-more" variant="body2" color="secondary">
            Learn more
          </Typography>
        </Tooltip>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={createReferral}
          color="primary"
          size="medium"
          variant="contained"
          loading={loading}
          disabled={email.length === 0 || loading}
        >
          Send referral
        </LoadingButton>
      </DialogActions>
    </DialogWrapper>
  );
};

export default ReferFriendDialog;
