import { useCallback, useMemo } from "react";
import axios, { AxiosError, AxiosPromise, AxiosResponse } from "axios";
import {
  DocumentCollection,
  DocumentCollectionCreateBody,
  DocumentCollectionDeleteBody,
  DocumentCollectionUpdate,
  DocumentCollectionUpdateBody,
  SuccessResponse,
} from "models/api/response.types";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import handleAxiosError from "utils/handleAxiosAlert";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";
import { defaultCollectionsTypes } from "models/components/Browse.models";

const createDocumentCollection = (
  documentCollection: DocumentCollectionCreateBody
): AxiosPromise<DocumentCollection> => {
  return axios.post("/api/collection/create", documentCollection);
};

const fetchDocumentCollections = (
  organizationId: number
): AxiosPromise<DocumentCollection[]> => {
  return axios.get(`/api/organization/${organizationId}/collection/list`);
};

const updateDocumentCollection = (
  payload: DocumentCollectionUpdateBody
): AxiosPromise<DocumentCollection[]> => {
  return axios.post(`/api/collection/update`, payload);
};

const deleteDocumentCollection = (
  payload: DocumentCollectionDeleteBody
): AxiosPromise<SuccessResponse> => {
  return axios.post(`/api/collection/delete`, payload);
};

interface useDocumentCollectionsResult {
  collections: DocumentCollection[] | undefined;
  collectionsQueryKey: string[];
  collectionsIsLoading: boolean;
  collectionsIsFetching: boolean;
  getCachedCollectionById: (
    id: number
  ) => [item: DocumentCollection | undefined, index: number];
  getCachedCollectionByName: (
    name: string
  ) => [item: DocumentCollection | undefined, index: number];
  createCollectionMutation: UseMutationResult<
    AxiosResponse<DocumentCollection>,
    unknown,
    DocumentCollectionCreateBody,
    unknown
  >;
  updateCollectionMutation: UseMutationResult<
    AxiosResponse<DocumentCollection[]>,
    unknown,
    Readonly<DocumentCollectionUpdateBody>,
    unknown
  >;
  removeCollectionMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    DocumentCollectionDeleteBody,
    unknown
  >;
  removeCachedCollection: (id: number) => DocumentCollection | undefined;
  upsertCachedCollections: (
    collections: DocumentCollection[]
  ) => DocumentCollection[] | undefined;
  defaultCollections: DocumentCollection[] | undefined;
  customCollections: DocumentCollection[] | undefined;
}

const documentCollectionService = {
  createDocumentCollection,
  fetchDocumentCollections,
  updateDocumentCollection,
  deleteDocumentCollection,
};

export default documentCollectionService;

interface ExtendedPromise<T> extends Promise<T> {
  cancel?: () => void;
}

export const useDocumentCollections = (
  organizationId: number | undefined
): useDocumentCollectionsResult => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const collectionsQueryKey = [`organization/${organizationId}/collections`];
  const queryClient = useQueryClient();

  const {
    data: collectionData,
    isLoading: collectionsIsLoading,
    isFetching: collectionsIsFetching,
  } = useQuery<unknown, unknown, any[], any>(
    collectionsQueryKey,
    (): ExtendedPromise<DocumentCollection[]> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = documentCollectionService.fetchDocumentCollections(
        organizationId as number
      );
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: DocumentCollection[] }) => responseData
      );
    },
    {
      enabled: !!organizationId,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch, { user }),
    }
  );

  const collections = useMemo(() => {
    return collectionData?.map((item) => {
      if (item.name === "__favorites") {
        return {
          ...item,
          name: "Favorites",
        };
      }
      if (item.name === "__read_later") {
        return {
          ...item,
          name: "Read later",
        };
      }
      return item;
    });
  }, [collectionData]);

  const defaultCollections = useMemo(() => {
    return collections?.filter((item) =>
      defaultCollectionsTypes.includes(item.name)
    );
  }, [collections]);

  const customCollections = useMemo(() => {
    return collections?.filter(
      (item) => !defaultCollectionsTypes.includes(item.name)
    );
  }, [collections]);

  const documentCollectionIndexById = useMemo(
    () =>
      collections?.reduce(
        (lut: { [id: number]: number }, collection, index) => {
          // eslint-disable-next-line no-param-reassign
          lut[collection.id] = index;
          return lut;
        },
        {}
      ),
    [collections]
  );

  const documentCollectionIndexByName = useMemo(
    () =>
      collections?.reduce(
        (lut: { [name: string]: number }, collection, index) => {
          // eslint-disable-next-line no-param-reassign
          lut[collection.name] = index;
          return lut;
        },
        {}
      ),
    [collections]
  );

  const getCachedCollectionById: (
    id: number
  ) => [
    item: Readonly<DocumentCollection> | undefined,
    index: Readonly<number>
  ] = useCallback(
    (id: number) =>
      collections &&
      documentCollectionIndexById &&
      id in documentCollectionIndexById
        ? [
            collections[documentCollectionIndexById[id]],
            documentCollectionIndexById[id],
          ]
        : [undefined, -1],
    [documentCollectionIndexById]
  );

  const getCachedCollectionByName: (
    name: string
  ) => [item: DocumentCollection | undefined, index: number] = useCallback(
    (name: string) =>
      collections &&
      documentCollectionIndexByName &&
      name in documentCollectionIndexByName
        ? [
            collections[documentCollectionIndexByName[name]],
            documentCollectionIndexByName[name],
          ]
        : [undefined, -1],
    [documentCollectionIndexByName]
  );

  // single
  const removeCachedCollection = (
    id: number
  ): DocumentCollection | undefined => {
    const [currentCollection, index] = getCachedCollectionById(id);
    if (collections && currentCollection) {
      const newCollections = [...collections];
      newCollections.splice(index, 1);
      queryClient.setQueryData<DocumentCollection[]>(
        collectionsQueryKey,
        newCollections
      );
      return currentCollection;
    }
    return undefined;
  };

  const upsertCachedCollections = (
    newCollectionsList: DocumentCollection[]
  ): DocumentCollection[] | undefined => {
    if (collections) {
      const newCollections = [...collections];
      newCollectionsList.forEach((collection: DocumentCollection) => {
        const [currentCollection, index] = getCachedCollectionById(
          collection.id
        );
        if (index > -1) {
          newCollections.splice(index, 1, collection);
        } else {
          newCollections.unshift(collection);
        }
      });
      queryClient.setQueryData<DocumentCollectionUpdate[]>(
        collectionsQueryKey,
        newCollections
      );
    } else {
      queryClient.invalidateQueries(collectionsQueryKey);
    }
    return newCollectionsList;
  };

  const createCollectionMutation = useMutation(
    (variables: DocumentCollectionCreateBody) =>
      documentCollectionService.createDocumentCollection(variables),
    {
      retry: false,
      onMutate: async (variables: DocumentCollectionCreateBody) => {
        const newDocumentCollection: DocumentCollection = {
          id: -new Date().getTime(),
          parent_id: variables.parent_id,
          organization_id: variables.organization_id,
          document_ids: variables.document_ids || [],
          modified_at: new Date().toISOString(),
          created_at: new Date().toISOString(),
          name: variables.name,
          user_id: user?.id || 0,
          is_shared: true,
        };
        upsertCachedCollections([newDocumentCollection]);
        return { newId: newDocumentCollection.id };
      },
      onSuccess: (response, payload, context) => {
        let newList: DocumentCollection[] = [];
        if (context?.newId) {
          newList = collections
            ? collections.filter(
                (collection: DocumentCollection) =>
                  collection.id !== context.newId
              )
            : [];
        }
        if (response.data) {
          newList = [...newList, response.data];
        }
        queryClient.setQueryData<DocumentCollectionUpdate[]>(
          collectionsQueryKey,
          newList
        );
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(collectionsQueryKey);
      },
    }
  );

  const updateCollectionMutation = useMutation(
    (payload: DocumentCollectionUpdateBody) => {
      return documentCollectionService.updateDocumentCollection(payload);
    },
    {
      retry: false,
      onMutate: async (payload: DocumentCollectionUpdateBody) => {
        // // cancel any pending collection queries
        // get existing collection
        payload.collections.map((collection) => {
          const [currentCollection] = getCachedCollectionById(collection.id);
          if (currentCollection) {
            const newCollection = { ...currentCollection };
            if (collection.name !== undefined)
              newCollection.name = collection.name;
            if (collection.document_ids !== undefined)
              newCollection.document_ids = [...collection.document_ids];
            return {
              previousCollection: upsertCachedCollections([newCollection]),
            };
          }
          return { previousCollection: undefined };
        });
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(collectionsQueryKey);
      },
    }
  );

  const removeCollectionMutation = useMutation(
    (payload: DocumentCollectionDeleteBody) =>
      documentCollectionService.deleteDocumentCollection(payload),
    {
      retry: false,
      onMutate: async (payload: DocumentCollectionDeleteBody) => {
        await queryClient.cancelQueries(collectionsQueryKey);
        if (collections) {
          const newCollections = collections.filter(
            (collection: DocumentCollection) =>
              !payload.collection_ids.includes(collection.id)
          );
          queryClient.setQueryData<DocumentCollectionUpdate[]>(
            collectionsQueryKey,
            newCollections
          );
          return { updatedCollections: newCollections };
        }
        return { updatedCollections: collections };
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(collectionsQueryKey);
      },
    }
  );

  return {
    collections,
    collectionsQueryKey,
    collectionsIsLoading,
    collectionsIsFetching,
    getCachedCollectionById,
    getCachedCollectionByName,
    createCollectionMutation,
    updateCollectionMutation,
    removeCollectionMutation,
    defaultCollections,
    customCollections,
    removeCachedCollection,
    upsertCachedCollections,
  };
};
