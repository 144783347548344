/* eslint-disable dot-notation */
import axios, {
  AxiosError,
  AxiosPromise,
  AxiosResponse,
  CancelToken as CancelTokenType,
} from "axios";
import {
  Annotation,
  AnnotationCreateBody,
  AnnotationUpdateBody,
  AnnotationDetailedList,
  IdResponse,
  CommentList,
  CommentCreateBody,
  Comment,
  AnnotationDetailed,
  CommentUpdateBody,
  SuccessResponse,
  AnnotationListResponse,
} from "models/api/response.types";
import { useCallback, useMemo } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";
import handleAxiosError from "utils/handleAxiosAlert";

const getAnnotation = (id: number): AxiosPromise<Annotation> => {
  return axios.get<Annotation>(`/api/annotation/${id}`);
};

const fetchAnnotationImage = (id: number): AxiosPromise<ArrayBuffer> =>
  axios.get(`/api/annotation/${id}/image`, {
    responseType: "arraybuffer",
  });

const postAnnotation = (
  newAnnotation: AnnotationCreateBody
): AxiosPromise<AnnotationDetailed> => {
  return axios.post<AnnotationDetailed>(
    `/api/annotation/create`,
    newAnnotation
  );
};

const updateAnnotation = (
  annotation: AnnotationUpdateBody,
  id: number
): AxiosPromise<AnnotationDetailed> => {
  return axios.post(`/api/annotation/${id}/update`, annotation);
};

const deleteAnnotation = (id: number): AxiosPromise<IdResponse> => {
  return axios.delete<IdResponse>(`/api/annotation/${id}/delete`);
};

const fetchCommentById = (id: number): AxiosPromise<Comment> => {
  return axios.get(`/api/comment/${id}`);
};

const fetchAnnotations = (
  id: number,
  options?: { cancelToken?: CancelTokenType }
): AxiosPromise<AnnotationDetailedList> => {
  return axios.get(`/api/document/${id}/annotation/list`, {
    cancelToken: options?.cancelToken,
  });
};

const fetchOrgAnnotations = (
  organizationId: number
): AxiosPromise<AnnotationDetailedList> => {
  return axios.get(
    `api/annotation/list?organization_id=${organizationId}&page_size=999`
  );
};

const fetchAnnotationCommentList = (id: number): AxiosPromise<CommentList> => {
  return axios.get(`/api/annotation/${id}/comment/list`);
};

const createCommentToAnnotation = (
  payload: CommentCreateBody
): AxiosPromise<Comment> => {
  return axios.post("/api/comment/create", payload);
};

const updateAnnotationComment = (
  id: number,
  payload: CommentUpdateBody
): AxiosPromise<Comment> => {
  return axios.post(`/api/comment/${id}/update`, payload);
};

const deleteAnnotationComment = (id: number): AxiosPromise<SuccessResponse> => {
  return axios.delete(`/api/comment/${id}/delete`);
};

const annotationService = {
  getAnnotation,
  postAnnotation,
  updateAnnotation,
  deleteAnnotation,
  fetchAnnotations,
  fetchAnnotationCommentList,
  createCommentToAnnotation,
  updateAnnotationComment,
  deleteAnnotationComment,
  fetchAnnotationImage,
  fetchCommentById,
  fetchOrgAnnotations,
};

export default annotationService;

interface CancellablePromise<T> extends Promise<T> {
  cancel?: () => void;
}

export interface UseAnnotationsResult {
  annotations: AnnotationDetailed[] | undefined;
  annotationsQueryKey: string[];
  annotationsIsLoading: boolean;
  annotationsIsFetching: boolean;
  getCachedAnnotationById: (
    id: number
  ) => [item: AnnotationDetailed | undefined, index: number];
  upsertCachedAnnotation: (
    newannotation: AnnotationDetailed
  ) => AnnotationDetailed | undefined;
  removeCachedAnnotation: (id: number) => AnnotationDetailed | undefined;
  updateAnnotationMutation: UseMutationResult<
    AxiosResponse<AnnotationDetailed>,
    unknown,
    {
      annotation: AnnotationUpdateBody;
      id: number;
    },
    {
      previousAnnotation: AnnotationDetailed | undefined;
    }
  >;
  deleteAnnotationMutation: UseMutationResult<
    AxiosResponse<IdResponse>,
    unknown,
    number,
    {
      removedAnnotation: AnnotationDetailed | undefined;
    }
  >;
  createAnnotationMutation: UseMutationResult<
    AxiosResponse<AnnotationDetailed>,
    unknown,
    AnnotationCreateBody,
    unknown
  >;
}

export const useAnnotations = (
  documentId: number | undefined
): UseAnnotationsResult => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();
  const annotationsQueryKey = [`document/${documentId}/annotations`];

  const {
    data: annotations,
    isLoading: annotationsIsLoading,
    isFetching: annotationsIsFetching,
  } = useQuery<unknown, unknown, AnnotationDetailed[], any>(
    annotationsQueryKey,
    (): CancellablePromise<AnnotationDetailed[]> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = annotationService.fetchAnnotations(
        documentId as number,
        {
          cancelToken: source.token,
        }
      );
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: AnnotationDetailed[] }) => responseData
      );
    },
    {
      enabled: !!documentId,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch, { user }),
    }
  );

  const annotationIndexById = useMemo(
    () =>
      annotations?.reduce(
        (lut: { [id: number]: number }, annotation, index) => {
          // eslint-disable-next-line no-param-reassign
          lut[annotation.id] = index;
          return lut;
        },
        {}
      ),
    [annotations]
  );

  const getCachedAnnotationById: (
    id: number
  ) => [item: AnnotationDetailed | undefined, index: number] = useCallback(
    (id: number) =>
      annotations && annotationIndexById && id in annotationIndexById
        ? [annotations[annotationIndexById[id]], annotationIndexById[id]]
        : [undefined, -1],
    [annotationIndexById]
  );

  const removeCachedAnnotation = (
    id: number
  ): AnnotationDetailed | undefined => {
    const [currentAnnotation, index] = getCachedAnnotationById(id);
    if (annotations && currentAnnotation) {
      const newAnnotations = [...annotations];
      newAnnotations.splice(index, 1);
      queryClient.setQueryData<AnnotationDetailed[]>(
        annotationsQueryKey,
        newAnnotations
      );
      return currentAnnotation;
    }
    return undefined;
  };

  const upsertCachedAnnotation = (
    newannotation: AnnotationDetailed
  ): AnnotationDetailed | undefined => {
    const [currentAnnotation, index] = getCachedAnnotationById(
      newannotation.id
    );
    if (annotations) {
      const newAnnotations = [...annotations];
      if (index > -1) {
        newAnnotations.splice(index, 1, newannotation);
      } else {
        newAnnotations.unshift(newannotation);
      }
      queryClient.setQueryData<AnnotationDetailed[]>(
        annotationsQueryKey,
        newAnnotations
      );
    } else {
      queryClient.setQueryData<AnnotationDetailed[]>(annotationsQueryKey, [
        newannotation,
      ]);
    }
    return currentAnnotation;
  };

  const updateAnnotationMutation = useMutation(
    (variables: { annotation: AnnotationUpdateBody; id: number }) =>
      annotationService.updateAnnotation(variables.annotation, variables.id),
    {
      retry: false,
      onMutate: async (variables: {
        annotation: AnnotationUpdateBody;
        id: number;
      }) => {
        await queryClient.cancelQueries(annotationsQueryKey);
        const [currentAnnotation] = getCachedAnnotationById(variables.id);
        return {
          previousAnnotation: currentAnnotation
            ? upsertCachedAnnotation({ ...currentAnnotation, ...variables })
            : undefined,
        };
      },
      onSuccess: (response) => {
        if (response?.data) upsertCachedAnnotation(response.data);
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(annotationsQueryKey);
      },
    }
  );

  const deleteAnnotationMutation = useMutation(
    (annotationId: number) => annotationService.deleteAnnotation(annotationId),
    {
      retry: false,
      onMutate: async (annotationId: number) => {
        await queryClient.cancelQueries(annotationsQueryKey);
        const removedAnnotation = removeCachedAnnotation(annotationId);
        return { removedAnnotation };
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(annotationsQueryKey);
      },
    }
  );

  const createAnnotationMutation = useMutation(
    (variables: AnnotationCreateBody) =>
      annotationService.postAnnotation(variables),
    {
      retry: false,
      onSuccess: (response) => {
        if (response?.data) {
          upsertCachedAnnotation(response.data);
        }
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(annotationsQueryKey);
      },
    }
  );

  return {
    annotations,
    annotationsQueryKey,
    annotationsIsLoading,
    annotationsIsFetching,
    getCachedAnnotationById,
    upsertCachedAnnotation,
    updateAnnotationMutation,
    deleteAnnotationMutation,
    createAnnotationMutation,
    removeCachedAnnotation,
  };
};

interface UseAnnotationCommentsResult {
  annotationCommentsQueryKey: string[];
  annotationComments: CommentList | undefined;
  annotationCommentsIsLoading: boolean;
  getCachedAnnotationCommentById: (
    id: number
  ) => [item: Comment | undefined, index: number];
  updateAnnotationCommentMutation: UseMutationResult<
    AxiosResponse<Comment>,
    unknown,
    {
      id: number;
      annotationComment: CommentUpdateBody;
    },
    {
      previousAnnotationComment: Comment | undefined;
    }
  >;
  deleteAnnotationCommentMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    number,
    {
      removedAnnotationComment: Comment | undefined;
    }
  >;
  createAnnotationCommentMutation: UseMutationResult<
    AxiosResponse<Comment>,
    unknown,
    CommentCreateBody,
    {
      newId: number;
    }
  >;
}

export const useAnnotationComments = (
  annotation: AnnotationDetailed | undefined,
  organizationId: number | undefined
): UseAnnotationCommentsResult => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();
  const annotationCommentsQueryKey = [`annotation/${annotation?.id}/comments`];
  const annotationsQueryKey = [`organization/${organizationId}/annotations`];

  const { data: annotationComments, isLoading: annotationCommentsIsLoading } =
    useQuery<unknown, unknown, CommentList, any>(
      annotationCommentsQueryKey,
      () =>
        annotationService
          .fetchAnnotationCommentList(annotation?.id as number)
          .then(({ data }) => data),
      {
        enabled: !!annotation,
        placeholderData: undefined,
      }
    );

  const getCachedAnnotationCommentById: (
    id: number
  ) => [item: Comment | undefined, index: number] = useCallback(
    (id: number) => {
      if (annotationComments && annotationComments.length > 0) {
        const idx = annotationComments.findIndex(
          (comment) => comment.id === id
        );
        if (idx > -1) {
          return [annotationComments[idx], idx];
        }
        return [undefined, -1];
      }
      return [undefined, -1];
    },
    [annotationComments]
  );

  const removeCachedAnnotationComment = (id: number): Comment | undefined => {
    const [currentAnnotationComment, index] =
      getCachedAnnotationCommentById(id);
    if (annotationComments && currentAnnotationComment) {
      const newAnnotationComments = [...annotationComments];
      newAnnotationComments.splice(index, 1);
      queryClient.setQueryData<Comment[]>(
        annotationCommentsQueryKey,
        newAnnotationComments
      );
      return currentAnnotationComment;
    }
    return undefined;
  };

  const upsertCachedAnnotationComment = (
    newAnnotationComment: Comment
  ): Comment | undefined => {
    const [currentAnnotationComment, index] = getCachedAnnotationCommentById(
      newAnnotationComment.id
    );
    if (annotationComments) {
      const newAnnotationComments = [...annotationComments];
      if (index > -1) {
        newAnnotationComments.splice(index, 1, newAnnotationComment);
      } else {
        newAnnotationComments.unshift(newAnnotationComment);
      }
      queryClient.setQueryData<Comment[]>(
        annotationCommentsQueryKey,
        newAnnotationComments
      );
    } else {
      queryClient.setQueryData<Comment[]>(annotationCommentsQueryKey, [
        newAnnotationComment,
      ]);
    }
    return currentAnnotationComment;
  };

  const updateAnnotationCommentMutation = useMutation(
    (variables: { id: number; annotationComment: CommentUpdateBody }) =>
      annotationService.updateAnnotationComment(
        variables.id,
        variables.annotationComment
      ),
    {
      retry: false,
      onMutate: (variables: {
        id: number;
        annotationComment: CommentUpdateBody;
      }) => {
        const [currentAnnotation] = getCachedAnnotationCommentById(
          variables.id
        );
        return {
          previousAnnotationComment: currentAnnotation
            ? upsertCachedAnnotationComment({
                ...currentAnnotation,
                ...variables.annotationComment,
              })
            : undefined,
        };
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(annotationCommentsQueryKey);
      },
    }
  );

  const deleteAnnotationCommentMutation = useMutation(
    (id: number) => annotationService.deleteAnnotationComment(id),
    {
      retry: false,
      onMutate: (id: number) => {
        const removedAnnotationComment = removeCachedAnnotationComment(id);
        return { removedAnnotationComment };
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(annotationCommentsQueryKey);
      },
    }
  );

  const createAnnotationCommentMutation = useMutation(
    (variables: CommentCreateBody) =>
      annotationService.createCommentToAnnotation(variables),
    {
      retry: false,
      onMutate: async (variables: CommentCreateBody) => {
        const newAnnotationComment: Comment = {
          id: -new Date().getTime(),
          annotation_id: variables.annotation_id,
          user_id: user?.id || 0,
          created_at: new Date().toISOString(),
          modified_at: new Date().toISOString(),
          modified_by: user?.id || 0,
          content: variables.content || "",
          user: { id: user?.id || 0, name: user?.name },
        };
        upsertCachedAnnotationComment(newAnnotationComment);
        return { newId: newAnnotationComment.id };
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(annotationCommentsQueryKey);
      },
      onSuccess: (response, payload, context) => {
        if (context?.newId) {
          removeCachedAnnotationComment(context.newId);
        }
        if (response?.data) {
          const annotations =
            queryClient.getQueryData<AnnotationDetailedList>(
              annotationsQueryKey
            );
          if (annotations) {
            const newAnnotations = [...annotations];
            const annotationToUpdateIndex = newAnnotations.findIndex(
              (ann) => ann.id === response.data.annotation_id
            );
            if (annotationToUpdateIndex > -1) {
              let commentCount =
                newAnnotations[annotationToUpdateIndex].comment_count;
              newAnnotations[annotationToUpdateIndex] = {
                ...newAnnotations[annotationToUpdateIndex],
                comment_count: (commentCount += 1),
              };
            }
            queryClient.setQueryData<AnnotationDetailedList>(
              annotationsQueryKey,
              newAnnotations
            );
          }
          upsertCachedAnnotationComment(response.data);
        }
      },
    }
  );

  return {
    annotationCommentsQueryKey,
    annotationComments,
    annotationCommentsIsLoading,
    getCachedAnnotationCommentById,
    updateAnnotationCommentMutation,
    deleteAnnotationCommentMutation,
    createAnnotationCommentMutation,
  };
};

export interface UseOrgAnnotationsResult {
  orgAnnotations: AnnotationDetailed[] | undefined;
  orgAnnotationsQueryKey: string[];
  orgAnnotationsIsLoading: boolean;
  orgAnnotationsIsFetching: boolean;
}

export const useOrgAnnotations = (orgId: number): UseOrgAnnotationsResult => {
  const dispatch = useDispatch();
  const orgAnnotationsQueryKey = [`organization/${orgId}/annotations`];

  const {
    data: orgAnnotations,
    isLoading: orgAnnotationsIsLoading,
    isFetching: orgAnnotationsIsFetching,
  } = useQuery<unknown, unknown, AnnotationDetailed[], any>(
    orgAnnotationsQueryKey,
    (): CancellablePromise<AnnotationListResponse> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = annotationService.fetchOrgAnnotations(
        orgId as number
      );
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: AnnotationListResponse }) =>
          responseData["_list"]
      );
    },
    {
      enabled: !!orgId,
      placeholderData: undefined,
      onError: (err) =>
        handleAxiosError(err as AxiosError, dispatch, {
          organizationId: orgId,
        }),
    }
  );

  return {
    orgAnnotations,
    orgAnnotationsQueryKey,
    orgAnnotationsIsLoading,
    orgAnnotationsIsFetching,
  };
};
