/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToolbarMode } from "components/DocumentViewer/DocumentViewer-types";
import { IState } from "store/reducers";
import { isMobile } from "react-device-detect";

type ToolBarModeType = "select" | "highlight" | "rectangle" | "pan";

export interface DocumentViewerState {
  toolBarMode: ToolBarModeType;
  focusLookUpInput: boolean;
  isRightSideBarOpen: boolean;
}

export const initialState: DocumentViewerState = {
  toolBarMode: isMobile ? "pan" : "select",
  focusLookUpInput: false,
  isRightSideBarOpen: false,
};

// document viewer slice is a slice that used for states in documentViewer flow
const documentViewerSlice = createSlice({
  name: "documentViewer",
  initialState,
  reducers: {
    setToolBarMode(state, action: PayloadAction<ToolBarModeType>) {
      const currentState = state;
      currentState.toolBarMode = action.payload;
    },
    setFocusLookUpInput(state, action) {
      state.focusLookUpInput = action.payload;
    },
    setRightSideBarOpen(state, action) {
      state.isRightSideBarOpen = action.payload;
    },
  },
});

export const { reducer } = documentViewerSlice;
export const { setToolBarMode, setFocusLookUpInput, setRightSideBarOpen } =
  documentViewerSlice.actions;

export const selectToolBarMode = createSelector(
  (state: IState) => state.documentViewer.toolBarMode,
  (item: ToolbarMode) => item
);

export const selectFocusLookUpInput = createSelector(
  (state: IState) => state.documentViewer.focusLookUpInput,
  (item) => item
);

export const getIsRightSideBarOpen = createSelector(
  (state: IState) => state.documentViewer.isRightSideBarOpen,
  (item) => item
);
