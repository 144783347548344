import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import aiCreateService, { useAICreateProjects } from "api/aiCreateService";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import { addAlert } from "store/features/general/slice";
import { DocumentReadResponse } from "models/api/response.types";
import { useQueryClient } from "@tanstack/react-query";

const AICreateConfirmDocumentDeletionDialog: React.FC<{
  projectId: number;
  currentDocuments: DocumentReadResponse[];
  open: boolean;
  documentsToDelete: number[];
  close: (success?: boolean) => void;
}> = ({ projectId, currentDocuments, open, documentsToDelete, close }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { upsertCachedAICreateProject } = useAICreateProjects(
    currentOrganizationId
  );

  const deleteDocumentsFromProject = () => {
    if (documentsToDelete.length > 0) {
      setLoading(true);
      const updatedDocumentList = currentDocuments.filter(
        (doc) => !documentsToDelete.includes(doc.id)
      );
      aiCreateService
        .updateAICreateProject(projectId, {
          document_ids: updatedDocumentList.map((doc) => doc.id),
        })
        .then(({ data }) => {
          setLoading(false);
          upsertCachedAICreateProject(data);
          close(true);
          // adding documents trigger refetch questions
          setTimeout(() => {
            queryClient.invalidateQueries([
              "ai_create_document_qas_status",
              projectId,
            ]);
          }, 500);
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            addAlert({
              severity: "error",
              autoHideDuration: 5000,
              alert: {
                message: "Something went wrong. Please try again later.",
              },
            })
          );
          close();
        });
    }
  };

  return (
    <Dialog open={open} onClose={() => close()} fullWidth maxWidth="sm">
      <DialogTitle>
        Delete selected documents
        <IconButton onClick={() => close()}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        Are you certain you want to proceed? Deleting selected document(s) will
        reflect further steps.
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          size="medium"
          onClick={() => close()}
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={deleteDocumentsFromProject}
          color="primary"
          size="medium"
          variant="contained"
          loading={loading}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AICreateConfirmDocumentDeletionDialog;
