/* eslint-disable import/prefer-default-export */

export const colorsToCreate = [
  "#EE6A59",
  "#F4B352",
  "#F3D653",
  "#62B602",
  "#29A3F5",
  "#8168DF",
];
