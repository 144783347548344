/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import { QAListResponse, User } from "models/api/response.types";
import * as XLSX from "xlsx";

const getRowData = (
  qaMessages: QAListResponse,
  sessionName?: string,
  users?: User[]
) => {
  const rowData: string[][] = [];
  const excelColumns = ["Timestamp", "Session name", "Role", "Content"];
  rowData.push(excelColumns);
  qaMessages.forEach((qa) => {
    // user row
    const questioner = users?.find((user) => user.id === qa.user_id);
    rowData.push([
      qa.created_at,
      sessionName || "Unknown",
      questioner?.email || "User",
      qa.query,
    ]);
    // assistant row
    rowData.push([
      qa.created_at,
      sessionName || "Unknown",
      "AI Assistant",
      qa.response,
    ]);
  });
  return rowData;
};

export const exportAIChatAsExcel = (
  qaMessages: QAListResponse,
  sessionName?: string,
  users?: User[]
) => {
  const worksheet = XLSX.utils.json_to_sheet(
    getRowData(qaMessages, sessionName, users),
    { skipHeader: true }
  );
  const workbook = XLSX.utils.book_new();
  worksheet["!cols"] = [{ wpx: 150 }];
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${sessionName || "Multi-doc-chat"}.xlsx`);
};
