import React, { useEffect } from "react";
import {
  Box,
  CSSObject,
  Divider,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { matchPath, useNavigate } from "react-router-dom";
import routePaths from "routes/routePaths";
import { useDispatch, useSelector } from "react-redux";
import { isSidebarOpen, setSideBarOpen } from "store/features/settings/slice";
import { companyFeatures } from "company-config";
import ProfileSelector from "components/helpers/ProfileSelector";
import OrganizationSelector from "components/helpers/OrganizationSelector";

const openedMixin = (theme: Theme): CSSObject => ({
  width: "100%",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(["width", "opacity"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  "&.MuiPaper-root": {
    [theme.breakpoints.down("lg")]: {
      width: "0px",
      opacity: 0,
      left: "-100px",
    },
  },
});

const SwitcherDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    position: "relative",
    height: "100%",
    border: "none",
    overflow: "hidden",
    borderRight: `1px solid ${theme.grey.light}`,
  },
  "& .logo": {
    cursor: "pointer",
  },
  ...(open && {
    ...openedMixin(theme),
    width: "300px",
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    width: 0,
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  [theme.breakpoints.down("lg")]: {
    position: "absolute",
    zIndex: 1202,
  },
  "& .top-content": {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    padding: "1rem",
  },
  "& .navigation-menu": {
    padding: "1rem",
    gap: "0.5rem",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "auto !important",
    "& .actions": {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      alignItems: "center",
    },
    "& .platforms": {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  },
  "& .profile-selector-container": {
    width: "100%",
    padding: "1rem",
  },
}));

const ActionButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: "4px",
  width: "100%",
  flexGrow: 0,
  position: "relative",
  "&.Mui-selected": {
    background: theme.grey.light,
  },
  "& .MuiListItemIcon-root": {
    minWidth: "0",
    marginRight: "0.7rem",
  },
}));

const Switcher: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const sideBarOpen = useSelector(isSidebarOpen);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    if (!smScreen && !sideBarOpen) {
      dispatch(setSideBarOpen(true));
    }
  }, [smScreen]);

  const workspaceMenu = [
    {
      text: "Profile",
      icon: <AccountCircleOutlinedIcon fontSize="small" />,
      path: routePaths.profile,
    },
    {
      text: "Workspace",
      icon: <DataUsageIcon fontSize="small" />,
      path: routePaths.workspaceOverview,
    },
    {
      text: "Members and invite",
      icon: <GroupAddOutlinedIcon fontSize="small" />,
      path: routePaths.workspaceMembers,
    },
  ];

  return (
    <SwitcherDrawer variant="permanent" open={sideBarOpen}>
      <Box className="top-content">
        <OrganizationSelector isSettingsPage />
        {smScreen && (
          <IconButton
            className="sidebar-close-icon"
            size="medium"
            onClick={() => dispatch(setSideBarOpen(false))}
            color="primary"
          >
            <ArrowBackIosNewIcon fontSize="small" color="action" />
          </IconButton>
        )}
      </Box>
      <Box className="navigation-menu">
        <Box className="actions">
          {workspaceMenu.map((action) => {
            const isActive = !!matchPath({ path: action.path }, pathName);
            return (
              <ActionButton
                key={action.text}
                selected={isActive}
                onClick={() => {
                  navigate(action.path);
                }}
              >
                <ListItemIcon>{action.icon}</ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color={isActive ? "textPrimary" : "textSecondary"}
                  >
                    {action.text}
                  </Typography>
                </ListItemText>
              </ActionButton>
            );
          })}
        </Box>
        <Box className="platforms">
          <ActionButton
            onClick={() => {
              navigate(routePaths.browser);
            }}
          >
            <ListItemIcon>
              <LibraryBooksIcon
                sx={{ color: theme.palette.primary.main }}
                fontSize="small"
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant="body2"
                fontWeight={500}
                color="textSecondary"
              >
                Document Analysis Platform
              </Typography>
            </ListItemText>
          </ActionButton>
          {companyFeatures.enableSecondaryApps && (
            <>
              <ActionButton
                onClick={() => {
                  navigate(routePaths.citegen);
                }}
              >
                <ListItemIcon>
                  <FormatListBulletedOutlinedIcon
                    sx={{ color: "#F19D38" }}
                    fontSize="small"
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    fontWeight={500}
                  >
                    Citation Generator
                  </Typography>
                </ListItemText>
              </ActionButton>
            </>
          )}
        </Box>
      </Box>
      <Divider />
      <Box className="profile-selector-container">
        <ProfileSelector />
      </Box>
    </SwitcherDrawer>
  );
};

export default Switcher;
