/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import CollectionSelector from "components/helpers/CollectionSelector";

const BibtexFileImport: React.FC<{
  selectedCollectionId?: number;
  fileContent: string;
  setSelectedCollectionId: (id?: number) => void;
  setFileContent: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  selectedCollectionId,
  setSelectedCollectionId,
  fileContent,
  setFileContent,
}) => {
  const fileReader = useRef<FileReader>(
    null
  ) as React.MutableRefObject<FileReader>;

  const handleFileRead = () => {
    if (fileReader.current) {
      setFileContent(fileReader.current.result as string);
    }
  };

  const handleFileOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      fileReader.current = new FileReader();
      fileReader.current.onloadend = handleFileRead;
      fileReader.current.readAsText(event.target.files[0]);
    }
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <input
          accept=".bib"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileOnChange}
          id="contained-button-file"
        />
        <label
          htmlFor="contained-button-file"
          style={{
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="textSecondary">
            Upload .bib file from your computer:
          </Typography>
          <Button
            variant="contained"
            component="span"
            size="small"
            color="secondary"
            startIcon={<CloudUploadIcon fontSize="small" />}
            sx={{
              marginRight: "1rem",
            }}
          >
            Upload
          </Button>
        </label>
      </Box>
      <Typography variant="body2" color="textSecondary">
        Or, paste BibTeX here:
      </Typography>
      <TextField
        variant="outlined"
        color="primary"
        fullWidth
        multiline
        rows={18}
        placeholder="@book{knuth1986texbook, &#10;title={The texbook}, &#10;author={Knuth, DonalErvin and bibby, Duane}, &#10;volume={1993}, &#10;year={1986} &#10;publisher={Addison-Wesley Reading, MA, USA} &#10;}"
        value={fileContent}
        onChange={(event) => {
          setFileContent(event.target.value);
        }}
        sx={{
          p: 0,
          height: "100%",
          background: "#FFF",
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          style: {
            padding: 0,
            fontFamily: "Consolas,menlo,monospace",
            height: "100%",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <SubdirectoryArrowRightIcon color="primary" fontSize="small" />
        <Typography variant="body2" fontWeight={500}>
          To the collection:
        </Typography>
        <CollectionSelector
          includeDefaultCollections
          canCreateCollection
          size="small"
          currentParentId={selectedCollectionId || -1}
          collectionToUse={(id) => {
            setSelectedCollectionId(id);
          }}
        />
      </Box>
    </>
  );
};

export default BibtexFileImport;
