import { normalizeAuthor } from "utils/bibtex";

/* eslint-disable import/prefer-default-export */
export const searchTextConverter = (query: string, text: string) => {
  const checkForQuotes = query.match(/"/g);
  let searchWords: string[] = [];
  // If there are quotes, we need to handle highlighting search results differently
  // Since words grouped in quotes should be highlighted together
  if (checkForQuotes && checkForQuotes.length > 1) {
    const tempSearchWords: string[] = [];
    let copyQuery = query;
    while (copyQuery.includes('"')) {
      const firstQuoteIndex = copyQuery.indexOf('"');
      if (firstQuoteIndex !== 0) {
        // Push words before the first quote normally
        copyQuery
          .slice(0, firstQuoteIndex)
          .split(" ")
          .forEach((word) => {
            if (word !== "") {
              tempSearchWords.push(word);
            }
          });
      }
      const secondQuoteIndex = copyQuery.indexOf('"', firstQuoteIndex + 1);
      if (secondQuoteIndex === -1) {
        // If there is only one quote, push the remainder of the string normally
        copyQuery.split(" ").forEach((word) => {
          if (word !== "") {
            tempSearchWords.push(word);
          }
        });
        break;
      }
      const quotedWord = copyQuery.slice(firstQuoteIndex + 1, secondQuoteIndex);
      // Push words that are in quotes together (don't split up by spaces)
      tempSearchWords.push(quotedWord);
      // Repeat with remainder of string
      copyQuery = copyQuery.slice(secondQuoteIndex + 1);
    }
    // Need to push the last words after the last quote (if any are left)
    copyQuery.split(" ").forEach((word) => {
      if (word !== "") {
        tempSearchWords.push(word);
      }
    });
    searchWords = tempSearchWords;
  } else {
    searchWords = query.split(" ");
  }

  let resultText = text;
  for (let i = 0; i < searchWords.length; i += 1) {
    // If word (or phrase if we had quotes surrounding it) is in the text, highlight it
    if (resultText.toLowerCase().includes(searchWords[i].toLowerCase())) {
      const startIndex = resultText
        .toLowerCase()
        .indexOf(searchWords[i].toLowerCase());
      const endIndex = startIndex + searchWords[i].length;
      const textResult = [
        resultText.slice(0, startIndex),
        `<em>`,
        resultText.slice(startIndex, endIndex),
        `</em>`,
        resultText.slice(endIndex),
      ].join("");
      resultText = textResult;
    }
  }

  return resultText;
};

export const setAuthorsData = (authors: string | string[]) => {
  const valueString = `${authors}`;
  let authorsArray: string[] = [];
  if (!authors) {
    return [];
  }
  if (Array.isArray(authors)) {
    authorsArray = authors as string[];
  } else if (valueString.includes(" and ")) {
    authorsArray = valueString.split(" and ");
  } else if (valueString.includes(" AND ")) {
    authorsArray = valueString.split(" AND ");
  } else {
    authorsArray = [authors as string];
  }
  return authorsArray
    .map((author) => author.trim())
    .map((author) => normalizeAuthor(author));
};
