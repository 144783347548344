import React, { useEffect } from "react";
import aiService from "api/aiService";
import { useDispatch } from "react-redux";
import { addAlert } from "store/features/general/slice";

const OpenAiProvider: React.FC = () => {
  const dispatch = useDispatch();

  const getStatus = () => {
    aiService.getAiStatus().then(({ data }) => {
      const aiError = sessionStorage.getItem("show-ai-error");
      if (!data.ai_online && aiError === "true") {
        dispatch(
          addAlert({
            severity: "warning",
            alert: {
              message:
                "Our language model provider OpenAI is currently experiencing sporadic outages which may cause interruptions to our AI features. We appreciate your patience as we work to integrate additional language model options to improve the uptime of our services.",
            },
            autoHideDuration: 10000,
          })
        );
        sessionStorage.setItem("show-ai-error", String(false));
      }
    });
  };

  useEffect(() => {
    const aiError = sessionStorage.getItem("show-ai-error");
    if (!aiError) {
      sessionStorage.setItem("show-ai-error", String(true));
    }
    getStatus();
    setInterval(() => {
      getStatus();
    }, 5 * 60 * 1000);
  }, []);

  return null;
};

export default OpenAiProvider;
