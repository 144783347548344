/* eslint-disable no-cond-assign */
/* eslint-disable prefer-regex-literals */
/* eslint-disable no-plusplus */
import React, { useEffect, useMemo, useState } from "react";
import { QAResponse } from "models/api/response.types";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import breaks from "remark-breaks";
import { useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";
import CheckIcon from "@mui/icons-material/Check";
import { useUsers } from "api/userService";
import moment from "moment";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { isAdmin } from "models/components/Permissions.models";
import { useAiQA } from "api/aiService";
import { useDocuments } from "api/documentService";
import { terminal_status_names } from "utils/aiHelpers";
import { styled } from "@mui/material/styles";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const Wrapper = styled(Box)(({ theme }) => ({
  padding: "1px",
  "& > *:not(:last-child)": {
    marginBottom: "1rem",
  },
  "& .query": {
    background: `${theme.notice.main}90`,
    borderRadius: "4px",
    borderBottomLeftRadius: "0",
    transition: "all 0.3s",
    border: "1px solid transparent",
    "&:not(.shared)": {
      background: "#dee8f7",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "0",
      marginLeft: "auto",
    },
    padding: "0.5rem 1rem",
    width: "fit-content",
    maxWidth: "60%",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "calc(100% - 1rem)",
    },
    "& .user": {
      fontSize: "12px",
      fontWeight: 500,
    },
    "&.to-delete": {
      borderColor: theme.palette.warning.light,
      "&:not(.shared)": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  "& .answer": {
    wordBreak: "break-word",
    width: "fit-content",
    background: `${theme.background.secondary}70`,
    border: "1px solid transparent",
    borderRadius: "4px",
    borderBottomLeftRadius: "0",
    padding: "0.5rem 1rem",
    fontSize: "14px",
    transition: "all 0.3s",
    maxWidth: "70%",
    overflow: "hidden",
    "&:hover": {
      "& .info .action-container": {
        opacity: 1,
      },
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "calc(100% - 1rem)",
    },
    "&.to-delete": {
      borderColor: theme.palette.primary.main,
    },
    "&.error": {
      background: `${theme.red.main}50`,
      "&.to-delete": {
        borderColor: theme.red.main,
      },
    },
    "& .info": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& .action-container": {
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        opacity: 0,
        "& .rating-result": {
          margin: "5px",
        },
      },
      "& .running-task": {
        width: "15px !important",
        height: "15px !important",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
      },
    },
    "& .document-sources": {
      display: "flex",
      gap: "1rem",
      alignItems: "flex-start",
      marginTop: "0.5rem",
      "& .source-list": {
        display: "flex",
        flexWrap: "wrap",
        gap: "0.5rem",
        "& .document-link": {
          paddingTop: "0.3rem",
          paddingBottom: "0.3rem",
        },
        " .document-text": {
          margin: 0,
          padding: "0.3rem",
          background: theme.grey.light,
          borderRadius: "4px",
        },
      },
    },
    "& .created-time": {
      fontSize: "13px",
      marginRight: "1rem",
    },
    "& .markdown p": {
      marginTop: "0.6em",
      marginBottom: "0.6em",
    },
    "& .markdown ul,& ol": {
      paddingLeft: "1rem",
      margin: "0.5rem 0",
    },
    "& .markdown table": {
      width: "100%",
      overflowX: "auto",
      margin: "0.5rem 0",
      borderCollapse: "collapse",
      textAlign: "left",
      display: "block",
      "& thead, & tbody": {
        background: theme.background.light,
        border: `1px solid ${theme.grey.light}`,
      },
      "& th,& td": {
        padding: "0.5rem 1rem",
      },
      "& thead tr": {
        "th:not(last-of-type)": {
          borderRight: `1px solid ${theme.grey.light}`,
        },
      },
      "& tbody tr": {
        borderTop: `1px solid ${theme.grey.light}`,
        "& td:not(last-of-type)": {
          borderRight: `1px solid ${theme.grey.light}`,
        },
      },
    },
    "& .markdown pre": {
      overflow: "scroll",
      background: theme.background.light,
      border: `1px solid ${theme.grey.light}`,
      fontSize: "12.5px",
      padding: "0.5rem",
      paddingBottom: 0,
      margin: `0.5rem 0`,
      color: theme.palette.primary.dark,
    },
    "& .markdown a": {
      color: theme.palette.secondary.main,
      textDecoration: "none",
      background: `${theme.palette.secondary.main}20`,
      padding: "0 0.3rem",
      borderRadius: "4px",
    },
  },
}));

interface ChunkMeta {
  document_id: number;
  doc_source_index: number;
  page_number: number;
  span: [number, number];
  text: string;
  title: string;
}

interface CiteKeyMapping {
  doc_map: CiteMap;
  chunk_maps: CiteMap[];
}

interface CiteMap {
  id: number;
  map: number;
}

const SourceTrackingTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const AIChatMessage: React.FC<{
  message: QAResponse;
  organizationId: number;
  showSources?: boolean;
  isDocumentViewer?: boolean;
}> = ({
  message,
  organizationId: currentOrganizationId,
  showSources,
  isDocumentViewer,
}) => {
  const currentUser = useSelector(selectUser);
  const { users, getCachedUserById } = useUsers(currentOrganizationId);
  const { documents } = useDocuments(currentOrganizationId);
  const { deleteQAMessageMutation } = useAiQA(message.ai_session_id);
  const [hoverDeleteMessage, setHoverDeleteMessage] = useState<
    number | undefined
  >(undefined);
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [user] = getCachedUserById(message.user_id);
  const citeKeys = new Set<string>([]);
  const currentUserRole = users?.find((u) => u.id === currentUser?.id)?.role;
  const createdAt = moment(message.created_at).format("h:mm a");
  const createdFromNow = moment().diff(moment(message.created_at), "seconds");
  const memberMessage = message.user_id !== currentUser?.id;
  const isRunning =
    message.task_status === "running" && message.response.length > 0;
  const isSuccessed = message?.response && message.task_status === "success";
  const isLoading =
    !terminal_status_names.includes(message.task_status) ||
    (message.task_status === "None" && createdFromNow < 10);
  const isFailed =
    terminal_status_names.includes(message.task_status) &&
    createdFromNow > 10 &&
    message.task_status !== "success";
  const isContinuiningResponse = message?.meta?.search_query;
  const [copied, setIsCopied] = useState<boolean>(false);

  async function copyTextToClipboard(text: string) {
    setIsCopied(true);
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand("copy", true, text);
  }

  useEffect(() => {
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }, [copied]);

  // Map the cite keys from the backend to more display friendly & sequential numbers
  const citeKeyMapping: CiteKeyMapping[] = useMemo(() => {
    const regex = /\[(.*?)\]/g;
    let match;
    const tempCiteKeyMapping: CiteKeyMapping[] = [];
    while ((match = regex.exec(message.response)) !== null) {
      if (match[1].split(".").length === 2) {
        const doc_id = parseInt(match[1].split(".")[0], 10);
        const chunk_id = parseInt(match[1].split(".")[1], 10);
        // Check if citeKeyMapping already has doc_id
        const existingDocEntry = tempCiteKeyMapping.find(
          (m) => m.doc_map.id === doc_id
        );
        if (existingDocEntry) {
          // Check if chunk_id already exists
          const existingChunkEntry = existingDocEntry.chunk_maps.find(
            (m) => m.id === chunk_id
          );
          if (!existingChunkEntry) {
            const chunk_map = {
              id: chunk_id,
              map: existingDocEntry.chunk_maps.length + 1,
            };
            existingDocEntry?.chunk_maps.push(chunk_map);
          }
        } else {
          const doc_map = {
            id: doc_id,
            map: tempCiteKeyMapping.length + 1,
          };
          const chunk_map = {
            id: chunk_id,
            map: 1,
          };
          const new_mapping = {
            doc_map,
            chunk_maps: [chunk_map],
          };
          tempCiteKeyMapping.push(new_mapping);
        }
      }
    }
    return [...tempCiteKeyMapping];
  }, [message.response]);

  // get chunk label -> ChunkMeta
  const sourceDocuments: ChunkMeta[] = useMemo(() => {
    if (message.meta?.chunk_metas && message.response) {
      const extractCiteKeys = (input: string): string[] => {
        const regex = /\[(.*?)\]/g;
        let match;
        const result = [];
        while ((match = regex.exec(input)) !== null) {
          result.push(match[1]);
        }
        return result;
      };
      // add array of cite keys to citeKeys
      extractCiteKeys(message.response).forEach((key) => {
        citeKeys.add(key);
      });
      const uniqueDocumentChunks: ChunkMeta[] = [];
      const ids = new Set<number>([]);
      Object.entries(message.meta?.chunk_metas).forEach(([key, value]) => {
        const chunkMeta = value as ChunkMeta;
        if (!ids.has(chunkMeta.document_id) && citeKeys.has(key)) {
          uniqueDocumentChunks.push({
            ...chunkMeta,
            doc_source_index:
              citeKeyMapping.find(
                (m) => m.doc_map.id === parseInt(key.split(".")[0], 10)
              )?.doc_map.map || -1,
          });
          ids.add(chunkMeta.document_id);
        }
      });
      uniqueDocumentChunks.sort(
        (a, b) => a.doc_source_index - b.doc_source_index
      );
      return uniqueDocumentChunks;
    }
    return [];
  }, [documents, message.response]);

  const messageResponse = useMemo(() => {
    if (message.response) {
      const getChunk = (key: string): ChunkMeta | undefined => {
        if (message.meta?.chunk_metas) {
          return message.meta?.chunk_metas[key];
        }
        return undefined;
      };
      const getDocViewerUrl = (chunk: ChunkMeta): string => {
        return `/document/${chunk.document_id}/view?organizationId=${currentOrganizationId}#/page/${chunk.page_number}/span/${chunk.span[0]}-${chunk.span[1]}`;
      };
      const regex = /\[(\d+\.\d+)\]/g;
      let match;
      let newText = message.response;
      while ((match = regex.exec(message.response)) !== null) {
        const key = match[1];
        const chunk = getChunk(key);
        if (chunk && chunk.page_number > 0) {
          const url = getDocViewerUrl(chunk);
          // Find the correlating doc and chunk index from our user friendly mapping
          const doc_index = sourceDocuments.find(
            (doc) => doc.document_id === chunk.document_id
          )?.doc_source_index;
          const chunk_index = citeKeyMapping
            .find((m) => m.doc_map.id === parseInt(key.split(".")[0], 10))
            ?.chunk_maps.find(
              (m) => m.id === parseInt(key.split(".")[1], 10)
            )?.map;
          newText = newText.replace(
            `[${key}]`,
            `[(${doc_index}.${chunk_index})](${url})`
          );
        } else {
          newText = newText.replace(`[${key}]`, "");
        }
      }
      newText = newText.replace(/\[\((\d+\.\d+)\)\]/g, "[$1]"); // replace extra brackets
      return newText;
    }
    return "";
  }, [message, message.response]);

  const markdownLink = (props: any) => {
    if (props.href.startsWith("/document/")) {
      const keyMappedValue = `${props.children}`;
      const keyMapping = citeKeyMapping.find(
        (m) => m.doc_map.map === parseInt(keyMappedValue.split(".")[0], 10)
      );
      const keyDocValue = keyMapping?.doc_map.id;
      const keyChunkValue = keyMapping?.chunk_maps.find(
        (m) => m.map === parseInt(keyMappedValue.split(".")[1], 10)
      )?.id;

      const key = `${keyDocValue}.${keyChunkValue}`;
      if (message.meta?.chunk_metas) {
        if (key in message.meta.chunk_metas) {
          const chunk: ChunkMeta = message.meta.chunk_metas[key];
          let label = `${chunk.title} (Page ${chunk.page_number})`;
          let content = chunk.text;
          if (chunk.page_number === 0) {
            label = `${chunk.title}`;
            content = "Proprietary";
          }
          if (isDocumentViewer) {
            return (
              <SourceTrackingTooltip
                title={
                  <>
                    <Typography variant="subtitle2" mb={1}>
                      {label}
                    </Typography>
                    <Typography variant="body2">{content}</Typography>
                  </>
                }
              >
                <a href={`#/page/${chunk.page_number}`}>
                  {chunk.page_number ? `p.\u00a0${chunk.page_number}` : "*"}
                </a>
              </SourceTrackingTooltip>
            );
          }
          return (
            <SourceTrackingTooltip
              title={
                <>
                  <Typography variant="subtitle2">{label}</Typography>
                </>
              }
            >
              <a href={props.href} rel="noreferrer" target="_blank">
                {props.children}
              </a>
            </SourceTrackingTooltip>
          );
        }
      }
    }
    return (
      <a href={props.href}>{props.children}</a> // All other links
    );
  };

  return (
    <Wrapper className="message" key={message.id}>
      {!isContinuiningResponse && (
        <Box
          className={clsx("query", {
            shared: memberMessage,
            "to-delete": hoverDeleteMessage === message.id,
          })}
        >
          {user?.name && memberMessage && (
            <Typography className="user">{user?.name}</Typography>
          )}
          <Typography variant="body2">{message.query}</Typography>
        </Box>
      )}
      <Box
        className={clsx("answer", {
          "to-delete": hoverDeleteMessage === message.id,
          error: isFailed,
        })}
      >
        {(isSuccessed || isFailed || isRunning) && (
          <ReactMarkdown
            className="markdown"
            remarkPlugins={[remarkGfm, breaks]}
            linkTarget="_blank"
            components={{
              a: markdownLink,
            }}
          >
            {!isFailed
              ? messageResponse
              : "Our servers are currently overloaded, please try again later. Thank you for your patience as we continue to scale our services."}
          </ReactMarkdown>
        )}
        {isLoading && !isRunning && (
          <Box className="loading-message">
            <span />
            <span />
            <span />
          </Box>
        )}
        {isRunning && (
          <Box className="info">
            <CircularProgress className="running-task" />
          </Box>
        )}
        {!isLoading && (
          <>
            <Box className="info">
              <Typography
                textAlign="right"
                className="created-time"
                color="textSecondary"
              >
                {createdAt}
              </Typography>
              <Box className="action-container">
                {(message.user_id === currentUser?.id ||
                  isAdmin(currentUserRole)) && (
                  <Tooltip enterDelay={500} title="Delete conversation">
                    <IconButton
                      size="small"
                      onClick={() => {
                        deleteQAMessageMutation.mutate(message.id);
                      }}
                      onMouseEnter={() => {
                        setHoverDeleteMessage(message.id);
                      }}
                      onMouseLeave={() => {
                        setHoverDeleteMessage(undefined);
                      }}
                    >
                      <DeleteOutlineIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                )}
                <IconButton
                  size="small"
                  onClick={() => {
                    copyTextToClipboard(messageResponse);
                  }}
                >
                  {copied ? (
                    <CheckIcon fontSize="inherit" />
                  ) : (
                    <ContentCopyIcon fontSize="inherit" />
                  )}
                </IconButton>
              </Box>
            </Box>
            {showSources && sourceDocuments.length > 0 && (
              <>
                <Divider sx={{ margin: "0.5rem 0" }} />
                <Box className="document-sources">
                  <Box className="source-list">
                    {sourceDocuments.map((sourceDocument, index) => {
                      // if doc exist, show source
                      if ((seeMore && index > 1) || index <= 1) {
                        const displayedTitle = sourceDocument.title;
                        const canView = sourceDocument.page_number > 0;
                        if (canView) {
                          return (
                            <Link
                              className="document-link"
                              href={`/document/${sourceDocument.document_id}/view?organizationId=${currentOrganizationId}#/page/${sourceDocument.page_number}`}
                              rel="noopener"
                              target="_blank"
                              key={sourceDocument.document_id}
                            >
                              {sourceDocument.doc_source_index}.{" "}
                              {displayedTitle}
                            </Link>
                          );
                        }
                        return (
                          <Typography className="document-text" variant="body2">
                            {index + 1}. {displayedTitle}
                          </Typography>
                        );
                      }
                      return null;
                    })}
                    {Object.entries(sourceDocuments).length > 2 && (
                      <Button
                        onClick={() => setSeeMore(!seeMore)}
                        size="small"
                        color="primary"
                        variant="text"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {seeMore ? "See less" : "See more"}
                      </Button>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Wrapper>
  );
};

export default AIChatMessage;
