/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-array-index-key */
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { authService } from "api";
import PasswordValidator from "components/Auth/PasswordValidator";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAlert } from "store/features/general/slice";
import { selectUser, setUser } from "store/features/session/slice";
import handleAxiosError from "utils/handleAxiosAlert";
import { academicStatusList, disciplineList } from "utils/profileData";

const InputWrapper = styled(Box)(({ theme }) => ({
  margin: `0 auto ${theme.spacing(3)} auto`,
  display: "flex",
  width: "100%",
  gap: "2rem",
  flexWrap: "wrap",
  justifyContent: "center",
  "& .MuiFormControl-root": {
    flex: 1,
    "& .MuiInputLabel-root": {
      color: "#272727",
      position: "relative",
      fontSize: "12px",
      transform: "none",
      marginBottom: "8px",
    },
  },
}));

const ProfileMenu: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [userName, setUserName] = useState<string>(user?.name || "");
  const [userAcademicStatus, setUserAcademicStatus] = useState<string>(
    user?.meta.academic_status || "Unspecified"
  );
  const [userDiscipline, setUserDiscipline] = useState<string>(
    user?.meta.discipline || "Unspecified"
  );
  const [userBio, setUserBio] = useState<string>(user?.meta.bio || "");
  const [nameErrorMessage, setNameErrorMessage] = useState<string>("");
  const [reminder, setReminder] = useState<boolean>(false);
  const [updatingUser, setUpdatingUser] = useState<boolean>(false);
  const [changingPassword, setChangingPassword] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordErrorMessage, setPasswordErrorMessage] =
    useState<boolean>(false);
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState<string>("");

  useEffect(() => {
    if (
      user?.name !== userName ||
      (user?.meta.academic_status &&
        user?.meta.academic_status !== userAcademicStatus) ||
      (!user?.meta.academic_status && userAcademicStatus !== "Unspecified") ||
      (user?.meta.discipline && user?.meta.discipline !== userDiscipline) ||
      (!user?.meta.discipline && userDiscipline !== "Unspecified") ||
      (user?.meta.bio && user?.meta.bio !== userBio) ||
      (!user?.meta.bio && userBio.length > 0)
    ) {
      setReminder(true);
    } else {
      setReminder(false);
    }
  }, [userName, userAcademicStatus, userDiscipline, userBio, user]);

  const updateProfile = () => {
    if (userName.length === 0) {
      setNameErrorMessage("Name can't be empty.");
    } else if (user) {
      setUpdatingUser(true);
      authService
        .updateUser(user?.id, {
          name: userName,
          meta: {
            ...user.meta,
            academic_status: userAcademicStatus,
            discipline: userDiscipline,
            bio: userBio,
          },
        })
        .then(({ data }) => {
          dispatch(
            setUser({
              ...user,
              ...data,
            })
          );
          setUpdatingUser(false);
          dispatch(
            addAlert({
              severity: "success",
              autoHideDuration: 5000,
              alert: {
                message: "Congrats! Profile has been changed.",
              },
            })
          );
        })
        .catch((err) => {
          setUpdatingUser(false);
          handleAxiosError(err, dispatch);
        });
    }
  };

  const submitChangePassword = () => {
    if (!isValidPassword) {
      setPasswordErrorMessage(true);
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordErrorMessage("Passwords don't match.");
    } else if (user) {
      setChangingPassword(true);
      authService
        .updateUser(user?.id, {
          password: newPassword,
        })
        .then(() => {
          setChangingPassword(false);
          setNewPassword("");
          setConfirmPassword("");
          dispatch(
            addAlert({
              severity: "success",
              autoHideDuration: 5000,
              alert: {
                message: "Your password has been changed.",
              },
            })
          );
        })
        .catch(() => {
          setChangingPassword(false);
          setConfirmPasswordErrorMessage(
            "Something went wrong. Please try again."
          );
        });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "1rem",
        overflow: "auto",
      }}
    >
      <Box className="header">
        <Typography variant="h6">User profile</Typography>
        <Divider sx={{ margin: "1rem 0 2rem 0" }} />
      </Box>
      <InputWrapper>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="email-input">
            Email
          </InputLabel>
          <TextField
            id="email-input"
            color="primary"
            variant="outlined"
            value={user?.email}
            disabled
            type="email"
            size="small"
            fullWidth
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="name-input">
            Name
          </InputLabel>
          <TextField
            id="name-input"
            fullWidth
            color="primary"
            variant="outlined"
            value={userName}
            size="small"
            type="text"
            error={nameErrorMessage.length > 0}
            helperText={nameErrorMessage}
            onChange={(e) => {
              setNameErrorMessage("");
              setUserName(e.target.value);
            }}
          />
        </FormControl>
      </InputWrapper>
      <InputWrapper>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="academic-input">
            Academic status
          </InputLabel>
          <Select
            size="small"
            variant="outlined"
            color="primary"
            id="academic-input"
            value={userAcademicStatus}
            onChange={(e) => {
              setUserAcademicStatus(e.target.value);
            }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
          >
            {academicStatusList.map((status, index) => (
              <MenuItem key={`${status}+${index}`} value={status}>
                <Typography variant="body2">{status}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="discipline-input">
            Discipline
          </InputLabel>
          <Select
            size="small"
            variant="outlined"
            color="primary"
            id="discipline-input"
            value={userDiscipline}
            onChange={(e) => {
              setUserDiscipline(e.target.value);
            }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
          >
            {disciplineList.map((discipline, index) => (
              <MenuItem key={`${discipline}+${index}`} value={discipline}>
                <Typography variant="body2">{discipline}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </InputWrapper>
      <InputWrapper
        sx={{
          marginTop: 0,
          "& .MuiFormControl-root": {
            "& textarea": {
              padding: 0,
            },
          },
        }}
      >
        <FormControl variant="standard">
          <InputLabel shrink htmlFor="bio-input">
            Bio
          </InputLabel>
          <TextField
            color="primary"
            variant="outlined"
            id="bio-input"
            size="small"
            fullWidth
            multiline
            type="text"
            value={userBio}
            maxRows={6}
            onChange={(e) => {
              setUserBio(e.target.value);
            }}
          />
        </FormControl>
      </InputWrapper>
      {reminder && (
        <Typography
          variant="body2"
          sx={{
            marginBottom: "16px",
            color: theme.red.main,
          }}
        >
          You&apos;ve made changes to your profile, don&apos;t forget to save or
          you&apos;ll lose them.
        </Typography>
      )}
      <InputWrapper
        sx={{
          justifyContent: "flex-start",
        }}
      >
        <LoadingButton
          color="primary"
          size="small"
          variant="contained"
          onClick={updateProfile}
          loading={updatingUser}
        >
          Update profile
        </LoadingButton>
      </InputWrapper>
      <Box className="header">
        <Typography variant="h6">Change password</Typography>
        <Divider sx={{ margin: "1rem 0 2rem 0" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <InputWrapper
          sx={{
            margin: 0,
            maxWidth: "48%",
          }}
        >
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="new-password-input">
              New password
            </InputLabel>
            <TextField
              type="password"
              id="new-password-input"
              variant="outlined"
              color="secondary"
              value={newPassword}
              size="small"
              error={passwordErrorMessage}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setPasswordErrorMessage(false);
              }}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  submitChangePassword();
                }
              }}
            />
          </FormControl>
        </InputWrapper>
        <InputWrapper
          sx={{
            margin: 0,
            marginBottom: "1rem",
            maxWidth: "48%",
          }}
        >
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="confirm-password-input">
              Confirm new password
            </InputLabel>
            <TextField
              type="password"
              id="confirm-password-input"
              variant="outlined"
              color="secondary"
              value={confirmPassword}
              size="small"
              error={confirmPasswordErrorMessage.length > 0}
              helperText={confirmPasswordErrorMessage}
              onChange={(e) => {
                setConfirmPasswordErrorMessage("");
                setConfirmPassword(e.target.value);
              }}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  submitChangePassword();
                }
              }}
            />
          </FormControl>
        </InputWrapper>
      </Box>
      <PasswordValidator
        password={newPassword}
        error={passwordErrorMessage}
        setIsValidPassword={setIsValidPassword}
      />
      <Box sx={{ marginTop: "1rem" }}>
        <LoadingButton
          type="button"
          color="primary"
          size="small"
          variant="contained"
          onClick={submitChangePassword}
          loading={changingPassword}
        >
          Update password
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ProfileMenu;
