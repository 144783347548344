import React, { useContext, useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import {
  SearchOutlined,
  ClearOutlined,
  HelpOutline,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  selectFocusSearchInput,
  selectIsSearch,
  selectSearchQuery,
  selectSearchTypeQuery,
  setFocusSearchInput,
  setIsSearch,
  setSearchQuery,
  setSearchTypeQuery,
  SearchType,
} from "store/features/browser/slice";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  ClickAwayListener,
  Select,
  MenuItem,
  Tooltip,
  styled,
  Typography,
} from "@mui/material";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import axios from "axios";
import { MultiDocumentSearchResponse } from "models/api/response.types";
import { DocumentsContext } from "pages/Documents";
import { useOrganizations } from "api/organizationService";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flex: 0.6,
  [theme.breakpoints.down("md")]: {
    flex: 1,
  },
  "& form": {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  "& .search-input-container": {
    borderRadius: "4px",
    minWidth: "350px",
    width: "100%",
    height: "36px",
    "& .button": {
      minWidth: 0,
    },
    border: `1px solid ${theme.grey.light}`,
    "&:hover": {
      border: `1px solid ${theme.grey.main}`,
    },
    background: theme.background.light,
    "& .search-input": {
      margin: "0 0.5rem",
      flex: 1,
      color: theme.palette.text.primary,
      "& input::placeholder": {
        opacity: 0.7,
      },
    },
    "& .search-type": {
      width: "6rem",
      fontSize: "0.875rem",
      height: "20px",
      margin: "7px 0 0",
      borderRadius: "0",
      borderLeft: `1px solid ${theme.grey.main}`,
      background: "none",
      color: theme.palette.primary.main,
      "& fieldset": {
        border: "none",
      },
      "& .MuiSelect-select": {
        padding: "0 0.5rem",
        textOverflow: "clip",
        lineHeight: "1.5",
      },
      "& .MuiSelect-icon": {
        right: "0",
        color: theme.palette.primary.main,
      },
    },
  },
}));

const SearchBar: React.FC = () => {
  const dispatch = useDispatch();
  const {
    setTrashMode,
    setSubmitSearch,
    setSearchedDocuments,
    setSelectedDocuments,
    setDocumentToShow,
  } = useContext(DocumentsContext);
  const { logAction } = useTelemetry();
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const focusSearchInput = useSelector(selectFocusSearchInput);
  const isSearch = useSelector(selectIsSearch);
  const searchQuery = useSelector(selectSearchQuery);
  const searchTypeQuery = useSelector(selectSearchTypeQuery);
  const { getCachedOrganizationById } = useOrganizations(user?.id);
  const [currentOrganization] = getCachedOrganizationById(
    currentOrganizationId || -1
  );
  const [searchType, setSearchType] = useState(searchTypeQuery);
  const [searchString, setSearchString] = useState(searchQuery);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchQuery.length === 0) {
      setSearchString("");
      setSearchedDocuments(undefined);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchString.length === 0) {
      dispatch(setSearchQuery(""));
      setSearchedDocuments(undefined);
      dispatch(setIsSearch(false));
    }
  }, [searchString]);

  useEffect(() => {
    if (!isSearch && searchQuery.length > 0) {
      dispatch(setSearchQuery(""));
      dispatch(setSearchTypeQuery(SearchType.fulltext));
      setSearchType(SearchType.fulltext);
    }
  }, [isSearch]);

  useEffect(() => {
    if (searchType !== searchTypeQuery) {
      dispatch(setSearchTypeQuery(searchType));
    }
  }, [searchTypeQuery]);

  // consume message to focus search input
  useEffect(() => {
    if (focusSearchInput && searchInputRef.current) {
      searchInputRef.current.focus();
      searchInputRef.current.select();
      dispatch(setFocusSearchInput(false));
    }
  }, [focusSearchInput]);

  const handleSearch = () => {
    setSubmitSearch(true);
    setTrashMode(false);
    setSelectedDocuments([]);
    setDocumentToShow(undefined);

    if (currentOrganization && searchType !== SearchType.semantic) {
      const url = `/api/document/search?q=${encodeURIComponent(
        searchString
      )}&organization_id=${currentOrganization.id}&meta=${
        searchType !== SearchType.fulltext
      }`;
      axios
        .get<MultiDocumentSearchResponse>(url)
        .then(({ data }) => {
          setSearchedDocuments(data.documents);
          setSubmitSearch(false);
          dispatch(setSearchTypeQuery(searchType));
          dispatch(setIsSearch(true));
        })
        .catch(() => {
          setSearchedDocuments([]);
          setSubmitSearch(false);
          dispatch(setIsSearch(true));
        });
    } else if (currentOrganization && searchType === SearchType.semantic) {
      const url = `/api/document/semantic_search?q=${encodeURIComponent(
        searchString
      )}&organization_id=${currentOrganization.id}`;
      axios
        .get<MultiDocumentSearchResponse>(url)
        .then(({ data }) => {
          setSearchedDocuments(data.documents);
          setSubmitSearch(false);
          dispatch(setSearchTypeQuery(searchType));
          dispatch(setIsSearch(true));
        })
        .catch(() => {
          setSearchedDocuments([]);
          setSubmitSearch(false);
          dispatch(setIsSearch(true));
        });
    }
  };

  // search on load if search props true
  useEffect(() => {
    if (isSearch && searchQuery.length > 0) {
      handleSearch();
    }
  }, [currentOrganization]);

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>): void => {
    event?.preventDefault();
    if (
      searchString.length > 0 &&
      (searchString !== searchQuery || searchType !== searchTypeQuery)
    ) {
      dispatch(setSearchQuery(searchString));
      logAction(telemetryAction.execute_search, {
        search_query: searchQuery,
      });
      handleSearch();
    }
  };

  useEffect(() => {
    if (searchString.length > 0 && searchType !== searchTypeQuery) {
      handleSubmit();
    }
  }, [searchType]);

  const searchInput = () => {
    return (
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "0 0.5rem",
        }}
        ref={searchContainerRef}
      >
        <form onSubmit={handleSubmit}>
          <IconButton
            size="small"
            onClick={() => handleSubmit()}
            aria-label="search"
          >
            <SearchOutlined fontSize="small" />
          </IconButton>
          <InputBase
            className="search-input"
            placeholder="Search documents"
            value={searchString}
            onChange={(e) => {
              if (e.target.value.length < 400) {
                setSearchString(e.target.value);
              }
            }}
            inputRef={searchInputRef}
            margin="dense"
          />
          {searchQuery.length > 0 && (
            <IconButton
              className="button clear-button"
              type="button"
              aria-label="clear"
              size="small"
              onClick={() => {
                if (isSearch) {
                  dispatch(setIsSearch(false));
                }
                setSelectedDocuments([]);
                setDocumentToShow(undefined);
                dispatch(setSearchQuery(""));
                setSearchType(searchTypeQuery);
                setSearchString("");
              }}
            >
              <ClearOutlined fontSize="small" />
            </IconButton>
          )}
        </form>
        <Select
          className="search-type"
          value={searchType}
          IconComponent={KeyboardArrowDown}
          MenuProps={{
            disablePortal: true,
          }}
          renderValue={(value: string) => value}
          onChange={(e) => {
            const newSearchType = e.target.value as SearchType;
            setSearchType(newSearchType);
          }}
        >
          <MenuItem
            value={SearchType.fulltext}
            sx={{
              width: "9rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">{SearchType.fulltext}</Typography>
            <Tooltip
              title="Full text search looks for exact keyword matches in both metadata and body of the documents."
              placement="bottom-start"
              arrow
            >
              <HelpOutline fontSize="small" color="primary" />
            </Tooltip>
          </MenuItem>
          <MenuItem
            value={SearchType.meta}
            sx={{
              width: "9rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">{SearchType.meta}</Typography>
            <Tooltip
              title='Metadata search looks for exact keyword matches in fields like "Title", "Author", "Abstract", etc.'
              placement="bottom-start"
              arrow
            >
              <HelpOutline fontSize="small" color="primary" />
            </Tooltip>
          </MenuItem>
          <MenuItem
            value={SearchType.semantic}
            sx={{
              width: "9rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">{SearchType.semantic}</Typography>
            <Tooltip
              title="Semantic search looks for matches in the meaning of the search terms, and does not require exact keyword matches."
              placement="bottom-start"
              arrow
            >
              <HelpOutline fontSize="small" color="primary" />
            </Tooltip>
          </MenuItem>
        </Select>
      </Box>
    );
  };

  return (
    <Wrapper>
      <ClickAwayListener
        onClickAway={(e) => {
          if (
            searchString !== searchQuery &&
            !searchContainerRef.current?.contains(e.target as Node)
          ) {
            setSearchString(searchQuery);
          }
          if (searchType !== searchTypeQuery) {
            setSearchType(searchTypeQuery);
          }
        }}
      >
        <Box className="search-input-container">{searchInput()}</Box>
      </ClickAwayListener>
    </Wrapper>
  );
};

export default SearchBar;
