import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  useTheme,
} from "@mui/material";
import { Invite, User } from "models/api/response.types";
import { SearchOutlined, ClearOutlined } from "@mui/icons-material";
import { useUsers } from "api/userService";
import { matchSorter } from "match-sorter";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { useOrgInvites } from "api/inviteService";
import InviteMembersDialog from "components/Dialogs/InviteMembersDialog";
import { useQueryClient } from "@tanstack/react-query";
import { useOrganizationUsage } from "api/organizationService";
import { useOrg } from "pages/WorkspaceSettings";
import useResizeObserver from "use-resize-observer";
import LoadingOverlay from "components/helpers/LoadingOverlay";
import SimpleTable from "./Tables/SimpleTable";
import OrganizationUsersTable from "./Tables/OrganizationUsersTable";
import { ITableRow } from "./Tables/table-utils";

const MembersAndInvite: React.FC = () => {
  const { org: currentOrg, containerRef } = useOrg();
  if (!currentOrg) {
    return null;
  }
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [memberSearchValue, setMemberSearchValue] = useState<string>("");
  const { organizationUsage, usageQueryKey } = useOrganizationUsage(
    currentOrg?.id
  );
  const { users, deleteUserMutation, updateUserRoleMutation } = useUsers(
    currentOrg.id
  );
  const {
    orgInvites: invites,
    orgInvitesQueryKey,
    deleteOrgInviteMutation,
  } = useOrgInvites(currentOrg.id);
  const [ref, setRef] = useState<
    React.MutableRefObject<HTMLDivElement> | undefined
  >(undefined);
  const sizesInputContainer = useResizeObserver({ ref });
  const [openInviteDialog, setOpenInviteDialog] = useState<boolean>(false);
  const membersSeatsRemaining = organizationUsage
    ? organizationUsage.usage_limits.members - organizationUsage.usage.members
    : 0;
  const guestsSeatsRemaining = organizationUsage
    ? organizationUsage.usage_limits.guests - organizationUsage.usage.guests
    : 0;

  // head cells
  const organiationUsersCells = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "lastActive",
      label: "Last Active",
    },
  ];

  // head cells
  const organiationInvitesCells = [
    {
      id: "email",
      label: "Email",
    },
    {
      id: "invitedBy",
      label: "Invited By",
    },
    {
      id: "time",
      label: "Time",
    },
    {
      id: "status",
      label: "Status",
    },
  ];

  const createUsersTableData = (
    id: number,
    name: string,
    email: string,
    role: string,
    lastActive: string
  ) => {
    return {
      name,
      email,
      role,
      lastActive,
      id,
    };
  };

  const createInviteTableData = (
    id: number,
    email: string,
    invitedBy: string,
    time: string,
    status: string
  ) => {
    return {
      id,
      email,
      invitedBy,
      time,
      status,
    };
  };

  // users table rows
  const usersTableRows = matchSorter(users || [], memberSearchValue, {
    keys: ["name"],
  }).map((user: User) => {
    const {
      name,
      email,
      last_active_at,
      organization_ids,
      organization_roles,
      id,
    } = user;
    const orgIndex = organization_ids.indexOf(currentOrg.id);
    const role = organization_roles ? organization_roles[orgIndex] : "member";
    const lastActiveAt = moment(last_active_at as string).format("lll");

    return createUsersTableData(
      id,
      name || "-",
      email,
      role || "member",
      lastActiveAt
    );
  });

  // invites table rows
  const invitesTableRows =
    invites?.map((invite: Invite) => {
      const { id, email, invited_by_email, accepted_at, declined_at, sent_at } =
        invite;
      const status =
        !accepted_at && !declined_at
          ? "Pending"
          : accepted_at
          ? "Accepted"
          : declined_at
          ? "Declined"
          : "Uknown";
      const invitedAt = moment(sent_at as string).format("lll");

      return createInviteTableData(
        id,
        email,
        invited_by_email,
        invitedAt,
        status
      );
    }) || [];

  if (!users) {
    return <LoadingOverlay />;
  }

  return (
    <Box sx={{ padding: "1rem", overflow: "auto" }}>
      <Box className="header">
        <Typography variant="h6">Workspace members</Typography>
        <Divider sx={{ margin: "1rem 0 2rem 0" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          marginBottom: "1rem",
          alignItems: "center",
          gap: "1rem",
          flexWrap: "wrap",
        }}
      >
        <Box
          ref={setRef}
          sx={{
            flex: 1,
          }}
        >
          <OutlinedInput
            color="primary"
            value={memberSearchValue}
            size="small"
            placeholder="Search member by name"
            onChange={(event) => {
              setMemberSearchValue(event.target.value);
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            }
            endAdornment={
              <>
                {memberSearchValue.length > 0 && (
                  <IconButton onClick={() => setMemberSearchValue("")}>
                    <ClearOutlined />
                  </IconButton>
                )}
              </>
            }
            sx={{
              display: "flex",
              minWidth: "250px",
              maxWidth: "40rem",
            }}
          />
        </Box>
        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenInviteDialog(true)}
        >
          Invite new member
        </Button>
      </Box>
      <Typography
        variant="body2"
        sx={{
          margin: "1rem 0",
        }}
      >
        There are{" "}
        <span
          style={{
            color: theme.palette.secondary.main,
            fontWeight: 500,
          }}
        >
          {users?.length}
        </span>{" "}
        user(s) in this workspace
      </Typography>
      <OrganizationUsersTable
        organization={currentOrg}
        membersSeatsRemaining={membersSeatsRemaining}
        guestsSeatsRemaining={guestsSeatsRemaining}
        maxHeight={
          containerRef.height && sizesInputContainer.height
            ? invites && invites.length > 0
              ? (containerRef.height - sizesInputContainer.height) /
                  (containerRef.height > 950 ? 2.5 : 3.5) -
                20
              : containerRef.height - sizesInputContainer.height - 250
            : 300
        }
        minWidth={650}
        headCells={organiationUsersCells}
        tableRows={usersTableRows}
        deleteRow={(id) => {
          deleteUserMutation.mutate(id);
        }}
        updateUserRole={(userId, role) => {
          updateUserRoleMutation.mutate(
            { userId, role },
            {
              onSuccess: () => {
                queryClient.invalidateQueries(usageQueryKey);
              },
            }
          );
        }}
      />
      {invites && invites.length > 0 && (
        <Box
          sx={{
            marginTop: "2rem",
          }}
        >
          <Box className="header">
            <Typography variant="h6">Invitation history</Typography>
            <Divider sx={{ margin: "1rem 0 2rem 0" }} />
          </Box>
          <SimpleTable
            maxHeight={
              containerRef.height && sizesInputContainer.height
                ? (containerRef.height - sizesInputContainer.height) / 3.5 - 20
                : 300
            }
            minWidth={650}
            headCells={organiationInvitesCells}
            tableRows={invitesTableRows}
            canDelete
            deleteRow={(row: ITableRow) => {
              deleteOrgInviteMutation.mutate(row.id as number);
            }}
          />
        </Box>
      )}
      {openInviteDialog && (
        <InviteMembersDialog
          organization={currentOrg}
          setOpen={setOpenInviteDialog}
          inviteCompletesCallback={() => {
            queryClient.invalidateQueries(orgInvitesQueryKey);
          }}
        />
      )}
    </Box>
  );
};

export default MembersAndInvite;
