import axios, { AxiosError, AxiosPromise, AxiosResponse } from "axios";
import {
  AccessTokenResponse,
  Invite,
  InviteList,
  SuccessResponse,
} from "models/api/response.types";
import { useCallback, useMemo } from "react";
import {
  UseMutationResult,
  useQueryClient,
  useQuery,
  useMutation,
} from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { addAlert } from "store/features/general/slice";
import { selectUser } from "store/features/session/slice";
import handleAxiosError from "utils/handleAxiosAlert";

interface NewInviteData {
  email: string;
  organization_id: number;
  role: string;
}

const InviteErrors = {
  INVITE_NOT_FOUD: "Invite not found.",
};

const inviteWarning = (text: string, dispatch: any) => {
  dispatch(
    addAlert({
      severity: "error",
      autoHideDuration: 10000,
      alert: {
        message: text,
      },
    })
  );
};

interface NewOrgInvite extends NewInviteData {
  status: string;
}

const checkInvitePermission = (
  email: string,
  orgId: number,
  role: string
): AxiosPromise<any> => {
  return axios.get(
    `/api/invite/user/permitted?email=${email}&organization_id=${orgId}&role=${role}`
  );
};

const inviteNewUser = (requestBody: NewInviteData): AxiosPromise<Invite> => {
  return axios.post("/api/invite/new_user/create", requestBody);
};

const inviteExistingUser = (requestBody: {
  email: string;
  organization_id: number;
  role: string;
}): AxiosPromise<Invite> => {
  return axios.post("/api/invite/existing_user/create", requestBody);
};

const userInvitesList = (userId: number): AxiosPromise<InviteList> => {
  return axios.get(`/api/invite/user/${userId}/received`);
};

const orgInviteList = (organizationId: number): AxiosPromise<InviteList> => {
  return axios.get(`/api/invite/organization/${organizationId}/list`);
};

const deleteInvite = (inviteId: number): AxiosPromise<SuccessResponse> => {
  return axios.delete(`/api/invite/${inviteId}/delete`);
};

const acceptInvite = (inviteId: number): AxiosPromise<any> => {
  return axios.post(`/api/invite/${inviteId}/accept`);
};

const declineInvite = (inviteId: number): AxiosPromise<any> => {
  return axios.post(`/api/invite/${inviteId}/decline`);
};

export const inviteService = {
  checkInvitePermission,
  inviteNewUser,
  inviteExistingUser,
  userInvitesList,
  orgInviteList,
  deleteInvite,
  acceptInvite,
  declineInvite,
};

export default inviteService;

interface UseOrganizationInvitesResult {
  orgInvites: InviteList | undefined;
  orgInvitesQueryKey: string[];
  orgInvitesIsLoading: boolean;
  getCachedOrgInviteById: (
    id: number
  ) => [item: Readonly<Invite> | undefined, index: Readonly<number>];
  upsertCachedOrgInvite: (invite: Invite) => void;
  deleteOrgInviteMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    number,
    {
      previousOrgInvite: Readonly<Invite> | undefined;
    }
  >;
  createOrgInviteMutation: UseMutationResult<
    AxiosResponse<Invite>,
    unknown,
    NewOrgInvite,
    {
      newId: number;
    }
  >;
}

export const useOrgInvites = (
  organizationId: number | undefined
): UseOrganizationInvitesResult => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();
  const orgInvitesQueryKey = [`organization/${organizationId}/invites`];

  const { data: orgInvites, isLoading: orgInvitesIsLoading } = useQuery<
    unknown,
    unknown,
    InviteList,
    any
  >(
    orgInvitesQueryKey,
    () =>
      inviteService
        .orgInviteList(organizationId as number)
        .then(({ data }) => data),
    {
      enabled: !!organizationId,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch),
    }
  );

  const orgInviteIndexById = useMemo(
    () =>
      orgInvites?.reduce((lut: { [id: number]: number }, invite, index) => {
        // eslint-disable-next-line no-param-reassign
        lut[invite.id] = index;
        return lut;
      }, {}),
    [orgInvites]
  );

  const getCachedOrgInviteById: (
    id: number
  ) => [item: Readonly<Invite> | undefined, index: Readonly<number>] =
    useCallback(
      (id: number) =>
        orgInvites && orgInviteIndexById && id in orgInviteIndexById
          ? [orgInvites[orgInviteIndexById[id]], orgInviteIndexById[id]]
          : [undefined, -1],
      [orgInviteIndexById]
    );

  const upsertCachedOrgInvite = (newOrgInvite: Invite): Invite | undefined => {
    const [currentOrgInvite, index] = getCachedOrgInviteById(newOrgInvite.id);
    if (orgInvites) {
      const newOrgInvites = [...orgInvites];
      if (index > -1) {
        newOrgInvites.splice(index, 1, newOrgInvite);
      } else {
        newOrgInvites.unshift(newOrgInvite);
      }
      queryClient.setQueryData<InviteList>(orgInvitesQueryKey, newOrgInvites);
    } else {
      queryClient.setQueryData<InviteList>(orgInvitesQueryKey, [newOrgInvite]);
    }
    return currentOrgInvite;
  };

  const removeCachedOrgInvite = (id: number): Invite | undefined => {
    const [currentOrgInvite, index] = getCachedOrgInviteById(id);
    if (orgInvites && currentOrgInvite) {
      const newOrgInvites = [...orgInvites];
      newOrgInvites.splice(index, 1);
      queryClient.setQueryData<InviteList>(orgInvitesQueryKey, newOrgInvites);
      return currentOrgInvite;
    }
    return undefined;
  };

  const deleteOrgInviteMutation = useMutation(
    (id: number) => inviteService.deleteInvite(id),
    {
      retry: false,
      onMutate: async (id: number) => {
        const [currentOrgInvite] = getCachedOrgInviteById(id);
        if (currentOrgInvite) removeCachedOrgInvite(currentOrgInvite.id);
        return { previousOrgInvite: currentOrgInvite };
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(orgInvitesQueryKey);
      },
    }
  );

  const createOrgInviteMutation = useMutation(
    (variables: NewOrgInvite) => {
      const { status, ...inviteData } = variables;
      if (status === "new") {
        return inviteService.inviteNewUser({ ...inviteData });
      }
      return inviteService.inviteExistingUser({ ...inviteData });
    },
    {
      retry: false,
      onMutate: async (variables: NewInviteData) => {
        const newOrgInvite: Invite = {
          id: -new Date().getTime(),
          user_id: -1,
          invited_by_user_id: user?.id || 0,
          invited_by_email: user?.email || "",
          invited_by_name: user?.name || "",
          organization_id: organizationId || 0,
          organization_name: "Temporary org",
          sent_at: new Date().toISOString(),
          email: variables.email,
          accepted_at: "",
          declined_at: "",
        };
        upsertCachedOrgInvite(newOrgInvite);
        return { newId: newOrgInvite.id };
      },
      onSuccess: (response, payload, context) => {
        if (context?.newId) {
          removeCachedOrgInvite(context.newId);
        }
        if (response?.data) {
          upsertCachedOrgInvite(response.data);
        }
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(orgInvitesQueryKey);
      },
    }
  );

  return {
    orgInvites,
    orgInvitesQueryKey,
    orgInvitesIsLoading,
    getCachedOrgInviteById,
    deleteOrgInviteMutation,
    createOrgInviteMutation,
    upsertCachedOrgInvite,
  };
};

interface UseUserInvitesResult {
  userInvites: InviteList | undefined;
  userInvitesQueryKey: string[];
  userInvitesIsLoading: boolean;
  getCachedUserInviteById: (
    id: number
  ) => [item: Readonly<Invite> | undefined, index: Readonly<number>];
  declineUserInviteMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    number,
    {
      previousUserInvite: Invite | undefined;
    }
  >;
  acceptUserInviteMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    number,
    {
      previousUserInvite: Invite | undefined;
    }
  >;
}

export const useUserInvites = (
  user: AccessTokenResponse | undefined
): UseUserInvitesResult => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const userInvitesQueryKey = [`user/${user?.id}/invites`];

  const { data: userInvites, isLoading: userInvitesIsLoading } = useQuery<
    unknown,
    unknown,
    InviteList,
    any
  >(
    userInvitesQueryKey,
    () =>
      inviteService
        .userInvitesList(user?.id as number)
        .then(({ data }) => data),
    {
      enabled: !!user,
      placeholderData: undefined,
    }
  );

  const userInviteIndexById = useMemo(
    () =>
      userInvites?.reduce((lut: { [id: number]: number }, invite, index) => {
        // eslint-disable-next-line no-param-reassign
        lut[invite.id] = index;
        return lut;
      }, {}),
    [userInvites]
  );

  const getCachedUserInviteById: (
    id: number
  ) => [item: Readonly<Invite> | undefined, index: Readonly<number>] =
    useCallback(
      (id: number) =>
        userInvites && userInviteIndexById && id in userInviteIndexById
          ? [userInvites[userInviteIndexById[id]], userInviteIndexById[id]]
          : [undefined, -1],
      [userInviteIndexById]
    );

  const upsertCachedUserInvite = (
    newUserInvite: Invite
  ): Invite | undefined => {
    const [currentOrgInvite, index] = getCachedUserInviteById(newUserInvite.id);
    if (userInvites) {
      const newUserInvites = [...userInvites];
      if (index > -1) {
        newUserInvites.splice(index, 1, newUserInvite);
      } else {
        newUserInvites.unshift(newUserInvite);
      }
      queryClient.setQueryData<InviteList>(userInvitesQueryKey, newUserInvites);
    } else {
      queryClient.setQueryData<InviteList>(userInvitesQueryKey, [
        newUserInvite,
      ]);
    }
    return currentOrgInvite;
  };

  const declineUserInviteMutation = useMutation(
    (id: number) => inviteService.declineInvite(id),
    {
      retry: false,
      onMutate: (id: number) => {
        const [currentUserInvite] = getCachedUserInviteById(id);
        return { previousUserInvite: currentUserInvite };
      },
      onSuccess: (response, payload, context) => {
        if (response?.data?.success && context?.previousUserInvite) {
          upsertCachedUserInvite({
            ...context?.previousUserInvite,
            declined_at: new Date().toISOString(),
          });
        }
      },
      onError: (error: any) => {
        if (error?.response?.data?.error === InviteErrors.INVITE_NOT_FOUD) {
          inviteWarning("Invite no longer exists", dispatch);
        } else {
          handleAxiosError(error as AxiosError, dispatch);
        }
        queryClient.invalidateQueries([`user/${user?.id}/notifications`]);
        queryClient.invalidateQueries(userInvitesQueryKey);
      },
    }
  );

  const acceptUserInviteMutation = useMutation(
    (id: number) => inviteService.acceptInvite(id),
    {
      retry: false,
      onMutate: (id: number) => {
        const [currentUserInvite] = getCachedUserInviteById(id);
        return { previousUserInvite: currentUserInvite };
      },
      onError: (error: any) => {
        if (error?.response?.data?.error === InviteErrors.INVITE_NOT_FOUD) {
          inviteWarning("Invite no longer exists", dispatch);
        } else {
          handleAxiosError(error as AxiosError, dispatch);
        }
        queryClient.invalidateQueries([`user/${user?.id}/notifications`]);
        queryClient.invalidateQueries(userInvitesQueryKey);
      },
      onSuccess: (response, payload, context) => {
        if (response?.data && context?.previousUserInvite) {
          upsertCachedUserInvite({
            ...context?.previousUserInvite,
            accepted_at: new Date().toISOString(),
          });
        }
      },
    }
  );

  return {
    userInvites,
    userInvitesQueryKey,
    userInvitesIsLoading,
    getCachedUserInviteById,
    declineUserInviteMutation,
    acceptUserInviteMutation,
  };
};
