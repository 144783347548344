import React, { useState } from "react";
import { DocumentReadResponse } from "models/api/response.types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CollectionSelector from "components/helpers/CollectionSelector";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import { useDocumentCollections } from "api/documentCollectionService";
import { addAlert } from "store/features/general/slice";

const AddToCollectionDialog: React.FC<{
  documents: DocumentReadResponse[];
  setOpen: (open: boolean) => void;
}> = ({ documents, setOpen }) => {
  const dispatch = useDispatch();
  const [collectionId, setCollectionId] = useState(-1);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { getCachedCollectionById, updateCollectionMutation } =
    useDocumentCollections(currentOrganizationId);

  const addDocumentsToCollection = () => {
    const [collection] = getCachedCollectionById(collectionId);
    if (collection) {
      updateCollectionMutation.mutate(
        {
          collections: [
            {
              id: collection.id,
              document_ids: [
                ...collection.document_ids,
                ...documents.map((doc) => doc.id),
              ],
            },
          ],
        },
        {
          onSuccess: () => {
            dispatch(
              addAlert({
                severity: "success",
                autoHideDuration: 5000,
                alert: {
                  message: `Documents(s) added to ${collection.name}.`,
                },
              })
            );
            setOpen(false);
          },
          onError: () => {
            setOpen(false);
          },
        }
      );
    }
  };

  return (
    <Dialog
      open
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Add document(s) to collection
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CollectionSelector
          includeDefaultCollections
          canCreateCollection
          currentParentId={collectionId}
          collectionToUse={(id?: number) => {
            setCollectionId(id || -1);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={collectionId === -1}
          size="medium"
          color="primary"
          onClick={() => {
            addDocumentsToCollection();
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddToCollectionDialog;
