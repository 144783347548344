import { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentOrganizationId,
  selectSessionId,
  selectUser,
} from "store/features/session/slice";

export interface IUserDataTelemetry {
  user_id: number;
  organization_id: number;
  session_uuid: string;
  action: string;
  payload_json: string;
  timestamp: string;
}

export interface IEventProps {
  session_uuid: string;
  user_id: number;
  organization_id: number;
  action: telemetryAction;
  payload_json: any;
}

export enum telemetryAction {
  onboarding_flow = "onboarding_flow",
  select_documents = "select_documents",
  switch_organization = "switch_organization",
  switching_tab = "switching_tab",
  page_exit = "page_exit",
  upload_files = "upload_files",
  open_document_viewer = "open_document_viewer",
  login = "login",
  execute_search = "execute_search",
  logout = "logout",
  file_already_exist = "file_already_exist",
  runtime_error = "runtime_error",

  // ImportReferencesDialog
  ImportReferencesDialogOpen = "ImportReferencesDialogOpen",
  ImportReferencesDialogClose = "ImportReferencesDialogClose",
  ImportReferencesDialogApply = "ImportReferencesDialogApply",

  // DocumentListItem
  DocumentListItemClickEdit = "DocumentListItemClickEdit",
  DocumentListItemClickReadLater = "DocumentListItemClickReadLater",
  DocumentListItemClickFavorites = "DocumentListItemClickFavorites",

  // Browser
  BrowserSwitchView = "BrowserSwitchView",
}

export const logUserEvent = (data: IEventProps): void => {
  const event: IUserDataTelemetry = {
    ...data,
    payload_json: JSON.stringify(data.payload_json),
    timestamp: new Date().toISOString(),
  };
  const userTelemetry = JSON.parse(
    localStorage.getItem("userTelemetry") || "[]"
  );
  if (!Array.isArray(userTelemetry)) {
    localStorage.setItem("userTelemetry", JSON.stringify([event]));
  } else {
    localStorage.setItem(
      "userTelemetry",
      JSON.stringify([...userTelemetry, event])
    );
  }
};
const useTelemetry = () => {
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const sessionId = useSelector(selectSessionId);

  const logAction = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (action: telemetryAction, payload: any) => {
      const event: IEventProps = {
        user_id: 0,
        organization_id: 0,
        action,
        session_uuid: sessionId,
        payload_json: {
          ...payload,
        },
      };
      if (user) {
        event.user_id = user.id;
      } else if (payload.user_id) {
        event.user_id = payload.user_id;
      }
      if (currentOrganizationId) {
        event.organization_id = currentOrganizationId;
      } else if (payload.organization_id) {
        event.organization_id = payload.organization_id;
      }
      logUserEvent(event);
    },
    [user, currentOrganizationId, sessionId]
  );
  return { logAction } as const;
};

export default useTelemetry;
