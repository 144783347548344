import React, { useState } from "react";
import {
  IconButton,
  ListItem,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import { Tag as TagInterface } from "models/api/response.types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InlineTagById from "components/Tags/InlineTagById";
import { useSelector } from "react-redux";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import { useTags } from "api/tagService";
import hexToHSL from "utils/hexToHSL";

export const ListItemContainer = styled(ListItem)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: "6px",
  paddingBottom: "6px",
  "& .tagCount": {
    marginRight: "10px",
    width: "10%",
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .tagData": {
    marginRight: "10px",
    width: "75%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .colorBadge": {
    position: "relative",
    width: "1rem",
    minWidth: "1rem",
    minHeight: "1rem",
    height: "1rem",
    borderRadius: "0.1rem",
    border: "1px solid rgba(127,127,127,.2)",
    margin: "0 4px",
    cursor: "pointer",
    "&:hover": {
      outline: " rgb(255,255,255) solid 2px",
      boxShadow: "rgb(0 0 0 / 25%) 0px 0px 5px 2px",
    },
    "& > svg": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      width: "0.85rem",
      height: "0.85rem",
      opacity: 0,
    },
  },
  "&:hover": {
    "& .colorBadge > svg": {
      opacity: 1,
    },
  },
  "& .tagActions": {
    display: "flex",
    width: "10%",
    justifyContent: "flex-end",
  },
}));

const Tag: React.FC<{
  tag: TagInterface;
  selectedTag: TagInterface | undefined;
  setSelectedTag: (tag: TagInterface) => void;
  setUpdateTag: (open: boolean) => void;
}> = ({ tag, selectedTag, setSelectedTag, setUpdateTag }) => {
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { deleteTagMutation } = useTags(currentOrganizationId);
  const [onHover, setOnHover] = useState<boolean>(false);
  const handleClickColorBadge = () => {
    setSelectedTag(tag);
    setUpdateTag(true);
  };
  const { l: luminosity } = hexToHSL(tag.color);
  const foreground =
    luminosity < 60 ? "rgba(255,255,255,0.8)" : "rgba(0,0,0,0.8)";

  return (
    <ListItemContainer
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      style={{
        backgroundColor:
          onHover || selectedTag?.id === tag.id ? "rgba(0, 0, 0, 0.04)" : "",
      }}
    >
      <ListItemText className="tagCount">
        <Typography variant="body2">{`(${tag.document_count})`}</Typography>
      </ListItemText>
      <div className="tagData">
        <InlineTagById
          id={tag?.id}
          key={tag?.id}
          organizationId={tag.organization_id}
        />
        <div
          className="colorBadge"
          style={{
            backgroundColor: tag.color,
          }}
          onClick={handleClickColorBadge}
        >
          <EditIcon
            fontSize="small"
            sx={{
              color: foreground,
            }}
          />
        </div>
      </div>
      <div className="tagActions">
        {onHover && (
          <IconButton
            onClick={() => {
              deleteTagMutation.mutate(tag.id);
            }}
            sx={{
              padding: 0,
            }}
          >
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    </ListItemContainer>
  );
};

export default Tag;
