import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useTags } from "api/tagService";
import { useDocuments } from "api/documentService";
import { DocumentReadResponse, TagList } from "models/api/response.types";
import { useReactQueryTagInput } from "utils/useTagInput";
import TagInput from "components/Tags/TagInput";

interface EditDocumentTagDialogProps {
  document: DocumentReadResponse;
  setOpen: (open: boolean) => void;
}
const EditDocumentTagsDialog: React.FC<EditDocumentTagDialogProps> = ({
  document,
  setOpen,
}) => {
  const { tags: orgTags } = useTags(document.organization_id);
  const { updateDocumentMutation } = useDocuments(document.organization_id);

  const handleSaveEditedTagIds = (tagIds: number[], different: boolean) => {
    if (different && document) {
      updateDocumentMutation.mutate({
        id: document.id,
        payload: { tag_ids: tagIds },
      });
    }
  };

  const [tags, setTags, saveSelectedTagIds] = useReactQueryTagInput({
    tagOptions: orgTags as TagList,
    defaultTagIds: document?.tag_ids || [],
    organizationId: document.organization_id,
    saveTagIds: handleSaveEditedTagIds,
  });

  const handleSaveClick = () => {
    const documentIds: number[] = [];
    if (document.id) {
      documentIds.push(document.id);
    }
    saveSelectedTagIds(documentIds);
  };

  return (
    <Dialog
      open
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
    >
      <DialogTitle>
        Edit document tags
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TagInput
          disabled={false}
          setValue={setTags}
          selectedTags={tags}
          dropDownTags={orgTags as TagList}
          handleSaveClick={handleSaveClick}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditDocumentTagsDialog;
