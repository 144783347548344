/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useEffect, useState } from "react";
import { useDocuments } from "api/documentService";
import {
  AnnotationDetailed,
  DocumentReadResponse,
  Notification,
  Tag,
} from "models/api/response.types";
import { useSelector } from "react-redux";
import io, { Socket } from "socket.io-client";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { useNotifications } from "api/notificationService";
import { useOrgInvites, useUserInvites } from "api/inviteService";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useTags } from "api/tagService";
import { useUsers } from "api/userService";
import { useDocumentCollections } from "api/documentCollectionService";
import { useOrganizations } from "api/organizationService";

export const socketChannels = {
  JOIN_ORG_ROOM: "joinOrgRoom",
  JOIN_PROJ_ROOM: "joinProjRoom",
  JOIN_USER_ROOM: "joinUserRoom",
  LEAVE_ORG_ROOM: "leaveOrgRoom",
  LEAVE_PROJ_ROOM: "leaveProjRoom",
  DISCONNECT: "disconnect",
  DOCUMENT_CONN: "document update",
  ORGANIZATION_CONN: "organization update",
  NOTIFICATION_CONN: "notification update",
  ANNOTATION_CONN: "annotation update",
  CCOLLECTION_CONN: "collection update",
  TAG_CONN: "tag update",
  COMMENT_CONN: "comment update",
};

interface ISocketProps {
  organizationId?: number;
  children: JSX.Element;
}

interface IProjectMessage {
  action: string;
  message: { [key: string]: any };
}

const SocketContext = createContext<{ socket: Socket | undefined }>({
  socket: undefined,
});

const SocketsProvider: React.FC<ISocketProps> = (props) => {
  const { organizationId } = props;
  const queryClient = useQueryClient();
  const user = useSelector(selectUser);
  const currentOrganizationId =
    organizationId || useSelector(selectCurrentOrganizationId);
  const { organizationQueryKey } = useOrganizations(user?.id);
  const { removeCachedDocument, upsertCachedDocument, removeCachedDocuments } =
    useDocuments(currentOrganizationId);
  const { removeCachedCollection, collectionsQueryKey } =
    useDocumentCollections(currentOrganizationId);
  const { removeCachedTag, upsertCachedTag } = useTags(currentOrganizationId);
  const { usersQueryKey } = useUsers(currentOrganizationId);
  const { upsertCachedNotification } = useNotifications(user);
  const { userInvitesQueryKey } = useUserInvites(user);
  const { orgInvitesQueryKey } = useOrgInvites(currentOrganizationId);
  const [previousOrganizationId, setPreviousOrganizationId] = useState<
    number | undefined
  >(undefined);
  const [socket, setSocket] = useState<Socket | undefined>(undefined);

  // for socket close connection
  useEffect(() => {
    return () => {
      socket?.removeAllListeners();
      socket?.close();
    };
  }, [socket]);

  // for joining/leaving user room
  useEffect(() => {
    const { baseURL } = axios.defaults;
    if (user && !socket && baseURL) {
      setSocket(
        io(baseURL, {
          transports: ["websocket", "polling"],
          auth: { token: user.access_token },
        })
      );
    }
    if (user && socket) {
      socket.emit(socketChannels.JOIN_USER_ROOM, user.id);
    }
  }, [user, socket, axios]);

  // for joining/leaving organization room
  useEffect(() => {
    if (currentOrganizationId && socket) {
      if (
        currentOrganizationId !== previousOrganizationId &&
        previousOrganizationId
      ) {
        socket.emit(socketChannels.LEAVE_ORG_ROOM, previousOrganizationId);
      }
      setPreviousOrganizationId(currentOrganizationId);
      socket.emit(socketChannels.JOIN_ORG_ROOM, currentOrganizationId);
    }
  }, [currentOrganizationId, socket]);

  // connection for document update/create/delete
  socket?.on(socketChannels.DOCUMENT_CONN, (data: IProjectMessage) => {
    const { action, message } = data;
    if (message) {
      if (action === "update" || action === "create") {
        // if(action === "update" && [""])
        const newDocument = message as DocumentReadResponse;
        upsertCachedDocument(newDocument);
      } else if (action === "delete" && message.document_id) {
        if (Array.isArray(message.document_id)) {
          const documentIds = message.document_id as number[];
          removeCachedDocuments(documentIds);
        } else {
          const docId = message.document_id as number;
          removeCachedDocument(docId);
        }
      }
    }
  });

  // connection for annotation update/create/delete
  socket?.on(socketChannels.ANNOTATION_CONN, (data: IProjectMessage) => {
    const { message } = data;
    if (message) {
      const newAnnotation = message as AnnotationDetailed;
      // annotations for current doc
      queryClient.invalidateQueries([
        `document/${newAnnotation.document_id}/annotations`,
      ]);
      // annotations for current org
      queryClient.invalidateQueries([
        `organization/${currentOrganizationId}/annotations`,
      ]);
    }
  });

  // connection for notifications
  socket?.on(socketChannels.NOTIFICATION_CONN, (data: IProjectMessage) => {
    const { action, message } = data;
    if (action === "create" && message?.creator?.user?.id !== user?.id) {
      if (message?.type === "invite") {
        queryClient.invalidateQueries(userInvitesQueryKey);
      } else {
        const notification = message as Notification;
        upsertCachedNotification(notification);
      }
    }
    // future logic for update/delete goes here
  });

  // connection for comments
  socket?.on(socketChannels.COMMENT_CONN, (data: IProjectMessage) => {
    const { message } = data;
    if (message.annotation_id) {
      queryClient.invalidateQueries([
        `annotation/${message.annotation_id}/comments`,
      ]);
    }
  });

  // connection for organization events
  socket?.on(socketChannels.ORGANIZATION_CONN, () => {
    queryClient.invalidateQueries(usersQueryKey);
    queryClient.invalidateQueries(organizationQueryKey);
    queryClient.invalidateQueries(orgInvitesQueryKey);
  });

  // connection for collections
  socket?.on(socketChannels.CCOLLECTION_CONN, (data: IProjectMessage) => {
    const { action, message } = data;
    if (action === "update" || action === "create") {
      queryClient.invalidateQueries(collectionsQueryKey);
    } else if (action === "delete" && message.collection_id) {
      const collId = message.collection_id as number;
      removeCachedCollection(collId);
    }
  });

  // connection for tags
  socket?.on(socketChannels.TAG_CONN, (data: IProjectMessage) => {
    const { action, message } = data;
    if (action === "update" || action === "create") {
      const newTag = message as Tag;
      upsertCachedTag(newTag);
    } else if (action === "delete" && message.tag_id) {
      const tagId = message.tag_id as number;
      removeCachedTag(tagId);
    }
  });

  socket?.on("connect_error", (err) => {
    console.log(err.message); // prints the message associated with the error
  });

  return <SocketContext.Provider value={{ socket }} {...props} />;
};

export default SocketsProvider;
