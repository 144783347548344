import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { organizationService } from "api";
import { useDispatch, useSelector } from "react-redux";
import handleAxiosError from "utils/handleAxiosAlert";
import { Organization } from "models/api/response.types";
import {
  selectCurrentOrganizationId,
  selectUser,
  setCurrentOrganizationId,
} from "store/features/session/slice";
import { addAlert } from "store/features/general/slice";
import { useOrganizations } from "api/organizationService";
import { useQueryClient } from "@tanstack/react-query";
import { useUsers } from "api/userService";

const LeaveOrganizationDialog: React.FC<{
  organization?: Organization;
  close: () => void;
}> = ({ organization, close }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { users } = useUsers(organization?.id);
  const { organizationQueryKey } = useOrganizations(user?.id);
  const [show, setShow] = useState<boolean>(false);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);

  useEffect(() => {
    if (organization) {
      setShow(true);
    }
  }, [organization]);

  const hide = () => {
    setShow(false);
    setTimeout(() => {
      close();
    }, 300);
  };

  const onSubmit = () => {
    if (organization) {
      organizationService
        .leaveOrganization(organization.id)
        .then(() => {
          queryClient.invalidateQueries(organizationQueryKey);
          dispatch(
            addAlert({
              severity: "success",
              autoHideDuration: 3000,
              alert: {
                message: `You left ${organization.name}`,
              },
            })
          );
          if (currentOrganizationId === organization.id) {
            dispatch(setCurrentOrganizationId(undefined));
          }
          hide();
        })
        .catch((reason) => {
          handleAxiosError(reason, dispatch);
        });
    }
  };
  return (
    <Dialog open={show} onClose={hide} fullWidth maxWidth="sm">
      <DialogTitle>
        Are you sure you want to leave {organization?.name}?
        <IconButton onClick={hide}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {users?.length === 1 ? (
          <Typography variant="body1" color="textSecondary">
            You are the only member of this workspace. Leaving it will
            irrevocably delete all data.
          </Typography>
        ) : (
          <Typography variant="body1" color="textSecondary">
            You will need to be invited to re-join the workspace after you
            leave.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          color="error"
          size="medium"
          variant="contained"
        >
          Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeaveOrganizationDialog;
