/* eslint-disable import/prefer-default-export */
import { DocumentReadResponse } from "models/api/response.types";

export const isTable = (document: DocumentReadResponse) => {
  return ["csv", "xlsx", "xls"].includes(document.doctype);
};

export const isStub = (document: DocumentReadResponse) => {
  return document.doctype === "stub";
};

export const getDocumentStatus = (document: DocumentReadResponse) => {
  const statuses: { [key: string]: boolean } = {
    ready: false,
    failed: false,
    in_progress: false,
    ocr_in_progress: false,
    can_be_viewed: false,
  };
  const { status } = document;
  const { file_extract, rasterization, ocr, grobid_metadata } = status;
  const currentTime = new Date();
  const canBeViewed = !isTable(document) && !isStub(document);
  const createdAtTime = new Date(document.created_at);
  const timeDifference = currentTime.getTime() - createdAtTime.getTime();
  const timeThreshold = 1 * 60 * 1000;

  // if document of type stub straight proceed to ready as true
  if (isStub(document)) {
    statuses.ready = true;
    return statuses;
  }

  // in case of grobid/rasterization check only first minute of document life
  if (
    canBeViewed &&
    (Object.keys(status).length === 0 ||
      (timeDifference < timeThreshold &&
        (!grobid_metadata || grobid_metadata < 2)) ||
      !rasterization ||
      rasterization < 2)
  ) {
    statuses.in_progress = true;
  }

  // for tables check if ready
  if (!canBeViewed && file_extract < 2) {
    statuses.in_progress = true;
  }

  // if ocr in progress
  if (canBeViewed && document.use_ocr && ocr < 2) {
    statuses.ocr_in_progress = true;
    statuses.in_progress = true;
  }

  // if file_extract or rasterization equals to 3, document failed to load
  if (file_extract === 3 || (rasterization === 3 && canBeViewed)) {
    statuses.failed = true;
    statuses.in_progress = false;
  }

  // rasterization equals 2 equals document success
  if (
    ((rasterization === 2 && canBeViewed) || file_extract === 2) &&
    !statuses.in_progress &&
    !status.failed
  ) {
    statuses.ready = true;
  }

  // condition when document can be viewed by user
  if (
    statuses.ready &&
    canBeViewed &&
    !statuses.ocr_in_progress &&
    !statuses.in_progress
  ) {
    statuses.can_be_viewed = true;
  }

  return statuses;
};
