import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Search, ClearOutlined } from "@mui/icons-material";
import { documentService } from "api";
import { IState } from "store/reducers";
import {
  DocumentSearchResponse,
  Token,
  Snippet,
  DocumentReadResponse,
} from "models/api/response.types";
import handleAxiosError from "utils/handleAxiosAlert";
import {
  selectFocusLookUpInput,
  setFocusLookUpInput,
} from "store/features/documentViewer/slice";

const SearchBox: React.FC<{
  setResults: React.Dispatch<
    React.SetStateAction<{
      tokens?: Token[];
      snippets?: Snippet[];
    }>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  document: DocumentReadResponse;
  view: string;
  searchedQuery: string;
  setSearchedQuery: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  setResults,
  setLoading,
  document,
  view,
  searchedQuery,
  setSearchedQuery,
}) => {
  const dispatch = useDispatch();
  const focusLookupInput = useSelector(selectFocusLookUpInput);
  const globalQuery = useSelector(
    (state: IState) => state.browser.search.query
  );
  const [query, setQuery] = useState("");
  const firstRender = useRef(true);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const memoizedFetchResults = useCallback(
    (queryParam: string) => {
      if (document.doctype !== "stub") {
        setLoading(true);
        documentService
          .fetchDocumentSearch(document.id, encodeURIComponent(queryParam))
          .then(({ data }: { data: DocumentSearchResponse }) => {
            setResults(data);
            // store searched query in Sidebar.tsx, reuse it when switching between tabs and documents
            setSearchedQuery(queryParam);
          })
          .catch((error) => {
            handleAxiosError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [document, setResults]
  );

  useEffect(() => {
    searchInputRef?.current?.focus();
    searchInputRef?.current?.select();
    dispatch(setFocusLookUpInput(false));
  }, [focusLookupInput]);

  // initialize search box on first render, query: stored searchedQuery or globalQuery.
  useEffect(() => {
    if (searchedQuery !== "") {
      memoizedFetchResults(searchedQuery);
      setQuery(searchedQuery);
    } else {
      memoizedFetchResults(globalQuery);
      setQuery(globalQuery);
    }
  }, []);

  // globalQuery changes trigger re-render
  useEffect(() => {
    // skip first render to prevent globalQuery overriding searchedQuery
    if (firstRender.current) {
      firstRender.current = false;
    } else if (globalQuery !== searchedQuery) {
      memoizedFetchResults(globalQuery);
      setQuery(globalQuery);
    }
  }, [globalQuery]);

  // everytime documentId is changed, perform a search on current query in search box.
  useEffect(() => {
    if (query !== "") {
      memoizedFetchResults(query);
    }
  }, [document]);

  const handleChangeQuery = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuery(event.target.value);
    if (event.target.value === "") {
      setResults({
        snippets: undefined,
        tokens: undefined,
      });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      if (query !== "") {
        memoizedFetchResults(query);
      }
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        ...(view === "browse"
          ? {
              display: "none",
              paddingBottom: "1rem",
            }
          : {
              display: "flex",
              padding: "1rem",
            }),
        flexDirection: "column",
      }}
    >
      <OutlinedInput
        inputRef={view !== "browse" ? searchInputRef : null}
        color="primary"
        size="small"
        fullWidth
        className="input"
        disabled={view === "browse"}
        value={query}
        onChange={handleChangeQuery}
        placeholder="Search"
        onKeyDown={handleKeyPress}
        startAdornment={
          <InputAdornment color="primary" position="start">
            <Search fontSize="small" />
          </InputAdornment>
        }
        endAdornment={
          <IconButton
            size="small"
            disabled={view === "browse"}
            onClick={() => {
              setQuery("");
              setResults({
                snippets: undefined,
                tokens: undefined,
              });
            }}
          >
            <ClearOutlined fontSize="small" />
          </IconButton>
        }
      />
      {view !== "browse" && (
        <Typography
          color="textSecondary"
          sx={{ marginTop: "0.5rem" }}
          variant="body2"
        >
          Surround “literal matches” with double quotes
        </Typography>
      )}
    </Box>
  );
};

export default SearchBox;
