import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  InputLabel,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import clsx from "clsx";
import { findMatches, parseBibTeX } from "utils/bibtex";
import {
  DocumentCollection,
  OrganizationSchema,
} from "models/api/response.types";
import {
  defaultOrganizationSchema,
  EntryName,
  entryTypes,
  sortSchemaOrder,
} from "utils/bibtexSchema";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import MetaField from "components/Tabs/DocumentPropertyTab/MetaField";
import displayTextWithMath from "utils/displayTextWithMath";
import detexify from "utils/detexify";
import documentService, { useDocuments } from "api/documentService";
import { useDispatch, useSelector } from "react-redux";
import { addAlert } from "store/features/general/slice";
import { useDocumentCollections } from "api/documentCollectionService";
import { useOrganizations } from "api/organizationService";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import CollectionSelector from "components/helpers/CollectionSelector";
import { defaultCollectionsTypes } from "models/components/Browse.models";

const Wrapper = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  minHeight: "500px",
  maxHeight: "800px",
  width: "100%",
  padding: "1rem 2rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  "& .highlighted-text": {
    color: theme.palette.secondary.main,
  },
  "& .identifier-form": {
    display: "flex",
    alignItems: "flex-end",
    gap: "1rem",
    "& .MuiInputLabel-root": {
      color: "#272727",
      position: "relative",
      fontSize: "14px",
      transform: "none",
      marginBottom: "8px",
    },
    "& #identifier": {
      background: theme.background.light,
      padding: "11px 14px",
    },
    "& .search-button": {
      minWidth: 0,
      padding: "0.5rem",
      "& .MuiButton-startIcon": {
        margin: 0,
      },
      "&.expandMargin": {
        marginBottom: "1.6rem",
      },
    },
  },
  "& .doc-properties": {
    padding: "3px",
    overflow: "auto",
    "& .description-field": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
    },
    "& .organization-schema": {
      "& .showAllFields": {
        borderRadius: 0,
        width: "100%",
        padding: "0.5rem",
      },
    },
    "& .descriptionTextArea": {
      padding: "8px 16px 16px",
      lineHeight: 1.7,
      color: "#6C6C6C",
      fontFamily: "inherit",
      fontSize: "14px",
      "& mjx-math": {
        whiteSpace: "break-spaces",
      },
      "&:focus": {
        outline: "none",
        border: "none",
      },
    },
    "& .MuiAccordion-root": {
      borderRadius: "4px",
      boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
      "&::before": {
        display: "none",
      },
    },
    "& .MuiAccordion-root:not(:last-child)": {
      marginBottom: "1rem",
    },
  },
  "& .collection-selector": {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
}));

const ManualEntryDialog: React.FC<{
  option: string;
}> = ({ option }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { getCachedOrganizationById } = useOrganizations(user?.id);
  const { collections, getCachedCollectionById, updateCollectionMutation } =
    useDocumentCollections(currentOrganizationId);
  const { upsertCachedDocuments, documents } = useDocuments(
    currentOrganizationId
  );
  const [organization] = getCachedOrganizationById(currentOrganizationId || -1);
  const [uploadCollection, setUploadCollection] = useState<
    DocumentCollection | undefined
  >(undefined);
  const [inputValue, setInputValue] = useState<string>("");
  const [search, setSearch] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [referenceMeta, setReferenceMeta] = useState<{ [key: string]: any }>(
    {}
  );
  const [showEmpty, setShowEmpty] = useState<boolean>(false);
  const showInput = option === "identifier";
  const [selectedCollectionId, setSelectedCollectionId] = useState<
    number | undefined
  >(undefined);
  const [fieldsReady, setFieldsReady] = useState<boolean>(
    option === "manually"
  );
  const [focusDescription, setFocusDescription] = useState<boolean>(false);
  const [creatingReference, setCreatingReference] = useState<boolean>(false);

  // set uploadCollection when selectedCollection is changed
  useEffect(() => {
    if (collections && selectedCollectionId) {
      const [collection] = getCachedCollectionById(selectedCollectionId);
      if (collection && !defaultCollectionsTypes.includes(collection?.name)) {
        setUploadCollection(collection);
      } else {
        setUploadCollection(undefined);
      }
    } else {
      setUploadCollection(undefined);
    }
  }, [selectedCollectionId, collections]);

  const exportReferenceBibtex = (reference: string) => {
    fetch(`https://translation-server.petal.org/export?format=bibtex`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: reference,
    })
      .then((response) => response.text())
      .then((data) => {
        setSearch(false);
        const bibtexEntries = parseBibTeX(data).map((entry) =>
          entry
            ? {
                ...entry.properties,
                entrytype: entry.entrytype,
                citekey: entry.citekey,
              }
            : {}
        );
        setReferenceMeta(bibtexEntries[0]);
        setFieldsReady(true);
      })
      .catch(() => {
        setSearch(false);
        setErrorMessage(true);
      });
  };

  const searchReference = () => {
    setSearch(true);
    fetch(`https://translation-server.petal.org/search`, {
      method: "post",
      headers: {
        "Content-Type": "text/plain", // IMPORTANT!
      },
      body: inputValue,
    })
      .then((response) => response.json())
      .then((data) => {
        exportReferenceBibtex(JSON.stringify(data));
      })
      .catch(() => {
        setSearch(false);
        setErrorMessage(true);
      });
  };

  const addReference = () => {
    if (organization) {
      const matches = findMatches(documents, referenceMeta);
      if (matches.length > 0 && matches[0].score > 0.95) {
        if (uploadCollection) {
          updateCollectionMutation.mutate({
            collections: [
              {
                id: uploadCollection.id,
                document_ids: [...uploadCollection.document_ids, matches[0].id],
              },
            ],
          });
        }
        setCreatingReference(false);
        dispatch(
          addAlert({
            severity: "warning",
            autoHideDuration: 5000,
            alert: {
              message: "Reference already exist in current workspace.",
            },
          })
        );
      } else {
        documentService
          .createDocumentStubs(currentOrganizationId || -1, [
            {
              meta_json: JSON.stringify(referenceMeta || {}),
              ui_json: JSON.stringify({ read_by: [] }),
            },
          ])
          .then((response) => {
            dispatch(
              addAlert({
                severity: "success",
                autoHideDuration: 5000,
                alert: {
                  message: "Reference has been created.",
                },
              })
            );
            upsertCachedDocuments(response.data);
            if (uploadCollection) {
              updateCollectionMutation.mutate({
                collections: [
                  {
                    id: uploadCollection.id,
                    document_ids: [
                      ...uploadCollection.document_ids,
                      response.data[0].id,
                    ],
                  },
                ],
              });
            }
            setCreatingReference(false);
          })
          .catch(() => {
            setCreatingReference(false);
            dispatch(
              addAlert({
                severity: "error",
                autoHideDuration: 5000,
                alert: {
                  message: "Adding process fails. Please try again.",
                },
              })
            );
          });
      }
    }
  };

  const getOrganizationSchemaFields = () => {
    let filteredSchema;
    if (organization) {
      filteredSchema = organization.schema.filter(({ name }) => {
        if (
          showEmpty === false &&
          !entryTypes[referenceMeta.entrytype as EntryName]?.required.includes(
            name
          )
        ) {
          if (
            referenceMeta[name] === undefined ||
            referenceMeta[name] === null ||
            referenceMeta[name] === ""
          ) {
            return false;
          }
        }
        return true;
      });
    } else {
      const defaultSchema: OrganizationSchema[] = [];
      const extraSchema: OrganizationSchema[] = [];
      Object.keys(referenceMeta).forEach((property: any) => {
        const index = defaultOrganizationSchema.findIndex(
          (defaultProp) => defaultProp.name === property
        );
        if (index > -1) {
          defaultSchema.push(defaultOrganizationSchema[index]);
        } else {
          extraSchema.push({
            name: property,
            label: property,
            type: Array.isArray(referenceMeta[property]) ? "list" : "text",
            default: false,
          });
        }
      });
      filteredSchema = [...defaultSchema, ...extraSchema];
    }

    const sortedSchema: OrganizationSchema[] = sortSchemaOrder(
      filteredSchema,
      referenceMeta.entrytype
    );
    return sortedSchema.map(({ name, type, label }) => {
      return (
        <MetaField
          disabled={false}
          key={`newDocument.${name}`}
          name={name}
          type={type}
          label={label}
          currentValue={referenceMeta[name] || ""}
          onChange={(newValue: unknown) => {
            const newRefMeta = { ...referenceMeta };
            newRefMeta[name] = newValue;
            setReferenceMeta(newRefMeta);
          }}
        />
      );
    });
  };

  return (
    <Wrapper>
      {showInput && (
        <Box className="identifier-form">
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="indentifier">
              Identifiers (ArXivID, DOI or PMID)
            </InputLabel>
            <TextField
              id="identifier"
              color="primary"
              variant="outlined"
              value={inputValue}
              type="text"
              size="medium"
              autoFocus
              placeholder="Enter identifier"
              error={errorMessage}
              helperText={errorMessage && "Can't find valid reference"}
              onChange={(e) => {
                setErrorMessage(false);
                setInputValue(e.target.value);
              }}
            />
          </FormControl>
          <LoadingButton
            className={clsx("search-button", {
              expandMargin: errorMessage,
            })}
            color="primary"
            variant="contained"
            startIcon={<SearchOutlined fontSize="large" />}
            disabled={inputValue.length === 0}
            loading={search}
            onClick={searchReference}
          />
        </Box>
      )}
      {fieldsReady && (
        <>
          <Box className="doc-properties">
            <Accordion square defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body2" lineHeight="inherit">
                  Document Properties
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: 0,
                }}
              >
                <div className="organization-schema">
                  {getOrganizationSchemaFields()}
                  <Button
                    className="showAllFields"
                    size="small"
                    fullWidth
                    startIcon={
                      showEmpty ? (
                        <VisibilityOffOutlinedIcon fontSize="small" />
                      ) : (
                        <VisibilityIcon fontSize="small" />
                      )
                    }
                    onClick={() => setShowEmpty(!showEmpty)}
                  >
                    {showEmpty ? (
                      <Typography variant="body2">Hide empty fields</Typography>
                    ) : (
                      <Typography variant="body2">Show all fields</Typography>
                    )}
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion square defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body2" lineHeight="inherit">
                  Abstract / Description
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  padding: 0,
                }}
              >
                <div
                  className="descriptionTextArea"
                  contentEditable
                  suppressContentEditableWarning
                  style={{
                    cursor: focusDescription ? "text" : "default",
                  }}
                  onBlur={(e) => {
                    if (focusDescription) {
                      const newMeta = { ...referenceMeta };
                      newMeta.abstract = e.currentTarget.textContent;
                      setReferenceMeta(newMeta);
                      setFocusDescription(false);
                    }
                  }}
                  onFocus={(e) => {
                    e.target.focus();
                    setFocusDescription(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      window?.getSelection()?.removeAllRanges();
                      setFocusDescription(false);
                      const newMeta = { ...referenceMeta };
                      newMeta.abstract = e.currentTarget.textContent;
                      setReferenceMeta(newMeta);
                    }
                  }}
                >
                  {focusDescription
                    ? referenceMeta?.abstract
                    : referenceMeta?.abstract?.length > 0
                    ? displayTextWithMath(
                        detexify(referenceMeta.abstract, false)
                      )
                    : "Add description"}
                </div>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box className="collection-selector">
            <SubdirectoryArrowRightIcon color="primary" fontSize="small" />
            <Typography variant="body2" fontWeight={500}>
              To the collection:
            </Typography>
            <CollectionSelector
              includeDefaultCollections
              canCreateCollection
              currentParentId={selectedCollectionId || -1}
              collectionToUse={(id) => {
                setSelectedCollectionId(id);
              }}
            />
          </Box>
          <LoadingButton
            onClick={addReference}
            color="primary"
            variant="contained"
            size="medium"
            disabled={
              !Object.values(referenceMeta).some(
                (value) => value || value.length > 0
              )
            }
            loading={creatingReference}
            sx={{
              margin: "auto auto 0 auto",
              width: "fit-content",
            }}
          >
            Add reference
          </LoadingButton>
        </>
      )}
    </Wrapper>
  );
};

export default ManualEntryDialog;
