import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import aiCreateService, { useAICreateProjects } from "api/aiCreateService";
import { AICreateProject } from "models/api/response.types";
import { addAlert } from "store/features/general/slice";
import { AICreateProjectTemplate } from "components/Browser/AICreate/AICreateProjectDashboard";

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: "#272727",
    position: "relative",
    fontSize: "14px",
    transform: "none",
    marginBottom: "8px",
  },
  "& #friend": {
    background: theme.background.light,
    padding: "11px 14px",
  },
  "& .description": {
    margin: "1rem 0 0.5rem 0",
  },
  "& .learn-more": {
    display: "inline-block",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const AICreateProjectDialog: React.FC<{
  open: boolean;
  projectToUpdate?: AICreateProject;
  setOpen: (open: boolean, newProj?: AICreateProject) => void;
  projectTemplate?: AICreateProjectTemplate;
}> = ({ open, projectToUpdate, setOpen, projectTemplate }) => {
  const dispatch = useDispatch();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { upsertCachedAICreateProject } = useAICreateProjects(
    currentOrganizationId
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [topicPoints, setTopicPoints] = useState<string>("");
  const [topicSummary, setTopicSummary] = useState<string>("");
  const [visibility, setVisibility] = useState<"shared" | "private">("private");

  const disabledCreation =
    !title || !topicPoints || (!topicSummary && !projectToUpdate);
  const disabledUpdate = !title && !!projectToUpdate;

  useEffect(() => {
    const timeOut = projectToUpdate || projectTemplate ? 0 : 300;
    setTimeout(() => {
      setTitle(projectToUpdate?.title || "");
      setTopicSummary(
        projectToUpdate?.topic_summary || projectTemplate?.topic_summary || ""
      );
      setTopicPoints(
        projectToUpdate?.topic_main_points ||
          projectTemplate?.topic_main_points ||
          ""
      );
      setVisibility(projectToUpdate?.visibility || "private");
      setIsUpdate(!!projectToUpdate);
    }, timeOut);
  }, [projectToUpdate, projectTemplate]);

  const errorMessage = () => {
    dispatch(
      addAlert({
        severity: "error",
        autoHideDuration: 5000,
        alert: {
          message: "Something went wrong. Please try again later.",
        },
      })
    );
  };

  const createProject = () => {
    if (currentOrganizationId) {
      setLoading(true);
      aiCreateService
        .createAICreateProject({
          meta: {},
          organization_id: currentOrganizationId,
          project_type: projectTemplate?.type || "user_defined",
          title,
          topic_summary: topicSummary,
          topic_main_points: topicPoints,
          visibility,
        })
        .then(({ data }) => {
          setLoading(false);
          upsertCachedAICreateProject(data);
          setOpen(false, data);
        })
        .catch(() => {
          setLoading(false);
          errorMessage();
        });
    }
  };

  const updateProject = () => {
    setLoading(true);
    aiCreateService
      .updateAICreateProject(projectToUpdate?.id as number, {
        title,
        visibility,
      })
      .then(({ data }) => {
        setLoading(false);
        upsertCachedAICreateProject(data);
        setOpen(false);
      })
      .catch(() => {
        setLoading(false);
        errorMessage();
      });
  };

  const submitForm = () => {
    if (!projectToUpdate && !disabledCreation) {
      createProject();
    } else if (projectToUpdate && !disabledUpdate) {
      updateProject();
    }
  };

  return (
    <DialogWrapper
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {isUpdate ? "Edit" : "Create New"}{" "}
        {projectTemplate?.short_label || "Project"}
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="friend">
            Title
          </InputLabel>
          <TextField
            color="primary"
            variant="outlined"
            value={title}
            type="text"
            size="small"
            autoFocus
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === "Enter") {
                event.preventDefault();
                submitForm();
              }
            }}
          />
        </FormControl>

        <FormControl
          variant="standard"
          fullWidth
          disabled={isUpdate}
          sx={{
            marginTop: "0.5rem",
          }}
        >
          <InputLabel shrink htmlFor="friend">
            What would you like to create?
          </InputLabel>
          <TextField
            color="primary"
            variant="outlined"
            value={topicSummary}
            placeholder=""
            type="text"
            size="small"
            multiline
            disabled={isUpdate}
            onChange={(e) => {
              setTopicSummary(e.target.value);
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === "Enter") {
                event.preventDefault();
                submitForm();
              }
            }}
          />
        </FormControl>
        <FormControl
          variant="standard"
          disabled={isUpdate}
          fullWidth
          sx={{
            marginTop: "0.5rem",
          }}
        >
          <InputLabel shrink htmlFor="friend">
            What are the main points you want to cover?
          </InputLabel>
          <TextField
            color="primary"
            variant="outlined"
            value={topicPoints}
            placeholder=""
            multiline
            minRows={6}
            disabled={isUpdate}
            onChange={(e) => {
              setTopicPoints(e.target.value);
            }}
          />
        </FormControl>
        {isUpdate && (
          <Typography
            display="block"
            variant="caption"
            sx={{ marginTop: "0.5rem" }}
          >
            Project summary and main points cannot be edited after creation;
            please create a new project instead.
          </Typography>
        )}

        <FormControl
          sx={{
            margin: "0.5rem 0 0 0.5rem",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                className="primary"
                checked={visibility === "shared"}
                onChange={() => {
                  if (visibility === "private") {
                    setVisibility("shared");
                  } else {
                    setVisibility("private");
                  }
                }}
              />
            }
            label={
              <Typography color="textSecondary" variant="body2">
                Make the project shared across workspace
              </Typography>
            }
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          color="primary"
          size="medium"
          variant="text"
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={submitForm}
          color="primary"
          size="medium"
          variant="contained"
          loading={loading}
          disabled={disabledCreation || disabledUpdate}
        >
          {isUpdate ? "Update" : "Create new project"}
        </LoadingButton>
      </DialogActions>
    </DialogWrapper>
  );
};

export default AICreateProjectDialog;
