import React, { useContext, useEffect, useState } from "react";
import { DocumentsContext } from "pages/Documents";
import { Drawer, styled, useMediaQuery, useTheme } from "@mui/material";
import { DocumentReadResponse } from "models/api/response.types";
import SideBarProperties from "./SideBarProperties";

interface DrawerProps {
  drawerwidth: number;
}

const SideBarDrawer = styled(Drawer)<DrawerProps>(({ drawerwidth, theme }) => ({
  width: drawerwidth,
  flexShrink: 0,
  position: "relative",
  [theme.breakpoints.down("xl")]: {
    position: "absolute",
    right: "0",
    height: "100%",
  },
  [theme.breakpoints.up("xl")]: {
    ...(drawerwidth > 0 && {
      marginLeft: "0.5rem",
    }),
  },
  transition: theme.transitions.create(["width", "opacity"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  "& .MuiDrawer-paper": {
    width: "100%",
    boxSizing: "border-box",
    background: "transparent",
    border: "none",
    position: "absolute",
    overflow: "hidden",
    [theme.breakpoints.down("xl")]: {
      boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
    },
  },
}));

const DocumentRightSideBar: React.FC = () => {
  const theme = useTheme();
  const { documentToShow, setDocumentToShow } = useContext(DocumentsContext);
  const lgScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [currentDoc, setCurrentDoc] = useState<
    DocumentReadResponse | undefined
  >(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const drawerWidth = lgScreen ? (open ? 400 : 0) : 400;

  useEffect(() => {
    if (documentToShow) {
      setCurrentDoc(documentToShow);
      setTimeout(() => {
        setOpen(true);
      }, 100);
    } else {
      setOpen(false);
    }
  }, [documentToShow]);

  if (!currentDoc || !documentToShow) {
    return null;
  }

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      setCurrentDoc(undefined);
      setDocumentToShow(undefined);
    }, 300);
  };

  return (
    <SideBarDrawer
      drawerwidth={drawerWidth}
      variant="persistent"
      anchor="right"
      open={open}
      className="right-side-bar"
    >
      {currentDoc && (
        <SideBarProperties document={currentDoc} onClose={close} />
      )}
    </SideBarDrawer>
  );
};

export default DocumentRightSideBar;
