/* eslint-disable prefer-const */
import React, { useEffect } from "react";

const ReloadOnIdle: React.FC<{
  timedOut: boolean;
  setTimedOut: (timOut: boolean) => void;
}> = ({ timedOut, setTimedOut }) => {
  let idleTimeout: NodeJS.Timeout | null = null;

  const handleVisibilityChange = () => {
    if (document.hidden) {
      // User switched to another tab or minimized the browser
      idleTimeout = setTimeout(() => {
        setTimedOut(true);
      }, 10 * 60 * 1000); // idle in 10 minutes
    } else if (timedOut) {
      window.location.reload();
    } else if (idleTimeout) {
      clearTimeout(idleTimeout);
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      if (idleTimeout) {
        clearTimeout(idleTimeout);
      }

      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [timedOut]);

  return <></>;
};

export default ReloadOnIdle;
