const routePaths = {
  default: "/",
  admin: "/admin",
  browser: "/browse",
  add: "/browse/add",
  documents: "/browse/documents",
  aiTables: "/browse/ai-tables",
  aiTable: "/browse/ai-table/:id",
  aiChat: "/browse/ai-chat",
  aiCreate: "/browse/ai-create",
  documentView: "/document/:id/view",
  logout: "/logout",
  citegen: "/citation/generator",
  signup: "/signup",
  login: "/login",
  selectService: "/select-service",
  forgotPasword: "/forgot-password",
  referral: "/referral",
  workspace: "/workspace",
  workspaceOverview: "/workspace/overview",
  workspaceBilling: "/workspace-billing",
  profile: "/workspace/profile",
  workspaceMembers: "/workspace/members",
  discourseSSO: "/discourse/sso",
  maintenance: "/maintenance",
};

export default routePaths;
