/* eslint-disable no-restricted-globals */
export interface ITableRow {
  [name: string]: string | number;
}

function descendingComparator(a: any, b: any, orderBy: string) {
  const resultB = isNaN(b[orderBy]) ? b[orderBy] : parseInt(b[orderBy], 10);
  const resultA = isNaN(a[orderBy]) ? a[orderBy] : parseInt(a[orderBy], 10);
  if (resultB < resultA) {
    return -1;
  }
  if (resultB > resultA) {
    return 1;
  }
  return 0;
}

export function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

export const stableSort = (array: any[], comparator: any) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
