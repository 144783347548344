/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { exportBibTeX } from "utils/bibtex";
import { DocumentReadResponse } from "models/api/response.types";
import downloadStringAsFile from "utils/downloadStringAsFile";
import { DialogActions } from "@mui/material";
import { isTable } from "utils/documentStatus";

const ExportDialog: React.FC<{
  setOpen: (open: boolean) => void;
  currentDocuments: DocumentReadResponse[];
}> = ({ setOpen, currentDocuments }) => {
  const bibtexStr = currentDocuments
    .filter((doc) => !isTable(doc))
    .map((document) => exportBibTeX(document))
    .join("\n\n");

  const [isCopied, setIsCopied] = useState(false);
  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand("copy", true, text);
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(bibtexStr)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const handleSaveClick = () => {
    downloadStringAsFile("citations.bib", bibtexStr);
  };

  return (
    <>
      <Dialog
        open
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Export reference
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            color="primary"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            rows={22}
            value={bibtexStr}
            style={{ flex: 1 }}
            inputProps={{
              style: {
                fontFamily: "menlo,consolas,monospace",
                overflowX: "scroll",
                whiteSpace: "pre",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={handleSaveClick}
          >
            Save to .bib file
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={handleCopyClick}
          >
            {isCopied ? "Copied!" : "Copy to clipboard"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportDialog;
