import axios, { AxiosPromise } from "axios";
import {
  ReferralList,
  ReferralUserPermittedResponse,
  ReferralCreateBody,
  Referral,
  SuccessResponse,
  ReferralCodeVerifyBody,
  ReferralCodeAcceptBody,
  AccessTokenResponse,
} from "models/api/response.types";

const getUsersReferrals = (userId: number): AxiosPromise<ReferralList> => {
  return axios.get(`/api/user/${userId}/referral/list`);
};

const checkReferralEmail = (
  email: string
): AxiosPromise<ReferralUserPermittedResponse> => {
  return axios.get(`/api/referral/user/permitted?email=${email}`);
};

const createReferral = (
  requestBody: ReferralCreateBody
): AxiosPromise<Referral> => {
  return axios.post(`/api/referral/create`, requestBody);
};

const deleteReferral = (referralId: number): AxiosPromise<SuccessResponse> => {
  return axios.delete(`/api/referral/${referralId}/delete`);
};

const verifyReferralCode = (
  requestBody: ReferralCodeVerifyBody
): AxiosPromise<Referral> => {
  return axios.post("/api/referral/code/verify", requestBody);
};

const acceptReferralUser = (
  requestBody: ReferralCodeAcceptBody
): AxiosPromise<AccessTokenResponse> => {
  return axios.post("/api/referral/code/accept", requestBody);
};

export const referralService = {
  getUsersReferrals,
  checkReferralEmail,
  createReferral,
  deleteReferral,
  verifyReferralCode,
  acceptReferralUser,
};

export default referralService;
