export const unicode_to_tex = {
  "\ud50a": "\\frakG", // 픊
  "\u2682": "\\diceiii", // ⚂
  "\u02e9": "\\tonebarextralow", // ˩
  "\u230f": "\\ulcrop", // ⌏
  "\u25e1": "\\botsemicircle", // ◡
  "\u230d": "\\dlcrop", // ⌍
  "\ud79d": "\\bfitsansXi", // 힝
  "\u02c9": "\\textmacron", // ˉ
  "\ud427": "\\bfn", // 퐧
  "\ud6aa": "\\bfGamma", // 횪
  "\u210f": "\\hslash", // ℏ
  "\ud4f1": "\\bfscrh", // 퓱
  "\ud728": "\\bfitNu", // 휨
  "\ud68b": "\\ttb", // 횋
  "\u2ac0": "\\supsetplus", // ⫀
  "\u2662": "\\diamondsuit", // ♢
  "\u2138": "\\daleth", // ℸ
  "\u028e": "\\textturny", // ʎ
  "\ud565": "\\Bbbt", // 핥
  "\ud61c": "\\itsansU", // 혜
  "\u2aec": "\\Not", // ⫬
  "\ud522": "\\frake", // 픢
  "\u2141": "\\Game", // ⅁
  "\ud52a": "\\frakm", // 픪
  "\ud696": "\\ttm", // 횖
  "\u29ad": "\\measangleultonw", // ⦭
  "\ud7a0": "\\bfitsansRho", // 힠
  "\ud706": "\\itlambda", // 휆
  "\u2240": "\\wr", // ≀
  "\u2245": "\\cong", // ≅
  "\ud77c": "\\bfsansnu", // 흼
  "\u2af1": "\\topcir", // ⫱
  "\u212f": "\\scre", // ℯ
  "\u29cd": "\\triangleserifs", // ⧍
  "\ud7e1": "\\Bbbnine", // ퟡ
  "\u2af9": "\\leqqslant", // ⫹
  "\ud6ec": "\\itLambda", // 훬
  "\u221f": "\\rightangle", // ∟
  "\ud58d": "\\bffrakh", // 햍
  "\ud7ce": "\\bfzero", // ퟎ
  "\u20d7": "\\wideoverrightarrow", // ⃗
  "\u2a72": "\\pluseqq", // ⩲
  "\u2315": "\\recorder", // ⌕
  "\u2060": "\\wordjoin", // ⁠
  "\u292a": "\\towa", // ⤪
  "\u29d1": "\\lfbowtie", // ⧑
  "\u25d7": "\\blackrighthalfcircle", // ◗
  "\u2050": "\\closure", // ⁐
  "\u03df": "\\upkoppa", // ϟ
  "\u2020": "\\dagger", // †
  "\u21fc": "\\nVleftrightarrow", // ⇼
  "\u2200": "\\forall", // ∀
  "\ud695": "\\ttl", // 횕
  "\u27d4": "\\pushout", // ⟔
  "\ud729": "\\bfitXi", // 휩
  "\u2002": "\\enspace", // no symbol
  "\u2948": "\\leftrightarrowcircle", // ⥈
  "\u0317": "\\textsubacute", // ̗
  "\u25f4": "\\circleulquad", // ◴
  "\u25ef": "\\lgwhtcircle", // ◯
  "\u25b4": "\\blacktriangle", // ▴
  "\ud7b4": "\\bfitsanslambda", // ힴ
  "\u29cb": "\\triangleubar", // ⧋
  "\u2aeb": "\\Vbar", // ⫫
  "\u00a9": "\\textcopyright", // ©
  "\u2297": "\\otimes", // ⊗
  "\u224b": "\\approxident", // ≋
  "\u295b": "\\barrightharpoonup", // ⥛
  "\ud606": "\\bfsansy", // 혆
  "\ud593": "\\bffrakn", // 햓
  "\u210e": "\\Planckconst", // ℎ
  "\u21a3": "\\rightarrowtail", // ↣
  "\u2a4d": "\\closedvarcap", // ⩍
  "\u2acc": "\\supsetneqq", // ⫌
  "\ud471": "\\bfitJ", // 푱
  "\u29ba": "\\obot", // ⦺
  "\ud7b1": "\\bfitsanstheta", // ힱ
  "\u21d4": "\\Leftrightarrow,", // ⇔
  "\u2998": "\\rblkbrbrak", // ⦘
  "\ud7da": "\\Bbbtwo", // ퟚ
  "\u2ae6": "\\varVdash", // ⫦
  "\u20ed": "\\overleftharpoondown", // ⃭
  "\u019b": "\\textcrlambda", // ƛ
  "\u1d8a": "\\textlhooks", // ᶊ
  "\u226b": "\\gg", // ≫
  "\u27e7": "\\rBrack", // ⟧
  "\u25f1": "\\squarellquad", // ◱
  "\u21b0": "\\Lsh", // ↰
  "\u2263": "\\Equiv", // ≣
  "\ud4df": "\\bfscrP", // 퓟
  "\ud5b8": "\\sansY", // 햸
  "\ud65e": "\\bfitsansi", // 홞
  "\u229d": "\\circleddash", // ⊝
  "\u263c": "\\sun", // ☼
  "\ud453": "\\itf", // 푓
  "\u02c6": "\\textfall", // ˆ
  "\u032e": "\\textsubbreve", // ̮
  "\u20dc": "\\ddddot", // ⃜
  "\u27d1": "\\wedgedot", // ⟑
  "\ud676": "\\ttG", // 홶
  "\ud509": "\\frakF", // 픉
  "\ud408": "\\bfI", // 퐈
  "\u2260": "\\textdoublebarslash", // ≠
  "\ud74c": "\\bfitchi", // 흌
  "\u2159": "\\fraconesixth", // ⅙
  "\u0188": "\\texthtc", // ƈ
  "\u0304": "\\bar", // ̄
  "\u03be": "\\upxi", // ξ
  "\u2969": "\\rightleftharpoonsdown", // ⥩
  "\u22ab": "\\VDash", // ⊫
  "\u210a": "\\scrg", // ℊ
  "\ud411": "\\bfR", // 퐑
  "\u29fa": "\\doubleplus", // ⧺
  "\ud785": "\\bfsansphi", // 힅
  "\ud7f4": "\\bfsanseight", // ퟴ
  "\u2466": "\\circledseven", // ⑦
  "\ud6b8": "\\bfRho", // 횸
  "\u231f": "\\lrcorner", // ⌟
  "\u0278": "\\textphi", // ɸ
  "\ud418": "\\bfY", // 퐘
  "\ud5a1": "\\sansB", // 햡
  "\ud417": "\\bfX", // 퐗
  "\ud450": "\\itc", // 푐
  "\u232c": "\\varhexagonlrbonds", // ⌬
  "\ud440": "\\itM", // 푀
  "\u2639": "\\frownie", // ☹
  "\ud6cc": "\\bflambda", // 훌
  "\u293e": "\\cwundercurvearrow", // ⤾
  "\ud659": "\\bfitsansd", // 홙
  "\u23df": "\\underbrace", // ⏟
  "\ud53a": "\\BbbC", // 픺
  "\u00f0": "\\dh", // ð
  "\u2a51": "\\wedgeodot", // ⩑
  "\ud42e": "\\bfu", // 퐮
  "\u2994": "\\rparengtr", // ⦔
  "\ud46d": "\\bfitF", // 푭
  "\ud5e9": "\\bfsansV", // 헩
  "\ud784": "\\bfsansupsilon", // 힄
  "\u225f": "\\questeq", // ≟
  "\u2974": "\\rightarrowsimilar", // ⥴
  "\u20ea": "\\textoverlayleftarrow", // ⃪
  "\u003c": "\\less", // <
  "\u2144": "\\Yup", // ⅄
  "\u0321": "\\textpalhook", // ̡
  "\u0026": "\\&", // &
  "\u0275": "\\textbaro", // ɵ
  "\u00ac": "\\neg", // ¬
  "\u2950": "\\leftrightharpoondowndown", // ⥐
  "\u2133": "\\scrM", // ℳ
  "\u278c": "\\blackcircledsansthree", // ➌
  "\ud788": "\\bfsansomega", // 히
  "\ud4ad": "\\scrR", // 풭
  "\u030a": "\\ocirc", // ̊
  "\u0346": "\\textoverbridge", // ͆
  "\ud5b2": "\\sansS", // 햲
  "\u2017": "\\twolowline", // ‗
  "\ud742": "\\bfitnu", // 흂
  "\u00be": "\\textthreequarters", // ¾
  "\u2524": "\\bdbvlh", // ┤
  "\u026e": "\\textlyoghlig", // ɮ
  "\u25d5": "\\blackcircleulquadwhite", // ◕
  "\u266b": "\\twonotes", // ♫
  "\u028b": "\\textscriptv", // ʋ
  "\u2960": "\\upharpoonleftbar", // ⥠
  "\u23b5": "\\underbracket", // ⎵
  "\u21ff": "\\leftrightarrowtriangle", // ⇿
  "\ud7c2": "\\bfitsansomega", // ퟂ
  "\u2664": "\\varspadesuit", // ♤
  "\u0397": "\\upEta", // Η
  "\u25a6": "\\squarehvfill", // ▦
  "\ud701": "\\itzeta", // 휁
  "\u2992": "\\rangledot", // ⦒
  "\ud419": "\\bfZ", // 퐙
  "\u2913": "\\downarrowbar", // ⤓
  "\ud4f2": "\\bfscri", // 퓲
  "\ud61b": "\\itsansT", // 혛
  "\u2078": "\\supeight", // ⁸
  "\ud472": "\\bfitK", // 푲
  "\u00c5": "\\AA", // Å
  "\ud7e7": "\\sansfive", // ퟧ
  "\ud412": "\\bfS", // 퐒
  "\u1d1c": "\\textscu", // ᴜ
  "\u2248": "\\thickapprox", // ≈
  "\ud66b": "\\bfitsansv", // 홫
  "\ud79e": "\\bfitsansOmicron", // 힞
  "\u027b": "\\textturnrrtail", // ɻ
  "\u030d": "\\textvbaraccent", // ̍
  "\ud709": "\\itxi", // 휉
  "\u23b0": "\\lmoustache", // ⎰
  "\ud42b": "\\bfr", // 퐫
  "\ud434": "\\itA", // 퐴
  "\ud6e7": "\\itZeta", // 훧
  "\u20e7": "\\annuity", // ⃧
  "\ud762": "\\bfsansNu", // 흢
  "\ud5eb": "\\bfsansX", // 헫
  "\u2a5b": "\\veemidvert", // ⩛
  "\u03b6": "\\upzeta", // ζ
  "\u025a": "\\textrhookschwa", // ɚ
  "\ud6f4": "\\itSigma", // 훴
  "\u2281": "\\nsucc", // ⊁
  "\u039d": "\\upNu", // Ν
  "\ud4ea": "\\bfscra", // 퓪
  "\u29b5": "\\circlehbar", // ⦵
  "\u29a7": "\\wideangleup", // ⦧
  "\ud57e": "\\bffrakS", // 핾
  "\u29da": "\\Lvzigzag", // ⧚
  "\ud787": "\\bfsanspsi", // 힇
  "\u00b5": "\\textmu", // µ
  "\u25d6": "\\blacklefthalfcircle", // ◖
  "\u3018": "\\Lbrbrak", // 〘
  "\u02a7": "\\textteshlig", // ʧ
  "\u212e": "\\textestimated", // ℮
  "\u2562": "\\bdbVlh", // ╢
  "\ud638": "\\itsansw", // 호
  "\ud63c": "\\bfitsansA", // 혼
  "\ud5d7": "\\bfsansD", // 헗
  "\u2a16": "\\sqint", // ⨖
  "\u258c": "\\blocklefthalf", // ▌
  "\ud49f": "\\scrD", // 풟
  "\u2279": "\\ngtrless", // ≹
  "\u0261": "\\textscriptg", // ɡ
  "\ud579": "\\bffrakN", // 핹
  "\u2980": "\\Vvert", // ⦀
  "\ud4a0": "\\scrE", // 풠
  "\ud7ae": "\\bfitsansvarepsilon", // 힮
  "\ud651": "\\bfitsansV", // 홑
  "\ud54d": "\\BbbV", // 핍
  "\ud496": "\\bfitu", // 풖
  "\ud564": "\\Bbbs", // 핤
  "\ud6c8": "\\bfeta", // 훈
  "\u02b2": "\\textsupj", // ʲ
  "\u21fe": "\\rightarrowtriangle", // ⇾
  "\u24b7": "\\circledB", // Ⓑ
  "\u25a5": "\\squarevfill", // ▥
  "\u226a": "\\ll", // ≪
  "\u2076": "\\supsix", // ⁶
  "\u3019": "\\Rbrbrak", // 〙
  "\u223a": "\\dotsminusdots", // ∺
  "\u29bf": "\\circledbullet", // ⦿
  "\ud4c1": "\\scrl", // 퓁
  "\u2126": "\\textohm", // Ω
  "\u2ab4": "\\succeqq", // ⪴
  "\ud536": "\\fraky", // 픶
  "\ud5ad": "\\sansN", // 햭
  "\u2a0b": "\\sumint", // ⨋
  "\u23e2": "\\trapezium", // ⏢
  "\u2a54": "\\Vee", // ⩔
  "\ud6e9": "\\itTheta", // 훩
  "\u0281": "\\textinvscr", // ʁ
  "\u27fe": "\\Longmapsto", // ⟾
  "\u298c": "\\rbrackubar", // ⦌
  "\u2286": "\\subseteq", // ⊆
  "\u0256": "\\textrtaild", // ɖ
  "\u24e0": "\\circledq", // ⓠ
  "\u22f9": "\\isinE", // ⋹
  "\u2ac7": "\\subsim", // ⫇
  "\ud65f": "\\bfitsansj", // 홟
  "\u0235": "\\textctn", // ȵ
  "\u00b9": "\\textonesuperior", // ¹
  "\u22c7": "\\divideontimes", // ⋇
  "\u2a75": "\\eqeq", // ⩵
  "\u2158": "\\fracfourfifths", // ⅘
  "\u22af": "\\nVDash", // ⊯
  "\u2215": "\\slash", // ∕
  "\u2236": "\\colon", // ∶
  "\u0284": "\\texthtbardotlessjvar", // ʄ
  "\ud647": "\\bfitsansL", // 홇
  "\u2937": "\\rightdowncurvedarrow", // ⤷
  "\u20e8": "\\threeunderdot", // ⃨
  "\u2061": "\\functionapply", // ⁡
  "\u0319": "\\textretracting", // ̙
  "\ud415": "\\bfV", // 퐕
  "\u220a": "\\smallin", // ∊
  "\u2147": "\\itBbbe", // ⅇ
  "\ud5a3": "\\sansD", // 햣
  "\u2571": "\\bdnesw", // ╱
  "\u2aa5": "\\gla", // ⪥
  "\ud68e": "\\tte", // 횎
  "\u02e6": "\\tonebarhigh", // ˦
  "\ud78b": "\\bfsansvartheta", // 힋
  "\u263a": "\\smiley", // ☺
  "\u301a": "\\lBrack", // 〚
  "\u2943": "\\leftarrowshortrightarrow", // ⥃
  "\u255b": "\\bduvlH", // ╛
  "\u00bb": "\\guillemotright", // »
  "\u22a7": "\\models", // ⊧
  "\ud47a": "\\bfitS", // 푺
  "\u29dd": "\\tieinfty", // ⧝
  "\u26ab": "\\mdblkcircle", // ⚫
  "\u2a76": "\\eqeqeq", // ⩶
  "\ud6a5": "\\jmath", // 횥
  "\u00ae": "\\textregistered", // ®
  "\u030e": "\\textdoublevbaraccent", // ̎
  "\ud66e": "\\bfitsansy", // 홮
  "\u22d6": "\\lessdot", // ⋖
  "\u2927": "\\tona", // ⤧
  "\u2683": "\\diceiv", // ⚃
  "\u27f9": "\\Longrightarrow", // ⟹
  "\u230b": "\\rfloor", // ⌋
  "\ud5d3": "\\sansz", // 헓
  "\ud713": "\\itpsi", // 휓
  "\u29c6": "\\boxast", // ⧆
  "\ud517": "\\frakT", // 픗
  "\u2119": "\\BbbP", // ℙ
  "\ud707": "\\itmu", // 휇
  "\ud64b": "\\bfitsansP", // 홋
  "\u22ba": "\\intercal", // ⊺
  "\u2993": "\\lparenless", // ⦓
  "\u22fb": "\\varnis", // ⋻
  "\u2640": "\\female", // ♀
  "\ud54f": "\\BbbX", // 핏
  "\ud66d": "\\bfitsansx", // 홭
  "\u0359": "\\textunderasterisk", // ͙
  "\ud5df": "\\bfsansL", // 헟
  "\ud661": "\\bfitsansl", // 홡
  "\ud5ca": "\\sansq", // 헊
  "\u2665": "\\varheartsuit", // ♥
  "\ud59d": "\\bffrakx", // 햝
  "\u25ac": "\\hrectangleblack", // ▬
  "\u29c4": "\\boxdiag", // ⧄
  "\u2ae9": "\\vBarv", // ⫩
  "\u24d9": "\\circledj", // ⓙ
  "\ud53c": "\\BbbE", // 피
  "\u266a": "\\eighthnote", // ♪
  "\u2abe": "\\supsetdot", // ⪾
  "\ud58b": "\\bffrakf", // 햋
  "\ud7ad": "\\bfitsansdelta", // 힭
  "\u22ad": "\\nvDash", // ⊭
  "\u293c": "\\curvearrowrightminus", // ⤼
  "\ud563": "\\Bbbr", // 핣
  "\u2a61": "\\varveebar", // ⩡
  "\u2ae4": "\\Dashv", // ⫤
  "\ud474": "\\bfitM", // 푴
  "\ud6bc": "\\bfUpsilon", // 횼
  "\u228b": "\\supsetneq", // ⊋
  "\u0311": "\\textroundcap", // ̑
  "\u2278": "\\nlessgtr", // ≸
  "\ud741": "\\bfitmu", // 흁
  "\u231d": "\\urcorner", // ⌝
  "\ud7c0": "\\bfitsanschi", // ퟀ
  "\ud5b6": "\\sansW", // 햶
  "\ud46b": "\\bfitD", // 푫
  "\u2a27": "\\plussubtwo", // ⨧
  "\u02b7": "\\textsupw", // ʷ
  "\u29b0": "\\revemptyset", // ⦰
  "\u2acd": "\\lsqhook", // ⫍
  "\u0254": "\\textopeno", // ɔ
  "\u23af": "\\hlineextender", // ⎯
  "\u2113": "\\ell", // ℓ
  "\u292c": "\\fdiagovrdiag", // ⤬
  "\ud699": "\\ttp", // 횙
  "\u02a1": "\\textbarglotstop", // ʡ
  "\u2086": "\\subsix", // ₆
  "\u224f": "\\bumpeq", // ≏
  "\ud5e4": "\\bfsansQ", // 헤
  "\ud7e9": "\\sansseven", // ퟩ
  "\u25ca": "\\mdlgwhtlozenge", // ◊
  "\u2685": "\\dicevi", // ⚅
  "\u2506": "\\bdtriplevdash", // ┆
  "\u22f5": "\\isindot", // ⋵
  "\u2319": "\\turnednot", // ⌙
  "\u21e0": "\\leftdasharrowhead", // ⇠
  "\u02a3": "\\textdzlig", // ʣ
  "\u2785": "\\circledsanssix", // ➅
  "\ud7b8": "\\bfitsansomicron", // ힸ
  "\u02d8": "\\textbreve", // ˘
  "\u02bc": "\\rasp", // ʼ
  "\u2aaa": "\\smt", // ⪪
  "\u29e7": "\\thermod", // ⧧
  "\u2a04": "\\biguplus", // ⨄
  "\u25e9": "\\squareulblack", // ◩
  "\ud573": "\\bffrakH", // 핳
  "\ud76e": "\\bfsansOmega", // 흮
  "\u25e0": "\\topsemicircle", // ◠
  "\u2680": "\\dicei", // ⚀
  "\u27d7": "\\fullouterjoin", // ⟗
  "\u2035": "\\textbackprime", // ‵
  "\u21d5": "\\Updownarrow", // ⇕
  "\u2902": "\\nvLeftarrow", // ⤂
  "\u212d": "\\frakC", // ℭ
  "\u2308": "\\lceil", // ⌈
  "\ud428": "\\bfo", // 퐨
  "\ud4e2": "\\bfscrS", // 퓢
  "\ud54e": "\\BbbW", // 핎
  "\u29ac": "\\measangleurtone", // ⦬
  "\ud7f0": "\\bfsansfour", // ퟰ
  "\u22c1": "\\bigvee", // ⋁
  "\u222d": "\\iiint", // ∭
  "\u23b3": "\\sumbottom", // ⎳
  "\u29c3": "\\cirE", // ⧃
  "\u21a8": "\\updownarrowbar", // ↨
  "\u03a3": "\\upSigma", // Σ
  "\u2ab5": "\\precneqq", // ⪵
  "\ud537": "\\frakz", // 픷
  "\ud60f": "\\itsansH", // 혏
  "\u2370": "\\APLboxquestion", // ⍰
  "\ud4ba": "\\scre", // 풺
  "\u2561": "\\bdbvlH", // ╡
  "\ud79c": "\\bfitsansNu", // 힜
  "\u2a35": "\\otimesrhrim", // ⨵
  "\u0308": "\\ddot", // ̈
  "\ud657": "\\bfitsansb", // 홗
  "\u27c4": "\\supsetcirc", // ⟄
  "\ud56e": "\\bffrakC", // 핮
  "\u278b": "\\blackcircledsanstwo", // ➋
  "\u0323": "\\textsubdot", // ̣
  "\u2025": "\\enleadertwodots", // ‥
  "\u24cd": "\\circledX", // Ⓧ
  "\ud63a": "\\itsansy", // 혺
  "\u21da": "\\Lleftarrow", // ⇚
  "\u215d": "\\fracfiveeighths", // ⅝
  "\ud569": "\\Bbbx", // 합
  "\u2aa7": "\\gtcc", // ⪧
  "\ud781": "\\bfsansvarsigma", // 힁
  "\u25d0": "\\circlelefthalfblack", // ◐
  "\u2234": "\\therefore", // ∴
  "\u23e0": "\\obrbrak", // ⏠
  "\u2644": "\\saturn", // ♄
  "\ud6a4": "\\imath", // 횤
  "\u22a2": "\\vdash", // ⊢
  "\u22c6": "\\star", // ⋆
  "\u1d8d": "\\textlhookx", // ᶍ
  "\ud4bf": "\\scrj", // 풿
  "\u24bf": "\\circledJ", // Ⓙ
  "\u2534": "\\bduvbh", // ┴
  "\ud761": "\\bfsansMu", // 흡
  "\u21d9": "\\Swarrow", // ⇙
  "\ud62d": "\\itsansl", // 혭
  "\ud518": "\\frakU", // 픘
  "\u21f6": "\\rightthreearrows", // ⇶
  "\ud5c9": "\\sansp", // 헉
  "\u2241": "\\nsim", // ≁
  "\u23e1": "\\ubrbrak", // ⏡
  "\u27e2": "\\concavediamondtickleft", // ⟢
  "\u03bb": "\\textlambda", // λ
  "\u25e3": "\\llblacktriangle", // ◣
  "\u21cc": "\\rightleftharpoons", // ⇌
  "\ud41b": "\\bfb", // 퐛
  "\u2606": "\\bigwhitestar", // ☆
  "\u221e": "\\infty", // ∞
  "\u21e2": "\\rightdasharrowhead", // ⇢
  "\ud507": "\\frakD", // 픇
  "\ud4a4": "\\scrI", // 풤
  "\u2004": "\\thirdemspace", // no symbol
  "\ud7c6": "\\bfitsansvarkappa", // ퟆ
  "\u03e1": "\\upsampi", // ϡ
  "\u2259": "\\wedgeq", // ≙
  "\u032b": "\\textsubw", // ̫
  "\u2ad6": "\\supsup", // ⫖
  "\ud744": "\\bfitomicron", // 흄
  "\u24c8": "\\circledS", // Ⓢ
  "\u02b6": "\\textsupinvscr", // ʶ
  "\u229b": "\\circledast", // ⊛
  "\ud646": "\\bfitsansK", // 홆
  "\u25cf": "\\mdlgblkcircle", // ●
  "\ud459": "\\itl", // 푙
  "\u02bb": "\\textturncomma", // ʻ
  "\u2a44": "\\capwedge", // ⩄
  "\ud77a": "\\bfsanslambda", // 흺
  "\u029b": "\\texthtscg", // ʛ
  "\u2a89": "\\lnapprox", // ⪉
  "\u2127": "\\mho", // ℧
  "\u0110": "\\textcrD", // Đ
  "\u291e": "\\rightarrowdiamond", // ⤞
  "\u02d0": "\\textlengthmark", // ː
  "\u2783": "\\circledsansfour", // ➃
  "\ud6fd": "\\itbeta", // 훽
  "\u229a": "\\circledcirc", // ⊚
  "\u201e": "\\quotdblbase", // „
  "\ud6bf": "\\bfPsi", // 횿
  "\u27ed": "\\Rbrbrak", // ⟭
  "\u2257": "\\circeq", // ≗
  "\u03f4": "\\upvarTheta", // ϴ
  "\u03de": "\\upKoppa", // Ϟ
  "\ud4f7": "\\bfscrn", // 퓷
  "\ud56c": "\\bffrakA", // 핬
  "\ud7d7": "\\bfnine", // ퟗ
  "\ud470": "\\bfitI", // 푰
  "\u2a86": "\\gtrapprox", // ⪆
  "\u0293": "\\textctyogh", // ʓ
  "\u2a3f": "\\amalg", // ⨿
  "\ud66a": "\\bfitsansu", // 홪
  "\u014a": "\\NG", // Ŋ
  "\ud68d": "\\ttd", // 획
  "\u25f9": "\\urtriangle", // ◹
  "\u2a0a": "\\modtwosum", // ⨊
  "\u2204": "\\nexists", // ∄
  "\u21be": "\\upharpoonright", // ↾
  "\ud601": "\\bfsanst", // 혁
  "\u2560": "\\bdbVrH", // ╠
  "\u201f": "\\quotdblright", // ‟
  "\u2276": "\\lessgtr", // ≶
  "\u22de": "\\curlyeqprec", // ⋞
  "\u22d9": "\\ggg", // ⋙
  "\u25cd": "\\circlevertfill", // ◍
  "\u2a29": "\\commaminus", // ⨩
  "\u2a69": "\\equivVvert", // ⩩
  "\u02e1": "\\ipasupl", // ˡ
  "\u2a50": "\\closedvarcupsmashprod", // ⩐
  "\u2a66": "\\eqdot", // ⩦
  "\u2208": "\\varin", // ∈
  "\u2788": "\\circledsansnine", // ➈
  "\u29f3": "\\errbarblackcircle", // ⧳
  "\u2190": "\\leftarrow,", // ←
  "\u22e0": "\\npreccurlyeq", // ⋠
  "\u3030": "\\hzigzag", // 〰
  "\ud74e": "\\bfitomega", // 흎
  "\u03b1": "\\upalpha", // α
  "\ud66f": "\\bfitsansz", // 홯
  "\ud6d7": "\\bfvarphi", // 훗
  "\u00de": "\\TH", // Þ
  "\u2465": "\\circledsix", // ⑥
  "\u2593": "\\blockthreeqtrshaded", // ▓
  "\u2951": "\\updownharpoonleftleft", // ⥑
  "\u02dc": "\\textsmalltilde", // ˜
  "\u293a": "\\acwoverarcarrow", // ⤺
  "\u2b22": "\\varhexagonblack", // ⬢
  "\u2a1f": "\\zcmp", // ⨟
  "\u2a79": "\\ltcir", // ⩹
  "\ud479": "\\bfitR", // 푹
  "\u25df": "\\llarc", // ◟
  "\ud62c": "\\itsansk", // 혬
  "\u01be": "\\textcrinvglotstop", // ƾ
  "\ud630": "\\itsanso", // 혰
  "\ud705": "\\itkappa", // 휅
  "\u25d9": "\\inversewhitecircle", // ◙
  "\u29ea": "\\blackdiamonddownarrow", // ⧪
  "\u2274": "\\nlesssim", // ≴
  "\u2336": "\\topbot", // ⌶
  "\u24be": "\\circledI", // Ⓘ
  "\u03c7": "\\textchi", // χ
  "\u25ed": "\\triangleleftblack", // ◭
  "\ud627": "\\itsansf", // 혧
  "\u230c": "\\drcrop", // ⌌
  "\u201a": "\\quotsinglbase", // ‚
  "\u0394": "\\upDelta", // Δ
  "\u03a4": "\\upTau", // Τ
  "\u22ef": "\\cdots", // ⋯
  "\u03a5": "\\upUpsilon", // Υ
  "\ud7d6": "\\bfeight", // ퟖ
  "\u213d": "\\Bbbgamma", // ℽ
  "\u2565": "\\bddVbh", // ╥
  "\ud4eb": "\\bfscrb", // 퓫
  "\u00d0": "\\DH", // Ð
  "\ud774": "\\bfsansvarepsilon", // 흴
  "\u00f8": "\\o", // ø
  "\u21d3": "\\Downarrowhead", // ⇓
  "\ud544": "\\BbbM", // 필
  "\u2999": "\\fourvdots", // ⦙
  "\u0298": "\\textbullseye", // ʘ
  "\u2959": "\\downharpoonleftbar", // ⥙
  "\u02d6": "\\textadvanced", // ˖
  "\ud7ac": "\\bfitsansgamma", // 힬
  "\u2a07": "\\conjquant", // ⨇
  "\u2a59": "\\veeonwedge", // ⩙
  "\u2a10": "\\cirfnint", // ⨐
  "\u25c5": "\\whitepointerleft", // ◅
  "\ud6b0": "\\bfIota", // 횰
  "\ud663": "\\bfitsansn", // 홣
  "\u2922": "\\neswarrow", // ⤢
  "\u2abd": "\\subsetdot", // ⪽
  "\ud743": "\\bfitxi", // 흃
  "\u21a2": "\\leftarrowtail", // ↢
  "\ud6a9": "\\bfBeta", // 횩
  "\u29eb": "\\blacklozenge", // ⧫
  "\u2044": "\\fracslash", // ⁄
  "\ud57d": "\\bffrakR", // 핽
  "\ud54c": "\\BbbU", // 핌
  "\u2321": "\\intbottom", // ⌡
  "\ud542": "\\BbbK", // 핂
  "\ufe35": "\\overparen", // ︵
  "\u29c7": "\\boxcircle", // ⧇
  "\u26a5": "\\Hermaphrodite", // ⚥
  "\u2971": "\\equalrightarrow", // ⥱
  "\u22e8": "\\precnsim", // ⋨
  "\ud642": "\\bfitsansG", // 홂
  "\u2394": "\\hexagon", // ⎔
  "\u222c": "\\iint", // ∬
  "\u25a3": "\\blackinwhitesquare", // ▣
  "\u256b": "\\bdbVbh", // ╫
  "\u301e": "\\cjkdprimequote", // 〞
  "\u23a8": "\\lbracemid", // ⎨
  "\ud486": "\\bfite", // 풆
  "\u0299": "\\textscb", // ʙ
  "\u25ae": "\\vrectangleblack", // ▮
  "\ud74f": "\\bfitpartial", // 흏
  "\u0337": "\\textoverlayshortsolidus", // ̷
  "\ud72c": "\\bfitRho", // 휬
  "\u297b": "\\suplarr", // ⥻
  "\u019a": "\\textbarl", // ƚ
  "\ud791": "\\bfitsansBeta", // 힑
  "\u039f": "\\upOmicron", // Ο
  "\u2a58": "\\bigslopedwedge", // ⩘
  "\u2ab6": "\\succneqq", // ⪶
  "\ud799": "\\bfitsansKappa", // 힙
  "\u2901": "\\nVtwoheadrightarrow", // ⤁
  "\u21b5": "\\carriagereturn", // ↵
  "\ud4f9": "\\bfscrp", // 퓹
  "\u01bb": "\\textcrtwo", // ƻ
  "\u2107": "\\Eulerconst", // ℇ
  "\u211d": "\\BbbR", // ℝ
  "\u2910": "\\drbkarow", // ⤐
  "\u00bf": "\\textquestiondown", // ¿
  "\u2a90": "\\gsiml", // ⪐
  "\ud708": "\\itnu", // 휈
  "\u2063": "\\invisiblesep", // ⁣
  "\ud5c1": "\\sansh", // 헁
  "\u2193": "\\shortdownarrow", // ↓
  "\ud41c": "\\bfc", // 퐜
  "\u200f": "\\RtoLmark", // ‏
  "\u03d6": "\\upvarpi", // ϖ
  "\u20e4": "\\enclosetriangle", // ⃤
  "\ud7a3": "\\bfitsansTau", // 힣
  "\u0322": "\\textrhook", // ̢
  "\u00af": "\\textasciimacron", // ¯
  "\ud7f1": "\\bfsansfive", // ퟱ
  "\u2468": "\\circlednine", // ⑨
  "\u24d6": "\\circledg", // ⓖ
  "\u2228": "\\vee", // ∨
  "\u2a08": "\\disjquant", // ⨈
  "\u207e": "\\suprparen", // ⁾
  "\u2317": "\\viewdata", // ⌗
  "\u2702": "\\scissors", // ✂
  "\u24e5": "\\circledv", // ⓥ
  "\ud55f": "\\Bbbn", // 핟
  "\u29fc": "\\lcurvyangle", // ⧼
  "\u2919": "\\lefttail", // ⤙
  "\ud423": "\\bfj", // 퐣
  "\u2736": "\\varstar", // ✶
  "\u025c": "\\textrevepsilon", // ɜ
  "\u2985": "\\lParen", // ⦅
  "\ud725": "\\bfitKappa", // 휥
  "\ud786": "\\bfsanschi", // 힆
  "\u2972": "\\similarrightarrow", // ⥲
  "\u21cb": "\\leftrightharpoons", // ⇋
  "\u2966": "\\leftrightharpoonsup", // ⥦
  "\u21ad": "\\leftrightwavyarrow", // ↭
  "\u2012": "\\figdash", // ‒
  "\u2930": "\\rdiagovsearrow", // ⤰
  "\ud6ac": "\\bfEpsilon", // 횬
  "\ud4d9": "\\bfscrJ", // 퓙
  "\ud5ee": "\\bfsansa", // 헮
  "\u2131": "\\scrF", // ℱ
  "\u21c5": "\\updownarrows", // ⇅
  "\ud6a3": "\\ttz", // 횣
  "\u2251": "\\Doteq", // ≑
  "\u225a": "\\veeeq", // ≚
  "\u02d1": "\\texthalflength", // ˑ
  "\u0295": "\\textrevglotstop", // ʕ
  "\u221b": "\\cuberoot", // ∛
  "\u29ca": "\\triangleodot", // ⧊
  "\u2a4e": "\\Sqcap", // ⩎
  "\u29c8": "\\boxbox", // ⧈
  "\u0314": "\\orough", // ̔
  "\u25a4": "\\squarehfill", // ▤
  "\u00e5": "\\aa", // å
  "\u2557": "\\bddVlH", // ╗
  "\u02ae": "\\textlongy", // ʮ
  "\ud5f1": "\\bfsansd", // 헱
  "\u2a02": "\\bigotimes", // ⨂
  "\ud4b8": "\\scrc", // 풸
  "\u211e": "\\textrecipe", // ℞
  "\ud735": "\\bfitnabla", // 휵
  "\u2467": "\\circledeight", // ⑧
  "\u2238": "\\dotminus", // ∸
  "\u00b7": "\\textperiodcentered,", // ·
  "\ud47b": "\\bfitT", // 푻
  "\ud5ec": "\\bfsansY", // 헬
  "\u263b": "\\blacksmiley", // ☻
  "\u27c8": "\\bsolhsub", // ⟈
  "\u25fb": "\\mdwhtsquare", // ◻
  "\u2ab2": "\\succneq", // ⪲
  "\ud67a": "\\ttK", // 홺
  "\u29aa": "\\measanglerdtose", // ⦪
  "\ud7e4": "\\sanstwo", // ퟤ
  "\u2792": "\\blackcircledsansnine", // ➒
  "\u21ce": "\\nLeftrightarrow", // ⇎
  "\u02ba": "\\cdprime", // ʺ
  "\u29d2": "\\rfbowtie", // ⧒
  "\ud42c": "\\bfs", // 퐬
  "\u2a40": "\\capdot", // ⩀
  "\ud74a": "\\bfitupsilon", // 흊
  "\ud6d1": "\\bfpi", // 훑
  "\ud7f6": "\\ttzero", // ퟶ
  "\u2195": "\\updownarrow", // ↕
  "\ud610": "\\itsansI", // 혐
  "\u29df": "\\dualmap", // ⧟
  "\u294f": "\\updownharpoonrightright", // ⥏
  "\u03c1": "\\uprho", // ρ
  "\u01ab": "\\textlhookt", // ƫ
  "\u0131": "\\imath,", // ı
  "\ud57c": "\\bffrakQ", // 핼
  "\u29ec": "\\circledownarrow", // ⧬
  "\u2ade": "\\shortlefttack", // ⫞
  "\u2a00": "\\bigodot", // ⨀
  "\u22b2": "\\vartriangleleft", // ⊲
  "\u21b9": "\\barleftarrowrightarrowbar", // ↹
  "\u2220": "\\angle", // ∠
  "\u295d": "\\bardownharpoonright", // ⥝
  "\u227a": "\\prec", // ≺
  "\u2088": "\\subeight", // ₈
  "\u22bb": "\\veebar", // ⊻
  "\u2a95": "\\eqslantless", // ⪕
  "\u22bf": "\\varlrtriangle", // ⊿
  "\u0280": "\\textscr", // ʀ
  "\ud51b": "\\frakX", // 픛
  "\ud5f8": "\\bfsansk", // 헸
  "\u02d9": "\\textdotaccent", // ˙
  "\u2709": "\\envelope", // ✉
  "\ud52e": "\\frakq", // 픮
  "\u225c": "\\triangleq", // ≜
  "\u2720": "\\maltese", // ✠
  "\ud7c3": "\\bfitsanspartial", // ퟃ
  "\ud602": "\\bfsansu", // 혂
  "\u24ea": "\\circledzero", // ⓪
  "\u21fa": "\\nVleftarrow", // ⇺
  "\u2b17": "\\diamondrightblack", // ⬗
  "\u24d5": "\\circledf", // ⓕ
  "\u29bc": "\\odotslashdot", // ⦼
  "\ud67d": "\\ttN", // 홽
  "\u292b": "\\rdiagovfdiag", // ⤫
  "\u20d6": "\\wideoverleftarrow", // ⃖
  "\u299b": "\\measuredangleleft", // ⦛
  "\ud609": "\\itsansB", // 혉
  "\ud7b7": "\\bfitsansxi", // ힷ
  "\ud687": "\\ttX", // 횇
  "\u030f": "\\textdoublegrave", // ̏
  "\u2b19": "\\diamondbotblack", // ⬙
  "\u039a": "\\upKappa", // Κ
  "\ud764": "\\bfsansOmicron", // 흤
  "\u00e6": "\\ae", // æ
  "\u2a1c": "\\lowint", // ⨜
  "\ud776": "\\bfsanseta", // 흶
  "\ud442": "\\itO", // 푂
  "\u0270": "\\textturnmrleg", // ɰ
  "\u20e6": "\\textoverlaydoublevert", // ⃦
  "\ud6cd": "\\bfmu", // 훍
  "\u0339": "\\textsubrhalfring", // ̹
  "\u295c": "\\upharpoonrightbar", // ⥜
  "\u210b": "\\scrH", // ℋ
  "\u2772": "\\lbrbrak", // ❲
  "\ud656": "\\bfitsansa", // 홖
  "\u2a9e": "\\simgtr", // ⪞
  "\ud5c3": "\\sansj", // 헃
  "\u02bd": "\\lasp", // ʽ
  "\ud6cb": "\\bfkappa", // 훋
  "\u23ac": "\\rbracemid", // ⎬
  "\u24dc": "\\circledm", // ⓜ
  "\u2adf": "\\shortdowntack", // ⫟
  "\ud75d": "\\bfsansTheta", // 흝
  "\u224a": "\\approxeq", // ≊
  "\u2264": "\\leq", // ≤
  "\ud4c6": "\\scrq", // 퓆
  "\ud59c": "\\bffrakw", // 햜
  "\ud6e6": "\\itEpsilon", // 훦
  "\ud52b": "\\frakn", // 픫
  "\u00b1": "\\pm", // ±
  "\ud6e5": "\\itDelta", // 훥
  "\ud6ae": "\\bfEta", // 횮
  "\u2907": "\\Mapsto", // ⤇
  "\u2070": "\\supzero", // ⁰
  "\u2a74": "\\Coloneq", // ⩴
  "\u2a37": "\\Otimes", // ⨷
  "\ud6dc": "\\bfepsilon", // 훜
  "\ud5f7": "\\bfsansj", // 헷
  "\ud693": "\\ttj", // 횓
  "\ud6de": "\\bfvarkappa", // 훞
  "\ud714": "\\itomega", // 휔
  "\u2643": "\\jupiter", // ♃
  "\ud6ba": "\\bfSigma", // 횺
  "\u2afe": "\\talloblong", // ⫾
  "\u2219": "\\vysmblkcircle", // ∙
  "\u2273": "\\gtrsim", // ≳
  "\u213f": "\\BbbPi", // ℿ
  "\u2a63": "\\veedoublebar", // ⩣
  "\u2083": "\\subthree", // ₃
  "\u02e8": "\\tonebarlow", // ˨
  "\u24ba": "\\circledE", // Ⓔ
  "\u24c1": "\\circledL", // Ⓛ
  "\u232d": "\\cylcty", // ⌭
  "\u29e1": "\\lrtriangleeq", // ⧡
  "\u0260": "\\texthtg", // ɠ
  "\u2277": "\\gtrless", // ≷
  "\u031b": "\\texthorn", // ̛
  "\u2b21": "\\varhexagon", // ⬡
  "\u239b": "\\lparenuend", // ⎛
  "\ud4c0": "\\scrk", // 퓀
  "\u2197": "\\nearrow", // ↗
  "\u295a": "\\leftharpoonupbar", // ⥚
  "\ud730": "\\bfitUpsilon", // 휰
  "\u2990": "\\rbrackurtick", // ⦐
  "\u2205": "\\varnothing", // ∅
  "\u2666": "\\vardiamondsuit", // ♦
  "\u23ae": "\\intextender", // ⎮
  "\u29a3": "\\revangle", // ⦣
  "\ud488": "\\bfitg", // 품
  "\ud753": "\\bfitvarphi", // 흓
  "\u03a9": "\\upOmega", // Ω
  "\u2087": "\\subseven", // ₇
  "\u2a03": "\\bigcupdot", // ⨃
  "\u2051": "\\textAsterisks", // ⁑
  "\ud4bc": "\\scrg", // 풼
  "\u22b0": "\\prurel", // ⊰
  "\u2a42": "\\barcup", // ⩂
  "\u297a": "\\leftarrowsubset", // ⥺
  "\u2040": "\\tieconcat", // ⁀
  "\ud4dc": "\\bfscrM", // 퓜
  "\u295f": "\\barrightharpoondown", // ⥟
  "\u27f7": "\\longleftrightarrow", // ⟷
  "\ud55d": "\\Bbbl", // 핝
  "\u2191": "\\uparrow,", // ↑
  "\u22d8": "\\lll", // ⋘
  "\ud59b": "\\bffrakv", // 햛
  "\ud62f": "\\itsansn", // 혯
  "\ud562": "\\Bbbq", // 핢
  "\ud66c": "\\bfitsansw", // 홬
  "\u2284": "\\nsubset", // ⊄
  "\u2031": "\\textpertenthousand", // ‱
  "\u2995": "\\Lparengtr", // ⦕
  "\ud7f7": "\\ttone", // ퟷ
  "\u203c": "\\Exclam", // ‼
  "\ud662": "\\bfitsansm", // 홢
  "\u2a94": "\\gesles", // ⪔
  "\u2258": "\\arceq", // ≘
  "\u0327": "\\c", // ̧
  "\u27c2": "\\perp", // ⟂
  "\u29f0": "\\errbardiamond", // ⧰
  "\ud7ee": "\\bfsanstwo", // ퟮ
  "\u02a0": "\\texthtq", // ʠ
  "\ud5f0": "\\bfsansc", // 헰
  "\u0269": "\\textiota", // ɩ
  "\u29a6": "\\wideangledown", // ⦦
  "\u2a6b": "\\simrdots", // ⩫
  "\u2a47": "\\capovercup", // ⩇
  "\ud44c": "\\itY", // 푌
  "\ud633": "\\itsansr", // 혳
  "\u29e9": "\\downtrianglerightblack", // ⧩
  "\ud72a": "\\bfitOmicron", // 휪
  "\u24e9": "\\circledz", // ⓩ
  "\ud49a": "\\bfity", // 풚
  "\u00f7": "\\div", // ÷
  "\u2320": "\\inttop", // ⌠
  "\u2233": "\\ointctrclockwise", // ∳
  "\u24c9": "\\circledT", // Ⓣ
  "\u031e": "\\textlowering", // ̞
  "\ud7ab": "\\bfitsansbeta", // 힫
  "\u291c": "\\rightdbltail", // ⤜
  "\ud4cf": "\\scrz", // 퓏
  "\ud726": "\\bfitLambda", // 휦
  "\u25fa": "\\lltriangle", // ◺
  "\u2a4c": "\\closedvarcup", // ⩌
  "\ud4ca": "\\scru", // 퓊
  "\ud7bb": "\\bfitsansvarsigma", // ힻ
  "\u0334": "\\textsuperimposetilde", // ̴
  "\u2aac": "\\smte", // ⪬
  "\u2026": "\\dots,", // …
  "\u2332": "\\conictaper", // ⌲
  "\u005f": "\\textunderscore", // _
  "\u026a": "\\textsci", // ɪ
  "\u2a4b": "\\twocaps", // ⩋
  "\u026d": "\\textrtaill", // ɭ
  "\ud403": "\\bfD", // 퐃
  "\u0310": "\\candra", // ̐
  "\u24bc": "\\circledG", // Ⓖ
  "\ud51e": "\\fraka", // 픞
  "\ud5b5": "\\sansV", // 햵
  "\ud6b2": "\\bfLambda", // 횲
  "\u2081": "\\subone", // ₁
  "\ud75a": "\\bfsansEpsilon", // 흚
  "\u25b9": "\\smalltriangleright", // ▹
  "\u24dd": "\\circledn", // ⓝ
  "\u2084": "\\subfour", // ₄
  "\ud7e6": "\\sansfour", // ퟦ
  "\ud5a8": "\\sansI", // 햨
  "\ud4ff": "\\bfscrv", // 퓿
  "\u2268": "\\lneqq", // ≨
  "\u22dd": "\\eqgtr", // ⋝
  "\u2936": "\\leftdowncurvedarrow", // ⤶
  "\ud7e8": "\\sanssix", // ퟨ
  "\ud431": "\\bfx", // 퐱
  "\ud54b": "\\BbbT", // 핋
  "\u29d6": "\\hourglass", // ⧖
  "\u25c9": "\\fisheye", // ◉
  "\u03dc": "\\upDigamma", // Ϝ
  "\ud62a": "\\itsansi", // 혪
  "\ud49b": "\\bfitz", // 풛
  "\ud4d3": "\\bfscrD", // 퓓
  "\ud44e": "\\ita", // 푎
  "\u294a": "\\leftrightharpoonupdown", // ⥊
  "\u2663": "\\clubsuit", // ♣
  "\u0264": "\\textramshorns", // ɤ
  "\u0332": "\\textsubline", // ̲
  "\ud41e": "\\bfe", // 퐞
  "\ud56f": "\\bffrakD", // 핯
  "\u215e": "\\fracseveneights", // ⅞
  "\ud42d": "\\bft", // 퐭
  "\ud652": "\\bfitsansW", // 홒
  "\u2212": "\\minus", // −
  "\ud7cf": "\\bfone", // ퟏ
  "\u2353": "\\APLboxupcaret", // ⍓
  "\u2271": "\\ngeq", // ≱
  "\u21c8": "\\upuparrows", // ⇈
  "\ud4a8": "\\scrM", // 풨
  "\ud7c5": "\\bfitsansvartheta", // ퟅ
  "\ud648": "\\bfitsansM", // 홈
  "\u25cc": "\\dottedcircle", // ◌
  "\ud6c0": "\\bfOmega", // 훀
  "\u2464": "\\circledfive", // ⑤
  "\ud460": "\\its", // 푠
  "\u2ad8": "\\supdsub", // ⫘
  "\ud5f5": "\\bfsansh", // 헵
  "\u25ab": "\\smwhtsquare", // ▫
  "\u25f5": "\\circlellquad", // ◵
  "\u02a4": "\\textdyoghlig", // ʤ
  "\u301b": "\\rBrack", // 〛
  "\ud558": "\\Bbbg", // 하
  "\u22e9": "\\succnsim", // ⋩
  "\ud50d": "\\frakJ", // 픍
  "\u028c": "\\textturnv", // ʌ
  "\ud631": "\\itsansp", // 혱
  "\ud55a": "\\Bbbi", // 핚
  "\u29fd": "\\rcurvyangle", // ⧽
  "\ud6a0": "\\ttw", // 횠
  "\u23a1": "\\lbrackuend", // ⎡
  "\u29fb": "\\tripleplus", // ⧻
  "\u2904": "\\nvLeftrightarrow", // ⤄
  "\ud681": "\\ttR", // 횁
  "\u27f5": "\\longleftarrow", // ⟵
  "\u231c": "\\ulcorner", // ⌜
  "\ud6f1": "\\itPi", // 훱
  "\u25a9": "\\squaregrayfill", // ▩
  "\u20a7": "\\textpesetas", // ₧
  "\u03b5": "\\upvarepsilon", // ε
  "\ud4ef": "\\bfscrf", // 퓯
  "\u25c4": "\\blackpointerleft", // ◄
  "\u29bd": "\\uparrowoncircle", // ⦽
  "\ud4c8": "\\scrs", // 퓈
  "\u028d": "\\textturnw", // ʍ
  "\ud4c5": "\\scrp", // 퓅
  "\u2abc": "\\Succ", // ⪼
  "\ud53e": "\\BbbG", // 픾
  "\ud4b6": "\\scra", // 풶
  "\u2b20": "\\pentagon", // ⬠
  "\u225e": "\\measeq", // ≞
  "\u220d": "\\smallni", // ∍
  "\u27e5": "\\whitesquaretickright", // ⟥
  "\ud5c7": "\\sansn", // 헇
  "\u27fb": "\\longmapsfrom", // ⟻
  "\u2a84": "\\gesdotol", // ⪄
  "\u223f": "\\sinewave", // ∿
  "\u24bd": "\\circledH", // Ⓗ
  "\ud751": "\\bfitvartheta", // 흑
  "\ud7b5": "\\bfitsansmu", // ힵ
  "\ud595": "\\bffrakp", // 햕
  "\ud6d0": "\\bfomicron", // 훐
  "\u2a53": "\\Wedge", // ⩓
  "\u200d": "\\zwjoin", // ‍
  "\u297e": "\\upfishtail", // ⥾
  "\ud584": "\\bffrakY", // 햄
  "\u2941": "\\cwcirclearrow", // ⥁
  "\u03b8": "\\texttheta", // θ
  "\ud543": "\\BbbL", // 핃
  "\ud6e1": "\\bfvarpi", // 훡
  "\ud619": "\\itsansR", // 혙
  "\u00d8": "\\O", // Ø
  "\ud42f": "\\bfv", // 퐯
  "\u27dd": "\\vlongdash", // ⟝
  "\ud59a": "\\bffraku", // 햚
  "\u2af6": "\\threedotcolon", // ⫶
  "\ud530": "\\fraks", // 픰
  "\u22ed": "\\ntrianglerighteq", // ⋭
  "\u21d2": "\\Rightarrow", // ⇒
  "\u25b6": "\\blacktriangleright", // ▶
  "\u255a": "\\bduVrH", // ╚
  "\u2206": "\\increment", // ∆
  "\ud71c": "\\bfitAlpha", // 휜
  "\u2782": "\\circledsansthree", // ➂
  "\u290c": "\\leftbkarrow", // ⤌
  "\u2143": "\\sansLinverted", // ⅃
  "\ud758": "\\bfsansGamma", // 흘
  "\ud650": "\\bfitsansU", // 홐
  "\u02e7": "\\tonebarmid", // ˧
  "\u29f8": "\\xsol", // ⧸
  "\ufe36": "\\underparen", // ︶
  "\ud426": "\\bfm", // 퐦
  "\ud7f3": "\\bfsansseven", // ퟳ
  "\u2933": "\\rightcurvedarrow", // ⤳
  "\u2978": "\\gtrarr", // ⥸
  "\u0292": "\\textyogh", // ʒ
  "\u2a8c": "\\gtreqqless", // ⪌
  "\u2a60": "\\wedgedoublebar", // ⩠
  "\u2230": "\\oiiint", // ∰
  "\ud6ff": "\\itdelta", // 훿
  "\ud500": "\\bfscrw", // 픀
  "\u02df": "\\ipacrossaccent", // ˟
  "\u252c": "\\bddvbh", // ┬
  "\u2213": "\\mp", // ∓
  "\ud645": "\\bfitsansJ", // 홅
  "\u0333": "\\textsubdoubleline", // ̳
  "\ud637": "\\itsansv", // 혷
  "\ud6d8": "\\bfchi", // 훘
  "\ud5de": "\\bfsansK", // 헞
  "\ud4de": "\\bfscrO", // 퓞
  "\u2554": "\\bddVrH", // ╔
  "\ud567": "\\Bbbv", // 핧
  "\ud736": "\\bfitalpha", // 휶
  "\u21e4": "\\barleftarrow", // ⇤
  "\ud755": "\\bfitvarpi", // 흕
  "\ud6d6": "\\bfupsilon", // 훖
  "\u26aa": "\\mdwhtcircle", // ⚪
  "\ud4c4": "\\scro", // 퓄
  "\ud715": "\\itpartial", // 휕
  "\ud77d": "\\bfsansxi", // 흽
  "\ud6bd": "\\bfPhi", // 횽
  "\u2a09": "\\bigtimes", // ⨉
  "\ud599": "\\bffrakt", // 햙
  "\u22a4": "\\top", // ⊤
  "\u25e5": "\\urblacktriangle", // ◥
  "\ud60d": "\\itsansF", // 혍
  "\u029f": "\\textscl", // ʟ
  "\ud7e3": "\\sansone", // ퟣ
  "\ud4be": "\\scri", // 풾
  "\u222f": "\\oiint", // ∯
  "\u2a18": "\\intx", // ⨘
  "\u27e6": "\\lBrack", // ⟦
  "\u02db": "\\textogonek", // ˛
  "\u2af8": "\\gggnest", // ⫸
  "\ud452": "\\ite", // 푒
  "\u2ac8": "\\supsim", // ⫈
  "\u27c0": "\\threedangle", // ⟀
  "\u2062": "\\invisibletimes", // ⁢
  "\ud672": "\\ttC", // 홲
  "\u24cc": "\\circledW", // Ⓦ
  "\u0307": "\\dot", // ̇
  "\u25f3": "\\squareurquad", // ◳
  "\u208e": "\\subrparen", // ₎
  "\u24e2": "\\circleds", // ⓢ
  "\u260c": "\\conjunction", // ☌
  "\ud4d1": "\\bfscrB", // 퓑
  "\u2a17": "\\intlarhk", // ⨗
  "\u2289": "\\nsupseteq", // ⊉
  "\ud5fa": "\\bfsansm", // 헺
  "\ud492": "\\bfitq", // 풒
  "\u0320": "\\textsubminus", // ̠
  "\u25d4": "\\circleurquadblack", // ◔
  "\u02c4": "\\textuptr", // ˄
  "\ud5be": "\\sanse", // 햾
  "\ud7d2": "\\bffour", // ퟒ
  "\ud6eb": "\\itKappa", // 훫
  "\u291b": "\\leftdbltail", // ⤛
  "\ud7cb": "\\bfdigamma", // ퟋ
  "\ud77b": "\\bfsansmu", // 흻
  "\ud566": "\\Bbbu", // 핦
  "\u2773": "\\rbrbrak", // ❳
  "\u2231": "\\intclockwise", // ∱
  "\ud7fc": "\\ttsix", // ퟼
  "\u2124": "\\BbbZ", // ℤ
  "\u02cf": "\\textlowacute", // ˏ
  "\u27f3": "\\cwgapcirclearrow", // ⟳
  "\u00a6": "\\textbrokenbar", // ¦
  "\u22fd": "\\varniobar", // ⋽
  "\ud580": "\\bffrakU", // 햀
  "\u22cb": "\\leftthreetimes", // ⋋
  "\u203a": "\\guilsinglright", // ›
  "\u21e1": "\\updasharrow", // ⇡
  "\ud51c": "\\frakY", // 픜
  "\ud5d6": "\\bfsansC", // 헖
  "\ud495": "\\bfitt", // 풕
  "\u2689": "\\blackcircledtwodots", // ⚉
  "\ud5e6": "\\bfsansS", // 헦
  "\u2abf": "\\subsetplus", // ⪿
  "\u2116": "\\textnumero", // №
  "\u2a8b": "\\lesseqqgtr", // ⪋
  "\ud468": "\\bfitA", // 푨
  "\ud798": "\\bfitsansIota", // 힘
  "\u228d": "\\cupdot", // ⊍
  "\u21d0": "\\Leftarrow", // ⇐
  "\ud63b": "\\itsansz", // 혻
  "\u2ab9": "\\precnapprox", // ⪹
  "\u2a11": "\\awint", // ⨑
  "\u2add": "\\forksnot", // ⫝
  "\ud48d": "\\bfitl", // 풍
  "\u01af": "\\Uhorn", // Ư
  "\ud4a2": "\\scrG", // 풢
  "\u2954": "\\barupharpoonright", // ⥔
  "\ud555": "\\Bbbd", // 핕
  "\u0315": "\\ocommatopright", // ̕
  "\ud550": "\\BbbY", // 핐
  "\ud6b1": "\\bfKappa", // 횱
  "\ud6e4": "\\itGamma", // 훤
  "\u0301": "\\acute", // ́
  "\u294c": "\\updownharpoonrightleft", // ⥌
  "\u27df": "\\cirbot", // ⟟
  "\u225d": "\\eqdef", // ≝
  "\ud5d2": "\\sansy", // 헒
  "\ud649": "\\bfitsansN", // 홉
  "\ud69c": "\\tts", // 횜
  "\ud552": "\\Bbba", // 핒
  "\u2925": "\\hksearrow", // ⤥
  "\u29ab": "\\measangleldtosw", // ⦫
  "\ud636": "\\itsansu", // 혶
  "\u23dc": "\\overparen", // ⏜
  "\u21db": "\\Rrightarrow", // ⇛
  "\u2117": "\\textcircledP", // ℗
  "\u222e": "\\oint", // ∮
  "\ud7bc": "\\bfitsanssigma", // ힼ
  "\ud44f": "\\itb", // 푏
  "\ud7d4": "\\bfsix", // ퟔ
  "\ud754": "\\bfitvarrho", // 흔
  "\u03bf": "\\upomicron", // ο
  "\ud611": "\\itsansJ", // 협
  "\u2ae7": "\\Barv", // ⫧
  "\ud654": "\\bfitsansY", // 화
  "\u0306": "\\breve", // ̆
  "\u25b7": "\\triangleright", // ▷
  "\u2986": "\\rParen", // ⦆
  "\ud679": "\\ttJ", // 홹
  "\u295e": "\\leftharpoondownbar", // ⥞
  "\u2a2b": "\\minusfdots", // ⨫
  "\ud778": "\\bfsansiota", // 흸
  "\u2940": "\\acwcirclearrow", // ⥀
  "\u03b9": "\\upiota", // ι
  "\u0395": "\\upEpsilon", // Ε
  "\u2a91": "\\lgE", // ⪑
  "\ud6d5": "\\bftau", // 훕
  "\u02c0": "\\textraiseglotstop", // ˀ
  "\u208d": "\\sublparen", // ₍
  "\u29db": "\\Rvzigzag", // ⧛
  "\ud665": "\\bfitsansp", // 홥
  "\u026b": "\\textltilde", // ɫ
  "\u00a8": "\\textasciidieresis", // ¨
  "\ud55b": "\\Bbbj", // 핛
  "\u266e": "\\natural", // ♮
  "\u2306": "\\vardoublebarwedge", // ⌆
  "\u2a2f": "\\vectimes", // ⨯
  "\ud756": "\\bfsansAlpha", // 흖
  "\u21cd": "\\nLeftarrow", // ⇍
  "\u29d7": "\\blackhourglass", // ⧗
  "\u02b5": "\\textsupturnrrtail", // ʵ
  "\u27db": "\\dashVdash", // ⟛
  "\u03bd": "\\upnu", // ν
  "\ud57a": "\\bffrakO", // 핺
  "\ud624": "\\itsansc", // 혤
  "\ud448": "\\itU", // 푈
  "\u2aed": "\\bNot", // ⫭
  "\u25c1": "\\triangleleft", // ◁
  "\u2ad9": "\\forkv", // ⫙
  "\u22df": "\\curlyeqsucc", // ⋟
  "\u03d0": "\\upvarbeta", // ϐ
  "\u29f1": "\\errbarblackdiamond", // ⧱
  "\u2250": "\\doteq", // ≐
  "\u25d8": "\\inversebullet", // ◘
  "\u21dc": "\\longleftzigzagarrow", // ⇜
  "\ud759": "\\bfsansDelta", // 흙
  "\ud773": "\\bfsansdelta", // 흳
  "\ud547": "\\BbbP", // 핇
  "\u22fc": "\\nis", // ⋼
  "\ud46c": "\\bfitE", // 푬
  "\ud6ed": "\\itMu", // 훭
  "\ud409": "\\bfJ", // 퐉
  "\u0294": "\\textglotstop", // ʔ
  "\ud554": "\\Bbbc", // 핔
  "\ud508": "\\frakE", // 픈
  "\u22e1": "\\nsucccurlyeq", // ⋡
  "\u250a": "\\bdquadvdash", // ┊
  "\u2311": "\\sqlozenge", // ⌑
  "\u23a3": "\\lbracklend", // ⎣
  "\ud5ac": "\\sansM", // 햬
  "\u25a0": "\\blacksquare", // ■
  "\u03dd": "\\updigamma", // ϝ
  "\u22b7": "\\imageof", // ⊷
  "\ud64d": "\\bfitsansR", // 홍
  "\ud475": "\\bfitN", // 푵
  "\ud5a0": "\\sansA", // 햠
  "\u23ab": "\\rbraceuend", // ⎫
  "\u007c": "\\vert", // |
  "\u253c": "\\bdbvbh", // ┼
  "\ud6ce": "\\bfnu", // 훎
  "\ud59e": "\\bffraky", // 햞
  "\ud62e": "\\itsansm", // 혮
  "\u2a38": "\\odiv", // ⨸
  "\u2567": "\\bduvbH", // ╧
  "\u21c4": "\\rightleftarrows", // ⇄
  "\u2a49": "\\capbarcup", // ⩉
  "\u033f": "\\textdoubleoverline", // ̿
  "\u2a9c": "\\eqqslantgtr", // ⪜
  "\u003e": "\\greater", // >
  "\u25b0": "\\parallelogramblack", // ▰
  "\u02cd": "\\textlowmacron", // ˍ
  "\ud438": "\\itE", // 퐸
  "\ud47d": "\\bfitV", // 푽
  "\u25af": "\\vrectangle", // ▯
  "\ud4a7": "\\scrL", // 풧
  "\u02d3": "\\textlhalfring", // ˓
  "\ud763": "\\bfsansXi", // 흣
  "\ud4c7": "\\scrr", // 퓇
  "\u23b9": "\\rvboxline", // ⎹
  "\ud65b": "\\bfitsansf", // 홛
  "\u2976": "\\ltlarr", // ⥶
  "\u039c": "\\upMu", // Μ
  "\u0272": "\\textltailn", // ɲ
  "\ud5e2": "\\bfsansO", // 헢
  "\ud407": "\\bfH", // 퐇
  "\u0237": "\\textdotlessj", // ȷ
  "\u306e": "\\hiraganano", // の
  "\u2272": "\\lesssim", // ≲
  "\u239d": "\\lparenlend", // ⎝
  "\u2ac1": "\\submult", // ⫁
  "\u03d1": "\\upvartheta", // ϑ
  "\ud5fe": "\\bfsansq", // 헾
  "\ud6ef": "\\itXi", // 훯
  "\ud4e8": "\\bfscrY", // 퓨
  "\ud416": "\\bfW", // 퐖
  "\ud797": "\\bfitsansTheta", // 힗
  "\u2a3c": "\\intprod", // ⨼
  "\u223c": "\\sim,", // ∼
  "\u2242": "\\eqsim", // ≂
  "\u21ae": "\\nleftrightarrow", // ↮
  "\u200a": "\\hspace", // no symbol
  "\u2293": "\\sqcap", // ⊓
  "\u2034": "\\trprime", // ‴
  "\ud480": "\\bfitY", // 풀
  "\ud7ef": "\\bfsansthree", // ퟯ
  "\u2249": "\\napprox", // ≉
  "\u2157": "\\fracthreefifths", // ⅗
  "\ud61e": "\\itsansW", // 혞
  "\ud757": "\\bfsansBeta", // 흗
  "\ud6e8": "\\itEta", // 훨
  "\u2294": "\\sqcup", // ⊔
  "\ud720": "\\bfitEpsilon", // 휠
  "\ud402": "\\bfC", // 퐂
  "\u2ab3": "\\preceqq", // ⪳
  "\u029d": "\\textctj", // ʝ
  "\u2ae5": "\\DashV", // ⫥
  "\u02d2": "\\textrhalfring", // ˒
  "\u2946": "\\leftarrowplus", // ⥆
  "\u278f": "\\blackcircledsanssix", // ➏
  "\u2975": "\\rightarrowapprox", // ⥵
  "\ud433": "\\bfz", // 퐳
  "\ud465": "\\itx", // 푥
  "\ud674": "\\ttE", // 홴
  "\ud49e": "\\scrC", // 풞
  "\u00aa": "\\textordfeminine", // ª
  "\ud529": "\\frakl", // 픩
  "\u2246": "\\simneqq", // ≆
  "\u2a4a": "\\twocups", // ⩊
  "\ud515": "\\frakR", // 픕
  "\u25bc": "\\bigblacktriangledown", // ▼
  "\u299d": "\\rightanglemdot", // ⦝
  "\u22c2": "\\bigcap", // ⋂
  "\u2032": "\\textprime", // ′
  "\u23e4": "\\strns", // ⏤
  "\u24ca": "\\circledU", // Ⓤ
  "\ud4f3": "\\bfscrj", // 퓳
  "\ud6f7": "\\itPhi", // 훷
  "\u2ad0": "\\csup", // ⫐
  "\ud6c5": "\\bfdelta", // 훅
  "\u0262": "\\textscg", // ɢ
  "\ud6ca": "\\bfiota", // 훊
  "\ud592": "\\bffrakm", // 햒
  "\u2a31": "\\timesbar", // ⨱
  "\ud7e2": "\\sanszero", // ퟢ
  "\ud6c7": "\\bfzeta", // 훇
  "\ud53f": "\\BbbH", // 픿
  "\ud43f": "\\itL", // 퐿
  "\u2423": "\\textvisiblespace", // ␣
  "\u2569": "\\bduVbH", // ╩
  "\ud504": "\\frakA", // 프
  "\ufe38": "\\underbrace", // ︸
  "\ud613": "\\itsansL", // 혓
  "\ud5fb": "\\bfsansn", // 헻
  "\u26b2": "\\neuter", // ⚲
  "\u02a6": "\\texttslig", // ʦ
  "\u2989": "\\llangle", // ⦉
  "\ud71e": "\\bfitGamma", // 휞
  "\ud732": "\\bfitChi", // 휲
  "\u24d8": "\\circledi", // ⓘ
  "\u296b": "\\dashleftharpoondown", // ⥫
  "\u21f7": "\\nvleftarrow", // ⇷
  "\u228e": "\\uplus", // ⊎
  "\ud4ae": "\\scrS", // 풮
  "\u23b4": "\\overbracket", // ⎴
  "\u2ab8": "\\succapprox", // ⪸
  "\u2322": "\\smallfrown", // ⌢
  "\u21b1": "\\Rsh", // ↱
  "\u25c0": "\\blacktriangleleft", // ◀
  "\ud7c9": "\\bfitsansvarpi", // ퟉
  "\u215a": "\\fracfivesixths", // ⅚
  "\u0153": "\\oe", // œ
  "\u22d0": "\\Subset", // ⋐
  "\u23b2": "\\sumtop", // ⎲
  "\u294d": "\\updownharpoonleftright", // ⥍
  "\ud653": "\\bfitsansX", // 홓
  "\u2af5": "\\nhVvert", // ⫵
  "\ud734": "\\bfitOmega", // 휴
  "\u2982": "\\typecolon", // ⦂
  "\ud463": "\\itv", // 푣
  "\u297c": "\\leftfishtail", // ⥼
  "\ud71b": "\\itvarpi", // 휛
  "\u29b9": "\\operp", // ⦹
  "\u273d": "\\dingasterisk", // ✽
  "\u29f5": "\\opbackslash", // ⧵
  "\u21a0": "\\twoheadrightarrow", // ↠
  "\u2057": "\\qprime", // ⁗
  "\ud6dd": "\\bfvartheta", // 훝
  "\u2a3d": "\\intprodr", // ⨽
  "\u2a6a": "\\dotsim", // ⩪
  "\u2089": "\\subnine", // ₉
  "\ud462": "\\itu", // 푢
  "\u2791": "\\blackcircledsanseight", // ➑
  "\u220e": "\\QED", // ∎
  "\u3014": "\\lbrbrak", // 〔
  "\u250c": "\\bddvrh", // ┌
  "\u2acb": "\\subsetneqq", // ⫋
  "\u0313": "\\osmooth", // ̓
  "\u20ac": "\\euro", // €
  "\u2af3": "\\parsim", // ⫳
  "\ud4d2": "\\bfscrC", // 퓒
  "\ud590": "\\bffrakk", // 햐
  "\u25d2": "\\circlebottomhalfblack", // ◒
  "\u02da": "\\textringaccent", // ˚
  "\ud722": "\\bfitEta", // 휢
  "\u298d": "\\lbrackultick", // ⦍
  "\u222a": "\\cup", // ∪
  "\u030b": "\\H", // ̋
  "\ud51d": "\\frakZ", // 픝
  "\ud7c1": "\\bfitsanspsi", // ퟁ
  "\u2aee": "\\revnmid", // ⫮
  "\u294b": "\\leftrightharpoondownup", // ⥋
  "\u29c2": "\\cirscir", // ⧂
  "\u290f": "\\dbkarow", // ⤏
  "\ud4f4": "\\bfscrk", // 퓴
  "\u2a55": "\\wedgeonwedge", // ⩕
  "\ud777": "\\bfsanstheta", // 흷
  "\u25a8": "\\squareneswfill", // ▨
  "\u215c": "\\fracthreeeighths", // ⅜
  "\ud76f": "\\bfsansnabla", // 흯
  "\ud622": "\\itsansa", // 혢
  "\u0362": "\\texttieunderrightarrow", // ͢
  "\ud501": "\\bfscrx", // 픁
  "\ud594": "\\bffrako", // 햔
  "\ud6d9": "\\bfpsi", // 훙
  "\ud528": "\\frakk", // 픨
  "\ud65c": "\\bfitsansg", // 활
  "\u29e2": "\\shuffle", // ⧢
  "\ud5ef": "\\bfsansb", // 헯
  "\ud666": "\\bfitsansq", // 홦
  "\ud4a6": "\\scrK", // 풦
  "\ud607": "\\bfsansz", // 혇
  "\u22e4": "\\sqsubsetneq", // ⋤
  "\u24cf": "\\circledZ", // Ⓩ
  "\u026c": "\\textbeltl", // ɬ
  "\u2572": "\\bdnwse", // ╲
  "\u2942": "\\rightarrowshortleftarrow", // ⥂
  "\ud68f": "\\ttf", // 횏
  "\u23a9": "\\lbracelend", // ⎩
  "\ufe37": "\\overbrace", // ︷
  "\ud490": "\\bfito", // 풐
  "\u2a5c": "\\midbarwedge", // ⩜
  "\u21b7": "\\curvearrowright", // ↷
  "\u2a88": "\\gneq", // ⪈
  "\u2a46": "\\cupovercap", // ⩆
  "\ud70c": "\\itrho", // 휌
  "\ud4e4": "\\bfscrU", // 퓤
  "\u256a": "\\bdbvbH", // ╪
  "\u2a22": "\\ringplus", // ⨢
  "\u20d1": "\\rightharpoonaccent", // ⃑
  "\ud6f2": "\\itRho", // 훲
  "\u22cd": "\\backsimeq", // ⋍
  "\ud779": "\\bfsanskappa", // 흹
  "\ud5e8": "\\bfsansU", // 헨
  "\u2a85": "\\lessapprox", // ⪅
  "\ud6fe": "\\itgamma", // 훾
  "\u24d0": "\\circleda", // ⓐ
  "\u298b": "\\lbrackubar", // ⦋
  "\u21d1": "\\Uparrow,", // ⇑
  "\u2a62": "\\doublebarvee", // ⩢
  "\ud40e": "\\bfO", // 퐎
  "\ud519": "\\frakV", // 픙
  "\ud4c2": "\\scrm", // 퓂
  "\ud612": "\\itsansK", // 혒
  "\ud70f": "\\ittau", // 휏
  "\u2243": "\\simeq", // ≃
  "\ud5f6": "\\bfsansi", // 헶
  "\ud48e": "\\bfitm", // 풎
  "\ud67c": "\\ttM", // 홼
  "\ud46e": "\\bfitG", // 푮
  "\ud7a2": "\\bfitsansSigma", // 힢
  "\u2a8e": "\\gsime", // ⪎
  "\u2559": "\\bduVrh", // ╙
  "\ud752": "\\bfitvarkappa", // 흒
  "\u026f": "\\textturnm", // ɯ
  "\u299e": "\\angles", // ⦞
  "\u2a43": "\\barcap", // ⩃
  "\u205f": "\\medmathspace", // no symbol
  "\u2a6c": "\\simminussim", // ⩬
  "\u2082": "\\subtwo", // ₂
  "\u2129": "\\turnediota", // ℩
  "\u2aea": "\\barV", // ⫪
  "\u2ace": "\\rsqhook", // ⫎
  "\u25d1": "\\circlerighthalfblack", // ◑
  "\ud6a1": "\\ttx", // 횡
  "\u27c1": "\\whiteinwhitetriangle", // ⟁
  "\u0271": "\\textltailm", // ɱ
  "\u2a7a": "\\gtcir", // ⩺
  "\u2a78": "\\equivDD", // ⩸
  "\ud686": "\\ttW", // 횆
  "\u228f": "\\sqsubset", // ⊏
  "\ud572": "\\bffrakG", // 핲
  "\ud4cd": "\\scrx", // 퓍
  "\ud483": "\\bfitb", // 풃
  "\u22bd": "\\barvee", // ⊽
  "\u2973": "\\leftarrowsimilar", // ⥳
  "\u25ad": "\\hrectangle", // ▭
  "\u26a0": "\\triangleexclam", // ⚠
  "\ud5ab": "\\sansL", // 햫
  "\ud4e9": "\\bfscrZ", // 퓩
  "\ud42a": "\\bfq", // 퐪
  "\u25bb": "\\whitepointerright", // ▻
  "\u20a1": "\\textcolonmonetary", // ₡
  "\u2038": "\\caretinsert", // ‸
  "\ud58c": "\\bffrakg", // 햌
  "\ud41a": "\\bfa", // 퐚
  "\ud7ec": "\\bfsanszero", // ퟬ
  "\u02e5": "\\tonebarextrahigh", // ˥
  "\u2005": "\\thickspace", // no symbol
  "\u2a9a": "\\eqqgtr", // ⪚
  "\ud570": "\\bffrakE", // 핰
  "\u27f1": "\\DDownarrow", // ⟱
  "\u21a9": "\\hookleftarrow", // ↩
  "\ud461": "\\itt", // 푡
  "\ud795": "\\bfitsansZeta", // 힕
  "\u2a2a": "\\minusdot", // ⨪
  "\ud4fa": "\\bfscrq", // 퓺
  "\u00fe": "\\th", // þ
  "\ud770": "\\bfsansalpha", // 흰
  "\u2115": "\\BbbN", // ℕ
  "\u0279": "\\textturnr", // ɹ
  "\u0274": "\\textscn", // ɴ
  "\u0393": "\\upGamma", // Γ
  "\u2a39": "\\triangleplus", // ⨹
  "\ud436": "\\itC", // 퐶
  "\u2564": "\\bddvbH", // ╤
  "\u278d": "\\blackcircledsansfour", // ➍
  "\ud49d": "\\scrB", // 풝
  "\ud55e": "\\Bbbm", // 핞
  "\u00a2": "\\textcent", // ¢
  "\ud5e1": "\\bfsansN", // 헡
  "\ud4a3": "\\scrH", // 풣
  "\ud4b2": "\\scrW", // 풲
  "\u23de": "\\overbrace", // ⏞
  "\ud6c2": "\\bfalpha", // 훂
  "\u29a8": "\\measanglerutone", // ⦨
  "\u29b6": "\\circledvert", // ⦶
  "\u0276": "\\textscoelig", // ɶ
  "\u028a": "\\textupsilon", // ʊ
  "\u25fe": "\\mdsmblksquare", // ◾
  "\u2a26": "\\plussim", // ⨦
  "\ud451": "\\itd", // 푑
  "\u2283": "\\supset", // ⊃
  "\u24ce": "\\circledY", // Ⓨ
  "\u2991": "\\langledot", // ⦑
  "\ud526": "\\fraki", // 픦
  "\u3012": "\\postalmark", // 〒
  "\ud668": "\\bfitsanss", // 홨
  "\ud667": "\\bfitsansr", // 홧
  "\ud5cd": "\\sanst", // 헍
  "\u2a0e": "\\intBar", // ⨎
  "\u263e": "\\leftmoon", // ☾
  "\u21b2": "\\Ldsh", // ↲
  "\u0325": "\\textsubring", // ̥
  "\u2211": "\\sum", // ∑
  "\ud458": "\\itk", // 푘
  "\u02d7": "\\textretracted", // ˗
  "\u29b3": "\\emptysetoarr", // ⦳
  "\u29f7": "\\rsolbar", // ⧷
  "\u24c3": "\\circledN", // Ⓝ
  "\ud660": "\\bfitsansk", // 홠
  "\u25a2": "\\squoval", // ▢
  "\u24e8": "\\circledy", // ⓨ
  "\u29b8": "\\obslash", // ⦸
  "\u22a0": "\\boxtimes", // ⊠
  "\u2abb": "\\Prec", // ⪻
  "\ud608": "\\itsansA", // 혈
  "\u02b1": "\\textsuphth", // ʱ
  "\u1d85": "\\textpalhookl", // ᶅ
  "\u25bd": "\\bigtriangledown", // ▽
  "\u03f6": "\\upbackepsilon", // ϶
  "\ud7bf": "\\bfitsansphi", // ힿ
  "\u22e7": "\\gnsim", // ⋧
  "\u23a6": "\\rbracklend", // ⎦
  "\ud512": "\\frakO", // 픒
  "\u0263": "\\textgamma", // ɣ
  "\u034c": "\\textdoubletilde", // ͌
  "\u03b2": "\\textbeta", // β
  "\ud516": "\\frakS", // 픖
  "\u2952": "\\barleftharpoonup", // ⥒
  "\u296d": "\\dashrightharpoondown", // ⥭
  "\u22a1": "\\boxdot", // ⊡
  "\ud545": "\\BbbN", // 핅
  "\u2156": "\\fractwofifths", // ⅖
  "\ud56b": "\\Bbbz", // 핫
  "\u2047": "\\Question", // ⁇
  "\u2207": "\\nabla", // ∇
  "\u2305": "\\varbarwedge", // ⌅
  "\u2033": "\\dprime", // ″
  "\ud6c1": "\\bfnabla", // 훁
  "\ud64a": "\\bfitsansO", // 홊
  "\u255d": "\\bduVlH", // ╝
  "\u204f": "\\textsemicolonreversed", // ⁏
  "\ud5d5": "\\bfsansB", // 헕
  "\ud533": "\\frakv", // 픳
  "\u29d4": "\\lftimes", // ⧔
  "\u298a": "\\rrangle", // ⦊
  "\u20ee": "\\underleftarrow", // ⃮
  "\u00a4": "\\textcurrency", // ¤
  "\u01b5": "\\Zbar", // Ƶ
  "\ud6ee": "\\itNu", // 훮
  "\ud73c": "\\bfiteta", // 휼
  "\ud4e5": "\\bfscrV", // 퓥
  "\ud430": "\\bfw", // 퐰
  "\u22cf": "\\curlywedge", // ⋏
  "\ud69e": "\\ttu", // 횞
  "\ud5d9": "\\bfsansF", // 헙
  "\u0396": "\\upZeta", // Ζ
  "\ud76c": "\\bfsansChi", // 희
  "\u2553": "\\bddVrh", // ╓
  "\u251c": "\\bdbvrh", // ├
  "\u21a7": "\\mapsdown", // ↧
  "\u22f7": "\\isinobar", // ⋷
  "\ud5b9": "\\sansZ", // 햹
  "\u02c7": "\\textrise", // ˇ
  "\u2ae1": "\\perps", // ⫡
  "\ud75b": "\\bfsansZeta", // 흛
  "\u2244": "\\nsime", // ≄
  "\ud7a8": "\\bfitsansOmega", // 힨
  "\u223e": "\\ac", // ∾
  "\u2afd": "\\sslash", // ⫽
  "\ud70e": "\\itsigma", // 휎
  "\ud73d": "\\bfittheta", // 휽
  "\u2037": "\\textbacktrprime", // ‷
  "\u210c": "\\frakH", // ℌ
  "\u203e": "\\overline", // ‾
  "\ud464": "\\itw", // 푤
  "\u29f9": "\\xbsol", // ⧹
  "\ud48f": "\\bfitn", // 풏
  "\u29ed": "\\blackcircledownarrow", // ⧭
  "\u0195": "\\texthvlig", // ƕ
  "\u211b": "\\scrR", // ℛ
  "\u25f8": "\\ultriangle", // ◸
  "\u23a0": "\\rparenlend", // ⎠
  "\u0192": "\\textflorin", // ƒ
  "\u2a98": "\\egsdot", // ⪘
  "\u2aab": "\\lat", // ⪫
  "\u21c2": "\\downharpoonright", // ⇂
  "\u2551": "\\bdVrule", // ║
  "\u2146": "\\itBbbd", // ⅆ
  "\ud441": "\\itN", // 푁
  "\u2a80": "\\gesdot", // ⪀
  "\ud796": "\\bfitsansEta", // 힖
  "\u005b": "\\lbrack", // [
  "\u213e": "\\BbbGamma", // ℾ
  "\u203b": "\\textreferencemark", // ※
  "\ud5dc": "\\bfsansI", // 헜
  "\u20eb": "\\textoverlaytwosolidus", // ⃫
  "\u2209": "\\notin", // ∉
  "\u032d": "\\textsubcircum", // ̭
  "\u2a15": "\\pointint", // ⨕
  "\u23e7": "\\elinters", // ⏧
  "\ud616": "\\itsansO", // 혖
  "\ud641": "\\bfitsansF", // 홁
  "\ud422": "\\bfi", // 퐢
  "\u221d": "\\varpropto", // ∝
  "\u211c": "\\Re,", // ℜ
  "\ud597": "\\bffrakr", // 햗
  "\u24d1": "\\circledb", // ⓑ
  "\u212c": "\\scrB", // ℬ
  "\u263f": "\\mercury", // ☿
  "\u03b3": "\\upgamma", // γ
  "\u296f": "\\downupharpoonsleftright", // ⥯
  "\u2918": "\\nVtwoheadrightarrowtail", // ⤘
  "\u02cb": "\\textgrave", // ˋ
  "\u2039": "\\guilsinglleft", // ‹
  "\u3015": "\\rbrbrak", // 〕
  "\u0250": "\\textturna", // ɐ
  "\ud5e7": "\\bfsansT", // 헧
  "\ud58f": "\\bffrakj", // 햏
  "\ud6ea": "\\itIota", // 훪
  "\ud7fb": "\\ttfive", // ퟻ
  "\u033d": "\\textovercross", // ̽
  "\u03c0": "\\uppi", // π
  "\ud582": "\\bffrakW", // 햂
  "\u2aa3": "\\partialmeetcontraction", // ⪣
  "\u0265": "\\textturnh", // ɥ
  "\u237c": "\\rangledownzigzagarrow", // ⍼
  "\ud4e6": "\\bfscrW", // 퓦
  "\u2588": "\\blockfull", // █
  "\u27d6": "\\rightouterjoin", // ⟖
  "\u25b3": "\\bigtriangleup", // △
  "\ud4ac": "\\scrQ", // 풬
  "\u00b4": "\\textasciiacute", // ´
  "\u0398": "\\upTheta", // Θ
  "\ud494": "\\bfits", // 풔
  "\u29a4": "\\angleubar", // ⦤
  "\u031c": "\\textsublhalfring", // ̜
  "\ud614": "\\itsansM", // 혔
  "\u207f": "\\supn", // ⁿ
  "\u03f5": "\\upepsilon", // ϵ
  "\u20ec": "\\overrightharpoondown", // ⃬
  "\ud5dd": "\\bfsansJ", // 헝
  "\u231a": "\\watchicon", // ⌚
  "\u20d2": "\\vertoverlay", // ⃒
  "\u2945": "\\rightarrowplus", // ⥅
  "\u220c": "\\nni", // ∌
  "\u0259": "\\textschwa", // ə
  "\u02e0": "\\ipasupgamma", // ˠ
  "\u21c6": "\\leftrightarrows", // ⇆
  "\u03a0": "\\upPi", // Π
  "\u201b": "\\quotsinglright", // ‛
  "\u29a9": "\\measanglelutonw", // ⦩
  "\u25ce": "\\bullseye", // ◎
  "\ud5a6": "\\sansG", // 햦
  "\u2043": "\\hyphenbullet", // ⁃
  "\u223b": "\\kernelcontraction", // ∻
  "\u2a32": "\\btimes", // ⨲
  "\u2ab7": "\\precapprox", // ⪷
  "\ud5c0": "\\sansg", // 헀
  "\u2aa0": "\\simgE", // ⪠
  "\u03bc": "\\upmu", // μ
  "\u2080": "\\subzero", // ₀
  "\ud605": "\\bfsansx", // 혅
  "\u2968": "\\rightleftharpoonsup", // ⥨
  "\u2196": "\\nwarrow", // ↖
  "\ud6af": "\\bfTheta", // 횯
  "\u2a19": "\\intcap", // ⨙
  "\u2648": "\\aries", // ♈
  "\u21f5": "\\downuparrows", // ⇵
  "\u03a6": "\\upPhi", // Φ
  "\u2a52": "\\veeodot", // ⩒
  "\u2309": "\\rceil", // ⌉
  "\u21f9": "\\nvleftrightarrow", // ⇹
  "\ud5da": "\\bfsansG", // 헚
  "\u2687": "\\circledtwodots", // ⚇
  "\u27f0": "\\UUparrow", // ⟰
  "\ud750": "\\bfitepsilon", // 흐
  "\u24cb": "\\circledV", // Ⓥ
  "\u29a2": "\\turnangle", // ⦢
  "\ud5b0": "\\sansQ", // 햰
  "\u0286": "\\textctesh", // ʆ
  "\ud5a2": "\\sansC", // 햢
  "\u2052": "\\textdiscount", // ⁒
  "\u033c": "\\textseagull", // ̼
  "\ud425": "\\bfl", // 퐥
  "\ud482": "\\bfita", // 풂
  "\u267e": "\\acidfree", // ♾
  "\ud63d": "\\bfitsansB", // 혽
  "\ud551": "\\BbbZ", // 핑
  "\u24bb": "\\circledF", // Ⓕ
  "\u22dc": "\\eqless", // ⋜
  "\ud772": "\\bfsansgamma", // 흲
  "\ud473": "\\bfitL", // 푳
  "\u2988": "\\rrparenthesis", // ⦈
  "\ud74b": "\\bfitphi", // 흋
  "\ud6db": "\\bfpartial", // 훛
  "\u2223": "\\shortmid", // ∣
  "\u0296": "\\textinvglotstop", // ʖ
  "\u290e": "\\leftdbkarrow", // ⤎
  "\ud4cc": "\\scrw", // 퓌
  "\ud400": "\\bfA", // 퐀
  "\ud4cb": "\\scrv", // 퓋
  "\ud557": "\\Bbbf", // 핗
  "\ud444": "\\itQ", // 푄
  "\u2a93": "\\lesges", // ⪓
  "\u24d3": "\\circledd", // ⓓ
  "\ud531": "\\frakt", // 픱
  "\u2a6f": "\\hatapprox", // ⩯
  "\u2229": "\\cap", // ∩
  "\u22da": "\\lesseqgtr", // ⋚
  "\u2a65": "\\rsub", // ⩥
  "\u255e": "\\bdbvrH", // ╞
  "\u292f": "\\fdiagovnearrow", // ⤯
  "\u29b1": "\\emptysetobar", // ⦱
  "\u00d7": "\\times", // ×
  "\u02c5": "\\textdptr", // ˅
  "\u2340": "\\APLnotbackslash", // ⍀
  "\u2660": "\\spadesuit", // ♠
  "\u031d": "\\textraising", // ̝
  "\u293f": "\\ccwundercurvearrow", // ⤿
  "\u00c6": "\\AE", // Æ
  "\u03b4": "\\updelta", // δ
  "\u2202": "\\partial", // ∂
  "\u0267": "\\texththeng", // ɧ
  "\u22c3": "\\bigcup", // ⋃
  "\u2609": "\\astrosun", // ☉
  "\u0282": "\\textrtails", // ʂ
  "\u2323": "\\smile,", // ⌣
  "\ud675": "\\ttF", // 홵
  "\u027f": "\\textlhti", // ɿ
  "\u2192": "\\rightarrow,", // →
  "\u21ba": "\\acwopencirclearrow", // ↺
  "\u2a7d": "\\leqslant", // ⩽
  "\u2300": "\\diameter", // ⌀
  "\u2558": "\\bduvrH", // ╘
  "\u21b8": "\\barovernorthwestarrow", // ↸
  "\u01c0": "\\textpipe", // ǀ
  "\u2a6e": "\\asteq", // ⩮
  "\u0251": "\\textscripta", // ɑ
  "\u0335": "\\textoverlayshortstroke", // ̵
  "\u033e": "\\textvtilde", // ̾
  "\ud65d": "\\bfitsansh", // 홝
  "\ud538": "\\BbbA", // 픸
  "\u2287": "\\supseteq", // ⊇
  "\u0318": "\\textadvancing", // ̘
  "\u213c": "\\Bbbpi", // ℼ
  "\ud7d5": "\\bfseven", // ퟕ
  "\ud532": "\\fraku", // 픲
  "\ud61f": "\\itsansX", // 혟
  "\ud685": "\\ttV", // 횅
  "\u2790": "\\blackcircledsansseven", // ➐
  "\u24e7": "\\circledx", // ⓧ
  "\u29c5": "\\boxbslash", // ⧅
  "\u25b2": "\\bigblacktriangleup", // ▲
  "\u25ff": "\\lrtriangle", // ◿
  "\ud41d": "\\bfd", // 퐝
  "\ud6fa": "\\itOmega", // 훺
  "\u2118": "\\wp", // ℘
  "\u255c": "\\bduVlh", // ╜
  "\ud429": "\\bfp", // 퐩
  "\u29f6": "\\dsol", // ⧶
  "\u25b1": "\\parallelogram", // ▱
  "\u200c": "\\zwnonjoin", // ‌
  "\ud53b": "\\BbbD", // 픻
  "\ud718": "\\itvarkappa", // 휘
  "\ud498": "\\bfitw", // 풘
  "\ud5d4": "\\bfsansA", // 헔
  "\ud7fd": "\\ttseven", // ퟽
  "\ud4b1": "\\scrV", // 풱
  "\u2201": "\\complement", // ∁
  "\ud692": "\\tti", // 횒
  "\ud691": "\\tth", // 횑
  "\u2140": "\\Bbbsum", // ⅀
  "\u02b0": "\\textsuph", // ʰ
  "\u027a": "\\textturnlonglegr", // ɺ
  "\u0266": "\\texthth", // ɦ
  "\u22ae": "\\nVdash", // ⊮
  "\ud71a": "\\itvarrho", // 휚
  "\ud644": "\\bfitsansI", // 홄
  "\ud738": "\\bfitgamma", // 휸
  "\u29cc": "\\triangles", // ⧌
  "\ud45a": "\\itm", // 푚
  "\u2009": "\\thinspace", // no symbol
  "\u29cf": "\\ltrivb", // ⧏
  "\ud61d": "\\itsansV", // 혝
  "\u03a8": "\\upPsi", // Ψ
  "\u27e0": "\\lozengeminus", // ⟠
  "\u29bb": "\\olcross", // ⦻
  "\u2903": "\\nvRightarrow", // ⤃
  "\u21c3": "\\downharpoonleft", // ⇃
  "\u2649": "\\taurus", // ♉
  "\u2282": "\\subset", // ⊂
  "\u2aff": "\\bigtalloblong", // ⫿
  "\ud683": "\\ttT", // 횃
  "\u2075": "\\supfive", // ⁵
  "\u219a": "\\nleftarrow", // ↚
  "\u22b9": "\\hermitmatrix", // ⊹
  "\u2956": "\\barleftharpoondown", // ⥖
  "\ud5bb": "\\sansb", // 햻
  "\u29e5": "\\eqvparsl", // ⧥
  "\u2ac2": "\\supmult", // ⫂
  "\u0391": "\\upAlpha", // Α
  "\u22a3": "\\dashv", // ⊣
  "\u2ad3": "\\subsup", // ⫓
  "\ud6da": "\\bfomega", // 훚
  "\ud72e": "\\bfitSigma", // 휮
  "\ud568": "\\Bbbw", // 함
  "\u2269": "\\gneqq", // ≩
  "\ud455": "\\ith", // 푕
  "\u29dc": "\\iinfin", // ⧜
  "\ud73f": "\\bfitkappa", // 휿
  "\ud4d0": "\\bfscrA", // 퓐
  "\u25e4": "\\ulblacktriangle", // ◤
  "\u0234": "\\textctl", // ȴ
  "\u29ce": "\\rtriltri", // ⧎
  "\ud5a4": "\\sansE", // 햤
  "\ud680": "\\ttQ", // 횀
  "\u2a73": "\\eqqsim", // ⩳
  "\u2917": "\\nvtwoheadrightarrowtail", // ⤗
  "\u2ad5": "\\subsub", // ⫕
  "\u2641": "\\earth", // ♁
  "\ud46f": "\\bfitH", // 푯
  "\u0257": "\\texthtd", // ɗ
  "\ud4b7": "\\scrb", // 풷
  "\u0316": "\\textsubgrave", // ̖
  "\u00ba": "\\textordmasculine", // º
  "\u0127": "\\textcrh", // ħ
  "\u01aa": "\\textlooptoprevesh", // ƪ
  "\u2149": "\\itBbbj", // ⅉ
  "\ud4e1": "\\bfscrR", // 퓡
  "\u2953": "\\rightharpoonupbar", // ⥓
  "\u2713": "\\checkmark", // ✓
  "\ud7dd": "\\Bbbfive", // ퟝ
  "\u29b7": "\\circledparallel", // ⦷
  "\u2a5f": "\\wedgebar", // ⩟
  "\ud477": "\\bfitP", // 푷
  "\u2235": "\\because", // ∵
  "\u2780": "\\circledsansone", // ➀
  "\u296e": "\\updownharpoonsleftright", // ⥮
  "\u21c7": "\\leftleftarrows", // ⇇
  "\u03ba": "\\upkappa", // κ
  "\u0255": "\\textctc", // ɕ
  "\u2928": "\\toea", // ⤨
  "\u2a36": "\\otimeshat", // ⨶
  "\ud6ab": "\\bfDelta", // 횫
  "\u29d9": "\\rvzigzag", // ⧙
  "\ud6b6": "\\bfOmicron", // 횶
  "\u2adb": "\\mlcp", // ⫛
  "\u296a": "\\leftharpoonupdash", // ⥪
  "\ud60e": "\\itsansG", // 혎
  "\ud6c9": "\\bftheta", // 훉
  "\ud4b3": "\\scrX", // 풳
  "\u25e8": "\\squarerightblack", // ◨
  "\u2566": "\\bddVbH", // ╦
  "\ud576": "\\bffrakK", // 핶
  "\u22d2": "\\Cap", // ⋒
  "\u24df": "\\circledp", // ⓟ
  "\ud669": "\\bfitsanst", // 황
  "\ud511": "\\frakN", // 픑
  "\u2adc": "\\forks", // ⫝̸
  "\u22e5": "\\sqsupsetneq", // ⋥
  "\ud502": "\\bfscry", // 픂
  "\u24e1": "\\circledr", // ⓡ
  "\u2786": "\\circledsansseven", // ➆
  "\u2a70": "\\approxeqq", // ⩰
  "\ud51a": "\\frakW", // 픚
  "\u039b": "\\upLambda", // Λ
  "\u01c2": "\\textdoublebarpipe", // ǂ
  "\u23b8": "\\lvboxline", // ⎸
  "\ud4a1": "\\scrF", // 풡
  "\ud574": "\\bffrakI", // 해
  "\u0277": "\\textcloseomega", // ɷ
  "\u230a": "\\lfloor", // ⌊
  "\ud59f": "\\bffrakz", // 햟
  "\u2ae3": "\\dashV", // ⫣
  "\u0289": "\\textbaru", // ʉ
  "\u2275": "\\ngtrsim", // ≵
  "\u21e9": "\\downwhitearrow", // ⇩
  "\u2aa2": "\\Gt", // ⪢
  "\u2957": "\\rightharpoondownbar", // ⥗
  "\u29f2": "\\errbarcircle", // ⧲
  "\ud604": "\\bfsansw", // 현
  "\u29d5": "\\rftimes", // ⧕
  "\u1d84": "\\textlhookk", // ᶄ
  "\u2a3b": "\\triangletimes", // ⨻
  "\ud69f": "\\ttv", // 횟
  "\u223d": "\\backsim", // ∽
  "\u2292": "\\sqsupseteq", // ⊒
  "\u24db": "\\circledl", // ⓛ
  "\ud456": "\\iti", // 푖
  "\ud621": "\\itsansZ", // 혡
  "\u2921": "\\nwsearrow", // ⤡
  "\ud7aa": "\\bfitsansalpha", // 힪
  "\ud6be": "\\bfChi", // 횾
  "\u2a23": "\\plushat", // ⨣
  "\u211a": "\\BbbQ", // ℚ
  "\u233d": "\\obar", // ⌽
  "\ud68a": "\\tta", // 횊
  "\u0268": "\\textbari", // ɨ
  "\ud559": "\\Bbbh", // 학
  "\u031f": "\\textsubplus", // ̟
  "\ud523": "\\frakf", // 픣
  "\u21fd": "\\leftarrowtriangle", // ⇽
  "\u24c2": "\\circledM", // Ⓜ
  "\u2a2c": "\\minusrdots", // ⨬
  "\ud782": "\\bfsanssigma", // 힂
  "\ud4c3": "\\scrn", // 퓃
  "\u03c5": "\\upupsilon", // υ
  "\u27f4": "\\rightarrowonoplus", // ⟴
  "\u2550": "\\bdHrule", // ═
  "\u025b": "\\textepsilon", // ɛ
  "\u22f1": "\\ddots", // ⋱
  "\ud45b": "\\itn", // 푛
  "\ud64c": "\\bfitsansQ", // 홌
  "\u00b0": "\\textdegree", // °
  "\u24d4": "\\circlede", // ⓔ
  "\u21ca": "\\downdownarrows", // ⇊
  "\ud45c": "\\ito", // 표
  "\u29d0": "\\vbrtri", // ⧐
  "\u0152": "\\OE", // Œ
  "\u22f8": "\\isinvb", // ⋸
  "\u2923": "\\hknwarrow", // ⤣
  "\u239c": "\\lparenextender", // ⎜
  "\ud466": "\\ity", // 푦
  "\ud540": "\\BbbI", // 핀
  "\u22d5": "\\equalparallel", // ⋕
  "\u2b14": "\\squareurblack", // ⬔
  "\ud527": "\\frakj", // 픧
  "\u22aa": "\\Vvdash", // ⊪
  "\u22ec": "\\ntrianglelefteq", // ⋬
  "\ud5b4": "\\sansU", // 햴
  "\u2a8a": "\\gnapprox", // ⪊
  "\u29d3": "\\fbowtie", // ⧓
  "\u2acf": "\\csub", // ⫏
  "\ud4d6": "\\bfscrG", // 퓖
  "\u25f6": "\\circlelrquad", // ◶
  "\ud467": "\\itz", // 푧
  "\ud790": "\\bfitsansAlpha", // 힐
  "\ud79b": "\\bfitsansMu", // 힛
  "\ud7f8": "\\tttwo", // ퟸ
  "\u219d": "\\rightwavyarrow", // ↝
  "\u23e3": "\\benzenr", // ⏣
  "\u2036": "\\textbackdprime", // ‶
  "\u2a8d": "\\lsime", // ⪍
  "\u2647": "\\pluto", // ♇
  "\u29ef": "\\errbarblacksquare", // ⧯
  "\u2461": "\\circledtwo", // ②
  "\u2132": "\\Finv", // Ⅎ
  "\u02be": "\\texthamza", // ʾ
  "\u228c": "\\cupleftarrow", // ⊌
  "\u27c5": "\\lbag", // ⟅
  "\ud5c6": "\\sansm", // 헆
  "\u227f": "\\succsim", // ≿
  "\u2a9d": "\\simless", // ⪝
  "\u29c1": "\\ogreaterthan", // ⧁
  "\u2508": "\\bdquadhdash", // ┈
  "\u0361": "\\t", // ͡
  "\u2074": "\\supfour", // ⁴
  "\u24c7": "\\circledR", // Ⓡ
  "\ud739": "\\bfitdelta", // 휹
  "\ud5fc": "\\bfsanso", // 헼
  "\ud5fd": "\\bfsansp", // 헽
  "\u03f0": "\\upvarkappa", // ϰ
  "\ud678": "\\ttI", // 홸
  "\ud5d1": "\\sansx", // 헑
  "\u2592": "\\blockhalfshaded", // ▒
  "\ud7a1": "\\bfitsansvarTheta", // 힡
  "\u256c": "\\bdbVbH", // ╬
  "\ud469": "\\bfitB", // 푩
  "\u27e9": "\\rangle", // ⟩
  "\u21fb": "\\nVrightarrow", // ⇻
  "\ud493": "\\bfitr", // 풓
  "\u2a2d": "\\opluslhrim", // ⨭
  "\u0328": "\\textpolhk", // ̨
  "\u01c1": "\\textdoublepipe", // ǁ
  "\ud4d4": "\\bfscrE", // 퓔
  "\u266f": "\\sharp", // ♯
  "\u2010": "\\texthyphen", // ‐
  "\ud677": "\\ttH", // 홷
  "\u0312": "\\oturnedcomma", // ̒
  "\u0302": "\\hat,", // ̂
  "\u24b9": "\\circledD", // Ⓓ
  "\ud7b3": "\\bfitsanskappa", // ힳ
  "\u22d4": "\\pitchfork", // ⋔
  "\ud447": "\\itT", // 푇
  "\u22e6": "\\lnsim", // ⋦
  "\u2afa": "\\geqqslant", // ⫺
  "\u2977": "\\leftarrowless", // ⥷
  "\u2ae0": "\\shortuptack", // ⫠
  "\u2a24": "\\simplus", // ⨤
  "\u02dd": "\\textdoubleacute", // ˝
  "\ud4dd": "\\bfscrN", // 퓝
  "\ud717": "\\itvartheta", // 휗
  "\ud6b5": "\\bfXi", // 횵
  "\u204e": "\\textasterisklow", // ⁎
  "\ud44b": "\\itX", // 푋
  "\u298e": "\\rbracklrtick", // ⦎
  "\ud760": "\\bfsansLambda", // 흠
  "\u29b2": "\\emptysetocirc", // ⦲
  "\ud6f9": "\\itPsi", // 훹
  "\ud506": "\\frakC", // 픆
  "\u2460": "\\circledone", // ①
  "\u2961": "\\bardownharpoonleft", // ⥡
  "\ud5b3": "\\sansT", // 햳
  "\ud704": "\\itiota", // 휄
  "\ud67f": "\\ttP", // 홿
  "\ud690": "\\ttg", // 횐
  "\ud6d4": "\\bfsigma", // 훔
  "\u02c8": "\\textprimstress", // ˈ
  "\u2aa4": "\\glj", // ⪤
  "\u2214": "\\dotplus", // ∔
  "\ud6c3": "\\bfbeta", // 훃
  "\u2296": "\\ominus", // ⊖
  "\u272a": "\\circledstar", // ✪
  "\u27eb": "\\rAngle", // ⟫
  "\u27ec": "\\Lbrbrak", // ⟬
  "\u22ac": "\\nvdash", // ⊬
  "\u23a7": "\\lbraceuend", // ⎧
  "\u2a5a": "\\wedgemidvert", // ⩚
  "\u27c6": "\\rbag", // ⟆
  "\u2a45": "\\cupvee", // ⩅
  "\ud54a": "\\BbbS", // 핊
  "\ud4fe": "\\bfscru", // 퓾
  "\u2a83": "\\lesdotor", // ⪃
  "\ud768": "\\bfsansSigma", // 흨
  "\u221c": "\\fourthroot", // ∜
  "\u2137": "\\gimel", // ℷ
  "\u0303": "\\tilde,", // ̃
  "\u2462": "\\circledthree", // ③
  "\u2688": "\\blackcircledrightdot", // ⚈
  "\ud60b": "\\itsansD", // 혋
  "\ud5c4": "\\sansk", // 헄
  "\u0326": "\\textsubcomma", // ̦
  "\u20de": "\\enclosesquare", // ⃞
  "\u22cc": "\\rightthreetimes", // ⋌
  "\ud4bd": "\\scrh", // 풽
  "\u2a48": "\\cupbarcap", // ⩈
  "\ud560": "\\Bbbo", // 할
  "\u21bc": "\\leftharpoonup", // ↼
  "\u0288": "\\textrtailt", // ʈ
  "\ud6df": "\\bfphi", // 훟
  "\u0283": "\\textesh", // ʃ
  "\ud6b4": "\\bfNu", // 횴
  "\u22eb": "\\ntriangleright", // ⋫
  "\ud514": "\\frakQ", // 픔
  "\ud410": "\\bfQ", // 퐐
  "\ud53d": "\\BbbF", // 픽
  "\u03c3": "\\upsigma", // σ
  "\u27e8": "\\langle", // ⟨
  "\ud43d": "\\itJ", // 퐽
  "\u291a": "\\righttail", // ⤚
  "\u2a7c": "\\gtquest", // ⩼
  "\u2963": "\\upharpoonsleftright", // ⥣
  "\u23ad": "\\rbracelend", // ⎭
  "\u21de": "\\nHuparrow", // ⇞
  "\ud65a": "\\bfitsanse", // 홚
  "\u0291": "\\textctz", // ʑ
  "\u23a2": "\\lbrackextender", // ⎢
  "\u02cc": "\\textsecstress", // ˌ
  "\ud40f": "\\bfP", // 퐏
  "\ud794": "\\bfitsansEpsilon", // 힔
  "\u2013": "--", // –
  "\u2939": "\\acwleftarcarrow", // ⤹
  "\u2024": "\\enleaderonedot", // ․
  "\u2a20": "\\zpipe", // ⨠
  "\ud6fc": "\\italpha", // 훼
  "\ud7c4": "\\bfitsansepsilon", // ퟄ
  "\ud712": "\\itchi", // 휒
  "\u03d2": "\\upUpsilon", // ϒ
  "\u224e": "\\Bumpeq", // ≎
  "\u29d8": "\\lvzigzag", // ⧘
  "\u300b": "\\rAngle", // 》
  "\u24e6": "\\circledw", // ⓦ
  "\u20dd": "\\enclosecircle", // ⃝
  "\ud70d": "\\itvarsigma", // 휍
  "\ud56d": "\\bffrakB", // 항
  "\u2226": "\\nparallel", // ∦
  "\u2a33": "\\smashtimes", // ⨳
  "\ud481": "\\bfitZ", // 풁
  "\ud7ea": "\\sanseight", // ퟪ
  "\u2254": "\\coloneq", // ≔
  "\u01c3": "\\textexclam", // ǃ
  "\u214a": "\\PropertyLine", // ⅊
  "\ud4ce": "\\scry", // 퓎
  "\u2584": "\\blocklowhalf", // ▄
  "\ud5db": "\\bfsansH", // 헛
  "\u21df": "\\nHdownarrow", // ⇟
  "\ud67b": "\\ttL", // 홻
  "\u2318": "\\cloverleaf", // ⌘
  "\ud443": "\\itP", // 푃
  "\ud63f": "\\bfitsansD", // 혿
  "\ud74d": "\\bfitpsi", // 흍
  "\u23b1": "\\rmoustache", // ⎱
  "\u21c9": "\\rightrightarrows", // ⇉
  "\u2552": "\\bddvrH", // ╒
  "\u27ff": "\\longrightzigzagarrow", // ⟿
  "\ud632": "\\itsansq", // 혲
  "\u21a5": "\\mapsup", // ↥
  "\u227c": "\\preccurlyeq", // ≼
  "\u24c0": "\\circledK", // Ⓚ
  "\u2194": "\\leftrightarrow", // ↔
  "\ud6a8": "\\bfAlpha", // 효
  "\u22f6": "\\varisinobar", // ⋶
  "\u2914": "\\nvrightarrowtail", // ⤔
  "\u25f7": "\\circleurquad", // ◷
  "\ud585": "\\bffrakZ", // 햅
  "\u2aca": "\\supsetapprox", // ⫊
  "\ud7ed": "\\bfsansone", // ퟭ
  "\ud623": "\\itsansb", // 혣
  "\u26ac": "\\mdsmwhtcircle", // ⚬
  "\u02de": "\\textrhoticity", // ˞
  "\u24de": "\\circledo", // ⓞ
  "\u27fd": "\\Longmapsfrom", // ⟽
  "\ud52f": "\\frakr", // 픯
  "\u230e": "\\urcrop", // ⌎
  "\u01b0": "\\uhorn", // ư
  "\u2a41": "\\uminus", // ⩁
  "\ud454": "\\itg", // 푔
  "\u263d": "\\rightmoon", // ☽
  "\u22a9": "\\Vdash", // ⊩
  "\u2935": "\\downrightcurvedarrow", // ⤵
  "\u23a5": "\\rbrackextender", // ⎥
  "\u2111": "\\Im,", // ℑ
  "\u292d": "\\seovnearrow", // ⤭
  "\ud41f": "\\bff", // 퐟
  "\u25c2": "\\smallblacktriangleleft", // ◂
  "\u21d6": "\\Nwarrow", // ⇖
  "\ud643": "\\bfitsansH", // 홃
  "\u219f": "\\twoheaduparrow", // ↟
  "\u24b6": "\\circledA", // Ⓐ
  "\u2967": "\\leftrightharpoonsdown", // ⥧
  "\ud5b1": "\\sansR", // 햱
  "\ud420": "\\bfg", // 퐠
  "\ud727": "\\bfitMu", // 휧
  "\u2a97": "\\elsdot", // ⪗
  "\u2929": "\\tosa", // ⤩
  "\u03c2": "\\upvarsigma", // ς
  "\u2079": "\\supnine", // ⁹
  "\u033a": "\\textinvsubbridge", // ̺
  "\u2605": "\\bigstar", // ★
  "\ud7e5": "\\sansthree", // ퟥ
  "\ud43a": "\\itG", // 퐺
  "\u2af0": "\\midcir", // ⫰
  "\u2135": "\\aleph", // ℵ
  "\ud575": "\\bffrakJ", // 핵
  "\ud51f": "\\frakb", // 픟
  "\u2555": "\\bddvlH", // ╕
  "\u214b": "\\upand", // ⅋
  "\ud43c": "\\itI", // 퐼
  "\u032c": "\\textsubwedge", // ̬
  "\u2646": "\\neptune", // ♆
  "\ud7a9": "\\bfitsansnabla", // 힩
  "\u21d7": "\\Nearrow", // ⇗
  "\u266d": "\\flat", // ♭
  "\u296c": "\\rightharpoonupdash", // ⥬
  "\u291f": "\\diamondleftarrowbar", // ⤟
  "\u039e": "\\upXi", // Ξ
  "\u2987": "\\llparenthesis", // ⦇
  "\u2789": "\\circledsansten", // ➉
  "\u2ac9": "\\subsetapprox", // ⫉
  "\u2b1a": "\\dottedsquare", // ⬚
  "\ud5b7": "\\sansX", // 햷
  "\ud723": "\\bfitTheta", // 휣
  "\u2908": "\\downarrowbarred", // ⤈
  "\u2027": "\\hyphenpoint", // ‧
  "\u2008": "\\punctspace", // no
  "\ud746": "\\bfitrho", // 흆
  "\u2a3e": "\\fcmp", // ⨾
  "\u027c": "\\textlonglegr", // ɼ
  "\ud721": "\\bfitZeta", // 휡
  "\u2563": "\\bdbVlH", // ╣
  "\u2a1a": "\\intcup", // ⨚
  "\u2afb": "\\texttripleslash", // ⫻
  "\u1d00": "\\textsca", // ᴀ
  "\u2a0c": "\\iiiint", // ⨌
  "\u29af": "\\measangledltosw", // ⦯
  "\u27da": "\\DashVDash", // ⟚
  "\ud583": "\\bffrakX", // 햃
  "\u25ea": "\\squarelrblack", // ◪
  "\u25cb": "\\mdlgwhtcircle", // ○
  "\u0331": "\\underbar,", // ̱
  "\u260e": "\\phone", // ☎
  "\ud792": "\\bfitsansGamma", // 힒
  "\u20e5": "\\textoverlaybackslash", // ⃥
  "\u2aba": "\\succnapprox", // ⪺
  "\ud7a5": "\\bfitsansPhi", // 힥
  "\u2a82": "\\gesdoto", // ⪂
  "\u2a99": "\\eqqless", // ⪙
  "\u222b": "\\int,", // ∫
  "\ud487": "\\bfitf", // 풇
  "\ud43e": "\\itK", // 퐾
  "\u27de": "\\longdashv", // ⟞
  "\ud499": "\\bfitx", // 풙
  "\u2a0d": "\\intbar", // ⨍
  "\u2642": "\\male", // ♂
  "\u0336": "\\textoverlaylongstroke", // ̶
  "\u2612": "\\XBox", // ☒
  "\ud55c": "\\Bbbk", // 한
  "\u25b5": "\\vartriangle", // ▵
  "\u25c7": "\\mdlgwhtdiamond", // ◇
  "\u00bc": "\\textonequarter", // ¼
  "\u02c1": "\\textraiserevglotstop", // ˁ
  "\ud748": "\\bfitsigma", // 흈
  "\u20db": "\\dddot", // ⃛
  "\u232e": "\\profalar", // ⌮
  "\ud628": "\\itsansg", // 혨
  "\ud4a9": "\\scrN", // 풩
  "\u2a77": "\\ddotseq", // ⩷
  "\u02af": "\\textvibyy", // ʯ
  "\ud50b": "\\frakH", // 픋
  "\ud5a5": "\\sansF", // 향
  "\ud7bd": "\\bfitsanstau", // ힽ
  "\ud4b4": "\\scrY", // 풴
  "\u20df": "\\enclosediamond", // ⃟
  "\u2a5e": "\\doublebarwedge", // ⩞
  "\u2ab1": "\\precneq", // ⪱
  "\u27c7": "\\veedot", // ⟇
  "\u229f": "\\boxminus", // ⊟
  "\ud581": "\\bffrakV", // 햁
  "\u25d3": "\\circletophalfblack", // ◓
  "\ud548": "\\BbbQ", // 핈
  "\ud7b6": "\\bfitsansnu", // ힶ
  "\u299a": "\\vzigzag", // ⦚
  "\u03c8": "\\uppsi", // ψ
  "\ud710": "\\itupsilon", // 휐
  "\u2ada": "\\topfork", // ⫚
  "\u22bc": "\\barwedge", // ⊼
  "\u02bf": "\\textain", // ʿ
  "\u24e4": "\\circledu", // ⓤ
  "\ud697": "\\ttn", // 횗
  "\u2af4": "\\interleave", // ⫴
  "\u226d": "\\nasymp", // ≭
  "\ud76a": "\\bfsansUpsilon", // 흪
  "\ud48c": "\\bfitk", // 풌
  "\u224c": "\\varbackcong", // ≌
  "\u2962": "\\leftharpoonsupdown", // ⥢
  "\u0330": "\\wideutilde", // ̰
  "\ud6e0": "\\bfvarrho", // 훠
  "\ud405": "\\bfF", // 퐅
  "\ud7b9": "\\bfitsanspi", // ힹ
  "\u23a4": "\\rbrackuend", // ⎤
  "\u22f2": "\\disin", // ⋲
  "\ud521": "\\frakd", // 픡
  "\u21af": "\\downzigzagarrow", // ↯
  "\u22e3": "\\nsqsupseteq", // ⋣
  "\u20d0": "\\leftharpoonaccent", // ⃐
  "\ud765": "\\bfsansPi", // 흥
  "\u01ad": "\\texthtt", // ƭ
  "\ud5a7": "\\sansH", // 햧
  "\u21a6": "\\mapsto", // ↦
  "\u22d7": "\\gtrdot", // ⋗
  "\ud5aa": "\\sansK", // 햪
  "\u2568": "\\bduVbh", // ╨
  "\u2015": "\\horizbar", // ―
  "\ud478": "\\bfitQ", // 푸
  "\ud58e": "\\bffraki", // 햎
  "\u2621": "\\danger", // ☡
  "\u27c3": "\\subsetcirc", // ⟃
  "\u2227": "\\wedge", // ∧
  "\u2142": "\\sansLmirrored", // ⅂
  "\u219c": "\\leftsquigarrow", // ↜
  "\ud75f": "\\bfsansKappa", // 흟
  "\ud6e2": "\\itAlpha", // 훢
  "\u232a": "\\crangle", // 〉
  "\u0329": "\\textsyllabic", // ̩
  "\ud534": "\\frakw", // 픴
  "\ud5f2": "\\bfsanse", // 헲
  "\u21b4": "\\linefeed", // ↴
  "\u2329": "\\clangle", // 〈
  "\u2a34": "\\otimeslhrim", // ⨴
  "\u2932": "\\nwovnearrow", // ⤲
  "\u2787": "\\circledsanseight", // ➇
  "\u01a0": "\\Ohorn", // Ơ
  "\u03b7": "\\upeta", // η
  "\u2ac4": "\\supedot", // ⫄
  "\ud44d": "\\itZ", // 푍
  "\u0273": "\\textrtailn", // ɳ
  "\ud671": "\\ttB", // 홱
  "\ud4f8": "\\bfscro", // 퓸
  "\ud6fb": "\\itnabla", // 훻
  "\ud591": "\\bffrakl", // 햑
  "\ud7a4": "\\bfitsansUpsilon", // 힤
  "\ud40d": "\\bfN", // 퐍
  "\ud7d9": "\\bbone", // ퟙ
  "\ud5ed": "\\bfsansZ", // 헭
  "\u29c0": "\\olessthan", // ⧀
  "\u030c": "\\widecheck", // ̌
  "\u02e2": "\\ipasups", // ˢ
  "\ud4c9": "\\scrt", // 퓉
  "\ud6f0": "\\itOmicron", // 훰
  "\ud64e": "\\bfitsansS", // 홎
  "\u29e4": "\\smeparsl", // ⧤
  "\u27d3": "\\pullback", // ⟓
  "\u25dc": "\\ularc", // ◜
  "\u22b5": "\\trianglerighteq", // ⊵
  "\u27e4": "\\whitesquaretickleft", // ⟤
  "\u02e3": "\\ipasupx", // ˣ
  "\u2a68": "\\equivVert", // ⩨
  "\ud421": "\\bfh", // 퐡
  "\u229e": "\\boxplus", // ⊞
  "\u0399": "\\upIota", // Ι
  "\ud4b0": "\\scrU", // 풰
  "\ud413": "\\bfT", // 퐓
  "\u233f": "\\APLnotslash", // ⌿
  "\ud446": "\\itS", // 푆
  "\u02ed": "\\ipaunaspirated", // ˭
  "\ud63e": "\\bfitsansC", // 혾
  "\ud702": "\\iteta", // 휂
  "\ud6f3": "\\itvarTheta", // 훳
  "\u22fa": "\\nisd", // ⋺
  "\u2931": "\\neovnwarrow", // ⤱
  "\ud7b0": "\\bfitsanseta", // ힰ
  "\u2580": "\\blockuphalf", // ▀
  "\ud40b": "\\bfL", // 퐋
  "\u201c": "\\textquotedblleft", // “
  "\u25fc": "\\mdblksquare", // ◼
  "\u201d": "\\textquotedblright", // ”
  "\u2247": "\\ncong", // ≇
  "\ud45e": "\\itq", // 푞
  "\u27e3": "\\concavediamondtickright", // ⟣
  "\u02ca": "\\textacute", // ˊ
  "\u23b6": "\\bbrktbrk", // ⎶
  "\u21f8": "\\nvrightarrow", // ⇸
  "\u23ce": "\\varcarriagereturn", // ⏎
  "\u2997": "\\lblkbrbrak", // ⦗
  "\u2ac5": "\\subseteqq", // ⫅
  "\u2007": "\\digitspace", // no symbol
  "\u29de": "\\nvinfty", // ⧞
  "\ud6f6": "\\itUpsilon", // 훶
  "\ud78e": "\\bfsansvarrho", // 힎
  "\ud78a": "\\bfsansepsilon", // 힊
  "\u22b3": "\\vartriangleright", // ⊳
  "\u2a92": "\\glE", // ⪒
  "\u2781": "\\circledsanstwo", // ➁
  "\ud7c8": "\\bfitsansvarrho", // ퟈
  "\u291d": "\\diamondleftarrow", // ⤝
  "\ud484": "\\bfitc", // 풄
  "\ud7f9": "\\ttthree", // ퟹ
  "\u21a1": "\\twoheaddownarrow", // ↡
  "\u239f": "\\rparenextender", // ⎟
  "\u22ea": "\\ntriangleleft", // ⋪
  "\u2198": "\\searrow", // ↘
  "\u02a2": "\\textbarrevglotstop", // ʢ
  "\u25dd": "\\urarc", // ◝
  "\u2310": "\\invnot", // ⌐
  "\u22d3": "\\Cup", // ⋓
  "\ud439": "\\itF", // 퐹
  "\u2556": "\\bddVlh", // ╖
  "\u2ad1": "\\csube", // ⫑
  "\u2a9b": "\\eqqslantless", // ⪛
  "\ud4f5": "\\bfscrl", // 퓵
  "\ud4a5": "\\scrJ", // 풥
  "\ud4e0": "\\bfscrQ", // 퓠
  "\u2003": "\\quad", // no symbol
  "\ud77f": "\\bfsanspi", // 흿
  "\u2422": "\\textblank", // ␢
  "\u20a4": "\\textlira", // ₤
  "\ud4ab": "\\scrP", // 풫
  "\u00b3": "\\textthreesuperior", // ³
  "\u03d8": "\\upoldKoppa", // Ϙ
  "\u2298": "\\oslash", // ⊘
  "\ud783": "\\bfsanstau", // 힃
  "\u2ae8": "\\vBar", // ⫨
  "\u25c3": "\\smalltriangleleft", // ◃
  "\ud5cc": "\\sanss", // 헌
  "\u2793": "\\blackcircledsansten", // ➓
  "\ud658": "\\bfitsansc", // 환
  "\u278e": "\\blackcircledsansfive", // ➎
  "\u22b4": "\\trianglelefteq", // ⊴
  "\ud435": "\\itB", // 퐵
  "\u22b1": "\\scurel", // ⊱
  "\ud689": "\\ttZ", // 횉
  "\ud731": "\\bfitPhi", // 휱
  "\u21ab": "\\looparrowleft", // ↫
  "\ud45f": "\\itr", // 푟
  "\u23aa": "\\vbraceextender", // ⎪
  "\u0360": "\\texttietilde", // ͠
  "\u2134": "\\scro", // ℴ
  "\u2a1e": "\\bigtriangleleft", // ⨞
  "\ud600": "\\bfsanss", // 혀
  "\u2784": "\\circledsansfive", // ➄
  "\ud57f": "\\bffrakT", // 핿
  "\u02c3": "\\textrptr", // ˃
  "\u22be": "\\measuredrightangle", // ⊾
  "\u025d": "\\textrhookrevepsilon", // ɝ
  "\u24c5": "\\circledP", // Ⓟ
  "\u2155": "\\fraconefifth", // ⅕
  "\u2a64": "\\dsub", // ⩤
  "\ud780": "\\bfsansrho", // 힀
  "\ud626": "\\itsanse", // 혦
  "\u23e5": "\\fltns", // ⏥
  "\u29fe": "\\tplus", // ⧾
  "\u0221": "\\textctd", // ȡ
  "\ud7b2": "\\bfitsansiota", // ힲ
  "\u21dd": "\\rightzigzagarrow", // ⇝
  "\u03c4": "\\uptau", // τ
  "\u2686": "\\circledrightdot", // ⚆
  "\ud78d": "\\bfsansvarphi", // 힍
  "\u035c": "\\texttieunderarc", // ͜
  "\u22a6": "\\assert", // ⊦
  "\u2a0f": "\\fint", // ⨏
  "\ud4e3": "\\bfscrT", // 퓣
  "\u23dd": "\\underparen", // ⏝
  "\u21bf": "\\upharpoonleft", // ↿
  "\u25aa": "\\smblksquare", // ▪
  "\u25bf": "\\triangledown", // ▿
  "\u2270": "\\nleq", // ≰
  "\u2669": "\\quarternote", // ♩
  "\u2a3a": "\\triangleminus", // ⨺
  "\u0392": "\\upBeta", // Β
  "\u02b9": "\\cprime", // ʹ
  "\ud587": "\\bffrakb", // 햇
  "\u24d2": "\\circledc", // ⓒ
  "\ud78c": "\\bfsansvarkappa", // 힌
  "\u22c5": "\\tnyblkcircle", // ⋅
  "\u2016": "\\Vert", // ‖
  "\u2154": "\\fractwothirds", // ⅔
  "\u2926": "\\hkswarrow", // ⤦
  "\ud629": "\\itsansh", // 혩
  "\u2924": "\\hknearrow", // ⤤
  "\u0180": "\\textcrb", // ƀ
  "\u25c6": "\\mdlgblkdiamond", // ◆
  "\ud716": "\\itepsilon", // 휖
  "\u25de": "\\lrarc", // ◞
  "\u2996": "\\Rparenless", // ⦖
  "\ud7e0": "\\Bbbeight", // ퟠ
  "\u0141": "\\L", // Ł
  "\ud404": "\\bfE", // 퐄
  "\u24da": "\\circledk", // ⓚ
  "\u2aaf": "\\preceq", // ⪯
  "\u2280": "\\nprec", // ⊀
  "\u25f2": "\\squarelrquad", // ◲
  "\u21bb": "\\cwopencirclearrow", // ↻
  "\u22ff": "\\bagmember", // ⋿
  "\ud71d": "\\bfitBeta", // 휝
  "\u22f3": "\\varisins", // ⋳
  "\u0236": "\\textctt", // ȶ
  "\ud6b7": "\\bfPi", // 횷
  "\ud766": "\\bfsansRho", // 흦
  "\u01ba": "\\textbenttailyogh", // ƺ
  "\ud670": "\\ttA", // 홰
  "\u2af2": "\\nhpar", // ⫲
  "\u27f6": "\\longrightarrow", // ⟶
  "\u027d": "\\textrtailr", // ɽ
  "\ud749": "\\bfittau", // 흉
  "\u0305": "\\overbar", // ̅
  "\ud553": "\\Bbbb", // 핓
  "\u00a1": "\\textexclamdown", // ¡
  "\u293b": "\\acwunderarcarrow", // ⤻
  "\u029a": "\\textcloseepsilon", // ʚ
  "\u21a4": "\\mapsfrom", // ↤
  "\u21aa": "\\hookrightarrow", // ↪
  "\u27c9": "\\suphsol", // ⟉
  "\u278a": "\\blackcircledsansone", // ➊
  "\u2290": "\\sqsupset", // ⊐
  "\u2266": "\\leqq", // ≦
  "\ud561": "\\Bbbp", // 핡
  "\u279b": "\\draftingarrow", // ➛
  "\u227b": "\\succ", // ≻
  "\u2a01": "\\bigoplus", // ⨁
  "\ud617": "\\itsansP", // 혗
  "\ud5af": "\\sansP", // 햯
  "\ud5ea": "\\bfsansW", // 헪
  "\u2aef": "\\cirmid", // ⫯
  "\u2b18": "\\diamondtopblack", // ⬘
  "\u2252": "\\fallingdotseq", // ≒
  "\u239e": "\\rparenuend", // ⎞
  "\ud69d": "\\ttt", // 횝
  "\ud5a9": "\\sansJ", // 햩
  "\u29b4": "\\emptysetoarrl", // ⦴
  "\u228a": "\\subsetneq", // ⊊
  "\u2128": "\\frakZ", // ℨ
  "\u03d5": "\\upphi", // ϕ
  "\u2a96": "\\eqslantgtr", // ⪖
  "\ud489": "\\bfith", // 풉
  "\u21ea": "\\whitearrowupfrombar", // ⇪
  "\u25db": "\\invwhitelowerhalfcircle", // ◛
  "\ud539": "\\BbbB", // 픹
  "\u2a06": "\\bigsqcup", // ⨆
  "\ud4d5": "\\bfscrF", // 퓕
  "\ud5c8": "\\sanso", // 허
  "\u0199": "\\texthtk", // ƙ
  "\ud640": "\\bfitsansE", // 홀
  "\u27ea": "\\lAngle", // ⟪
  "\ud5cf": "\\sansv", // 헏
  "\ud586": "\\bffraka", // 햆
  "\ud44a": "\\itW", // 푊
  "\u27d5": "\\leftouterjoin", // ⟕
  "\u299f": "\\angdnr", // ⦟
  "\u2a9f": "\\simlE", // ⪟
  "\ud6e3": "\\itBeta", // 훣
  "\ud556": "\\Bbbe", // 핖
  "\u22a5": "\\bot", // ⊥
  "\u29a0": "\\gtlpar", // ⦠
  "\ud7af": "\\bfitsanszeta", // 힯
  "\u21e5": "\\rightarrowbar", // ⇥
  "\u025f": "\\textbardotlessj", // ɟ
  "\u25c8": "\\blackinwhitediamond", // ◈
  "\u0290": "\\textrtailz", // ʐ
  "\ud70a": "\\itomicron", // 휊
  "\ud449": "\\itV", // 푉
  "\ud72f": "\\bfitTau", // 휯
  "\u028f": "\\textscy", // ʏ
  "\u22ee": "\\vdots", // ⋮
  "\u22ca": "\\rtimes", // ⋊
  "\ud5ba": "\\sansa", // 햺
  "\u2a21": "\\zproject", // ⨡
  "\u22ce": "\\curlyvee", // ⋎
  "\ud46a": "\\bfitC", // 푪
  "\ud6b3": "\\bfMu", // 횳
  "\ud48b": "\\bfitj", // 풋
  "\ud7df": "\\Bbbseven", // ퟟ
  "\u2915": "\\nVrightarrowtail", // ⤕
  "\u29e0": "\\laplac", // ⧠
  "\u0338": "\\textoverlaylongsolidus", // ̸
  "\u2909": "\\uparrowbarred", // ⤉
  "\ud524": "\\frakg", // 픤
  "\u297f": "\\downfishtail", // ⥿
  "\ud4fb": "\\bfscrr", // 퓻
  "\u032f": "\\textsubarch", // ̯
  "\u227e": "\\precsim", // ≾
  "\u21e6": "\\leftwhitearrow", // ⇦
  "\u2979": "\\subrarr", // ⥹
  "\u27f2": "\\acwgapcirclearrow", // ⟲
  "\u2591": "\\blockqtrshaded", // ░
  "\u2299": "\\odot", // ⊙
  "\ud625": "\\itsansd", // 혥
  "\ud7a6": "\\bfitsansChi", // 힦
  "\u2aa6": "\\ltcc", // ⪦
  "\u220b": "\\ni,", // ∋
  "\u2a1d": "\\Join", // ⨝
  "\u2130": "\\scrE", // ℰ
  "\u21ac": "\\looparrowright", // ↬
  "\ud6cf": "\\bfxi", // 훏
  "\ud639": "\\itsansx", // 혹
  "\u033b": "\\textsubsquare", // ̻
  "\u210d": "\\BbbH", // ℍ
  "\ud7ca": "\\bfDigamma", // ퟊
  "\u2905": "\\twoheadmapsto", // ⤅
  "\u2232": "\\varointclockwise", // ∲
  "\ud603": "\\bfsansv", // 혃
  "\ud445": "\\itR", // 푅
  "\ud513": "\\frakP", // 픓
  "\u2684": "\\dicev", // ⚄
  "\u299c": "\\rightanglesqr", // ⦜
  "\u02c2": "\\textlptr", // ˂
  "\u03a1": "\\upRho", // Ρ
  "\ud541": "\\BbbJ", // 핁
  "\ud620": "\\itsansY", // 혠
  "\u2502": "\\bdvrule", // │
  "\ud60c": "\\itsansE", // 혌
  "\u27e1": "\\concavediamond", // ⟡
  "\u2145": "\\itBbbD", // ⅅ
  "\u2934": "\\uprightcurvearrow", // ⤴
  "\u2ad4": "\\supsub", // ⫔
  "\u27dc": "\\multimapinv", // ⟜
  "\ud7d0": "\\bftwo", // ퟐ
  "\ud525": "\\frakh", // 픥
  "\u2b15": "\\squarellblack", // ⬕
  "\u00ab": "\\guillemotleft", // «
  "\ud673": "\\ttD", // 홳
  "\ud4aa": "\\scrO", // 풪
  "\ud414": "\\bfU", // 퐔
  "\u21b3": "\\Rdsh", // ↳
  "\ud7fe": "\\tteight", // ퟾
  "\u29e3": "\\eparsl", // ⧣
  "\u21e7": "\\upwhitearrow", // ⇧
  "\u24d7": "\\circledh", // ⓗ
  "\u00b2": "\\texttwosuperior", // ²
  "\ud45d": "\\itp", // 푝
  "\u2a8f": "\\lsimg", // ⪏
  "\u2b13": "\\squarebotblack", // ⬓
  "\u2077": "\\supseven", // ⁷
  "\u25a7": "\\squarenwsefill", // ▧
  "\ud535": "\\frakx", // 픵
  "\u02a8": "\\texttctctlig", // ʨ
  "\u27d2": "\\upin", // ⟒
  "\u21d8": "\\Searrow", // ⇘
  "\ud78f": "\\bfsansvarpi", // 힏
  "\u03e0": "\\upSampi", // Ϡ
  "\u2125": "\\textoz", // ℥
  "\u2a13": "\\scpolint", // ⨓
  "\u2291": "\\sqsubseteq", // ⊑
  "\ud5f4": "\\bfsansg", // 헴
  "\u27fa": "\\Longleftrightarrow", // ⟺
  "\ud457": "\\itj", // 푗
  "\ud6d3": "\\bfvarsigma", // 훓
  "\ud7c7": "\\bfitsansvarphi", // ퟇
  "\u29ee": "\\errbarsquare", // ⧮
  "\u2255": "\\eqcolon", // ≕
  "\ud6c6": "\\bfvarepsilon", // 훆
  "\u2267": "\\geqq", // ≧
  "\ud775": "\\bfsanszeta", // 흵
  "\u2030": "\\textperthousand", // ‰
  "\u29be": "\\circledwhitebullet", // ⦾
  "\ud694": "\\ttk", // 횔
  "\u200e": "\\LtoRmark", // ‎
  "\u22a8": "\\vDash", // ⊨
  "\ud47f": "\\bfitX", // 푿
  "\u2ad2": "\\csupe", // ⫒
  "\ud700": "\\itvarepsilon", // 휀
  "\u2981": "\\mdsmblkcircle", // ⦁
  "\ud769": "\\bfsansTau", // 흩
  "\u2122": "\\texttrademark", // ™
  "\u029c": "\\textsch", // ʜ
  "\u22c0": "\\bigwedge", // ⋀
  "\u2105": "\\incare", // ℅
  "\u292e": "\\neovsearrow", // ⤮
  "\u2944": "\\shortrightarrowleftarrow", // ⥄
  "\u2b23": "\\hexagonblack", // ⬣
  "\u29e6": "\\gleichstark", // ⧦
  "\ud719": "\\itvarphi", // 휙
  "\u00bd": "\\textonehalf", // ½
  "\u293d": "\\curvearrowleftplus", // ⤽
  "\ud4fc": "\\bfscrs", // 퓼
  "\u25be": "\\blacktriangledown", // ▾
  "\u2285": "\\nsupset", // ⊅
  "\u25da": "\\invwhiteupperhalfcircle", // ◚
  "\ud771": "\\bfsansbeta", // 흱
  "\ud4da": "\\bfscrK", // 퓚
  "\ud6f8": "\\itChi", // 훸
  "\u2a7b": "\\ltquest", // ⩻
  "\ud72d": "\\bfitvarTheta", // 휭
  "\u24e3": "\\circledt", // ⓣ
  "\u25b8": "\\smallblacktriangleright", // ▸
  "\u27d8": "\\bigbot", // ⟘
  "\ud688": "\\ttY", // 횈
  "\u2288": "\\nsubseteq", // ⊈
  "\u00b6": "\\P", // ¶
  "\ud4f6": "\\bfscrm", // 퓶
  "\u2a12": "\\rppolint", // ⨒
  "\ud6ad": "\\bfZeta", // 횭
  "\u03db": "\\upstigma", // ϛ
  "\ud401": "\\bfB", // 퐁
  "\u2224": "\\nmid", // ∤
  "\u2014": "\\emdash", // —
  "\ud7de": "\\Bbbsix", // ퟞ
  "\u23e6": "\\accurrent", // ⏦
  "\u2ae2": "\\vDdash", // ⫢
  "\ud5ce": "\\sansu", // 헎
  "\ud7d3": "\\bffive", // ퟓ
  "\ud70b": "\\itpi", // 휋
  "\u200b": "\\zwspace", // no symbol
  "\ud789": "\\bfsanspartial", // 힉
  "\ud424": "\\bfk", // 퐤
  "\u25e7": "\\squareleftblack", // ◧
  "\u25a1": "\\mdlgwhtsquare", // □
  "\u22b8": "\\multimap", // ⊸
  "\ud767": "\\bfsansvarTheta", // 흧
  "\u2a6d": "\\congdot", // ⩭
  "\u2a87": "\\lneq", // ⪇
  "\ud4ed": "\\bfscrd", // 퓭
  "\u0287": "\\textturnt", // ʇ
  "\u2ab0": "\\succeq", // ⪰
  "\ud64f": "\\bfitsansT", // 홏
  "\ud60a": "\\itsansC", // 혊
  "\u219e": "\\twoheadleftarrow", // ↞
  "\u25eb": "\\boxbar", // ◫
  "\u2239": "\\dashcolon", // ∹
  "\u0285": "\\textvibyi", // ʅ
  "\u2906": "\\Mapsfrom", // ⤆
  "\u22c8": "\\bowtie", // ⋈
  "\ud50c": "\\frakI", // 플
  "\u20e1": "\\overleftrightarrow", // ⃡
  "\ud47c": "\\bfitU", // 푼
  "\u1d81": "\\textlhookd", // ᶁ
  "\ud61a": "\\itsansS", // 혚
  "\u2a5d": "\\midbarvee", // ⩝
  "\u02ce": "\\textlowgrave", // ˎ
  "\ud505": "\\frakB", // 픅
  "\ud571": "\\bffrakF", // 핱
  "\u02b3": "\\textsupr", // ʳ
  "\u2021": "\\ddagger", // ‡
  "\ud684": "\\ttU", // 횄
  "\u22e2": "\\nsqsubseteq", // ⋢
  "\u2463": "\\circledfour", // ④
  "\u2313": "\\profsurf", // ⌓
  "\ud71f": "\\bfitDelta", // 휟
  "\ud57b": "\\bffrakP", // 핻
  "\u1d07": "\\textsce", // ᴇ
  "\u2518": "\\bduvlh", // ┘
  "\u21b6": "\\curvearrowleft", // ↶
  "\u03c6": "\\upvarphi", // φ
  "\u2203": "\\exists", // ∃
  "\u226f": "\\ngtr", // ≯
  "\u227d": "\\succcurlyeq", // ≽
  "\ud497": "\\bfitv", // 풗
  "\u2a7e": "\\geqslant", // ⩾
  "\u2949": "\\twoheaduparrowcircle", // ⥉
  "\u25ba": "\\blackpointerright", // ►
  "\u01a1": "\\ohorn", // ơ
  "\u2916": "\\twoheadrightarrowtail", // ⤖
  "\u29ae": "\\measangledrtose", // ⦮
  "\ud79a": "\\bfitsansLambda", // 힚
  "\u2a7f": "\\lesdot", // ⩿
  "\u21c0": "\\rightharpoonup", // ⇀
  "\ud79f": "\\bfitsansPi", // 힟
  "\u212b": "\\Angstrom", // Å
  "\u2afc": "\\biginterleave", // ⫼
  "\u2a71": "\\eqqplus", // ⩱
  "\u29a1": "\\sphericalangleup", // ⦡
  "\u2a2e": "\\oplusrhrim", // ⨮
  "\u21cf": "\\nRightarrow", // ⇏
  "\ud740": "\\bfitlambda", // 흀
  "\ud745": "\\bfitpi", // 흅
  "\u2965": "\\downharpoonsleftright", // ⥥
  "\ud5e5": "\\bfsansR", // 헥
  "\u2aad": "\\late", // ⪭
  "\ud503": "\\bfscrz", // 픃
  "\ud7a7": "\\bfitsansPsi", // 힧
  "\u2a4f": "\\Sqcup", // ⩏
  "\u029e": "\\textturnk", // ʞ
  "\ud6b9": "\\bfvarTheta", // 횹
  "\u031a": "\\droang", // ̚
  "\u27d9": "\\bigtop", // ⟙
  "\ud737": "\\bfitbeta", // 휷
  "\u2265": "\\geq", // ≥
  "\u0309": "\\ovhook", // ̉
  "\ud48a": "\\bfiti", // 풊
  "\ud6c4": "\\bfgamma", // 후
  "\u220f": "\\prod", // ∏
  "\ufffd": "\\unknown", // �
  "\ud5c5": "\\sansl", // 헅
  "\u25ec": "\\trianglecdot", // ◬
  "\ud793": "\\bfitsansDelta", // 힓
  "\u25e2": "\\lrblacktriangle", // ◢
  "\ud58a": "\\bffrake", // 햊
  "\u25ee": "\\trianglerightblack", // ◮
  "\ud7f5": "\\bfsansnine", // ퟵ
  "\u22f0": "\\adots", // ⋰
  "\u2237": "\\Colon", // ∷
  "\ud546": "\\BbbO", // 핆
  "\ud56a": "\\Bbby", // 핪
  "\ud4e7": "\\bfscrX", // 퓧
  "\u2a81": "\\lesdoto", // ⪁
  "\u2500": "\\bdhrule", // ─
  "\u2393": "\\dircurrent", // ⎓
  "\ud5ff": "\\bfsansr", // 헿
  "\u03a7": "\\upChi", // Χ
  "\ud73a": "\\bfitvarepsilon", // 휺
  "\ud76b": "\\bfsansPhi", // 흫
  "\u0252": "\\textturnscripta", // ɒ
  "\u290d": "\\rightbkarrow", // ⤍
  "\ud73e": "\\bfitiota", // 휾
  "\ud5c2": "\\sansi", // 헂
  "\u2514": "\\bduvrh", // └
  "\u02a5": "\\textdctzlig", // ʥ
  "\ud4ee": "\\bfscre", // 퓮
  "\u21c1": "\\rightharpoondown", // ⇁
  "\u2a1b": "\\upint", // ⨛
  "\u2ac3": "\\subedot", // ⫃
  "\u02e4": "\\ipasuprerglotstpp", // ˤ
  "\u2aae": "\\bumpeqq", // ⪮
  "\u2136": "\\beth", // ℶ
  "\ud747": "\\bfitvarsigma", // 흇
  "\u22c9": "\\ltimes", // ⋉
  "\ud4f0": "\\bfscrg", // 퓰
  "\ud47e": "\\bfitW", // 푾
  "\u221a": "\\surd,", // √
  "\u22db": "\\gtreqless", // ⋛
  "\u2964": "\\rightharpoonsupdown", // ⥤
  "\ud67e": "\\ttO", // 홾
  "\u207d": "\\suplparen", // ⁽
  "\ud68c": "\\ttc", // 회
  "\ud588": "\\bffrakc", // 했
  "\u2295": "\\oplus", // ⊕
  "\u00a3": "\\sterling", // £
  "\u226e": "\\nless", // ≮
  "\u03d9": "\\upoldkoppa", // ϙ
  "\ud406": "\\bfG", // 퐆
  "\u2210": "\\coprod", // ∐
  "\ud4b9": "\\scrd", // 풹
  "\u02d5": "\\textlowered", // ˕
  "\ud5bd": "\\sansd", // 햽
  "\u24c6": "\\circledQ", // Ⓠ
  "\u02ec": "\\ipavoicing", // ˬ
  "\ud5cb": "\\sansr", // 헋
  "\u22f4": "\\isins", // ⋴
  "\u20a3": "\\textfranc", // ₣
  "\u1d8e": "\\textlhookz", // ᶎ
  "\u2261": "\\equiv", // ≡
  "\ud75e": "\\bfsansIota", // 흞
  "\ud711": "\\itphi", // 휑
  "\u0024": "\\textdollar", // $
  "\ud43b": "\\itH", // 퐻
  "\u01a5": "\\texthtp", // ƥ
  "\u2148": "\\itBbbi", // ⅈ
  "\ud510": "\\frakM", // 픐
  "\ud432": "\\bfy", // 퐲
  "\ud7dc": "\\Bbbfour", // ퟜ
  "\u22fe": "\\niobar", // ⋾
  "\ud634": "\\itsanss", // 혴
  "\u2256": "\\eqcirc", // ≖
  "\ud4b5": "\\scrZ", // 풵
  "\u03f1": "\\upvarrho", // ϱ
  "\u2316": "\\target", // ⌖
  "\ud69b": "\\ttr", // 횛
  "\u20e9": "\\widebridgeabove", // ⃩
  "\u0111": "\\textcrd", // đ
  "\u2681": "\\diceii", // ⚁
  "\ud5d8": "\\bfsansE", // 험
  "\u0022": "\\textquotedbl", // \"
  "\u2ad7": "\\suphsub", // ⫗
  "\ud4d7": "\\bfscrH", // 퓗
  "\ud7ba": "\\bfitsansrho", // ힺ
  "\u22d1": "\\Supset", // ⋑
  "\ud437": "\\itD", // 퐷
  "\u23b7": "\\sqrtbottom", // ⎷
  "\u2222": "\\ltrpar", // ∢
  "\u22c4": "\\diamond", // ⋄
  "\ud589": "\\bffrakd", // 행
  "\u27d0": "\\diamondcdot", // ⟐
  "\u014b": "\\ng", // ŋ
  "\u2011": "\\nobreakhyphen", // ‑
  "\u2938": "\\cwrightarcarrow", // ⤸
  "\ud50f": "\\frakL", // 픏
  "\u2667": "\\varclubsuit", // ♧
  "\ud476": "\\bfitO", // 푶
  "\u29a5": "\\revangleubar", // ⦥
  "\u229c": "\\circledequal", // ⊜
  "\ud69a": "\\ttq", // 횚
  "\u290b": "\\Ddownarrow", // ⤋
  "\u2302": "\\house", // ⌂
  "\u2510": "\\bddvlh", // ┐
  "\u2218": "\\vysmwhtcircle", // ∘
  "\ud733": "\\bfitPsi", // 휳
  "\u29ff": "\\tminus", // ⧿
  "\ud664": "\\bfitsanso", // 홤
  "\u225b": "\\stareq", // ≛
  "\u219b": "\\nrightarrow", // ↛
  "\ud5e3": "\\bfsansP", // 헣
  "\ud724": "\\bfitIota", // 휤
  "\ud73b": "\\bfitzeta", // 휻
  "\ud40a": "\\bfK", // 퐊
  "\u298f": "\\lbracklltick", // ⦏
  "\ud596": "\\bffrakq", // 햖
  "\u21e3": "\\downdasharrow", // ⇣
  "\ud635": "\\itsanst", // 혵
  "\u2a05": "\\bigsqcap", // ⨅
  "\u2970": "\\rightimply", // ⥰
  "\u2022": "\\textbullet,", // •
  "\u294e": "\\leftrightharpoonupup", // ⥎
  "\u2110": "\\scrI", // ℐ
  "\ud6bb": "\\bfTau", // 횻
  "\ud5e0": "\\bfsansM", // 헠
  "\u2aa8": "\\lescc", // ⪨
  "\u2a57": "\\bigslopedvee", // ⩗
  "\u2a25": "\\plusdot", // ⨥
  "\ud76d": "\\bfsansPsi", // 흭
  "\u2102": "\\BbbC", // ℂ
  "\ud7eb": "\\sansnine", // ퟫ
  "\u2085": "\\subfive", // ₅
  "\u2a56": "\\veeonvee", // ⩖
  "\u2aa9": "\\gescc", // ⪩
  "\u25fd": "\\mdsmwhtsquare", // ◽
  "\u2a28": "\\plustrif", // ⨨
  "\u2221": "\\measuredangle", // ∡
  "\u29c9": "\\boxonbox", // ⧉
  "\ud40c": "\\bfM", // 퐌
  "\ud698": "\\tto", // 횘
  "\u03c9": "\\textomega", // ω
  "\ud6a2": "\\tty", // 횢
  "\u24c4": "\\circledO", // Ⓞ
  "\ud7db": "\\Bbbthree", // ퟛ
  "\ud7ff": "\\ttnine", // ퟿
  "\u2b12": "\\squaretopblack", // ⬒
  "\u027e": "\\textfishhookr", // ɾ
  "\u226c": "\\between", // ≬
  "\u03da": "\\upStigma", // Ϛ
  "\u0297": "\\textstretchcvar", // ʗ
  "\u02d4": "\\textraised", // ˔
  "\ud7f2": "\\bfsanssix", // ퟲ
  "\ud5f3": "\\bfsansf", // 헳
  "\u2112": "\\scrL", // ℒ
  "\u2a14": "\\npolint", // ⨔
  "\u224d": "\\asymp", // ≍
  "\u00a5": "\\yen", // ¥
  "\u21e8": "\\rightwhitearrow", // ⇨
  "\ud7d8": "\\bbzero", // ퟘ
  "\u2aa1": "\\Lt", // ⪡
  "\ud4ec": "\\bfscrc", // 퓬
  "\u24b8": "\\circledC", // Ⓒ
  "\u2b16": "\\diamondleftblack", // ⬖
  "\u2a67": "\\dotequiv", // ⩧
  "\ud52c": "\\frako", // 픬
  "\ud75c": "\\bfsansEta", // 흜
  "\u21f4": "\\circleonrightarrow", // ⇴
  "\u2947": "\\rightarrowx", // ⥇
  "\u2955": "\\downharpoonrightbar", // ⥕
  "\u2900": "\\nvtwoheadrightarrow", // ⤀
  "\ud520": "\\frakc", // 픠
  "\u22b6": "\\origof", // ⊶
  "\ud5d0": "\\sansw", // 헐
  "\ud682": "\\ttS", // 횂
  "\ud6d2": "\\bfrho", // 훒
  "\u02b8": "\\textsupy", // ʸ
  "\u2a30": "\\dottimes", // ⨰
  "\ud4d8": "\\bfscrI", // 퓘
  "\u290a": "\\Uuparrow", // ⤊
  "\u0258": "\\textreve", // ɘ
  "\u2199": "\\swarrow", // ↙
  "\ud577": "\\bffrakL", // 핷
  "\ud77e": "\\bfsansomicron", // 흾
  "\ud655": "\\bfitsansZ", // 확
  "\u25e6": "\\smwhtcircle", // ◦
  "\u02b4": "\\textsupturnr", // ʴ
  "\ud5bf": "\\sansf", // 햿
  "\ud7be": "\\bfitsansupsilon", // ힾ
  "\u231e": "\\llcorner", // ⌞
  "\u2ac6": "\\supseteqq", // ⫆
  "\u005e": "\\textasciicircum", // ^
  "\u2312": "\\profline", // ⌒
  "\ud5bc": "\\sansc", // 햼
  "\ud703": "\\ittheta", // 휃
  "\u2262": "\\nequiv", // ≢
  "\u00df": "\\ss", // ß
  "\ud7d1": "\\bfthree", // ퟑ
  "\ud4af": "\\scrT", // 풯
  "\u29e8": "\\downtriangleleftblack", // ⧨
  "\u21bd": "\\leftharpoondown", // ↽
  "\u2590": "\\blockrighthalf", // ▐
  "\u300a": "\\lAngle", // 《
  "\u25f0": "\\squareulquad", // ◰
  "\ud52d": "\\frakp", // 픭
  "\u20ef": "\\underrightarrow", // ⃯
  "\ud598": "\\bffraks", // 햘
  "\ud549": "\\BbbR", // 핉
  "\ud5f9": "\\bfsansl", // 헹
  "\u025e": "\\textcloserevepsilon", // ɞ
  "\ud50e": "\\frakK", // 픎
  "\u032a": "\\textsubbridge", // ̪
  "\ud615": "\\itsansN", // 형
  "\u0253": "\\texthtb", // ɓ
  "\ud578": "\\bffrakM", // 핸
  "\u0324": "\\textsubumlaut", // ̤
  "\u2920": "\\barrightarrowdiamond", // ⤠
  "\ud4db": "\\bfscrL", // 퓛
  "\u2153": "\\fraconethird", // ⅓
  "\ud485": "\\bfitd", // 풅
  "\u2217": "\\ast", // ∗
  "\ud4bb": "\\scrf", // 풻
  "\u00a7": "\\S", // §
  "\u29f4": "\\ruledelayed", // ⧴
  "\u2911": "\\rightdotarrow", // ⤑
  "\u2af7": "\\lllnest", // ⫷
  "\u2958": "\\barupharpoonleft", // ⥘
  "\u2225": "\\parallel,", // ∥
  "\u0300": "\\grave", // ̀
  "\ud49c": "\\scrA", // 풜
  "\u0142": "\\l", // ł
  "\u2912": "\\baruparrow", // ⤒
  "\ud72b": "\\bfitPi", // 휫
  "\ud5ae": "\\sansO", // 햮
  "\ud618": "\\itsansQ", // 혘
  "\u215b": "\\fraconeeighth", // ⅛
  "\ud7fa": "\\ttfour", // ퟺ
  "\ud4fd": "\\bfscrt", // 퓽
  "\u27f8": "\\Longleftarrow", // ⟸
  "\u255f": "\\bdbVrh", // ╟
  "\u2661": "\\heartsuit", // ♡
  "\ud491": "\\bfitp", // 풑
  "\ud62b": "\\itsansj", // 혫
  "\u297d": "\\rightfishtail", // ⥽
  "\u2253": "\\risingdotseq", // ≓
  "\u2216": "\\setminus,", // ∖
  "\u27fc": "\\longmapsto", // ⟼
  "\ud6f5": "\\itTau", // 훵
  "\u002a": "\\ast", // *
  "\u00ad": "\\-", // ­
  "\u00b8": "\\c{}", // ¸
  "\u00c0": "\\`{A}", // À
  "\u00c1": "\\'{A}", // Á
  "\u00c2": "\\^{A}", // Â
  "\u00c3": "\\~{A}", // Ã
  "\u00c4": '\\"{A}', // Ä
  "\u00c7": "\\c{C}", // Ç
  "\u00c8": "\\`{E}", // È
  "\u00c9": "\\'{E}", // É
  "\u00ca": "\\^{E}", // Ê
  "\u00cb": '\\"{E}', // Ë
  "\u00cc": "\\`{I}", // Ì
  "\u00cd": "\\'{I}", // Í
  "\u00ce": "\\{\\^{I}}", // Î
  "\u00cf": '\\"{I}', // Ï
  "\u00d1": "\\~{N}", // Ñ
  "\u00d2": "\\`{O}", // Ò
  "\u00d3": "\\'{O}", // Ó
  "\u00d4": "\\^{O}", // Ô
  "\u00d5": "\\~{O}", // Õ
  "\u00d6": '\\"{O}', // Ö
  "\u00d9": "\\`{U}", // Ù
  "\u00da": "\\'{U}", // Ú
  "\u00db": "\\^{U}", // Û
  "\u00dc": '\\"{U}', // Ü
  "\u00dd": "\\'{Y}", // Ý
  "\u00e0": "\\`{a}", // à
  "\u00e1": "\\'{a}", // á
  "\u00e2": "\\^{a}", // â
  "\u00e3": "\\~{a}", // ã
  "\u00e4": '\\"{a}', // ä
  "\u00e7": "\\c{c}", // ç
  "\u00e8": "\\`{e}", // è
  "\u00e9": "\\'{e}", // é
  "\u00ea": "\\^{e}", // ê
  "\u00eb": '\\"{e}', // ë
  "\u00ec": "\\`{\\i}", // ì
  "\u00ed": "\\'{\\i}", // í
  "\u00ee": "\\^{\\i}", // î
  "\u00ef": '\\"{\\i}', // ï
  "\u00f1": "\\~{n}", // ñ
  "\u00f2": "\\`{o}", // ò
  "\u00f3": "\\'{o}", // ó
  "\u00f4": "\\^{o}", // ô
  "\u00f5": "\\~{o}", // õ
  "\u00f6": '\\"{o}', // ö
  "\u00f9": "\\`{u}", // ù
  "\u00fa": "\\'{u}", // ú
  "\u00fb": "\\^{u}", // û
  "\u00fc": '\\"{u}', // ü
  "\u00fd": "\\'{y}", // ý
  "\u00ff": '\\"{y}', // ÿ
  "\u0100": "\\={A}", // Ā
  "\u0101": "\\={a}", // ā
  "\u0102": "\\u{A}", // Ă
  "\u0103": "\\u{a}", // ă
  "\u0104": "\\k{A}", // Ą
  "\u0105": "\\k{a}", // ą
  "\u0106": "\\'{C}", // Ć
  "\u0107": "\\'{c}", // ć
  "\u0108": "\\^{C}", // Ĉ
  "\u0109": "\\^{c}", // ĉ
  "\u010a": "\\.{C}", // Ċ
  "\u010b": "\\.{c}", // ċ
  "\u010c": "\\v{C}", // Č
  "\u010d": "\\v{c}", // č
  "\u010e": "\\v{D}", // Ď
  "\u010f": "\\v{d}", // ď
  "\u0112": "\\={E}", // Ē
  "\u0113": "\\={e}", // ē
  "\u0114": "\\u{E}", // Ĕ
  "\u0115": "\\u{e}", // ĕ
  "\u0116": "\\.{E}", // Ė
  "\u0117": "\\.{e}", // ė
  "\u0118": "\\k{E}", // Ę
  "\u0119": "\\k{e}", // ę
  "\u011a": "\\v{E}", // Ě
  "\u011b": "\\v{e}", // ě
  "\u011c": "\\^{G}", // Ĝ
  "\u011d": "\\^{g}", // ĝ
  "\u011e": "\\u{G}", // Ğ
  "\u011f": "\\u{g}", // ğ
  "\u0120": "\\.{G}", // Ġ
  "\u0121": "\\.{g}", // ġ
  "\u0122": "\\c{G}", // Ģ
  "\u0123": "\\c{g}", // ģ
  "\u0124": "\\^{H}", // Ĥ
  "\u0125": "\\^{h}", // ĥ
  "\u0128": "\\~{I}", // Ĩ
  "\u0129": "\\~{\\i}", // ĩ
  "\u012a": "\\={I}", // Ī
  "\u012b": "\\={\\i}", // ī
  "\u012c": "\\u{I}", // Ĭ
  "\u012d": "\\u{\\i}", // ĭ
  "\u012e": "\\k{I}", // Į
  "\u012f": "\\k{i}", // į
  "\u0130": "\\.{I}", // İ
  "\u0132": "\\IJ", // Ĳ
  "\u0133": "\\ij", // ĳ
  "\u0134": "\\^{J}", // Ĵ
  "\u0135": "\\^{\\j}", // ĵ
  "\u0136": "\\c{K}", // Ķ
  "\u0137": "\\c{k}", // ķ
  "\u0139": "\\'{L}", // Ĺ
  "\u013a": "\\'{l}", // ĺ
  "\u013b": "\\c{L}", // Ļ
  "\u013c": "\\c{l}", // ļ
  "\u013d": "\\v{L}", // Ľ
  "\u013e": "\\v{l}", // ľ
  "\u0143": "\\'{N}", // Ń
  "\u0144": "\\'{n}", // ń
  "\u0145": "\\c{N}", // Ņ
  "\u0146": "\\c{n}", // ņ
  "\u0147": "\\v{N}", // Ň
  "\u0148": "\\v{n}", // ň
  "\u0149": "\\'n", // ŉ
  "\u014c": "\\={O}", // Ō
  "\u014d": "\\={o}", // ō
  "\u014e": "\\u{O}", // Ŏ
  "\u014f": "\\u{o}", // ŏ
  "\u0150": "\\H{O}", // Ő
  "\u0151": "\\H{o}", // ő
  "\u0154": "\\'{R}", // Ŕ
  "\u0155": "\\'{r}", // ŕ
  "\u0156": "\\c{R}", // Ŗ
  "\u0157": "\\c{r}", // ŗ
  "\u0158": "\\v{R}", // Ř
  "\u0159": "\\v{r}", // ř
  "\u015a": "\\'{S}", // Ś
  "\u015b": "\\'{s}", // ś
  "\u015c": "\\^{S}", // Ŝ
  "\u015d": "\\^{s}", // ŝ
  "\u015e": "\\c{S}", // Ş
  "\u015f": "\\c{s}", // ş
  "\u0160": "\\v{S}", // Š
  "\u0161": "\\v{s}", // š
  "\u0162": "\\c{T}", // Ţ
  "\u0163": "\\c{t}", // ţ
  "\u0164": "\\v{T}", // Ť
  "\u0165": "\\v{t}", // ť
  "\u0168": "\\~{U}", // Ũ
  "\u0169": "\\~{u}", // ũ
  "\u016a": "\\={U}", // Ū
  "\u016b": "\\={u}", // ū
  "\u016c": "\\u{U}", // Ŭ
  "\u016d": "\\u{u}", // ŭ
  "\u016e": "\\r{U}", // Ů
  "\u016f": "\\r{u}", // ů
  "\u0170": "\\H{U}", // Ű
  "\u0171": "\\H{u}", // ű
  "\u0172": "\\k{U}", // Ų
  "\u0173": "\\k{u}", // ų
  "\u0174": "\\^{W}", // Ŵ
  "\u0175": "\\^{w}", // ŵ
  "\u0176": "\\^{Y}", // Ŷ
  "\u0177": "\\^{y}", // ŷ
  "\u0178": '\\"{Y}', // Ÿ
  "\u0179": "\\'{Z}", // Ź
  "\u017a": "\\'{z}", // ź
  "\u017b": "\\.{Z}", // Ż
  "\u017c": "\\.{z}", // ż
  "\u017d": "\\v{Z}", // Ž
  "\u017e": "\\v{z}", // ž
  "\u019e": "\\textnrleg", // ƞ
  "\u01f5": "\\'{g}", // ǵ
  "\u0386": "\\'{A}", // Ά
  "\u0388": "\\'{E}", // Έ
  "\u0389": "\\'{H}", // Ή
  "\u038a": "\\'{}{I}", // Ί
  "\u038c": "\\'{}O", // Ό
  "\u038e": "\\mathrm{\\'Y}", // Ύ
  "\u038f": "\\mathrm{\\'\\Omega}", // Ώ
  "\u0390": "\\acute{\\ddot{\\iota}}", // ΐ
  "\u03aa": "\\mathrm{\\ddot{I}}", // Ϊ
  "\u03ab": "\\mathrm{\\ddot{Y}}", // Ϋ
  "\u03ac": "\\'{$\\alpha$}", // ά
  "\u03ad": "\\acute{\\epsilon}", // έ
  "\u03ae": "\\acute{\\eta}", // ή
  "\u03af": "\\acute{\\iota}", // ί
  "\u03b0": "\\acute{\\ddot{\\upsilon}}", // ΰ
  "\u03ca": "\\ddot{\\iota}", // ϊ
  "\u03cb": "\\ddot{\\upsilon}", // ϋ
  "\u03cc": "\\'{o}", // ό
  "\u03cd": "\\acute{\\upsilon}", // ύ
  "\u03ce": "\\acute{\\omega}", // ώ
  "\u2006": "\\hspace{0.166em}", // no symbol
  "\u2645": "\\uranus", // ♅
  "\u264a": "\\gemini", // ♊
  "\u264b": "\\cancer", // ♋
  "\u264c": "\\leo", // ♌
  "\u264d": "\\virgo", // ♍
  "\u264e": "\\libra", // ♎
  "\u264f": "\\scorpio", // ♏
  "\u2650": "\\sagittarius", // ♐
  "\u2651": "\\capricornus", // ♑
  "\u2652": "\\aquarius", // ♒
  "\u2653": "\\pisces", // ♓
  "\ufb00": "ff", // ﬀ
  "\ufb01": "fi", // ﬁ
  "\ufb02": "fl", // ﬂ
  "\ufb03": "ffi", // ﬃ
  "\ufb04": "ffl", // ﬄ"
};

export const tex_to_unicode = {
  "\\sqrtbottom": "\u23b7", // ⎷
  "\\bfscrb": "\ud4eb", // 퓫
  "\\times": "\u00d7", // ×
  "\\circeq": "\u2257", // ≗
  "\\rparengtr": "\u2994", // ⦔
  "\\bfitsansl": "\ud661", // 홡
  "\\bduVbh": "\u2568", // ╨
  "\\fourvdots": "\u2999", // ⦙
  "\\leftdbltail": "\u291b", // ⤛
  "\\blackcircledtwodots": "\u2689", // ⚉
  "\\acute": "\u0301", // ́
  "\\bfitzeta": "\ud73b", // 휻
  "\\bfscrG": "\ud4d6", // 퓖
  "\\bffrakK": "\ud576", // 핶
  "\\bfsansvarTheta": "\ud767", // 흧
  "\\sqsubsetneq": "\u22e4", // ⋤
  "\\circleda": "\u24d0", // ⓐ
  "\\bfAlpha": "\ud6a8", // 효
  "\\textctt": "\u0236", // ȶ
  "\\itm": "\ud45a", // 푚
  "\\APLboxquestion": "\u2370", // ⍰
  "\\elsdot": "\u2a97", // ⪗
  "\\itIota": "\ud6ea", // 훪
  "\\hat": "\u0302", // ̂
  "\\textfall": "\u02c6", // ˆ
  "\\lat": "\u2aab", // ⪫
  "\\jupiter": "\u2643", // ♃
  "\\ttthree": "\ud7f9", // ퟹ
  "\\bfeta": "\ud6c8", // 훈
  "\\circledbullet": "\u29bf", // ⦿
  "\\itKappa": "\ud6eb", // 훫
  "\\bfscrW": "\ud4e6", // 퓦
  "\\textsubcircum": "\u032d", // ̭
  "\\textsch": "\u029c", // ʜ
  "\\looparrowright": "\u21ac", // ↬
  "\\texthorn": "\u031b", // ̛
  "\\nequiv": "\u2262", // ≢
  "\\precneq": "\u2ab1", // ⪱
  "\\bfitkappa": "\ud73f", // 휿
  "\\itsanss": "\ud634", // 혴
  "\\triangleleftblack": "\u25ed", // ◭
  "\\circledX": "\u24cd", // Ⓧ
  "\\lesdoto": "\u2a81", // ⪁
  "\\bfG": "\ud406", // 퐆
  "\\ddot": "\u0308", // ̈
  "\\nsucccurlyeq": "\u22e1", // ⋡
  "\\ttl": "\ud695", // 횕
  "\\Gt": "\u2aa2", // ⪢
  "\\bffraku": "\ud59a", // 햚
  "\\vBar": "\u2ae8", // ⫨
  "\\squareurquad": "\u25f3", // ◳
  "\\rAngle": "\u27eb", // ⟫, 》
  "\\looparrowleft": "\u21ab", // ↫
  "\\overrightharpoondown": "\u20ec", // ⃬
  "\\BbbR": "\u211d", // ℝ, 핉
  "\\bfsanst": "\ud601", // 혁
  "\\sqsubseteq": "\u2291", // ⊑
  "\\bfscrg": "\ud4f0", // 퓰
  "\\itiota": "\ud704", // 휄
  "\\sumtop": "\u23b2", // ⎲
  "\\tte": "\ud68e", // 횎
  "\\circledsanseight": "\u2787", // ➇
  "\\upvarbeta": "\u03d0", // ϐ
  "\\bfpsi": "\ud6d9", // 훙
  "\\itsansk": "\ud62c", // 혬
  "\\itsansB": "\ud609", // 혉
  "\\frakO": "\ud512", // 픒
  "\\fractwofifths": "\u2156", // ⅖
  "\\bfitbeta": "\ud737", // 휷
  "\\upint": "\u2a1b", // ⨛
  "\\textbackdprime": "\u2036", // ‶
  "\\circlevertfill": "\u25cd", // ◍
  "\\frakd": "\ud521", // 픡
  "\\bfitsansKappa": "\ud799", // 힙
  "\\Bbbj": "\ud55b", // 핛
  "\\itphi": "\ud711", // 휑
  "\\textturnt": "\u0287", // ʇ
  "\\Rsh": "\u21b1", // ↱
  "\\d": "\u0323", // ̣
  "\\textoverlayshortsolidus": "\u0337", // ̷
  "\\neg": "\u00ac", // ¬
  "\\watchicon": "\u231a", // ⌚
  "\\intclockwise": "\u2231", // ∱
  "\\errbarblacksquare": "\u29ef", // ⧯
  "\\blackcircleulquadwhite": "\u25d5", // ◕
  "\\measeq": "\u225e", // ≞
  "\\itsansM": "\ud614", // 혔
  "\\bfscrN": "\ud4dd", // 퓝
  "\\TH": "\u00de", // Þ
  "\\bfvartheta": "\ud6dd", // 훝
  "\\nVDash": "\u22af", // ⊯
  "\\hknwarrow": "\u2923", // ⤣
  "\\bfscra": "\ud4ea", // 퓪
  "\\equalrightarrow": "\u2971", // ⥱
  "\\frakM": "\ud510", // 픐
  "\\bfitsansn": "\ud663", // 홣
  "\\textbackprime": "\u2035", // ‵
  "\\smallint": "\u222b", // ∫
  "\\squarellblack": "\u2b15", // ⬕
  "\\frake": "\ud522", // 픢
  "\\succnapprox": "\u2aba", // ⪺
  "\\bfsansIota": "\ud75e", // 흞
  "\\barcap": "\u2a43", // ⩃
  "\\textregistered": "\u00ae", // ®
  "\\textlhalfring": "\u02d3", // ˓
  "\\bfitg": "\ud488", // 품
  "\\textadvanced": "\u02d6", // ˖
  "\\bduVbH": "\u2569", // ╩
  "\\bffrakp": "\ud595", // 햕
  "\\itEpsilon": "\ud6e6", // 훦
  "\\textrevepsilon": "\u025c", // ɜ
  "\\textoverlayshortstroke": "\u0335", // ̵
  "\\cprime": "\u02b9", // ʹ
  "\\lAngle": "\u300a", // 《, ⟪
  "\\itBbbd": "\u2146", // ⅆ
  "\\textsubarch": "\u032f", // ̯
  "\\nsubseteq": "\u2288", // ⊈
  "\\bfscrM": "\ud4dc", // 퓜
  "\\bfitnabla": "\ud735", // 휵
  "\\bfitMu": "\ud727", // 휧
  "\\Uuparrow": "\u290a", // ⤊
  "\\urcrop": "\u230e", // ⌎
  "\\sansLmirrored": "\u2142", // ⅂
  "\\geqqslant": "\u2afa", // ⫺
  "\\textohm": "\u2126", // Ω
  "\\simplus": "\u2a24", // ⨤
  "\\circledz": "\u24e9", // ⓩ
  "\\textctl": "\u0234", // ȴ
  "\\bdvrule": "\u2502", // │
  "\\iff": "\u21d4", // ⇔
  "\\forks": "\u2adc", // ⫝̸
  "\\bfa": "\ud41a", // 퐚
  "\\rightharpoonupbar": "\u2953", // ⥓
  "\\rightthreearrows": "\u21f6", // ⇶
  "\\circledfive": "\u2464", // ⑤
  "\\textthreequarters": "\u00be", // ¾
  "\\zwnonjoin": "\u200c", // ‌
  "\\upalpha": "\u03b1", // α
  "\\ltrpar": "\u2222", // ∢
  "\\textdyoghlig": "\u02a4", // ʤ
  "\\ocommatopright": "\u0315", // ̕
  "\\eqcolon": "\u2255", // ≕
  "\\hknearrow": "\u2924", // ⤤
  "\\cong": "\u2245", // ≅
  "\\textsecstress": "\u02cc", // ˌ
  "\\bfitA": "\ud468", // 푨
  "\\bfL": "\ud40b", // 퐋
  "\\textctz": "\u0291", // ʑ
  "\\Cap": "\u22d2", // ⋒
  "\\bduVlh": "\u255c", // ╜
  "\\blackcircledsansten": "\u2793", // ➓
  "\\bfitsansq": "\ud666", // 홦
  "\\scrK": "\ud4a6", // 풦
  "\\blocklowhalf": "\u2584", // ▄
  "\\bfitsansEpsilon": "\ud794", // 힔
  "\\bdbvrh": "\u251c", // ├
  "\\ddddot": "\u20dc", // ⃜
  "\\crangle": "\u232a", // 〉
  "\\exclam": "\u0021", // !
  "\\prurel": "\u22b0", // ⊰
  "\\gesdot": "\u2a80", // ⪀
  "\\rightarrowdiamond": "\u291e", // ⤞
  "\\ccwundercurvearrow": "\u293f", // ⤿
  "\\textprimstress": "\u02c8", // ˈ
  "\\nvDash": "\u22ad", // ⊭
  "\\t": "\u0361", // ͡
  "\\itE": "\ud438", // 퐸
  "\\circledcirc": "\u229a", // ⊚
  "\\npolint": "\u2a14", // ⨔
  "\\textturnr": "\u0279", // ɹ
  "\\bfitq": "\ud492", // 풒
  "\\scrm": "\ud4c2", // 퓂
  "\\tosa": "\u2929", // ⤩
  "\\Re": "\u211c", // ℜ
  "\\frakL": "\ud50f", // 픏
  "\\between": "\u226c", // ≬
  "\\Game": "\u2141", // ⅁
  "\\fractwothirds": "\u2154", // ⅔
  "\\rparen": "\u0029", // )
  "\\Bbby": "\ud56a", // 핪
  "\\upDigamma": "\u03dc", // Ϝ
  "\\sansf": "\ud5bf", // 햿
  "\\upoldkoppa": "\u03d9", // ϙ
  "\\bfscrf": "\ud4ef", // 퓯
  "\\leftdbkarrow": "\u290e", // ⤎
  "\\approxeq": "\u224a", // ≊
  "\\bffour": "\ud7d2", // ퟒ
  "\\circledeight": "\u2467", // ⑧
  "\\bfitsansU": "\ud650", // 홐
  "\\itsansN": "\ud615", // 형
  "\\mdsmblksquare": "\u25fe", // ◾
  "\\circledk": "\u24da", // ⓚ
  "\\bfity": "\ud49a", // 풚
  "\\vzigzag": "\u299a", // ⦚
  "\\bfsanseight": "\ud7f4", // ퟴ
  "\\acwopencirclearrow": "\u21ba", // ↺
  "\\bfsansX": "\ud5eb", // 헫
  "\\varisins": "\u22f3", // ⋳
  "\\textringaccent": "\u02da", // ˚
  "\\bfitRho": "\ud72c", // 휬
  "\\textbullseye": "\u0298", // ʘ
  "\\sansM": "\ud5ac", // 햬
  "\\ncong": "\u2247", // ≇
  "\\textctn": "\u0235", // ȵ
  "\\cylcty": "\u232d", // ⌭
  "\\bdquadvdash": "\u250a", // ┊
  "\\thickspace": "\u2005", // no symbol
  "\\itJ": "\ud43d", // 퐽
  "\\rightleftharpoons": "\u21cc", // ⇌
  "\\leftharpoonup": "\u21bc", // ↼
  "\\ttS": "\ud682", // 횂
  "\\bfitsanso": "\ud664", // 홤
  "\\bfscro": "\ud4f8", // 퓸
  "\\thicksim": "\u223c", // ∼
  "\\doublebarwedge": "\u2a5e", // ⩞
  "\\ttm": "\ud696", // 횖
  "\\bduvrH": "\u2558", // ╘
  "\\ttA": "\ud670", // 홰
  "\\lbrack": "\u005b", // [
  "\\precnapprox": "\u2ab9", // ⪹
  "\\sansA": "\ud5a0", // 햠
  "\\rightanglemdot": "\u299d", // ⦝
  "\\prec": "\u227a", // ≺
  "\\upOmega": "\u03a9", // Ω
  "\\isinvb": "\u22f8", // ⋸
  "\\bfsansSigma": "\ud768", // 흨
  "\\bfsansD": "\ud5d7", // 헗
  "\\nleftarrow": "\u219a", // ↚
  "\\gneqq": "\u2269", // ≩
  "\\frakf": "\ud523", // 픣
  "\\eqvparsl": "\u29e5", // ⧥
  "\\midcir": "\u2af0", // ⫰
  "\\boxcircle": "\u29c7", // ⧇
  "\\Vvert": "\u2980", // ⦀
  "\\lvzigzag": "\u29d8", // ⧘
  "\\bfsansUpsilon": "\ud76a", // 흪
  "\\phone": "\u260e", // ☎
  "\\blockhalfshaded": "\u2592", // ▒
  "\\bfitsansXi": "\ud79d", // 힝
  "\\dicev": "\u2684", // ⚄
  "\\emptysetocirc": "\u29b2", // ⦲
  "\\itEta": "\ud6e8", // 훨
  "\\lparenuend": "\u239b", // ⎛
  "\\unknown": "\ufffd", // �
  "\\simlE": "\u2a9f", // ⪟
  "\\bfsansfive": "\ud7f1", // ퟱ
  "\\natural": "\u266e", // ♮
  "\\ity": "\ud466", // 푦
  "\\bfitm": "\ud48e", // 풎
  "\\bfitsansPsi": "\ud7a7", // 힧
  "\\bflambda": "\ud6cc", // 훌
  "\\elinters": "\u23e7", // ⏧
  "\\bigslopedvee": "\u2a57", // ⩗
  "\\circlelefthalfblack": "\u25d0", // ◐
  "\\ntriangleleft": "\u22ea", // ⋪
  "\\dddot": "\u20db", // ⃛
  "\\ttZ": "\ud689", // 횉
  "\\textdctzlig": "\u02a5", // ʥ
  "\\textbacktrprime": "\u2037", // ‷
  "\\itBbbi": "\u2148", // ⅈ
  "\\ttc": "\ud68c", // 회
  "\\hookrightarrow": "\u21aa", // ↪
  "\\gtrdot": "\u22d7", // ⋗
  "\\bfsanso": "\ud5fc", // 헼
  "\\bfitchi": "\ud74c", // 흌
  "\\bffrakI": "\ud574", // 해
  "\\textramshorns": "\u0264", // ɤ
  "\\textrtailt": "\u0288", // ʈ
  "\\leftarrowplus": "\u2946", // ⥆
  "\\Longleftrightarrow": "\u27fa", // ⟺
  "\\enclosediamond": "\u20df", // ⃟
  "\\equiv": "\u2261", // ≡
  "\\PropertyLine": "\u214a", // ⅊
  "\\upXi": "\u039e", // Ξ
  "\\subfour": "\u2084", // ₄
  "\\circledC": "\u24b8", // Ⓒ
  "\\bigcupdot": "\u2a03", // ⨃
  "\\longleftzigzagarrow": "\u21dc", // ⇜
  "\\dashVdash": "\u27db", // ⟛
  "\\itPi": "\ud6f1", // 훱
  "\\scrp": "\ud4c5", // 퓅
  "\\tieinfty": "\u29dd", // ⧝
  "\\bfsansTheta": "\ud75d", // 흝
  "\\textvibyy": "\u02af", // ʯ
  "\\whitesquaretickright": "\u27e5", // ⟥
  "\\textonesuperior": "\u00b9", // ¹
  "\\DashVDash": "\u27da", // ⟚
  "\\ttz": "\ud6a3", // 횣
  "\\updelta": "\u03b4", // δ
  "\\ittau": "\ud70f", // 휏
  "\\suprparen": "\u207e", // ⁾
  "\\bfitE": "\ud46c", // 푬
  "\\rbrackuend": "\u23a4", // ⎤
  "\\flat": "\u266d", // ♭
  "\\dircurrent": "\u2393", // ⎓
  "\\textAsterisks": "\u2051", // ⁑
  "\\bfscrv": "\ud4ff", // 퓿
  "\\DDownarrow": "\u27f1", // ⟱
  "\\invisiblesep": "\u2063", // ⁣
  "\\perps": "\u2ae1", // ⫡
  "\\bfK": "\ud40a", // 퐊
  "\\hkswarrow": "\u2926", // ⤦
  "\\zpipe": "\u2a20", // ⨠
  "\\textadvancing": "\u0318", // ̘
  "\\frakP": "\ud513", // 픓
  "\\bdbvlH": "\u2561", // ╡
  "\\parallelogram": "\u25b1", // ▱
  "\\RtoLmark": "\u200f", // ‏
  "\\frakI": "\ud50c", // 플, ℑ
  "\\BbbP": "\ud547", // 핇, ℙ
  "\\circledn": "\u24dd", // ⓝ
  "\\bfnu": "\ud6ce", // 훎
  "\\uparrowoncircle": "\u29bd", // ⦽
  "\\shortdowntack": "\u2adf", // ⫟
  "\\bfitb": "\ud483", // 풃
  "\\textyogh": "\u0292", // ʒ
  "\\lmoustache": "\u23b0", // ⎰
  "\\textturnrrtail": "\u027b", // ɻ
  "\\%": "\u0025", // %
  "\\supfour": "\u2074", // ⁴
  "\\bffrakL": "\ud577", // 핷
  "\\supdsub": "\u2ad8", // ⫘
  "\\itr": "\ud45f", // 푟
  "\\bfitH": "\ud46f", // 푯
  "\\bfitvarsigma": "\ud747", // 흇
  "\\rightharpoonup": "\u21c0", // ⇀
  "\\DJ": "\u0110", // Đ
  "\\thinspace": "\u2009", // no symbol
  "\\acwoverarcarrow": "\u293a", // ⤺
  "\\ae": "\u00e6", // æ
  "\\vertoverlay": "\u20d2", // ⃒
  "\\rightarrowtriangle": "\u21fe", // ⇾
  "\\rblkbrbrak": "\u2998", // ⦘
  "\\bfGamma": "\ud6aa", // 횪
  "\\cwcirclearrow": "\u2941", // ⥁
  "\\bfitsansSigma": "\ud7a2", // 힢
  "\\bfsansE": "\ud5d8", // 험
  "\\bfPsi": "\ud6bf", // 횿
  "\\squarelrblack": "\u25ea", // ◪
  "\\blackcircledownarrow": "\u29ed", // ⧭
  "\\frakT": "\ud517", // 픗
  "\\biginterleave": "\u2afc", // ⫼
  "\\upzeta": "\u03b6", // ζ
  "\\enspace": "\u2002", // no symbol
  "\\lsqhook": "\u2acd", // ⫍
  "\\scrg": "\u210a", // ℊ, 풼
  "\\sumbottom": "\u23b3", // ⎳
  "\\nmid": "\u2224", // ∤
  "\\rightarrowtail": "\u21a3", // ↣
  "\\textscy": "\u028f", // ʏ
  "\\rightarrowonoplus": "\u27f4", // ⟴
  "\\blocklefthalf": "\u258c", // ▌
  "\\bffrake": "\ud58a", // 햊
  "\\upand": "\u214b", // ⅋
  "\\gsime": "\u2a8e", // ⪎
  "\\timesbar": "\u2a31", // ⨱
  "\\rmoustache": "\u23b1", // ⎱
  "\\circledsansten": "\u2789", // ➉
  "\\quad": "\u2003", // no symbol
  "\\rrparenthesis": "\u2988", // ⦈
  "\\textcent": "\u00a2", // ¢
  "\\rightdowncurvedarrow": "\u2937", // ⤷
  "\\lftimes": "\u29d4", // ⧔
  "\\textcircledP": "\u2117", // ℗
  "\\upPsi": "\u03a8", // Ψ
  "\\Downarrowhead": "\u21d3", // ⇓
  "\\bfitxi": "\ud743", // 흃
  "\\textbardotlessj": "\u025f", // ɟ
  "\\upiota": "\u03b9", // ι
  "\\upfishtail": "\u297e", // ⥾
  "\\bfitvarrho": "\ud754", // 흔
  "\\llangle": "\u2989", // ⦉
  "\\quotdblbase": "\u201e", // „
  "\\itn": "\ud45b", // 푛
  "\\tteight": "\ud7fe", // ퟾
  "\\squareulblack": "\u25e9", // ◩
  "\\smt": "\u2aaa", // ⪪
  "\\textbackslash": "\u005c", // \
  "\\profsurf": "\u2313", // ⌓
  "\\sansy": "\ud5d2", // 헒
  "\\nlesssim": "\u2274", // ≴
  "\\bfsansx": "\ud605", // 혅
  "\\bfitP": "\ud477", // 푷
  "\\wedgeodot": "\u2a51", // ⩑
  "\\rparenuend": "\u239e", // ⎞
  "\\subsix": "\u2086", // ₆
  "\\QED": "\u220e", // ∎
  "\\smallsmile": "\u2323", // ⌣
  "\\ttg": "\ud690", // 횐
  "\\itsansF": "\ud60d", // 혍
  "\\lrtriangle": "\u25ff", // ◿
  "\\rbrbrak": "\u3015", // 〕, ❳
  "\\sansW": "\ud5b6", // 햶
  "\\textbaru": "\u0289", // ʉ
  "\\downarrowbarred": "\u2908", // ⤈
  "\\bfsansz": "\ud607", // 혇
  "\\bfscrV": "\ud4e5", // 퓥
  "\\bfnine": "\ud7d7", // ퟗ
  "\\itpsi": "\ud713", // 휓
  "\\bddVbh": "\u2565", // ╥
  "\\o": "\u00f8", // ø
  "\\rightleftharpoonsup": "\u2968", // ⥨
  "\\textrevglotstop": "\u0295", // ʕ
  "\\Question": "\u2047", // ⁇
  "\\Bbbsix": "\ud7de", // ퟞ
  "\\acwgapcirclearrow": "\u27f2", // ⟲
  "\\itsansJ": "\ud611", // 협
  "\\bfX": "\ud417", // 퐗
  "\\barovernorthwestarrow": "\u21b8", // ↸
  "\\ngtrless": "\u2279", // ≹
  "\\ttI": "\ud678", // 홸
  "\\itsansl": "\ud62d", // 혭
  "\\supsim": "\u2ac8", // ⫈
  "\\Subset": "\u22d0", // ⋐
  "\\less": "\u003c", // <
  "\\nVrightarrowtail": "\u2915", // ⤕
  "\\bfitsansvarTheta": "\ud7a1", // 힡
  "\\csupe": "\u2ad2", // ⫒
  "\\bfsansTau": "\ud769", // 흩
  "\\bfI": "\ud408", // 퐈
  "\\enclosesquare": "\u20de", // ⃞
  "\\ttK": "\ud67a", // 홺
  "\\curlyeqsucc": "\u22df", // ⋟
  "\\cirbot": "\u27df", // ⟟
  "\\textprime": "\u2032", // ′
  "\\scrv": "\ud4cb", // 퓋
  "\\Lt": "\u2aa1", // ⪡
  "\\Bbbk": "\ud55c", // 한
  "\\Wedge": "\u2a53", // ⩓
  "\\supsetplus": "\u2ac0", // ⫀
  "\\towa": "\u292a", // ⤪
  "\\gtcir": "\u2a7a", // ⩺
  "\\bfitsanspartial": "\ud7c3", // ퟃ
  "\\incare": "\u2105", // ℅
  "\\Nwarrow": "\u21d6", // ⇖
  "\\itpi": "\ud70b", // 휋
  "\\bfsansupsilon": "\ud784", // 힄
  "\\eqslantless": "\u2a95", // ⪕
  "\\Rvzigzag": "\u29db", // ⧛
  "\\bdquadhdash": "\u2508", // ┈
  "\\errbarsquare": "\u29ee", // ⧮
  "\\twoheaduparrow": "\u219f", // ↟
  "\\guillemotleft": "\u00ab", // «
  "\\annuity": "\u20e7", // ⃧
  "\\drcrop": "\u230c", // ⌌
  "\\itNu": "\ud6ee", // 훮
  "\\ulblacktriangle": "\u25e4", // ◤
  "\\textsubwedge": "\u032c", // ̬
  "\\scrb": "\ud4b7", // 풷
  "\\tto": "\ud698", // 횘
  "\\bddvlh": "\u2510", // ┐
  "\\Bbbq": "\ud562", // 핢
  "\\textpolhk": "\u0328", // ̨
  "\\Rparenless": "\u2996", // ⦖
  "\\bfitZ": "\ud481", // 풁
  "\\circledF": "\u24bb", // Ⓕ
  "\\bfscrd": "\ud4ed", // 퓭
  "\\frako": "\ud52c", // 픬
  "\\nvrightarrow": "\u21f8", // ⇸
  "\\rBrace": "\u2984", // ⦄
  "\\rightdbkarrow": "\u290f", // ⤏
  "\\circledR": "\u24c7", // Ⓡ
  "\\bdnesw": "\u2571", // ╱
  "\\ttG": "\ud676", // 홶
  "\\frakZ": "\u2128", // ℨ, 픝
  "\\bdbVrh": "\u255f", // ╟
  "\\tonebarhigh": "\u02e6", // ˦
  "\\bfO": "\ud40e", // 퐎
  "\\overbar": "\u0305", // ̅
  "\\smalltriangleleft": "\u25c3", // ◃
  "\\upvarphi": "\u03c6", // φ
  "\\OE": "\u0152", // Œ
  "\\bfitBeta": "\ud71d", // 휝
  "\\rightharpoonupdash": "\u296c", // ⥬
  "\\itnabla": "\ud6fb", // 훻
  "\\tonebarextralow": "\u02e9", // ˩
  "\\leftthreetimes": "\u22cb", // ⋋
  "\\bbone": "\ud7d9", // ퟙ
  "\\textepsilon": "\u025b", // ɛ
  "\\rightdasharrow": "\u21e2", // ⇢
  "\\leftrightarrow": "\u2194", // ↔
  "\\blackcircledsansthree": "\u278c", // ➌
  "\\horizbar": "\u2015", // ―
  "\\upvartheta": "\u03d1", // ϑ
  "\\rightleftharpoonsdown": "\u2969", // ⥩
  "\\textbeltl": "\u026c", // ɬ
  "\\itGamma": "\ud6e4", // 훤
  "\\hslash": "\u210f", // ℏ
  "\\sansQ": "\ud5b0", // 햰
  "\\quarternote": "\u2669", // ♩
  "\\textomega": "\u03c9", // ω
  "\\bfitsansMu": "\ud79b", // 힛
  "\\bfitXi": "\ud729", // 휩
  "\\upstigma": "\u03db", // ϛ
  "\\texthtt": "\u01ad", // ƭ
  "\\opbackslash": "\u29f5", // ⧵
  "\\scrE": "\ud4a0", // 풠, ℰ
  "\\bfsansS": "\ud5e6", // 헦
  "\\bigcap": "\u22c2", // ⋂
  "\\barrightharpoondown": "\u295f", // ⥟
  "\\bffrakE": "\ud570", // 핰
  "\\sanszero": "\ud7e2", // ퟢ
  "\\mapsdown": "\u21a7", // ↧
  "\\rightdotarrow": "\u2911", // ⤑
  "\\intercal": "\u22ba", // ⊺
  "\\sansb": "\ud5bb", // 햻
  "\\bfitOmega": "\ud734", // 휴
  "\\ngtr": "\u226f", // ≯
  "\\bddvbh": "\u252c", // ┬
  "\\vartriangle": "\u25b5", // ▵
  "\\revangleubar": "\u29a5", // ⦥
  "\\frakt": "\ud531", // 픱
  "\\Bbbn": "\ud55f", // 핟
  "\\circledsix": "\u2465", // ⑥
  "\\bffrakP": "\ud57b", // 핻
  "\\itT": "\ud447", // 푇
  "\\mdlgblksquare": "\u25a0", // ■
  "\\itsansh": "\ud629", // 혩
  "\\ohorn": "\u01a1", // ơ
  "\\blacktriangleright": "\u25b6", // ▶
  "\\bfitsansr": "\ud667", // 홧
  "\\circlelrquad": "\u25f6", // ◶
  "\\dsol": "\u29f6", // ⧶
  "\\bfitalpha": "\ud736", // 휶
  "\\conjquant": "\u2a07", // ⨇
  "\\sansv": "\ud5cf", // 헏
  "\\bfo": "\ud428", // 퐨
  "\\varhexagon": "\u2b21", // ⬡
  "\\widehat": "\u0302", // ̂
  "\\upharpoonrightbar": "\u295c", // ⥜
  "\\sansD": "\ud5a3", // 햣
  "\\sansF": "\ud5a5", // 향
  "\\dashcolon": "\u2239", // ∹
  "\\rightarrowsimilar": "\u2974", // ⥴
  "\\ttfour": "\ud7fa", // ퟺ
  "\\texthth": "\u0266", // ɦ
  "\\textoverlaysolidusv": "\u0338", // ̸
  "\\lbraceuend": "\u23a7", // ⎧
  "\\postalmark": "\u3012", // 〒
  "\\ipaunaspirated": "\u02ed", // ˭
  "\\bNot": "\u2aed", // ⫭
  "\\itzeta": "\ud701", // 휁
  "\\textnumero": "\u2116", // №
  "\\bfitTau": "\ud72f", // 휯
  "\\bigcirc": "\u25cb", // ○
  "\\varnothing": "\u2205", // ∅
  "\\bfsanslambda": "\ud77a", // 흺
  "\\sanse": "\ud5be", // 햾
  "\\bdbVlh": "\u2562", // ╢
  "\\upeta": "\u03b7", // η
  "\\bfsansG": "\ud5da", // 헚
  "\\gesdoto": "\u2a82", // ⪂
  "\\textsupw": "\u02b7", // ʷ
  "\\textreferencemark": "\u203b", // ※
  "\\cupdot": "\u228d", // ⊍
  "\\bfitk": "\ud48c", // 풌
  "\\textretracting": "\u0319", // ̙
  "\\trianglecdot": "\u25ec", // ◬
  "\\bfitw": "\ud498", // 풘
  "\\textlonglegr": "\u027c", // ɼ
  "\\bffrakN": "\ud579", // 핹
  "\\blacksquare": "\u25a0", // ■
  "\\dh": "\u00f0", // ð
  "\\textsubbar": "\u0331", // ̱
  "\\Vee": "\u2a54", // ⩔
  "\\bfitY": "\ud480", // 풀
  "\\bfitKappa": "\ud725", // 휥
  "\\textbari": "\u0268", // ɨ
  "\\textlengthmark": "\u02d0", // ː
  "\\circledZ": "\u24cf", // Ⓩ
  "\\textbreve": "\u02d8", // ˘
  "\\frakx": "\ud535", // 픵
  "\\Bbbe": "\ud556", // 핖
  "\\scrr": "\ud4c7", // 퓇
  "\\hyphenbullet": "\u2043", // ⁃
  "\\lgE": "\u2a91", // ⪑
  "\\scpolint": "\u2a13", // ⨓
  "\\wp": "\u2118", // ℘
  "\\blockfull": "\u2588", // █
  "\\itOmicron": "\ud6f0", // 훰
  "\\ulcrop": "\u230f", // ⌏
  "\\bddVrh": "\u2553", // ╓
  "\\rasp": "\u02bc", // ʼ
  "\\leqslant": "\u2a7d", // ⩽
  "\\bfsansLambda": "\ud760", // 흠
  "\\textlambda": "\u03bb", // λ
  "\\itZ": "\ud44d", // 푍
  "\\Vdash": "\u22a9", // ⊩
  "\\tonebarextrahigh": "\u02e5", // ˥
  "\\textsublhalfring": "\u031c", // ̜
  "\\bdbVrH": "\u2560", // ╠
  "\\Bbbsum": "\u2140", // ⅀
  "\\thermod": "\u29e7", // ⧧
  "\\earth": "\u2641", // ♁
  "\\textlhooks": "\u1d8a", // ᶊ
  "\\leftrightharpoonsup": "\u2966", // ⥦
  "\\textscriptg": "\u0261", // ɡ
  "\\textsubgrave": "\u0316", // ̖
  "\\itsansQ": "\ud618", // 혘
  "\\quotdblright": "\u201f", // ‟
  "\\hbar": "\u210f", // ℏ
  "\\lgwhtcircle": "\u25ef", // ◯
  "\\cdots": "\u22ef", // ⋯
  "\\intcap": "\u2a19", // ⨙
  "\\textphi": "\u0278", // ɸ
  "\\bddvrH": "\u2552", // ╒
  "\\textchi": "\u03c7", // χ
  "\\upupsilon": "\u03c5", // υ
  "\\wideangleup": "\u29a7", // ⦧
  "\\textltilde": "\u026b", // ɫ
  "\\fracseveneights": "\u215e", // ⅞
  "\\int": "\u222b", // ∫
  "\\dagger": "\u2020", // †
  "\\bfscrB": "\ud4d1", // 퓑
  "\\circledG": "\u24bc", // Ⓖ
  "\\fracfivesixths": "\u215a", // ⅚
  "\\bfN": "\ud40d", // 퐍
  "\\intx": "\u2a18", // ⨘
  "\\texthtd": "\u0257", // ɗ
  "\\sansm": "\ud5c6", // 헆
  "\\rightarrowshortleftarrow": "\u2942", // ⥂
  "\\upnu": "\u03bd", // ν
  "\\circledone": "\u2460", // ①
  "\\shortmid": "\u2223", // ∣
  "\\blackcircledsanssix": "\u278f", // ➏
  "\\amalg": "\u2a3f", // ⨿
  "\\S": "\u00a7", // §
  "\\blacktriangleleft": "\u25c0", // ◀
  "\\neswarrow": "\u2922", // ⤢
  "\\textlooptoprevesh": "\u01aa", // ƪ
  "\\lambdabar": "\u019b", // ƛ
  "\\minusfdots": "\u2a2b", // ⨫
  "\\frakp": "\ud52d", // 픭
  "\\circledj": "\u24d9", // ⓙ
  "\\longdashv": "\u27de", // ⟞
  "\\textsubminus": "\u0320", // ̠
  "\\capovercup": "\u2a47", // ⩇
  "\\measuredangleleft": "\u299b", // ⦛
  "\\twoheaddownarrow": "\u21a1", // ↡
  "\\succsim": "\u227f", // ≿
  "\\textasciitilde": "\u007e", // ~
  "\\veedot": "\u27c7", // ⟇
  "\\bfsansMu": "\ud761", // 흡
  "\\scrG": "\ud4a2", // 풢
  "\\leqq": "\u2266", // ≦
  "\\bfitsansxi": "\ud7b7", // ힷ
  "\\smiley": "\u263a", // ☺
  "\\fracthreeeighths": "\u215c", // ⅜
  "\\sansO": "\ud5ae", // 햮
  "\\bduVlH": "\u255d", // ╝
  "\\rightthreetimes": "\u22cc", // ⋌
  "\\sim": "\u223c", // ∼
  "\\wedgeonwedge": "\u2a55", // ⩕
  "\\bfU": "\ud414", // 퐔
  "\\blockthreeqtrshaded": "\u2593", // ▓
  "\\cupleftarrow": "\u228c", // ⊌
  "\\bfsansrho": "\ud780", // 힀
  "\\circledq": "\u24e0", // ⓠ
  "\\scrA": "\ud49c", // 풜
  "\\bfitR": "\ud479", // 푹
  "\\BbbJ": "\ud541", // 핁
  "\\suphsol": "\u27c9", // ⟉
  "\\itO": "\ud442", // 푂
  "\\&": "\u0026", // &
  "\\bfJ": "\ud409", // 퐉
  "\\bfitI": "\ud470", // 푰
  "\\dashleftharpoondown": "\u296b", // ⥫
  "\\bffrakA": "\ud56c", // 핬
  "\\bfitvarpi": "\ud755", // 흕
  "\\scrY": "\ud4b4", // 풴
  "\\bfitsansZeta": "\ud795", // 힕
  "\\textunderasterisk": "\u0359", // ͙
  "\\textsyllabic": "\u0329", // ̩
  "\\sansfive": "\ud7e7", // ퟧ
  "\\itkappa": "\ud705", // 휅
  "\\textreve": "\u0258", // ɘ
  "\\bdbVlH": "\u2563", // ╣
  "\\sansK": "\ud5aa", // 햪
  "\\bfsansC": "\ud5d6", // 헖
  "\\nisd": "\u22fa", // ⋺
  "\\smallni": "\u220d", // ∍
  "\\bfscrC": "\ud4d2", // 퓒
  "\\bddvrh": "\u250c", // ┌
  "\\bfitsansvarrho": "\ud7c8", // ퟈
  "\\frakA": "\ud504", // 프
  "\\subthree": "\u2083", // ₃
  "\\bffrakH": "\ud573", // 핳
  "\\iiiint": "\u2a0c", // ⨌
  "\\ttq": "\ud69a", // 횚
  "\\itP": "\ud443", // 푃
  "\\leftrightarrowtriangle": "\u21ff", // ⇿
  "\\whitearrowupfrombar": "\u21ea", // ⇪
  "\\veeonwedge": "\u2a59", // ⩙
  "\\late": "\u2aad", // ⪭
  "\\scrR": "\u211b", // ℛ, 풭
  "\\textblank": "\u2422", // ␢
  "\\scrD": "\ud49f", // 풟
  "\\oint": "\u222e", // ∮
  "\\bfscrq": "\ud4fa", // 퓺
  "\\textdotlessj": "\u0237", // ȷ
  "\\bfitsansNu": "\ud79c", // 힜
  "\\smwhtdiamond": "\u22c4", // ⋄
  "\\tnyblkcircle": "\u22c5", // ⋅
  "\\bfiteta": "\ud73c", // 휼
  "\\parallelogramblack": "\u25b0", // ▰
  "\\frakl": "\ud529", // 픩
  "\\bfLambda": "\ud6b2", // 횲
  "\\lambdaslash": "\u019b", // ƛ
  "\\Longrightarrow": "\u27f9", // ⟹
  "\\Longleftarrow": "\u27f8", // ⟸
  "\\Bbbo": "\ud560", // 할
  "\\scrP": "\ud4ab", // 풫
  "\\increment": "\u2206", // ∆
  "\\sansY": "\ud5b8", // 햸
  "\\smallblacktriangleleft": "\u25c2", // ◂
  "\\bfsansW": "\ud5ea", // 헪
  "\\bfXi": "\ud6b5", // 횵
  "\\bdbvrH": "\u255e", // ╞
  "\\bffrakU": "\ud580", // 햀
  "\\rightouterjoin": "\u27d6", // ⟖
  "\\itB": "\ud435", // 퐵
  "\\bfitnu": "\ud742", // 흂
  "\\bfsanssigma": "\ud782", // 힂
  "\\eqdot": "\u2a66", // ⩦
  "\\searrow": "\u2198", // ↘
  "\\circlerighthalfblack": "\u25d1", // ◑
  "\\bffrakD": "\ud56f", // 핯
  "\\leftrightharpoondowndown": "\u2950", // ⥐
  "\\zwjoin": "\u200d", // ‍
  "\\fdiagovnearrow": "\u292f", // ⤯
  "\\iti": "\ud456", // 푖
  "\\bfk": "\ud424", // 퐤
  "\\guilsinglleft": "\u2039", // ‹
  "\\bfsansb": "\ud5ef", // 헯
  "\\BbbB": "\ud539", // 픹
  "\\bfsansd": "\ud5f1", // 헱
  "\\bfitsansL": "\ud647", // 홇
  "\\textmacron": "\u02c9", // ˉ
  "\\sansl": "\ud5c5", // 헅
  "\\fallingdotseq": "\u2252", // ≒
  "\\bfscrn": "\ud4f7", // 퓷
  "\\bfscrE": "\ud4d4", // 퓔
  "\\leftdasharrowhead": "\u21e0", // ⇠
  "\\leftleftarrows": "\u21c7", // ⇇
  "\\bfitsansTau": "\ud7a3", // 힣
  "\\upsampi": "\u03e1", // ϡ
  "\\bfsansm": "\ud5fa", // 헺
  "\\sansh": "\ud5c1", // 헁
  "\\upRho": "\u03a1", // Ρ
  "\\tts": "\ud69c", // 횜
  "\\scurel": "\u22b1", // ⊱
  "\\bffrakh": "\ud58d", // 햍
  "\\frakN": "\ud511", // 픑
  "\\itsansi": "\ud62a", // 혪
  "\\bfsansp": "\ud5fd", // 헽
  "\\circlehbar": "\u29b5", // ⦵
  "\\bfM": "\ud40c", // 퐌
  "\\rdiagovsearrow": "\u2930", // ⤰
  "\\circledA": "\u24b6", // Ⓐ
  "\\diamondbotblack": "\u2b19", // ⬙
  "\\texttslig": "\u02a6", // ʦ
  "\\ttL": "\ud67b", // 홻
  "\\bfitsansd": "\ud659", // 홙
  "\\whiteinwhitetriangle": "\u27c1", // ⟁
  "\\lparenextender": "\u239c", // ⎜
  "\\mdlgblkdiamond": "\u25c6", // ◆
  "\\lBrack": "\u301a", // 〚, ⟦
  "\\nis": "\u22fc", // ⋼
  "\\BbbW": "\ud54e", // 핎
  "\\plussim": "\u2a26", // ⨦
  "\\boxdiag": "\u29c4", // ⧄
  "\\target": "\u2316", // ⌖
  "\\bfPi": "\ud6b7", // 횷
  "\\obar": "\u233d", // ⌽
  "\\bfsansPsi": "\ud76d", // 흭
  "\\rtriltri": "\u29ce", // ⧎
  "\\fraku": "\ud532", // 픲
  "\\eqqless": "\u2a99", // ⪙
  "\\uppi": "\u03c0", // π
  "\\coloneq": "\u2254", // ≔
  "\\varni": "\u220b", // ∋
  "\\textquotedblright": "\u201d", // ”
  "\\itt": "\ud461", // 푡
  "\\itW": "\ud44a", // 푊
  "\\bfdelta": "\ud6c5", // 훅
  "\\scrs": "\ud4c8", // 퓈
  "\\Bbba": "\ud552", // 핒
  "\\female": "\u2640", // ♀
  "\\Rbrbrak": "\u3019", // 〙, ⟭
  "\\bfitB": "\ud469", // 푩
  "\\frakE": "\ud508", // 픈
  "\\textdoublevbaraccent": "\u030e", // ̎
  "\\Lvzigzag": "\u29da", // ⧚
  "\\angle": "\u2220", // ∠
  "\\longleftrightarrow": "\u27f7", // ⟷
  "\\measangleurtone": "\u29ac", // ⦬
  "\\bigodot": "\u2a00", // ⨀
  "\\bfitsansphi": "\ud7bf", // ힿ
  "\\underbrace": "\ufe38", // ︸, ⏟
  "\\dicevi": "\u2685", // ⚅
  "\\eparsl": "\u29e3", // ⧣
  "\\circledw": "\u24e6", // ⓦ
  "\\Longmapsto": "\u27fe", // ⟾
  "\\itsansC": "\ud60a", // 혊
  "\\BbbZ": "\u2124", // ℤ, 핑
  "\\Planckconst": "\u210e", // ℎ
  "\\spadesuit": "\u2660", // ♠
  "\\dashV": "\u2ae3", // ⫣
  "\\check": "\u030c", // ̌
  "\\itH": "\ud43b", // 퐻
  "\\trianglerighteq": "\u22b5", // ⊵
  "\\nsucc": "\u2281", // ⊁
  "\\textdoublepipe": "\u01c1", // ǁ
  "\\scrk": "\ud4c0", // 퓀
  "\\lq": "\u2018", // ‘
  "\\lneqq": "\u2268", // ≨
  "\\frakW": "\ud51a", // 픚
  "\\sqcup": "\u2294", // ⊔
  "\\leftrightsquigarrow": "\u21ad", // ↭
  "\\bfscre": "\ud4ee", // 퓮
  "\\longleftarrow": "\u27f5", // ⟵
  "\\topfork": "\u2ada", // ⫚
  "\\gg": "\u226b", // ≫
  "\\wedgedoublebar": "\u2a60", // ⩠
  "\\twocaps": "\u2a4b", // ⩋
  "\\seovnearrow": "\u292d", // ⤭
  "\\textdotaccent": "\u02d9", // ˙
  "\\nVtwoheadrightarrow": "\u2901", // ⤁
  "\\BbbG": "\ud53e", // 픾
  "\\squoval": "\u25a2", // ▢
  "\\barvee": "\u22bd", // ⊽
  "\\underrightarrow": "\u20ef", // ⃯
  "\\leftharpoonsupdown": "\u2962", // ⥢
  "\\curvearrowright": "\u21b7", // ↷
  "\\bfv": "\ud42f", // 퐯
  "\\tti": "\ud692", // 횒
  "\\imageof": "\u22b7", // ⊷
  "\\wideutilde": "\u0330", // ̰
  "\\fracthreefifths": "\u2157", // ⅗
  "\\profline": "\u2312", // ⌒
  "\\bfitdelta": "\ud739", // 휹
  "\\bfitsansP": "\ud64b", // 홋
  "\\mapsto": "\u21a6", // ↦
  "\\similarrightarrow": "\u2972", // ⥲
  "\\textsci": "\u026a", // ɪ
  "\\capdot": "\u2a40", // ⩀
  "\\frakv": "\ud533", // 픳
  "\\mdwhtcircle": "\u26aa", // ⚪
  "\\frown": "\u2322", // ⌢
  "\\longmapsto": "\u27fc", // ⟼
  "\\sansI": "\ud5a8", // 햨
  "\\bffrakw": "\ud59c", // 햜
  "\\vert": "\u007c", // |
  "\\circledh": "\u24d7", // ⓗ
  "\\itsanse": "\ud626", // 혦
  "\\mdsmwhtsquare": "\u25fd", // ◽
  "\\itsansc": "\ud624", // 혤
  "\\veedoublebar": "\u2a63", // ⩣
  "\\leftrightwavyarrow": "\u21ad", // ↭
  "\\BbbL": "\ud543", // 핃
  "\\textbrokenbar": "\u00a6", // ¦
  "\\multimap": "\u22b8", // ⊸
  "\\lessdot": "\u22d6", // ⋖
  "\\uprho": "\u03c1", // ρ
  "\\textvisiblespace": "\u2423", // ␣
  "\\scrL": "\u2112", // ℒ, 풧
  "\\textpalhook": "\u0321", // ̡
  "\\downfishtail": "\u297f", // ⥿
  "\\bigcup": "\u22c3", // ⋃
  "\\bfitsansy": "\ud66e", // 홮
  "\\boxast": "\u29c6", // ⧆
  "\\textturnscripta": "\u0252", // ɒ
  "\\textsubring": "\u0325", // ̥
  "\\bigtop": "\u27d9", // ⟙
  "\\Swarrow": "\u21d9", // ⇙
  "\\nvinfty": "\u29de", // ⧞
  "\\asteq": "\u2a6e", // ⩮
  "\\textrtaild": "\u0256", // ɖ
  "\\twocups": "\u2a4a", // ⩊
  "\\hksearrow": "\u2925", // ⤥
  "\\bullet": "\u2219", // ∙
  "\\sanss": "\ud5cc", // 헌
  "\\bfV": "\ud415", // 퐕
  "\\sansH": "\ud5a7", // 햧
  "\\bfitsansdelta": "\ud7ad", // 힭
  "\\wideoverrightarrow": "\u20d7", // ⃗
  "\\clubsuit": "\u2663", // ♣
  "\\APLboxupcaret": "\u2353", // ⍓
  "\\bfitt": "\ud495", // 풕
  "\\uminus": "\u2a41", // ⩁
  "\\leftarrowtail": "\u21a2", // ↢
  "\\subsetdot": "\u2abd", // ⪽
  "\\bfitsansupsilon": "\ud7be", // ힾ
  "\\ominus": "\u2296", // ⊖
  "\\bffrakG": "\ud572", // 핲
  "\\bfscrR": "\ud4e1", // 퓡
  "\\nleftrightarrow": "\u21ae", // ↮
  "\\bffive": "\ud7d3", // ퟓ
  "\\bfscrj": "\ud4f3", // 퓳
  "\\bdbvbh": "\u253c", // ┼
  "\\k": "\u0328", // ̨
  "\\bfe": "\ud41e", // 퐞
  "\\upStigma": "\u03da", // Ϛ
  "\\bfsansT": "\ud5e7", // 헧
  "\\downharpoonleftbar": "\u2959", // ⥙
  "\\textctyogh": "\u0293", // ʓ
  "\\bfscry": "\ud502", // 픂
  "\\bfsansu": "\ud602", // 혂
  "\\bfitsansG": "\ud642", // 홂
  "\\nVleftrightarrow": "\u21fc", // ⇼
  "\\odiv": "\u2a38", // ⨸
  "\\ltimes": "\u22c9", // ⋉
  "\\succneqq": "\u2ab6", // ⪶
  "\\nvRightarrow": "\u2903", // ⤃
  "\\itvarphi": "\ud719", // 휙
  "\\rightbkarrow": "\u290d", // ⤍
  "\\bfitsansLambda": "\ud79a", // 힚
  "\\otimesrhrim": "\u2a35", // ⨵
  "\\scrC": "\ud49e", // 풞
  "\\submult": "\u2ac1", // ⫁
  "\\nsqsupseteq": "\u22e3", // ⋣
  "\\bigblacktriangledown": "\u25bc", // ▼
  "\\itxi": "\ud709", // 휉
  "\\textlowacute": "\u02cf", // ˏ
  "\\scrJ": "\ud4a5", // 풥
  "\\digitspace": "\u2007", // no symbol
  "\\intlarhk": "\u2a17", // ⨗
  "\\textquotedbl": "\u0022", // \"
  "\\textacute": "\u02ca", // ˊ
  "\\llcorner": "\u231e", // ⌞
  "\\dingasterisk": "\u273d", // ✽
  "\\Rightarrow": "\u21d2", // ⇒
  "\\textretracted": "\u02d7", // ˗
  "\\ipasuprerglotstpp": "\u02e4", // ˤ
  "\\inversewhitecircle": "\u25d9", // ◙
  "\\ipasupl": "\u02e1", // ˡ
  "\\bigsqcap": "\u2a05", // ⨅
  "\\bffrakz": "\ud59f", // 햟
  "\\sun": "\u263c", // ☼
  "\\itsansR": "\ud619", // 혙
  "\\itf": "\ud453", // 푓
  "\\textordmasculine": "\u00ba", // º
  "\\bfitsansC": "\ud63e", // 혾
  "\\blackrighthalfcircle": "\u25d7", // ◗
  "\\upPi": "\u03a0", // Π
  "\\textonequarter": "\u00bc", // ¼
  "\\sum": "\u2211", // ∑
  "\\bfsansJ": "\ud5dd", // 헝
  "\\triangledown": "\u25bf", // ▿
  "\\ttd": "\ud68d", // 획
  "\\succeqq": "\u2ab4", // ⪴
  "\\ttseven": "\ud7fd", // ퟽
  "\\Bbbl": "\ud55d", // 핝
  "\\in": "\u2208", // ∈
  "\\itsansv": "\ud637", // 혷
  "\\textsubw": "\u032b", // ̫
  "\\vee": "\u2228", // ∨
  "\\bigbot": "\u27d8", // ⟘
  "\\bfsansnu": "\ud77c", // 흼
  "\\textschwa": "\u0259", // ə
  "\\bfd": "\ud41d", // 퐝
  "\\congdot": "\u2a6d", // ⩭
  "\\ttP": "\ud67f", // 홿
  "\\bfsansU": "\ud5e8", // 헨
  "\\bfsansfour": "\ud7f0", // ퟰ
  "\\boxtimes": "\u22a0", // ⊠
  "\\closedvarcupsmashprod": "\u2a50", // ⩐
  "\\bddvlH": "\u2555", // ╕
  "\\textfishhookr": "\u027e", // ɾ
  "\\mdlgwhtdiamond": "\u25c7", // ◇
  "\\bfnabla": "\ud6c1", // 훁
  "\\squarehfill": "\u25a4", // ▤
  "\\upChi": "\u03a7", // Χ
  "\\ltcc": "\u2aa6", // ⪦
  "\\sansz": "\ud5d3", // 헓
  "\\div": "\u00f7", // ÷
  "\\BbbX": "\ud54f", // 핏
  "\\rsolbar": "\u29f7", // ⧷
  "\\mdlgwhtcircle": "\u25cb", // ○
  "\\bfittau": "\ud749", // 흉
  "\\upAlpha": "\u0391", // Α
  "\\textlowmacron": "\u02cd", // ˍ
  "\\itsansL": "\ud613", // 혓
  "\\updownharpoonrightright": "\u294f", // ⥏
  "\\wedgebar": "\u2a5f", // ⩟
  "\\subfive": "\u2085", // ₅
  "\\gleichstark": "\u29e6", // ⧦
  "\\upvarsigma": "\u03c2", // ς
  "\\supfive": "\u2075", // ⁵
  "\\bfitsansa": "\ud656", // 홖
  "\\revemptyset": "\u29b0", // ⦰
  "\\itdelta": "\ud6ff", // 훿
  "\\itN": "\ud441", // 푁
  "\\intprodr": "\u2a3d", // ⨽
  "\\twoheadmapsto": "\u2905", // ⤅
  "\\circledd": "\u24d3", // ⓓ
  "\\bfH": "\ud407", // 퐇
  "\\succnsim": "\u22e9", // ⋩
  "\\questeq": "\u225f", // ≟
  "\\itsansy": "\ud63a", // 혺
  "\\subsim": "\u2ac7", // ⫇
  "\\circledL": "\u24c1", // Ⓛ
  "\\textdoublebarpipe": "\u01c2", // ǂ
  "\\leftarrowsubset": "\u297a", // ⥺
  "\\bfitpartial": "\ud74f", // 흏
  "\\sphericalangleup": "\u29a1", // ⦡
  "\\texththeng": "\u0267", // ɧ
  "\\blacktriangle": "\u25b4", // ▴
  "\\lllnest": "\u2af7", // ⫷
  "\\bffrakt": "\ud599", // 햙
  "\\underbar": "\u0331", // ̱
  "\\textrtailn": "\u0273", // ɳ
  "\\shortparallel": "\u2225", // ∥
  "\\bfitpi": "\ud745", // 흅
  "\\bigtriangleup": "\u25b3", // △
  "\\cdprime": "\u02ba", // ʺ
  "\\bfitpsi": "\ud74d", // 흍
  "\\bfseven": "\ud7d5", // ퟕ
  "\\varbarwedge": "\u2305", // ⌅
  "\\rbracklrtick": "\u298e", // ⦎
  "\\pluseqq": "\u2a72", // ⩲
  "\\ast": "\u2217", // ∗
  "\\textrhook": "\u0322", // ̢
  "\\eqqslantless": "\u2a9b", // ⪛
  "\\vysmwhtcircle": "\u2218", // ∘
  "\\bfitJ": "\ud471", // 푱
  "\\urarc": "\u25dd", // ◝
  "\\acwleftarcarrow": "\u2939", // ⤹
  "\\textslash": "\u002f", // /
  "\\measangleldtosw": "\u29ab", // ⦫
  "\\textcloseomega": "\u0277", // ɷ
  "\\bfupsilon": "\ud6d6", // 훖
  "\\typecolon": "\u2982", // ⦂
  "\\bfscrO": "\ud4de", // 퓞
  "\\hexagonblack": "\u2b23", // ⬣
  "\\turnangle": "\u29a2", // ⦢
  "\\downdownarrows": "\u21ca", // ⇊
  "\\rbracemid": "\u23ac", // ⎬
  "\\succneq": "\u2ab2", // ⪲
  "\\circledy": "\u24e8", // ⓨ
  "\\upIota": "\u0399", // Ι
  "\\bfscrh": "\ud4f1", // 퓱
  "\\cirmid": "\u2aef", // ⫯
  "\\upin": "\u27d2", // ⟒
  "\\textsubwvar": "\u0077", // w
  "\\surd": "\u221a", // √
  "\\textquotedblleft": "\u201c", // “
  "\\gtrless": "\u2277", // ≷
  "\\toea": "\u2928", // ⤨
  "\\itsansu": "\ud636", // 혶
  "\\textdzlig": "\u02a3", // ʣ
  "\\bfsanstau": "\ud783", // 힃
  "\\updownarrow": "\u2195", // ↕
  "\\circletophalfblack": "\u25d3", // ◓
  "\\frakY": "\ud51c", // 픜
  "\\ipasupgamma": "\u02e0", // ˠ
  "\\itSigma": "\ud6f4", // 훴
  "\\ddotseq": "\u2a77", // ⩷
  "\\measangledrtose": "\u29ae", // ⦮
  "\\rceil": "\u2309", // ⌉
  "\\bfsansNu": "\ud762", // 흢
  "\\smile": "\u2323", // ⌣
  "\\triangleplus": "\u2a39", // ⨹
  "\\scrF": "\u2131", // ℱ, 풡
  "\\i": "\u0131", // ı
  "\\ttE": "\ud674", // 홴
  "\\downupharpoonsleftright": "\u296f", // ⥯
  "\\ttW": "\ud686", // 횆
  "\\bfsansRho": "\ud766", // 흦
  "\\Finv": "\u2132", // Ⅎ
  "\\twonotes": "\u266b", // ♫
  "\\bdhrule": "\u2500", // ─
  "\\pm": "\u00b1", // ±
  "\\circledl": "\u24db", // ⓛ
  "\\parallel": "\u2225", // ∥
  "\\smallblacktriangleright": "\u25b8", // ▸
  "\\textscr": "\u0280", // ʀ
  "\\bfitmu": "\ud741", // 흁
  "\\rcurvyangle": "\u29fd", // ⧽
  "\\supseven": "\u2077", // ⁷
  "\\tttwo": "\ud7f8", // ퟸ
  "\\circledsansseven": "\u2786", // ➆
  "\\Rdsh": "\u21b3", // ↳
  "\\succ": "\u227b", // ≻
  "\\bfalpha": "\ud6c2", // 훂
  "\\itv": "\ud463", // 푣
  "\\bffrakR": "\ud57d", // 핽
  "\\barrightharpoonup": "\u295b", // ⥛
  "\\triangletimes": "\u2a3b", // ⨻
  "\\BbbO": "\ud546", // 핆
  "\\iteta": "\ud702", // 휂
  "\\bfBeta": "\ud6a9", // 횩
  "\\rq": "\u2019", // ’
  "\\vardiamondsuit": "\u2666", // ♦
  "\\tona": "\u2927", // ⤧
  "\\bfsansV": "\ud5e9", // 헩
  "\\prod": "\u220f", // ∏
  "\\circledW": "\u24cc", // Ⓦ
  "\\baruparrow": "\u2912", // ⤒
  "\\bfsansv": "\ud603", // 혃
  "\\veeeq": "\u225a", // ≚
  "\\bfitPi": "\ud72b", // 휫
  "\\circledg": "\u24d6", // ⓖ
  "\\supsetapprox": "\u2aca", // ⫊
  "\\bfitd": "\ud485", // 풅
  "\\bfitL": "\ud473", // 푳
  "\\mdblkcircle": "\u26ab", // ⚫
  "\\Bbbc": "\ud554", // 핔
  "\\textroundcap": "\u0311", // ̑
  "\\ddagger": "\u2021", // ‡
  "\\bfEta": "\ud6ae", // 횮
  "\\mlcp": "\u2adb", // ⫛
  "\\ttx": "\ud6a1", // 횡
  "\\bfsansvarrho": "\ud78e", // 힎
  "\\bfB": "\ud401", // 퐁
  "\\diceiii": "\u2682", // ⚂
  "\\coprod": "\u2210", // ∐
  "\\bfitsansp": "\ud665", // 홥
  "\\complement": "\u2201", // ∁
  "\\bfitsansW": "\ud652", // 홒
  "\\multimapinv": "\u27dc", // ⟜
  "\\squarelrquad": "\u25f2", // ◲
  "\\ttQ": "\ud680", // 횀
  "\\beth": "\u2136", // ℶ
  "\\textscoelig": "\u0276", // ɶ
  "\\revnmid": "\u2aee", // ⫮
  "\\ttw": "\ud6a0", // 횠
  "\\bffrakM": "\ud578", // 핸
  "\\minusdot": "\u2a2a", // ⨪
  "\\texttctctlig": "\u02a8", // ʨ
  "\\bfitLambda": "\ud726", // 휦
  "\\bfitsansnabla": "\ud7a9", // 힩
  "\\ltrivb": "\u29cf", // ⧏
  "\\textrptr": "\u02c3", // ˃
  "\\rangle": "\u27e9", // ⟩
  "\\circledr": "\u24e1", // ⓡ
  "\\nsime": "\u2244", // ≄
  "\\diamondsuit": "\u2662", // ♢
  "\\Coloneq": "\u2a74", // ⩴
  "\\cirfnint": "\u2a10", // ⨐
  "\\sqint": "\u2a16", // ⨖
  "\\xbsol": "\u29f9", // ⧹
  "\\bfQ": "\ud410", // 퐐
  "\\pullback": "\u27d3", // ⟓
  "\\bfitsanspi": "\ud7b9", // ힹ
  "\\fcmp": "\u2a3e", // ⨾
  "\\subsup": "\u2ad3", // ⫓
  "\\itsansp": "\ud631", // 혱
  "\\dotsminusdots": "\u223a", // ∺
  "\\BbbN": "\u2115", // ℕ, 핅
  "\\cirE": "\u29c3", // ⧃
  "\\bfscrk": "\ud4f4", // 퓴
  "\\XBox": "\u2612", // ☒
  "\\leqqslant": "\u2af9", // ⫹
  "\\bfR": "\ud411", // 퐑
  "\\bfkappa": "\ud6cb", // 훋
  "\\accurrent": "\u23e6", // ⏦
  "\\leftarrowsimilar": "\u2973", // ⥳
  "\\bfitsansN": "\ud649", // 홉
  "\\emleader": "\u2026", // …
  "\\circledJ": "\u24bf", // Ⓙ
  "\\bffrakS": "\ud57e", // 핾
  "\\aleph": "\u2135", // ℵ
  "\\bffrakm": "\ud592", // 햒
  "\\itZeta": "\ud6e7", // 훧
  "\\upTheta": "\u0398", // Θ
  "\\ntrianglerighteq": "\u22ed", // ⋭
  "\\upgamma": "\u03b3", // γ
  "\\enclosecircle": "\u20dd", // ⃝
  "\\Bbbthree": "\ud7db", // ퟛ
  "\\fraky": "\ud536", // 픶
  "\\ttO": "\ud67e", // 홾
  "\\bfsansl": "\ud5f9", // 헹
  "\\scrt": "\ud4c9", // 퓉
  "\\lbrace": "\u007b", // {
  "\\vrectangleblack": "\u25ae", // ▮
  "\\bfitF": "\ud46d", // 푭
  "\\bfvarrho": "\ud6e0", // 훠
  "\\dsub": "\u2a64", // ⩤
  "\\supedot": "\u2ac4", // ⫄
  "\\bfite": "\ud486", // 풆
  "\\varbackcong": "\u224c", // ≌
  "\\textinvglotstop": "\u0296", // ʖ
  "\\Cup": "\u22d3", // ⋓
  "\\textsubplus": "\u031f", // ̟
  "\\textsubdoubleline": "\u0333", // ̳
  "\\diceiv": "\u2683", // ⚃
  "\\emdash": "\u2014", // —
  "\\texttieunderarc": "\u035c", // ͜
  "\\scro": "\ud4c4", // 퓄, ℴ
  "\\bfsansvartheta": "\ud78b", // 힋
  "\\curlyvee": "\u22ce", // ⋎
  "\\smblksquare": "\u25aa", // ▪
  "\\nshortmid": "\u2224", // ∤
  "\\swarrow": "\u2199", // ↙
  "\\frakB": "\ud505", // 픅
  "\\circleonrightarrow": "\u21f4", // ⇴
  "\\ngtrsim": "\u2275", // ≵
  "\\rftimes": "\u29d5", // ⧕
  "\\tty": "\ud6a2", // 횢
  "\\lozenge": "\u25ca", // ◊
  "\\itsansm": "\ud62e", // 혮
  "\\bfitNu": "\ud728", // 휨
  "\\upbackepsilon": "\u03f6", // ϶
  "\\closedvarcap": "\u2a4d", // ⩍
  "\\vdash": "\u22a2", // ⊢
  "\\bddVrH": "\u2554", // ╔
  "\\sublparen": "\u208d", // ₍
  "\\bdbvlh": "\u2524", // ┤
  "\\bfz": "\ud433", // 퐳
  "\\ogreaterthan": "\u29c1", // ⧁
  "\\bfitsansgamma": "\ud7ac", // 힬
  "\\sansfour": "\ud7e6", // ퟦ
  "\\veemidvert": "\u2a5b", // ⩛
  "\\sqsubset": "\u228f", // ⊏
  "\\ttb": "\ud68b", // 횋
  "\\eighthnote": "\u266a", // ♪
  "\\trprime": "\u2034", // ‴
  "\\circledtwodots": "\u2687", // ⚇
  "\\lparen": "\u0028", // (
  "\\itu": "\ud462", // 푢
  "\\BbbD": "\ud53b", // 픻
  "\\sansw": "\ud5d0", // 헐
  "\\blackcircledrightdot": "\u2688", // ⚈
  "\\eqqslantgtr": "\u2a9c", // ⪜
  "\\clangle": "\u2329", // 〈
  "\\bfone": "\ud7cf", // ퟏ
  "\\bbrktbrk": "\u23b6", // ⎶
  "\\scrn": "\ud4c3", // 퓃
  "\\varisinobar": "\u22f6", // ⋶
  "\\vDash": "\u22a8", // ⊨
  "\\tta": "\ud68a", // 횊
  "\\bfr": "\ud42b", // 퐫
  "\\orough": "\u0314", // ̔
  "\\nhpar": "\u2af2", // ⫲
  "\\sansnine": "\ud7eb", // ퟫ
  "\\Bbbtwo": "\ud7da", // ퟚ
  "\\BbbE": "\ud53c", // 피
  "\\circledm": "\u24dc", // ⓜ
  "\\bfitvartheta": "\ud751", // 흑
  "\\upharpoonsleftright": "\u2963", // ⥣
  "\\shuffle": "\u29e2", // ⧢
  "\\suplarr": "\u297b", // ⥻
  "\\subzero": "\u2080", // ₀
  "\\textdoubleacute": "\u02dd", // ˝
  "\\bffrakv": "\ud59b", // 햛
  "\\rightarrowbar": "\u21e5", // ⇥
  "\\UUparrow": "\u27f0", // ⟰
  "\\equivVert": "\u2a68", // ⩨
  "\\itlambda": "\ud706", // 휆
  "\\Bbbfour": "\ud7dc", // ퟜ
  "\\Mapsto": "\u2907", // ⤇
  "\\circleddash": "\u229d", // ⊝
  "\\wideangledown": "\u29a6", // ⦦
  "\\preceqq": "\u2ab3", // ⪳
  "\\asymp": "\u224d", // ≍
  "\\vartriangleleft": "\u22b2", // ⊲
  "\\ss": "\u00df", // ß
  "\\bfsigma": "\ud6d4", // 훔
  "\\scrz": "\ud4cf", // 퓏
  "\\itsansz": "\ud63b", // 혻
  "\\simless": "\u2a9d", // ⪝
  "\\itg": "\ud454", // 푔
  "\\rightfishtail": "\u297d", // ⥽
  "\\itsansq": "\ud632", // 혲
  "\\isins": "\u22f4", // ⋴
  "\\itsansn": "\ud62f", // 혯
  "\\supset": "\u2283", // ⊃
  "\\dicei": "\u2680", // ⚀
  "\\not": "\u0338", // ̸
  "\\bfitr": "\ud493", // 풓
  "\\bffrakk": "\ud590", // 햐
  "\\leftrightharpoonupdown": "\u294a", // ⥊
  "\\nsupseteq": "\u2289", // ⊉
  "\\Angstrom": "\u212b", // Å
  "\\supeight": "\u2078", // ⁸
  "\\bfscri": "\ud4f2", // 퓲
  "\\bfitn": "\ud48f", // 풏
  "\\rightwhitearrow": "\u21e8", // ⇨
  "\\varspadesuit": "\u2664", // ♤
  "\\bfthree": "\ud7d1", // ퟑ
  "\\lltriangle": "\u25fa", // ◺
  "\\Otimes": "\u2a37", // ⨷
  "\\smwhtcircle": "\u25e6", // ◦
  "\\bffrakQ": "\ud57c", // 핼
  "\\bfzeta": "\ud6c7", // 훇
  "\\bfscrS": "\ud4e2", // 퓢
  "\\textgamma": "\u0263", // ɣ
  "\\bfscrz": "\ud503", // 픃
  "\\bfitepsilon": "\ud750", // 흐
  "\\dashrightharpoondown": "\u296d", // ⥭
  "\\itvarsigma": "\ud70d", // 휍
  "\\bduVrh": "\u2559", // ╙
  "\\textcrtwo": "\u01bb", // ƻ
  "\\updownharpoonrightleft": "\u294c", // ⥌
  "\\fraconefifth": "\u2155", // ⅕
  "\\circlellquad": "\u25f5", // ◵
  "\\frakn": "\ud52b", // 픫
  "\\leftfishtail": "\u297c", // ⥼
  "\\tplus": "\u29fe", // ⧾
  "\\arceq": "\u2258", // ≘
  "\\itsansg": "\ud628", // 혨
  "\\textinvscr": "\u0281", // ʁ
  "\\bfitsansJ": "\ud645", // 홅
  "\\lesdot": "\u2a7f", // ⩿
  "\\bfsansH": "\ud5db", // 헛
  "\\updasharrow": "\u21e1", // ⇡
  "\\scrB": "\ud49d", // 풝, ℬ
  "\\dj": "\u0111", // đ
  "\\colon": "\u2236", // ∶
  "\\mdlgwhtlozenge": "\u25ca", // ◊
  "\\ipasups": "\u02e2", // ˢ
  "\\circledN": "\u24c3", // Ⓝ
  "\\frakw": "\ud534", // 픴
  "\\bfitV": "\ud47d", // 푽
  "\\fraki": "\ud526", // 픦
  "\\glj": "\u2aa4", // ⪤
  "\\rightleftarrows": "\u21c4", // ⇄
  "\\bfitsansepsilon": "\ud7c4", // ퟄ
  "\\frakG": "\ud50a", // 픊
  "\\textturnk": "\u029e", // ʞ
  "\\rightdasharrowhead": "\u21e2", // ⇢
  "\\bigwhitestar": "\u2606", // ☆
  "\\errbarcircle": "\u29f2", // ⧲
  "\\textturnw": "\u028d", // ʍ
  "\\thirdemspace": "\u2004", // no symbol
  "\\bfitsansIota": "\ud798", // 힘
  "\\Barv": "\u2ae7", // ⫧
  "\\nwovnearrow": "\u2932", // ⤲
  "\\textbullet": "\u2022", // •
  "\\itBeta": "\ud6e3", // 훣
  "\\bfitX": "\ud47f", // 푿
  "\\bfitsansrho": "\ud7ba", // ힺ
  "\\precneqq": "\u2ab5", // ⪵
  "\\downarrow": "\u2193", // ↓
  "\\circledY": "\u24ce", // Ⓨ
  "\\backprime": "\u2035", // ‵
  "\\texthamza": "\u02be", // ʾ
  "\\circledsanstwo": "\u2781", // ➁
  "\\diamondleftarrow": "\u291d", // ⤝
  "\\BbbV": "\ud54d", // 핍
  "\\textlyoghlig": "\u026e", // ɮ
  "\\ttC": "\ud672", // 홲
  "\\bfepsilon": "\ud6dc", // 훜
  "\\acwunderarcarrow": "\u293b", // ⤻
  "\\lnapprox": "\u2a89", // ⪉
  "\\textasciiacute": "\u00b4", // ´
  "\\bfittheta": "\ud73d", // 휽
  "\\rbraceuend": "\u23ab", // ⎫
  "\\sqsupsetneq": "\u22e5", // ⋥
  "\\curvearrowleft": "\u21b6", // ↶
  "\\vartriangleright": "\u22b3", // ⊳
  "\\bfF": "\ud405", // 퐅
  "\\bffrakT": "\ud57f", // 핿
  "\\itd": "\ud451", // 푑
  "\\scrX": "\ud4b3", // 풳
  "\\longmapsfrom": "\u27fb", // ⟻
  "\\ttr": "\ud69b", // 횛
  "\\bfscrL": "\ud4db", // 퓛
  "\\measangleultonw": "\u29ad", // ⦭
  "\\bfitvarphi": "\ud753", // 흓
  "\\bffrakO": "\ud57a", // 핺
  "\\bfitsansPhi": "\ud7a5", // 힥
  "\\intBar": "\u2a0e", // ⨎
  "\\ttM": "\ud67c", // 홼
  "\\bullseye": "\u25ce", // ◎
  "\\bfitsansH": "\ud643", // 홃
  "\\boxminus": "\u229f", // ⊟
  "\\sanso": "\ud5c8", // 허
  "\\varnis": "\u22fb", // ⋻
  "\\bfS": "\ud412", // 퐒
  "\\ntriangleright": "\u22eb", // ⋫
  "\\downharpoonright": "\u21c2", // ⇂
  "\\daleth": "\u2138", // ℸ
  "\\lowint": "\u2a1c", // ⨜
  "\\frakb": "\ud51f", // 픟
  "\\textrhoticity": "\u02de", // ˞
  "\\bfsansdelta": "\ud773", // 흳
  "\\circledb": "\u24d1", // ⓑ
  "\\bfsansDelta": "\ud759", // 흙
  "\\twoheadrightarrowtail": "\u2916", // ⤖
  "\\textsubcomma": "\u0326", // ̦
  "\\nparallel": "\u2226", // ∦
  "\\ll": "\u226a", // ≪
  "\\textsupy": "\u02b8", // ʸ
  "\\sansR": "\ud5b1", // 햱
  "\\sansj": "\ud5c3", // 헃
  "\\bfDelta": "\ud6ab", // 횫
  "\\itvarpi": "\ud71b", // 휛
  "\\dotequiv": "\u2a67", // ⩧
  "\\bdnwse": "\u2572", // ╲
  "\\bfitsansOmicron": "\ud79e", // 힞
  "\\itX": "\ud44b", // 푋
  "\\circledstar": "\u272a", // ✪
  "\\rightimply": "\u2970", // ⥰
  "\\bfitsansvarphi": "\ud7c7", // ퟇
  "\\textdollar": "\u0024", // $
  "\\errbarblackdiamond": "\u29f1", // ⧱
  "\\textrecipe": "\u211e", // ℞
  "\\threedangle": "\u27c0", // ⟀
  "\\bfsansA": "\ud5d4", // 헔
  "\\doubleplus": "\u29fa", // ⧺
  "\\nabla": "\u2207", // ∇
  "\\blackcircledsansnine": "\u2792", // ➒
  "\\bfKappa": "\ud6b1", // 횱
  "\\itM": "\ud440", // 푀
  "\\downwhitearrow": "\u21e9", // ⇩
  "\\bfbeta": "\ud6c3", // 훃
  "\\bfsansY": "\ud5ec", // 헬
  "\\wedgeq": "\u2259", // ≙
  "\\boxplus": "\u229e", // ⊞
  "\\nvtwoheadrightarrow": "\u2900", // ⤀
  "\\bfscrZ": "\ud4e9", // 퓩
  "\\bfscrX": "\ud4e7", // 퓧
  "\\textsubumlaut": "\u0324", // ̤
  "\\textoverlaysolidusiv": "\u0338", // ̸
  "\\textraised": "\u02d4", // ˔
  "\\fisheye": "\u25c9", // ◉
  "\\scrw": "\ud4cc", // 퓌
  "\\bfsanszero": "\ud7ec", // ퟬ
  "\\bfitsansvartheta": "\ud7c5", // ퟅ
  "\\frakF": "\ud509", // 픉
  "\\plushat": "\u2a23", // ⨣
  "\\wordjoin": "\u2060", // ⁠
  "\\Bbbv": "\ud567", // 핧
  "\\jmath": "\ud6a5", // 횥
  "\\downuparrows": "\u21f5", // ⇵
  "\\nearrow": "\u2197", // ↗
  "\\barrightarrowdiamond": "\u2920", // ⤠
  "\\varin": "\u2208", // ∈
  "\\tieconcat": "\u2040", // ⁀
  "\\inttop": "\u2320", // ⌠
  "\\bfT": "\ud413", // 퐓
  "\\bfitGamma": "\ud71e", // 휞
  "\\itbeta": "\ud6fd", // 훽
  "\\bfitp": "\ud491", // 풑
  "\\neptune": "\u2646", // ♆
  "\\ac": "\u223e", // ∾
  "\\textdptr": "\u02c5", // ˅
  "\\textsigma": "\u03c3", // σ
  "\\textsubacute": "\u0317", // ̗
  "\\bfitC": "\ud46a", // 푪
  "\\urcorner": "\u231d", // ⌝
  "\\talloblong": "\u2afe", // ⫾
  "\\measangledltosw": "\u29af", // ⦯
  "\\bfsanss": "\ud600", // 혀
  "\\barcup": "\u2a42", // ⩂
  "\\gneq": "\u2a88", // ⪈
  "\\rfbowtie": "\u29d2", // ⧒
  "\\hksearow": "\u2925", // ⤥
  "\\bfsansk": "\ud5f8", // 헸
  "\\bar": "\u0304", // ̄
  "\\bfitsansAlpha": "\ud790", // 힐
  "\\circledsanssix": "\u2785", // ➅
  "\\lbag": "\u27c5", // ⟅
  "\\BbbF": "\ud53d", // 픽
  "\\eqsim": "\u2242", // ≂
  "\\circledsansone": "\u2780", // ➀
  "\\blackpointerright": "\u25ba", // ►
  "\\sansg": "\ud5c0", // 헀
  "\\itsansO": "\ud616", // 혖
  "\\lessgtr": "\u2276", // ≶
  "\\minus": "\u2212", // −
  "\\upKappa": "\u039a", // Κ
  "\\precapprox": "\u2ab7", // ⪷
  "\\bfith": "\ud489", // 풉
  "\\bfitU": "\ud47c", // 푼
  "\\Bbbfive": "\ud7dd", // ퟝ
  "\\textalpha": "\u03b1", // α
  "\\upLambda": "\u039b", // Λ
  "\\nVdash": "\u22ae", // ⊮
  "\\its": "\ud460", // 푠
  "\\BbbQ": "\u211a", // ℚ, 핈
  "\\mdwhtsquare": "\u25fb", // ◻
  "\\lsime": "\u2a8d", // ⪍
  "\\upoldKoppa": "\u03d8", // Ϙ
  "\\bfsansgamma": "\ud772", // 흲
  "\\bfsanse": "\ud5f2", // 헲
  "\\upvarrho": "\u03f1", // ϱ
  "\\Lbrbrak": "\u27ec", // ⟬, 〘
  "\\nsubset": "\u2284", // ⊄
  "\\simgtr": "\u2a9e", // ⪞
  "\\cwrightarcarrow": "\u2938", // ⤸
  "\\fourthroot": "\u221c", // ∜
  "\\osmooth": "\u0313", // ̓
  "\\leftarrowshortrightarrow": "\u2943", // ⥃
  "\\parsim": "\u2af3", // ⫳
  "\\bfvarsigma": "\ud6d3", // 훓
  "\\Exclam": "\u203c", // ‼
  "\\bfsansthree": "\ud7ef", // ퟯ
  "\\bfpi": "\ud6d1", // 훑
  "\\intextender": "\u23ae", // ⎮
  "\\doublebarvee": "\u2a62", // ⩢
  "\\upPhi": "\u03a6", // Φ
  "\\bfA": "\ud400", // 퐀
  "\\sansX": "\ud5b7", // 햷
  "\\texttwosuperior": "\u00b2", // ²
  "\\angles": "\u299e", // ⦞
  "\\bfsansphi": "\ud785", // 힅
  "\\updigamma": "\u03dd", // ϝ
  "\\barleftarrowrightarrowbar": "\u21b9", // ↹
  "\\Hermaphrodite": "\u26a5", // ⚥
  "\\urblacktriangle": "\u25e5", // ◥
  "\\bfsansvarsigma": "\ud781", // 힁
  "\\bfsansAlpha": "\ud756", // 흖
  "\\bfE": "\ud404", // 퐄
  "\\textrtails": "\u0282", // ʂ
  "\\itQ": "\ud444", // 푄
  "\\veeonvee": "\u2a56", // ⩖
  "\\difference": "\u223c", // ∼
  "\\APLnotbackslash": "\u2340", // ⍀
  "\\sterling": "\u00a3", // £
  "\\blackcircledsansfour": "\u278d", // ➍
  "\\zwspace": "\u200b", // no symbol
  "\\lvboxline": "\u23b8", // ⎸
  "\\bfitsanszeta": "\ud7af", // 힯
  "\\bfitsanseta": "\ud7b0", // ힰ
  "\\npreccurlyeq": "\u22e0", // ⋠
  "\\textvtilde": "\u033e", // ̾
  "\\itsanso": "\ud630", // 혰
  "\\varVdash": "\u2ae6", // ⫦
  "\\bddVbH": "\u2566", // ╦
  "\\invisibletimes": "\u2062", // ⁢
  "\\intcup": "\u2a1a", // ⨚
  "\\sansthree": "\ud7e5", // ퟥ
  "\\scrc": "\ud4b8", // 풸
  "\\bigsqcup": "\u2a06", // ⨆
  "\\bfitsansu": "\ud66a", // 홪
  "\\itC": "\ud436", // 퐶
  "\\itMu": "\ud6ed", // 훭
  "\\itsansU": "\ud61c", // 혜
  "\\dotplus": "\u2214", // ∔
  "\\textlhookx": "\u1d8d", // ᶍ
  "\\Vert": "\u2016", // ‖
  "\\sansc": "\ud5bc", // 햼
  "\\textglotstop": "\u0294", // ʔ
  "\\fullouterjoin": "\u27d7", // ⟗
  "\\uparrow": "\u2191", // ↑
  "\\cwundercurvearrow": "\u293e", // ⤾
  "\\diamondcdot": "\u27d0", // ⟐
  "\\textcrb": "\u0180", // ƀ
  "\\smte": "\u2aac", // ⪬
  "\\triangleleft": "\u25c1", // ◁
  "\\circledo": "\u24de", // ⓞ
  "\\itD": "\ud437", // 퐷
  "\\circledequal": "\u229c", // ⊜
  "\\cupovercap": "\u2a46", // ⩆
  "\\bfitomega": "\ud74e", // 흎
  "\\underparen": "\ufe36", // ︶, ⏝
  "\\cdot": "\u22c5", // ⋅
  "\\iint": "\u222c", // ∬
  "\\intbar": "\u2a0d", // ⨍
  "\\textsca": "\u1d00", // ᴀ
  "\\Rrightarrow": "\u21db", // ⇛
  "\\bfitZeta": "\ud721", // 휡
  "\\bdHrule": "\u2550", // ═
  "\\enleadertwodots": "\u2025", // ‥
  "\\btimes": "\u2a32", // ⨲
  "\\ovhook": "\u0309", // ̉
  "\\textcloserevepsilon": "\u025e", // ɞ
  "\\Supset": "\u22d1", // ⋑
  "\\leftrightarrows": "\u21c6", // ⇆
  "\\updownharpoonleftright": "\u294d", // ⥍
  "\\bfitsanstheta": "\ud7b1", // ힱ
  "\\leftouterjoin": "\u27d5", // ⟕
  "\\rbrackubar": "\u298c", // ⦌
  "\\texthtp": "\u01a5", // ƥ
  "\\upxi": "\u03be", // ξ
  "\\itsansd": "\ud625", // 혥
  "\\supsub": "\u2ad4", // ⫔
  "\\backslash": "\u005c", // \
  "\\eqdef": "\u225d", // ≝
  "\\bfitsansvarepsilon": "\ud7ae", // 힮
  "\\scrZ": "\ud4b5", // 풵
  "\\textrise": "\u02c7", // ˇ
  "\\subeight": "\u2088", // ₈
  "\\sansB": "\ud5a1", // 햡
  "\\lblkbrbrak": "\u2997", // ⦗
  "\\boxbox": "\u29c8", // ⧈
  "\\niobar": "\u22fe", // ⋾
  "\\upEpsilon": "\u0395", // Ε
  "\\intprod": "\u2a3c", // ⨼
  "\\BbbC": "\u2102", // ℂ, 픺
  "\\bffrakW": "\ud582", // 햂
  "\\textlira": "\u20a4", // ₤
  "\\frakh": "\ud525", // 픥
  "\\bfitD": "\ud46b", // 푫
  "\\itsansA": "\ud608", // 혈
  "\\overbrace": "\u23de", // ⏞, ︷
  "\\Uparrowhead": "\u21d1", // ⇑
  "\\texttrprime": "\u2034", // ‴
  "\\sqsupset": "\u2290", // ⊐
  "\\bfitDelta": "\ud71f", // 휟
  "\\Doteq": "\u2251", // ≑
  "\\bfD": "\ud403", // 퐃
  "\\varclubsuit": "\u2667", // ♧
  "\\textoverbridge": "\u0346", // ͆
  "\\breve": "\u0306", // ̆
  "\\textturncomma": "\u02bb", // ʻ
  "\\#": "\u0023", // #
  "\\aa": "\u00e5", // å
  "\\supmult": "\u2ac2", // ⫂
  "\\bfitsansI": "\ud644", // 홄
  "\\upphi": "\u03d5", // ϕ
  "\\itgamma": "\ud6fe", // 훾
  "\\nHuparrow": "\u21de", // ⇞
  "\\curvearrowleftplus": "\u293d", // ⤽
  "\\circledrightdot": "\u2686", // ⚆
  "\\ttF": "\ud675", // 홵
  "\\circledx": "\u24e7", // ⓧ
  "\\blackcircledsansone": "\u278a", // ➊
  "\\ttN": "\ud67d", // 홽
  "\\BbbGamma": "\u213e", // ℾ
  "\\bfsansxi": "\ud77d", // 흽
  "\\textiota": "\u0269", // ɩ
  "\\fraka": "\ud51e", // 픞
  "\\quotsinglright": "\u201b", // ‛
  "\\bfh": "\ud421", // 퐡
  "\\rparenlend": "\u23a0", // ⎠
  "\\bfitsansbeta": "\ud7ab", // 힫
  "\\precsim": "\u227e", // ≾
  "\\modtwosum": "\u2a0a", // ⨊
  "\\bfitsansTheta": "\ud797", // 힗
  "\\gtreqless": "\u22db", // ⋛
  "\\upTau": "\u03a4", // Τ
  "\\lesseqgtr": "\u22da", // ⋚
  "\\textvbaraccent": "\u030d", // ̍
  "\\bfitsansvarpi": "\ud7c9", // ퟉
  "\\textrtailr": "\u027d", // ɽ
  "\\BbbH": "\u210d", // ℍ, 픿
  "\\textoverlaysolidusiii": "\u0338", // ̸
  "\\awint": "\u2a11", // ⨑
  "\\textsupr": "\u02b3", // ʳ
  "\\textoverlayleftarrow": "\u20ea", // ⃪
  "\\Eulerconst": "\u2107", // ℇ
  "\\preccurlyeq": "\u227c", // ≼
  "\\ttT": "\ud683", // 횃
  "\\rightanglesqr": "\u299c", // ⦜
  "\\frakq": "\ud52e", // 픮
  "\\squarehvfill": "\u25a6", // ▦
  "\\bfitQ": "\ud478", // 푸
  "\\textpalhookl": "\u1d85", // ᶅ
  "\\squarebotblack": "\u2b13", // ⬓
  "\\diameter": "\u2300", // ⌀
  "\\bfitsansm": "\ud662", // 홢
  "\\laplac": "\u29e0", // ⧠
  "\\bfsansw": "\ud604", // 현
  "\\hermitmatrix": "\u22b9", // ⊹
  "\\bffrakF": "\ud571", // 핱
  "\\shortdownarrow": "\u2193", // ↓
  "\\olcross": "\u29bb", // ⦻
  "\\prime": "\u2032", // ′
  "\\smeparsl": "\u29e4", // ⧤
  "\\squaregrayfill": "\u25a9", // ▩
  "\\bduvrh": "\u2514", // └
  "\\blackcircledsanstwo": "\u278b", // ➋
  "\\bfitsanss": "\ud668", // 홨
  "\\sharp": "\u266f", // ♯
  "\\frakQ": "\ud514", // 픔
  "\\circledM": "\u24c2", // Ⓜ
  "\\vrectangle": "\u25af", // ▯
  "\\circledK": "\u24c0", // Ⓚ
  "\\bffrakq": "\ud596", // 햖
  "\\itl": "\ud459", // 푙
  "\\Bbbnine": "\ud7e1", // ퟡ
  "\\house": "\u2302", // ⌂
  "\\backsimeq": "\u22cd", // ⋍
  "\\bfsanssix": "\ud7f2", // ퟲ
  "\\barleftharpoonup": "\u2952", // ⥒
  "\\rangledownzigzagarrow": "\u237c", // ⍼
  "\\textctc": "\u0255", // ɕ
  "\\bfx": "\ud431", // 퐱
  "\\Ddownarrow": "\u290b", // ⤋
  "\\H": "\u030b", // ̋
  "\\risingdotseq": "\u2253", // ≓
  "\\NG": "\u014a", // Ŋ
  "\\sansone": "\ud7e3", // ퟣ
  "\\textestimated": "\u212e", // ℮
  "\\upharpoonleft": "\u21bf", // ↿
  "\\ite": "\ud452", // 푒
  "\\hlineextender": "\u23af", // ⎯
  "\\doteq": "\u2250", // ≐
  "\\llparenthesis": "\u2987", // ⦇
  "\\bfzero": "\ud7ce", // ퟎ
  "\\overline": "\u203e", // ‾
  "\\diceii": "\u2681", // ⚁
  "\\bfvarpi": "\ud6e1", // 훡
  "\\textdiscount": "\u2052", // ⁒
  "\\upMu": "\u039c", // Μ
  "\\texttietilde": "\u0360", // ͠
  "\\scrq": "\ud4c6", // 퓆
  "\\csube": "\u2ad1", // ⫑
  "\\bfsansr": "\ud5ff", // 헿
  "\\partialmeetcontraction": "\u2aa3", // ⪣
  "\\bfitsansomicron": "\ud7b8", // ힸ
  "\\bfTheta": "\ud6af", // 횯
  "\\oe": "\u0153", // œ
  "\\bfitsansf": "\ud65b", // 홛
  "\\scra": "\ud4b6", // 풶
  "\\sansx": "\ud5d1", // 헑
  "\\bfitsansChi": "\ud7a6", // 힦
  "\\bfitsansB": "\ud63d", // 혽
  "\\AA": "\u00c5", // Å
  "\\llarc": "\u25df", // ◟
  "\\sansN": "\ud5ad", // 햭
  "\\circledi": "\u24d8", // ⓘ
  "\\BbbA": "\ud538", // 픸
  "\\bfitsansY": "\ud654", // 화
  "\\angdnr": "\u299f", // ⦟
  "\\itsansH": "\ud60f", // 혏
  "\\bfu": "\ud42e", // 퐮
  "\\circledast": "\u229b", // ⊛
  "\\ttV": "\ud685", // 횅
  "\\itPhi": "\ud6f7", // 훷
  "\\bfitvarepsilon": "\ud73a", // 휺
  "\\bfj": "\ud423", // 퐣
  "\\bfsansEpsilon": "\ud75a", // 흚
  "\\rangledot": "\u2992", // ⦒
  "\\Bbbd": "\ud555", // 핕
  "\\itq": "\ud45e", // 푞
  "\\leftrightharpoonsdown": "\u2967", // ⥧
  "\\Equiv": "\u2263", // ≣
  "\\bfitsansmu": "\ud7b5", // ힵ
  "\\cupbarcap": "\u2a48", // ⩈
  "\\linefeed": "\u21b4", // ↴
  "\\star": "\u22c6", // ⋆
  "\\textraising": "\u031d", // ̝
  "\\VDash": "\u22ab", // ⊫
  "\\sansr": "\ud5cb", // 헋
  "\\leftbkarrow": "\u290c", // ⤌
  "\\because": "\u2235", // ∵
  "\\gtrapprox": "\u2a86", // ⪆
  "\\itU": "\ud448", // 푈
  "\\odotslashdot": "\u29bc", // ⦼
  "\\leftharpoonupdash": "\u296a", // ⥪
  "\\gtquest": "\u2a7c", // ⩼
  "\\circledvert": "\u29b6", // ⦶
  "\\trslash": "\u2afb", // ⫻
  "\\underbracket": "\u23b5", // ⎵
  "\\emptysetoarr": "\u29b3", // ⦳
  "\\leftarrow": "\u2190", // ←
  "\\textsupj": "\u02b2", // ʲ
  "\\downtrianglerightblack": "\u29e9", // ⧩
  "\\bfeight": "\ud7d6", // ퟖ
  "\\bfitgamma": "\ud738", // 휸
  "\\leftdasharrow": "\u21e0", // ⇠
  "\\frakX": "\ud51b", // 픛
  "\\geqq": "\u2267", // ≧
  "\\rightcurvedarrow": "\u2933", // ⤳
  "\\itAlpha": "\ud6e2", // 훢
  "\\widecheck": "\u030c", // ̌
  "\\circledV": "\u24cb", // Ⓥ
  "\\bfitsansiota": "\ud7b2", // ힲ
  "\\itb": "\ud44f", // 푏
  "\\bfsansseven": "\ud7f3", // ퟳ
  "\\frakC": "\u212d", // ℭ, 픆
  "\\bfc": "\ud41c", // 퐜
  "\\textltailm": "\u0271", // ɱ
  "\\bfscrF": "\ud4d5", // 퓕
  "\\grave": "\u0300", // ̀
  "\\downzigzagarrow": "\u21af", // ↯
  "\\circledu": "\u24e4", // ⓤ
  "\\shortlefttack": "\u2ade", // ⫞
  "\\subsetcirc": "\u27c3", // ⟃
  "\\circledp": "\u24df", // ⓟ
  "\\invnot": "\u2310", // ⌐
  "\\lesdotor": "\u2a83", // ⪃
  "\\ttone": "\ud7f7", // ퟷ
  "\\bsolhsub": "\u27c8", // ⟈
  "\\squarecrossfill": "\u25a9", // ▩
  "\\emptysetoarrl": "\u29b4", // ⦴
  "\\upBeta": "\u0392", // Β
  "\\bffrakV": "\ud581", // 햁
  "\\upUpsilon": "\u03a5", // Υ, ϒ
  "\\texthyphen": "\u2010", // ‐
  "\\supsetdot": "\u2abe", // ⪾
  "\\gtlpar": "\u29a0", // ⦠
  "\\curvearrowrightminus": "\u293c", // ⤼
  "\\rightharpoondown": "\u21c1", // ⇁
  "\\itBbbD": "\u2145", // ⅅ
  "\\twoheaduparrowcircle": "\u2949", // ⥉
  "\\bfsansPhi": "\ud76b", // 흫
  "\\forksnot": "\u2add", // ⫝
  "\\scrM": "\ud4a8", // 풨, ℳ
  "\\ngeq": "\u2271", // ≱
  "\\textsupturnr": "\u02b4", // ʴ
  "\\operp": "\u29b9", // ⦹
  "\\fraconeeighth": "\u215b", // ⅛
  "\\eqeqeq": "\u2a76", // ⩶
  "\\textturnlonglegr": "\u027a", // ɺ
  "\\nexists": "\u2204", // ∄
  "\\measanglerdtose": "\u29aa", // ⦪
  "\\rightarrow": "\u2192", // →
  "\\nshortparallel": "\u2226", // ∦
  "\\itsansb": "\ud623", // 혣
  "\\bfitsansD": "\ud63f", // 혿
  "\\circledtwo": "\u2461", // ②
  "\\itLambda": "\ud6ec", // 훬
  "\\bigwedge": "\u22c0", // ⋀
  "\\Succ": "\u2abc", // ⪼
  "\\turnediota": "\u2129", // ℩
  "\\textlongy": "\u02ae", // ʮ
  "\\bfsansZ": "\ud5ed", // 헭
  "\\subone": "\u2081", // ₁
  "\\bfitsansF": "\ud641", // 홁
  "\\bfp": "\ud429", // 퐩
  "\\bfitsansDelta": "\ud793", // 힓
  "\\circledf": "\u24d5", // ⓕ
  "\\rbrackurtick": "\u2990", // ⦐
  "\\textinvsubbridge": "\u033a", // ̺
  "\\supn": "\u207f", // ⁿ
  "\\bfP": "\ud40f", // 퐏
  "\\bfsanseta": "\ud776", // 흶
  "\\lfbowtie": "\u29d1", // ⧑
  "\\isindot": "\u22f5", // ⋵
  "\\bfitSigma": "\ud72e", // 휮
  "\\bdVrule": "\u2551", // ║
  "\\lParen": "\u2985", // ⦅
  "\\bfsansQ": "\ud5e4", // 헤
  "\\ntrianglelefteq": "\u22ec", // ⋬
  "\\rightarrowx": "\u2947", // ⥇
  "\\leftarrowless": "\u2977", // ⥷
  "\\itL": "\ud43f", // 퐿
  "\\bfitT": "\ud47b", // 푻
  "\\bfsansPi": "\ud765", // 흥
  "\\textogonek": "\u02db", // ˛
  "\\textlhookt": "\u01ab", // ƫ
  "\\glE": "\u2a92", // ⪒
  "\\textseagull": "\u033c", // ̼
  "\\textraiseglotstop": "\u02c0", // ˀ
  "\\fraconethird": "\u2153", // ⅓
  "\\ttB": "\ud671", // 홱
  "\\bfitsansRho": "\ud7a0", // 힠
  "\\angleubar": "\u29a4", // ⦤
  "\\ttj": "\ud693", // 횓
  "\\notin": "\u2209", // ∉
  "\\rightarrowapprox": "\u2975", // ⥵
  "\\textteshlig": "\u02a7", // ʧ
  "\\bfgamma": "\ud6c4", // 후
  "\\bfitsansM": "\ud648", // 홈
  "\\sansa": "\ud5ba", // 햺
  "\\overleftrightarrow": "\u20e1", // ⃡
  "\\sansp": "\ud5c9", // 헉
  "\\th": "\u00fe", // þ
  "\\conictaper": "\u2332", // ⌲
  "\\upepsilon": "\u03f5", // ϵ
  "\\shortrightarrowleftarrow": "\u2944", // ⥄
  "\\scrN": "\ud4a9", // 풩
  "\\itR": "\ud445", // 푅
  "\\simrdots": "\u2a6b", // ⩫
  "\\sansJ": "\ud5a9", // 햩
  "\\bfscrJ": "\ud4d9", // 퓙
  "\\textturnmrleg": "\u0270", // ɰ
  "\\iinfin": "\u29dc", // ⧜
  "\\itsansY": "\ud620", // 혠
  "\\divideontimes": "\u22c7", // ⋇
  "\\mdsmwhtcircle": "\u26ac", // ⚬
  "\\nsupset": "\u2285", // ⊅
  "\\bfitsansBeta": "\ud791", // 힑
  "\\euro": "\u20ac", // €
  "\\ttsix": "\ud7fc", // ퟼
  "\\ubrbrak": "\u23e1", // ⏡
  "\\bfIota": "\ud6b0", // 횰
  "\\squareneswfill": "\u25a8", // ▨
  "\\Yup": "\u2144", // ⅄
  "\\oiint": "\u222f", // ∯
  "\\commaminus": "\u2a29", // ⨩
  "\\bfsansBeta": "\ud757", // 흗
  "\\Nearrow": "\u21d7", // ⇗
  "\\itRho": "\ud6f2", // 훲
  "\\blackinwhitesquare": "\u25a3", // ▣
  "\\concavediamondtickleft": "\u27e2", // ⟢
  "\\circleurquad": "\u25f7", // ◷
  "\\lbrbrak": "\u3014", // 〔, ❲
  "\\itBbbe": "\u2147", // ⅇ
  "\\Bbbeight": "\ud7e0", // ퟠ
  "\\textordfeminine": "\u00aa", // ª
  "\\odot": "\u2299", // ⊙
  "\\geqslant": "\u2a7e", // ⩾
  "\\bfitsansA": "\ud63c", // 혼
  "\\bfitsansnu": "\ud7b6", // ힶ
  "\\nVleftarrow": "\u21fa", // ⇺
  "\\quotsinglbase": "\u201a", // ‚
  "\\varveebar": "\u2a61", // ⩡
  "\\ocirc": "\u030a", // ̊
  "\\circledB": "\u24b7", // Ⓑ
  "\\subsetneq": "\u228a", // ⊊
  "\\twoheadrightarrow": "\u21a0", // ↠
  "\\sanssix": "\ud7e8", // ퟨ
  "\\circledsansfive": "\u2784", // ➄
  "\\varheartsuit": "\u2665", // ♥
  "\\vdots": "\u22ee", // ⋮
  "\\measuredangle": "\u2221", // ∡
  "\\lbracelend": "\u23a9", // ⎩
  "\\textupsilon": "\u028a", // ʊ
  "\\maltese": "\u2720", // ✠
  "\\leq": "\u2264", // ≤
  "\\bfitK": "\ud472", // 푲
  "\\textsuperimposetilde": "\u0334", // ̴
  "\\P": "\u00b6", // ¶
  "\\lesseqqgtr": "\u2a8b", // ⪋
  "\\ultriangle": "\u25f8", // ◸
  "\\itvarepsilon": "\ud700", // 휀
  "\\textexclamdown": "\u00a1", // ¡
  "\\bigtalloblong": "\u2aff", // ⫿
  "\\bfitj": "\ud48b", // 풋
  "\\fracslash": "\u2044", // ⁄
  "\\revangle": "\u29a3", // ⦣
  "\\ulcorner": "\u231c", // ⌜
  "\\textscripta": "\u0251", // ɑ
  "\\textlhookz": "\u1d8e", // ᶎ
  "\\textstretchcvar": "\u0297", // ʗ
  "\\fdiagovrdiag": "\u292c", // ⤬
  "\\varpropto": "\u221d", // ∝
  "\\subtwo": "\u2082", // ₂
  "\\nsqsubseteq": "\u22e2", // ⋢
  "\\bfpartial": "\ud6db", // 훛
  "\\topcir": "\u2af1", // ⫱
  "\\astrosun": "\u2609", // ☉
  "\\subset": "\u2282", // ⊂
  "\\bfitsansO": "\ud64a", // 홊
  "\\leftharpoondown": "\u21bd", // ↽
  "\\bfy": "\ud432", // 퐲
  "\\itUpsilon": "\ud6f6", // 훶
  "\\textbabygamma": "\u0264", // ɤ
  "\\nasymp": "\u226d", // ≭
  "\\otimeshat": "\u2a36", // ⨶
  "\\rsqhook": "\u2ace", // ⫎
  "\\bffrakb": "\ud587", // 햇
  "\\oplusrhrim": "\u2a2e", // ⨮
  "\\topbot": "\u2336", // ⌶
  "\\circleurquadblack": "\u25d4", // ◔
  "\\lnsim": "\u22e6", // ⋦
  "\\bfitomicron": "\ud744", // 흄
  "\\bfZ": "\ud419", // 퐙
  "\\nsim": "\u2241", // ≁
  "\\itrho": "\ud70c", // 휌
  "\\stareq": "\u225b", // ≛
  "\\textltailn": "\u0272", // ɲ
  "\\bfsansvarphi": "\ud78d", // 힍
  "\\bfsix": "\ud7d4", // ퟔ
  "\\sansi": "\ud5c2", // 헂
  "\\scrW": "\ud4b2", // 풲
  "\\disjquant": "\u2a08", // ⨈
  "\\mdlgwhtsquare": "\u25a1", // □
  "\\aries": "\u2648", // ♈
  "\\circlede": "\u24d4", // ⓔ
  "\\lbrackextender": "\u23a2", // ⎢
  "\\itA": "\ud434", // 퐴
  "\\bfitsansE": "\ud640", // 홀
  "\\itsigma": "\ud70e", // 휎
  "\\itnu": "\ud708", // 휈
  "\\circledsansnine": "\u2788", // ➈
  "\\textcolonmonetary": "\u20a1", // ₡
  "\\biguplus": "\u2a04", // ⨄
  "\\bfitsansc": "\ud658", // 환
  "\\textscn": "\u0274", // ɴ
  "\\circleds": "\u24e2", // ⓢ
  "\\uptau": "\u03c4", // τ
  "\\functionapply": "\u2061", // ⁡
  "\\neuter": "\u26b2", // ⚲
  "\\midbarwedge": "\u2a5c", // ⩜
  "\\subedot": "\u2ac3", // ⫃
  "\\droang": "\u031a", // ̚
  "\\bfsansalpha": "\ud770", // 흰
  "\\bfitsansz": "\ud66f", // 홯
  "\\rppolint": "\u2a12", // ⨒
  "\\leftzigzagarrow": "\u21dc", // ⇜
  "\\bfitlambda": "\ud740", // 흀
  "\\bfsansnine": "\ud7f5", // ퟵ
  "\\itomicron": "\ud70a", // 휊
  "\\obot": "\u29ba", // ⦺
  "\\bfscrr": "\ud4fb", // 퓻
  "\\simneqq": "\u2246", // ≆
  "\\itDelta": "\ud6e5", // 훥
  "\\leftrightharpoondownup": "\u294b", // ⥋
  "\\mp": "\u2213", // ∓
  "\\circledparallel": "\u29b7", // ⦷
  "\\bfSigma": "\ud6ba", // 횺
  "\\Join": "\u2a1d", // ⨝
  "\\itk": "\ud458", // 푘
  "\\upomicron": "\u03bf", // ο
  "\\bffrakJ": "\ud575", // 핵
  "\\bfsansOmega": "\ud76e", // 흮
  "\\itsansS": "\ud61a", // 혚
  "\\itsansr": "\ud633", // 혳
  "\\bffrakZ": "\ud585", // 햅
  "\\bfitsanstau": "\ud7bd", // ힽ
  "\\Searrow": "\u21d8", // ⇘
  "\\ttnine": "\ud7ff", // ퟿
  "\\texthtbardotlessjvar": "\u0284", // ʄ
  "\\itsansT": "\ud61b", // 혛
  "\\bfRho": "\ud6b8", // 횸
  "\\BbbY": "\ud550", // 핐
  "\\wedgemidvert": "\u2a5a", // ⩚
  "\\bfsanschi": "\ud786", // 힆
  "\\lessapprox": "\u2a85", // ⪅
  "\\textasciicircum": "\u005e", // ^
  "\\textbeta": "\u03b2", // β
  "\\lneq": "\u2a87", // ⪇
  "\\bduvlH": "\u255b", // ╛
  "\\eqqgtr": "\u2a9a", // ⪚
  "\\langle": "\u27e8", // ⟨
  "\\bfsansR": "\ud5e5", // 헥
  "\\iiint": "\u222d", // ∭
  "\\thickapprox": "\u2248", // ≈
  "\\bfvarepsilon": "\ud6c6", // 훆
  "\\equivVvert": "\u2a69", // ⩩
  "\\bfitOmicron": "\ud72a", // 휪
  "\\downharpoonrightbar": "\u2955", // ⥕
  "\\bfitsansEta": "\ud796", // 힖
  "\\midbarvee": "\u2a5d", // ⩝
  "\\sqsupseteq": "\u2292", // ⊒
  "\\DashV": "\u2ae5", // ⫥
  "\\rrangle": "\u298a", // ⦊
  "\\frakV": "\ud519", // 픙
  "\\bfi": "\ud422", // 퐢
  "\\nobreakhyphen": "\u2011", // ‑
  "\\bfsansbeta": "\ud771", // 흱
  "\\scrH": "\u210b", // ℋ, 풣
  "\\textopeno": "\u0254", // ɔ
  "\\textturnh": "\u0265", // ɥ
  "\\inversebullet": "\u25d8", // ◘
  "\\bfitsansR": "\ud64d", // 홍
  "\\bfitf": "\ud487", // 풇
  "\\gtrsim": "\u2273", // ≳
  "\\tminus": "\u29ff", // ⧿
  "\\pointint": "\u2a15", // ⨕
  "\\textgrave": "\u02cb", // ˋ
  "\\recorder": "\u2315", // ⌕
  "\\sqcap": "\u2293", // ⊓
  "\\cupvee": "\u2a45", // ⩅
  "\\triangleright": "\u25b7", // ▷
  "\\sanseight": "\ud7ea", // ퟪ
  "\\bfsansf": "\ud5f3", // 헳
  "\\squareurblack": "\u2b14", // ⬔
  "\\circledS": "\u24c8", // Ⓢ
  "\\bfitsansPi": "\ud79f", // 힟
  "\\ttR": "\ud681", // 횁
  "\\triangleserifs": "\u29cd", // ⧍
  "\\ttfive": "\ud7fb", // ퟻ
  "\\scrx": "\ud4cd", // 퓍
  "\\supnine": "\u2079", // ⁹
  "\\frakS": "\ud516", // 픖
  "\\subsetplus": "\u2abf", // ⪿
  "\\bfsanspi": "\ud77f", // 흿
  "\\sansU": "\ud5b4", // 햴
  "\\backcong": "\u224c", // ≌
  "\\bffraky": "\ud59e", // 햞
  "\\bfitv": "\ud497", // 풗
  "\\obrbrak": "\u23e0", // ⏠
  "\\textfranc": "\u20a3", // ₣
  "\\ell": "\u2113", // ℓ
  "\\bfitsansi": "\ud65e", // 홞
  "\\upomega": "\u03c9", // ω
  "\\Longmapsfrom": "\u27fd", // ⟽
  "\\Bbbp": "\ud561", // 핡
  "\\cloverleaf": "\u2318", // ⌘
  "\\supseteq": "\u2287", // ⊇
  "\\bfsansvarepsilon": "\ud774", // 흴
  "\\gggnest": "\u2af8", // ⫸
  "\\bfitPsi": "\ud733", // 휳
  "\\upwhitearrow": "\u21e7", // ⇧
  "\\bddVlh": "\u2556", // ╖
  "\\threedotcolon": "\u2af6", // ⫶
  "\\sansn": "\ud5c7", // 헇
  "\\bigtimes": "\u2a09", // ⨉
  "\\approxeqq": "\u2a70", // ⩰
  "\\lefttail": "\u2919", // ⤙
  "\\bfscrm": "\ud4f6", // 퓶
  "\\textoverlaysolidusii": "\u0338", // ̸
  "\\candra": "\u0310", // ̐
  "\\bfscrU": "\ud4e4", // 퓤
  "\\approx": "\u2248", // ≈
  "\\bfsansO": "\ud5e2", // 헢
  "\\cwopencirclearrow": "\u21bb", // ↻
  "\\bffrakY": "\ud584", // 햄
  "\\upDelta": "\u0394", // Δ
  "\\gnsim": "\u22e7", // ⋧
  "\\textcrD": "\u0110", // Đ
  "\\imath": "\u0131", // ı, 횤
  "\\therefore": "\u2234", // ∴
  "\\closure": "\u2050", // ⁐
  "\\textdprime": "\u2033", // ″
  "\\nLeftarrow": "\u21cd", // ⇍
  "\\fracfiveeighths": "\u215d", // ⅝
  "\\slash": "\u2215", // ∕
  "\\bfitsansb": "\ud657", // 홗
  "\\scrh": "\ud4bd", // 풽
  "\\yen": "\u00a5", // ¥
  "\\bfitvarkappa": "\ud752", // 흒
  "\\itchi": "\ud712", // 휒
  "\\diamondleftblack": "\u2b16", // ⬖
  "\\leftwhitearrow": "\u21e6", // ⇦
  "\\blacktriangledown": "\u25be", // ▾
  "\\mercury": "\u263f", // ☿
  "\\itK": "\ud43e", // 퐾
  "\\urtriangle": "\u25f9", // ◹
  "\\nvrightarrowtail": "\u2914", // ⤔
  "\\scrI": "\ud4a4", // 풤, ℐ
  "\\textcrh": "\u0127", // ħ
  "\\barleftarrow": "\u21e4", // ⇤
  "\\texttripleslash": "\u2afb", // ⫻
  "\\fbowtie": "\u29d3", // ⧓
  "\\Bbbs": "\ud564", // 핤
  "\\xsol": "\u29f8", // ⧸
  "\\bumpeq": "\u224f", // ≏
  "\\nless": "\u226e", // ≮
  "\\bfsansvarpi": "\ud78f", // 힏
  "\\emptysetobar": "\u29b1", // ⦱
  "\\lrblacktriangle": "\u25e2", // ◢
  "\\itS": "\ud446", // 푆
  "\\textbarl": "\u019a", // ƚ
  "\\squaretopblack": "\u2b12", // ⬒
  "\\isinE": "\u22f9", // ⋹
  "\\sanstwo": "\ud7e4", // ퟤ
  "\\qprime": "\u2057", // ⁗
  "\\bfW": "\ud416", // 퐖
  "\\textrtaill": "\u026d", // ɭ
  "\\circledfour": "\u2463", // ④
  "\\varhexagonblack": "\u2b22", // ⬢
  "\\ttv": "\ud69f", // 횟
  "\\bfsansc": "\ud5f0", // 헰
  "\\texthtq": "\u02a0", // ʠ
  "\\strns": "\u23e4", // ⏤
  "\\subrparen": "\u208e", // ₎
  "\\squareulquad": "\u25f0", // ◰
  "\\textoverlaylongsolidus": "\u0338", // ̸
  "\\bfitu": "\ud496", // 풖
  "\\upharpoonleftbar": "\u2960", // ⥠
  "\\bfsansChi": "\ud76c", // 희
  "\\DH": "\u00d0", // Ð
  "\\medmathspace": "\u205f", // no symbol
  "\\circledO": "\u24c4", // Ⓞ
  "\\downtriangleleftblack": "\u29e8", // ⧨
  "\\diamond": "\u22c4", // ⋄
  "\\hatapprox": "\u2a6f", // ⩯
  "\\Leftrightarrow": "\u21d4", // ⇔
  "\\Vvdash": "\u22aa", // ⊪
  "\\Bbbw": "\ud568", // 함
  "\\bfb": "\ud41b", // 퐛
  "\\dualmap": "\u29df", // ⧟
  "\\bfsansa": "\ud5ee", // 헮
  "\\tripleplus": "\u29fb", // ⧻
  "\\scri": "\ud4be", // 풾
  "\\bfitsanssigma": "\ud7bc", // ힼ
  "\\textsupinvscr": "\u02b6", // ʶ
  "\\frakc": "\ud520", // 픠
  "\\bfsansP": "\ud5e3", // 헣
  "\\upOmicron": "\u039f", // Ο
  "\\Bbbone": "\ud7d9", // ퟙ
  "\\textperiodcentered": "\u00b7", // ·
  "\\itTheta": "\ud6e9", // 훩
  "\\varlrtriangle": "\u22bf", // ⊿
  "\\bfitsansK": "\ud646", // 홆
  "\\scrU": "\ud4b0", // 풰
  "\\upvarepsilon": "\u03b5", // ε
  "\\lasp": "\u02bd", // ʽ
  "\\bfitsansx": "\ud66d", // 홭
  "\\figdash": "\u2012", // ‒
  "\\curlyeqprec": "\u22de", // ⋞
  "\\ttn": "\ud697", // 횗
  "\\leftarrowtriangle": "\u21fd", // ⇽
  "\\itsansD": "\ud60b", // 혋
  "\\bfitsansvarkappa": "\ud7c6", // ퟆ
  "\\oslash": "\u2298", // ⊘
  "\\bfs": "\ud42c", // 퐬
  "\\twoheadleftarrow": "\u219e", // ↞
  "\\bfw": "\ud430", // 퐰
  "\\textoverlaybackslash": "\u20e5", // ⃥
  "\\textoverlaytwosolidus": "\u20eb", // ⃫
  "\\propto": "\u221d", // ∝
  "\\Lleftarrow": "\u21da", // ⇚
  "\\supzero": "\u2070", // ⁰
  "\\nvleftrightarrow": "\u21f9", // ⇹
  "\\invwhiteupperhalfcircle": "\u25da", // ◚
  "\\circledsansfour": "\u2783", // ➃
  "\\upchi": "\u03c7", // χ
  "\\dlcrop": "\u230d", // ⌍
  "\\subsetneqq": "\u2acb", // ⫋
  "\\underleftarrow": "\u20ee", // ⃮
  "\\lbracklltick": "\u298f", // ⦏
  "\\bigvee": "\u22c1", // ⋁
  "\\bfsansvarkappa": "\ud78c", // 힌
  "\\scrT": "\ud4af", // 풯
  "\\rightsquigarrow": "\u219d", // ↝
  "\\blackpointerleft": "\u25c4", // ◄
  "\\bfitG": "\ud46e", // 푮
  "\\textsubtilde": "\u0330", // ̰
  "\\bfscrA": "\ud4d0", // 퓐
  "\\draftingarrow": "\u279b", // ➛
  "\\vectimes": "\u2a2f", // ⨯
  "\\bfitsansQ": "\ud64c", // 홌
  "\\bffrakg": "\ud58c", // 햌
  "\\downrightcurvedarrow": "\u2935", // ⤵
  "\\l": "\u0142", // ł
  "\\nVrightarrow": "\u21fb", // ⇻
  "\\bowtie": "\u22c8", // ⋈
  "\\textturny": "\u028e", // ʎ
  "\\textscb": "\u0299", // ʙ
  "\\bardownharpoonleft": "\u2961", // ⥡
  "\\bigtriangleleft": "\u2a1e", // ⨞
  "\\bfitsansV": "\ud651", // 홑
  "\\bfitsansUpsilon": "\ud7a4", // 힤
  "\\bfsansOmicron": "\ud764", // 흤
  "\\concavediamond": "\u27e1", // ⟡
  "\\subseteq": "\u2286", // ⊆
  "\\texthtg": "\u0260", // ɠ
  "\\textsubbridge": "\u032a", // ̪
  "\\bfitsansj": "\ud65f", // 홟
  "\\itomega": "\ud714", // 휔
  "\\subseteqq": "\u2ac5", // ⫅
  "\\pushout": "\u27d4", // ⟔
  "\\nwsearrow": "\u2921", // ⤡
  "\\itsansE": "\ud60c", // 혌
  "\\bfEpsilon": "\ud6ac", // 횬
  "\\textraiserevglotstop": "\u02c1", // ˁ
  "\\bffrakX": "\ud583", // 햃
  "\\circledownarrow": "\u29ec", // ⧬
  "\\barwedge": "\u22bc", // ⊼
  "\\simminussim": "\u2a6c", // ⩬
  "\\italpha": "\ud6fc", // 훼
  "\\bigstar": "\u2605", // ★
  "\\intbottom": "\u2321", // ⌡
  "\\bfsansmu": "\ud77b", // 흻
  "\\squarenwsefill": "\u25a7", // ▧
  "\\semicolon": "\u003b", // ;
  "\\uplambda": "\u03bb", // λ
  "\\Bbbf": "\ud557", // 핗
  "\\dashv": "\u22a3", // ⊣
  "\\itvarTheta": "\ud6f3", // 훳
  "\\leftrightharpoons": "\u21cb", // ⇋
  "\\Zbar": "\u01b5", // Ƶ
  "\\itOmega": "\ud6fa", // 훺
  "\\textoverlaylongstroke": "\u0336", // ̶
  "\\updownarrowbar": "\u21a8", // ↨
  "\\supseteqq": "\u2ac6", // ⫆
  "\\oturnedcomma": "\u0312", // ̒
  "\\backsim": "\u223d", // ∽
  "\\circlebottomhalfblack": "\u25d2", // ◒
  "\\mapsfrom": "\u21a4", // ↤
  "\\bfsansy": "\ud606", // 혆
  "\\bfUpsilon": "\ud6bc", // 횼
  "\\bfsansiota": "\ud778", // 흸
  "\\bfsansF": "\ud5d9", // 헙
  "\\models": "\u22a7", // ⊧
  "\\dotminus": "\u2238", // ∸
  "\\smallsetminus": "\u2216", // ∖
  "\\bfscrH": "\ud4d7", // 퓗
  "\\lbracemid": "\u23a8", // ⎨
  "\\bfsansomicron": "\ud77e", // 흾
  "\\downdasharrow": "\u21e3", // ⇣
  "\\upZeta": "\u0396", // Ζ
  "\\bfsansZeta": "\ud75b", // 흛
  "\\plus": "\u002b", // +
  "\\gtcc": "\u2aa7", // ⪧
  "\\concavediamondtickright": "\u27e3", // ⟣
  "\\punctspace": "\u2008", // no symbol
  "\\dots": "\u2026", // …
  "\\ltquest": "\u2a7b", // ⩻
  "\\bfsansXi": "\ud763", // 흣
  "\\sansG": "\ud5a6", // 햦
  "\\bfitsanst": "\ud669", // 황
  "\\bfitN": "\ud475", // 푵
  "\\mho": "\u2127", // ℧
  "\\textlowering": "\u031e", // ̞
  "\\bagmember": "\u22ff", // ⋿
  "\\scissors": "\u2702", // ✂
  "\\O": "\u00d8", // Ø
  "\\bfn": "\ud427", // 퐧
  "\\leftrightarrowcircle": "\u2948", // ⥈
  "\\bfitEpsilon": "\ud720", // 휠
  "\\sansseven": "\ud7e9", // ퟩ
  "\\sansLinverted": "\u2143", // ⅃
  "\\Updownarrow": "\u21d5", // ⇕
  "\\textturna": "\u0250", // ɐ
  "\\gla": "\u2aa5", // ⪥
  "\\sqlozenge": "\u2311", // ⌑
  "\\smblkcircle": "\u2022", // •
  "\\topsemicircle": "\u25e0", // ◠
  "\\supsix": "\u2076", // ⁶
  "\\drbkarow": "\u2910", // ⤐
  "\\itc": "\ud450", // 푐
  "\\Sqcup": "\u2a4f", // ⩏
  "\\frakD": "\ud507", // 픇
  "\\bfxi": "\ud6cf", // 훏
  "\\rvzigzag": "\u29d9", // ⧙
  "\\bftau": "\ud6d5", // 훕
  "\\Bbbt": "\ud565", // 핥
  "\\bfitsansomega": "\ud7c2", // ퟂ
  "\\subsub": "\u2ad5", // ⫕
  "\\bduvbH": "\u2567", // ╧
  "\\rightharpoonsupdown": "\u2964", // ⥤
  "\\textcrd": "\u0111", // đ
  "\\blacklefthalfcircle": "\u25d6", // ◖
  "\\bfvarTheta": "\ud6b9", // 횹
  "\\squarevfill": "\u25a5", // ▥
  "\\textthreesuperior": "\u00b3", // ³
  "\\bfm": "\ud426", // 퐦
  "\\emptyset": "\u2205", // ∅
  "\\BbbS": "\ud54a", // 핊
  "\\succapprox": "\u2ab8", // ⪸
  "\\ttk": "\ud694", // 횔
  "\\ularc": "\u25dc", // ◜
  "\\Sqcap": "\u2a4e", // ⩎
  "\\ttf": "\ud68f", // 횏
  "\\bfsansM": "\ud5e0", // 헠
  "\\bfitAlpha": "\ud71c", // 휜
  "\\blackinwhitediamond": "\u25c8", // ◈
  "\\vbraceextender": "\u23aa", // ⎪
  "\\frakR": "\ud515", // 픕, ℜ
  "\\smwhtsquare": "\u25ab", // ▫
  "\\itz": "\ud467", // 푧
  "\\mdsmblkcircle": "\u2981", // ⦁
  "\\circledv": "\u24e5", // ⓥ
  "\\texthtb": "\u0253", // ɓ
  "\\langledot": "\u2991", // ⦑
  "\\downharpoonsleftright": "\u2965", // ⥥
  "\\bffrakn": "\ud593", // 햓
  "\\textonehalf": "\u00bd", // ½
  "\\bfitsansS": "\ud64e", // 홎
  "\\textbenttailyogh": "\u01ba", // ƺ
  "\\textsubbreve": "\u032e", // ̮
  "\\zproject": "\u2a21", // ⨡
  "\\itI": "\ud43c", // 퐼
  "\\BbbPi": "\u213f", // ℿ
  "\\textsupturnrrtail": "\u02b5", // ʵ
  "\\rightrightarrows": "\u21c9", // ⇉
  "\\bfitsansg": "\ud65c", // 활
  "\\textrtailz": "\u0290", // ʐ
  "\\sansZ": "\ud5b9", // 햹
  "\\rsub": "\u2a65", // ⩥
  "\\upvarkappa": "\u03f0", // ϰ
  "\\saturn": "\u2644", // ♄
  "\\nwarrow": "\u2196", // ↖
  "\\circledt": "\u24e3", // ⓣ
  "\\textdegree": "\u00b0", // °
  "\\capbarcup": "\u2a49", // ⩉
  "\\upbeta": "\u03b2", // β
  "\\rbrace": "\u007d", // }
  "\\Bbbu": "\ud566", // 핦
  "\\AE": "\u00c6", // Æ
  "\\bfomega": "\ud6da", // 훚
  "\\llblacktriangle": "\u25e3", // ◣
  "\\ipacrossaccent": "\u02df", // ˟
  "\\smalltriangleright": "\u25b9", // ▹
  "\\curlywedge": "\u22cf", // ⋏
  "\\kernelcontraction": "\u223b", // ∻
  "\\blackcircledsansseven": "\u2790", // ➐
  "\\textbarrevglotstop": "\u02a2", // ʢ
  "\\bfscrs": "\ud4fc", // 퓼
  "\\tonebarmid": "\u02e7", // ˧
  "\\eqgtr": "\u22dd", // ⋝
  "\\squarellquad": "\u25f1", // ◱
  "\\textturnv": "\u028c", // ʌ
  "\\leftdowncurvedarrow": "\u2936", // ⤶
  "\\Bbbx": "\ud569", // 합
  "\\setminus": "\u2216", // ∖
  "\\scrV": "\ud4b1", // 풱
  "\\succeq": "\u2ab0", // ⪰
  "\\guilsinglright": "\u203a", // ›
  "\\textovercross": "\u033d", // ̽
  "\\rvboxline": "\u23b9", // ⎹
  "\\textmu": "\u00b5", // µ
  "\\hspace": "\u200a", // no symbol
  "\\textlhti": "\u027f", // ɿ
  "\\leftwavyarrow": "\u219c", // ↜
  "\\bigblacktriangleup": "\u25b2", // ▲
  "\\ddots": "\u22f1", // ⋱
  "\\bfitsansGamma": "\ud792", // 힒
  "\\bffrakd": "\ud589", // 행
  "\\bfitIota": "\ud724", // 휤
  "\\bfitsansv": "\ud66b", // 홫
  "\\squarerightblack": "\u25e8", // ◨
  "\\textoverlaydoublevert": "\u20e6", // ⃦
  "\\blackcircledsanseight": "\u2791", // ➑
  "\\overleftharpoondown": "\u20ed", // ⃭
  "\\bfsansGamma": "\ud758", // 흘
  "\\overparen": "\u23dc", // ⏜, ︵
  "\\varniobar": "\u22fd", // ⋽
  "\\plusdot": "\u2a25", // ⨥
  "\\wideoverleftarrow": "\u20d6", // ⃖
  "\\ltlarr": "\u2976", // ⥶
  "\\sansV": "\ud5b5", // 햵
  "\\v": "\u02c7", // ˇ
  "\\bfitTheta": "\ud723", // 휣
  "\\backdprime": "\u2036", // ‶
  "\\nvLeftarrow": "\u2902", // ⤂
  "\\invwhitelowerhalfcircle": "\u25db", // ◛
  "\\cap": "\u2229", // ∩
  "\\bfsanstheta": "\ud777", // 흷
  "\\rbrackextender": "\u23a5", // ⎥
  "\\Ohorn": "\u01a0", // Ơ
  "\\assert": "\u22a6", // ⊦
  "\\rParen": "\u2986", // ⦆
  "\\vec": "\u20d7", // ⃗
  "\\nRightarrow": "\u21cf", // ⇏
  "\\circledwhitebullet": "\u29be", // ⦾
  "\\itTau": "\ud6f5", // 훵
  "\\bft": "\ud42d", // 퐭
  "\\hexagon": "\u2394", // ⎔
  "\\bfTau": "\ud6bb", // 횻
  "\\bfphi": "\ud6df", // 훟
  "\\approxident": "\u224b", // ≋
  "\\ita": "\ud44e", // 푎
  "\\nvtwoheadrightarrowtail": "\u2917", // ⤗
  "\\sansq": "\ud5ca", // 헊
  "\\top": "\u22a4", // ⊤
  "\\rightwavyarrow": "\u219d", // ↝
  "\\bfg": "\ud420", // 퐠
  "\\veebar": "\u22bb", // ⊻
  "\\circledsansthree": "\u2782", // ➂
  "\\textflorin": "\u0192", // ƒ
  "\\Uparrow": "\u21d1", // ⇑
  "\\ttJ": "\ud679", // 홹
  "\\eqslantgtr": "\u2a96", // ⪖
  "\\sslash": "\u2afd", // ⫽
  "\\supsup": "\u2ad6", // ⫖
  "\\triangleodot": "\u29ca", // ⧊
  "\\textdoubleoverline": "\u033f", // ̿
  "\\textdoubletilde": "\u034c", // ͌
  "\\hookleftarrow": "\u21a9", // ↩
  "\\bfitsansalpha": "\ud7aa", // 힪
  "\\Bbbb": "\ud553", // 핓
  "\\fraconesixth": "\u2159", // ⅙
  "\\eqqplus": "\u2a71", // ⩱
  "\\upEta": "\u0397", // Η
  "\\supsetneqq": "\u2acc", // ⫌
  "\\bfscrt": "\ud4fd", // 퓽
  "\\boxdot": "\u22a1", // ⊡
  "\\lparenlend": "\u239d", // ⎝
  "\\righttail": "\u291a", // ⤚
  "\\tilde": "\u0303", // ̃
  "\\forkv": "\u2ad9", // ⫙
  "\\opluslhrim": "\u2a2d", // ⨭
  "\\bfvarphi": "\ud6d7", // 훗
  "\\itV": "\ud449", // 푉
  "\\bffrakx": "\ud59d", // 햝
  "\\ttzero": "\ud7f6", // ퟶ
  "\\rightarrowplus": "\u2945", // ⥅
  "\\bdtriplevdash": "\u2506", // ┆
  "\\bfchi": "\ud6d8", // 훘
  "\\viewdata": "\u2317", // ⌗
  "\\upuparrows": "\u21c8", // ⇈
  "\\Bbbm": "\ud55e", // 핞
  "\\itF": "\ud439", // 퐹
  "\\mdblksquare": "\u25fc", // ◼
  "\\nlessgtr": "\u2278", // ≸
  "\\scry": "\ud4ce", // 퓎
  "\\smallfrown": "\u2322", // ⌢
  "\\preceq": "\u2aaf", // ⪯
  "\\pentagon": "\u2b20", // ⬠
  "\\Lsh": "\u21b0", // ↰
  "\\hyphenpoint": "\u2027", // ‧
  "\\bfitsansk": "\ud660", // 홠
  "\\bfDigamma": "\ud7ca", // ퟊
  "\\sansE": "\ud5a4", // 햤
  "\\bfscrI": "\ud4d8", // 퓘
  "\\bfitEta": "\ud722", // 휢
  "\\textbaro": "\u0275", // ɵ
  "\\shortrightarrow": "\u2192", // →
  "\\ith": "\ud455", // 푕
  "\\ittheta": "\ud703", // 휃
  "\\uptheta": "\u03b8", // θ
  "\\itsansx": "\ud639", // 혹
  "\\olessthan": "\u29c0", // ⧀
  "\\bfitChi": "\ud732", // 휲
  "\\boxbar": "\u25eb", // ◫
  "\\bfsanspsi": "\ud787", // 힇
  "\\bfitz": "\ud49b", // 풛
  "\\itsansj": "\ud62b", // 혫
  "\\gesdotol": "\u2a84", // ⪄
  "\\circleulquad": "\u25f4", // ◴
  "\\precnsim": "\u22e8", // ⋨
  "\\bfscrK": "\ud4da", // 퓚
  "\\textain": "\u02bf", // ʿ
  "\\geq": "\u2265", // ≥
  "\\bfomicron": "\ud6d0", // 훐
  "\\fracfourfifths": "\u2158", // ⅘
  "\\supsetcirc": "\u27c4", // ⟄
  "\\smashtimes": "\u2a33", // ⨳
  "\\Bbbg": "\ud558", // 하
  "\\hiraganano": "\u306e", // の
  "\\whitesquaretickleft": "\u27e4", // ⟤
  "\\leftrightharpoonupup": "\u294e", // ⥎
  "\\itsansW": "\ud61e", // 혞
  "\\nvleftarrow": "\u21f7", // ⇷
  "\\bfsansKappa": "\ud75f", // 흟
  "\\bffrakj": "\ud58f", // 햏
  "\\bfMu": "\ud6b3", // 횳
  "\\triangleq": "\u225c", // ≜
  "\\frownie": "\u2639", // ☹
  "\\itsansI": "\ud610", // 혐
  "\\bfscrp": "\ud4f9", // 퓹
  "\\bfitsanse": "\ud65a", // 홚
  "\\sinewave": "\u223f", // ∿
  "\\lrtriangleeq": "\u29e1", // ⧡
  "\\origof": "\u22b6", // ⊶
  "\\nLeftrightarrow": "\u21ce", // ⇎
  "\\barupharpoonright": "\u2954", // ⥔
  "\\Colon": "\u2237", // ∷
  "\\vbrtri": "\u29d0", // ⧐
  "\\bfl": "\ud425", // 퐥
  "\\bfNu": "\ud6b4", // 횴
  "\\sumint": "\u2a0b", // ⨋
  "\\boxonbox": "\u29c9", // ⧉
  "\\bigotimes": "\u2a02", // ⨂
  "\\partial": "\u2202", // ∂
  "\\subnine": "\u2089", // ₉
  "\\taurus": "\u2649", // ♉
  "\\scrQ": "\ud4ac", // 풬
  "\\ttu": "\ud69e", // 횞
  "\\bfscru": "\ud4fe", // 퓾
  "\\Bumpeq": "\u224e", // ≎
  "\\Bbbh": "\ud559", // 학
  "\\textrhookschwa": "\u025a", // ɚ
  "\\acidfree": "\u267e", // ♾
  "\\bfsanspartial": "\ud789", // 힉
  "\\measanglelutonw": "\u29a9", // ⦩
  "\\scrS": "\ud4ae", // 풮
  "\\textqprime": "\u2057", // ⁗
  "\\nrightarrow": "\u219b", // ↛
  "\\itY": "\ud44c", // 푌
  "\\textunderscore": "\u005f", // _
  "\\circlednine": "\u2468", // ⑨
  "\\bfvarkappa": "\ud6de", // 훞
  "\\updownharpoonleftleft": "\u2951", // ⥑
  "\\itsansG": "\ud60e", // 혎
  "\\widebridgeabove": "\u20e9", // ⃩
  "\\uppsi": "\u03c8", // ψ
  "\\shortuparrow": "\u2191", // ↑
  "\\itx": "\ud465", // 푥
  "\\bffraks": "\ud598", // 햘
  "\\dotsim": "\u2a6a", // ⩪
  "\\lsimg": "\u2a8f", // ⪏
  "\\shortleftarrow": "\u2190", // ←
  "\\frakJ": "\ud50d", // 픍
  "\\ointctrclockwise": "\u2233", // ∳
  "\\cuberoot": "\u221b", // ∛
  "\\bfitW": "\ud47e", // 푾
  "\\itsansZ": "\ud621", // 혡
  "\\longrightarrow": "\u27f6", // ⟶
  "\\textasciigrave": "\u0060", // `
  "\\texttrademark": "\u2122", // ™
  "\\bffrakc": "\ud588", // 했
  "\\sphericalangle": "\u2222", // ∢
  "\\bfitphi": "\ud74b", // 흋
  "\\bfitc": "\ud484", // 풄
  "\\lozengeminus": "\u27e0", // ⟠
  "\\Mapsfrom": "\u2906", // ⤆
  "\\lbrackuend": "\u23a1", // ⎡
  "\\uprightcurvearrow": "\u2934", // ⤴
  "\\triangleminus": "\u2a3a", // ⨺
  "\\trianglelefteq": "\u22b4", // ⊴
  "\\textlhookd": "\u1d81", // ᶁ
  "\\bfOmega": "\ud6c0", // 훀
  "\\circledH": "\u24bd", // Ⓗ
  "\\textscu": "\u1d1c", // ᴜ
  "\\textdoublegrave": "\u030f", // ̏
  "\\bfsanstwo": "\ud7ee", // ퟮ
  "\\ttX": "\ud687", // 횇
  "\\hourglass": "\u29d6", // ⧖
  "\\bffraka": "\ud586", // 햆
  "\\napprox": "\u2249", // ≉
  "\\zcmp": "\u2a1f", // ⨟
  "\\cirscir": "\u29c2", // ⧂
  "\\sqrt": "\u221a", // √
  "\\rfloor": "\u230b", // ⌋
  "\\lbrackubar": "\u298b", // ⦋
  "\\trapezium": "\u23e2", // ⏢
  "\\bfPhi": "\ud6bd", // 횽
  "\\ni": "\u220b", // ∋
  "\\eqeq": "\u2a75", // ⩵
  "\\textlptr": "\u02c2", // ˂
  "\\lparenless": "\u2993", // ⦓
  "\\circledc": "\u24d2", // ⓒ
  "\\blackdiamonddownarrow": "\u29ea", // ⧪
  "\\cdotp": "\u00b7", // ·
  "\\textlowgrave": "\u02ce", // ˎ
  "\\plustrif": "\u2a28", // ⨨
  "\\disin": "\u22f2", // ⋲
  "\\itXi": "\ud6ef", // 훯
  "\\bfitsanslambda": "\ud7b4", // ힴ
  "\\squareleftblack": "\u25e7", // ◧
  "\\BbbT": "\ud54b", // 핋
  "\\interleave": "\u2af4", // ⫴
  "\\nVtwoheadrightarrowtail": "\u2918", // ⤘
  "\\bfOmicron": "\ud6b6", // 횶
  "\\overleftarrow": "\u20d6", // ⃖
  "\\bfsansEta": "\ud75c", // 흜
  "\\scrd": "\ud4b9", // 풹
  "\\leftharpoondownbar": "\u295e", // ⥞
  "\\bfitsansX": "\ud653", // 홓
  "\\Downarrow": "\u21d3", // ⇓
  "\\itPsi": "\ud6f9", // 훹
  "\\equalparallel": "\u22d5", // ⋕
  "\\dottedcircle": "\u25cc", // ◌
  "\\bfitsansh": "\ud65d", // 홝
  "\\adots": "\u22f0", // ⋰
  "\\scrl": "\ud4c1", // 퓁
  "\\bfsansL": "\ud5df", // 헟
  "\\textsemicolonreversed": "\u204f", // ⁏
  "\\wedgedot": "\u27d1", // ⟑
  "\\circledthree": "\u2462", // ③
  "\\rtimes": "\u22ca", // ⋊
  "\\dprime": "\u2033", // ″
  "\\Bbbgamma": "\u213d", // ℽ
  "\\texthtc": "\u0188", // ƈ
  "\\circledQ": "\u24c6", // Ⓠ
  "\\neovsearrow": "\u292e", // ⤮
  "\\circledD": "\u24b9", // Ⓓ
  "\\textsmalltilde": "\u02dc", // ˜
  "\\rightdbltail": "\u291c", // ⤜
  "\\varointclockwise": "\u2232", // ∲
  "\\downarrowbar": "\u2913", // ⤓
  "\\bfscrY": "\ud4e8", // 퓨
  "\\threeunderdot": "\u20e8", // ⃨
  "\\bfsansepsilon": "\ud78a", // 힊
  "\\itsansK": "\ud612", // 혒
  "\\ipavoicing": "\u02ec", // ˬ
  "\\ipasupx": "\u02e3", // ˣ
  "\\fint": "\u2a0f", // ⨏
  "\\sansk": "\ud5c4", // 헄
  "\\tth": "\ud691", // 횑
  "\\BbbK": "\ud542", // 핂
  "\\checkmark": "\u2713", // ✓
  "\\textexclam": "\u01c3", // ǃ
  "\\bdbvbH": "\u256a", // ╪
  "\\ggg": "\u22d9", // ⋙
  "\\textrhookrevepsilon": "\u025d", // ɝ
  "\\bigtriangledown": "\u25bd", // ▽
  "\\itmu": "\ud707", // 휇
  "\\scrf": "\ud4bb", // 풻
  "\\bfitsigma": "\ud748", // 흈
  "\\minusrdots": "\u2a2c", // ⨬
  "\\lbracklend": "\u23a3", // ⎣
  "\\textpertenthousand": "\u2031", // ‱
  "\\textturnm": "\u026f", // ɯ
  "\\textoz": "\u2125", // ℥
  "\\itepsilon": "\ud716", // 휖
  "\\otimeslhrim": "\u2a34", // ⨴
  "\\textsubline": "\u0332", // ̲
  "\\scre": "\u212f", // ℯ, 풺
  "\\frakU": "\ud518", // 픘
  "\\itsansa": "\ud622", // 혢
  "\\bdbVbH": "\u256c", // ╬
  "\\textperthousand": "\u2030", // ‰
  "\\itvarkappa": "\ud718", // 휘
  "\\lrcorner": "\u231f", // ⌟
  "\\veeodot": "\u2a52", // ⩒
  "\\fltns": "\u23e5", // ⏥
  "\\textcurrency": "\u00a4", // ¤
  "\\^": "\u02c6", // ˆ
  "\\textasciimacron": "\u00af", // ¯
  "\\itpartial": "\ud715", // 휕
  "\\sansP": "\ud5af", // 햯
  "\\profalar": "\u232e", // ⌮
  "\\vlongdash": "\u27dd", // ⟝
  "\\fraks": "\ud530", // 픰
  "\\acwcirclearrow": "\u2940", // ⥀
  "\\bddvbH": "\u2564", // ╤
  "\\textctj": "\u029d", // ʝ
  "\\texthvlig": "\u0195", // ƕ
  "\\bfscrT": "\ud4e3", // 퓣
  "\\Leftarrow": "\u21d0", // ⇐
  "\\bfsansK": "\ud5de", // 헞
  "\\bfitPhi": "\ud731", // 휱
  "\\tonebarlow": "\u02e8", // ˨
  "\\nleq": "\u2270", // ≰
  "\\itvarrho": "\ud71a", // 휚
  "\\lesssim": "\u2272", // ≲
  "\\leftmoon": "\u263e", // ☾
  "\\ttY": "\ud688", // 횈
  "\\measanglerutone": "\u29a8", // ⦨
  "\\suphsub": "\u2ad7", // ⫗
  "\\lll": "\u22d8", // ⋘
  "\\bfitupsilon": "\ud74a", // 흊
  "\\bfsansn": "\ud5fb", // 헻
  "\\scrO": "\ud4aa", // 풪
  "\\longrightzigzagarrow": "\u27ff", // ⟿
  "\\nhVvert": "\u2af5", // ⫵
  "\\otimes": "\u2297", // ⊗
  "\\circledzero": "\u24ea", // ⓪
  "\\cjkdprimequote": "\u301e", // 〞
  "\\csup": "\u2ad0", // ⫐
  "\\frakH": "\u210c", // ℌ, 픋
  "\\textctesh": "\u0286", // ʆ
  "\\bfits": "\ud494", // 풔
  "\\upNu": "\u039d", // Ν
  "\\Bbbr": "\ud563", // 핣
  "\\bdbVbh": "\u256b", // ╫
  "\\textsce": "\u1d07", // ᴇ
  "\\bfsansomega": "\ud788", // 히
  "\\texthtk": "\u0199", // ƙ
  "\\enleaderonedot": "\u2024", // ․
  "\\ne": "\u2260", // ≠
  "\\widerightharpoonaccent": "\u20d1", // ⃑
  "\\downharpoonleft": "\u21c3", // ⇃
  "\\shortuptack": "\u2ae0", // ⫠
  "\\bfq": "\ud42a", // 퐪
  "\\twolowline": "\u2017", // ‗
  "\\hzigzag": "\u3030", // 〰
  "\\errbardiamond": "\u29f0", // ⧰
  "\\lfloor": "\u230a", // ⌊
  "\\dbkarow": "\u290f", // ⤏
  "\\rbracelend": "\u23ad", // ⎭
  "\\nvdash": "\u22ac", // ⊬
  "\\bfitrho": "\ud746", // 흆
  "\\nni": "\u220c", // ∌
  "\\blockrighthalf": "\u2590", // ▐
  "\\rightzigzagarrow": "\u21dd", // ⇝
  "\\cup": "\u222a", // ∪
  "\\textscl": "\u029f", // ʟ
  "\\greater": "\u003e", // >
  "\\bffrakB": "\ud56d", // 항
  "\\itw": "\ud464", // 푤
  "\\itChi": "\ud6f8", // 훸
  "\\textctd": "\u0221", // ȡ
  "\\mapsup": "\u21a5", // ↥
  "\\vBarv": "\u2ae9", // ⫩
  "\\bfscrw": "\ud500", // 픀
  "\\bffrakC": "\ud56e", // 핮
  "\\itsansw": "\ud638", // 호
  "\\upkappa": "\u03ba", // κ
  "\\bffrako": "\ud594", // 햔
  "\\danger": "\u2621", // ☡
  "\\varstar": "\u2736", // ✶
  "\\Lparengtr": "\u2995", // ⦕
  "\\mid": "\u2223", // ∣
  "\\L": "\u0141", // Ł
  "\\textcopyright": "\u00a9", // ©
  "\\textsubrhalfring": "\u0339", // ̹
  "\\nHdownarrow": "\u21df", // ⇟
  "\\textsubdot": "\u0323", // ̣
  "\\lceil": "\u2308", // ⌈
  "\\textatsign": "\u0040", // @
  "\\ito": "\ud45c", // 표
  "\\Uhorn": "\u01af", // Ư
  "\\bfitsansOmega": "\ud7a8", // 힨
  "\\bfsansone": "\ud7ed", // ퟭ
  "\\hkswarow": "\u2926", // ⤦
  "\\bigslopedwedge": "\u2a58", // ⩘
  "\\sansS": "\ud5b2", // 햲
  "\\carriagereturn": "\u21b5", // ↵
  "\\bfitsansT": "\ud64f", // 홏
  "\\upvarpi": "\u03d6", // ϖ
  "\\textcloseepsilon": "\u029a", // ʚ
  "\\bduVrH": "\u255a", // ╚
  "\\sansu": "\ud5ce", // 헎
  "\\bfZeta": "\ud6ad", // 횭
  "\\upSigma": "\u03a3", // Σ
  "\\itsanst": "\ud635", // 혵
  "\\Ldsh": "\u21b2", // ↲
  "\\bfitiota": "\ud73e", // 휾
  "\\frakK": "\ud50e", // 픎
  "\\gnapprox": "\u2a8a", // ⪊
  "\\upharpoonright": "\u21be", // ↾
  "\\bfscrc": "\ud4ec", // 퓬
  "\\texttieunderrightarrow": "\u0362", // ͢
  "\\trianglerightblack": "\u25ee", // ◮
  "\\bfito": "\ud490", // 풐
  "\\itsansV": "\ud61d", // 혝
  "\\subrarr": "\u2979", // ⥹
  "\\bfsansg": "\ud5f4", // 헴
  "\\oiiint": "\u2230", // ∰
  "\\vardoublebarwedge": "\u2306", // ⌆
  "\\male": "\u2642", // ♂
  "\\textoverlaysolidusi": "\u0338", // ̸
  "\\wideleftharpoonaccent": "\u20d0", // ⃐
  "\\gsiml": "\u2a90", // ⪐
  "\\BbbI": "\ud540", // 핀
  "\\Bbbzero": "\ud7d8", // ퟘ
  "\\eqless": "\u22dc", // ⋜
  "\\Not": "\u2aec", // ⫬
  "\\blacksmiley": "\u263b", // ☻
  "\\bff": "\ud41f", // 퐟
  "\\dottimes": "\u2a30", // ⨰
  "\\leftsquigarrow": "\u219c", // ↜
  "\\updownarrows": "\u21c5", // ⇅
  "\\bftwo": "\ud7d0", // ퟐ
  "\\hrectangleblack": "\u25ac", // ▬
  "\\bfsanskappa": "\ud779", // 흹
  "\\blacklozenge": "\u29eb", // ⧫
  "\\lbrackultick": "\u298d", // ⦍
  "\\hrectangle": "\u25ad", // ▭
  "\\circledI": "\u24be", // Ⓘ
  "\\textdoublebarslash": "\u2260", // ≠
  "\\circledT": "\u24c9", // Ⓣ
  "\\gesles": "\u2a94", // ⪔
  "\\circledE": "\u24ba", // Ⓔ
  "\\botsemicircle": "\u25e1", // ◡
  "\\upGamma": "\u0393", // Γ
  "\\upSampi": "\u03e0", // Ϡ
  "\\itupsilon": "\ud710", // 휐
  "\\bfsansI": "\ud5dc", // 헜
  "\\rbracklend": "\u23a6", // ⎦
  "\\frakm": "\ud52a", // 픪
  "\\pluto": "\u2647", // ♇
  "\\textpesetas": "\u20a7", // ₧
  "\\bfC": "\ud402", // 퐂
  "\\bffrakf": "\ud58b", // 햋
  "\\heartsuit": "\u2661", // ♡
  "\\bfdigamma": "\ud7cb", // ퟋ
  "\\equivDD": "\u2a78", // ⩸
  "\\leftharpoonupbar": "\u295a", // ⥚
  "\\scrj": "\ud4bf", // 풿
  "\\mdlgblkcircle": "\u25cf", // ●
  "\\frakj": "\ud527", // 픧
  "\\bfChi": "\ud6be", // 횾
  "\\lesges": "\u2a93", // ⪓
  "\\varhexagonlrbonds": "\u232c", // ⌬
  "\\closedvarcup": "\u2a4c", // ⩌
  "\\barV": "\u2aea", // ⫪
  "\\rightmoon": "\u263d", // ☽
  "\\turnednot": "\u2319", // ⌙
  "\\barupharpoonleft": "\u2958", // ⥘
  "\\updownharpoonsleftright": "\u296e", // ⥮
  "\\bfitsansw": "\ud66c", // 홬
  "\\Bbbz": "\ud56b", // 핫
  "\\Vbar": "\u2aeb", // ⫫
  "\\oplus": "\u2295", // ⊕
  "\\bduvlh": "\u2518", // ┘
  "\\supsetneq": "\u228b", // ⊋
  "\\wr": "\u2240", // ≀
  "\\triangleubar": "\u29cb", // ⧋
  "\\lescc": "\u2aa8", // ⪨
  "\\triangles": "\u29cc", // ⧌
  "\\texttheta": "\u03b8", // θ
  "\\bfsansh": "\ud5f5", // 헵
  "\\leftharpoonaccent": "\u20d0", // ⃐
  "\\bfsansj": "\ud5f7", // 헷
  "\\caretinsert": "\u2038", // ‸
  "\\rbag": "\u27c6", // ⟆
  "\\bfscrD": "\ud4d3", // 퓓
  "\\endash": "\u2013", // –
  "\\conjunction": "\u260c", // ☌
  "\\gimel": "\u2137", // ℷ
  "\\bfitx": "\ud499", // 풙
  "\\textpipe": "\u01c0", // ǀ
  "\\ringplus": "\u2a22", // ⨢
  "\\frakk": "\ud528", // 픨
  "\\bffraki": "\ud58e", // 햎
  "\\bfitM": "\ud474", // 푴
  "\\bduvbh": "\u2534", // ┴
  "\\square": "\u25a1", // □
  "\\bfsanszeta": "\ud775", // 흵
  "\\bfsansnabla": "\ud76f", // 흯
  "\\textsubsquare": "\u033b", // ̻
  "\\pitchfork": "\u22d4", // ⋔
  "\\sanst": "\ud5cd", // 헍
  "\\Dashv": "\u2ae4", // ⫤
  "\\bffrakr": "\ud597", // 햗
  "\\bfitsanschi": "\ud7c0", // ퟀ
  "\\exists": "\u2203", // ∃
  "\\bftheta": "\ud6c9", // 훉
  "\\whitepointerleft": "\u25c5", // ◅
  "\\csub": "\u2acf", // ⫏
  "\\capwedge": "\u2a44", // ⩄
  "\\bfsansB": "\ud5d5", // 헕
  "\\simeq": "\u2243", // ≃
  "\\smallin": "\u220a", // ∊
  "\\textquestiondown": "\u00bf", // ¿
  "\\bfitvarTheta": "\ud72d", // 휭
  "\\ttp": "\ud699", // 횙
  "\\sansd": "\ud5bd", // 햽
  "\\diamondrightblack": "\u2b17", // ⬗
  "\\varcarriagereturn": "\u23ce", // ⏎
  "\\wedge": "\u2227", // ∧
  "\\sansL": "\ud5ab", // 햫
  "\\textbarglotstop": "\u02a1", // ʡ
  "\\Bbbpi": "\u213c", // ℼ
  "\\circledseven": "\u2466", // ⑦
  "\\cwgapcirclearrow": "\u27f3", // ⟳
  "\\rBrack": "\u27e7", // ⟧, 〛
  "\\rightharpoondownbar": "\u2957", // ⥗
  "\\gtreqqless": "\u2a8c", // ⪌
  "\\bfsansi": "\ud5f6", // 헶
  "\\vDdash": "\u2ae2", // ⫢
  "\\bot": "\u22a5", // ⊥
  "\\bddVlH": "\u2557", // ╗
  "\\upvarTheta": "\u03f4", // ϴ
  "\\itsansP": "\ud617", // 혗
  "\\textdoubleslash": "\u2afd", // ⫽
  "\\textcrinvglotstop": "\u01be", // ƾ
  "\\itBbbj": "\u2149", // ⅉ
  "\\rparenextender": "\u239f", // ⎟
  "\\textesh": "\u0283", // ʃ
  "\\egsdot": "\u2a98", // ⪘
  "\\obslash": "\u29b8", // ⦸
  "\\vysmblkcircle": "\u2219", // ∙
  "\\rightangle": "\u221f", // ∟
  "\\lrarc": "\u25de", // ◞
  "\\neovnwarrow": "\u2931", // ⤱
  "\\bfscrl": "\ud4f5", // 퓵
  "\\uplus": "\u228e", // ⊎
  "\\itsansX": "\ud61f", // 혟
  "\\BbbU": "\ud54c", // 핌
  "\\blackhourglass": "\u29d7", // ⧗
  "\\whitepointerright": "\u25bb", // ▻
  "\\subseven": "\u2087", // ₇
  "\\envelope": "\u2709", // ✉
  "\\ltcir": "\u2a79", // ⩹
  "\\triangleexclam": "\u26a0", // ⚠
  "\\rdiagovfdiag": "\u292b", // ⤫
  "\\eqqsim": "\u2a73", // ⩳
  "\\blockqtrshaded": "\u2591", // ░
  "\\circ": "\u2218", // ∘
  "\\textsuphth": "\u02b1", // ʱ
  "\\benzenr": "\u23e3", // ⏣
  "\\rightharpoonaccent": "\u20d1", // ⃑
  "\\APLnotslash": "\u233f", // ⌿
  "\\bbzero": "\ud7d8", // ퟘ
  "\\forall": "\u2200", // ∀
  "\\upsigma": "\u03c3", // σ
  "\\bfiota": "\ud6ca", // 훊
  "\\frakg": "\ud524", // 픤
  "\\bfitS": "\ud47a", // 푺
  "\\bfitUpsilon": "\ud730", // 휰
  "\\textscriptv": "\u028b", // ʋ
  "\\BbbM": "\ud544", // 필
  "\\subsetapprox": "\u2ac9", // ⫉
  "\\bfitl": "\ud48d", // 풍
  "\\itG": "\ud43a", // 퐺
  "\\enclosetriangle": "\u20e4", // ⃤
  "\\itj": "\ud457", // 푗
  "\\lBrace": "\u2983", // ⦃
  "\\textuptr": "\u02c4", // ˄
  "\\textrhalfring": "\u02d2", // ˒
  "\\LtoRmark": "\u200e", // ‎
  "\\boxbslash": "\u29c5", // ⧅
  "\\guillemotright": "\u00bb", // »
  "\\ttD": "\ud673", // 홳
  "\\ng": "\u014b", // ŋ
  "\\bfitO": "\ud476", // 푶
  "\\bfscrQ": "\ud4e0", // 퓠
  "\\textasterisklow": "\u204e", // ⁎
  "\\bffrakl": "\ud591", // 햑
  "\\ttU": "\ud684", // 횄
  "\\bfY": "\ud418", // 퐘
  "\\plussubtwo": "\u2a27", // ⨧
  "\\bfitsansZ": "\ud655", // 확
  "\\texthalflength": "\u02d1", // ˑ
  "\\blackcircledsansfive": "\u278e", // ➎
  "\\infty": "\u221e", // ∞
  "\\Bbbseven": "\ud7df", // ퟟ
  "\\textscg": "\u0262", // ɢ
  "\\upKoppa": "\u03de", // Ϟ
  "\\diamondtopblack": "\u2b18", // ⬘
  "\\widetilde": "\u0303", // ̃
  "\\overbracket": "\u23b4", // ⎴
  "\\frakr": "\ud52f", // 픯
  "\\frakz": "\ud537", // 픷
  "\\textlowered": "\u02d5", // ˕
  "\\bardownharpoonright": "\u295d", // ⥝
  "\\circledU": "\u24ca", // Ⓤ
  "\\sansC": "\ud5a2", // 햢
  "\\mdlgblklozenge": "\u29eb", // ⧫
  "\\uparrowbarred": "\u2909", // ⤉
  "\\bfitsansvarsigma": "\ud7bb", // ힻ
  "\\ttH": "\ud677", // 홷
  "\\textvibyi": "\u0285", // ʅ
  "\\bfsansq": "\ud5fe", // 헾
  "\\bumpeqq": "\u2aae", // ⪮
  "\\bfmu": "\ud6cd", // 훍
  "\\rbrack": "\u005d", // ]
  "\\suplparen": "\u207d", // ⁽
  "\\bfitsanskappa": "\ud7b3", // ힳ
  "\\backtrprime": "\u2037", // ‷
  "\\bfscrP": "\ud4df", // 퓟
  "\\barleftharpoondown": "\u2956", // ⥖
  "\\sansT": "\ud5b3", // 햳
  "\\scru": "\ud4ca", // 퓊
  "\\dot": "\u0307", // ̇
  "\\upmu": "\u03bc", // μ
  "\\ttt": "\ud69d", // 횝
  "\\nprec": "\u2280", // ⊀
  "\\ruledelayed": "\u29f4", // ⧴
  "\\textcrlambda": "\u019b", // ƛ
  "\\itp": "\ud45d", // 푝
  "\\succcurlyeq": "\u227d", // ≽
  "\\textsuph": "\u02b0", // ʰ
  "\\simgE": "\u2aa0", // ⪠
  "\\circledP": "\u24c5", // Ⓟ
  "\\texthtscg": "\u029b", // ʛ
  "\\bfsansN": "\ud5e1", // 헡
  "\\measuredrightangle": "\u22be", // ⊾
  "\\gescc": "\u2aa9", // ⪩
  "\\itvartheta": "\ud717", // 휗
  "\\lcurvyangle": "\u29fc", // ⧼
  "\\Bbbi": "\ud55a", // 핚
  "\\upkoppa": "\u03df", // ϟ
  "\\nvLeftrightarrow": "\u2904", // ⤄
  "\\gtrarr": "\u2978", // ⥸
  "\\isinobar": "\u22f7", // ⋷
  "\\Prec": "\u2abb", // ⪻
  "\\dottedsquare": "\u2b1a", // ⬚
  "\\bfiti": "\ud48a", // 풊
  "\\uhorn": "\u01b0", // ư
  "\\bfrho": "\ud6d2", // 훒
  "\\perp": "\u27c2", // ⟂
  "\\diamondleftarrowbar": "\u291f", // ⤟
  "\\errbarblackcircle": "\u29f3", // ⧳
  "\\textasciidieresis": "\u00a8", // ¨
  "\\itsansf": "\ud627", // 혧
  "\\blockuphalf": "\u2580", // ▀
  "\\c": "\u0327", // ̧
  "\\bfita": "\ud482", // 풂
  "\\textlhookk": "\u1d84", // ᶄ
  "\\eqcirc": "\u2256", // ≖
  "\\bfscrx": "\ud501", // 픁
  "\\equal": "\u003d", // =
  "\\Im": "\u2111", // ℑ
  "\\bigoplus": "\u2a01", // ⨁
  "\\bfitsanspsi": "\ud7c1", // ퟁ
  "''''": "\u2057", // ⁗
  "'n": "\u0149", // ŉ
  ",,": "\u201e", // „
  "..": "\u2025", // ‥
  ":=": "\u2254", // ≔
  "<kern-0.58em(": "\u2993", // ⦓
  "=:": "\u2255", // ≕
  IJ: "\u0132", // Ĳ
  "\\'{$\\alpha$}": "\u03ac", // ά
  "\\'{A}": "\u00c1", // Á
  "\\'{C}": "\u0106", // Ć
  "\\'{E}": "\u00c9", // É
  "\\'{H}": "\u0389", // Ή
  "\\'{I}": "\u00cd", // Í
  "\\'{L}": "\u0139", // Ĺ
  "\\'{N}": "\u0143", // Ń
  "\\'{O}": "\u00d3", // Ó
  "\\'{R}": "\u0154", // Ŕ
  "\\'{S}": "\u015a", // Ś
  "\\'{U}": "\u00da", // Ú
  "\\'{Y}": "\u00dd", // Ý
  "\\'{Z}": "\u0179", // Ź
  "\\'{\\i}": "\u00ed", // í
  "\\'{a}": "\u00e1", // á
  "\\'{c}": "\u0107", // ć
  "\\'{e}": "\u00e9", // é
  "\\'{g}": "\u01f5", // ǵ
  "\\'{l}": "\u013a", // ĺ
  "\\'{n}": "\u0144", // ń
  "\\'{o}": "\u00f3", // ó
  "\\'{r}": "\u0155", // ŕ
  "\\'{s}": "\u015b", // ś
  "\\'{u}": "\u00fa", // ú
  "\\'{y}": "\u00fd", // ý
  "\\'{z}": "\u017a", // ź
  "\\'A": "\u00c1", // Á
  "\\'C": "\u0106", // Ć
  "\\'E": "\u00c9", // É
  "\\'H": "\u0389", // Ή
  "\\'I": "\u00cd", // Í
  "\\'L": "\u0139", // Ĺ
  "\\'N": "\u0143", // Ń
  "\\'O": "\u00d3", // Ó
  "\\'R": "\u0154", // Ŕ
  "\\'S": "\u015a", // Ś
  "\\'U": "\u00da", // Ú
  "\\'Y": "\u00dd", // Ý
  "\\'Z": "\u0179", // Ź
  "\\'\\i": "\u00ed", // í
  "\\'a": "\u00e1", // á
  "\\'c": "\u0107", // ć
  "\\'e": "\u00e9", // é
  "\\'g": "\u01f5", // ǵ
  "\\'l": "\u013a", // ĺ
  "\\'n": "\u0144", // ń
  "\\'o": "\u00f3", // ó
  "\\'r": "\u0155", // ŕ
  "\\'s": "\u015b", // ś
  "\\'u": "\u00fa", // ú
  "\\'y": "\u00fd", // ý
  "\\'z": "\u017a", // ź
  "\\'{}O": "\u038c", // Ό
  "\\'{}{I}": "\u038a", // Ί
  "\\-": "\u00ad", // ­
  "\\.": "\u0307", // ̇
  "\\.{C}": "\u010a", // Ċ
  "\\.{E}": "\u0116", // Ė
  "\\.{G}": "\u0120", // Ġ
  "\\.{I}": "\u0130", // İ
  "\\.{Z}": "\u017b", // Ż
  "\\.{c}": "\u010b", // ċ
  "\\.{e}": "\u0117", // ė
  "\\.{g}": "\u0121", // ġ
  "\\.{z}": "\u017c", // ż
  "\\;": "\u2009-0200A-0200A", // -0200A-0200A
  "\\=": "\u0304", // ̄
  "\\={A}": "\u0100", // Ā
  "\\={E}": "\u0112", // Ē
  "\\={I}": "\u012a", // Ī
  "\\={O}": "\u014c", // Ō
  "\\={U}": "\u016a", // Ū
  "\\={\\i}": "\u012b", // ī
  "\\={a}": "\u0101", // ā
  "\\={e}": "\u0113", // ē
  "\\={o}": "\u014d", // ō
  "\\={u}": "\u016b", // ū
  "\\Alpha": "\u0391", // Α
  "\\Angle": "\u299c", // ⦜
  "\\Beta": "\u0392", // Β
  "\\Chi": "\u03a7", // Χ
  "\\Delta": "\u0394", // Δ
  "\\Digamma": "\u03dc", // Ϝ
  "\\DownArrowBar": "\u2913", // ⤓
  "\\DownArrowUpArrow": "\u21f5", // ⇵
  "\\DownLeftRightVector": "\u2950", // ⥐
  "\\DownLeftTeeVector": "\u295e", // ⥞
  "\\DownLeftVectorBar": "\u2956", // ⥖
  "\\DownRightTeeVector": "\u295f", // ⥟
  "\\DownRightVectorBar": "\u2957", // ⥗
  "\\ElOr": "\u2a56", // ⩖
  "\\Elolarr": "\u2940", // ⥀
  "\\Elorarr": "\u2941", // ⥁
  "\\Elroang": "\u2986", // ⦆
  "\\Elxsqcup": "\u2a06", // ⨆
  "\\Elxuplus": "\u2a04", // ⨄
  "\\ElzAnd": "\u2a53", // ⩓
  "\\ElzCint": "\u2a0d", // ⨍
  "\\ElzInf": "\u2a07", // ⨇
  "\\ElzLap": "\u29ca", // ⧊
  "\\ElzOr": "\u2a54", // ⩔
  "\\ElzRlarr": "\u2942", // ⥂
  "\\ElzSup": "\u2a08", // ⨈
  "\\ElzThr": "\u2a05", // ⨅
  "\\ElzTimes": "\u2a2f", // ⨯
  "\\Elzbar": "\u0336", // ̶
  "\\Elzbtdl": "\u026c", // ɬ
  "\\Elzcirfb": "\u25d2", // ◒
  "\\Elzcirfl": "\u25d0", // ◐
  "\\Elzcirfr": "\u25d1", // ◑
  "\\Elzclomeg": "\u0277", // ɷ
  "\\Elzddfnc": "\u2999", // ⦙
  "\\Elzdefas": "\u29cb", // ⧋
  "\\Elzdlcorn": "\u23a3", // ⎣
  "\\Elzdshfnc": "\u2506", // ┆
  "\\Elzdyogh": "\u02a4", // ʤ
  "\\Elzesh": "\u0283", // ʃ
  "\\Elzfhr": "\u027e", // ɾ
  "\\Elzglst": "\u0294", // ʔ
  "\\Elzhlmrk": "\u02d1", // ˑ
  "\\Elzinglst": "\u0296", // ʖ
  "\\Elzinvv": "\u028c", // ʌ
  "\\Elzinvw": "\u028d", // ʍ
  "\\Elzlmrk": "\u02d0", // ː
  "\\Elzlow": "\u02d5", // ˕
  "\\Elzlpargt": "\u29a0", // ⦠
  "\\Elzltlmr": "\u0271", // ɱ
  "\\Elzltln": "\u0272", // ɲ
  "\\Elzminhat": "\u2a5f", // ⩟
  "\\Elzopeno": "\u0254", // ɔ
  "\\Elzpalh": "\u0321", // ̡
  "\\Elzpbgam": "\u0264", // ɤ
  "\\Elzpgamma": "\u0263", // ɣ
  "\\Elzpscrv": "\u028b", // ʋ
  "\\Elzpupsil": "\u028a", // ʊ
  "\\ElzrLarr": "\u2944", // ⥄
  "\\Elzrais": "\u02d4", // ˔
  "\\Elzrarrx": "\u2947", // ⥇
  "\\Elzreapos": "\u201b", // ‛
  "\\Elzreglst": "\u0295", // ʕ
  "\\Elzrh": "\u0322", // ̢
  "\\Elzrl": "\u027c", // ɼ
  "\\Elzrtld": "\u0256", // ɖ
  "\\Elzrtll": "\u026d", // ɭ
  "\\Elzrtln": "\u0273", // ɳ
  "\\Elzrtlr": "\u027d", // ɽ
  "\\Elzrtls": "\u0282", // ʂ
  "\\Elzrtlt": "\u0288", // ʈ
  "\\Elzrtlz": "\u0290", // ʐ
  "\\Elzrttrnr": "\u027b", // ɻ
  "\\Elzrvbull": "\u25d8", // ◘
  "\\Elzsbbrg": "\u032a", // ̪
  "\\Elzsblhr": "\u02d3", // ˓
  "\\Elzsbrhr": "\u02d2", // ˒
  "\\Elzschwa": "\u0259", // ə
  "\\Elzsqfl": "\u25e7", // ◧
  "\\Elzsqfnw": "\u2519", // ┙
  "\\Elzsqfr": "\u25e8", // ◨
  "\\Elzsqfse": "\u25ea", // ◪
  "\\Elzsqspne": "\u22e5", // ⋥
  "\\Elztdcol": "\u2af6", // ⫶
  "\\Elztesh": "\u02a7", // ʧ
  "\\Elztfnc": "\u2980", // ⦀
  "\\Elztrna": "\u0250", // ɐ
  "\\Elztrnh": "\u0265", // ɥ
  "\\Elztrnm": "\u026f", // ɯ
  "\\Elztrnmlr": "\u0270", // ɰ
  "\\Elztrnr": "\u0279", // ɹ
  "\\Elztrnrl": "\u027a", // ɺ
  "\\Elztrnsa": "\u0252", // ɒ
  "\\Elztrnt": "\u0287", // ʇ
  "\\Elztrny": "\u028e", // ʎ
  "\\Elzverti": "\u02cc", // ˌ
  "\\Elzverts": "\u02c8", // ˈ
  "\\Elzvrecto": "\u25af", // ▯
  "\\Elzxh": "\u0127", // ħ
  "\\Elzxl": "\u0335", // ̵
  "\\Elzxrat": "\u211e", // ℞
  "\\Elzyogh": "\u0292", // ʒ
  "\\Epsilon": "\u0395", // Ε
  "\\Equal": "\u2a75", // ⩵
  "\\Eta": "\u0397", // Η
  "\\Gamma": "\u0393", // Γ
  "\\H{O}": "\u0150", // Ő
  "\\H{U}": "\u0170", // Ű
  "\\H{o}": "\u0151", // ő
  "\\H{u}": "\u0171", // ű
  "\\H{}": "\u02dd", // ˝
  "\\Iota": "\u0399", // Ι
  "\\Kappa": "\u039a", // Κ
  "\\Koppa": "\u03de", // Ϟ
  "\\Lambda": "\u039b", // Λ
  "\\LeftDownTeeVector": "\u2961", // ⥡
  "\\LeftDownVectorBar": "\u2959", // ⥙
  "\\LeftRightVector": "\u294e", // ⥎
  "\\LeftTeeVector": "\u295a", // ⥚
  "\\LeftTriangleBar": "\u29cf", // ⧏
  "\\LeftUpDownVector": "\u2951", // ⥑
  "\\LeftUpTeeVector": "\u2960", // ⥠
  "\\LeftUpVectorBar": "\u2958", // ⥘
  "\\LeftVectorBar": "\u2952", // ⥒
  "\\NestedGreaterGreater": "\u2aa2", // ⪢
  "\\NestedLessLess": "\u2aa1", // ⪡
  "\\NotEqualTilde": "\u2242", // ≂-00338
  "\\NotGreaterGreater": "\u226b", // ≫-00338
  "\\NotHumpDownHump": "\u224e", // ≎-00338
  "\\NotHumpEqual": "\u224f", // ≏-00338
  "\\NotLeftTriangleBar": "\u29cf", // ⧏-00338
  "\\NotLessLess": "\u226a", // ≪-00338
  "\\NotNestedGreaterGreater": "\u2aa2", // ⪢-00338
  "\\NotNestedLessLess": "\u2aa1", // ⪡-00338
  "\\NotPrecedesTilde": "\u227e", // ≾-00338
  "\\NotRightTriangleBar": "\u29d0", // ⧐-00338
  "\\NotSquareSubset": "\u228f", // ⊏-00338
  "\\NotSquareSuperset": "\u2290", // ⊐-00338
  "\\NotSucceedsTilde": "\u227f", // ≿-00338
  "\\Omega": "\u03a9", // Ω
  "\\Phi": "\u03a6", // Φ
  "\\Pi": "\u03a0", // Π
  "\\Pisymbol{ppi020}{105}": "\u2a9e", // ⪞
  "\\Pisymbol{ppi020}{117}": "\u2a9d", // ⪝
  "\\Pisymbol{ppi022}{87}": "\u03d0", // ϐ
  "\\Psi": "\u03a8", // Ψ
  "\\ReverseUpEquilibrium": "\u296f", // ⥯
  "\\Rho": "\u03a1", // Ρ
  "\\RightDownTeeVector": "\u295d", // ⥝
  "\\RightDownVectorBar": "\u2955", // ⥕
  "\\RightTeeVector": "\u295b", // ⥛
  "\\RightTriangleBar": "\u29d0", // ⧐
  "\\RightUpDownVector": "\u294f", // ⥏
  "\\RightUpTeeVector": "\u295c", // ⥜
  "\\RightUpVectorBar": "\u2954", // ⥔
  "\\RightVectorBar": "\u2953", // ⥓
  "\\RoundImplies": "\u2970", // ⥰
  "\\RuleDelayed": "\u29f4", // ⧴
  "\\Sampi": "\u03e0", // Ϡ
  "\\Sigma": "\u03a3", // Σ
  "\\Stigma": "\u03da", // Ϛ
  "\\Tau": "\u03a4", // Τ
  "\\Theta": "\u0398", // Θ
  "\\UpArrowBar": "\u2912", // ⤒
  "\\UpEquilibrium": "\u296e", // ⥮
  "\\Upsilon": "\u03a5", // Υ
  "\\Xi": "\u039e", // Ξ
  "\\Zeta": "\u0396", // Ζ
  '\\"': "\u0308", //  ̈
  '\\"{A}': "\u00c4", // Ä
  '\\"{E}': "\u00cb", // Ë
  '\\"{I}': "\u00cf", // Ï
  '\\"{O}': "\u00d6", // Ö
  '\\"{U}': "\u00dc", // Ü
  '\\"{Y}': "\u0178", // Ÿ
  '\\"{\\i}': "\u00ef", // ï
  '\\"{a}': "\u00e4", // ä
  '\\"{e}': "\u00eb", // ë
  '\\"{o}': "\u00f6", // ö
  '\\"{u}': "\u00fc", // ü
  '\\"{y}': "\u00ff", // ÿ
  "\\^{A}": "\u00c2", // Â
  "\\^{C}": "\u0108", // Ĉ
  "\\^{E}": "\u00ca", // Ê
  "\\^{G}": "\u011c", // Ĝ
  "\\^{H}": "\u0124", // Ĥ
  "\\^{I}": "\u00ce", // Î
  "\\^{J}": "\u0134", // Ĵ
  "\\^{O}": "\u00d4", // Ô
  "\\^{S}": "\u015c", // Ŝ
  "\\^{U}": "\u00db", // Û
  "\\^{W}": "\u0174", // Ŵ
  "\\^{Y}": "\u0176", // Ŷ
  "\\^{\\i}": "\u00ee", // î
  "\\^{\\j}": "\u0135", // ĵ
  "\\^{a}": "\u00e2", // â
  "\\^{c}": "\u0109", // ĉ
  "\\^{e}": "\u00ea", // ê
  "\\^{g}": "\u011d", // ĝ
  "\\^{h}": "\u0125", // ĥ
  "\\^{o}": "\u00f4", // ô
  "\\^{s}": "\u015d", // ŝ
  "\\^{u}": "\u00fb", // û
  "\\^{w}": "\u0175", // ŵ
  "\\^{y}": "\u0177", // ŷ
  '\\"A': "\u00c4", // Ä
  '\\"E': "\u00cb", // Ë
  '\\"I': "\u00cf", // Ï
  '\\"O': "\u00d6", // Ö
  '\\"U': "\u00dc", // Ü
  '\\"Y': "\u0178", // Ÿ
  '\\"\\i': "\u00ef", // ï
  '\\"a': "\u00e4", // ä
  '\\"e': "\u00eb", // ë
  '\\"o': "\u00f6", // ö
  '\\"u': "\u00fc", // ü
  '\\"y': "\u00ff", // ÿ
  "\\^A": "\u00c2", // Â
  "\\^C": "\u0108", // Ĉ
  "\\^E": "\u00ca", // Ê
  "\\^G": "\u011c", // Ĝ
  "\\^H": "\u0124", // Ĥ
  "\\^I": "\u00ce", // Î
  "\\^J": "\u0134", // Ĵ
  "\\^O": "\u00d4", // Ô
  "\\^S": "\u015c", // Ŝ
  "\\^U": "\u00db", // Û
  "\\^W": "\u0174", // Ŵ
  "\\^Y": "\u0176", // Ŷ
  "\\^\\i": "\u00ee", // î
  "\\^\\j": "\u0135", // ĵ
  "\\^a": "\u00e2", // â
  "\\^c": "\u0109", // ĉ
  "\\^e": "\u00ea", // ê
  "\\^g": "\u011d", // ĝ
  "\\^h": "\u0125", // ĥ
  "\\^o": "\u00f4", // ô
  "\\^s": "\u015d", // ŝ
  "\\^u": "\u00fb", // û
  "\\^w": "\u0175", // ŵ
  "\\^y": "\u0177", // ŷ
  "\\^{}": "\u005e", // ^
  "\\_": "\u005f", // _
  "\\`": "\u0300", // ̀
  "\\`{A}": "\u00c0", // À
  "\\`{E}": "\u00c8", // È
  "\\`{I}": "\u00cc", // Ì
  "\\`{O}": "\u00d2", // Ò
  "\\`{U}": "\u00d9", // Ù
  "\\`{\\i}": "\u00ec", // ì
  "\\`{a}": "\u00e0", // à
  "\\`{e}": "\u00e8", // è
  "\\`{o}": "\u00f2", // ò
  "\\`{u}": "\u00f9", // ù
  "\\acute{\\ddot{\\iota}}": "\u0390", // ΐ
  "\\acute{\\ddot{\\upsilon}}": "\u03b0", // ΰ
  "\\acute{\\epsilon}": "\u03ad", // έ
  "\\acute{\\eta}": "\u03ae", // ή
  "\\acute{\\iota}": "\u03af", // ί
  "\\acute{\\omega}": "\u03ce", // ώ
  "\\acute{\\upsilon}": "\u03cd", // ύ
  "\\allequal": "\u224c", // ≌
  "\\alpha": "\u03b1", // α
  "\\approxnotequal": "\u2246", // ≆
  "\\aquarius": "\u2652", // ♒
  "\\arrowwaveright": "\u219c", // ↜
  "\\backepsilon": "\u03f6", // ϶
  "\\beta": "\u03b2", // β
  "\\cancer": "\u264b", // ♋
  "\\capricornus": "\u2651", // ♑
  "\\chi": "\u03c7", // χ
  "\\circlearrowleft": "\u21ba", // ↺
  "\\circlearrowright": "\u21bb", // ↻
  "\\clockoint": "\u2a0f", // ⨏
  "\\clwintegral": "\u2231", // ∱
  "\\cyrchar\\C": "\u030f", // ̏
  "\\cyrchar\\CYRA": "\u0410", // А
  "\\cyrchar\\CYRABHCH": "\u04bc", // Ҽ
  "\\cyrchar\\CYRABHCHDSC": "\u04be", // Ҿ
  "\\cyrchar\\CYRABHDZE": "\u04e0", // Ӡ
  "\\cyrchar\\CYRABHHA": "\u04a8", // Ҩ
  "\\cyrchar\\CYRAE": "\u04d4", // Ӕ
  "\\cyrchar\\CYRB": "\u0411", // Б
  "\\cyrchar\\CYRBYUS": "\u046a", // Ѫ
  "\\cyrchar\\CYRC": "\u0426", // Ц
  "\\cyrchar\\CYRCH": "\u0427", // Ч
  "\\cyrchar\\CYRCHLDSC": "\u04cb", // Ӌ
  "\\cyrchar\\CYRCHRDSC": "\u04b6", // Ҷ
  "\\cyrchar\\CYRCHVCRS": "\u04b8", // Ҹ
  "\\cyrchar\\CYRD": "\u0414", // Д
  "\\cyrchar\\CYRDJE": "\u0402", // Ђ
  "\\cyrchar\\CYRDZE": "\u0405", // Ѕ
  "\\cyrchar\\CYRDZHE": "\u040f", // Џ
  "\\cyrchar\\CYRE": "\u0415", // Е
  "\\cyrchar\\CYREREV": "\u042d", // Э
  "\\cyrchar\\CYRERY": "\u042b", // Ы
  "\\cyrchar\\CYRF": "\u0424", // Ф
  "\\cyrchar\\CYRFITA": "\u0472", // Ѳ
  "\\cyrchar\\CYRG": "\u0413", // Г
  "\\cyrchar\\CYRGHCRS": "\u0492", // Ғ
  "\\cyrchar\\CYRGHK": "\u0494", // Ҕ
  "\\cyrchar\\CYRGUP": "\u0490", // Ґ
  "\\cyrchar\\CYRH": "\u0425", // Х
  "\\cyrchar\\CYRHDSC": "\u04b2", // Ҳ
  "\\cyrchar\\CYRHRDSN": "\u042a", // Ъ
  "\\cyrchar\\CYRI": "\u0418", // И
  "\\cyrchar\\CYRIE": "\u0404", // Є
  "\\cyrchar\\CYRII": "\u0406", // І
  "\\cyrchar\\CYRIOTBYUS": "\u046c", // Ѭ
  "\\cyrchar\\CYRIOTE": "\u0464", // Ѥ
  "\\cyrchar\\CYRIOTLYUS": "\u0468", // Ѩ
  "\\cyrchar\\CYRISHRT": "\u0419", // Й
  "\\cyrchar\\CYRIZH": "\u0474", // Ѵ
  "\\cyrchar\\CYRJE": "\u0408", // Ј
  "\\cyrchar\\CYRK": "\u041a", // К
  "\\cyrchar\\CYRKBEAK": "\u04a0", // Ҡ
  "\\cyrchar\\CYRKDSC": "\u049a", // Қ
  "\\cyrchar\\CYRKHCRS": "\u049e", // Ҟ
  "\\cyrchar\\CYRKHK": "\u04c3", // Ӄ
  "\\cyrchar\\CYRKOPPA": "\u0480", // Ҁ
  "\\cyrchar\\CYRKSI": "\u046e", // Ѯ
  "\\cyrchar\\CYRKVCRS": "\u049c", // Ҝ
  "\\cyrchar\\CYRL": "\u041b", // Л
  "\\cyrchar\\CYRLJE": "\u0409", // Љ
  "\\cyrchar\\CYRLYUS": "\u0466", // Ѧ
  "\\cyrchar\\CYRM": "\u041c", // М
  "\\cyrchar\\CYRN": "\u041d", // Н
  "\\cyrchar\\CYRNDSC": "\u04a2", // Ң
  "\\cyrchar\\CYRNG": "\u04a4", // Ҥ
  "\\cyrchar\\CYRNHK": "\u04c7", // Ӈ
  "\\cyrchar\\CYRNJE": "\u040a", // Њ
  "\\cyrchar\\CYRO": "\u041e", // О
  "\\cyrchar\\CYROMEGA": "\u0460", // Ѡ
  "\\cyrchar\\CYROMEGARND": "\u047a", // Ѻ
  "\\cyrchar\\CYROMEGATITLO": "\u047c", // Ѽ
  "\\cyrchar\\CYROT": "\u047e", // Ѿ
  "\\cyrchar\\CYROTLD": "\u04e8", // Ө
  "\\cyrchar\\CYRP": "\u041f", // П
  "\\cyrchar\\CYRPHK": "\u04a6", // Ҧ
  "\\cyrchar\\CYRPSI": "\u0470", // Ѱ
  "\\cyrchar\\CYRR": "\u0420", // Р
  "\\cyrchar\\CYRRTICK": "\u048e", // Ҏ
  "\\cyrchar\\CYRS": "\u0421", // С
  "\\cyrchar\\CYRSCHWA": "\u04d8", // Ә
  "\\cyrchar\\CYRSDSC": "\u04aa", // Ҫ
  "\\cyrchar\\CYRSEMISFTSN": "\u048c", // Ҍ
  "\\cyrchar\\CYRSFTSN": "\u042c", // Ь
  "\\cyrchar\\CYRSH": "\u0428", // Ш
  "\\cyrchar\\CYRSHCH": "\u0429", // Щ
  "\\cyrchar\\CYRSHHA": "\u04ba", // Һ
  "\\cyrchar\\CYRT": "\u0422", // Т
  "\\cyrchar\\CYRTDSC": "\u04ac", // Ҭ
  "\\cyrchar\\CYRTETSE": "\u04b4", // Ҵ
  "\\cyrchar\\CYRTSHE": "\u040b", // Ћ
  "\\cyrchar\\CYRU": "\u0423", // У
  "\\cyrchar\\CYRUK": "\u0478", // Ѹ
  "\\cyrchar\\CYRUSHRT": "\u040e", // Ў
  "\\cyrchar\\CYRV": "\u0412", // В
  "\\cyrchar\\CYRY": "\u04ae", // Ү
  "\\cyrchar\\CYRYA": "\u042f", // Я
  "\\cyrchar\\CYRYAT": "\u0462", // Ѣ
  "\\cyrchar\\CYRYHCRS": "\u04b0", // Ұ
  "\\cyrchar\\CYRYI": "\u0407", // Ї
  "\\cyrchar\\CYRYO": "\u0401", // Ё
  "\\cyrchar\\CYRYU": "\u042e", // Ю
  "\\cyrchar\\CYRZ": "\u0417", // З
  "\\cyrchar\\CYRZDSC": "\u0498", // Ҙ
  "\\cyrchar\\CYRZH": "\u0416", // Ж
  "\\cyrchar\\CYRZHDSC": "\u0496", // Җ
  "\\cyrchar\\CYRpalochka": "\u04c0", // Ӏ
  "\\cyrchar\\cyra": "\u0430", // а
  "\\cyrchar\\cyrabhch": "\u04bd", // ҽ
  "\\cyrchar\\cyrabhchdsc": "\u04bf", // ҿ
  "\\cyrchar\\cyrabhdze": "\u04e1", // ӡ
  "\\cyrchar\\cyrabhha": "\u04a9", // ҩ
  "\\cyrchar\\cyrae": "\u04d5", // ӕ
  "\\cyrchar\\cyrb": "\u0431", // б
  "\\cyrchar\\cyrc": "\u0446", // ц
  "\\cyrchar\\cyrch": "\u0447", // ч
  "\\cyrchar\\cyrchldsc": "\u04cc", // ӌ
  "\\cyrchar\\cyrchrdsc": "\u04b7", // ҷ
  "\\cyrchar\\cyrchvcrs": "\u04b9", // ҹ
  "\\cyrchar\\cyrd": "\u0434", // д
  "\\cyrchar\\cyrdje": "\u0452", // ђ
  "\\cyrchar\\cyrdze": "\u0455", // ѕ
  "\\cyrchar\\cyrdzhe": "\u045f", // џ
  "\\cyrchar\\cyre": "\u0435", // е
  "\\cyrchar\\cyrerev": "\u044d", // э
  "\\cyrchar\\cyrery": "\u044b", // ы
  "\\cyrchar\\cyrf": "\u0444", // ф
  "\\cyrchar\\cyrg": "\u0433", // г
  "\\cyrchar\\cyrghcrs": "\u0493", // ғ
  "\\cyrchar\\cyrghk": "\u0495", // ҕ
  "\\cyrchar\\cyrgup": "\u0491", // ґ
  "\\cyrchar\\cyrh": "\u0445", // х
  "\\cyrchar\\cyrhdsc": "\u04b3", // ҳ
  "\\cyrchar\\cyrhrdsn": "\u044a", // ъ
  "\\cyrchar\\cyrhundredthousands": "\u0488", // ҈
  "\\cyrchar\\cyri": "\u0438", // и
  "\\cyrchar\\cyrie": "\u0454", // є
  "\\cyrchar\\cyrii": "\u0456", // і
  "\\cyrchar\\cyriotbyus": "\u046d", // ѭ
  "\\cyrchar\\cyriote": "\u0465", // ѥ
  "\\cyrchar\\cyriotlyus": "\u0469", // ѩ
  "\\cyrchar\\cyrishrt": "\u0439", // й
  "\\cyrchar\\cyrje": "\u0458", // ј
  "\\cyrchar\\cyrk": "\u043a", // к
  "\\cyrchar\\cyrkbeak": "\u04a1", // ҡ
  "\\cyrchar\\cyrkdsc": "\u049b", // қ
  "\\cyrchar\\cyrkhcrs": "\u049f", // ҟ
  "\\cyrchar\\cyrkhk": "\u04c4", // ӄ
  "\\cyrchar\\cyrkoppa": "\u0481", // ҁ
  "\\cyrchar\\cyrksi": "\u046f", // ѯ
  "\\cyrchar\\cyrkvcrs": "\u049d", // ҝ
  "\\cyrchar\\cyrl": "\u043b", // л
  "\\cyrchar\\cyrlje": "\u0459", // љ
  "\\cyrchar\\cyrlyus": "\u0467", // ѧ
  "\\cyrchar\\cyrm": "\u043c", // м
  "\\cyrchar\\cyrmillions": "\u0489", // ҉
  "\\cyrchar\\cyrn": "\u043d", // н
  "\\cyrchar\\cyrndsc": "\u04a3", // ң
  "\\cyrchar\\cyrng": "\u04a5", // ҥ
  "\\cyrchar\\cyrnhk": "\u04c8", // ӈ
  "\\cyrchar\\cyrnje": "\u045a", // њ
  "\\cyrchar\\cyro": "\u043e", // о
  "\\cyrchar\\cyromega": "\u0461", // ѡ
  "\\cyrchar\\cyromegarnd": "\u047b", // ѻ
  "\\cyrchar\\cyromegatitlo": "\u047d", // ѽ
  "\\cyrchar\\cyrot": "\u047f", // ѿ
  "\\cyrchar\\cyrotld": "\u04e9", // ө
  "\\cyrchar\\cyrp": "\u043f", // п
  "\\cyrchar\\cyrphk": "\u04a7", // ҧ
  "\\cyrchar\\cyrpsi": "\u0471", // ѱ
  "\\cyrchar\\cyrr": "\u0440", // р
  "\\cyrchar\\cyrrtick": "\u048f", // ҏ
  "\\cyrchar\\cyrs": "\u0441", // с
  "\\cyrchar\\cyrschwa": "\u04d9", // ә
  "\\cyrchar\\cyrsdsc": "\u04ab", // ҫ
  "\\cyrchar\\cyrsemisftsn": "\u048d", // ҍ
  "\\cyrchar\\cyrsftsn": "\u044c", // ь
  "\\cyrchar\\cyrsh": "\u0448", // ш
  "\\cyrchar\\cyrshch": "\u0449", // щ
  "\\cyrchar\\cyrshha": "\u04bb", // һ
  "\\cyrchar\\cyrt": "\u0442", // т
  "\\cyrchar\\cyrtdsc": "\u04ad", // ҭ
  "\\cyrchar\\cyrtetse": "\u04b5", // ҵ
  "\\cyrchar\\cyrthousands": "\u0482", // ҂
  "\\cyrchar\\cyrtshe": "\u045b", // ћ
  "\\cyrchar\\cyru": "\u0443", // у
  "\\cyrchar\\cyruk": "\u0479", // ѹ
  "\\cyrchar\\cyrushrt": "\u045e", // ў
  "\\cyrchar\\cyrv": "\u0432", // в
  "\\cyrchar\\cyry": "\u04af", // ү
  "\\cyrchar\\cyrya": "\u044f", // я
  "\\cyrchar\\cyryhcrs": "\u04b1", // ұ
  "\\cyrchar\\cyryi": "\u0457", // ї
  "\\cyrchar\\cyryo": "\u0451", // ё
  "\\cyrchar\\cyryu": "\u044e", // ю
  "\\cyrchar\\cyrz": "\u0437", // з
  "\\cyrchar\\cyrzdsc": "\u0499", // ҙ
  "\\cyrchar\\cyrzh": "\u0436", // ж
  "\\cyrchar\\cyrzhdsc": "\u0497", // җ
  "\\cyrchar\\textnumero": "\u2116", // №
  "\\cyrchar{\\'\\CYRG}": "\u0403", // Ѓ
  "\\cyrchar{\\'\\CYRK}": "\u040c", // Ќ
  "\\cyrchar{\\'\\cyrg}": "\u0453", // ѓ
  "\\cyrchar{\\'\\cyrk}": "\u045c", // ќ
  "\\c{C}": "\u00c7", // Ç
  "\\c{G}": "\u0122", // Ģ
  "\\c{K}": "\u0136", // Ķ
  "\\c{L}": "\u013b", // Ļ
  "\\c{N}": "\u0145", // Ņ
  "\\c{R}": "\u0156", // Ŗ
  "\\c{S}": "\u015e", // Ş
  "\\c{T}": "\u0162", // Ţ
  "\\c{c}": "\u00e7", // ç
  "\\c{g}": "\u0123", // ģ
  "\\c{k}": "\u0137", // ķ
  "\\c{l}": "\u013c", // ļ
  "\\c{n}": "\u0146", // ņ
  "\\c{r}": "\u0157", // ŗ
  "\\c{s}": "\u015f", // ş
  "\\c{t}": "\u0163", // ţ
  "\\c{}": "\u00b8", // ¸
  "\\dblarrowupdown": "\u21c5", // ⇅
  "\\ddot{\\iota}": "\u03ca", // ϊ
  "\\ddot{\\upsilon}": "\u03cb", // ϋ
  "\\delta": "\u03b4", // δ
  "\\diagup": "\u2571", // ╱
  "\\digamma": "\u03dd", // ϝ
  "\\ding{100}": "\u2744", // ❄
  "\\ding{101}": "\u2745", // ❅
  "\\ding{102}": "\u2746", // ❆
  "\\ding{103}": "\u2747", // ❇
  "\\ding{104}": "\u2748", // ❈
  "\\ding{105}": "\u2749", // ❉
  "\\ding{106}": "\u274a", // ❊
  "\\ding{107}": "\u274b", // ❋
  "\\ding{108}": "\u25cf", // ●
  "\\ding{109}": "\u274d", // ❍
  "\\ding{110}": "\u25a0", // ■
  "\\ding{111}": "\u274f", // ❏
  "\\ding{112}": "\u2750", // ❐
  "\\ding{113}": "\u2751", // ❑
  "\\ding{114}": "\u2752", // ❒
  "\\ding{115}": "\u25b2", // ▲
  "\\ding{116}": "\u25bc", // ▼
  "\\ding{117}": "\u25c6", // ◆
  "\\ding{118}": "\u2756", // ❖
  "\\ding{119}": "\u25d7", // ◗
  "\\ding{120}": "\u2758", // ❘
  "\\ding{121}": "\u2759", // ❙
  "\\ding{122}": "\u275a", // ❚
  "\\ding{123}": "\u275b", // ❛
  "\\ding{124}": "\u275c", // ❜
  "\\ding{125}": "\u275d", // ❝
  "\\ding{126}": "\u275e", // ❞
  "\\ding{161}": "\u2761", // ❡
  "\\ding{162}": "\u2762", // ❢
  "\\ding{163}": "\u2763", // ❣
  "\\ding{164}": "\u2764", // ❤
  "\\ding{165}": "\u2765", // ❥
  "\\ding{166}": "\u2766", // ❦
  "\\ding{167}": "\u2767", // ❧
  "\\ding{168}": "\u2663", // ♣
  "\\ding{169}": "\u2666", // ♦
  "\\ding{170}": "\u2665", // ♥
  "\\ding{171}": "\u2660", // ♠
  "\\ding{172}": "\u2460", // ①
  "\\ding{173}": "\u2461", // ②
  "\\ding{174}": "\u2462", // ③
  "\\ding{175}": "\u2463", // ④
  "\\ding{176}": "\u2464", // ⑤
  "\\ding{177}": "\u2465", // ⑥
  "\\ding{178}": "\u2466", // ⑦
  "\\ding{179}": "\u2467", // ⑧
  "\\ding{180}": "\u2468", // ⑨
  "\\ding{181}": "\u2469", // ⑩
  "\\ding{182}": "\u2776", // ❶
  "\\ding{183}": "\u2777", // ❷
  "\\ding{184}": "\u2778", // ❸
  "\\ding{185}": "\u2779", // ❹
  "\\ding{186}": "\u277a", // ❺
  "\\ding{187}": "\u277b", // ❻
  "\\ding{188}": "\u277c", // ❼
  "\\ding{189}": "\u277d", // ❽
  "\\ding{190}": "\u277e", // ❾
  "\\ding{191}": "\u277f", // ❿
  "\\ding{192}": "\u2780", // ➀
  "\\ding{193}": "\u2781", // ➁
  "\\ding{194}": "\u2782", // ➂
  "\\ding{195}": "\u2783", // ➃
  "\\ding{196}": "\u2784", // ➄
  "\\ding{197}": "\u2785", // ➅
  "\\ding{198}": "\u2786", // ➆
  "\\ding{199}": "\u2787", // ➇
  "\\ding{200}": "\u2788", // ➈
  "\\ding{201}": "\u2789", // ➉
  "\\ding{202}": "\u278a", // ➊
  "\\ding{203}": "\u278b", // ➋
  "\\ding{204}": "\u278c", // ➌
  "\\ding{205}": "\u278d", // ➍
  "\\ding{206}": "\u278e", // ➎
  "\\ding{207}": "\u278f", // ➏
  "\\ding{208}": "\u2790", // ➐
  "\\ding{209}": "\u2791", // ➑
  "\\ding{210}": "\u2792", // ➒
  "\\ding{211}": "\u2793", // ➓
  "\\ding{212}": "\u2794", // ➔
  "\\ding{216}": "\u2798", // ➘
  "\\ding{217}": "\u2799", // ➙
  "\\ding{218}": "\u279a", // ➚
  "\\ding{219}": "\u279b", // ➛
  "\\ding{220}": "\u279c", // ➜
  "\\ding{221}": "\u279d", // ➝
  "\\ding{222}": "\u279e", // ➞
  "\\ding{223}": "\u279f", // ➟
  "\\ding{224}": "\u27a0", // ➠
  "\\ding{225}": "\u27a1", // ➡
  "\\ding{226}": "\u27a2", // ➢
  "\\ding{227}": "\u27a3", // ➣
  "\\ding{228}": "\u27a4", // ➤
  "\\ding{229}": "\u27a5", // ➥
  "\\ding{230}": "\u27a6", // ➦
  "\\ding{231}": "\u27a7", // ➧
  "\\ding{232}": "\u27a8", // ➨
  "\\ding{233}": "\u27a9", // ➩
  "\\ding{234}": "\u27aa", // ➪
  "\\ding{235}": "\u27ab", // ➫
  "\\ding{236}": "\u27ac", // ➬
  "\\ding{237}": "\u27ad", // ➭
  "\\ding{238}": "\u27ae", // ➮
  "\\ding{239}": "\u27af", // ➯
  "\\ding{241}": "\u27b1", // ➱
  "\\ding{242}": "\u27b2", // ➲
  "\\ding{243}": "\u27b3", // ➳
  "\\ding{244}": "\u27b4", // ➴
  "\\ding{245}": "\u27b5", // ➵
  "\\ding{246}": "\u27b6", // ➶
  "\\ding{247}": "\u27b7", // ➷
  "\\ding{248}": "\u27b8", // ➸
  "\\ding{249}": "\u27b9", // ➹
  "\\ding{250}": "\u27ba", // ➺
  "\\ding{251}": "\u27bb", // ➻
  "\\ding{252}": "\u27bc", // ➼
  "\\ding{253}": "\u27bd", // ➽
  "\\ding{254}": "\u27be", // ➾
  "\\ding{33}": "\u2701", // ✁
  "\\ding{34}": "\u2702", // ✂
  "\\ding{35}": "\u2703", // ✃
  "\\ding{36}": "\u2704", // ✄
  "\\ding{37}": "\u260e", // ☎
  "\\ding{38}": "\u2706", // ✆
  "\\ding{39}": "\u2707", // ✇
  "\\ding{40}": "\u2708", // ✈
  "\\ding{41}": "\u2709", // ✉
  "\\ding{42}": "\u261b", // ☛
  "\\ding{43}": "\u261e", // ☞
  "\\ding{44}": "\u270c", // ✌
  "\\ding{45}": "\u270d", // ✍
  "\\ding{46}": "\u270e", // ✎
  "\\ding{47}": "\u270f", // ✏
  "\\ding{48}": "\u2710", // ✐
  "\\ding{49}": "\u2711", // ✑
  "\\ding{50}": "\u2712", // ✒
  "\\ding{51}": "\u2713", // ✓
  "\\ding{52}": "\u2714", // ✔
  "\\ding{53}": "\u2715", // ✕
  "\\ding{54}": "\u2716", // ✖
  "\\ding{55}": "\u2717", // ✗
  "\\ding{56}": "\u2718", // ✘
  "\\ding{57}": "\u2719", // ✙
  "\\ding{58}": "\u271a", // ✚
  "\\ding{59}": "\u271b", // ✛
  "\\ding{60}": "\u271c", // ✜
  "\\ding{61}": "\u271d", // ✝
  "\\ding{62}": "\u271e", // ✞
  "\\ding{63}": "\u271f", // ✟
  "\\ding{64}": "\u2720", // ✠
  "\\ding{65}": "\u2721", // ✡
  "\\ding{66}": "\u2722", // ✢
  "\\ding{67}": "\u2723", // ✣
  "\\ding{68}": "\u2724", // ✤
  "\\ding{69}": "\u2725", // ✥
  "\\ding{70}": "\u2726", // ✦
  "\\ding{71}": "\u2727", // ✧
  "\\ding{72}": "\u2605", // ★
  "\\ding{73}": "\u2729", // ✩
  "\\ding{74}": "\u272a", // ✪
  "\\ding{75}": "\u272b", // ✫
  "\\ding{76}": "\u272c", // ✬
  "\\ding{77}": "\u272d", // ✭
  "\\ding{78}": "\u272e", // ✮
  "\\ding{79}": "\u272f", // ✯
  "\\ding{80}": "\u2730", // ✰
  "\\ding{81}": "\u2731", // ✱
  "\\ding{82}": "\u2732", // ✲
  "\\ding{83}": "\u2733", // ✳
  "\\ding{84}": "\u2734", // ✴
  "\\ding{85}": "\u2735", // ✵
  "\\ding{86}": "\u2736", // ✶
  "\\ding{87}": "\u2737", // ✷
  "\\ding{88}": "\u2738", // ✸
  "\\ding{89}": "\u2739", // ✹
  "\\ding{90}": "\u273a", // ✺
  "\\ding{91}": "\u273b", // ✻
  "\\ding{92}": "\u273c", // ✼
  "\\ding{93}": "\u273d", // ✽
  "\\ding{94}": "\u273e", // ✾
  "\\ding{95}": "\u273f", // ✿
  "\\ding{96}": "\u2740", // ❀
  "\\ding{97}": "\u2741", // ❁
  "\\ding{98}": "\u2742", // ❂
  "\\ding{99}": "\u2743", // ❃
  "\\doteqdot": "\u2251", // ≑
  "\\downslopeellipsis": "\u22f1", // ⋱
  "\\ensuremath{\\Elzpes}": "\u20a7", // ₧
  "\\epsilon": "\u03b5", // ε
  "\\estimates": "\u2259", // ≙
  "\\eta": "\u03b7", // η
  "\\eth": "\u01aa", // ƪ
  "\\fbox{~~}": "\u25ad", // ▭
  "\\forcesextra": "\u22a8", // ⊨
  "\\gamma": "\u03b3", // γ
  "\\gemini": "\u264a", // ♊
  "\\greaterequivlnt": "\u2273", // ≳
  "\\gvertneqq": "\u2269", // ≩-0FE00
  "\\hermitconjmatrix": "\u22b9", // ⊹
  "\\homothetic": "\u223b", // ∻
  "\\hphantom{,}": "\u2008", // no symbol
  "\\hphantom{0}": "\u2007", // no symbol
  "\\hspace{0.166em}": "\u2006", // no symbol
  "\\hspace{0.167em}": "\u2009", // no symbol
  "\\hspace{0.25em}": "\u2005", // no symbol
  "\\hspace{0.33em}": "\u2004", // no symbol
  "\\hspace{0.6em}": "\u2002", // no symbol
  "\\hspace{1em}": "\u2003", // no symbol
  "\\image": "\u22b7", // ⊷
  "\\int\\!\\int": "\u222c", // ∬
  "\\int\\!\\int\\!\\int": "\u222d", // ∭
  "\\iota": "\u03b9", // ι
  "\\kappa": "\u03ba", // κ
  "\\k{A}": "\u0104", // Ą
  "\\k{E}": "\u0118", // Ę
  "\\k{I}": "\u012e", // Į
  "\\k{U}": "\u0172", // Ų
  "\\k{a}": "\u0105", // ą
  "\\k{e}": "\u0119", // ę
  "\\k{i}": "\u012f", // į
  "\\k{u}": "\u0173", // ų
  "\\k{}": "\u02db", // ˛
  "\\lambda": "\u03bb", // λ
  "\\lazysinv": "\u223e", // ∾
  "\\ldots": "\u2026", // …
  "\\leo": "\u264c", // ♌
  "\\lessequivlnt": "\u2272", // ≲
  "\\libra": "\u264e", // ♎
  "\\lnot": "\u00ac", // ¬
  "\\lvertneqq": "\u2268", // ≨-0FE00
  "\\mathbb{0}": "\ud7d8", // ퟘ
  "\\mathbb{1}": "\ud7d9", // ퟙ
  "\\mathbb{2}": "\ud7da", // ퟚ
  "\\mathbb{3}": "\ud7db", // ퟛ
  "\\mathbb{4}": "\ud7dc", // ퟜ
  "\\mathbb{5}": "\ud7dd", // ퟝ
  "\\mathbb{6}": "\ud7de", // ퟞ
  "\\mathbb{7}": "\ud7df", // ퟟ
  "\\mathbb{8}": "\ud7e0", // ퟠ
  "\\mathbb{9}": "\ud7e1", // ퟡ
  "\\mathbb{A}": "\ud538", // 픸
  "\\mathbb{B}": "\ud539", // 픹
  "\\mathbb{C}": "\u2102", // ℂ
  "\\mathbb{D}": "\ud53b", // 픻
  "\\mathbb{E}": "\ud53c", // 피
  "\\mathbb{F}": "\ud53d", // 픽
  "\\mathbb{G}": "\ud53e", // 픾
  "\\mathbb{H}": "\u210d", // ℍ
  "\\mathbb{I}": "\ud540", // 핀
  "\\mathbb{J}": "\ud541", // 핁
  "\\mathbb{K}": "\ud542", // 핂
  "\\mathbb{L}": "\ud543", // 핃
  "\\mathbb{M}": "\ud544", // 필
  "\\mathbb{N}": "\u2115", // ℕ
  "\\mathbb{O}": "\ud546", // 핆
  "\\mathbb{P}": "\u2119", // ℙ
  "\\mathbb{Q}": "\u211a", // ℚ
  "\\mathbb{R}": "\u211d", // ℝ
  "\\mathbb{S}": "\ud54a", // 핊
  "\\mathbb{T}": "\ud54b", // 핋
  "\\mathbb{U}": "\ud54c", // 핌
  "\\mathbb{V}": "\ud54d", // 핍
  "\\mathbb{W}": "\ud54e", // 핎
  "\\mathbb{X}": "\ud54f", // 핏
  "\\mathbb{Y}": "\ud550", // 핐
  "\\mathbb{Z}": "\u2124", // ℤ
  "\\mathbb{a}": "\ud552", // 핒
  "\\mathbb{b}": "\ud553", // 핓
  "\\mathbb{c}": "\ud554", // 핔
  "\\mathbb{d}": "\ud555", // 핕
  "\\mathbb{e}": "\ud556", // 핖
  "\\mathbb{f}": "\ud557", // 핗
  "\\mathbb{g}": "\ud558", // 하
  "\\mathbb{h}": "\ud559", // 학
  "\\mathbb{i}": "\ud55a", // 핚
  "\\mathbb{j}": "\ud55b", // 핛
  "\\mathbb{k}": "\ud55c", // 한
  "\\mathbb{l}": "\ud55d", // 핝
  "\\mathbb{m}": "\ud55e", // 핞
  "\\mathbb{n}": "\ud55f", // 핟
  "\\mathbb{o}": "\ud560", // 할
  "\\mathbb{p}": "\ud561", // 핡
  "\\mathbb{q}": "\ud562", // 핢
  "\\mathbb{r}": "\ud563", // 핣
  "\\mathbb{s}": "\ud564", // 핤
  "\\mathbb{t}": "\ud565", // 핥
  "\\mathbb{u}": "\ud566", // 핦
  "\\mathbb{v}": "\ud567", // 핧
  "\\mathbb{w}": "\ud568", // 함
  "\\mathbb{x}": "\ud569", // 합
  "\\mathbb{y}": "\ud56a", // 핪
  "\\mathbb{z}": "\ud56b", // 핫
  "\\mathbf{0}": "\ud7ce", // ퟎ
  "\\mathbf{1}": "\ud7cf", // ퟏ
  "\\mathbf{2}": "\ud7d0", // ퟐ
  "\\mathbf{3}": "\ud7d1", // ퟑ
  "\\mathbf{4}": "\ud7d2", // ퟒ
  "\\mathbf{5}": "\ud7d3", // ퟓ
  "\\mathbf{6}": "\ud7d4", // ퟔ
  "\\mathbf{7}": "\ud7d5", // ퟕ
  "\\mathbf{8}": "\ud7d6", // ퟖ
  "\\mathbf{9}": "\ud7d7", // ퟗ
  "\\mathbf{A}": "\ud400", // 퐀
  "\\mathbf{B}": "\ud401", // 퐁
  "\\mathbf{C}": "\ud402", // 퐂
  "\\mathbf{D}": "\ud403", // 퐃
  "\\mathbf{E}": "\ud404", // 퐄
  "\\mathbf{F}": "\ud405", // 퐅
  "\\mathbf{G}": "\ud406", // 퐆
  "\\mathbf{H}": "\ud407", // 퐇
  "\\mathbf{I}": "\ud408", // 퐈
  "\\mathbf{J}": "\ud409", // 퐉
  "\\mathbf{K}": "\ud40a", // 퐊
  "\\mathbf{L}": "\ud40b", // 퐋
  "\\mathbf{M}": "\ud40c", // 퐌
  "\\mathbf{N}": "\ud40d", // 퐍
  "\\mathbf{O}": "\ud40e", // 퐎
  "\\mathbf{P}": "\ud40f", // 퐏
  "\\mathbf{Q}": "\ud410", // 퐐
  "\\mathbf{R}": "\ud411", // 퐑
  "\\mathbf{S}": "\ud412", // 퐒
  "\\mathbf{T}": "\ud413", // 퐓
  "\\mathbf{U}": "\ud414", // 퐔
  "\\mathbf{V}": "\ud415", // 퐕
  "\\mathbf{W}": "\ud416", // 퐖
  "\\mathbf{X}": "\ud417", // 퐗
  "\\mathbf{Y}": "\ud418", // 퐘
  "\\mathbf{Z}": "\ud419", // 퐙
  "\\mathbf{\\Alpha}": "\ud6c2", // 훂
  "\\mathbf{\\Beta}": "\ud6c3", // 훃
  "\\mathbf{\\Chi}": "\ud6d8", // 훘
  "\\mathbf{\\Delta}": "\ud6ab", // 횫
  "\\mathbf{\\Epsilon}": "\ud6ac", // 횬
  "\\mathbf{\\Eta}": "\ud6ae", // 횮
  "\\mathbf{\\Gamma}": "\ud6aa", // 횪
  "\\mathbf{\\Iota}": "\ud6ca", // 훊
  "\\mathbf{\\Kappa}": "\ud6cb", // 훋
  "\\mathbf{\\Lambda}": "\ud6cc", // 훌
  "\\mathbf{\\Omega}": "\ud6da", // 훚
  "\\mathbf{\\Phi}": "\ud6d7", // 훗
  "\\mathbf{\\Pi}": "\ud6d1", // 훑
  "\\mathbf{\\Psi}": "\ud6d9", // 훙
  "\\mathbf{\\Rho}": "\ud6d2", // 훒
  "\\mathbf{\\Sigma}": "\ud6ba", // 횺
  "\\mathbf{\\Tau}": "\ud6d5", // 훕
  "\\mathbf{\\Theta}": "\ud6af", // 횯
  "\\mathbf{\\Upsilon}": "\ud6d6", // 훖
  "\\mathbf{\\Xi}": "\ud6b5", // 횵
  "\\mathbf{\\Zeta}": "\ud6ad", // 횭
  "\\mathbf{\\nabla}": "\ud6c1", // 훁
  "\\mathbf{\\phi}": "\ud6df", // 훟
  "\\mathbf{\\theta}": "\ud6c9", // 훉
  "\\mathbf{\\varkappa}": "\ud6de", // 훞
  "\\mathbf{\\varpi}": "\ud6e1", // 훡
  "\\mathbf{\\varrho}": "\ud6e0", // 훠
  "\\mathbf{\\varsigma}": "\ud6d3", // 훓
  "\\mathbf{\\vartheta}": "\ud6dd", // 훝
  "\\mathbf{a}": "\ud41a", // 퐚
  "\\mathbf{b}": "\ud41b", // 퐛
  "\\mathbf{c}": "\ud41c", // 퐜
  "\\mathbf{d}": "\ud41d", // 퐝
  "\\mathbf{e}": "\ud41e", // 퐞
  "\\mathbf{f}": "\ud41f", // 퐟
  "\\mathbf{g}": "\ud420", // 퐠
  "\\mathbf{h}": "\ud421", // 퐡
  "\\mathbf{i}": "\ud422", // 퐢
  "\\mathbf{j}": "\ud423", // 퐣
  "\\mathbf{k}": "\ud424", // 퐤
  "\\mathbf{l}": "\ud425", // 퐥
  "\\mathbf{m}": "\ud426", // 퐦
  "\\mathbf{n}": "\ud427", // 퐧
  "\\mathbf{o}": "\ud428", // 퐨
  "\\mathbf{p}": "\ud429", // 퐩
  "\\mathbf{q}": "\ud42a", // 퐪
  "\\mathbf{r}": "\ud42b", // 퐫
  "\\mathbf{s}": "\ud42c", // 퐬
  "\\mathbf{t}": "\ud42d", // 퐭
  "\\mathbf{u}": "\ud42e", // 퐮
  "\\mathbf{v}": "\ud42f", // 퐯
  "\\mathbf{w}": "\ud430", // 퐰
  "\\mathbf{x}": "\ud431", // 퐱
  "\\mathbf{y}": "\ud432", // 퐲
  "\\mathbf{z}": "\ud433", // 퐳
  "\\mathbin{{:}\\!\\!{-}\\!\\!{:}}": "\u223a", // ∺
  "\\mathbit{A}": "\ud468", // 푨
  "\\mathbit{B}": "\ud469", // 푩
  "\\mathbit{C}": "\ud46a", // 푪
  "\\mathbit{D}": "\ud46b", // 푫
  "\\mathbit{E}": "\ud46c", // 푬
  "\\mathbit{F}": "\ud46d", // 푭
  "\\mathbit{G}": "\ud46e", // 푮
  "\\mathbit{H}": "\ud46f", // 푯
  "\\mathbit{I}": "\ud470", // 푰
  "\\mathbit{J}": "\ud471", // 푱
  "\\mathbit{K}": "\ud472", // 푲
  "\\mathbit{L}": "\ud473", // 푳
  "\\mathbit{M}": "\ud474", // 푴
  "\\mathbit{N}": "\ud475", // 푵
  "\\mathbit{O}": "\ud72d", // 휭
  "\\mathbit{P}": "\ud477", // 푷
  "\\mathbit{Q}": "\ud478", // 푸
  "\\mathbit{R}": "\ud479", // 푹
  "\\mathbit{S}": "\ud47a", // 푺
  "\\mathbit{T}": "\ud47b", // 푻
  "\\mathbit{U}": "\ud47c", // 푼
  "\\mathbit{V}": "\ud47d", // 푽
  "\\mathbit{W}": "\ud47e", // 푾
  "\\mathbit{X}": "\ud47f", // 푿
  "\\mathbit{Y}": "\ud480", // 풀
  "\\mathbit{Z}": "\ud481", // 풁
  "\\mathbit{\\Alpha}": "\ud736", // 휶
  "\\mathbit{\\Beta}": "\ud71d", // 휝
  "\\mathbit{\\Chi}": "\ud74c", // 흌
  "\\mathbit{\\Delta}": "\ud739", // 휹
  "\\mathbit{\\Epsilon}": "\ud720", // 휠
  "\\mathbit{\\Eta}": "\ud722", // 휢
  "\\mathbit{\\Gamma}": "\ud738", // 휸
  "\\mathbit{\\Iota}": "\ud73e", // 휾
  "\\mathbit{\\Kappa}": "\ud725", // 휥
  "\\mathbit{\\Lambda}": "\ud726", // 휦
  "\\mathbit{\\Omega}": "\ud74e", // 흎
  "\\mathbit{\\Phi}": "\ud74b", // 흋
  "\\mathbit{\\Pi}": "\ud745", // 흅
  "\\mathbit{\\Psi}": "\ud74d", // 흍
  "\\mathbit{\\Rho}": "\ud72c", // 휬
  "\\mathbit{\\Sigma}": "\ud748", // 흈
  "\\mathbit{\\Tau}": "\ud749", // 흉
  "\\mathbit{\\Theta}": "\ud723", // 휣
  "\\mathbit{\\Upsilon}": "\ud74a", // 흊
  "\\mathbit{\\Xi}": "\ud729", // 휩
  "\\mathbit{\\Zeta}": "\ud721", // 휡
  "\\mathbit{\\nabla}": "\ud735", // 휵
  "\\mathbit{\\phi}": "\ud753", // 흓
  "\\mathbit{\\varkappa}": "\ud752", // 흒
  "\\mathbit{\\varpi}": "\ud755", // 흕
  "\\mathbit{\\varrho}": "\ud754", // 흔
  "\\mathbit{\\varsigma}": "\ud747", // 흇
  "\\mathbit{\\vartheta}": "\ud751", // 흑
  "\\mathbit{a}": "\ud482", // 풂
  "\\mathbit{b}": "\ud483", // 풃
  "\\mathbit{c}": "\ud484", // 풄
  "\\mathbit{d}": "\ud485", // 풅
  "\\mathbit{e}": "\ud486", // 풆
  "\\mathbit{f}": "\ud487", // 풇
  "\\mathbit{g}": "\ud488", // 품
  "\\mathbit{h}": "\ud489", // 풉
  "\\mathbit{i}": "\ud48a", // 풊
  "\\mathbit{j}": "\ud48b", // 풋
  "\\mathbit{k}": "\ud48c", // 풌
  "\\mathbit{l}": "\ud48d", // 풍
  "\\mathbit{m}": "\ud48e", // 풎
  "\\mathbit{n}": "\ud48f", // 풏
  "\\mathbit{o}": "\ud490", // 풐
  "\\mathbit{p}": "\ud491", // 풑
  "\\mathbit{q}": "\ud492", // 풒
  "\\mathbit{r}": "\ud493", // 풓
  "\\mathbit{s}": "\ud494", // 풔
  "\\mathbit{t}": "\ud495", // 풕
  "\\mathbit{u}": "\ud496", // 풖
  "\\mathbit{v}": "\ud497", // 풗
  "\\mathbit{w}": "\ud498", // 풘
  "\\mathbit{x}": "\ud499", // 풙
  "\\mathbit{y}": "\ud49a", // 풚
  "\\mathbit{z}": "\ud49b", // 풛
  "\\mathfrak{A}": "\ud504", // 프
  "\\mathfrak{B}": "\ud505", // 픅
  "\\mathfrak{C}": "\u212d", // ℭ
  "\\mathfrak{D}": "\ud507", // 픇
  "\\mathfrak{E}": "\ud508", // 픈
  "\\mathfrak{F}": "\ud509", // 픉
  "\\mathfrak{G}": "\ud50a", // 픊
  "\\mathfrak{H}": "\u210c", // ℌ
  "\\mathfrak{I}": "\u2111", // ℑ
  "\\mathfrak{J}": "\ud50d", // 픍
  "\\mathfrak{K}": "\ud50e", // 픎
  "\\mathfrak{L}": "\ud50f", // 픏
  "\\mathfrak{M}": "\ud510", // 픐
  "\\mathfrak{N}": "\ud511", // 픑
  "\\mathfrak{O}": "\ud512", // 픒
  "\\mathfrak{P}": "\ud513", // 픓
  "\\mathfrak{Q}": "\ud514", // 픔
  "\\mathfrak{R}": "\u211c", // ℜ
  "\\mathfrak{S}": "\ud516", // 픖
  "\\mathfrak{T}": "\ud517", // 픗
  "\\mathfrak{U}": "\ud518", // 픘
  "\\mathfrak{V}": "\ud519", // 픙
  "\\mathfrak{W}": "\ud51a", // 픚
  "\\mathfrak{X}": "\ud51b", // 픛
  "\\mathfrak{Y}": "\ud51c", // 픜
  "\\mathfrak{Z}": "\u2128", // ℨ
  "\\mathfrak{a}": "\ud51e", // 픞
  "\\mathfrak{b}": "\ud51f", // 픟
  "\\mathfrak{c}": "\ud520", // 픠
  "\\mathfrak{d}": "\ud521", // 픡
  "\\mathfrak{e}": "\ud522", // 픢
  "\\mathfrak{f}": "\ud523", // 픣
  "\\mathfrak{g}": "\ud524", // 픤
  "\\mathfrak{h}": "\ud525", // 픥
  "\\mathfrak{i}": "\ud526", // 픦
  "\\mathfrak{j}": "\ud527", // 픧
  "\\mathfrak{k}": "\ud528", // 픨
  "\\mathfrak{l}": "\ud529", // 픩
  "\\mathfrak{m}": "\ud52a", // 픪
  "\\mathfrak{n}": "\ud52b", // 픫
  "\\mathfrak{o}": "\ud52c", // 픬
  "\\mathfrak{p}": "\ud52d", // 픭
  "\\mathfrak{q}": "\ud52e", // 픮
  "\\mathfrak{r}": "\ud52f", // 픯
  "\\mathfrak{s}": "\ud530", // 픰
  "\\mathfrak{t}": "\ud531", // 픱
  "\\mathfrak{u}": "\ud532", // 픲
  "\\mathfrak{v}": "\ud533", // 픳
  "\\mathfrak{w}": "\ud534", // 픴
  "\\mathfrak{x}": "\ud535", // 픵
  "\\mathfrak{y}": "\ud536", // 픶
  "\\mathfrak{z}": "\ud537", // 픷
  "\\mathmit{A}": "\ud4d0", // 퓐
  "\\mathmit{B}": "\ud4d1", // 퓑
  "\\mathmit{C}": "\ud4d2", // 퓒
  "\\mathmit{D}": "\ud4d3", // 퓓
  "\\mathmit{E}": "\ud4d4", // 퓔
  "\\mathmit{F}": "\ud4d5", // 퓕
  "\\mathmit{G}": "\ud4d6", // 퓖
  "\\mathmit{H}": "\ud4d7", // 퓗
  "\\mathmit{I}": "\ud4d8", // 퓘
  "\\mathmit{J}": "\ud4d9", // 퓙
  "\\mathmit{K}": "\ud4da", // 퓚
  "\\mathmit{L}": "\ud4db", // 퓛
  "\\mathmit{M}": "\ud4dc", // 퓜
  "\\mathmit{N}": "\ud4dd", // 퓝
  "\\mathmit{O}": "\ud4de", // 퓞
  "\\mathmit{P}": "\ud4df", // 퓟
  "\\mathmit{Q}": "\ud4e0", // 퓠
  "\\mathmit{R}": "\ud4e1", // 퓡
  "\\mathmit{S}": "\ud4e2", // 퓢
  "\\mathmit{T}": "\ud4e3", // 퓣
  "\\mathmit{U}": "\ud4e4", // 퓤
  "\\mathmit{V}": "\ud4e5", // 퓥
  "\\mathmit{W}": "\ud4e6", // 퓦
  "\\mathmit{X}": "\ud4e7", // 퓧
  "\\mathmit{Y}": "\ud4e8", // 퓨
  "\\mathmit{Z}": "\ud4e9", // 퓩
  "\\mathmit{a}": "\ud4ea", // 퓪
  "\\mathmit{b}": "\ud4eb", // 퓫
  "\\mathmit{c}": "\ud4ec", // 퓬
  "\\mathmit{d}": "\ud4ed", // 퓭
  "\\mathmit{e}": "\ud4ee", // 퓮
  "\\mathmit{f}": "\ud4ef", // 퓯
  "\\mathmit{g}": "\ud4f0", // 퓰
  "\\mathmit{h}": "\ud4f1", // 퓱
  "\\mathmit{i}": "\ud4f2", // 퓲
  "\\mathmit{j}": "\ud4f3", // 퓳
  "\\mathmit{k}": "\ud4f4", // 퓴
  "\\mathmit{l}": "\ud4f5", // 퓵
  "\\mathmit{m}": "\ud4f6", // 퓶
  "\\mathmit{n}": "\ud4f7", // 퓷
  "\\mathmit{o}": "\ud4f8", // 퓸
  "\\mathmit{p}": "\ud4f9", // 퓹
  "\\mathmit{q}": "\ud4fa", // 퓺
  "\\mathmit{r}": "\ud4fb", // 퓻
  "\\mathmit{s}": "\ud4fc", // 퓼
  "\\mathmit{t}": "\ud4fd", // 퓽
  "\\mathmit{u}": "\ud4fe", // 퓾
  "\\mathmit{v}": "\ud4ff", // 퓿
  "\\mathmit{w}": "\ud500", // 픀
  "\\mathmit{x}": "\ud501", // 픁
  "\\mathmit{y}": "\ud502", // 픂
  "\\mathmit{z}": "\ud503", // 픃
  "\\mathrm{'Y}": "\u038e", // Ύ
  "\\mathrm{'\\Omega}": "\u038f", // Ώ
  "\\mathrm{\\ddot{I}}": "\u03aa", // Ϊ
  "\\mathrm{\\ddot{Y}}": "\u03ab", // Ϋ
  "\\mathrm{\\mu}": "\u00b5", // µ
  "\\mathscr{A}": "\ud49c", // 풜
  "\\mathscr{B}": "\u212c", // ℬ
  "\\mathscr{C}": "\ud49e", // 풞
  "\\mathscr{D}": "\ud49f", // 풟
  "\\mathscr{E}": "\u2130", // ℰ
  "\\mathscr{F}": "\u2131", // ℱ
  "\\mathscr{G}": "\ud4a2", // 풢
  "\\mathscr{H}": "\u210b", // ℋ
  "\\mathscr{I}": "\u2110", // ℐ
  "\\mathscr{J}": "\ud4a5", // 풥
  "\\mathscr{K}": "\ud4a6", // 풦
  "\\mathscr{L}": "\u2112", // ℒ
  "\\mathscr{M}": "\u2133", // ℳ
  "\\mathscr{N}": "\ud4a9", // 풩
  "\\mathscr{O}": "\ud4aa", // 풪
  "\\mathscr{P}": "\ud4ab", // 풫
  "\\mathscr{Q}": "\ud4ac", // 풬
  "\\mathscr{R}": "\u211b", // ℛ
  "\\mathscr{S}": "\ud4ae", // 풮
  "\\mathscr{T}": "\ud4af", // 풯
  "\\mathscr{U}": "\ud4b0", // 풰
  "\\mathscr{V}": "\ud4b1", // 풱
  "\\mathscr{W}": "\ud4b2", // 풲
  "\\mathscr{X}": "\ud4b3", // 풳
  "\\mathscr{Y}": "\ud4b4", // 풴
  "\\mathscr{Z}": "\ud4b5", // 풵
  "\\mathscr{a}": "\ud4b6", // 풶
  "\\mathscr{b}": "\ud4b7", // 풷
  "\\mathscr{c}": "\ud4b8", // 풸
  "\\mathscr{d}": "\ud4b9", // 풹
  "\\mathscr{e}": "\u212f", // ℯ
  "\\mathscr{f}": "\ud4bb", // 풻
  "\\mathscr{g}": "\u210a", // ℊ
  "\\mathscr{h}": "\ud4bd", // 풽
  "\\mathscr{i}": "\ud4be", // 풾
  "\\mathscr{j}": "\ud4bf", // 풿
  "\\mathscr{k}": "\ud4c0", // 퓀
  "\\mathscr{l}": "\ud4c1", // 퓁
  "\\mathscr{m}": "\ud4c2", // 퓂
  "\\mathscr{n}": "\ud4c3", // 퓃
  "\\mathscr{o}": "\u2134", // ℴ
  "\\mathscr{p}": "\ud4c5", // 퓅
  "\\mathscr{q}": "\ud4c6", // 퓆
  "\\mathscr{r}": "\ud4c7", // 퓇
  "\\mathscr{s}": "\ud4c8", // 퓈
  "\\mathscr{t}": "\ud4c9", // 퓉
  "\\mathscr{u}": "\ud4ca", // 퓊
  "\\mathscr{v}": "\ud4cb", // 퓋
  "\\mathscr{w}": "\ud4cc", // 퓌
  "\\mathscr{x}": "\ud4cd", // 퓍
  "\\mathscr{y}": "\ud4ce", // 퓎
  "\\mathscr{z}": "\ud4cf", // 퓏
  "\\mathsfbfsl{A}": "\ud63c", // 혼
  "\\mathsfbfsl{B}": "\ud63d", // 혽
  "\\mathsfbfsl{C}": "\ud63e", // 혾
  "\\mathsfbfsl{D}": "\ud63f", // 혿
  "\\mathsfbfsl{E}": "\ud640", // 홀
  "\\mathsfbfsl{F}": "\ud641", // 홁
  "\\mathsfbfsl{G}": "\ud642", // 홂
  "\\mathsfbfsl{H}": "\ud643", // 홃
  "\\mathsfbfsl{I}": "\ud644", // 홄
  "\\mathsfbfsl{J}": "\ud645", // 홅
  "\\mathsfbfsl{K}": "\ud646", // 홆
  "\\mathsfbfsl{L}": "\ud647", // 홇
  "\\mathsfbfsl{M}": "\ud648", // 홈
  "\\mathsfbfsl{N}": "\ud649", // 홉
  "\\mathsfbfsl{O}": "\ud64a", // 홊
  "\\mathsfbfsl{P}": "\ud64b", // 홋
  "\\mathsfbfsl{Q}": "\ud64c", // 홌
  "\\mathsfbfsl{R}": "\ud64d", // 홍
  "\\mathsfbfsl{S}": "\ud64e", // 홎
  "\\mathsfbfsl{T}": "\ud64f", // 홏
  "\\mathsfbfsl{U}": "\ud650", // 홐
  "\\mathsfbfsl{V}": "\ud651", // 홑
  "\\mathsfbfsl{W}": "\ud652", // 홒
  "\\mathsfbfsl{X}": "\ud653", // 홓
  "\\mathsfbfsl{Y}": "\ud654", // 화
  "\\mathsfbfsl{Z}": "\ud655", // 확
  "\\mathsfbfsl{\\Alpha}": "\ud7aa", // 힪
  "\\mathsfbfsl{\\Beta}": "\ud7ab", // 힫
  "\\mathsfbfsl{\\Chi}": "\ud7a6", // 힦
  "\\mathsfbfsl{\\Delta}": "\ud793", // 힓
  "\\mathsfbfsl{\\Epsilon}": "\ud7ae", // 힮
  "\\mathsfbfsl{\\Eta}": "\ud796", // 힖
  "\\mathsfbfsl{\\Gamma}": "\ud7ac", // 힬
  "\\mathsfbfsl{\\Iota}": "\ud7b2", // ힲ
  "\\mathsfbfsl{\\Kappa}": "\ud799", // 힙
  "\\mathsfbfsl{\\Lambda}": "\ud7b4", // ힴ
  "\\mathsfbfsl{\\Omega}": "\ud7a8", // 힨
  "\\mathsfbfsl{\\Phi}": "\ud7a5", // 힥
  "\\mathsfbfsl{\\Pi}": "\ud7b9", // ힹ
  "\\mathsfbfsl{\\Psi}": "\ud7c1", // ퟁ
  "\\mathsfbfsl{\\Rho}": "\ud7a0", // 힠
  "\\mathsfbfsl{\\Sigma}": "\ud7a2", // 힢
  "\\mathsfbfsl{\\Tau}": "\ud7a3", // 힣
  "\\mathsfbfsl{\\Upsilon}": "\ud7a4", // 힤
  "\\mathsfbfsl{\\Xi}": "\ud7b7", // ힷ
  "\\mathsfbfsl{\\Zeta}": "\ud7af", // 힯
  "\\mathsfbfsl{\\nabla}": "\ud7a9", // 힩
  "\\mathsfbfsl{\\phi}": "\ud7c7", // ퟇
  "\\mathsfbfsl{\\varkappa}": "\ud7c6", // ퟆ
  "\\mathsfbfsl{\\varpi}": "\ud7c9", // ퟉
  "\\mathsfbfsl{\\varrho}": "\ud7c8", // ퟈
  "\\mathsfbfsl{\\varsigma}": "\ud7bb", // ힻ
  "\\mathsfbfsl{\\vartheta}": "\ud7c5", // ퟅ
  "\\mathsfbfsl{a}": "\ud656", // 홖
  "\\mathsfbfsl{b}": "\ud657", // 홗
  "\\mathsfbfsl{c}": "\ud658", // 환
  "\\mathsfbfsl{d}": "\ud659", // 홙
  "\\mathsfbfsl{e}": "\ud65a", // 홚
  "\\mathsfbfsl{f}": "\ud65b", // 홛
  "\\mathsfbfsl{g}": "\ud65c", // 활
  "\\mathsfbfsl{h}": "\ud65d", // 홝
  "\\mathsfbfsl{i}": "\ud65e", // 홞
  "\\mathsfbfsl{j}": "\ud65f", // 홟
  "\\mathsfbfsl{k}": "\ud660", // 홠
  "\\mathsfbfsl{l}": "\ud661", // 홡
  "\\mathsfbfsl{m}": "\ud662", // 홢
  "\\mathsfbfsl{n}": "\ud663", // 홣
  "\\mathsfbfsl{o}": "\ud664", // 홤
  "\\mathsfbfsl{p}": "\ud665", // 홥
  "\\mathsfbfsl{q}": "\ud666", // 홦
  "\\mathsfbfsl{r}": "\ud667", // 홧
  "\\mathsfbfsl{s}": "\ud668", // 홨
  "\\mathsfbfsl{t}": "\ud669", // 황
  "\\mathsfbfsl{u}": "\ud66a", // 홪
  "\\mathsfbfsl{v}": "\ud66b", // 홫
  "\\mathsfbfsl{w}": "\ud66c", // 홬
  "\\mathsfbfsl{x}": "\ud66d", // 홭
  "\\mathsfbfsl{y}": "\ud66e", // 홮
  "\\mathsfbfsl{z}": "\ud66f", // 홯
  "\\mathsfbf{0}": "\ud7ec", // ퟬ
  "\\mathsfbf{1}": "\ud7ed", // ퟭ
  "\\mathsfbf{2}": "\ud7ee", // ퟮ
  "\\mathsfbf{3}": "\ud7ef", // ퟯ
  "\\mathsfbf{4}": "\ud7f0", // ퟰ
  "\\mathsfbf{5}": "\ud7f1", // ퟱ
  "\\mathsfbf{6}": "\ud7f2", // ퟲ
  "\\mathsfbf{7}": "\ud7f3", // ퟳ
  "\\mathsfbf{8}": "\ud7f4", // ퟴ
  "\\mathsfbf{9}": "\ud7f5", // ퟵ
  "\\mathsfbf{A}": "\ud5d4", // 헔
  "\\mathsfbf{B}": "\ud5d5", // 헕
  "\\mathsfbf{C}": "\ud5d6", // 헖
  "\\mathsfbf{D}": "\ud5d7", // 헗
  "\\mathsfbf{E}": "\ud5d8", // 험
  "\\mathsfbf{F}": "\ud5d9", // 헙
  "\\mathsfbf{G}": "\ud5da", // 헚
  "\\mathsfbf{H}": "\ud5db", // 헛
  "\\mathsfbf{I}": "\ud5dc", // 헜
  "\\mathsfbf{J}": "\ud5dd", // 헝
  "\\mathsfbf{K}": "\ud5de", // 헞
  "\\mathsfbf{L}": "\ud5df", // 헟
  "\\mathsfbf{M}": "\ud5e0", // 헠
  "\\mathsfbf{N}": "\ud5e1", // 헡
  "\\mathsfbf{O}": "\ud5e2", // 헢
  "\\mathsfbf{P}": "\ud5e3", // 헣
  "\\mathsfbf{Q}": "\ud5e4", // 헤
  "\\mathsfbf{R}": "\ud5e5", // 헥
  "\\mathsfbf{S}": "\ud5e6", // 헦
  "\\mathsfbf{T}": "\ud5e7", // 헧
  "\\mathsfbf{U}": "\ud5e8", // 헨
  "\\mathsfbf{V}": "\ud5e9", // 헩
  "\\mathsfbf{W}": "\ud5ea", // 헪
  "\\mathsfbf{X}": "\ud5eb", // 헫
  "\\mathsfbf{Y}": "\ud5ec", // 헬
  "\\mathsfbf{Z}": "\ud5ed", // 헭
  "\\mathsfbf{\\Alpha}": "\ud770", // 흰
  "\\mathsfbf{\\Beta}": "\ud757", // 흗
  "\\mathsfbf{\\Chi}": "\ud76c", // 희
  "\\mathsfbf{\\Delta}": "\ud773", // 흳
  "\\mathsfbf{\\Epsilon}": "\ud75a", // 흚
  "\\mathsfbf{\\Eta}": "\ud776", // 흶
  "\\mathsfbf{\\Gamma}": "\ud772", // 흲
  "\\mathsfbf{\\Iota}": "\ud778", // 흸
  "\\mathsfbf{\\Kappa}": "\ud779", // 흹
  "\\mathsfbf{\\Lambda}": "\ud77a", // 흺
  "\\mathsfbf{\\Omega}": "\ud788", // 히
  "\\mathsfbf{\\Phi}": "\ud76b", // 흫
  "\\mathsfbf{\\Pi}": "\ud765", // 흥
  "\\mathsfbf{\\Psi}": "\ud76d", // 흭
  "\\mathsfbf{\\Rho}": "\ud766", // 흦
  "\\mathsfbf{\\Sigma}": "\ud768", // 흨
  "\\mathsfbf{\\Tau}": "\ud769", // 흩
  "\\mathsfbf{\\Theta}": "\ud777", // 흷
  "\\mathsfbf{\\Upsilon}": "\ud784", // 힄
  "\\mathsfbf{\\Xi}": "\ud763", // 흣
  "\\mathsfbf{\\Zeta}": "\ud75b", // 흛
  "\\mathsfbf{\\nabla}": "\ud76f", // 흯
  "\\mathsfbf{\\phi}": "\ud78d", // 힍
  "\\mathsfbf{\\varkappa}": "\ud78c", // 힌
  "\\mathsfbf{\\varpi}": "\ud78f", // 힏
  "\\mathsfbf{\\varrho}": "\ud78e", // 힎
  "\\mathsfbf{\\varsigma}": "\ud781", // 힁
  "\\mathsfbf{\\vartheta}": "\ud767", // 흧
  "\\mathsfbf{a}": "\ud5ee", // 헮
  "\\mathsfbf{b}": "\ud5ef", // 헯
  "\\mathsfbf{c}": "\ud5f0", // 헰
  "\\mathsfbf{d}": "\ud5f1", // 헱
  "\\mathsfbf{e}": "\ud5f2", // 헲
  "\\mathsfbf{f}": "\ud5f3", // 헳
  "\\mathsfbf{g}": "\ud5f4", // 헴
  "\\mathsfbf{h}": "\ud5f5", // 헵
  "\\mathsfbf{i}": "\ud5f6", // 헶
  "\\mathsfbf{j}": "\ud5f7", // 헷
  "\\mathsfbf{k}": "\ud5f8", // 헸
  "\\mathsfbf{l}": "\ud5f9", // 헹
  "\\mathsfbf{m}": "\ud5fa", // 헺
  "\\mathsfbf{n}": "\ud5fb", // 헻
  "\\mathsfbf{o}": "\ud5fc", // 헼
  "\\mathsfbf{p}": "\ud5fd", // 헽
  "\\mathsfbf{q}": "\ud5fe", // 헾
  "\\mathsfbf{r}": "\ud5ff", // 헿
  "\\mathsfbf{s}": "\ud600", // 혀
  "\\mathsfbf{t}": "\ud601", // 혁
  "\\mathsfbf{u}": "\ud602", // 혂
  "\\mathsfbf{v}": "\ud603", // 혃
  "\\mathsfbf{w}": "\ud604", // 현
  "\\mathsfbf{x}": "\ud605", // 혅
  "\\mathsfbf{y}": "\ud606", // 혆
  "\\mathsfbf{z}": "\ud607", // 혇
  "\\mathsfsl{A}": "\ud608", // 혈
  "\\mathsfsl{B}": "\ud609", // 혉
  "\\mathsfsl{C}": "\ud60a", // 혊
  "\\mathsfsl{D}": "\ud60b", // 혋
  "\\mathsfsl{E}": "\ud60c", // 혌
  "\\mathsfsl{F}": "\ud60d", // 혍
  "\\mathsfsl{G}": "\ud60e", // 혎
  "\\mathsfsl{H}": "\ud60f", // 혏
  "\\mathsfsl{I}": "\ud610", // 혐
  "\\mathsfsl{J}": "\ud611", // 협
  "\\mathsfsl{K}": "\ud612", // 혒
  "\\mathsfsl{L}": "\ud613", // 혓
  "\\mathsfsl{M}": "\ud614", // 혔
  "\\mathsfsl{N}": "\ud615", // 형
  "\\mathsfsl{O}": "\ud616", // 혖
  "\\mathsfsl{P}": "\ud617", // 혗
  "\\mathsfsl{Q}": "\ud618", // 혘
  "\\mathsfsl{R}": "\ud619", // 혙
  "\\mathsfsl{S}": "\ud61a", // 혚
  "\\mathsfsl{T}": "\ud61b", // 혛
  "\\mathsfsl{U}": "\ud61c", // 혜
  "\\mathsfsl{V}": "\ud61d", // 혝
  "\\mathsfsl{W}": "\ud61e", // 혞
  "\\mathsfsl{X}": "\ud61f", // 혟
  "\\mathsfsl{Y}": "\ud620", // 혠
  "\\mathsfsl{Z}": "\ud621", // 혡
  "\\mathsfsl{a}": "\ud622", // 혢
  "\\mathsfsl{b}": "\ud623", // 혣
  "\\mathsfsl{c}": "\ud624", // 혤
  "\\mathsfsl{d}": "\ud625", // 혥
  "\\mathsfsl{e}": "\ud626", // 혦
  "\\mathsfsl{f}": "\ud627", // 혧
  "\\mathsfsl{g}": "\ud628", // 혨
  "\\mathsfsl{h}": "\ud629", // 혩
  "\\mathsfsl{i}": "\ud62a", // 혪
  "\\mathsfsl{j}": "\ud62b", // 혫
  "\\mathsfsl{k}": "\ud62c", // 혬
  "\\mathsfsl{l}": "\ud62d", // 혭
  "\\mathsfsl{m}": "\ud62e", // 혮
  "\\mathsfsl{n}": "\ud62f", // 혯
  "\\mathsfsl{o}": "\ud630", // 혰
  "\\mathsfsl{p}": "\ud631", // 혱
  "\\mathsfsl{q}": "\ud632", // 혲
  "\\mathsfsl{r}": "\ud633", // 혳
  "\\mathsfsl{s}": "\ud634", // 혴
  "\\mathsfsl{t}": "\ud635", // 혵
  "\\mathsfsl{u}": "\ud636", // 혶
  "\\mathsfsl{v}": "\ud637", // 혷
  "\\mathsfsl{w}": "\ud638", // 호
  "\\mathsfsl{x}": "\ud639", // 혹
  "\\mathsfsl{y}": "\ud63a", // 혺
  "\\mathsfsl{z}": "\ud63b", // 혻
  "\\mathsf{0}": "\ud7e2", // ퟢ
  "\\mathsf{1}": "\ud7e3", // ퟣ
  "\\mathsf{2}": "\ud7e4", // ퟤ
  "\\mathsf{3}": "\ud7e5", // ퟥ
  "\\mathsf{4}": "\ud7e6", // ퟦ
  "\\mathsf{5}": "\ud7e7", // ퟧ
  "\\mathsf{6}": "\ud7e8", // ퟨ
  "\\mathsf{7}": "\ud7e9", // ퟩ
  "\\mathsf{8}": "\ud7ea", // ퟪ
  "\\mathsf{9}": "\ud7eb", // ퟫ
  "\\mathsf{A}": "\ud5a0", // 햠
  "\\mathsf{B}": "\ud5a1", // 햡
  "\\mathsf{C}": "\ud5a2", // 햢
  "\\mathsf{D}": "\ud5a3", // 햣
  "\\mathsf{E}": "\ud5a4", // 햤
  "\\mathsf{F}": "\ud5a5", // 향
  "\\mathsf{G}": "\ud5a6", // 햦
  "\\mathsf{H}": "\ud5a7", // 햧
  "\\mathsf{I}": "\ud5a8", // 햨
  "\\mathsf{J}": "\ud5a9", // 햩
  "\\mathsf{K}": "\ud5aa", // 햪
  "\\mathsf{L}": "\ud5ab", // 햫
  "\\mathsf{M}": "\ud5ac", // 햬
  "\\mathsf{N}": "\ud5ad", // 햭
  "\\mathsf{O}": "\ud5ae", // 햮
  "\\mathsf{P}": "\ud5af", // 햯
  "\\mathsf{Q}": "\ud5b0", // 햰
  "\\mathsf{R}": "\ud5b1", // 햱
  "\\mathsf{S}": "\ud5b2", // 햲
  "\\mathsf{T}": "\ud5b3", // 햳
  "\\mathsf{U}": "\ud5b4", // 햴
  "\\mathsf{V}": "\ud5b5", // 햵
  "\\mathsf{W}": "\ud5b6", // 햶
  "\\mathsf{X}": "\ud5b7", // 햷
  "\\mathsf{Y}": "\ud5b8", // 햸
  "\\mathsf{Z}": "\ud5b9", // 햹
  "\\mathsf{a}": "\ud5ba", // 햺
  "\\mathsf{b}": "\ud5bb", // 햻
  "\\mathsf{c}": "\ud5bc", // 햼
  "\\mathsf{d}": "\ud5bd", // 햽
  "\\mathsf{e}": "\ud5be", // 햾
  "\\mathsf{f}": "\ud5bf", // 햿
  "\\mathsf{g}": "\ud5c0", // 헀
  "\\mathsf{h}": "\ud5c1", // 헁
  "\\mathsf{i}": "\ud5c2", // 헂
  "\\mathsf{j}": "\ud5c3", // 헃
  "\\mathsf{k}": "\ud5c4", // 헄
  "\\mathsf{l}": "\ud5c5", // 헅
  "\\mathsf{m}": "\ud5c6", // 헆
  "\\mathsf{n}": "\ud5c7", // 헇
  "\\mathsf{o}": "\ud5c8", // 허
  "\\mathsf{p}": "\ud5c9", // 헉
  "\\mathsf{q}": "\ud5ca", // 헊
  "\\mathsf{r}": "\ud5cb", // 헋
  "\\mathsf{s}": "\ud5cc", // 헌
  "\\mathsf{t}": "\ud5cd", // 헍
  "\\mathsf{u}": "\ud5ce", // 헎
  "\\mathsf{v}": "\ud5cf", // 헏
  "\\mathsf{w}": "\ud5d0", // 헐
  "\\mathsf{x}": "\ud5d1", // 헑
  "\\mathsf{y}": "\ud5d2", // 헒
  "\\mathsf{z}": "\ud5d3", // 헓
  "\\mathslbb{A}": "\ud56c", // 핬
  "\\mathslbb{B}": "\ud56d", // 항
  "\\mathslbb{C}": "\ud56e", // 핮
  "\\mathslbb{D}": "\ud56f", // 핯
  "\\mathslbb{E}": "\ud570", // 핰
  "\\mathslbb{F}": "\ud571", // 핱
  "\\mathslbb{G}": "\ud572", // 핲
  "\\mathslbb{H}": "\ud573", // 핳
  "\\mathslbb{I}": "\ud574", // 해
  "\\mathslbb{J}": "\ud575", // 핵
  "\\mathslbb{K}": "\ud576", // 핶
  "\\mathslbb{L}": "\ud577", // 핷
  "\\mathslbb{M}": "\ud578", // 핸
  "\\mathslbb{N}": "\ud579", // 핹
  "\\mathslbb{O}": "\ud57a", // 핺
  "\\mathslbb{P}": "\ud57b", // 핻
  "\\mathslbb{Q}": "\ud57c", // 핼
  "\\mathslbb{R}": "\ud57d", // 핽
  "\\mathslbb{S}": "\ud57e", // 핾
  "\\mathslbb{T}": "\ud57f", // 핿
  "\\mathslbb{U}": "\ud580", // 햀
  "\\mathslbb{V}": "\ud581", // 햁
  "\\mathslbb{W}": "\ud582", // 햂
  "\\mathslbb{X}": "\ud583", // 햃
  "\\mathslbb{Y}": "\ud584", // 햄
  "\\mathslbb{Z}": "\ud585", // 햅
  "\\mathslbb{a}": "\ud586", // 햆
  "\\mathslbb{b}": "\ud587", // 햇
  "\\mathslbb{c}": "\ud588", // 했
  "\\mathslbb{d}": "\ud589", // 행
  "\\mathslbb{e}": "\ud58a", // 햊
  "\\mathslbb{f}": "\ud58b", // 햋
  "\\mathslbb{g}": "\ud58c", // 햌
  "\\mathslbb{h}": "\ud58d", // 햍
  "\\mathslbb{i}": "\ud58e", // 햎
  "\\mathslbb{j}": "\ud58f", // 햏
  "\\mathslbb{k}": "\ud590", // 햐
  "\\mathslbb{l}": "\ud591", // 햑
  "\\mathslbb{m}": "\ud592", // 햒
  "\\mathslbb{n}": "\ud593", // 햓
  "\\mathslbb{o}": "\ud594", // 햔
  "\\mathslbb{p}": "\ud595", // 햕
  "\\mathslbb{q}": "\ud596", // 햖
  "\\mathslbb{r}": "\ud597", // 햗
  "\\mathslbb{s}": "\ud598", // 햘
  "\\mathslbb{t}": "\ud599", // 햙
  "\\mathslbb{u}": "\ud59a", // 햚
  "\\mathslbb{v}": "\ud59b", // 햛
  "\\mathslbb{w}": "\ud59c", // 햜
  "\\mathslbb{x}": "\ud59d", // 햝
  "\\mathslbb{y}": "\ud59e", // 햞
  "\\mathslbb{z}": "\ud59f", // 햟
  "\\mathsl{A}": "\ud434", // 퐴
  "\\mathsl{B}": "\ud435", // 퐵
  "\\mathsl{C}": "\ud436", // 퐶
  "\\mathsl{D}": "\ud437", // 퐷
  "\\mathsl{E}": "\ud438", // 퐸
  "\\mathsl{F}": "\ud439", // 퐹
  "\\mathsl{G}": "\ud43a", // 퐺
  "\\mathsl{H}": "\ud43b", // 퐻
  "\\mathsl{I}": "\ud43c", // 퐼
  "\\mathsl{J}": "\ud43d", // 퐽
  "\\mathsl{K}": "\ud43e", // 퐾
  "\\mathsl{L}": "\ud43f", // 퐿
  "\\mathsl{M}": "\ud440", // 푀
  "\\mathsl{N}": "\ud441", // 푁
  "\\mathsl{O}": "\ud442", // 푂
  "\\mathsl{P}": "\ud443", // 푃
  "\\mathsl{Q}": "\ud444", // 푄
  "\\mathsl{R}": "\ud445", // 푅
  "\\mathsl{S}": "\ud446", // 푆
  "\\mathsl{T}": "\ud447", // 푇
  "\\mathsl{U}": "\ud448", // 푈
  "\\mathsl{V}": "\ud449", // 푉
  "\\mathsl{W}": "\ud44a", // 푊
  "\\mathsl{X}": "\ud44b", // 푋
  "\\mathsl{Y}": "\ud44c", // 푌
  "\\mathsl{Z}": "\ud44d", // 푍
  "\\mathsl{\\Alpha}": "\ud6fc", // 훼
  "\\mathsl{\\Beta}": "\ud6fd", // 훽
  "\\mathsl{\\Chi}": "\ud6f8", // 훸
  "\\mathsl{\\Delta}": "\ud6ff", // 훿
  "\\mathsl{\\Epsilon}": "\ud700", // 휀
  "\\mathsl{\\Eta}": "\ud702", // 휂
  "\\mathsl{\\Gamma}": "\ud6fe", // 훾
  "\\mathsl{\\Iota}": "\ud704", // 휄
  "\\mathsl{\\Kappa}": "\ud705", // 휅
  "\\mathsl{\\Lambda}": "\ud706", // 휆
  "\\mathsl{\\Omega}": "\ud714", // 휔
  "\\mathsl{\\Phi}": "\ud711", // 휑
  "\\mathsl{\\Pi}": "\ud6f1", // 훱
  "\\mathsl{\\Psi}": "\ud6f9", // 훹
  "\\mathsl{\\Rho}": "\ud6f2", // 훲
  "\\mathsl{\\Sigma}": "\ud6f4", // 훴
  "\\mathsl{\\Tau}": "\ud6f5", // 훵
  "\\mathsl{\\Theta}": "\ud703", // 휃
  "\\mathsl{\\Upsilon}": "\ud6f6", // 훶
  "\\mathsl{\\Xi}": "\ud709", // 휉
  "\\mathsl{\\Zeta}": "\ud701", // 휁
  "\\mathsl{\\nabla}": "\ud6fb", // 훻
  "\\mathsl{\\phi}": "\ud719", // 휙
  "\\mathsl{\\varkappa}": "\ud718", // 휘
  "\\mathsl{\\varpi}": "\ud71b", // 휛
  "\\mathsl{\\varrho}": "\ud71a", // 휚
  "\\mathsl{\\varsigma}": "\ud70d", // 휍
  "\\mathsl{\\vartheta}": "\ud717", // 휗
  "\\mathsl{a}": "\ud44e", // 푎
  "\\mathsl{b}": "\ud44f", // 푏
  "\\mathsl{c}": "\ud450", // 푐
  "\\mathsl{d}": "\ud451", // 푑
  "\\mathsl{e}": "\ud452", // 푒
  "\\mathsl{f}": "\ud453", // 푓
  "\\mathsl{g}": "\ud454", // 푔
  "\\mathsl{i}": "\ud456", // 푖
  "\\mathsl{j}": "\ud457", // 푗
  "\\mathsl{k}": "\ud458", // 푘
  "\\mathsl{l}": "\ud459", // 푙
  "\\mathsl{m}": "\ud45a", // 푚
  "\\mathsl{n}": "\ud45b", // 푛
  "\\mathsl{o}": "\ud45c", // 표
  "\\mathsl{p}": "\ud45d", // 푝
  "\\mathsl{q}": "\ud45e", // 푞
  "\\mathsl{r}": "\ud45f", // 푟
  "\\mathsl{s}": "\ud460", // 푠
  "\\mathsl{t}": "\ud461", // 푡
  "\\mathsl{u}": "\ud462", // 푢
  "\\mathsl{v}": "\ud463", // 푣
  "\\mathsl{w}": "\ud464", // 푤
  "\\mathsl{x}": "\ud465", // 푥
  "\\mathsl{y}": "\ud466", // 푦
  "\\mathsl{z}": "\ud467", // 푧
  "\\mathtt{0}": "\ud7f6", // ퟶ
  "\\mathtt{1}": "\ud7f7", // ퟷ
  "\\mathtt{2}": "\ud7f8", // ퟸ
  "\\mathtt{3}": "\ud7f9", // ퟹ
  "\\mathtt{4}": "\ud7fa", // ퟺ
  "\\mathtt{5}": "\ud7fb", // ퟻ
  "\\mathtt{6}": "\ud7fc", // ퟼
  "\\mathtt{7}": "\ud7fd", // ퟽
  "\\mathtt{8}": "\ud7fe", // ퟾
  "\\mathtt{9}": "\ud7ff", // ퟿
  "\\mathtt{A}": "\ud670", // 홰
  "\\mathtt{B}": "\ud671", // 홱
  "\\mathtt{C}": "\ud672", // 홲
  "\\mathtt{D}": "\ud673", // 홳
  "\\mathtt{E}": "\ud674", // 홴
  "\\mathtt{F}": "\ud675", // 홵
  "\\mathtt{G}": "\ud676", // 홶
  "\\mathtt{H}": "\ud677", // 홷
  "\\mathtt{I}": "\ud678", // 홸
  "\\mathtt{J}": "\ud679", // 홹
  "\\mathtt{K}": "\ud67a", // 홺
  "\\mathtt{L}": "\ud67b", // 홻
  "\\mathtt{M}": "\ud67c", // 홼
  "\\mathtt{N}": "\ud67d", // 홽
  "\\mathtt{O}": "\ud67e", // 홾
  "\\mathtt{P}": "\ud67f", // 홿
  "\\mathtt{Q}": "\ud680", // 횀
  "\\mathtt{R}": "\ud681", // 횁
  "\\mathtt{S}": "\ud682", // 횂
  "\\mathtt{T}": "\ud683", // 횃
  "\\mathtt{U}": "\ud684", // 횄
  "\\mathtt{V}": "\ud685", // 횅
  "\\mathtt{W}": "\ud686", // 횆
  "\\mathtt{X}": "\ud687", // 횇
  "\\mathtt{Y}": "\ud688", // 횈
  "\\mathtt{Z}": "\ud689", // 횉
  "\\mathtt{a}": "\ud68a", // 횊
  "\\mathtt{b}": "\ud68b", // 횋
  "\\mathtt{c}": "\ud68c", // 회
  "\\mathtt{d}": "\ud68d", // 획
  "\\mathtt{e}": "\ud68e", // 횎
  "\\mathtt{f}": "\ud68f", // 횏
  "\\mathtt{g}": "\ud690", // 횐
  "\\mathtt{h}": "\ud691", // 횑
  "\\mathtt{i}": "\ud692", // 횒
  "\\mathtt{j}": "\ud693", // 횓
  "\\mathtt{k}": "\ud694", // 횔
  "\\mathtt{l}": "\ud695", // 횕
  "\\mathtt{m}": "\ud696", // 횖
  "\\mathtt{n}": "\ud697", // 횗
  "\\mathtt{o}": "\ud698", // 횘
  "\\mathtt{p}": "\ud699", // 횙
  "\\mathtt{q}": "\ud69a", // 횚
  "\\mathtt{r}": "\ud69b", // 횛
  "\\mathtt{s}": "\ud69c", // 횜
  "\\mathtt{t}": "\ud69d", // 횝
  "\\mathtt{u}": "\ud69e", // 횞
  "\\mathtt{v}": "\ud69f", // 횟
  "\\mathtt{w}": "\ud6a0", // 횠
  "\\mathtt{x}": "\ud6a1", // 횡
  "\\mathtt{y}": "\ud6a2", // 횢
  "\\mathtt{z}": "\ud6a3", // 횣
  "\\mbox{\texteuro}": "\u20ac", // €
  "\\mkern1mu": "\u200a", // no symbol
  "\\mkern4mu": "\u205f", // no symbol
  "\\mu": "\u03bc", // μ
  "\\ngeqslant": "\u2a7e", // ⩾-00338
  "\\nleqslant": "\u2a7d", // ⩽-00338
  "\\nolinebreak": "\u2060", // ⁠
  "\\not =": "\u2260", // ≠
  "\\not<": "\u226e", // ≮
  "\\not>": "\u226f", // ≯
  "\\not\\apid": "\u224b", // ≋-00338
  "\\not\\approx": "\u2249", // ≉
  "\\not\\cong": "\u2247", // ≇
  "\\not\\doteq": "\u2250", // ≐-00338
  "\\not\\equiv": "\u2262", // ≢
  "\\not\\geq": "\u2271", // ≱
  "\\not\\in": "\u2209", // ∉
  "\\not\\kern-0.3em\times": "\u226d", // ≭
  "\\not\\leq": "\u2270", // ≰
  "\\not\\ni": "\u220c", // ∌
  "\\not\\prec": "\u2280", // ⊀
  "\\not\\preceq": "\u2aaf", // ⪯-00338
  "\\not\\sim": "\u2241", // ≁
  "\\not\\simeq": "\u2244", // ≄
  "\\not\\sqsubseteq": "\u22e2", // ⋢
  "\\not\\sqsupseteq": "\u22e3", // ⋣
  "\\not\\subset": "\u2284", // ⊄
  "\\not\\subseteq": "\u2288", // ⊈
  "\\not\\succ": "\u2281", // ⊁
  "\\not\\succeq": "\u2ab0", // ⪰-00338
  "\\not\\supset": "\u2285", // ⊅
  "\\not\\supseteq": "\u2289", // ⊉
  "\\notgreaterless": "\u2279", // ≹
  "\\notlessgreater": "\u2278", // ≸
  "\\nsubseteqq": "\u2ac5", // ⫅-00338
  "\\nsupseteqq": "\u2ac6", // ⫆-00338
  "\\nu": "\u03bd", // ν
  "\\omega": "\u03c9", // ω
  "\\openbracketleft": "\u301a", // 〚
  "\\openbracketright": "\u301b", // 〛
  "\\original": "\u22b6", // ⊶
  "\\perspcorrespond": "\u2306", // ⌆
  "\\phi": "\u03d5", // ϕ
  "\\pi": "\u03c0", // π
  "\\pisces": "\u2653", // ♓
  "\\precedesnotsimilar": "\u22e8", // ⋨
  "\\psi": "\u03c8", // ψ
  "\\r": "\u030a", // ̊
  "\\rho": "\u03c1", // ρ
  "\\rightanglearc": "\u22be", // ⊾
  "\\rule{1em}{1pt}": "\u2015", // ―
  "\\r{U}": "\u016e", // Ů
  "\\r{u}": "\u016f", // ů
  "\\r{}": "\u02da", // ˚
  "\\sagittarius": "\u2650", // ♐
  "\\scorpio": "\u264f", // ♏
  "\\sigma": "\u03c3", // σ
  "\\sim\\joinrel\\leadsto": "\u27ff", // ⟿
  "\\space": "\u0020", //
  "\\sqrint": "\u2a16", // ⨖
  "\\stackrel{*}{=}": "\u2a6e", // ⩮
  "\\starequal": "\u225b", // ≛
  "\\surfintegral": "\u222f", // ∯
  "\\tau": "\u03c4", // τ
  "\\textTheta": "\u03f4", // ϴ
  "\\textasciibreve": "\u02d8", // ˘
  "\\textasciicaron": "\u02c7", // ˇ
  "\\textdagger": "\u2020", // †
  "\\textdaggerdbl": "\u2021", // ‡
  "\\textendash": "\u2013", // –
  "\\textfrac{1}{3}": "\u2153", // ⅓
  "\\textfrac{1}{5}": "\u2155", // ⅕
  "\\textfrac{1}{6}": "\u2159", // ⅙
  "\\textfrac{1}{8}": "\u215b", // ⅛
  "\\textfrac{2}{3}": "\u2154", // ⅔
  "\\textfrac{2}{5}": "\u2156", // ⅖
  "\\textfrac{3}{5}": "\u2157", // ⅗
  "\\textfrac{3}{8}": "\u215c", // ⅜
  "\\textfrac{4}{5}": "\u2158", // ⅘
  "\\textfrac{5}{6}": "\u215a", // ⅚
  "\\textfrac{5}{8}": "\u215d", // ⅝
  "\\textfrac{7}{8}": "\u215e", // ⅞
  "\\textnrleg": "\u019e", // ƞ
  "\\textparagraph": "\u00b6", // ¶
  "\\textquotesingle": "\u0027", // '
  "\\textsection": "\u00a7", // §
  "\\textsterling": "\u00a3", // £
  "\\texttildelow": "\u02dc", // ˜
  "\\texttimes": "\u00d7", // ×
  "\\textvartheta": "\u03d1", // ϑ
  "\\textyen": "\u00a5", // ¥
  "\\tildetrpl": "\u224b", // ≋
  "\\tone{11}": "\u02e9", // ˩
  "\\tone{22}": "\u02e8", // ˨
  "\\tone{33}": "\u02e7", // ˧
  "\\tone{44}": "\u02e6", // ˦
  "\\tone{55}": "\u02e5", // ˥
  "\\truestate": "\u22a7", // ⊧
  "\\u": "\u0306", // ̆
  "\\upsilon": "\u03c5", // υ
  "\\upslopeellipsis": "\u22f0", // ⋰
  "\\uranus": "\u2645", // ♅
  "\\u{A}": "\u0102", // Ă
  "\\u{E}": "\u0114", // Ĕ
  "\\u{G}": "\u011e", // Ğ
  "\\u{I}": "\u012c", // Ĭ
  "\\u{O}": "\u014e", // Ŏ
  "\\u{U}": "\u016c", // Ŭ
  "\\u{\\i}": "\u012d", // ĭ
  "\\u{a}": "\u0103", // ă
  "\\u{e}": "\u0115", // ĕ
  "\\u{g}": "\u011f", // ğ
  "\\u{o}": "\u014f", // ŏ
  "\\u{u}": "\u016d", // ŭ
  "\\varepsilon": "\u025b", // ɛ
  "\\varkappa": "\u03f0", // ϰ
  "\\varphi": "\u03c6", // φ
  "\\varpi": "\u03d6", // ϖ
  "\\varrho": "\u03f1", // ϱ
  "\\varsigma": "\u03c2", // ς
  "\\varsubsetneqq": "\u228a", // ⊊-0FE00
  "\\varsupsetneq": "\u228b", // ⊋-0FE00
  "\\venus": "\u2640", // ♀
  "\\verymuchgreater": "\u22d9", // ⋙
  "\\verymuchless": "\u22d8", // ⋘
  "\\virgo": "\u264d", // ♍
  "\\volintegral": "\u2230", // ∰
  "\\v{C}": "\u010c", // Č
  "\\v{D}": "\u010e", // Ď
  "\\v{E}": "\u011a", // Ě
  "\\v{L}": "\u013d", // Ľ
  "\\v{N}": "\u0147", // Ň
  "\\v{R}": "\u0158", // Ř
  "\\v{S}": "\u0160", // Š
  "\\v{T}": "\u0164", // Ť
  "\\v{Z}": "\u017d", // Ž
  "\\v{c}": "\u010d", // č
  "\\v{d}": "\u010f", // ď
  "\\v{e}": "\u011b", // ě
  "\\v{l}": "\u013e", // ľ
  "\\v{n}": "\u0148", // ň
  "\\v{r}": "\u0159", // ř
  "\\v{s}": "\u0161", // š
  "\\v{t}": "\u0165", // ť
  "\\v{z}": "\u017e", // ž
  "\\xi": "\u03be", // ξ
  "\\zeta": "\u03b6", // ζ
  "\\~": "\u0303", // ̃
  "\\~{A}": "\u00c3", // Ã
  "\\~{I}": "\u0128", // Ĩ
  "\\~{N}": "\u00d1", // Ñ
  "\\~{O}": "\u00d5", // Õ
  "\\~{U}": "\u0168", // Ũ
  "\\~{\\i}": "\u0129", // ĩ
  "\\~{a}": "\u00e3", // ã
  "\\~{n}": "\u00f1", // ñ
  "\\~{o}": "\u00f5", // õ
  "\\~{u}": "\u0169", // ũ
  "\\~A": "\u00c3", // Ã
  "\\~I": "\u0128", // Ĩ
  "\\~N": "\u00d1", // Ñ
  "\\~O": "\u00d5", // Õ
  "\\~U": "\u0168", // Ũ
  "\\~\\i": "\u0129", // ĩ
  "\\~a": "\u00e3", // ã
  "\\~n": "\u00f1", // ñ
  "\\~o": "\u00f5", // õ
  "\\~u": "\u0169", // ũ
  "{\fontencoding{LECO}selectfontchar177}": "\u0311", // ̑
  "{\fontencoding{LECO}selectfontchar184}": "\u0318", // ̘
  "{\fontencoding{LECO}selectfontchar185}": "\u0319", // ̙
  "{\fontencoding{LECO}selectfontchar203}": "\u032b", // ̫
  "{\fontencoding{LECO}selectfontchar207}": "\u032f", // ̯
  "{\fontencoding{LECO}selectfontchar215}": "\u0337", // ̷
  "{\fontencoding{LECO}selectfontchar216}": "\u0338", // ̸
  "{\fontencoding{LECO}selectfontchar218}": "\u033a", // ̺
  "{\fontencoding{LECO}selectfontchar219}": "\u033b", // ̻
  "{\fontencoding{LECO}selectfontchar220}": "\u033c", // ̼
  "{\fontencoding{LECO}selectfontchar221}": "\u033d", // ̽
  "{\fontencoding{LECO}selectfontchar225}": "\u0361", // ͡
  "{\fontencoding{LEIP}selectfontchar202}": "\u027f", // ɿ
  "{\fontencoding{LEIP}selectfontchar61}": "\u0258", // ɘ
  "{\fontencoding{LELA}selectfontchar195}": "\u01ba", // ƺ
  "{\fontencoding{LELA}selectfontchar201}": "\u013f", // Ŀ
  "{\fontencoding{LELA}selectfontchar202}": "\u0140", // ŀ
  "{\fontencoding{LELA}selectfontchar40}": "\u0126", // Ħ
  "{\fontencoding{LELA}selectfontchar47}": "\u0166", // Ŧ
  "{\fontencoding{LELA}selectfontchar63}": "\u0167", // ŧ
  "{\fontencoding{LELA}selectfontchar91}": "\u0138", // ĸ
  "{\rlap{\textbackslash}{{/}!!{/}}}": "\u2AFD-020E5", // ⫽-020E5
  "{^1}": "\u00b9", // ¹
  "{^2}": "\u00b2", // ²
  "{^3}": "\u00b3", // ³
  "{_ast}": "\u2217", // ∗
  "{{/}!!{/}}": "\u2afd", // ⫽"
} as const;
