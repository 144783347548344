/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import routePaths from "routes/routePaths";
import { useNavigate } from "react-router-dom";

const UploadCollectionsLimitExceededDialog: React.FC<{
  setOpen: () => void;
  proceedWithFileUploadOnly: () => void;
}> = ({ setOpen, proceedWithFileUploadOnly }) => {
  const navigate = useNavigate();
  return (
    <Dialog fullWidth maxWidth="sm" onClose={setOpen} open>
      <DialogTitle>
        Collections limit exceeded
        <IconButton aria-label="close" onClick={setOpen}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Alert color="warning">
          You are at your collections limit. Free tier only supports{" "}
          <strong>2</strong> collections. Please{" "}
          <Link
            color="secondary"
            fontWeight={500}
            underline="hover"
            onClick={() => {
              navigate(routePaths.workspaceBilling);
            }}
          >
            upgrade
          </Link>{" "}
          your plan if you wish to create more collections or proceed with file
          upload directly.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          size="medium"
          onClick={proceedWithFileUploadOnly}
        >
          Proceed with file upload only
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadCollectionsLimitExceededDialog;
