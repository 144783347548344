import React from "react";
import { MathJax } from "better-react-mathjax";

// cache for math expressions
let expressionsLut: Record<string, any> = {};

// catch $$...$$ or $...$ | \(...\)
const inlineMathExpression = /(\$+)(?:(?!\1)[\s\S])*\1|\\\(.*?\)/gm;

const displayTextWithMath = (textProp: string | string[]): any => {
  const text = Array.isArray(textProp) ? textProp.join(", ") : textProp;
  const expressions = text.match(inlineMathExpression) || [];
  if (expressions.length === 0) return text;

  if (Object.keys(expressionsLut).length > 100_000) {
    expressionsLut = {};
  }
  const lutKey = text + expressions.length;
  if (expressionsLut[lutKey]) {
    return expressionsLut[lutKey];
  }
  const element = <MathJax>{text}</MathJax>;
  expressionsLut[lutKey] = element;
  // reset lookup-table
  if (Object.keys(expressionsLut).length > 100_000) {
    expressionsLut = {};
  }
  return element;
};

export default displayTextWithMath;
