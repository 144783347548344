import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Popover,
  Skeleton,
  TextField,
  styled,
} from "@mui/material";
import DrawOutlinedIcon from "@mui/icons-material/DrawOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { LoadingButton } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { AICreateTemplateBlock } from "models/api/response.types";
import { terminal_status_names } from "utils/aiHelpers";
import aiCreateService, {
  useAICreateTemplateBlocks,
} from "api/aiCreateService";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import breaks from "remark-breaks";

const Wrapper = styled(Box)(({ theme }) => ({
  "& .question-container": {
    display: "flex",
    gap: "1rem",
    alignItems: "start",
  },
  "& .answer-container": {
    marginTop: "1rem",
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
}));

const AIPromptBlockContentType: React.FC<{ block: AICreateTemplateBlock }> = ({
  block,
}) => {
  const { upsertCachedAICreateTemplateBlock } = useAICreateTemplateBlocks(
    block.project_id
  );
  const [text, setText] = useState<string>(block.ai_prompt);
  const [example, setExample] = useState<string>(block.ai_example);
  const [exampleTrigger, setExampleTrigger] = useState<boolean>(false);
  const [popoverAnchorEl, setPopoverAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const { isLoading, isFailed } = useMemo(() => {
    if (block) {
      return {
        isLoading: !terminal_status_names.includes(block.response_task_status),
        isFailed:
          terminal_status_names.includes(block.response_task_status) &&
          block.response_task_status !== "success",
      };
    }
    return {
      isLoading: false,
      isFailed: false,
    };
  }, [block]);

  useEffect(() => {
    const saver = setTimeout(
      () => {
        const promptDifferent = text !== block.ai_prompt;
        const exampleDifferent = example !== block.ai_example && exampleTrigger;
        if (promptDifferent || exampleDifferent) {
          aiCreateService
            .updateAICreateTemplateBlock(block.project_id, block.id, {
              ...(promptDifferent && {
                ai_prompt: text,
              }),
              ...(exampleDifferent && {
                ai_example: example,
              }),
            })
            .then(({ data }) => {
              upsertCachedAICreateTemplateBlock(data);
            });
        }
        if (exampleTrigger) {
          setExampleTrigger(false);
          setPopoverAnchorEl(null);
        }
      },
      exampleTrigger ? 0 : 1000
    );

    return () => {
      clearTimeout(saver);
    };
  }, [text, exampleTrigger]);

  const runAi = () => {
    aiCreateService
      .rerunAICreateTemplateBlock(block.project_id, block.id)
      .then(({ data }) => {
        upsertCachedAICreateTemplateBlock(data);
      });
  };

  return (
    <>
      <Wrapper className="block-content">
        <Box className="question-container">
          <TextField
            variant="outlined"
            color="primary"
            value={text}
            size="small"
            placeholder="Enter a writing prompt for the AI"
            fullWidth
            multiline
            minRows={1}
            maxRows={12}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
          <Button
            size="small"
            color="primary"
            variant="text"
            startIcon={
              block?.ai_example ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <ModeEditOutlineOutlinedIcon fontSize="small" />
              )
            }
            onClick={(e) => {
              setPopoverAnchorEl(e.currentTarget);
            }}
          >
            Example
          </Button>
          <LoadingButton
            size="small"
            color="primary"
            variant="text"
            disabled={text.length === 0}
            loading={isLoading}
            onClick={runAi}
            startIcon={
              block?.content ? (
                <RestartAltOutlinedIcon fontSize="small" />
              ) : (
                <DrawOutlinedIcon fontSize="small" />
              )
            }
          >
            Write
          </LoadingButton>
        </Box>
        <Box className="answer-container">
          {!isLoading ? (
            <ReactMarkdown
              remarkPlugins={[remarkGfm, breaks]}
              linkTarget="_blank"
            >
              {!isFailed
                ? block.content
                : "Our servers are currently overloaded, please try again later. Thank you for your patience as we continue to scale our services."}
            </ReactMarkdown>
          ) : (
            <>
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </>
          )}
        </Box>
      </Wrapper>
      <Popover
        open={!!popoverAnchorEl}
        anchorEl={popoverAnchorEl}
        onClose={() => {
          if (example !== block.ai_example) {
            setExample(block.ai_example);
          }
          setPopoverAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          padding: "1rem",
        }}
      >
        <Box
          sx={{
            padding: "1rem",
            width: "550px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            variant="outlined"
            color="primary"
            autoFocus
            value={example}
            size="small"
            placeholder="Type or paste an example to guide the AI's writing style."
            fullWidth
            multiline
            minRows={4}
            maxRows={12}
            onChange={(e) => setExample(e.target.value)}
          />
          <Button
            color="primary"
            size="small"
            variant="contained"
            sx={{
              margin: "1rem 0 0 auto",
            }}
            disabled={example === block.ai_example}
            onClick={() => {
              setExampleTrigger(true);
            }}
          >
            Save
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AIPromptBlockContentType;
