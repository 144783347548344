import axios, {
  AxiosError,
  AxiosPromise,
  AxiosResponse,
  CancelToken as CancelTokenType,
} from "axios";
import {
  SuccessResponse,
  TagCreateBody,
  TagList,
  Tag,
  DocumentReadResponse,
} from "models/api/response.types";
import { useCallback, useMemo } from "react";
import {
  useQuery,
  useQueryClient,
  useMutation,
  UseMutationResult,
} from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";
import handleAxiosError from "utils/handleAxiosAlert";

const fetchTags = (
  organizationId: number,
  options?: { cancelToken?: CancelTokenType }
): AxiosPromise<TagList> => {
  return axios.get(`/api/organization/${organizationId}/tag/list`, {
    cancelToken: options?.cancelToken,
  });
};

const createTag = (tag: TagCreateBody): AxiosPromise<Tag> => {
  return axios.post("/api/tag/create", tag);
};

const createMultipleTags = (tags: TagCreateBody[]): AxiosPromise<Tag[]> => {
  return axios.post("/api/tag/create_many", { tags });
};

export interface TagUpdateVariables {
  id: number;
  name: string;
  color: string;
}

const updateTag = (tag: TagUpdateVariables): AxiosPromise<Tag> => {
  return axios.post(`/api/tag/${tag.id}/update`, {
    color: tag.color,
    name: tag.name,
  });
};

const deleteTag = (id: number): AxiosPromise<SuccessResponse> => {
  return axios.delete(`/api/tag/${id}/delete`);
};

const addTagToDocuments = (
  id: number,
  documentIds: number[]
): AxiosPromise<SuccessResponse> => {
  return axios.post(`/api/tag/${id}/add_documents`, {
    document_ids: documentIds,
  });
};

const removeTagFromDocuments = (
  id: number,
  documentIds: number[]
): AxiosPromise<SuccessResponse> => {
  return axios.post(`api/tag/${id}/remove_documents`, {
    document_ids: documentIds,
  });
};

const tagService = {
  fetchTags,
  createMultipleTags,
  createTag,
  updateTag,
  deleteTag,
  addTagToDocuments,
  removeTagFromDocuments,
};

export default tagService;

interface CancellablePromise<T> extends Promise<T> {
  cancel?: () => void;
}

interface UseTagsResult {
  tags: Tag[] | undefined;
  tagsQueryKey: string[];
  tagsIsLoading: boolean;
  tagsIsFetching: boolean;
  getCachedTagById: (id: number) => [item: Tag | undefined, index: number];
  getCachedTagByName: (name: string) => [item: Tag | undefined, index: number];
  upsertCachedTag: (newTag: Tag) => Tag | undefined;
  removeCachedTag: (id: number) => Tag | undefined;
  updateTagMutation: UseMutationResult<
    AxiosResponse<Tag>,
    unknown,
    Readonly<TagUpdateVariables>,
    {
      previousTag: Tag | undefined;
    }
  >;
  deleteTagMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    number,
    {
      removedTag: Tag | undefined;
    }
  >;
  createTagMutation: UseMutationResult<
    AxiosResponse<Tag>,
    unknown,
    TagCreateBody,
    unknown
  >;
  createTagsMutation: UseMutationResult<
    AxiosResponse<Tag[]>,
    unknown,
    TagCreateBody[],
    unknown
  >;
  addTagToDocumentsMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    {
      tagId: number;
      documentIds: number[];
    },
    {
      documentsUpdated: number[];
    }
  >;
  removeTagFromDocumentsMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    {
      tagId: number;
      documentIds: number[];
    },
    {
      documentsUpdated: number[];
    }
  >;
}

export const useTags = (organizationId: number | undefined): UseTagsResult => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();
  const tagsQueryKey = [`organization/${organizationId}/tags`];
  const documentsQueryKey = [`organization/${organizationId}/documents`];
  const {
    data: tags,
    isLoading: tagsIsLoading,
    isFetching: tagsIsFetching,
  } = useQuery<unknown, unknown, Tag[], any>(
    tagsQueryKey,
    (): CancellablePromise<Tag[]> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = tagService.fetchTags(organizationId as number, {
        cancelToken: source.token,
      });
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: Tag[] }) => responseData
      );
    },
    {
      enabled: !!organizationId,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch, { user }),
    }
  );

  const tagIndexById = useMemo(
    () =>
      tags?.reduce((lut: { [id: number]: number }, tag, index) => {
        // eslint-disable-next-line no-param-reassign
        lut[tag.id] = index;
        return lut;
      }, {}),
    [tags]
  );

  const tagIndexByName = useMemo(
    () =>
      tags?.reduce((lut: { [name: string]: number }, tag, index) => {
        // eslint-disable-next-line no-param-reassign
        lut[tag.name] = index;
        return lut;
      }, {}),
    [tags]
  );

  const getCachedTagById: (
    id: number
  ) => [item: Tag | undefined, index: number] = useCallback(
    (id: number) =>
      tags && tagIndexById && id in tagIndexById
        ? [tags[tagIndexById[id]], tagIndexById[id]]
        : [undefined, -1],
    [tagIndexById]
  );

  const getCachedTagByName: (
    name: string
  ) => [item: Tag | undefined, index: number] = useCallback(
    (name: string) =>
      tags && tagIndexByName && name in tagIndexByName
        ? [tags[tagIndexByName[name]], tagIndexByName[name]]
        : [undefined, -1],
    [tagIndexByName]
  );

  const removeCachedTag = (id: number): Tag | undefined => {
    const [currentTag, index] = getCachedTagById(id);
    if (tags && currentTag) {
      const newTags = [...tags];
      newTags.splice(index, 1);
      queryClient.setQueryData<Tag[]>(tagsQueryKey, newTags);
      return currentTag;
    }
    return undefined;
  };

  const upsertCachedTag = (newTag: Tag): Tag | undefined => {
    const [currentTag, index] = getCachedTagById(newTag.id);
    if (tags) {
      const newTags = [...tags];
      if (index > -1) {
        newTags.splice(index, 1, newTag);
      } else {
        newTags.unshift(newTag);
      }
      queryClient.setQueryData<Tag[]>(tagsQueryKey, newTags);
    } else {
      queryClient.setQueryData<Tag[]>(tagsQueryKey, [newTag]);
    }
    return currentTag;
  };

  const updateTagMutation = useMutation(
    (variables: Readonly<TagUpdateVariables>) =>
      tagService.updateTag(variables),
    {
      retry: false,
      onMutate: async (variables: Readonly<TagUpdateVariables>) => {
        await queryClient.cancelQueries(tagsQueryKey);
        const [currentTag] = getCachedTagById(variables.id);
        return {
          previousTag: currentTag
            ? upsertCachedTag({ ...currentTag, ...variables })
            : undefined,
        };
      },
      onSuccess: (response) => {
        if (response?.data) upsertCachedTag(response.data);
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(tagsQueryKey);
      },
    }
  );

  const deleteTagMutation = useMutation(
    (tagId: number) => tagService.deleteTag(tagId),
    {
      retry: false,
      onMutate: async (tagId: number) => {
        await queryClient.cancelQueries(tagsQueryKey);
        const removedTag = removeCachedTag(tagId);
        return { removedTag };
      },
      onSuccess: (response, payload, context) => {
        const documents =
          queryClient.getQueryData<DocumentReadResponse[]>(documentsQueryKey);
        if (documents) {
          const newDocuments = [...documents];
          newDocuments.forEach((document) => {
            if (
              context?.removedTag &&
              document.tag_ids.includes(context.removedTag.id)
            ) {
              const oldTagIndex = document.tag_ids.indexOf(
                context.removedTag.id
              );
              if (oldTagIndex > -1) {
                document.tag_ids.splice(oldTagIndex, 1);
              }
            }
          });
          queryClient.setQueryData<DocumentReadResponse[]>(
            documentsQueryKey,
            newDocuments
          );
        }
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(tagsQueryKey);
      },
    }
  );

  const createTagMutation = useMutation(
    (variables: TagCreateBody) => tagService.createTag(variables),
    {
      retry: false,
      onSuccess: (response, payload) => {
        if (response?.data) {
          upsertCachedTag(response.data);
          const documents =
            queryClient.getQueryData<DocumentReadResponse[]>(documentsQueryKey);
          if (documents) {
            const newDocuments = [...documents];
            newDocuments.forEach((document) => {
              if (payload.add_document_ids?.includes(document.id)) {
                document.tag_ids.push(response.data.id);
              }
            });
            queryClient.setQueryData<DocumentReadResponse[]>(
              documentsQueryKey,
              newDocuments
            );
          }
        }
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(tagsQueryKey);
      },
    }
  );

  const createTagsMutation = useMutation(
    (variables: TagCreateBody[]) => tagService.createMultipleTags(variables),
    {
      retry: false,
      onSuccess: (response) => {
        if (response?.data) {
          response.data.forEach((tag) => upsertCachedTag(tag));
        }
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(tagsQueryKey);
      },
    }
  );

  const addTagToDocumentsMutation = useMutation(
    (variables: { tagId: number; documentIds: number[] }) =>
      tagService.addTagToDocuments(variables.tagId, variables.documentIds),
    {
      retry: false,
      onMutate: async (variables: { tagId: number; documentIds: number[] }) => {
        const documents =
          queryClient.getQueryData<DocumentReadResponse[]>(documentsQueryKey);
        const documentsUpdated: number[] = [];
        if (documents) {
          const idSet = new Set(variables.documentIds);
          const newDocuments = documents.map((document) => {
            if (idSet.has(document.id)) {
              documentsUpdated.push(document.id);
              return {
                ...document,
                tag_ids: [...document.tag_ids, variables.tagId],
              };
            }
            return document;
          });
          queryClient.setQueryData<DocumentReadResponse[]>(
            documentsQueryKey,
            newDocuments
          );
        }
        return { documentsUpdated };
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(documentsQueryKey);
      },
    }
  );

  const removeTagFromDocumentsMutation = useMutation(
    (variables: { tagId: number; documentIds: number[] }) =>
      tagService.removeTagFromDocuments(variables.tagId, variables.documentIds),
    {
      retry: false,
      onMutate: async (variables: { tagId: number; documentIds: number[] }) => {
        const documents =
          queryClient.getQueryData<DocumentReadResponse[]>(documentsQueryKey);
        const documentsUpdated: number[] = [];
        if (documents) {
          const idSet = new Set(variables.documentIds);
          const newDocuments = documents.map((document) => {
            if (idSet.has(document.id)) {
              documentsUpdated.push(document.id);
              return {
                ...document,
                tag_ids: document.tag_ids.filter(
                  (id) => id !== variables.tagId
                ),
              };
            }
            return document;
          });
          queryClient.setQueryData<DocumentReadResponse[]>(
            documentsQueryKey,
            newDocuments
          );
        }
        return { documentsUpdated };
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(documentsQueryKey);
      },
    }
  );

  return {
    tags,
    tagsQueryKey,
    tagsIsLoading,
    tagsIsFetching,
    getCachedTagById,
    getCachedTagByName,
    upsertCachedTag,
    removeCachedTag,
    updateTagMutation,
    deleteTagMutation,
    createTagMutation,
    createTagsMutation,
    addTagToDocumentsMutation,
    removeTagFromDocumentsMutation,
  };
};
