import React from "react";
import { Snackbar, styled } from "@mui/material";

export const StyledSnackbar = styled(Snackbar)`
  z-index: 1500;
  .MuiSnackbarContent-root {
    width: 100%;
    padding: 0;
    border-radius: 4px;
    background-color: #fff;
    min-width: 0;
  }
  .MuiSnackbarContent-message {
    max-width: 700px;
    min-width: 350px;
    color: #303030;
    padding: 0;
  }
  .loading-message {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    & .MuiLinearProgress-root {
      width: 100%;
      margin-right: 1rem;
    }
    & p {
      margin: 0;
    }
    & button {
      margin-left: 1rem;
      &:hover {
        background: #ececec;
      }
    }
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .MuiAlert-root {
    display: flex;
    align-items: center;
    .MuiAlert-message {
      display: flex;
      align-items: center;
      gap: 0 1rem;
    }
    ,
    .MuiAlert-icon {
      padding: 0;
    }
    ,
    .MuiAlert-action {
      padding: 0;
      margin-left: auto;
    }
  }
`;

interface ISnackBarProps {
  message: JSX.Element;
  anchorPosition: {
    vertical: any;
    horizontal: any;
  };
  autoHideDuration: number | null;
}

const SnackBar: React.FC<{
  open: boolean;
  close?: () => void;
  snackBarProps: ISnackBarProps;
}> = ({ open, close, snackBarProps }) => {
  return (
    <StyledSnackbar
      anchorOrigin={snackBarProps.anchorPosition}
      onClose={() => {
        if (close) {
          close();
        }
      }}
      open={open}
      autoHideDuration={snackBarProps.autoHideDuration}
      message={snackBarProps.message}
    />
  );
};

export default SnackBar;
