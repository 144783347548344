import React, { useState } from "react";
import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import SouthOutlinedIcon from "@mui/icons-material/SouthOutlined";
import NorthOutlinedIcon from "@mui/icons-material/NorthOutlined";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import { Check } from "@mui/icons-material";
import { sortOptions } from "utils/projectSort";
import clsx from "clsx";

export const RootContainer = styled("div")(({ theme }) => ({
  "& .simpleMenu": {
    "& .MuiMenuItem-root": {
      paddingRight: "2rem",
      paddingLeft: "1rem",
    },
  },
  "& .sortButton.selected": {
    background: theme.menu.selected,
  },
}));

const SortProjectMenu: React.FC<{
  sort: { key: string; order: string };
  setProjectSort: (sort: { key: string; order: string }) => void;
}> = ({ sort, setProjectSort }) => {
  const [sortDialog, setSortDialog] = useState<Element | null>(null);
  const handleClickSortDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSortDialog(event.currentTarget);
  };

  const handleCloseSortDialog = () => {
    setSortDialog(null);
  };

  const setSortOrderKey = (sortKey: string) => {
    if (sort.key !== sortKey) {
      setProjectSort({ key: sortKey, order: sort.order });
    }
    handleCloseSortDialog();
  };

  const setSortOrder = (sortOrder: "ascending" | "descending") => {
    if (sort.order !== sortOrder) {
      setProjectSort({ key: sort.key, order: sortOrder });
    }
    handleCloseSortDialog();
  };

  return (
    <RootContainer>
      <Button
        variant="text"
        color="primary"
        disableElevation
        onClick={handleClickSortDialog}
        startIcon={<SortOutlinedIcon fontSize="small" />}
        endIcon={
          sort.order === "ascending" ? (
            <NorthOutlinedIcon fontSize="small" />
          ) : (
            <SouthOutlinedIcon fontSize="small" />
          )
        }
        className={clsx("sortButton", { selected: sortDialog })}
      >
        <Typography sx={{ whiteSpace: "nowrap" }} variant="body2">
          {sortOptions[sort.key].label}
        </Typography>
      </Button>
      <Menu
        id="simple-menu"
        className="simpleMenu"
        anchorEl={sortDialog}
        keepMounted
        open={Boolean(sortDialog)}
        onClose={handleCloseSortDialog}
      >
        <MenuItem onClick={() => setSortOrderKey("title")}>
          {sort.key === "title" && (
            <ListItemIcon className="menu-icon">
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={sort.key !== "title"}
            primary={<Typography variant="body2">Title</Typography>}
          />
        </MenuItem>
        <MenuItem onClick={() => setSortOrderKey("modified_at")}>
          {sort.key === "modified_at" && (
            <ListItemIcon className="menu-icon">
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={sort.key !== "modified_at"}
            primary={<Typography variant="body2">Modified At</Typography>}
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setSortOrder("ascending")}>
          {sort.order === "ascending" && (
            <ListItemIcon className="menu-icon">
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={sort.order !== "ascending"}
            primary={<Typography variant="body2">Ascending</Typography>}
          />
        </MenuItem>
        <MenuItem onClick={() => setSortOrder("descending")}>
          {sort.order === "descending" && (
            <ListItemIcon className="menu-icon">
              <Check fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText
            inset={sort.order !== "descending"}
            primary={<Typography variant="body2">Descending</Typography>}
          />
        </MenuItem>
      </Menu>
    </RootContainer>
  );
};

export default SortProjectMenu;
