export interface ISortOrder {
  key: string;
  order: "ascending" | "descending";
}

interface SortOption {
  label: string;
  compare: (
    a: { title: string; modified_at: string },
    b: { title: string; modified_at: string },
    order: string
  ) => number;
}

export const sortOptions: Record<string, SortOption> = {
  title: {
    label: "Title",
    compare: (a, b, order) => {
      if (a.title !== undefined && b.title !== undefined) {
        const list = a.title
          .toLocaleLowerCase()
          .localeCompare(b.title.toLocaleLowerCase());
        return order === "ascending" ? list : -list;
      }
      if (a.title !== undefined) {
        return order === "ascending" ? 1 : -1;
      }
      if (b.title !== undefined) {
        return order === "ascending" ? -1 : 1;
      }
      return 0;
    },
  },
  modified_at: {
    label: "Modified At",
    compare: (a, b, order) => {
      if (a.modified_at && b.modified_at) {
        const list = a.modified_at
          .toLocaleLowerCase()
          .localeCompare(b.modified_at.toLocaleLowerCase());
        return order === "ascending" ? list : -list;
      }
      if (a.modified_at) {
        return order === "ascending" ? 1 : -1;
      }
      if (b.modified_at) {
        return order === "ascending" ? -1 : 1;
      }

      return 0;
    },
  },
};
