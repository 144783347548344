/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import { persistor, store } from "store/index";
import App from "App";
import { MathJaxContext } from "better-react-mathjax";
import { DEV_HOSTNAME, DEV_URL, PROD_HOSTNAME, PROD_URL } from "company-config";
import * as Sentry from "@sentry/react";

Sentry.init({
  enabled:
    window.location.hostname !== "localhost" &&
    window.location.hostname !== "127.0.0.1" &&
    window.location.hostname !== DEV_HOSTNAME,
  dsn: "https://30d8d1e1bbbe4f2bb3e780b6e24c983d@o4505365823488000.ingest.sentry.io/4505393409163264",
  tunnel: "https://cite.petal.org/api/sentry/sentry",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/cite\.petal\.org\/api\//],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 120000,
      refetchOnReconnect: true,
    },
  },
});

const MathJaxConfig = {
  tex: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    packages: { "[+]": ["upgreek"] },
  },
  svg: {
    fontCache: "global",
  },
  loader: { load: ["[tex]/upgreek"] },
};

// If running on localhost, use staging server
axios.defaults.baseURL = DEV_URL;
if (window.location.hostname === PROD_HOSTNAME) {
  axios.defaults.baseURL = PROD_URL;
}
axios.defaults.headers.post["Content-Type"] = "application/json";

// Override baseURL using localStorage.setItem("baseURL", "url");
if (
  localStorage.getItem("baseURL") !== null &&
  localStorage.getItem("baseURL") !== axios.defaults.baseURL
) {
  if (
    // eslint-disable-next-line no-alert
    window.confirm(
      `Press OK to continue using baseURL ${localStorage.getItem(
        "baseURL"
      )}? Press Cancel to use ${axios.defaults.baseURL}.`
    )
  ) {
    axios.defaults.baseURL = localStorage.getItem("baseURL") || undefined;
    console.log(`Using `);
  } else {
    localStorage.removeItem("baseURL");
  }
}

const container = document.getElementById("app");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <QueryClientProvider client={client}>
      <PersistGate persistor={persistor}>
        <MathJaxContext config={MathJaxConfig}>
          <App />
        </MathJaxContext>
      </PersistGate>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  </Provider>
);
