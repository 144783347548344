/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QARequestBody } from "models/api/response.types";
import { IState } from "store/reducers";
import { AILanguage } from "utils/aiHelpers";

interface TextSelectionType extends QARequestBody {
  documentId: number;
}

export interface AIState {
  textSelectionQuestion?: TextSelectionType;
  aiLanguage: AILanguage;
}

export const initialState: AIState = {
  textSelectionQuestion: undefined,
  aiLanguage: "English (US)",
};

// session slice is a slice that used for user session
const sessionSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    setTextSelectionQuestion(
      state,
      action: PayloadAction<TextSelectionType | undefined>
    ) {
      state.textSelectionQuestion = action.payload;
    },
    setAILanguage(state, action: PayloadAction<AILanguage>) {
      state.aiLanguage = action.payload;
    },
  },
});

export const { reducer } = sessionSlice;
export const { setTextSelectionQuestion, setAILanguage } = sessionSlice.actions;

export const selectTextSelectionQuestion = createSelector(
  (state: IState) => state.ai.textSelectionQuestion,
  (item) => item
);

export const selectAILanguage = createSelector(
  (state: IState) => state.ai.aiLanguage,
  (item) => item
);
