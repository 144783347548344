/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from "react";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {
  Box,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { setSideBarOpen } from "store/features/browser/slice";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import ListIcon from "@mui/icons-material/List";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch } from "react-redux";
import AICreateProjectDialog from "components/Dialogs/AICreate/AICreateProjectDialog";
import clsx from "clsx";
import {
  AICreateProjectList,
  AICreateProject as AICreateProjectType,
} from "models/api/response.types";
import MainLoader from "components/helpers/MainLoader";
import { sortOptions } from "utils/projectSort";
import AICreateDeleteProjectDialog from "components/Dialogs/AICreate/AICreateDeleteProjectDialog";
import { AiCreateProjectDashboardOnboarding } from "components/Onboarding/AiCreateOnboarding";
import AICreateProject from "./AICreateProject";
import SortProjectMenu from "./SortProjectMenu";

interface ProjProps {
  projectcolor: string;
  noproject: string;
}

export const projectColors: { [k: string]: any } = {
  user_defined: "#184e77",
  blog_post: "#ff9800",
  marketing_research_report: "#9c27b0",
  literature_review: "#4caf50",
  personal_bio: "#3f51b5",
  job_description: "#68a691",
  short_essay: "#ffbc42",
};

export const projectIcons: { [k: string]: any } = {
  user_defined: <TuneOutlinedIcon className="icon" fontSize="inherit" />,
  blog_post: <BookOutlinedIcon className="icon" fontSize="inherit" />,
  personal_bio: <PersonOutlinedIcon className="icon" fontSize="inherit" />,
  marketing_research_report: (
    <TravelExploreIcon className="icon" fontSize="inherit" />
  ),
  short_essay: <TextSnippetOutlinedIcon className="icon" fontSize="inherit" />,
  job_description: (
    <BusinessCenterOutlinedIcon className="icon" fontSize="inherit" />
  ),
  literature_review: (
    <FindInPageOutlinedIcon className="icon" fontSize="inherit" />
  ),
};

export interface AICreateProjectTemplate {
  type: string;
  label: string;
  description: string;
  color: string;
  icon: JSX.Element;
  short_label: string;
  topic_summary: string;
  topic_main_points: string;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    backgroundColor: theme.background.light,
    maxWidth: "500px",
  },
  "& .tooltip-container": {
    padding: "0.5rem 1rem",
    "& .title": {
      marginBottom: "0.5rem",
      color,
    },
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  overflow: " auto",
  width: "100%",
  height: "100%",
  overflowX: "hidden",
  "& .main-container": {
    maxWidth: "1270px",
    padding: "1rem",
    margin: "0 auto",
    overflow: "hidden",
    "& .templates-container": {
      overflow: "hidden",
      margin: "0 auto",
      maxWidth: "100%",
      "& .templates-header": {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        marginBottom: "0.5rem",
      },
      "& .default-templates-list": {
        flex: 1,
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        paddingBottom: "0.5rem",
        "&.start-new": {
          display: "grid",
          gridTemplateColumns:
            "repeat(auto-fill, minmax(300px, 1fr))" /* Adjust the minmax value */,
          gap: "1rem",
        },
        "&:not(.start-new)": {
          display: "flex",
          alignItems: "center",
          overflowX: "auto",
          flexWrap: "none",
        },
      },
    },
    "& .projects-container": {
      marginTop: "3rem",
      "& .projects-header": {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        marginBottom: "0.5rem",
        "& .project-actions": {
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          marginLeft: "auto",
          "& .MuiToggleButtonGroup-root": {
            backgroundColor: theme.background.light,
          },
        },
      },
      "& .project-list-container": {
        "&.card": {
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
        },
        "&.list": {
          display: "flex",
          flexDirection: "column",
        },
        gap: "1rem",
      },
      "& .no-recent-projects": {
        marginTop: "1rem",
      },
    },
  },
}));

const TemplateWrapper = styled(Box)<ProjProps>(
  ({ theme, projectcolor, noproject }) => ({
    "& .header": {
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
      ...(!noproject && {
        flexDirection: "column",
        justifyContent: "center",
      }),
    },
    background: theme.background.light,
    borderRadius: "4px",
    padding: "0.5rem",
    border: `1px solid ${theme.grey.light}`,
    display: "flex",
    flexDirection: "column",
    transition: "all 0.3s",
    cursor: "pointer",
    ...(noproject
      ? {
          height: "200px",
        }
      : {
          minWidth: "140px",
          width: "140px",
          height: "160px",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }),
    "&:hover": {
      borderColor: projectcolor,
    },
    "& svg": {
      fill: projectcolor,
      ...(noproject
        ? {
            width: "25px",
            height: "25px",
          }
        : {
            width: "30px",
            height: "30px",
            marginBottom: "0.5rem",
          }),
    },
    "& .description": {
      ...(noproject
        ? {
            marginTop: "0.5rem",
          }
        : {
            display: "none",
          }),
    },
  })
);

const AICreateProjectDashboard: React.FC<{
  setSelectedProjectId: (projId: number) => void;
  projects?: AICreateProjectList;
}> = ({ setSelectedProjectId, projects }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [createCustomProject, setCreateCustomProject] =
    useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<
    AICreateProjectTemplate | undefined
  >(undefined);
  const [projectSort, setProjectSort] = useState({
    key: "modified_at",
    order: "descending",
  });
  const [view, setView] = useState<"list" | "card">("card");
  const [editProject, setEditProject] = useState<
    AICreateProjectType | undefined
  >(undefined);
  const [deleteProjectId, setDeleteProjectId] = useState<number | undefined>(
    undefined
  );

  const projectTemplates: Array<AICreateProjectTemplate> = [
    {
      type: "user_defined",
      label: "Blank Project",
      description:
        "Create a new project from scratch. You can specify the project's topic and description, select source documents, extract knowledge from the references via questions, and write a report based on that knowledge.",
      color: projectColors.user_defined,
      icon: projectIcons.user_defined,
      short_label: "Project",
      topic_summary: "Project about...",
      topic_main_points: "",
    },
    {
      type: "personal_bio",
      label: "Personal Bio",
      description: `Synthesize insights on current job relevance, career achievements, influential publications, 
      work history, personal passions, and educational background, 
      offering a multifaceted perspective on their industry impact.`,
      color: projectColors.personal_bio,
      icon: projectIcons.personal_bio,
      short_label: "Personal Bio",
      topic_summary: "Personal Bio about...",
      topic_main_points: `Relation to current professional engagement
Accomplishment highlights
Major publications (if any)
Professional experience
Personal interests
Training and Education`,
    },
    {
      type: "job_description",
      label: "Job Description",
      description: `Provide an integrated analysis of industry experts, covering their current professional roles, 
      key accomplishments, notable publications, extensive experience, personal interests, and educational background, 
      offering a holistic view of their career trajectories and contributions.`,
      color: projectColors.job_description,
      icon: projectIcons.job_description,
      short_label: "Job Description",
      topic_summary: "Job Description about...",
      topic_main_points: `Job responsibilities
Basic qualifications
Competitive qualifications
Eligibility requirements
Perks and benefits
Salary expactations
Application workflow and processing timeline`,
    },
    {
      type: "blog_post",
      label: "Blog Post",
      description:
        "Create an engaging blog post based on knowledge extracted from reference documents.",
      color: projectColors.blog_post,
      icon: projectIcons.blog_post,
      short_label: "Blog Post",
      topic_summary: "Blog Postabout...",
      topic_main_points: `Keywords to include:
Intended audience 
Tone of voice
Call to action`,
    },
    {
      type: "marketing_research_report",
      label: "Market Research Report",
      description: `Encapsulate an executive summary, clear market definition, analysis of market size and structure, 
        drivers of demand, exploration of relevant business models, profiling of notable players, evaluation 
        of barriers to entry, and identification of potential causes for market disruption.`,
      color: projectColors.marketing_research_report,
      icon: projectIcons.marketing_research_report,
      short_label: "Marketing Report",
      topic_summary: "Marketing Report about...",
      topic_main_points: `Executive summary
Market definition
Market size
Market structure
Drivers of demand
Relevant business models
Notable players
Barriers to entry
Potential causes for disruption
      `,
    },
    {
      type: "literature_review",
      label: "Literature Review",
      description:
        "Compile, summarize, and evaluate existing research and scholarly articles on a specific topic.",
      color: projectColors.literature_review,
      icon: projectIcons.literature_review,
      short_label: "Literature Review",
      topic_summary: "Literature Review about...",
      topic_main_points: `For each publication:
- What question or problem is addressed in each publication?
- What are the key concepts and how are they defined?
- What are the key theories, models, and methods?
- Does the research use established frameworks or take an innovative approach?
- What are the results and conclusions of each study?
- What are the strengths and weaknesses of the research?`,
    },
    {
      type: "short_essay",
      label: "Short Essay",
      description: `Integrate key elements of scholarly research, encompassing supporting points for the thesis, underpinned by logical 
        reasoning and references, potential counterarguments, strategic rebuttals, and suggestions for future works to extend the research scope.`,
      color: projectColors.short_essay,
      icon: projectIcons.short_essay,
      short_label: "Short Essay",
      topic_summary: "Short Essay about...",
      topic_main_points: `Thesis supporting points
Reasoning and references
Potential counterarguments
Rebuttals to counterarguments
Future works`,
    },
  ];

  const projectsToUse: AICreateProjectList = useMemo(() => {
    if (projects) {
      return projects.sort((a, b) =>
        sortOptions[projectSort.key].compare(a, b, projectSort.order)
      );
    }
    return [];
  }, [projects, projectSort]);

  const noProjects = projects && projects.length === 0;

  if (!projects) {
    return <MainLoader />;
  }

  return (
    <>
      <Wrapper>
        <Box className="main-container">
          <Box className="templates-container">
            <Box className="templates-header">
              {smScreen && (
                <IconButton
                  className="sidebar-close-icon"
                  size="medium"
                  onClick={() => dispatch(setSideBarOpen(true))}
                  color="primary"
                >
                  <MenuOutlinedIcon fontSize="medium" color="action" />
                </IconButton>
              )}
              <Typography fontWeight={noProjects ? 500 : 400} variant="body1">
                Start a new project
              </Typography>
            </Box>
            <Box
              className={clsx(
                "default-templates-list",
                noProjects && "start-new"
              )}
            >
              {projectTemplates.map((project, index) => {
                return (
                  <LightTooltip
                    placement="bottom-start"
                    enterDelay={300}
                    disableHoverListener={noProjects}
                    key={project.type}
                    color={project.color}
                    title={
                      <div className="tooltip-container">
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          className="title"
                        >
                          {project.label}
                        </Typography>
                        <Typography variant="body2">
                          {project.description}
                        </Typography>
                      </div>
                    }
                  >
                    <TemplateWrapper
                      projectcolor={project.color}
                      noproject={noProjects ? "true" : ""}
                      onClick={() => {
                        if (index > 0) {
                          setSelectedTemplate(projectTemplates[index]);
                        }
                        setCreateCustomProject(true);
                      }}
                    >
                      <Box
                        className="header"
                        sx={
                          project.type === "marketing_research_report" &&
                          projects.length === 0
                            ? {
                                marginTop: "20px",
                              }
                            : {}
                        }
                      >
                        {project.icon}
                        <Typography
                          color={noProjects ? "inherit" : "textPrimary"}
                          fontWeight={noProjects ? 500 : 400}
                          className="title"
                          variant="body2"
                        >
                          {project.label}
                        </Typography>
                      </Box>
                      <Typography
                        className="description"
                        color="textPrimary"
                        variant="body2"
                      >
                        {project.description}
                      </Typography>
                    </TemplateWrapper>
                  </LightTooltip>
                );
              })}
            </Box>
          </Box>
          {projects && projects?.length > 0 && (
            <Box className="projects-container">
              <Box className="projects-header">
                <Typography variant="body1" fontWeight={500}>
                  Recent projects
                </Typography>
                <Box className="project-actions">
                  <ToggleButtonGroup
                    color="primary"
                    value={view}
                    exclusive
                    onChange={(e, value) => {
                      if (value) {
                        setView(value);
                      }
                    }}
                  >
                    <ToggleButton value="card" size="small">
                      <ContentCopyIcon fontSize="small" />
                    </ToggleButton>
                    <ToggleButton value="list" size="small">
                      <ListIcon fontSize="small" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <SortProjectMenu
                    sort={projectSort}
                    setProjectSort={setProjectSort}
                  />
                </Box>
              </Box>
              <Box className={clsx("project-list-container", view)}>
                {projectsToUse.map((proj) => {
                  return (
                    <AICreateProject
                      key={proj.id}
                      project={proj}
                      setSelectedProjectId={setSelectedProjectId}
                      editProject={setEditProject}
                      setDeleteProjectId={setDeleteProjectId}
                      view={view}
                    />
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>
      </Wrapper>
      <AICreateProjectDialog
        open={createCustomProject || !!editProject}
        projectToUpdate={editProject}
        setOpen={(open: boolean, newProj?: AICreateProjectType) => {
          if (editProject) {
            setEditProject(undefined);
          }
          setCreateCustomProject(open);
          setSelectedTemplate(undefined);
          if (newProj) {
            setSelectedProjectId(newProj.id);
          }
        }}
        projectTemplate={selectedTemplate}
      />
      <AICreateDeleteProjectDialog
        projectId={deleteProjectId}
        close={() => {
          setDeleteProjectId(undefined);
        }}
      />
      <AiCreateProjectDashboardOnboarding />
    </>
  );
};

export default AICreateProjectDashboard;
