import { useCallback, useMemo } from "react";
import axios, {
  AxiosError,
  AxiosProgressEvent,
  AxiosPromise,
  AxiosResponse,
  CancelToken as CancelTokenType,
} from "axios";
import { IOutlineItem } from "models/components/DocumentViewer.models";
import {
  DocumentReadResponse,
  DocumentResourceStats,
  DocumentSearchResponse,
  PageLayoutResponse,
  SuccessResponse,
  DocumentUpdateBody,
  DocumentBibtexResponse,
  DocumentLinksResponse,
  DocumentList,
} from "models/api/response.types";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import handleAxiosError from "utils/handleAxiosAlert";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";

const download = (
  documentId: number,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
): AxiosPromise<ArrayBuffer> =>
  axios.get(`/api/document/download?document_ids=${documentId}`, {
    responseType: "arraybuffer",
    onDownloadProgress,
  });

const content = (
  documentId: number,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
): AxiosPromise<ArrayBuffer> =>
  axios.get(`/api/document/${documentId}/content`, {
    responseType: "arraybuffer",
    onDownloadProgress,
  });

const fetchDocuments = (
  organizationId: number,
  options?: { cancelToken?: CancelTokenType }
): AxiosPromise<DocumentReadResponse[]> =>
  axios.get(`/api/organization/${organizationId}/document/list`, {
    cancelToken: options?.cancelToken,
  });

const fetchDocument = (
  documentId: number
): AxiosPromise<DocumentReadResponse> =>
  axios.get(`/api/document/${documentId}`);

const fetchDocumentPageLayout = (
  documentId: number,
  pageNumber: number,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<any> =>
  axios.get(`/api/document/${documentId}/page/${pageNumber}/layout`, {
    onDownloadProgress,
  });

const fetchDocumentPageImage = (
  documentId: number,
  pageNumber: number,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
): AxiosPromise<ArrayBuffer> =>
  axios.get(`/api/document/${documentId}/page/${pageNumber}/image`, {
    responseType: "arraybuffer",
    onDownloadProgress,
  });

const fetchDocumentPageThumbnail = (
  documentId: number,
  pageNumber: number
): AxiosPromise<ArrayBuffer> =>
  axios.get(`/api/document/${documentId}/page/${pageNumber}/thumbnail`, {
    responseType: "arraybuffer",
  });

const fetchDocumentSearch = (
  id: number,
  query: string
): AxiosPromise<DocumentSearchResponse> =>
  axios.get(`/api/document/${id}/search?q=${query}`);

const fetchDocumentLayout = (
  id: number,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
): AxiosPromise<PageLayoutResponse[]> =>
  axios.get(`/api/document/${id}/layout`, { onDownloadProgress });

const fetchDocumentResources = (
  id: number
): AxiosPromise<DocumentResourceStats> =>
  axios.get(`/api/document/${id}/resources`);

const fetchDocumentOutline = (id: number): AxiosPromise<IOutlineItem[]> =>
  axios.get(`/api/document/${id}/outline`);

const fetchDocumentText = (id: number): AxiosPromise<string> =>
  axios.get(`/api/document/${id}/text`);

const fetchDocumentLinks = (
  documentId: number
): AxiosPromise<DocumentLinksResponse> =>
  axios.get(`/api/document/${documentId}/links`);

const updateDocument = (
  id: number,
  updates: DocumentUpdateBody
): AxiosPromise<DocumentReadResponse> =>
  axios.post(`/api/document/${id}/update`, updates);

const createDocumentStubs = (
  organizationId: number,
  stubs: Array<{ meta_json: string; ui_json?: string }>
): AxiosPromise<DocumentReadResponse[]> =>
  axios.post(`/api/document/create_stubs`, {
    organization_id: organizationId,
    stubs,
  });

const getSourceTableContent = (documentId: number): AxiosPromise<any> =>
  axios.get(`/api/document/${documentId}/content`).then((res) => res.data);

// axios.delete does support a request body. It accepts two parameters: url and optional config.
// You can use config.data to set the request body and headers as follows:
const deleteMultipleDocuments = (
  document_ids: number[]
): AxiosPromise<SuccessResponse> =>
  axios.delete(`/api/document/delete`, { data: { document_ids } });

const grobidReferences = (
  documentId: number
): AxiosPromise<DocumentBibtexResponse> =>
  axios.get(`/api/document/${documentId}/grobid/references`);

const grobidFulltext = (documentId: number): AxiosPromise<string> =>
  axios.get(`/api/document/${documentId}/grobid/fulltext`);

const moveDocumentsToTrash = (
  documentIds: number[]
): AxiosPromise<SuccessResponse> =>
  axios.post(`/api/document/trash`, { document_ids: documentIds });

const restoreDocumentsFromTrash = (
  documentIds: number[]
): AxiosPromise<SuccessResponse> =>
  axios.post(`/api/document/restore`, { document_ids: documentIds });

const doiLookup = (payload: {
  title: string;
  author: string;
  sources: string[];
}): AxiosPromise<any> => axios.post("/api/document/doi_lookup", payload);

const doiSearch = (path: string): AxiosPromise<any> =>
  axios.get(`/api/document/doi_search?doi=${path}`);

const copyDocuments = (payload: {
  destination_organization_id: number;
  document_ids: number[];
  copy_annotations: boolean;
  copy_tags: boolean;
}): AxiosPromise<any> => axios.post("/api/document/copy", payload);

const documentService = {
  fetchDocuments,
  fetchDocument,
  fetchDocumentSearch,
  fetchDocumentLayout,
  fetchDocumentPageLayout,
  fetchDocumentPageImage,
  fetchDocumentPageThumbnail,
  fetchDocumentOutline,
  fetchDocumentResources,
  fetchDocumentText,
  updateDocument,
  createDocumentStubs,
  deleteMultipleDocuments,
  grobidReferences,
  grobidFulltext,
  moveDocumentsToTrash,
  restoreDocumentsFromTrash,
  fetchDocumentLinks,
  doiLookup,
  doiSearch,
  download,
  content,
  copyDocuments,
  getSourceTableContent,
};

export default documentService;

// Used to add a cancel property to Promise to appease Typescript
// See https://github.com/tannerlinsley/react-query/issues/1265
interface ExtendedPromise<T> extends Promise<T> {
  cancel?: () => void;
}

// the arguments for the updateDocument mutation
interface DocumentUpdateVariables {
  id: number;
  payload: {
    organization_id?: number;
    meta?: { [key: string]: unknown };
    content?: [{ [key: string]: unknown }];
    ui?: { [key: string]: unknown };
    tag_ids?: number[];
    use_ocr?: boolean;
    filename?: string;
    is_trash?: boolean;
  };
}

interface UseDocumentsResult {
  documents: DocumentReadResponse[] | undefined;
  documentsQueryKey: string[];
  documentsIsLoading: boolean;
  documentsIsFetching: boolean;
  getCachedDocumentById: (
    id: number
  ) => [item: Readonly<DocumentReadResponse> | undefined, index: number];
  upsertCachedDocument: (
    newDocument: DocumentReadResponse
  ) => DocumentReadResponse | undefined;
  upsertCachedDocuments: (
    newDocuments: DocumentList
  ) => DocumentList | undefined;
  removeCachedDocument: (id: number) => DocumentReadResponse | undefined;
  removeCachedDocuments: (ids: number[]) => DocumentReadResponse[] | undefined;
  updateDocumentMutation: UseMutationResult<
    AxiosResponse<DocumentReadResponse>,
    unknown,
    Readonly<DocumentUpdateVariables>,
    unknown
  >;
  deleteMultipleDocumentsMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    number[],
    unknown
  >;
  moveDocumentsToTrashMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    number[],
    unknown
  >;
  restoreDocumentsFromTrashMutation: UseMutationResult<
    AxiosResponse<SuccessResponse>,
    unknown,
    number[],
    unknown
  >;
}

export const useDocuments = (
  organizationId: number | undefined
): UseDocumentsResult => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();
  const documentsQueryKey = [`organization/${organizationId}/documents`];

  const {
    data: documents,
    isLoading: documentsIsLoading,
    isFetching: documentsIsFetching,
  } = useQuery<unknown, unknown, DocumentReadResponse[], any>(
    documentsQueryKey,
    (): ExtendedPromise<DocumentReadResponse[]> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = documentService.fetchDocuments(
        organizationId as number,
        {
          cancelToken: source.token,
        }
      );
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: DocumentReadResponse[] }) =>
          responseData
      );
    },
    {
      enabled: !!organizationId,
      placeholderData: undefined,
      onError: (err) => handleAxiosError(err as AxiosError, dispatch, { user }),
    }
  );

  const documentIndexById = useMemo(
    () =>
      documents?.reduce((lut: { [id: number]: number }, document, index) => {
        // eslint-disable-next-line no-param-reassign
        lut[document.id] = index;
        return lut;
      }, {}),
    [documents]
  );

  const getCachedDocumentById: (
    id: number
  ) => [
    item: Readonly<DocumentReadResponse> | undefined,
    index: Readonly<number>
  ] = useCallback(
    (id: number) =>
      documents && documentIndexById && id in documentIndexById
        ? [documents[documentIndexById[id]], documentIndexById[id]]
        : [undefined, -1],
    [documentIndexById]
  );

  // single
  const removeCachedDocument = (
    id: number
  ): DocumentReadResponse | undefined => {
    const [currentDocument, index] = getCachedDocumentById(id);
    if (documents && currentDocument) {
      const newDocuments = [...documents];
      newDocuments.splice(index, 1);
      queryClient.setQueryData<DocumentReadResponse[]>(
        documentsQueryKey,
        newDocuments
      );
      return currentDocument;
    }
    return undefined;
  };

  // multiple
  const removeCachedDocuments = (
    ids: number[]
  ): DocumentReadResponse[] | undefined => {
    if (documents) {
      const removedDocuments: DocumentReadResponse[] = [];
      const newDocuments = documents.filter(
        (document: DocumentReadResponse) => {
          if (!ids.includes(document.id)) {
            removedDocuments.push(document);
            return true;
          }
          return false;
        }
      );
      queryClient.setQueryData<DocumentReadResponse[]>(
        documentsQueryKey,
        newDocuments
      );
      return removedDocuments;
    }
    return undefined;
  };

  // single
  const upsertCachedDocument = (
    newDocument: DocumentReadResponse
  ): DocumentReadResponse | undefined => {
    const [currentDocument, index] = getCachedDocumentById(newDocument.id);
    if (documents) {
      const newDocuments = [...documents];
      if (index > -1) {
        newDocuments.splice(index, 1, newDocument);
      } else {
        newDocuments.unshift(newDocument);
      }
      queryClient.setQueryData<DocumentReadResponse[]>(
        documentsQueryKey,
        newDocuments
      );
    } else {
      queryClient.setQueryData<DocumentReadResponse[]>(documentsQueryKey, [
        newDocument,
      ]);
    }
    return currentDocument;
  };

  // multiple
  const upsertCachedDocuments = (
    newDocumentsList: DocumentList
  ): DocumentList | undefined => {
    if (documents) {
      const newDocuments = [...documents];
      newDocumentsList.forEach((document) => {
        const [currentDocument, index] = getCachedDocumentById(document.id);
        if (index > -1) {
          newDocuments.splice(index, 1, document);
        } else {
          newDocuments.unshift(document);
        }
      });

      queryClient.setQueryData<DocumentReadResponse[]>(
        documentsQueryKey,
        newDocuments
      );
    } else {
      queryClient.invalidateQueries(documentsQueryKey);
    }

    return newDocumentsList;
  };

  const updateDocumentMutation = useMutation(
    ({ id, payload }: Readonly<DocumentUpdateVariables>) => {
      const updates: DocumentUpdateBody = {};
      // convert DocumentUpdateVariables to DocumentUpdateBody
      if (payload.filename !== undefined) updates.filename = payload.filename;
      if (payload.meta !== undefined)
        updates.meta_json = JSON.stringify(payload.meta);
      if (payload.content !== undefined)
        updates.content = JSON.stringify(payload.content);
      if (payload.ui !== undefined)
        updates.ui_json = JSON.stringify(payload.ui);
      if (payload.tag_ids !== undefined)
        updates.tags_json = JSON.stringify(payload.tag_ids);
      if (payload.use_ocr !== undefined) updates.use_ocr = payload.use_ocr;
      if (payload.is_trash !== undefined) updates.is_trash = payload.is_trash;
      // make API call with DocumentUpdateBody
      return documentService.updateDocument(id, updates);
    },
    {
      retry: false,
      onMutate: async ({ id, payload }: Readonly<DocumentUpdateVariables>) => {
        // cancel any pending document queries
        await queryClient.cancelQueries(documentsQueryKey);
        // get existing document
        const [currentDocument] = getCachedDocumentById(id);
        if (currentDocument) {
          // apply optimistic updates to copy of current document
          const newDocument = { ...currentDocument };
          let moveToTrash = false;
          if (payload.filename !== undefined)
            newDocument.filename = payload.filename;
          if (payload.meta !== undefined)
            newDocument.meta = { ...payload.meta };
          if (payload.tag_ids !== undefined)
            newDocument.tag_ids = [...payload.tag_ids];
          if (payload.use_ocr !== undefined)
            newDocument.use_ocr = payload.use_ocr;
          if (payload.is_trash !== undefined) {
            moveToTrash = true;
            newDocument.is_trash = payload.is_trash;
          }
          return {
            previousDocument: upsertCachedDocument(newDocument),
            moveToTrash,
          };
        }
        return { previousDocument: undefined };
      },
      onSuccess: (response) => {
        if (response?.data) {
          upsertCachedDocument(response.data);
        }
      },
      onError: (error) => {
        queryClient.invalidateQueries(documentsQueryKey);
        handleAxiosError(error as AxiosError, dispatch);
      },
    }
  );

  const deleteMultipleDocumentsMutation = useMutation(
    (documentIds: number[]) =>
      documentService.deleteMultipleDocuments(documentIds),
    {
      retry: false,
      onMutate: async (documentIds: number[]) => {
        await queryClient.cancelQueries(documentsQueryKey);
        const removedDocuments = removeCachedDocuments(documentIds);
        return { removedDocuments };
      },
      onSettled: (response, error, variables, context) => {
        if (error) {
          // on error we roll-back the optimistic update by restoring context.previousDocument
          handleAxiosError(error as AxiosError, dispatch);
          if (context?.removedDocuments) {
            upsertCachedDocuments(context.removedDocuments);
            // trigger refetch documents in case rollback caused inconsistency
            queryClient.invalidateQueries(documentsQueryKey);
          }
        }
      },
    }
  );

  const moveDocumentsToTrashMutation = useMutation(
    (documentIds: number[]) =>
      documentService.moveDocumentsToTrash(documentIds),
    {
      retry: false,
      onMutate: async (documentIds: number[]) => {
        await queryClient.cancelQueries(documentsQueryKey);
        if (documents) {
          const newDocuments = [...documents];
          documentIds.forEach((id) => {
            const [currentDocument, index] = getCachedDocumentById(id);
            if (index > -1 && currentDocument) {
              const updatedDocument = { ...currentDocument, is_trash: true };
              newDocuments.splice(index, 1, updatedDocument);
            }
          });
          queryClient.setQueryData<DocumentReadResponse[]>(
            documentsQueryKey,
            newDocuments
          );
        }
        return { updatedDocumentIds: documentIds };
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(documentsQueryKey);
      },
    }
  );

  const restoreDocumentsFromTrashMutation = useMutation(
    (documentIds: number[]) =>
      documentService.restoreDocumentsFromTrash(documentIds),
    {
      retry: false,
      onMutate: async (documentIds: number[]) => {
        await queryClient.cancelQueries(documentsQueryKey);
        if (documents) {
          const newDocuments = [...documents];
          documentIds.forEach((id) => {
            const [currentDocument, index] = getCachedDocumentById(id);
            if (index > -1 && currentDocument) {
              const updatedDocument = { ...currentDocument, is_trash: false };
              newDocuments.splice(index, 1, updatedDocument);
            }
          });
          queryClient.setQueryData<DocumentReadResponse[]>(
            documentsQueryKey,
            newDocuments
          );
        }
        return { updatedDocumentIds: documentIds };
      },
      onError: (error) => {
        handleAxiosError(error as AxiosError, dispatch);
        queryClient.invalidateQueries(documentsQueryKey);
      },
    }
  );

  return {
    documents,
    documentsQueryKey,
    documentsIsLoading,
    documentsIsFetching,
    getCachedDocumentById,
    upsertCachedDocument,
    upsertCachedDocuments,
    removeCachedDocument,
    removeCachedDocuments,
    updateDocumentMutation,
    deleteMultipleDocumentsMutation,
    moveDocumentsToTrashMutation,
    restoreDocumentsFromTrashMutation,
  };
};
