import { Box, styled } from "@mui/material";

/* eslint-disable import/prefer-default-export */
export const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  overflow: "hidden",
  flexDirection: "column",
  gap: "1rem",
  padding: `${theme.spacing(1.5)}`,
  [theme.breakpoints.down("lg")]: {
    gap: theme.spacing(1.5),
  },
  "& .error-message": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    "& img": {
      width: "250px",
      [theme.breakpoints.down("lg")]: {
        width: "200px",
      },
      [theme.breakpoints.down("md")]: {
        width: "150px",
      },
    },
  },
  "& .content": {
    flex: 1,
    display: "flex",
    gap: "1rem",
    overflow: "hidden",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      gap: theme.spacing(1.5),
    },
    "& .sessions-large": {
      maxWidth: "300px",
      minWidth: "200px",
      flex: 1,
      background: theme.background.light,
      borderRadius: "4px",
      border: `1px solid ${theme.grey.light}`,
      padding: `${theme.spacing(1.5)} 0`,
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      [theme.breakpoints.down("xl")]: {
        maxWidth: "250px",
      },
      "& .add-chat": {
        margin: `0 ${theme.spacing(1.5)}`,
      },
      "& .session-list": {
        overflow: "auto",
        padding: `0 ${theme.spacing(1.5)}`,
        marginTop: "0.5rem",
        "& .list-button:not(last-of-type)": {
          marginBottom: theme.spacing(1.5),
        },
        "& .list-button": {
          height: "48px",
          borderRadius: "4px",
          border: `1px solid ${theme.grey.light}`,
          "&:hover": {
            "& .actions": {
              display: "flex",
            },
          },
          "& .MuiListItemIcon-root": {
            minWidth: "0",
            marginRight: "1rem",
          },
          "& p": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "& .actions": {
            display: "none",
          },
        },
      },
    },
    "& .sessions-small": {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
      "& .add-session-button": {
        background: theme.palette.primary.main,
        color: "#FFF",
        transition: "all 0.3s",
        "&:hover": {
          background: theme.palette.primary.dark,
        },
      },
    },
    "& .chat": {
      flex: 1,
      overflow: "hidden",
      "& .dialog-container": {
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: 0,
        "& .chat-container": {
          flex: 1,
          position: "relative",
          background: theme.background.light,
          borderRadius: "4px",
          border: `1px solid ${theme.grey.light}`,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          "& .chat-header": {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: `${theme.spacing(1.5)}`,
            gap: theme.spacing(1),
          },
          "& .notification-alerts-box": {
            display: "flex",
            alignItems: "center",
            gap: ".35rem",
            "& .notification-alerts-count": {
              fontSize: ".875rem",
              lineHeight: "1rem",
            },
          },
          "& .document-state": {
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          },
          "& #chat-content": {
            transition: "all 0.3s",
            padding: `${theme.spacing(1.5)}`,
            display: "flex",
            // need to start scroll at the bottom
            flexDirection: "column-reverse",
            alignItems: "start",
            gap: "1rem",
            overflow: "hidden",
            overflowY: "auto",
            "& .custom-message": {
              background: `${theme.background.secondary}70`,
              borderRadius: "4px",
              borderBottomLeftRadius: "0",
              padding: "0.5rem 1rem",
              fontSize: "14px",
              width: "fit-content",
              maxWidth: "60%",
              [theme.breakpoints.down("lg")]: {
                maxWidth: "80%",
              },
              [theme.breakpoints.down("md")]: {
                maxWidth: "calc(100% - 1rem)",
              },
              "& .verify-actions": {
                marginTop: theme.spacing(1),
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              },
            },
            "& .message-container": {
              width: "100%",
              "& > *:not(:last-child)": {
                marginBottom: "1rem",
              },
              "& .date": {
                width: "100%",
              },
              "& .messages": {
                width: "100%",
                "& > .message:not(:last-child)": {
                  marginBottom: "1rem",
                },
              },
            },
          },
          "& .loading-message": {
            "& span": {
              width: "12px",
              height: "12px",
              margin: "0 5px",
              backgroundColor: theme.palette.primary.main,
              borderRadius: "50%",
              display: "inline-block",
              animationName: "dots",
              animationDuration: "2s",
              animationIterationCount: "infinite",
              animationTimingFunction: "ease-in-out",
              "&:nth-of-type(2)": {
                animationDelay: "0.4s",
              },
              "&:nth-of-type(3)": {
                animationDelay: "0.8s",
              },
            },
            "@keyframes dots": {
              "50%": {
                opacity: 0,
                transfrom: "scale(0.7) translateY(10px)",
              },
            },
          },
          "& .continue-button": {
            position: "absolute",
            background: theme.background.light,
            bottom: "0.5rem",
            left: "50%",
            transform: "translateX(-50%)",
            boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
            whiteSpace: "nowrap",
          },
        },
        "& .form-container": {
          marginTop: "auto",
          border: `1px solid ${theme.grey.light}`,
          background: theme.background.light,
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& .collapse-button": {
            "& .option-icon": {
              transition: "all 0.3s",
              "&.opened": {
                transform: "rotate(180deg)",
              },
            },
          },
          "& .MuiCollapse-root": {
            width: "100%",
          },
          "& .MuiCollapse-wrapperInner": {
            width: "100%",
            padding: "0 1rem",
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          },
          "& .question-container": {
            padding: "1rem",
            width: "100%",
            "&:not(.no-documents) p": {
              marginLeft: "5rem",
            },
            "& p": {
              marginBottom: "0.3rem",
            },
            "& .input-container": {
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            },
          },
          "& .external-knowledge-container, & .history-container": {
            width: "100%",
            "& .MuiCheckbox-root": {
              padding: 0,
              marginRight: "0.5rem",
            },
            "& label": {
              margin: 0,
            },
          },
          "& .answer-prefix-container": {
            flexDirection: "row",
            width: "100%",
            alignItems: "flex-end",
            "& .MuiCheckbox-root": {
              padding: 0,
              marginRight: "0.5rem",
              marginBottom: "0.3rem",
            },
          },
        },
      },
    },
  },
}));
