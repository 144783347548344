import { Tag } from "models/api/response.types";

export interface ISortOrder {
  key: string;
  order: "ascending" | "descending";
}

interface SortOption {
  label: string;
  compare: (a: Tag, b: Tag, order: string) => number;
}

export const sortOptions: Record<string, SortOption> = {
  frequency: {
    label: "Frequency",
    compare: (a, b, order) => {
      if (a.document_count >= 0 && b.document_count >= 0) {
        const aYear = a.document_count;
        const bYear = b.document_count;
        return order === "ascending" ? aYear - bYear : bYear - aYear;
      }
      return 0;
    },
  },
  alphabetical: {
    label: "Alphabetical",
    compare: (a, b, order) => {
      if (a.name && b.name) {
        const list = a.name
          .toLocaleLowerCase()
          .localeCompare(b.name.toLocaleLowerCase());
        return order === "ascending" ? list : list * -1;
      }
      return 0;
    },
  },
};
