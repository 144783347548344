/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Close } from "@mui/icons-material";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TagInput from "components/Tags/TagInput";
import { useReactQueryTagInput } from "utils/useTagInput";
import { useTags } from "api/tagService";
import { DocumentReadResponse, Tag, TagList } from "models/api/response.types";
import { selectCurrentOrganizationId } from "store/features/session/slice";

const AddTagsDialog: React.FC<{
  setOpen: (open: boolean) => void;
  selectedDocuments: DocumentReadResponse[];
}> = ({ setOpen, selectedDocuments }) => {
  const handleSaveEditedTagIds = (tagIds: number[], different: boolean) => {};
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);

  const { tags: tagsList, addTagToDocumentsMutation } = useTags(
    currentOrganizationId
  );
  const [tags, setTags, saveSelectedTagIds] = useReactQueryTagInput({
    tagOptions: tagsList as Tag[],
    defaultTagIds: [],
    organizationId: currentOrganizationId || 0,
    saveTagIds: handleSaveEditedTagIds,
  });

  const handleSaveTagClick = () => {
    const documentIds: number[] = [];
    saveSelectedTagIds(documentIds);
  };

  const handleAddTagsToDocuments = () => {
    const selectedDocumentsIds = selectedDocuments.map((item) => item.id);
    saveSelectedTagIds(selectedDocumentsIds);
    const tagsArray = tags.map((item) =>
      item.id === -1
        ? tagsList?.filter((tag) => tag.name === item.name)[0]
        : item
    );
    tagsArray.map((tag) =>
      addTagToDocumentsMutation.mutate({
        tagId: tag.id,
        documentIds: selectedDocumentsIds,
      })
    );
  };

  return (
    <Dialog
      open
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Add tags
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TagInput
          disabled={false}
          setValue={setTags}
          selectedTags={tags as TagList}
          dropDownTags={tagsList || []}
          handleSaveClick={handleSaveTagClick}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => {
            handleAddTagsToDocuments();
            setOpen(false);
          }}
        >
          Add tags
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTagsDialog;
