import { styled } from "@mui/material/styles";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  width: "100%",
  height: "100%",
  alignItems: "center",
  borderRadius: "4px",
  padding: "1px",
  "& .noResults": {
    margin: "2rem 1rem",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.4)",
  },
  "& .input": {
    background: theme.background.light,
  },
  "& .snippetsContainer": {
    gridArea: "snippetsContainer",
    marginTop: "1rem",
    padding: "2px",
    height: "100%",
    width: "100%",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    "& .snippet": {
      background: theme.background.light,
      borderRadius: "4px",
      boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
      padding: "1rem",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      "&:not(.browse-view)": {
        cursor: "pointer",
      },
    },
  },
  "& .snippetChart": {
    gridArea: "snippetsChart",
    width: "100%",
    height: "200px",
    boxShadow: "0 0.25rem 0.5rem -0.5rem rgba(0, 0, 0, 0.3)",
    display: "none",
  },
  "& .snippetsContainer strong": {
    padding: " 0rem 0.1rem",
    margin: "0rem -0.1rem",
    borderBottom: "2px solid",
  },
  "& .recharts-legend-item-text, .recharts-default-tooltip, .snippet-text": {
    fontSize: "14px",
  },
}));

export default Wrapper;
